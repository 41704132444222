import useSortableData from '../../../../../../hooks/useSortableData';
import styled from 'styled-components';
import React, { useState } from 'react';

import { ReactComponent as Icn_chevronTop } from '../../../../../../assets/images/icn_chevron-top.svg';
import { ReactComponent as Icn_chevronCircleDown } from '../../../../../../assets/images/icn_chevronCircle-down.svg';
import { ReactComponent as Icn_check } from '../../../../../../assets/images/icn_Check-r.svg';
import { useTranslation } from 'react-i18next';

// #region styled-components

const IndexTableContainer = styled.div``;

const ToggleBtn = styled.h6`
	color: var(--primary);
	font-size: 14px;
	margin-right: 16px;
	cursor: pointer;
`;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
	cursor: pointer;

	/* &:hover {
		background-color: var(--primaryllt) !important;
	} */
`;

const TR = styled.tr`
	height: 40px;

	&:hover {
		background-color: transparent !important;
	}
	&:hover:nth-of-type(0) {
		background-color: var(--primaryllt) !important;
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const ChildTR = styled.tr`
	height: 40px;
`;

const ChildTH = styled.th`
	height: 40px;
	border: 1px solid var(--grey1) !important;
	text-align: center !important;
	font-weight: bold;

	&:nth-of-type(1) {
		border-left: none !important;
		width: 56px;
	}

	&:nth-of-type(2) {
		width: 120px;
	}

	&:nth-of-type(3),
	&:nth-of-type(4),
	&:nth-of-type(5),
	&:nth-of-type(6) {
		width: 160px;
	}

	&:nth-of-type(7) {
		border-right: none !important;
		width: 80px;
	}

	&:nth-of-type(8) {
		border-right: none !important;
		width: 160px;
	}
`;

const ChildTD = styled.td`
	height: 40px;
	border: 1px solid var(--grey1) !important;
	padding: 0.5rem !important;
	text-align: center;

	&:first-of-type {
		border-left: none !important;
	}

	&:nth-of-type(3),
	&:nth-of-type(4) {
		text-align: end;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const ChildTRFooter = styled.tr`
	height: 40px;
	border-top: 2px solid var(--grey3);
`;
//#endregion

function DetailAndGoodsTable(props) {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);

	// prop
	const {
		guidName,
		sortBy,
		headTitleList,
		bodyTitleList,
		childHeadTitleList,
		indexData,
	} = props;
	// console.log(props);

	// state
	const [orderBy, setOrderBy] = useState('desc');
	const [UISort, setUISort] = useState(sortBy);
	const [toggleBtnAllOpen, setToggleBtnAllOpen] = useState(false); // 展開/收合全部 btn

	// component
	const { items, requestSort, sortConfig } = useSortableData(indexData);

	// variable
	const purchaseCategoryCodeName = {
		M: t('lgsPurchaseOrder:material'),
		A: t('lgsPurchaseOrder:asset'),
		E: t('lgsPurchaseOrder:expense'),
	}; // 型態 => name轉換

	//#endregion

	//#region 表格排序

	const preferredOrder = (indexData, bodyTitleList) => {
		let newObject = {};
		for (let i = 0; i < bodyTitleList.length; i++) {
			if (indexData.hasOwnProperty(bodyTitleList[i])) {
				newObject[bodyTitleList[i]] = indexData[bodyTitleList[i]];
			}
		}
		return newObject;
	};

	const getClassNamesFor = (name) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	const handleSortChange = (newTarget) => {
		const oldTarget = UISort;
		setUISort(newTarget);

		if (oldTarget === newTarget)
			return setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');

		return setOrderBy('asc');
	};

	preferredOrder(indexData, bodyTitleList);

	//#endregion

	return (
		<div className="position-relative">
			<IndexTableContainer className="firstRowMinHeight bg-white d-flex flex-column p-4 position-relative">
				{/* 開闔btn */}
				<div className="text-end">
					{toggleBtnAllOpen ? (
						<ToggleBtn
							onClick={() => {
								// 箭頭btn向下
								document
									.querySelectorAll('.iconChevron')
									.forEach(
										(el) =>
											(el.style.transform =
												'rotate(0.5turn)')
									);

								// 隱藏全部子層
								document
									.querySelectorAll('.childInfoTr')
									.forEach((item) =>
										item.classList.add('d-none')
									);
								setToggleBtnAllOpen(false);
							}}
						>
							{t('lgsPurchaseOrder:foldAll')}
							{/* 箭頭btn向上 */}
							<Icn_chevronCircleDown
								className="ms-2 mb-1"
								style={{ transform: 'rotate(0.5turn)' }}
							/>
						</ToggleBtn>
					) : (
						<ToggleBtn
							onClick={() => {
								// 箭頭btn向上
								document
									.querySelectorAll('.iconChevron')
									.forEach((el) => (el.style.transform = ''));

								// 顯示子層
								document
									.querySelectorAll('.childInfoTr')
									.forEach((item) =>
										item.classList.remove('d-none')
									);
								setToggleBtnAllOpen(true);
							}}
						>
							{t('lgsPurchaseOrder:expandAll')}
							{/* 箭頭btn向下 */}
							<Icn_chevronCircleDown className="ms-2 mb-1" />
						</ToggleBtn>
					)}
				</div>

				{/* table */}
				<IndexTable className="table">
					<THead
						className="table-head"
						items={items}
						requestSort={requestSort}
						sortConfig={sortConfig}
					>
						<TR>
							{headTitleList?.map((el) => (
								<TH
									key={`tableHead${headTitleList.indexOf(
										el
									)}`}
									className={`
                                    text-center
                                        px-2
                                        ${
											bodyTitleList[
												headTitleList.indexOf(el)
											]
										}
                                        ${getClassNamesFor(
											bodyTitleList[
												headTitleList.indexOf(el)
											]
										)}
                                            `}
									onClick={() => {
										requestSort(
											bodyTitleList[
												headTitleList.indexOf(el)
											]
										);
										handleSortChange(
											bodyTitleList[
												headTitleList.indexOf(el)
											]
										);
									}}
								>
									{el}
									{bodyTitleList[
										headTitleList.indexOf(el)
									] === UISort &&
										(orderBy === 'desc' ? (
											<span className="ml-1">▼</span>
										) : (
											<span className="ml-1">▲</span>
										))}
								</TH>
							))}

							{/* 展開按鈕空間 */}
							<TH
								className={`
                                    text-center
                                        px-2`}
							></TH>
						</TR>
					</THead>
					<TBody>
						{items?.map((data, index) => (
							<React.Fragment key={data[guidName]}>
								{/* 父層 */}
								<TR
									className="list-item table-item"
									id={data[guidName]}
								>
									{bodyTitleList?.map((el) => {
										return (
											<TD
												className={`text-left p-2 
	                                            text-center 

												// 價差大於10，顯示紅字
												${(el === 'spreadPercent' && data['spreadPercent']) > 10 && 'text-danger'}`}
												key={`${el}${data[guidName]}`}
											>
												{/* 資料顯示轉換 1.型態 2.檢驗 */}
												{el ===
													'purchaseCategoryCode' &&
												data[el] === 'M' ? (
													purchaseCategoryCodeName.M
												) : 'purchaseCategoryCode' &&
												  data[el] === 'A' ? (
													purchaseCategoryCodeName.A
												) : 'purchaseCategoryCode' &&
												  data[el] === 'E' ? (
													purchaseCategoryCodeName.E
												) : el === 'inspection' &&
												  data[el] === '1' ? (
													<Icn_check />
												) : el === 'inspection' &&
												  data[el] === '0' ? (
													''
												) : (
													data[el]
												)}
											</TD>
										);
									})}

									{/* 展開按鈕 */}
									<TD
										className={`text-left p-2 
	                                            text-center`}
										style={{ cursor: 'pointer' }}
										onClick={(e) => {
											// icon 箭頭方向
											e.target.closest('svg').style
												.transform === ''
												? (e.target.closest(
														'svg'
												  ).style.transform =
														'rotate(.5turn)')
												: (e.target.closest(
														'svg'
												  ).style.transform = '');

											// 子層顯示
											e.target
												.closest('tr')
												.nextElementSibling.classList.contains(
													'd-none'
												)
												? e.target
														.closest('tr')
														.nextElementSibling.classList.remove(
															'd-none'
														)
												: e.target
														.closest('tr')
														.nextElementSibling.classList.add(
															'd-none'
														);

											e.target.closest(
												'svg'
											).style.transform =
												'rotate("0.5turn")';
										}}
									>
										<Icn_chevronTop
											className="iconChevron"
											style={{
												transform:
													index === 0
														? ''
														: 'rotate(.5turn)',
											}}
										/>
									</TD>
								</TR>

								{/* 子層 */}
								<tr
									// 第一個預設開啟
									className={`${
										index !== 0 && 'd-none'
									} childInfoTr`}
								>
									{/* purchaseOrderGoodsList 是否為空 */}
									{/* {console.log(data.purchaseOrderGoodsList.length)} */}
									{data.purchaseOrderGoodsList.length ===
									0 ? (
										<>
											<td
												colSpan="2"
												className="p-0 bg-primaryllt"
											></td>
											<td
												colSpan="10"
												className="p-0 bg-primaryllt"
											>
												<div className="pt-3 pb-2">
													<span
														style={{
															color: 'var(--primary)',
														}}
														className="fw-bold me-4"
													>
														{t(
															'lgsPurchaseOrder:receiptHistory'
														)}
													</span>
													<span className="text-secondary fw-bold">
														(
														{t(
															'lgsPurchaseOrder:noData'
														)}
														)
													</span>
												</div>
											</td>
										</>
									) : (
										<>
											<td
												colSpan="2"
												className="p-0"
											></td>
											<td colSpan="10" className="p-0">
												<div className="pt-3 pb-2 text-primary fw-bold">
													{t(
														'lgsPurchaseOrder:receiptHistory'
													)}
												</div>
												<table className="mt-1">
													<thead>
														{/* 是否顯示批次編號 */}
														<ChildTR>
															{childHeadTitleList?.map(
																(el, index) =>
																	index ===
																	childHeadTitleList.length -
																		1 ? (
																		<ChildTH
																			key={
																				el +
																				index
																			}
																			className={`text-left p-2 ${
																				!data
																					?.purchaseOrderGoodsList[0]
																					.batchNo &&
																				'd-none'
																			}`}
																		>
																			{el}
																		</ChildTH>
																	) : (
																		<ChildTH
																			key={
																				el +
																				index
																			}
																			className={`text-left p-2`}
																		>
																			{el}
																		</ChildTH>
																	)
															)}
														</ChildTR>
													</thead>
													<tbody>
														{data.purchaseOrderGoodsList?.map(
															(el) => (
																<ChildTR
																	key={
																		el.goodsItemGuid
																	}
																>
																	<ChildTD scope="row">
																		{
																			el.displayNumber
																		}
																	</ChildTD>
																	<ChildTD>
																		{
																			el.postingDate
																		}
																	</ChildTD>
																	<ChildTD>
																		{
																			el.quantityReceive
																		}
																	</ChildTD>
																	<ChildTD>
																		{
																			el.uncollectedQuantity
																		}
																	</ChildTD>
																	<ChildTD>
																		{
																			el.materialCube
																		}
																	</ChildTD>
																	<ChildTD>
																		{
																			el.documentNo
																		}
																	</ChildTD>
																	<ChildTD>
																		{
																			el.displayOrder
																		}
																	</ChildTD>
																	{el.batchNo && (
																		<ChildTD>
																			{
																				el.batchNo
																			}
																		</ChildTD>
																	)}
																</ChildTR>
															)
														)}
														<ChildTRFooter>
															<td
																scope="row"
																className="text-end p-2"
															></td>
															<td className="text-end p-2 fw-bold text-secondary">
																{t(
																	'lgsPurchaseOrder:totalAmount'
																)}
															</td>
															<td className="text-end p-2 fw-bold text-secondary">
																{
																	data.totalQuantityReceive
																}
															</td>
															<td className="text-end p-2 fw-bold text-secondary">
																{
																	data.totalUncollectedQuantity
																}
															</td>
														</ChildTRFooter>
													</tbody>
												</table>
											</td>
										</>
									)}
								</tr>
							</React.Fragment>
						))}
					</TBody>
				</IndexTable>
			</IndexTableContainer>
		</div>
	);
}

export default DetailAndGoodsTable;
