//import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BtnNormal2 } from '../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import FormControlInput from '../../../components/Inputs/FormControlInput';
import { PageTitleBar } from '../../../components/exports';
import { generateUpdateLogContent } from '../../../utils';
import { DialogConfirm } from './DialogConfirm';
import { SubmitConfirm } from './SubmitConfirm';

// #region styled-components

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
`;

const BtnNormal = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    height: 32px;
    margin-right: 8px;
    svg {
        margin-right: 8px;
    }
`;

const Wrapper = styled.div`
    min-height: 720px;
`;

const TextareaElement = styled.textarea`
    border: 0px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
`;

const Textarea = styled.div`
    border: 0px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
    background-color: var(--primaryllt);
    /* width: 600px;
    height: 160px; */
`;

const NumberInputElement = styled.input`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    -webkit-inner-spin-button,
    -webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:hover::-webkit-inner-spin-button,
    &:hover::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        opacity: 0;
    }

    &:focus::-webkit-inner-spin-button,
    &:focus::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        opacity: 0;
    }
`;
const InputElement = styled.input`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;

    &:focus-visible {
        outline: 0;
    }
    background-color: transparent;
    border: none;
    border: 1px solid #e3e8ee;
    cursor: auto;
`;

const SelectElement = styled.select`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;

    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    /* 下拉箭頭圖案 */
    background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
        linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
    background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
    background-size: 6px 6px, 6px 6px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
`;

const LabelNormal = styled.label`
    text-align: left;
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;

const NoInfoRow = styled.div`
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	margin-top: 16px;
	border-bottom: 1px solid var(--grey2);
	mb-3
`;

const DialogBtn = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 12px;
    cursor: pointer;
    height: 32px;
`; // dialog按鈕

const CancelInputBtn = styled.span`
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 3px;
    right: 32px;
    width: 24px;
    text-align: center;
    display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
    cursor: pointer;
`; // 取消按鈕

//#endregion

const LgsAppendixClass_Edit = () => {
    const { t } = useTranslation(['common', 'lgsAppendixClass']);
    const pageTitle = t('lgsAppendixClass:pageTitle');
    const appendixClassGuid = useLocation().pathname.split('/').pop();
    const navigate = useNavigate();
    const [data, setData] = useState({
        appendixClassGuid: '',
        appendixClassCode: '',
        name01: '',
        name02: '',
        name03: '',
        purchaseOrder: true,
        salesOrder: true,
        status: '',
    });
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
    const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
    const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
        useState(false);

    const [err, setErr] = useState(false);
    const [isAppendixClassCodeExisted, setIsAppendixClassCodeExisted] =
        useState(false);

    const [originalDataForLog, setOriginalDataForLog] = useState({
        // The parameters which were needed for logging page
        //異動紀錄需比對的欄位
        appendixClassCode: '',
        name01: '',
        name02: '',
        name03: '',
        status: '',
    });

    //這邊因為是 details 後端使用 root(/) 接，而不是使用 querry(?) 接
    //寫法1

    // useEffect(() => {
    // 	axios.get(`api/Logistics/LgsAppendixClass/getData`).then((res) => {
    // 		console.log('後端資料：', res.data); //要用 ,不可以使用+
    // 		// setPurchaseOrderNoRuleList(res.data.purchaseOrderNoRuleList);
    // 		//console.log(res.data.purchaseOrderNoRuleList);
    // 	});
    // }, []);

    useEffect(() => {
        axios(
            `api/Logistics/LgsAppendixClass/getDetails/${appendixClassGuid}`
        ).then((res) => {
            const newData = res.data;
            setData({ ...newData });
            console.log('newData:', newData);

            //要先設定 setOriginalDataForLog，否則 setOriginalDataForLog 為空
            const { appendixClassCode, name01, name02, name03, status } =
                newData;

            setOriginalDataForLog({
                appendixClassCode,
                name01,
                name02,
                name03,
                status,
            });

            // console.log('data:', data);
        });
    }, []);
    //console.log('data :', data);

    const handleActivate = () => {
        const updateContent = t('log.activate', {
            value: data.name,
        });

        console.log(updateContent);
        axios
            .put(`api/Logistics/LgsAppendixClass/activate`, {
                typeGuid: data.typeGuid,
                status: '1',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const handleInActivate = () => {
        const updateContent = t('log.lock', {
            value: data.name,
        });
        axios
            .put(`api/Logistics/LgsAppendixClass/activate`, {
                typeGuid: data.typeGuid,
                status: '2',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const handleDelete = () => {
        const updateContent = t('log.delete', {
            value: data.name,
        });
        axios
            .put(`api/Logistics/LgsAppendixClass/activate`, {
                typeGuid: data.typeGuid,
                status: '4',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    // const handleDialogConfirm = (
    // 	dialogNoRule,
    // 	dialogData,
    // 	dialogStartNo,
    // 	dialogIncrement
    // ) => {
    // 	setData({
    // 		...data,
    // 		...dialogData,
    // 		startNo: dialogStartNo,
    // 		incremental: dialogIncrement,
    // 	});

    // 	console.log(
    // 		dialogNoRule,
    // 		originalDataForLog,
    // 		dialogData,
    // 		dialogStartNo,
    // 		dialogIncrement
    // 	);

    // 	setNoRule(dialogNoRule);
    // };
    // const checkInputs = () => {
    // 	if (data?.appendixClassCode) {
    // 		setErr(false);
    // 		return setIsSubmitConfirmDialogOpen(true);
    // 	} else {
    // 		setErr(true);
    // 	}
    // };

    const checkInputs = () => {
        setIsAppendixClassCodeExisted(false);

        if (data?.appendixClassCode) {
            setErr(false);
            return data?.status === '1'
                ? handleSubmit('1')
                : setIsSubmitConfirmDialogOpen(true);
        } else {
            setErr(true);
        }
    };
    const handleSubmit = (status) => {
        console.log(data);
        console.log(originalDataForLog);

        const newData = { ...data, status: status };
        const updateContent = generateUpdateLogContent(
            'purchaseOrderAppendix',
            t,
            newData,
            originalDataForLog
        );

        const {
            appendixClassGuid,
            appendixClassCode,
            name01,
            name02,
            name03,
            rowStamp,
        } = data;

        if (updateContent) {
            axios
                .put(`api/Logistics/LgsAppendixClass/update`, {
                    appendixClassGuid,
                    appendixClassCode,
                    name01,
                    name02,
                    name03,
                    purchaseOrder: true,
                    salesOrder: true,
                    status,
                    rowStamp,
                    updateContent,
                })
                .then((res) => navigate(-1));
        } else {
            navigate(-1);
        }
    };

    console.log(data);

    const checkAppendixClassCode = async (e) => {
        console.log(e.target.value);
        await axios
            .get(
                `api/Logistics/LgsAppendixClass/verifyCode?appendixClassGuid=${appendixClassGuid}&AppendixClassCode=${e.target.value}`
            )
            .then((res) => {
                console.log('check代碼是否valid', res.data);
                setIsAppendixClassCodeExisted(false);
            })
            .catch((err) => {
                console.log(err);
                setIsAppendixClassCodeExisted(true);

                setData({
                    ...data,
                    appendixClassCode: '',
                });
            });
    };

    return (
        <>
            <div id="detailsView">
                <PageTitleBar
                    pageTitle={pageTitle}
                    previousPageButton={true}
                    backTo={-1}
                />

                {/* DialogConfirm element */}
                <DialogConfirm
                    isOpen={isActivateDialogOpen}
                    setIsOpen={setIsActivateDialogOpen}
                    handleClick={() => handleActivate()}
                    message={t('dialog.activeConfirm')}
                />
                <DialogConfirm
                    isOpen={isInActivateDialogOpen}
                    setIsOpen={setIsInActivateDialogOpen}
                    handleClick={() => handleInActivate()}
                    message={t('dialog.lockConfirm')}
                />
                <DialogConfirm
                    isOpen={isDeleteDialogOpen}
                    setIsOpen={setIsDeleteDialogOpen}
                    handleClick={() => handleDelete()}
                    message={t('dialog.deleteConfirm')}
                />
                <SubmitConfirm
                    t={t}
                    isOpen={isSubmitConfirmDialogOpen}
                    setIsOpen={setIsSubmitConfirmDialogOpen}
                    handleClickInactive={() => handleSubmit('0')}
                    handleClickActive={() => handleSubmit('1')}
                    message={t('dialog.saveConfirm')}
                />
                <Container>
                    <Left></Left>
                    <Center></Center>
                    <Right>
                        <BtnNormal2
                            className="btn-primary mediumBtn"
                            handleClick={() => checkInputs()}
                        >
                            <span>{t('button.saveButton')}</span>
                        </BtnNormal2>
                        <BtnNormal2
                            className="btn-outline-primary mediumBtn"
                            handleClick={() => navigate(-1)}
                        >
                            <span>{t('button.cancelButton')}</span>
                        </BtnNormal2>
                    </Right>
                </Container>

                <div className="position-relative">
                    <Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <FormControlInput
                                        id="appendixClassCode"
                                        labelText={t(
                                            'lgsAppendixClass:appendixClassCode'
                                        )}
                                        value={data.appendixClassCode}
                                        onChange={(e) => {
                                            const value =
                                                e.target.value.replace(
                                                    /[^a-zA-Z0-9]/g,
                                                    ''
                                                );
                                            setData({
                                                ...data,
                                                appendixClassCode:
                                                    e.target.value,
                                            });
                                        }}
                                        onBlur={checkAppendixClassCode}
                                        disabled={false}
                                        disabledCssType={0}
                                        isErr={err}
                                        errMsg="此欄位為必填欄位"
                                        isAppendixClassCodeExisted={
                                            isAppendixClassCodeExisted
                                        }
                                    />

                                    <div
                                        className={`text-danger mt-1 ${
                                            isAppendixClassCodeExisted
                                                ? 'd-block'
                                                : 'd-none'
                                        }`}
                                    >
                                        此代碼已存在請重新輸入
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 py-1 "></div>

                                <div className="col-lg-4 px-4 py-1 "></div>
                            </div>

                            <div className="row">
                                {/* #Col1---------------------  */}
                                <div className="col-lg-4 px-4 py-1 ">
                                    <FormControlInput
                                        id="name"
                                        labelText={t('lgsAppendixClass:name01')}
                                        value={data.name01}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                name01: e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                        isErr={err}
                                        errMsg="此欄位為必填欄位"
                                    />
                                </div>
                                {/* #endCol1---------------------  */}
                                <div className="row">
                                    {/* #Col2---------------------  */}
                                    <div className="col-lg-4 px-4 py-1 ">
                                        <FormControlInput
                                            id="name"
                                            labelText={t(
                                                'lgsAppendixClass:name02'
                                            )}
                                            value={data.name02}
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    name02: e.target.value,
                                                });
                                            }}
                                            disabled={false}
                                            disabledCssType={0}
                                            //isErr={err}
                                            errMsg="此欄位為必填欄位"
                                        />
                                    </div>
                                </div>
                                {/* #endCol2---------------------  */}

                                <div className="row">
                                    {/* #Col3---------------------  */}
                                    <div className="col-lg-4 px-4 py-1 ">
                                        <FormControlInput
                                            id="name"
                                            labelText={t(
                                                'lgsAppendixClass:name03'
                                            )}
                                            value={data.name03}
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    name03: e.target.value,
                                                });
                                            }}
                                            disabled={false}
                                            disabledCssType={0}
                                            //isErr={err}
                                            errMsg={t('errMsg.mustFill')}
                                        />
                                    </div>
                                    {/* #endCol3---------------------  */}
                                </div>
                            </div>
                        </div>
                    </Wrapper>
                </div>
            </div>
        </>
    );
};

export default LgsAppendixClass_Edit;
