import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Icn_OpenModal } from '../../../assets/images/icn_OpenModal.svg';
import { BtnNormal2 } from '../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import FormControlInput from '../../../components/Inputs/FormControlInput';
import { generateUpdateLogContent } from '../../../utils';
import { PageTitleBar } from '../LgsPurchaseOrder/exports';
import { DialogConfirm } from './DialogConfirm';
import {
    convertFromNumberingRuleToRuleForUser,
    getRulePreview,
    periodFormatDict,
} from './NumberingRule';
import NumberingRuleDialog from './NumberingRuleDialog';
import { SubmitConfirm } from './SubmitConfirm';

// #region styled-components
const LabelNormal = styled.label`
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;
const InputElement = styled.input`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    background-color: ${(prop) => prop.isDisabled && '#efefef4d'};
`;

const DialogBtn = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0px;
    padding-left: 16px;
    padding-right: 12px;

    height: 32px;

    background-color: ${(prop) => prop.isDisabled && '#efefef4d'};
    cursor: ${(prop) => !prop.isDisabled && 'pointer;'};
`; // dialog按鈕
const Wrapper = styled.div`
    min-height: 720px;

    border-radius: 0 0 0.75rem 0.75rem;
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
    display: flex;
`;
const Textarea = styled.div`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
    background-color: #efefef4d;
    /* width: 600px;
    height: 160px; */
`;
//#endregion

function LgsSalesOrderNoRule_Edit() {
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState(0);
    const [view, setView] = useState('edit');
    const pageTitle = t('lgsSalesOrderNoRule:pageTitle');
    const permission = localStorage.getItem('Permission_LgsSalesOrderNoRule');
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const noRuleGuid = useParams().guid;

    // console.log(lang, t);

    // const { t, i18n } = useTranslation();
    // numbering rule 的參數
    const [data, setData] = useState({
        // 非 numbering rule 組成參數
        name: '',
        noRuleGuid: '',
        status: '',
        rowStamp: '',

        // numbering rule 組成參數
        prefix: '', //前綴
        period: '', //週期
        separator: '', //分隔
        suffix: '', //後綴
        length: '', //長度
        startNo: '', //啟始值
        incremental: '', //增量值
        length: 0, //連續號碼長度
    });

    // 真正的 numbering rule
    const [noRule, setNoRule] = useState('');

    const [originalDataForLog, setOriginalDataForLog] = useState({
        // The parameters which were needed for logging page
        name: '',
        noRule: '',
        startNo: '',
        incremental: '',
        status: '',
    });

    // 給使用者看的 numbering rule

    const [ruleForUser, setRuleForUser] = useState('');

    // numbering rule 的預期結果示範
    const [preview, setPreview] = useState([]);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
    const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
    const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
        useState(false);

    const [err, setErr] = useState(false);

    const handleActivate = () => {
        const updateContent = t('log.activate', {
            value: data.name,
        });

        console.log(updateContent);

        console.log(data);

        axios
            .put(`api/Logistics/LgsSalesOrderNoRule/activate`, {
                noRuleGuid: data.noRuleGuid,
                name: data.name,
                noRule: data.noRule,
                startNo: data.startNo,
                incremental: data.incremental,
                status: '1',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const handleInActivate = () => {
        const updateContent = t('log.lock', {
            value: data.name,
        });

        axios
            .put(`api/Logistics/LgsSalesOrderNoRule/activate`, {
                noRuleGuid: data.noRuleGuid,
                name: data.name,
                noRule: data.noRule,
                startNo: data.startNo,
                incremental: data.incremental,
                status: '2',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const handleDelete = () => {
        const updateContent = t('log.delete', {
            value: data.name,
        });

        axios
            .put(`api/Logistics/LgsSalesOrderNoRule/activate`, {
                noRuleGuid: data.noRuleGuid,
                name: data.name,
                noRule: data.noRule,
                startNo: data.startNo,
                incremental: data.incremental,
                status: '4',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const handleDialogConfirm = (
        dialogNoRule,
        dialogData,
        dialogStartNo,
        dialogIncrement
    ) => {
        setData({
            ...data,
            ...dialogData,
            startNo: dialogStartNo,
            incremental: dialogIncrement,
        });

        console.log(
            dialogNoRule,
            originalDataForLog.noRule,
            dialogNoRule !== originalDataForLog.noRule
        );

        setNoRule(dialogNoRule);
    };

    const checkInputs = () => {
        if (data?.name) {
            setErr(false);
            return data?.status === '1'
                ? handleSubmit('1')
                : setIsSubmitConfirmDialogOpen(true);
        } else {
            setErr(true);
        }
    };

    const handleSubmit = (status) => {
        console.log(data);
        //console.log(originalDataForLog);

        const newData = { ...data, status: status };
        const updateContent = generateUpdateLogContent(
            'purchaseOrderNoRule',
            t,
            newData,
            originalDataForLog
        );

        const { name, noRuleGuid, startNo, incremental, rowStamp } = data;

        if (updateContent) {
            axios
                .put(`api/Logistics/LgsSalesOrderNoRule/update`, {
                    name,
                    noRule,
                    noRuleGuid,
                    startNo,
                    incremental,
                    status,
                    rowStamp,
                    updateContent,
                })
                .then((res) => navigate(-1));
        } else {
            navigate(-1);
        }
    };

    // 取得使用者資料
    useEffect(() => {
        axios(
            `api/Logistics/LgsSalesOrderNoRule/getDetails/${noRuleGuid}`
        ).then((response) => {
            console.log(response.data.noRule);
            const {
                // displayOrder, // 沒有用到
                incremental,
                name,
                noRule,
                noRuleGuid,
                rowStamp,
                startNo,
                status,
            } = response.data;

            // 存放 numbering rule data
            setData({
                ...data,
                noRuleGuid: noRuleGuid,
                status: status,
                rowStamp: rowStamp,
                startNo: startNo,
                name: name,
                incremental: incremental,
                noRule: noRule,
            });

            setOriginalDataForLog({
                name: name,
                noRule: noRule,
                startNo: startNo,
                incremental: incremental,
                status: status,
            });

            // 存放 numbering rule(db)
            setNoRule(noRule);
        });
    }, []);

    // 計算 numbering rule(UI) & 產生 preview
    useEffect(() => {
        // 把 numbering rule(db) 拆解
        const {
            prefix,
            suffix,
            timeSeparator,
            format,
            content,
            period,
            length,
        } = convertFromNumberingRuleToRuleForUser(noRule, data.startNo);

        // 把拆解後的參數, 更新到 data 裡
        setData({
            ...data,
            prefix: prefix,
            suffix: suffix,
            timeSeparator: timeSeparator,
            period: period,
            length: length,
            format: format,
            noRule: noRule,
        });

        const noRuleForUser = `${prefix}${format}${timeSeparator}${content}${suffix}`;
        setRuleForUser(noRuleForUser);

        const rule = {
            prefix,
            timeSeparator,
            format: periodFormatDict[period],
            startNo: data.startNo,
            incremental: data.incremental,
            suffix,
        };

        // 產生 preview
        const preview = getRulePreview(rule);
        setPreview(preview);
    }, [noRule, data.startNo, data.incremental]);

    return (
        <>
            <div id="detailsView">
                <PageTitleBar
                    pageTitle={pageTitle}
                    previousPageButton={true}
                    backTo={-1}
                    // isDialogConfirm={true}
                    // isDialogConfirm={true}
                    // handleDialogConfirm={handleDialogConfirm}
                />
                {/* DialogConfirm element */}
                <DialogConfirm
                    t={t}
                    isOpen={isActivateDialogOpen}
                    setIsOpen={setIsActivateDialogOpen}
                    handleClick={() => handleActivate()}
                    message={t('dialog.activeConfirm')}
                />
                <DialogConfirm
                    t={t}
                    isOpen={isInActivateDialogOpen}
                    setIsOpen={setIsInActivateDialogOpen}
                    handleClick={() => handleInActivate()}
                    message={t('dialog.lockConfirm')}
                />
                <DialogConfirm
                    t={t}
                    isOpen={isDeleteDialogOpen}
                    setIsOpen={setIsDeleteDialogOpen}
                    handleClick={() => handleDelete()}
                    message={t('dialog.deleteConfirm')}
                />
                <SubmitConfirm
                    t={t}
                    isOpen={isSubmitConfirmDialogOpen}
                    setIsOpen={setIsSubmitConfirmDialogOpen}
                    handleClickInactive={() => handleSubmit('0')}
                    handleClickActive={() => handleSubmit('1')}
                    message={t('dialog.saveConfirm')}
                />
                <Container>
                    <Left></Left>
                    <Center></Center>
                    <Right>
                        <BtnNormal2
                            className="btn-primary mediumBtn"
                            handleClick={() => checkInputs()}
                        >
                            {t('button.saveButton')}
                        </BtnNormal2>
                        <BtnNormal2
                            className="btn-outline-primary mediumBtn"
                            handleClick={() => navigate(-1)}
                        >
                            {t('button.cancelButton')}
                        </BtnNormal2>
                    </Right>
                </Container>
                <div className="position-relative">
                    <Wrapper className="bg-whit d-flex flex-column p-4 position-relative bg-white">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <FormControlInput
                                        id="name"
                                        labelText={t(
                                            'lgsSalesOrderNoRule:name'
                                        )}
                                        value={data.name}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                name: e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                        isErr={err}
                                        errMsg={t('errMsg.mustFill')}
                                    />
                                    {/* <InputWithDialog
                                        id="noRule"
                                        labelText={t('lgsPurchaseOrderNoRule:noRule')}
                                        value={ruleForUser}
                                        onChange={() => {}}
                                        disabled={
                                            data?.status !== '0' ? true : false
                                        }
                                        disabledCssType={0}
                                        handleDialogShow={() => {
                                            setModalOpen(true);
                                        }}
                                    /> */}
                                    <div
                                        className={`wrapper d-flex flex-column mb-3`}
                                    >
                                        <LabelNormal>
                                            {t('lgsSalesOrderNoRule:noRule')}
                                        </LabelNormal>
                                        <div
                                            className="input-group vendorGuidInput"
                                            id="vendorGuidInput"
                                        >
                                            <InputElement
                                                className="form-control border-right-0 item-view-noRule border-grey2"
                                                placeholder=""
                                                value={ruleForUser}
                                                onChange={() => {}}
                                                type="text"
                                                id="noRule"
                                                name="noRule"
                                                style={{
                                                    cursor: 'pointer',
                                                    pointerEvents: 'none',
                                                    borderRight: 0,
                                                }}
                                                isDisabled={
                                                    data?.status !== '0'
                                                }
                                            />

                                            <DialogBtn
                                                // style={{
                                                //     backgroundColor:
                                                //         '#efefef4d',
                                                //     cursor: 'pointer',
                                                // }}
                                                isDisabled={
                                                    data?.status !== '0'
                                                }
                                                onClick={() => {
                                                    data?.status !== '0'
                                                        ? ''
                                                        : setModalOpen(true);
                                                }}
                                            >
                                                <Icn_OpenModal />
                                            </DialogBtn>
                                        </div>
                                    </div>
                                    <FormControlInput
                                        id="startNo"
                                        labelText={t(
                                            'lgsSalesOrderNoRule:startNo'
                                        )}
                                        type="number"
                                        value={data?.startNo}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                startNo: e.target.value,
                                            });
                                        }}
                                        disabled={
                                            data?.status !== '0' ? true : false
                                        }
                                        disabledCssType={0}
                                    />
                                    <FormControlInput
                                        id="incremental"
                                        labelText={t(
                                            'lgsSalesOrderNoRule:incremental'
                                        )}
                                        value={data.incremental}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                incremental: e.target.value,
                                            });
                                        }}
                                        disabled={
                                            data?.status !== '0' ? true : false
                                        }
                                        disabledCssType={0}
                                    />
                                    <LabelNormal>
                                        {t('lgsSalesOrderNoRule:preview')}
                                    </LabelNormal>
                                    <Textarea disabled>
                                        <div className="mb-1">{preview[0]}</div>
                                        <div className="mb-1">{preview[1]}</div>
                                        <div className="mb-1">{preview[2]}</div>
                                    </Textarea>
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-5"></div>
                                <div className="col-lg-4 px-4 pr-xl-6"></div>
                            </div>
                        </div>
                    </Wrapper>
                </div>
            </div>
            <NumberingRuleDialog
                modalOpen={modalOpen}
                handleClose={() => {
                    setModalOpen(false);
                }}
                data={{
                    ...data,
                    prefix: data.prefix?.replace(new RegExp('"', 'g'), ''),
                    suffix: data.suffix?.replace(new RegExp('"', 'g'), ''),
                }}
                setData={setData}
                noRule={noRule}
                preview={preview}
                setPreview={setPreview}
                handleSubmit={handleDialogConfirm}
                t={t}
            />
        </>
    );
}

export default LgsSalesOrderNoRule_Edit;
