import React from 'react';
import styled from 'styled-components';
import { LabelNormal } from './shared/LabelNormal';
import { disabledStyle } from './shared/DisabledStyle';
import { ErrMsg } from './shared/ErrMsg';
import { ReactComponent as Icn_OpenModal } from '../../assets/images/icn_OpenModal.svg';
// 使用範例
/* 
<Input
	wrapperClass="mb-3" // 外層class，可用來推下面margin
	labelText="年齡" // label文字
	type="number" // input型態
	id="age" // input id&name ; label htmlFor
	placeholder="請填寫年齡"
	disabled={false}
	disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
	register={register} // react-hook-form
	validation={{
		// 驗證邏輯
		required: 'must be required',
		min: { value: 0, message: '>0' },
		max: { value: 100, message: '<100' },
	}}
	isErr={errors.age} // 是否顯示錯誤訊息
	errMsg={errors.age?.message} // 錯誤訊息
	onChange={(e) => {
		setValue('age', e.target.value);
		const ageValue = getValues('age');
		// console.log(ageValue);
	}}
/>
*/

const InputElement = styled.input`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    ${disabledStyle}
`;

const DialogBtn = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 12px;
    cursor: pointer;
    height: 32px;

    ${disabledStyle}
`; // dialog按鈕

function InputWithDialog({
    labelText,
    elementStyle,
    type,
    value,
    placeholder,
    onChange,
    isErr,
    errMsg,
    wrapperClass,
    disabled,
    disabledCssType,
    handleDialogShow,
    id,
}) {
    return (
        <div className={`wrapper d-flex flex-column mb-3 ${wrapperClass}`}>
            <LabelNormal htmlFor={id}>{labelText}</LabelNormal>
            <div className="input-group vendorGuidInput" id="currencyCode">
                <InputElement
                    placeholder={placeholder}
                    onChange={() => {}}
                    value={value}
                    type="text"
                    id="currencyCode"
                    name="currencyCode"
                    style={{
                        cursor: 'pointer',
                        pointerEvents: 'none',
                        borderRight: 0,
                        width: '86%',
                    }}
                    disabled={disabled}
                    disabledCssType={disabledCssType}
                />
                <DialogBtn
                    style={{
                        backgroundColor: '#fff',
                        cursor: 'pointer',
                        width: '14%',
                        backgroundColor: `${disabled && '#EFEFEF4D'}`,
                    }}
                    onClick={() => {
                        handleDialogShow();
                    }}
                    disabled={disabled}
                    disabledCssType={disabledCssType}
                >
                    <Icn_OpenModal />
                </DialogBtn>
            </div>
            <span className="text-danger ms-1 mt-1"></span>
        </div>
    );
}

export default InputWithDialog;
