import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../assets/images/icn_inputDialog.svg';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

// #region styled-components
const ModalWrapper = styled.div`
	/* position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	height: 100%; */
`;

const ModalDialog = styled.div`
	/* max-width: 1000px !important;
	min-height: calc(100% - 3.5rem); */
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
	text-align: left;
`;

const InputElement = styled.input`
	border: ${(props) =>
		props.isDetailView ? 'none' : '1px solid var(--grey2)'};
	border-bottom: ${(props) =>
		props.isDetailView
			? '1px solid var(--grey1)'
			: '1px solid var(--grey2)'};
	border-radius: ${(props) => (props.isDetailView ? 'none' : '4px')};
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: ${(props) =>
			props.isDetailView ? 'transparent' : '#eff2f5'};
	}

	&[type='number']::-webkit-inner-spin-button,
	&[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const SelectElement = styled.select`
	border: ${(props) =>
		props.isDetailView ? 'none' : '1px solid var(--grey2)'};
	border-bottom: ${(props) =>
		props.isDetailView
			? '1px solid var(--grey1)'
			: '1px solid var(--grey2)'};
	border-radius: ${(props) => (props.isDetailView ? 'none' : '4px')};

	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: ${(props) =>
			props.isDetailView ? 'transparent' : '#eff2f5'};
	}

	/* 下拉箭頭圖案 */
	background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
		linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
	background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
	background-size: ${(props) =>
		props.isDetailView ? '0px' : '6px 6px, 6px 6px'};
	background-repeat: no-repeat;
	-webkit-appearance: none;
`;

// -----Dialog------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	height: 25px;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: ${(props) =>
		props.isDetailView
			? '1px solid var(--grey1)'
			: '1px solid var(--grey2)'};
	border-right: ${(props) =>
		props.isDetailView ? '1px solid var(--grey1)' : 0};
	border-radius: ${(props) => (props.isDetailView ? '4px' : '4px 0 0 4px')};
	padding: 6px 8px;
	height: 25px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: ${(props) =>
		props.isDetailView ? '#eff2f5' : 'transparent'};
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 24px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

// #endregion

export const DialogInvoice = ({
	isOpen,
	firstDialogName,
	backEndData,
	invoiceDialogData,
	setInvoiceDialogData,
	tableBodyData,
	decimalPlaceTl,
	isDetailView,
}) => {
	//#region state initialization

	const { t } = useTranslation(['common', 'lgsPurchaseOrderInvoiceReturn']); // i18n

	// prop
	const { invoiceFormatTypeList, invoiceTaxRateTypeList } = backEndData;
	console.log(backEndData);
	console.log('tableBodyData', tableBodyData);
	console.log('invoiceDialogData', invoiceDialogData);

	// cookies
	const [cookies] = useCookies();
	let countryCode = cookies._company_country_code;
	// countryCode = 'CN';

	//#endregion

	return (
		<>
			{isOpen && (
				<ModalWrapper>
					<ModalDialog
						className="d-flex align-items-center"
						onClick={(e) => e.stopPropagation()}
					>
						<div className="modal-content border-0 bg-white">
							<div
								className="modal-header px-4 pt-4 pb-3"
								style={{
									borderBottom: '1px solid var(--grey2)',
								}}
							>
								<h5 className="text-primary fw-bold fontSize125Rem mb-0">
									{firstDialogName === 'govInvoiceBtn'
										? t(
												'lgsPurchaseOrderInvoiceReturn:invoiceDetails'
										  )
										: firstDialogName === 'otherInvoiceBtn'
										? t(
												'lgsPurchaseOrderInvoiceReturn:otherCertificate'
										  )
										: ''}
								</h5>
							</div>

							<div className="modal-body text-center pt-4 px-6">
								<section>
									<div className="row mb-3">
										<div className="col-lg-3">
											<div className="d-flex flex-column">
												<LabelNormal
													htmlFor={`vatControl`}
												>
													{t(
														'common:invoiceDialog.vatControl'
													)}
												</LabelNormal>
												<SelectElement
													id={'vatControl'}
													value={
														invoiceDialogData.vatControl
													}
													isDetailView={isDetailView}
													disabled
													onChange={() => {
														setInvoiceDialogData({
															...invoiceDialogData,
															vatControl:
																event.target
																	.value,
														});
													}}
												>
													<option value="1">
														{t(
															'common:invoiceDialog.input'
														)}
													</option>
													<option value="2">
														{t(
															'common:invoiceDialog.output'
														)}
													</option>
												</SelectElement>
											</div>
										</div>
										<div className="col-lg-3">
											<div className="d-flex flex-column">
												<LabelNormal
													htmlFor={`invoiceDate`}
												>
													{firstDialogName ===
													'govInvoiceBtn'
														? t(
																'common:invoiceDialog.invoiceDate'
														  )
														: t(
																'common:invoiceDialog.invoiceDate2'
														  )}
												</LabelNormal>
												<InputElement
													id={'invoiceDate'}
													type="date"
													value={
														invoiceDialogData.invoiceDate
													}
													isDetailView={isDetailView}
													disabled={isDetailView}
													onChange={() => {
														setInvoiceDialogData({
															...invoiceDialogData,
															invoiceDate:
																event.target
																	.value,
															invoiceDateWarning:
																'',
														});
													}}
												></InputElement>
												<span className="text-danger text-start ms-1 my-1">
													{
														invoiceDialogData.invoiceDateWarning
													}
												</span>
											</div>
										</div>

										{countryCode === 'CN' && (
											<div className="col-lg-3">
												<div className="d-flex flex-column">
													<LabelNormal
														htmlFor={`invoiceCode`}
													>
														{firstDialogName ===
														'govInvoiceBtn'
															? t(
																	'common:invoiceDialog.invoiceCode'
															  )
															: t(
																	'common:invoiceDialog.invoiceCode2'
															  )}
													</LabelNormal>
													<InputElement
														id={'invoiceCode'}
														value={
															invoiceDialogData.invoiceCode
														}
														isDetailView={
															isDetailView
														}
														disabled={isDetailView}
														onChange={() => {
															setInvoiceDialogData(
																{
																	...invoiceDialogData,
																	invoiceCode:
																		event
																			.target
																			.value,
																}
															);
														}}
													></InputElement>
												</div>
											</div>
										)}

										<div className="col-lg-3">
											<div className="d-flex flex-column">
												<LabelNormal
													htmlFor={`invoiceNo`}
												>
													{firstDialogName ===
													'govInvoiceBtn'
														? t(
																'common:invoiceDialog.invoiceNo'
														  )
														: t(
																'common:invoiceDialog.invoiceNo2'
														  )}
												</LabelNormal>
												<InputElement
													id={'invoiceNo'}
													value={
														invoiceDialogData.invoiceNo
													}
													isDetailView={isDetailView}
													disabled={isDetailView}
													onChange={() => {
														setInvoiceDialogData({
															...invoiceDialogData,
															invoiceNo:
																event.target
																	.value,
														});
													}}
												></InputElement>
											</div>
										</div>
									</div>
									<div className="row mb-3">
										<div className="col-lg-3">
											<div className="d-flex flex-column">
												{firstDialogName ===
												'govInvoiceBtn' ? (
													<>
														<LabelNormal
															htmlFor={`vatInvoiceFormat`}
														>
															{t(
																'common:invoiceDialog.vatInvoiceFormat'
															)}
														</LabelNormal>
														<SelectElement
															id={
																'vatInvoiceFormat'
															}
															value={
																invoiceDialogData.vatInvoiceFormat
															}
															isDetailView={
																isDetailView
															}
															disabled={
																isDetailView
															}
															onChange={() => {
																const vatInvoiceFormatViewName =
																	invoiceFormatTypeList.find(
																		(el) =>
																			el.formatTypeGuid ===
																			event
																				.target
																				.value
																	).name01;

																setInvoiceDialogData(
																	{
																		...invoiceDialogData,
																		vatInvoiceFormat:
																			event
																				.target
																				.value,

																		vatInvoiceFormatViewName:
																			vatInvoiceFormatViewName,
																	}
																);
															}}
														>
															{invoiceFormatTypeList.map(
																(el) => {
																	return (
																		<option
																			key={
																				el.formatTypeGuid
																			}
																			value={
																				el.formatTypeGuid
																			}
																		>
																			{
																				el.name01
																			}
																		</option>
																	);
																}
															)}
														</SelectElement>
													</>
												) : firstDialogName ===
												  'otherInvoiceBtn' ? (
													<>
														<LabelNormal
															htmlFor={`vatInvoiceFormat`}
														>
															{t(
																'common:invoiceDialog.vatInvoiceFormat2'
															)}
														</LabelNormal>
														<InputElement
															id={
																'vatInvoiceFormat'
															}
															value={
																invoiceDialogData.vatInvoiceFormat
															}
															isDetailView={
																isDetailView
															}
															disabled={
																isDetailView
															}
															onChange={() => {
																setInvoiceDialogData(
																	{
																		...invoiceDialogData,
																		vatInvoiceFormat:
																			event
																				.target
																				.value,
																	}
																);
															}}
														></InputElement>
													</>
												) : (
													<></>
												)}
											</div>
										</div>
										<div className="col-lg-3">
											<div className="row">
												<div className="col-7">
													<div className="d-flex flex-column">
														<LabelNormal
															htmlFor={`vatInvoiceTaxType`}
														>
															{t(
																'common:invoiceDialog.vatInvoiceTaxType'
															)}
														</LabelNormal>
														<SelectElement
															id={
																'vatInvoiceTaxType'
															}
															value={
																invoiceDialogData.vatInvoiceTaxType
															}
															isDetailView={
																isDetailView
															}
															disabled={
																isDetailView
															}
														>
															{invoiceTaxRateTypeList.map(
																(el) => {
																	return (
																		<option
																			key={
																				el.rateTypeGuid
																			}
																			value={
																				el.rateTypeGuid
																			}
																		>
																			{
																				el.name01
																			}
																		</option>
																	);
																}
															)}
														</SelectElement>
													</div>
												</div>
												<div className="col-4 px-0 d-flex">
													<InputElement
														id={'vatRate'}
														className="w-100 mt-4"
														value={
															Number.isInteger(
																invoiceTaxRateTypeList.find(
																	(el) => {
																		return (
																			el.rateTypeGuid ===
																			invoiceDialogData.vatInvoiceTaxType
																		);
																	}
																).taxRate
															)
																? invoiceTaxRateTypeList
																		.find(
																			(
																				el
																			) =>
																				el.rateTypeGuid ===
																				invoiceDialogData.vatInvoiceTaxType
																		)
																		.taxRate.toFixed(
																			0
																		)
																: invoiceTaxRateTypeList
																		.find(
																			(
																				el
																			) =>
																				el.rateTypeGuid ===
																				invoiceDialogData.vatInvoiceTaxType
																		)
																		.taxRate.toFixed(
																			decimalPlaceTl
																		)
														}
														isDetailView={
															isDetailView
														}
														disabled
													></InputElement>
													<span className="ps-2 mt-5">
														%
													</span>
												</div>
											</div>
										</div>
										{countryCode === 'TW' && (
											<div className="col-lg-3">
												<div className="d-flex flex-column">
													<LabelNormal
														htmlFor={`vatInputTaxable`}
													>
														{t(
															'common:invoiceDialog.vatInputTaxable'
														)}
													</LabelNormal>
													<SelectElement
														id={'vatInputTaxable'}
														value={
															invoiceDialogData.vatInputTaxable
														}
														isDetailView={
															isDetailView
														}
														disabled={isDetailView}
														onChange={() => {
															setInvoiceDialogData(
																{
																	...invoiceDialogData,
																	vatInputTaxable:
																		event
																			.target
																			.value,
																}
															);
														}}
													>
														<option value="0">
															{t(
																'common:invoiceDialog.nonDeductible'
															)}
														</option>
														<option value="1">
															{t(
																'common:invoiceDialog.deductible'
															)}
														</option>
													</SelectElement>
												</div>
											</div>
										)}
									</div>
									<div className="row">
										<div className="col-lg-3">
											<div className="d-flex flex-column">
												<LabelNormal
													htmlFor={`netAmount`}
												>
													{t(
														'common:invoiceDialog.netAmount'
													)}
												</LabelNormal>
												<InputElement
													id={'netAmount'}
													type="number"
													value={
														invoiceDialogData.netAmount
													}
													isDetailView={isDetailView}
													disabled={isDetailView}
												></InputElement>
												<span className="text-danger text-start ms-1 my-1">
													{
														invoiceDialogData.netAmountWarning
													}
												</span>
											</div>
										</div>
										<div className="col-lg-3">
											<div className="d-flex flex-column">
												<LabelNormal
													htmlFor={`taxAmount`}
												>
													{t(
														'common:invoiceDialog.taxAmount'
													)}
												</LabelNormal>
												<InputElement
													id={'taxAmount'}
													type="number"
													value={
														invoiceDialogData.taxAmount
													}
													isDetailView={isDetailView}
													disabled={isDetailView}
												></InputElement>
												<span className="text-danger text-start ms-1 my-1">
													{
														invoiceDialogData.taxAmountWarning
													}
												</span>
											</div>
										</div>
										<div className="col-lg-3">
											<div className="d-flex flex-column">
												<LabelNormal
													htmlFor={`totalAmount`}
												>
													{t(
														'common:invoiceDialog.totalAmount'
													)}
												</LabelNormal>
												<InputElement
													id={'totalAmount'}
													value={
														invoiceDialogData.totalAmount
													}
													isDetailView={isDetailView}
													disabled
													onChange={() => {}}
												></InputElement>
											</div>
										</div>
										<div className="col-lg-3">
											<div className="d-flex flex-column">
												<LabelNormal
													htmlFor={`currencyName`}
												>
													{t(
														'common:invoiceDialog.currencyName'
													)}
												</LabelNormal>
												<InputElement
													id={'currencyName'}
													value={
														invoiceDialogData.currencyName
													}
													isDetailView={isDetailView}
													disabled
												></InputElement>
											</div>
										</div>
									</div>
								</section>
								<hr
									className="mt-5 mb-4"
									style={{
										border: `1px solid var(--grey2)`,
									}}
								/>
								<section className="row">
									<div className="col-lg-6">
										<div className="row mb-3">
											<div className="col-lg-3">
												<h6 className="my-2 fw-bold text-start fontSize875Rem">
													{t(
														'common:invoiceDialog.purchaser'
													)}
												</h6>
											</div>
											<div className="col-lg-9">
												<DialogWrapper className="h-100 row flex-nowrap">
													<InputDialogElementData
														id="purchaserCustomerGuid"
														type="text"
														value={
															invoiceDialogData.purchaserCustomerGuid
														}
														onChange={() => {}}
													/>
													<InputDialogElementView
														id="purchaserCustomerViewName"
														className="h-100"
														style={{
															minWidth: '85px',
															cursor: 'auto',
														}}
														value={
															invoiceDialogData.purchaserCustomerViewName
														}
														isDetailView={
															isDetailView
														}
														onChange={() => {}}
													/>
													{isDetailView ? (
														<></>
													) : (
														<>
															<CancelInputBtn
																isValueExist={
																	invoiceDialogData.purchaserCustomerViewName &&
																	true
																}
															>
																<Icn_InputYellowCancel />
															</CancelInputBtn>

															<DialogBtn
																style={{
																	cursor: 'pointer',
																}}
															>
																<Icn_InputDialog />
															</DialogBtn>
														</>
													)}
												</DialogWrapper>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-lg-5">
												<div className="d-flex flex-column">
													<LabelNormal
														htmlFor={`purchaserTaxID`}
													>
														{countryCode == 'TW'
															? t(
																	'common:invoiceDialog.UBN'
															  )
															: countryCode ==
															  'CN'
															? t(
																	'common:invoiceDialog.TIN'
															  )
															: ''}
													</LabelNormal>
													<InputElement
														id={'purchaserTaxID'}
														value={
															invoiceDialogData.purchaserTaxID
														}
														isDetailView={
															isDetailView
														}
														disabled={isDetailView}
														onChange={() => {
															setInvoiceDialogData(
																{
																	...invoiceDialogData,
																	purchaserTaxID:
																		event
																			.target
																			.value,
																}
															);
														}}
													></InputElement>
												</div>
											</div>
											<div className="form-group col-lg-7">
												<div className="d-flex flex-column">
													<LabelNormal
														htmlFor={`purchaserTaxName`}
													>
														{t(
															'common:invoiceDialog.name'
														)}
													</LabelNormal>
													<InputElement
														id={'purchaserTaxName'}
														value={
															invoiceDialogData.purchaserTaxName
														}
														isDetailView={
															isDetailView
														}
														disabled={isDetailView}
														onChange={() => {
															setInvoiceDialogData(
																{
																	...invoiceDialogData,
																	purchaserTaxName:
																		event
																			.target
																			.value,
																}
															);
														}}
													></InputElement>
												</div>
											</div>
										</div>
										{countryCode === 'CN' && (
											<>
												<div className="row my-3">
													<div className="d-flex flex-column">
														<LabelNormal
															htmlFor={`purchaserAddTel`}
														>
															{t(
																'common:invoiceDialog.addressAndPhone'
															)}
														</LabelNormal>
														<InputElement
															id={
																'purchaserAddTel'
															}
															value={
																invoiceDialogData.purchaserAddTel
															}
															isDetailView={
																isDetailView
															}
															disabled={
																isDetailView
															}
															onChange={() => {
																setInvoiceDialogData(
																	{
																		...invoiceDialogData,
																		purchaserAddTel:
																			event
																				.target
																				.value,
																	}
																);
															}}
														></InputElement>
													</div>
												</div>
												<div className="row">
													<div className="d-flex flex-column">
														<LabelNormal
															htmlFor={`purchaserBankAccount`}
														>
															{t(
																'common:invoiceDialog.bankAndAccount'
															)}
														</LabelNormal>
														<InputElement
															id={
																'purchaserBankAccount'
															}
															value={
																invoiceDialogData.purchaserBankAccount
															}
															isDetailView={
																isDetailView
															}
															disabled={
																isDetailView
															}
															onChange={() => {
																setInvoiceDialogData(
																	{
																		...invoiceDialogData,
																		purchaserBankAccount:
																			event
																				.target
																				.value,
																	}
																);
															}}
														></InputElement>
													</div>
												</div>
											</>
										)}
									</div>
									<div className="col-lg-6">
										<div className="row mb-3">
											<div className="col-lg-3">
												<h6 className="my-2 fw-bold text-start fontSize875Rem">
													{t(
														'common:invoiceDialog.seller'
													)}
												</h6>
											</div>
											<div className="col-lg-9">
												<DialogWrapper className="h-100 row flex-nowrap">
													<InputDialogElementData
														id="salesVendorGuid"
														type="text"
														value={
															invoiceDialogData.salesVendorGuid
														}
														onChange={() => {}}
													/>
													<InputDialogElementView
														id="salesVendorViewName"
														className="h-100"
														style={{
															minWidth: '85px',
															cursor: 'auto',
														}}
														value={
															invoiceDialogData.salesVendorViewName
														}
														isDetailView={
															isDetailView
														}
														onChange={() => {}}
													/>
													{isDetailView ? (
														<></>
													) : (
														<>
															<CancelInputBtn
																isValueExist={
																	invoiceDialogData.salesVendorViewName &&
																	true
																}
															>
																<Icn_InputYellowCancel />
															</CancelInputBtn>

															<DialogBtn
																style={{
																	cursor: 'pointer',
																}}
															>
																<Icn_InputDialog />
															</DialogBtn>
														</>
													)}
												</DialogWrapper>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-lg-5">
												<div className="d-flex flex-column">
													<LabelNormal
														htmlFor={`salesTaxID`}
													>
														{countryCode == 'TW'
															? t(
																	'common:invoiceDialog.UBN'
															  )
															: countryCode ==
															  'CN'
															? t(
																	'common:invoiceDialog.TIN'
															  )
															: ''}
													</LabelNormal>
													<InputElement
														id={'salesTaxID'}
														value={
															invoiceDialogData.salesTaxID
														}
														isDetailView={
															isDetailView
														}
														disabled={isDetailView}
														onChange={() => {
															setInvoiceDialogData(
																{
																	...invoiceDialogData,
																	salesTaxID:
																		event
																			.target
																			.value,
																}
															);
														}}
													></InputElement>
												</div>
											</div>
											<div className="form-group col-lg-7">
												<div className="d-flex flex-column">
													<LabelNormal
														htmlFor={`salesTaxName`}
													>
														{t(
															'common:invoiceDialog.name'
														)}
													</LabelNormal>
													<InputElement
														id={'salesTaxName'}
														value={
															invoiceDialogData.salesTaxName
														}
														isDetailView={
															isDetailView
														}
														disabled={isDetailView}
														onChange={() => {
															setInvoiceDialogData(
																{
																	...invoiceDialogData,
																	salesTaxName:
																		event
																			.target
																			.value,
																}
															);
														}}
													></InputElement>
												</div>
											</div>
										</div>

										{countryCode === 'CN' && (
											<>
												<div className="row my-3">
													<div className="d-flex flex-column">
														<LabelNormal
															htmlFor={`salesAddTel`}
														>
															{t(
																'common:invoiceDialog.addressAndPhone'
															)}
														</LabelNormal>
														<InputElement
															id={'salesAddTel'}
															value={
																invoiceDialogData.salesAddTel
															}
															isDetailView={
																isDetailView
															}
															disabled={
																isDetailView
															}
															onChange={() => {
																setInvoiceDialogData(
																	{
																		...invoiceDialogData,
																		salesAddTel:
																			event
																				.target
																				.value,
																	}
																);
															}}
														></InputElement>
													</div>
												</div>
												<div className="row">
													<div className="d-flex flex-column">
														<LabelNormal
															htmlFor={`salesBankAccount`}
														>
															{t(
																'common:invoiceDialog.bankAndAccount'
															)}
														</LabelNormal>
														<InputElement
															id={
																'salesBankAccount'
															}
															value={
																invoiceDialogData.salesBankAccount
															}
															isDetailView={
																isDetailView
															}
															disabled={
																isDetailView
															}
															onChange={() => {
																setInvoiceDialogData(
																	{
																		...invoiceDialogData,
																		salesBankAccount:
																			event
																				.target
																				.value,
																	}
																);
															}}
														></InputElement>
													</div>
												</div>
											</>
										)}
									</div>
								</section>
							</div>
						</div>
					</ModalDialog>
				</ModalWrapper>
			)}
		</>
	);
};
