import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BtnNormal2 } from '../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import FormControlInput from '../../../components/Inputs/FormControlInput';
import SelectElement from '../../../components/Inputs/SelectElement';
import useAxios from '../../../hooks/useAxios';
import { PageTitleBar } from '../LgsPurchaseOrder/exports';
import { SubmitConfirm } from './SubmitConfirm';

// #region styled-components
const LabelNormal = styled.label`
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;
const InputElement = styled.input`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
`;

const DialogBtn = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 12px;
    cursor: pointer;
    height: 32px;
`; // dialog按鈕
const Wrapper = styled.div`
    min-height: 720px;

    border-radius: 0 0 0.75rem 0.75rem;
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
    display: flex;
`;
const Textarea = styled.div`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
    background-color: #efefef4d;
    /* width: 600px;
    height: 160px; */
`;
//#endregion

function lgsServiceClass_Create() {
    const { t } = useTranslation(['common', 'lgsServiceClass']);
    const [page, setPage] = useState(0);
    const [view, setView] = useState('create');
    const pageTitle = t('lgsServiceClass:pageTitle');
    const permission = localStorage.getItem('Permission_lgsServiceClass');
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const noRuleGuid = useParams().guid;
    const { isLoading, err, sendRequest: axiosGetData } = useAxios();

    // numbering rule 的參數
    const [data, setData] = useState({});

    // 真正的 numbering rule
    const [noRule, setNoRule] = useState('3');
    // 給使用者看的 numbering rule
    const [ruleForUser, setRuleForUser] = useState('000');
    // numbering rule 的預期結果示範
    const [preview, setPreview] = useState([]);

    const [uomList, setUomList] = useState([]);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
    const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
    const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
        useState(false);

    const [error, setError] = useState({});

    const handleSubmit = (status) => {
        const {
            classCode,
            name01,
            serviceOriginCategoryCode,
            uomCode,
            rowStamp,
        } = data;

        const updateContent = t('log.create', {
            value: name01,
        });

        axios
            .post(`api/Logistics/lgsServiceClass/insert`, {
                classCode,
                name01,
                serviceOriginCategoryCode,
                uomCode,
                status,
                rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const checkInputs = async () => {
        const { state, message } = await handleVerifyClassCode(data?.classCode);

        console.log({ state, message });
        const newError = { ...error };
        if (!state) {
            console.log('falseeee');
            newError.classCode = {
                state,
                message,
            };
        }

        if (data?.name01) {
            newError.name01 = false;
        } else {
            newError.name01 = true;
        }

        if (data?.serviceOriginCategoryCode) {
            newError.serviceOriginCategoryCode = false;
        } else {
            newError.serviceOriginCategoryCode = true;
        }

        // const keys = Object.keys(error);
        console.log(newError);

        // change UI (if errors occur)
        setError(newError);

        // determine if dialog shows or not
        const isValid = Object.values(newError).every((el) => el === false);

        return isValid && setIsSubmitConfirmDialogOpen(true);
    };

    const handleVerifyClassCode = async (val) => {
        if (!val) {
            setError({
                ...error,
                classCode: {
                    state: true,
                },
            });
            return true;
        }

        const res = await axios
            .get(`api/Logistics/lgsServiceClass/verifyCode?ClassCode=${val}`)
            .then((res) => {
                // instance event -> dialog shows or not
                const errorList = { ...error };

                // warnings show or not
                const newErrorState = { ...error };

                errorList.classCode = false;
                newErrorState.classCode = false;

                setError(errorList);
                return {
                    state: true,
                };
            })
            .catch((err) => {
                const res = err.response.data;
                // instance event -> dialog shows or not
                const errorList = { ...error };

                // warnings show or not
                const newErrorState = { ...error };
                const tValue = `errMsg.${res.message}`;
                errorList.classCode = {
                    state: true,
                    message: `${t(tValue)}`,
                };
                newErrorState.classCode = true;

                return {
                    state: false,
                    message: `${t(tValue)}`,
                };
            });

        return res;
    };

    useEffect(() => {
        axiosGetData(
            {
                url: `api/Logistics/LgsServiceClass/getCreateEdit`,
            },
            (res) => {
                const uomList = res?.uomList;

                const result = uomList.map((item) => {
                    item.id = item.uomguid;
                    item.value = item.uomcode;
                    item.name = item.uomcode + item.name01;

                    return item;
                });

                setUomList(result);
            }
        );
    }, []);

    return (
        <>
            <div id="detailsView">
                <PageTitleBar
                    pageTitle={pageTitle}
                    previousPageButton={true}
                    backTo={-1}
                />
                {/* DialogConfirm element */}
                <SubmitConfirm
                    t={t}
                    isOpen={isSubmitConfirmDialogOpen}
                    setIsOpen={setIsSubmitConfirmDialogOpen}
                    handleClickInactive={() => handleSubmit('0')}
                    handleClickActive={() => handleSubmit('1')}
                    message={t('dialog.saveConfirm')}
                />
                <Container>
                    <Left></Left>
                    <Center></Center>
                    <Right>
                        <BtnNormal2
                            className="btn-primary mediumBtn"
                            handleClick={() => checkInputs()}
                        >
                            {t('button.saveButton')}
                        </BtnNormal2>
                        <BtnNormal2
                            className="btn-outline-primary mediumBtn"
                            handleClick={() => navigate(-1)}
                        >
                            {t('button.cancelButton')}
                        </BtnNormal2>
                    </Right>
                </Container>
                <div className="position-relative">
                    <Wrapper className="bg-whit d-flex flex-column p-4 position-relative bg-white">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <FormControlInput
                                        id="name01"
                                        labelText={t(
                                            'lgsServiceClass:details.name01'
                                        )}
                                        name="name01"
                                        value={data.name01}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                name01: e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                        isErr={error?.name01}
                                        errMsg={t('errMsg.mustFill')}
                                    />
                                    <FormControlInput
                                        id="classCode"
                                        labelText={t(
                                            'lgsServiceClass:details.classCode'
                                        )}
                                        name="classCode"
                                        value={
                                            data.classCode
                                                ? data.classCode.toUpperCase()
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                classCode: e.target.value,
                                            });
                                        }}
                                        onBlur={(e) =>
                                            handleVerifyClassCode(
                                                e.target.value
                                            )
                                        }
                                        disabled={false}
                                        disabledCssType={0}
                                        isErr={!error?.classCode?.state}
                                        errMsg={error?.classCode?.message}
                                    />
                                    <SelectElement
                                        id="serviceOriginCategoryCode"
                                        labelText={t(
                                            'lgsServiceClass:details.serviceOriginCategoryCode'
                                        )}
                                        options={[
                                            {
                                                id: 'O',
                                                value: 'O',
                                                name: '外包',
                                            },
                                            {
                                                id: 'W',
                                                value: 'W',
                                                name: '自行承接',
                                            },
                                        ]}
                                        value={data.serviceOriginCategoryCode}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                serviceOriginCategoryCode:
                                                    e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                        isErr={error?.serviceOriginCategoryCode}
                                        errMsg={t('errMsg.mustFill')}
                                    />
                                    {/* <FormControlInput
                                        id="serviceOriginCategoryCode"
                                        labelText={t(
                                            'lgsServiceClass:details.serviceOriginCategoryCode'
                                        )}
                                        value={data?.serviceOriginCategoryCode}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                serviceOriginCategoryCode:
                                                    e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                    /> */}
                                    <SelectElement
                                        id="uomCode"
                                        labelText={t(
                                            'lgsServiceClass:details.uomCode'
                                        )}
                                        value={data.uomCode}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                uomCode: e.target.value,
                                            });
                                        }}
                                        options={uomList}
                                    ></SelectElement>
                                    {/* <FormControlInput
                                        id="uomCode"
                                        labelText={t(
                                            'lgsServiceClass:details.uomCode'
                                        )}
                                        value={data.uomCode}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                uomCode: e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                    /> */}
                                </div>
                                {/* <div className="col-lg-4 px-4 pr-xl-5">
                                    <FormControlInput
                                        id="name02"
                                        labelText={t(
                                            'lgsServiceClass:name02'
                                        )}
                                        value={data.incremental}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                incremental: e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                    />
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <FormControlInput
                                        id="name03"
                                        labelText={t(
                                            'lgsServiceClass:name03'
                                        )}
                                        value={data.incremental}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                incremental: e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </Wrapper>
                </div>
            </div>
        </>
    );
}

export default lgsServiceClass_Create;
