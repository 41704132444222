// #region 程式說明--進銷存服務主檔

/*  **會計科目餘額表**
    
    Author：
    2022/11/21: 潤林: 初版
    
    **主要功能**：

    1.提供「列表」、「查詢」、「新增」、「檢視」、「修改」、「刪除」、「啟動」、「停用」、「刪除」、「異動記錄」功能
    2.列表：顯示所有服務主檔，並提供搜尋功能。
    3.查詢：顯示單筆服務主檔的資料，包括基本資料、服務的照片和服務的預定售價。
    4.新增：新增服務主檔及其照片和預定售價。
    5.修改：修改服務主檔及其照片和預定售價。
    6.啟動：啟動服務主檔。
    7.停用：停用服務主檔。
    8.刪除：刪除服務主檔。
    9.異動記錄：查看服務主檔的異動記錄。
    
    **使用者步驟**：

    A. 服務主檔列表
        1.從側邊欄點選「服務主檔」，進入服務主檔「列表」頁面。
        2.點選頁面上方的選單按鈕，將可以選擇進入「異動記錄」以查詢全部服務主檔異動記錄。
        3.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行服務主檔搜尋。
        4.使用者可點擊列表中每欄的標題，將以此標題為排序依據，並以反向排序或正向排序顯示服務主檔列表資料。
        5.如果使用者已進行進階搜尋，則會列表上方出現已選擇的搜尋條件的小卡片，以顯示已選擇的搜尋條件。
        6.使用者可點擊上述的小卡片，以刪除該進階搜尋條件，或點擊「進階查詢」小卡片，以刪除所有進階搜尋條件。

    B. 進階搜尋
        1.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行服務主檔搜尋。
        2.使用者可選擇之搜尋條件：服務代碼、服務名稱、服務分類、計量單位、可銷售、狀態。
        3.使用者可選擇之搜尋邏輯：包括、不包括、等於、不等於、大於、小於、大於等於、小於等於。
        4.使用者可選擇之搜尋邏輯會根據其選擇的搜尋條件而有所變化，但不會出現上述方法以外的選項。
        5.使用者可於右方的輸入框輸入搜尋條件，輸入框的種類會有「文字」、「數字」、「日期」、「下拉選單」四種。
        6.使右方的輸入框種類會根據其選擇的搜尋條件而有所變化，但不會出現上述方法以外的選項。
        7.使用者可一個或多個搜尋條件，當多於一個搜尋條件時，會多出現一個搜尋邏輯的選項，會有「且」和「或」兩個選頂。
        8.「且」代表「且」搜尋邏輯，「或」代表「或」搜尋邏輯。
        9.當多於一個搜尋條件時，會出現一個刪除小按鈕於每一筆搜尋條件的右側，點選後會刪除該搜尋條件。
        10.在 dialog 中間會有一個橘色的刪除小按鈕，點選後會刪除全部搜尋條件。
        11.點選「確定」，進行服務主檔搜尋，並回到服務主檔「列表」頁面顯示搜尋結果。

    C. 新增服務主檔
        1.如需新增服務主檔服務及其資料，請點選「新增」以進入「新增」頁面。
        2.新增頁面分為上下兩部分，分別為「基本資料」和「服務照片及預定售價」。
        3.在「基本資料」部分，輸入服務主檔基本資料。
        4.在「服務照片及預定售價」部分，上傳服務照片及輸入預定售價。
        5.輸入完畢後，點選「儲存」以進行服務主檔的新增。
        6.點選「儲存」時，使用者可選擇「存檔」或「存檔並啟用」。
        7.服務主檔新增完成後，將會返回服務主檔列表。
        8.在新增的過程中，使用者可點選「取消」按鈕或頁面左上方的返回按鈕，以取消操作並返回服務主檔列表。

    D. 查詢服務主檔
        1.如需查詢服務主檔服務及其資料，請點選「列表」頁面中的任一筆資料,以進入「查詢」頁面。
        2.查詢頁面分為上下兩部分，分別為「基本資料」和「服務照片及預定售價」。
        3.在「基本資料」部分，顯示服務主檔基本資料。
        4.在「服務照片及預定售價」部分，顯示服務照片及預定售價。
        5.如需要修改服務主檔服務及其資料，請點選頁面左上方的「修改」按鈕，以進入「修改」頁面。
        6.點選頁面左上方的返回按鈕，將返回服務主檔列表。
        7.點選頁面上方的選單按鈕，將可以選擇「啟用」、「刪除」、「停用」或進入「異動記錄」以查詢該服務主檔異動記錄。
        8.如服務主檔正處於未啟用狀態，將可選擇「修改」、「啟用」、「刪除」或「異動記錄」。
        9.如服務主檔正處於啟用狀態，將可選擇「修改」、「停用」或「異動記錄」。
        10.如服務主檔正處於停用狀態，將可選擇「修改」、「啟用」或「異動記錄」。

    E. 修改服務主檔
        1.修改頁面分為上下兩部分，分別為「基本資料」和「服務照片及預定售價」。
        2.在「基本資料」部分，輸入服務主檔基本資料。
        3.在「服務照片及預定售價」部分，上傳服務照片及輸入預定售價。
        4.輸入完畢後，點選「儲存」以進行服務主檔的修改。
        5.點選「儲存」時，如該服務主檔的狀態是「未啟用」或「停用」，使用者可選擇「存檔」或「存檔並啟用」。
        6.點選「儲存」時，如該服務主檔的狀態是「啟用中」，修改會主立即生效。
        7.服務主檔修改完成後，將會返回服務主檔查詢。
        8.在修改的過程中，使用者可點選「取消」按鈕或頁面左上方的返回按鈕，以取消操作並返回服務主檔列表。

    F. 服務主檔異動記錄
        1.異動記錄以列表方式呈現，預設以時間正序排序。
        2.使用者可點擊列表中每欄的標題，將以此標題為排序依據，並以反向排序或正向排序顯示服務主檔列表資料。
        3.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行異動記錄的進階搜尋。
        4.此進階查詢功能使用邏輯和「列表」頁面的進階查詢相同。

    **功能頁面**：

    (一)「新增」頁面
        1.使用者輸入：
            (1)基本資料：
                (A)服務代碼（必填，且同公司之服務代碼不可重複）
                (B)名稱(「名稱一」為必填，「名稱二」和「名稱三」為選填)
                (C)服務分類（必填）
                (D)計量單位（必填）
                (E)可銷售（必填）
            (2)照片：
                (A)服務照片
            (3)預定售價：
                (A)幣別（必填）
                (B)起始日期（必填）
                (C)結束日期
                (D)數量下限（必填，且為正整數）
                (E)數量上限（必填，且須大於數量下限）
                (F)未稅單價（必填）
                (G)備註

        2.儲存前檢查：
            (1)必填項目：如資料輸入有缺，將會顯示錯誤訊息於其輸入框下（請填寫資料）。
                (A)基本資料：服務代碼，名稱，服務分類，計量單位，可銷售
                (B)價格條件：幣別，起始日期，數量下限，數量上限，未稅單價
                
            (2)輸入內容檢查：如有錯誤，將會顯示錯誤訊息於其輸入框下。
                (A)服務代碼重複：顯示「此代碼已被使用」
                (B)數量下限少於1：顯示「數量下限不能小於1」
                (C)數量上限少於數量下限：顯示「數量上限不能小於數量下限」

            (3)如輸入的資料無錯誤，則會進入儲存資料的流程。

    **資料連動**：
        1.「計量單位」會依據「服務分類」的選項進行資料連動。
        2.如被選擇的服務分類自帶有預定計量單位，「計量單位」的值將會是服務分類的「預定計量單位」。
        3.即使「計量單位」的被設定為「服務分類自帶有預定計量單位」，使用者也可以自行設定，以覆蓋服務分類的「預定計量單位」。
        4.如被選擇的服務分類沒有預定計量單位，「計量單位」的值將會是使用者選擇的「計量單位」。

    (二)「修改」頁面
        1.畫面邏輯幾乎與「新增」頁面相同。
        2.當服務主檔的狀態不是「未啟用」時，不可修改「服務代碼」和「服務分類」
*/

// #endregion 程式說明--進銷存服務主檔

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as LogIcon } from '../../../assets/images/icn_Log.svg';
import { CreateBtnNormal } from '../../../components/Buttons/CreateBtnNormal';
import { SearchBtnSmall } from '../../../components/Buttons/SearchBtnSmall';
import { DropdownMenuArea } from '../../../components/DropdownMenuArea/DropdownMenuArea';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../../components/Modals/AdvancedSearchResultTag';
import Table from '../../../components/Table/Table';
import { PageTitleBar } from '../../../components/exports';
import useAuth from '../../../hooks/useAuth';
import useAxios from '../../../hooks/useAxios';
import { serviceMasterAdvancedSearchData as searchContentList } from '../../../utils/advancedSearchData';

// #region styled-components
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
    display: flex;
`;

//#endregion

function LgsPurchaseOrder_Index() {
    const { t } = useTranslation(['common', 'lgsServiceMaster']);
    const pageTitle = t('lgsServiceMaster:pageTitle');

    // 使用 useAuth Hook 取得 isLogin 和 checkAuth
    const { isLogin, checkAuth } = useAuth();
    const navigate = useNavigate();

    // 在組件生命週期方法中呼叫 checkAuth 函式檢查登入狀態
    useEffect(() => {
        checkAuth();
    }, []);

    const [fetchedData, setFetchedData] = useState([]);
    const [displayColumns, setDisplayColumns] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const view = 'index';
    // const sortBy = 'orderDate';
    const [sortBy, setSortBy] = useState('ServiceNo	desc');
    const [orderBy, setOrderBy] = useState('desc');
    const guidName = 'serviceGuid';
    const { isLoading, error, sendRequest: axiosIndexData } = useAxios();

    const [advancedSearchShow, setAdvancedSearchShow] = useState(false);

    if (error) {
        console.log('error !', error);
    }

    const headTitleList = [
        '#',
        t('lgsServiceMaster:index.serviceNo'),
        t('lgsServiceMaster:index.name01'),
        t('lgsServiceMaster:index.classCode'),
        t('lgsServiceMaster:index.uomCode'),
        t('lgsServiceMaster:index.status'),
    ];

    const bodyTitleList = [
        'displayOrder',
        'serviceNo',
        'name01',
        'classCode',
        'uomCode',
        'status',
    ];

    const tableStyles = [
        {
            minWidth: '56px',
            textAlign: 'center',
        },
        {
            minWidth: '160px',
            textAlign: 'left',
        },
        {
            minWidth: '280px',
            textAlign: 'left',
        },
        {
            minWidth: '168px',
            textAlign: 'left',
        },
        {
            minWidth: '168px',
            textAlign: 'left',
        },
        {
            minWidth: '120px',
            textAlign: 'left',
        },
    ];

    const columnMustShow = ['displayOrder', 'orderDate', 'orderNo'];

    // 進階搜尋 一進去的第一筆的模板
    const advancedSearchDataTemp = {
        searchLogic: 'AND',
        column: searchContentList[0].column,
        searchOperator: '==',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
        selectOptionList: searchContentList[0].selectOptionList,
    };

    const statusList = [
        {
            status: '0',
            name: '未啟用',
        },
        {
            status: '1',
            name: '啟用中',
        },
        {
            status: '2',
            name: '停用',
        },
    ];

    const forSaleList = [
        {
            forSale: '0',
            name: '不能銷售',
        },
        {
            forSale: '1',
            name: '可銷售',
        },
        {
            forSale: '2',
            name: '暫停銷售',
        },
    ];

    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    const [displayList, setDisplayList] = useState([]);

    // Advanced Search

    //todo: default sort col => orderDate

    /// 1. 第一次進入 index
    /// 2. 改變進階查詢
    /// 3. 改變筆數
    /// 4. 改變排序
    /// 都需要重新向後端拿資料, 所以合成一個 function 處理

    // advancedSearchDataList: 進階查詢資料
    // sort: 排序
    const fetchData = (
        advancedSearchDataList = null,
        pageSize = 20, // 每頁筆數
        sort = sortBy,
        pageNumber = 1
    ) => {
        const url = `api/Logistics/LgsServiceMaster/GetList`;
        console.log(advancedSearchDataList);
        // 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
        if (advancedSearchDataList) {
            // only "one" search data
            advancedSearchDataList[0].searchLogic = '';
        }

        axiosIndexData(
            {
                url,
                method: 'post',
                data: {
                    sort,
                    pageSize,
                    page: pageNumber,
                    advancedSearchDataList,
                    TransactionCode: 'lgsServiceMaster', //permission 由前端傳直給後端，判斷權限
                },
            },
            (res) => {
                // console.log(res);
                const { indexData, permission, numberOfData, totalPage } = res;

                // 分頁資訊
                setPaginationData({
                    ...paginationData,
                    numberOfData: numberOfData,
                    totalPage: totalPage,
                    pageNumber: pageNumber,
                });

                // table 資料
                setFetchedData({
                    indexData,
                    permission,
                    paginationData,
                });

                function isArrayFn(obj) {
                    if (typeof Array.isArray === 'function') {
                        return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
                    } else {
                        // 否則就使用 toString 方法
                        return (
                            Object.prototype.toString.call(obj) ===
                            '[object Array]'
                        );
                    }
                }

                /// 這和 api 回傳的 result 無關
                // 如果有 advancedSearchDataList, 且是 array
                // console.log('original', advancedSearchDataList);
                if (isArrayFn(advancedSearchDataList)) {
                    // 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
                    // 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
                    advancedSearchDataList.map((el) => {
                        searchContentList.map((item) => {
                            // console.log(item);
                            if (el.searchFilter === item.tableColumn) {
                                el.searchFilter = item.searchFromDataBase;
                            }
                        });
                    });

                    // console.log('after edit', advancedSearchDataList);

                    setDisplayList(advancedSearchDataList);
                    setAdvancedSearchDataList(advancedSearchDataList);
                }

                handleAdvancedSearchModalClose();
                localStorage.setItem('Permission_LgsPurchaseOrder', permission);
            }
        );
    };

    const getLocalStorageData = useCallback(() => {
        const gettingData = async () => {
            const data = await (JSON.parse(
                localStorage.getItem(`${guidName}DisplayColumns`)
            ) || bodyTitleList);

            setDisplayColumns(data);

            return data;
        };

        return gettingData();
    }, []);

    const handleOpenAdvancedSearch = () => {
        console.log('handleOpenAdvancedSearch');
    };

    const handleAdvancedSearchModalClose = () => setAdvancedSearchShow(false);
    const handleAdvancedSearchModalShow = () => {
        setAdvancedSearchShow(true);
    };

    const deleteAllFilters = () => {
        setDisplayList([]);
        setAdvancedSearchDataList([]);
        fetchData();
    };

    const deleteFilter = (targetIndex) => {
        console.log('deleteFilter index', targetIndex);
        console.log(advancedSearchDataList[targetIndex]);
        console.log(displayList[targetIndex]);

        const newAdvancedSearchDataList = advancedSearchDataList.filter(
            (_, index) => index !== targetIndex
        );
        const newDisplayList = displayList.filter(
            (_, index) => index !== targetIndex
        );
        setDisplayList(newDisplayList);
        setAdvancedSearchDataList(newAdvancedSearchDataList);
        fetchData(
            newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
        );
    };

    useEffect(() => {
        // fetchIndexInfo();
        getLocalStorageData();
        fetchData();

        return () => {
            // cleanup
        };
    }, [getLocalStorageData]);

    // console.log(advancedSearchDataList);

    return (
        <>
            <div id="indexView">
                <PageTitleBar
                    pageTitle={pageTitle}
                    previousPageButton={false}
                />
                <Container>
                    <Left>
                        <CreateBtnNormal
                            permission={fetchedData?.permission}
                            word={t('button.createButton')}
                        />
                    </Left>
                    <Center></Center>
                    <Right>
                        <SearchBtnSmall
                            permission={fetchedData?.permission}
                            handleClick={handleAdvancedSearchModalShow}
                        />
                        <DropdownMenuArea permission={fetchedData?.permission}>
                            <div onClick={() => navigate('Log')}>
                                <LogIcon />
                                <span>{t('button.logButton')}</span>{' '}
                            </div>
                        </DropdownMenuArea>
                    </Right>
                </Container>
                <div className="position-relative firstRowMinHeight bg-white d-flex flex-column p-4 position-relative overflow-auto">
                    <AdvancedSearchResultTag
                        advancedSearchDataList={advancedSearchDataList}
                        deleteAllFilters={deleteAllFilters}
                        deleteFilter={deleteFilter}
                        ns="lgsServiceMaster"
                    />
                    {fetchedData?.indexData && (
                        <Table
                            guidName={guidName}
                            headTitleList={headTitleList}
                            bodyTitleList={bodyTitleList}
                            tableData={fetchedData?.indexData}
                            indexData={fetchedData?.indexData}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                            displayColumns={displayColumns}
                            setDisplayColumns={setDisplayColumns}
                            paginationData={paginationData}
                            setPaginationData={setPaginationData}
                            fetchData={fetchData}
                            fetchedData={fetchedData}
                            tableStyles={tableStyles}
                            columnMustShow={columnMustShow}
                            rowClick={true}
                            advancedSearchDataList={advancedSearchDataList}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            displaySetting={false}
                        />
                    )}
                </div>
            </div>

            {advancedSearchShow && (
                <AdvancedSearchModal
                    isOpen={advancedSearchShow}
                    handleClose={handleAdvancedSearchModalClose}
                    headTitleList={headTitleList}
                    bodyTitleList={bodyTitleList}
                    advancedSearchDataList={advancedSearchDataList}
                    setAdvancedSearchDataList={setAdvancedSearchDataList}
                    advancedSearchDataTemp={advancedSearchDataTemp}
                    fetchData={fetchData}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    setFetchedData={setFetchedData}
                    displayList={
                        displayList.length > 0
                            ? displayList
                            : [{ ...advancedSearchDataTemp }]
                    }
                    setDisplayList={setDisplayList}
                    searchContentList={searchContentList}
                    ns="lgsServiceMaster"
                    restSearchData={{ statusList, forSaleList }}
                />
            )}
        </>
    );
}

export default LgsPurchaseOrder_Index;
