import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import useSortableData from '../../../../../../../hooks/useSortableData';
import Modal from '../../../../../../../components/Modals/IndexTableDisplayModal';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TFoot = styled.tfoot`
	border: none;
`;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const FakeTD = styled.td`
	cursor: auto;
	border: none !important;
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;

const TypeTH = styled.th`
	color: ${(prop) => prop.color};
	border-bottom: 1px solid ${(prop) => prop.color} !important;
	border-top: 0px;
	border-right: 0px;
	border-left: 0px;
`;
//#endregion

function LgsPurchaseOrder_HistoryOverviewTable(props) {
	const { t } = useTranslation();

	// console.log(props);
	const {
		guidName,
		sortBy,
		tableHoverEffect,
		headTitleList,
		bodyTitleList,
		purchaseOrderStatus,
		indexData,
		property,
		orderDate,
		foreignGoodsReceiveAmount,
		foreignInvoiceGrossAmount,
		foreignInvoiceTaxAmount,
		foreignPayableAmount,
		goodsReceiveAmount,
		invoiceGrossAmount,
		invoiceTaxAmount,
		payableAmount,
		totalForeignGoodsReceiveAmount,
		totalForeignInvoiceGrossAmount,
		totalForeignInvoiceTaxAmount,
		totalForeignPayableAmount,
		totalGoodsReceiveAmount,
		totalInvoiceGrossAmount,
		totalInvoiceTaxAmount,
		totalPayableAmount,
		foreignCurrency,
		tableHeadStyles,
		tableBodyStyles,
		grossAmount,
		foreignGrossAmount,
		postingDate,
		exchageRate,
		varianceBalance,
	} = props;

	console.log(props);

	const [orderBy, setOrderBy] = useState('desc');
	const [UISort, setUISort] = useState(sortBy);
	const { items, requestSort, sortConfig } = useSortableData(indexData);
	const navigate = useNavigate();
	const location = useLocation();

	const propertyObj = {
		0: '採購訂單',
		1: '採購收貨',
		2: '採購退貨',
		3: '採購收發票',
		4: '採購退發票',
		5: '採購付款',
		6: '採購付款退回',
		7: '採購付款結清',
	};

	const getClassNamesFor = (name) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	const handleSortChange = (newTarget) => {
		const oldTarget = UISort;
		setUISort(newTarget);

		if (oldTarget === newTarget)
			return setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');

		return setOrderBy('asc');
	};

	const preferredOrder = (indexData, bodyTitleList) => {
		let newObject = {};
		for (let i = 0; i < bodyTitleList.length; i++) {
			if (indexData.hasOwnProperty(bodyTitleList[i])) {
				newObject[bodyTitleList[i]] = indexData[bodyTitleList[i]];
			}
		}
		return newObject;
	};

	preferredOrder(indexData, bodyTitleList);

	console.log(
		Number(totalPayableAmount) -
			(Number(grossAmount) || Number(foreignGrossAmount))
	);

	return (
		<div className="position-relative">
			<IndexTableContainer className="firstRowMinHeight bg-white d-flex flex-column p-4 position-relative justify-content-betwen">
				<IndexTable className="table">
					<THead
						className="table-head"
						items={items}
						requestSort={requestSort}
						sortConfig={sortConfig}
						style={{ backgroundColor: 'white' }}
					>
						<TR className="border-0">
							{foreignCurrency ? (
								<React.Fragment>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<TypeTH
										className={`px-2 text-center`}
										color={'var(--primary)'}
									>
										{t(
											'lgsPurchaseOrderPaymentClose:page2.historyOverview.receiveGoods'
										)}
									</TypeTH>
									<TypeTH
										className={`px-2 text-center`}
										colSpan={2}
										color={'#31A952'}
									>
										{t(
											'lgsPurchaseOrderPaymentClose:page2.historyOverview.receiveInvoice'
										)}
									</TypeTH>
									<TypeTH
										className={`px-2 text-center`}
										colSpan={2}
										color={'#FFA51D'}
									>
										{t(
											'lgsPurchaseOrderPaymentClose:page2.historyOverview.makePayment'
										)}
									</TypeTH>
								</React.Fragment>
							) : (
								<React.Fragment>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<th
										className={`border-0 px-2 text-center`}
									></th>
									<TypeTH
										className={`px-2 text-center`}
										color={'var(--primary)'}
									>
										{t(
											'lgsPurchaseOrderPaymentClose:page2.historyOverview.receiveGoods'
										)}
									</TypeTH>
									<TypeTH
										className={`px-2 text-center`}
										colSpan={2}
										color={'#31A952'}
									>
										{t(
											'lgsPurchaseOrderPaymentClose:page2.historyOverview.receiveInvoice'
										)}
									</TypeTH>
									<TypeTH
										className={`px-2 text-center`}
										color={'#FFA51D'}
									>
										{t(
											'lgsPurchaseOrderPaymentClose:page2.historyOverview.makePayment'
										)}
									</TypeTH>
								</React.Fragment>
							)}
						</TR>
					</THead>
					<THead
						className="table-head"
						items={items}
						requestSort={requestSort}
						sortConfig={sortConfig}
					>
						<TR className="">
							{headTitleList?.map((el) => {
								return (
									<TH
										key={`tableHead${headTitleList.indexOf(
											el
										)}`}
										style={
											tableHeadStyles[
												headTitleList.indexOf(el)
											]
										}
										className={`
                                        px-2
                                        border 
                                        text-center ${getClassNamesFor(
											bodyTitleList[
												headTitleList.indexOf(el)
											]
										)}`}
										onClick={() => {
											requestSort(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											);
											handleSortChange(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											);
										}}
									>
										{el}
										{bodyTitleList[
											headTitleList.indexOf(el)
										] === UISort &&
											(orderBy === 'desc' ? (
												<span className="ml-1">▼</span>
											) : (
												<span className="ml-1">▲</span>
											))}
									</TH>
								);
							})}
						</TR>
					</THead>
					<TBody>
						{/* 假 TR => 用作 padding 用途 */}
						<tr
							className="border-0"
							id="fakeTr1"
							key="fakeTr1"
							style={{ height: '8px' }}
						>
							{bodyTitleList.map((el) => {
								return (
									<FakeTD
										style={
											tableBodyStyles[
												bodyTitleList.indexOf(el)
											]
										}
										className="p-0 border-0"
										key={`${
											el[guidName]
										}_${bodyTitleList.indexOf(el)}`}
									></FakeTD>
								);
							})}
						</tr>
						<TR
							className=""
							id="purchaseOrderStatus"
							key="purchaseOrderStatus"
							hoverEffect={tableHoverEffect}
							style={{ backgroundColor: '#EEF4FD' }}
						>
							{bodyTitleList.map((el) => {
								if (el === 'property') {
									const propertyString =
										propertyObj[bodyTitleList.indexOf(el)];

									return (
										<TD
											style={
												tableBodyStyles[
													bodyTitleList.indexOf(el)
												]
											}
											className="p-2 border"
											key={`${
												el[guidName]
											}_${bodyTitleList.indexOf(el)}`}
										>
											<div>{propertyObj[props[el]]}</div>
										</TD>
									);
								}
								return (
									<TD
										style={
											tableBodyStyles[
												bodyTitleList.indexOf(el)
											]
										}
										className={`p-2 border 
                                        ${props[el] < 0 && 'text-danger'}`}
										key={`${
											el[guidName]
										}_${bodyTitleList.indexOf(el)}`}
									>
										<div>{props[el]}</div>
									</TD>
								);
							})}
						</TR>

						{/* 假 TR => 用作 padding 用途 */}
						<tr
							className="border-0"
							id="fakeTr2"
							key="fakeTr2"
							style={{ height: '8px' }}
						>
							{bodyTitleList.map((el) => {
								return (
									<FakeTD
										style={
											tableBodyStyles[
												bodyTitleList.indexOf(el)
											]
										}
										className="p-0 border-0"
										key={`${
											el[guidName]
										}_${bodyTitleList.indexOf(el)}`}
									></FakeTD>
								);
							})}
						</tr>

						{items?.map((data) => {
							return (
								<TR
									id={data[guidName]}
									key={data[guidName]}
									hoverEffect={tableHoverEffect}
								>
									{bodyTitleList.map((el) => {
										if (el === 'property') {
											const propertyString =
												propertyObj[data[el]];
											return (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList.indexOf(
																el
															)
														]
													}
													className={`p-2 border `}
													key={`${el}${data[guidName]}`}
												>
													{propertyString}
												</TD>
											);
										}
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList.indexOf(
															el
														)
													]
												}
												className={`p-2 border ${
													data[el] < 0 &&
													'text-danger'
												}`}
												key={`${el}${data[guidName]}`}
											>
												{data[el]}
											</TD>
										);
									})}
								</TR>
							);
						})}

						<TR
							className=""
							id="paymentStatus"
							key="paymentStatus"
							hoverEffect={tableHoverEffect}
							style={{ backgroundColor: '#FFF9E7' }}
						>
							<TD className={`p-2 border text-center`}>
								<div>***</div>
							</TD>
							<TD className={`p-2 border`}>
								<div>{postingDate}</div>
							</TD>
							<TD className={`p-2 border`}>
								<div>採購付款差異結清</div>
							</TD>
							<TD className={`p-2 border text-end`}>
								<div></div>
							</TD>
							<TD className={`p-2 border text-end`}>
								<div></div>
							</TD>
							<TD className={`p-2 border text-end`}>
								<div></div>
							</TD>
							<TD className={`p-2 border text-end`}>
								<div>{varianceBalance || 0}</div>
							</TD>
							{foreignCurrency ? (
								<TD className={`p-2 border text-end`}>
									<div>
										{varianceBalance * exchageRate || 0}
									</div>
								</TD>
							) : null}
							{/* {bodyTitleList.map((el) => {
                                if (el === 'property') {
                                    const propertyString =
                                        propertyObj[bodyTitleList.indexOf(el)];
                                    return (
                                        <TD
                                            style={
                                                tableBodyStyles[
                                                    bodyTitleList.indexOf(el)
                                                ]
                                            }
                                            className="p-2 border"
                                            key={`${
                                                el[guidName]
                                            }_${bodyTitleList.indexOf(el)}`}
                                        >
                                            <div>採購付款</div>
                                        </TD>
                                    );
                                }
                                return (
                                    <TD
                                        style={
                                            tableBodyStyles[
                                                bodyTitleList.indexOf(el)
                                            ]
                                        }
                                        className={`p-2 border 
                                        ${props[el] < 0 && 'text-danger'}`}
                                        key={`${
                                            el[guidName]
                                        }_${bodyTitleList.indexOf(el)}`}
                                    >
                                        <div>{props[el]}</div>
                                    </TD>
                                );
                            })} */}
						</TR>
					</TBody>
					<TFoot>
						{foreignCurrency ? (
							<FootTR
								id="totalAmount"
								key="totalAmount"
								hoverEffect={tableHoverEffect}
								style={{ fontWeight: 'bold' }}
							>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right fw-bold">
									{t(
										'lgsPurchaseOrderPaymentClose:page2.balance'
									)}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalForeignGoodsReceiveAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalForeignGoodsReceiveAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalForeignInvoiceTaxAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalForeignInvoiceTaxAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalForeignInvoiceGrossAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalForeignInvoiceGrossAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalForeignPayableAmount < 0 &&
										'text-danger'
									}`}
								>
									{Number(totalForeignPayableAmount) -
										Number(varianceBalance)}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalPayableAmount < 0 && 'text-danger'
									}`}
								>
									{Number(totalPayableAmount) -
										Number(
											varianceBalance * exchageRate || 0
										)}
								</FootTD>
							</FootTR>
						) : (
							<FootTR
								id="totalAmount"
								key="totalAmount"
								hoverEffect={tableHoverEffect}
								style={{ fontWeight: 'bold' }}
							>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right"></FootTD>
								<FootTD className="p-2 text-right fw-bold">
									{t(
										'lgsPurchaseOrderPaymentClose:page2.balance'
									)}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalGoodsReceiveAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalGoodsReceiveAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalInvoiceTaxAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalInvoiceTaxAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalInvoiceGrossAmount < 0 &&
										'text-danger'
									}`}
								>
									{totalInvoiceGrossAmount}
								</FootTD>
								<FootTD
									className={`p-2 text-right fw-bold ${
										totalPayableAmount < 0 && 'text-danger'
									}`}
								>
									{Number(totalPayableAmount) -
										Number(varianceBalance)}
								</FootTD>
							</FootTR>
						)}
					</TFoot>
				</IndexTable>
			</IndexTableContainer>
		</div>
	);
}

export default LgsPurchaseOrder_HistoryOverviewTable;
