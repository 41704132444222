import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Input from '../../../components/FormElement/Input';
import ThreeColLayout from '../../../components/ThreeColLayout/ThreeColLayout';

// #region styled-components

const TopTable = styled.table`
    font-size: 12px;
    color: #616e83;
    background-color: #f7f8fc;
    width: 100% !important;
    height: 128px !important;
    border: 1px solid var(--grey1) !important;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
`;

const TBody = styled.tbody``;

const TR = styled.tr`
    height: 32px;
    border: 2px solid var(--grey1) !important;
`;
const TH1 = styled.th`
    padding: 0px;
    height: 32px;
    width: 23% !important;
    text-align: center !important;
`;
const TH = styled.th`
    padding: 0px;
    height: 32px;
    width: 38.5% !important;
    text-align: center !important;
`;
const TD1 = styled.td`
    padding: 0px;
    height: 32px;
    width: 23% !important;
    border: 1px solid var(--grey1) !important;
    text-align: center !important;
`;
const TD = styled.td`
    padding-right: 8px;
    height: 32px;
    width: 38.5% !important;
    border: 1px solid var(--grey1) !important;

    text-align: right !important;
`;

// #endregion

function LgsPurchaseOrder_TopCommonInfo({
    register,
    errors,
    getValues,
    setValue,
    detailsInfo,
}) {
    console.log(detailsInfo);

    const { t } = useTranslation(['common', 'lgsPurchaseOrder']);

    const {
        foreignCurrency,
        vendor,
        orderTypeCode,
        orderDate,
        currencyCode,
        foreignCurrencyCode,
        exchageRate,
        inputTax,
        inputTaxRate,
        netAmount,
        taxAmount,
        grossAmount,
        foreignNetAmount,
        foreignTaxAmount,
        foreignGrossAmount,
    } = detailsInfo;

    console.log('foreignCurrency', foreignCurrency);

    function LeftCol1() {
        const [valueShow, setValueShow] = useState('');

        return (
            <>
                <Input
                    wrapperClass="mb-3" // 外層class，可用來推下面margin
                    labelText={t('lgsPurchaseOrder:vendor')} // label文字
                    type="text" // input型態
                    id="vendor" // input id&name ; label htmlFor
                    disabled={true}
                    disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                    register={register}
                    validation={{
                        required: 'must be required',
                    }}
                    isErr={errors.tel} // 是否顯示錯誤訊息
                    errMsg={errors.tel?.message} // 錯誤訊息
                    value={vendor}
                    onChange={(e) => {
                        setValue('vendor', e.target.value);
                        const vendorValue = getValues('vendor');
                        // console.log(vendorValue);
                    }}
                />

                <div className="row">
                    <div className="col-lg-8">
                        <Input
                            wrapperClass="mb-3" // 外層class，可用來推下面margin
                            labelText={t('lgsPurchaseOrder:purchaseOrderType')} // label文字
                            type="text" // input型態
                            id="orderTypeCode" // input id&name ; label htmlFor
                            disabled={true}
                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                            register={register}
                            validation={{
                                required: 'must be required',
                            }}
                            isErr={errors.tel} // 是否顯示錯誤訊息
                            errMsg={errors.tel?.message} // 錯誤訊息
                            value={orderTypeCode}
                            onChange={(e) => {
                                setValue('orderTypeCode', e.target.value);
                                const orderTypeCodeValue =
                                    getValues('orderTypeCode');
                                // console.log(orderTypeCodeValue);
                            }}
                        />
                    </div>

                    <div className="col-lg-4">
                        <Input
                            wrapperClass="mb-3" // 外層class，可用來推下面margin
                            labelText={t('lgsPurchaseOrder:orderDate')} // label文字
                            type="date" // input型態
                            id="orderDate" // input id&name ; label htmlFor
                            disabled={true}
                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                            register={register}
                            validation={{
                                required: 'must be required',
                            }}
                            isErr={errors.tel} // 是否顯示錯誤訊息
                            errMsg={errors.tel?.message} // 錯誤訊息
                            value={orderDate}
                            onChange={(e) => {
                                setValue('orderDate', e.target.value);
                                const orderDateValue = getValues('orderDate');
                                // console.log(orderDateValue);
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }

    function CenterCol1() {
        return (
            <>
                <div className="row">
                    <div className={foreignCurrency ? 'col-lg-8' : 'col-lg-12'}>
                        {foreignCurrency ? (
                            <Input
                                wrapperClass="mb-3" // 外層class，可用來推下面margin
                                labelText={t('lgsPurchaseOrder:currency')} // label文字
                                type="text" // input型態
                                id="foreignCurrencyCode" // input id&name ; label htmlFor
                                placeholder={currencyCode}
                                disabled={true}
                                disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                register={register}
                                validation={{
                                    required: 'must be required',
                                }}
                                isErr={errors.tel} // 是否顯示錯誤訊息
                                errMsg={errors.tel?.message} // 錯誤訊息
                                value={foreignCurrencyCode}
                                onChange={(e) => {
                                    setValue(
                                        'foreignCurrencyCode',
                                        e.target.value
                                    );
                                    const foreignCurrencyCodeValue = getValues(
                                        'foreignCurrencyCode'
                                    );
                                    // console.log(foreignCurrencyCodeValue);
                                }}
                            />
                        ) : (
                            <Input
                                wrapperClass="mb-3" // 外層class，可用來推下面margin
                                labelText={t('lgsPurchaseOrder:currency')} // label文字
                                type="text" // input型態
                                id="currencyCode" // input id&name ; label htmlFor
                                placeholder={currencyCode}
                                disabled={true}
                                disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                register={register}
                                validation={{
                                    required: 'must be required',
                                }}
                                isErr={errors.tel} // 是否顯示錯誤訊息
                                errMsg={errors.tel?.message} // 錯誤訊息
                                value={currencyCode}
                                onChange={(e) => {
                                    setValue('currencyCode', e.target.value);
                                    const currencyCodeValue =
                                        getValues('currencyCode');
                                    // console.log(currencyCodeValue);
                                }}
                            />
                        )}
                    </div>
                    {foreignCurrency && (
                        <div className="col-lg-4">
                            <Input
                                wrapperClass="mb-3" // 外層class，可用來推下面margin
                                labelText={t('lgsPurchaseOrder:exchangeRate')} // label文字
                                type="text" // input型態
                                id="exchageRate" // input id&name ; label htmlFor
                                placeholder=""
                                disabled={true}
                                disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                register={register}
                                validation={{
                                    required: 'must be required',
                                }}
                                isErr={errors.tel} // 是否顯示錯誤訊息
                                errMsg={errors.tel?.message} // 錯誤訊息
                                value={exchageRate}
                                onChange={(e) => {
                                    setValue('exchageRate', e.target.value);
                                    const exchageRateValue =
                                        getValues('exchageRate');
                                    // console.log(exchageRateValue);
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <Input
                            wrapperClass="mb-3" // 外層class，可用來推下面margin
                            labelText={t('lgsPurchaseOrder:invoiceTaxCategory')} // label文字
                            type="text" // input型態
                            id="inputTax" // input id&name ; label htmlFor
                            placeholder=""
                            disabled={true}
                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                            register={register}
                            validation={{
                                required: 'must be required',
                            }}
                            isErr={errors.tel} // 是否顯示錯誤訊息
                            errMsg={errors.tel?.message} // 錯誤訊息
                            value={inputTax}
                            onChange={(e) => {
                                setValue('inputTax', e.target.value);
                                const inputTaxValue = getValues('inputTax');
                                // console.log(inputTaxValue);
                            }}
                        />
                    </div>

                    <div className="col-lg-4">
                        <Input
                            wrapperClass="mb-3" // 外層class，可用來推下面margin
                            labelText={t('lgsPurchaseOrder:invoiceTaxRate')} // label文字
                            type="text" // input型態
                            id="inputTaxRate" // input id&name ; label htmlFor
                            placeholder=""
                            disabled={true}
                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                            register={register}
                            validation={{
                                required: 'must be required',
                            }}
                            isErr={errors.tel} // 是否顯示錯誤訊息
                            errMsg={errors.tel?.message} // 錯誤訊息
                            value={inputTaxRate}
                            onChange={(e) => {
                                setValue('inputTaxRater', e.target.value);
                                const inputTaxRateValue =
                                    getValues('inputTaxRate');
                                // console.log(inputTaxRateValue);
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }

    function RightCol1() {
        return (
            <>
                <TopTable>
                    <TBody>
                        <TR>
                            <TH1></TH1>
                            {foreignCurrency && <TH>{foreignCurrencyCode}</TH>}
                            <TH>{currencyCode}</TH>
                        </TR>
                        <TR>
                            <TD1>{t('lgsPurchaseOrder:netAmount')}</TD1>
                            {foreignCurrency && <TD>{foreignNetAmount}</TD>}
                            <TD>{netAmount}</TD>
                        </TR>
                        <TR>
                            <TD1>{t('lgsPurchaseOrder:taxAmount')}</TD1>
                            {foreignCurrency && <TD>{foreignTaxAmount}</TD>}
                            <TD>{taxAmount}</TD>
                        </TR>
                        <TR>
                            <TD1>{t('lgsPurchaseOrder:grossAmount')}</TD1>
                            {foreignCurrency && <TD>{foreignGrossAmount}</TD>}
                            <TD>{grossAmount}</TD>
                        </TR>
                    </TBody>
                </TopTable>
            </>
        );
    }

    return (
        <div className="row bg-white rounded-b-xxl pb-0 mx-0 mb-3">
            <div className="px-3 py-5">
                <ThreeColLayout
                    LeftCol={<LeftCol1 />}
                    CenterCol={<CenterCol1 />}
                    RightCol={<RightCol1 />}
                />
            </div>
        </div>
    );
}

export default LgsPurchaseOrder_TopCommonInfo;
