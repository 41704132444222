import { useState, useEffect } from 'react';
import InvoiceTable from './InvoiceTable';
import { useTranslation } from 'react-i18next';

function InvoiceTab({ parentData }) {
	//#region state initiation
	const { t } = useTranslation(['common', 'lgsPurchaseOrderGoodsInvoice']); // i18n

	const { decimalPlaceTl } = parentData.page1.backEndData.purchaseOrder;

	// state
	const [backEndData, setBackEndData] = useState({});
	const [pageData, setPageData] = useState({});
	const [tableBodyData, setTableBodyData] = useState([]);

	//#endregion

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsPurchaseOrderGoodsInvoice:invoice'),
		t('lgsPurchaseOrderGoodsInvoice:invoiceFormat'),
		t('lgsPurchaseOrderGoodsInvoice:return'),
		t('lgsPurchaseOrderGoodsInvoice:certificateDate'),
		t('lgsPurchaseOrderGoodsInvoice:certificateNumber'),
		t('lgsPurchaseOrderGoodsInvoice:taxRate'),
		t('lgsPurchaseOrderGoodsInvoice:totalTaxableAmount'),
		t('lgsPurchaseOrderGoodsInvoice:netAmount2'),
		t('lgsPurchaseOrderGoodsInvoice:taxAmount2'),
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'governmentInvoice',
		'vatInvoiceFormatViewName',
		'invoiceReturn',
		'invoiceDate',
		'invoiceNo',
		'vatRate',
		'totalAmount',
		'netAmount',
		'taxAmount',
	];

	// 不同 input 有沒有小數點的要求
	const toFloatList = [
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'decimalPlace',
		'decimalPlace',
		'decimalPlace',
	];

	// 不同 input 的 type
	const inputProperties = [
		{ type: 'number' },
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'date' },
		{ type: 'text' },
		{ type: 'number' },
		{ type: 'number' },
		{ type: 'number' },
		{ type: 'number' },
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '72px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'left',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '72px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
	];

	//#endregion

	useEffect(() => {
		setBackEndData(parentData.page2.backEndData);
		setTableBodyData(parentData.page2.invoiceItemList);
		setPageData(parentData.page2);
	}, []);

	return (
		<>
			<div className="position-relative">
				<div className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
					<InvoiceTable
						backEndData={backEndData}
						parentData={parentData}
						pageData={pageData}
						setPageData={setPageData}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						tableBodyData={tableBodyData}
						setTableBodyData={setTableBodyData}
						decimalPlaceTl={decimalPlaceTl}
					/>
				</div>
			</div>
		</>
	);
}

export default InvoiceTab;
