import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { SaveSubmitBtn } from '../../../../../components/Buttons/SaveSubmitBtn';
import { CancelSubmitBtn } from '../../../../../components/Buttons/CancelSubmitBtn';
import TabPage from '../../../../../components/TabPage/TabPage';
import useAxios from '../../../../../hooks/useAxios';
import GoodsItemTab from './GoodsItemTab/GoodsItemTab';
import InvoiceTab from './InvoiceTab/InvoiceTab';
import StandardCostTab from './StandardCostTab/StandardCostTab';
import AccountDocumentTab from './AccountDocumentTab/AccountDocumentTab';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import { useTranslation } from 'react-i18next';
import { DialogWarning } from '../DialogWarning';

// #region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page3(props) {
	//#region state initialization

	const { t } = useTranslation(['common', 'lgsPurchaseOrderGoodsInvoice']); // i18n

	const {
		parentData,
		setParentData,
		page,
		processSteps,
		handlePrevPage,
		handleNextPage,
	} = props;

	const exchangeRate = parentData.page1.goodsItemInfo.exchageRate;
	const { decimalPlaceTl } = parentData.page1.backEndData.purchaseOrder;

	// state
	const [tab3Data, setTab3Data] = useState([]);
	const [tab4Data, setTab4Data] = useState({});

	// axios
	const { sendRequest: axiosDataPage4 } = useAxios();

	//#endregion

	//#region 4個分頁tab

	// 分頁title
	const tabNavList = [
		t('lgsPurchaseOrderGoodsInvoice:goodsDetails'),
		t('lgsPurchaseOrderGoodsInvoice:invoiceDetails'),
		t('lgsPurchaseOrderGoodsInvoice:accountingVoucher'),
		t('lgsPurchaseOrderGoodsInvoice:standardCostDifference'),
	];

	// 分頁內容 4頁 component
	const tabContent = [
		<GoodsItemTab parentData={parentData} />,
		<InvoiceTab parentData={parentData} />,
		<AccountDocumentTab parentData={parentData} tab3Data={tab3Data} />,
		<StandardCostTab parentData={parentData} tab4Data={tab4Data} />,
	];
	//#endregion

	//#region useEffect

	useEffect(() => {
		const url = `api/Logistics/LgsPurchaseOrderGoodsInvoice/getPage4`;
		const method = 'POST';

		const purchaseOrderItemList = [];
		parentData.page1.purchaseOrderItemList.forEach((el) => {
			let newList = {
				PurchaseOrderItemGuid: el.purchaseOrderItemGuid || null,
				DisplayOrder: el.displayOrder || null,
				PurchaseCategoryCode: el.purchaseCategoryCode || null,
				MaterialGuid: el.materialGuid || null,
				MaterialClassGuid: el.materialClassGuid || null,
				AssetsClassGuid: el.assetsClassGuid || null,
				ExpenseClassGuid: el.expenseClassGuid || null,
				QuantityDelivered: el.quantityReceive || null,
				NetPrice: el.netPrice || null,
				ForeignNetPrice: el.foreignNetPrice || null,
				Note: el.note || null,
			};

			purchaseOrderItemList.push(newList);
		});
		console.log(purchaseOrderItemList);

		const data = {
			PurchaseOrderGuid: parentData.page1.goodsItemInfo.purchaseOrderGuid,
			PostingDate: parentData.page1.goodsItemInfo.postingDate,
			PurchaseOrderItemList: purchaseOrderItemList,
		};

		axiosDataPage4({ url, method, data }, async (res) => {
			console.log(res);
			console.log(res.status);

			// if (res.response.status === 500) {
			// 	console.log(res.response);

			// 	setWarningMessage(
			// 		`缺少資料: ${res.response.data.message}，即將離開回到首頁。`
			// 	);
			// 	setIsRedirect(true);
			// 	handleDialogWarning();
			// 	return;
			// }

			// 1.會計憑證
			const { goodsReceivedNoteList, invoiceList, priceVarianceList } =
				res;

			// 給予排序，後端需要(將三個list內元件，依序給予序號)
			let displayOrder = 1;

			// 1-1.goodsReceivedNoteList 處理小數點位數
			goodsReceivedNoteList.forEach((el) => {
				if (el.amountCr)
					el.amountCr = el.amountCr.toFixed(decimalPlaceTl);
				if (el.amountDr)
					el.amountDr = el.amountDr.toFixed(decimalPlaceTl);

				// 給displayOrder
				el.displayOrder = displayOrder;
				displayOrder++;
			});

			// 1-2.newInvoiceList 處理發票相關
			const newInvoiceList = [...invoiceList];
			newInvoiceList.forEach((el, index) => {
				if (index === 0) {
					// 金額為"收到發票的稅額"。
					el.amountDr = Number(
						parentData.page2.allAmount.taxAmount * exchangeRate
					).toFixed(decimalPlaceTl);
					el.amountCr = 0;
				}
				if (index === 1) {
					// 金額為"收到發票的應稅總額"
					el.amountDr = 0;
					el.amountCr = Number(
						parentData.page2.allAmount.totalAmount * exchangeRate
					).toFixed(decimalPlaceTl);
				}
				if (index === 2) {
					// 金額為"未收到發票的未稅總額"
					el.amountDr = 0;
					el.amountCr = Number(
						(parentData.page1.totalPriceInfo
							.totalPurchaseForeignPrice -
							parentData.page2.allAmount.netAmount) *
							exchangeRate
					).toFixed(decimalPlaceTl);
				}
			});

			// a.假如沒有發票，則不顯示 "收到發票的稅額"&"收到發票的應稅總額"
			parentData.page2.allAmount.totalAmount === 0 &&
				newInvoiceList.splice(0, 2);
			// b.假如"未收到發票的未稅總額"=0，則不顯示
			parentData.page1.totalPriceInfo.totalPurchaseForeignPrice -
				parentData.page2.allAmount.netAmount ===
				0 && newInvoiceList.splice(2, 1);

			// 給displayOrder
			newInvoiceList.forEach((el) => {
				el.displayOrder = displayOrder;
				displayOrder++;
			});
			console.log(newInvoiceList);

			// 1-3.priceVarianceList 處理小數點位數
			priceVarianceList.forEach((el) => {
				if (el.amountCr)
					el.amountCr = el.amountCr.toFixed(decimalPlaceTl);
				if (el.amountDr)
					el.amountDr = el.amountDr.toFixed(decimalPlaceTl);

				// 給displayOrder
				el.displayOrder = displayOrder;
				displayOrder++;
			});

			// 1-4.合體(前端畫面使用)
			let accountList = [
				...goodsReceivedNoteList,
				...newInvoiceList,
				...priceVarianceList,
			];
			console.log(accountList);

			setTab3Data({
				// 前端畫面使用
				accountDocumentName: res.accountDocumentName,
				accountList: accountList,

				// 後端需要的，分開3個list
				dataToBackEnd: {
					goodsReceivedNoteList: goodsReceivedNoteList,
					newInvoiceList: newInvoiceList,
					priceVarianceList: priceVarianceList,
				},
			});

			// 2.標準成本差異
			const selectedPurchaseOrderItemGuid = res.standardPriceList.map(
				(el) => el.purchaseOrderItemGuid
			);
			// console.log(selectedPurchaseOrderItemGuid);

			const newParentData = JSON.parse(JSON.stringify(parentData));
			newParentData.page1.purchaseOrderItemList =
				newParentData.page1.purchaseOrderItemList.filter((el) => {
					return selectedPurchaseOrderItemGuid.includes(
						el.purchaseOrderItemGuid
					);
				});

			// console.log(parentData);

			newParentData.page1.purchaseOrderItemList.forEach(
				(purchaseOrderItem, index) => {
					// 1.displayOrder
					purchaseOrderItem.displayOrder = index + 1;

					// 2.換算標準單價
					const standardPrice = res.standardPriceList.find(
						(el) =>
							el.purchaseOrderItemGuid ===
							purchaseOrderItem.purchaseOrderItemGuid
					);

					if (standardPrice && standardPrice.standardPrice !== 0) {
						// standardPrice.standardPrice ===0時欄位直接空白

						// 標準單價
						purchaseOrderItem.standardNetPrice =
							standardPrice.standardPrice.toFixed(decimalPlaceTl);

						// 標準金額
						purchaseOrderItem.standardPurchasePrice = (
							Number(purchaseOrderItem.quantityReceive) *
							Number(standardPrice.standardPrice)
						).toFixed(decimalPlaceTl);

						// 差異單價
						purchaseOrderItem.diffNetPrice = (
							purchaseOrderItem.netPrice -
							standardPrice.standardPrice
						).toFixed(decimalPlaceTl);

						// 差異金額
						purchaseOrderItem.diffPurchasePrice = (
							(purchaseOrderItem.netPrice -
								standardPrice.standardPrice) *
							purchaseOrderItem.quantityReceive
						).toFixed(decimalPlaceTl);

						// 差異%
						purchaseOrderItem.diffPercent = (
							((purchaseOrderItem.netPrice -
								standardPrice.standardPrice) /
								standardPrice.standardPrice) *
							100
						).toFixed(0);
					}
				}
			);

			// console.log(newParentData);
			setTab4Data(newParentData.page1);
		});
	}, []);

	console.log('parentData', parentData);
	console.log('tab3Data', tab3Data);
	console.log('tab4Data', tab4Data);
	//#endregion

	//#region submit送出給後端

	// 整理給後端的資料
	const organizeData = () => {
		const oldParentData = { ...parentData };
		const oldTab3Data = { ...tab3Data };

		// 整理資料

		const insertPurchaseOrderGoodsInvoicePurchaseOrderItemList =
			oldParentData.page1.purchaseOrderItemList.map(
				({
					purchaseOrderItemGuid,
					displayOrder,
					purchaseCategoryCode,
					materialGuid,
					quantityReceive,
					netPrice,
					foreignNetPrice,
					note,
					cubeGuid,
					batchNo,
				}) => ({
					purchaseOrderItemGuid,
					displayOrder,
					purchaseCategoryCode,
					materialGuid,
					quantityDelivered: quantityReceive,
					netPrice,
					foreignNetPrice,
					note,
					materialCubeGuid: cubeGuid,
					batchNo,
				})
			);

		const insertPurchaseOrderGoodsInvoiceInvoiceList =
			oldParentData.page2.invoiceItemList.map(
				({
					governmentInvoice,
					invoiceDate,
					invoiceNo,
					vatInvoiceFormat,
					vatInvoiceTaxType,
					vatInputTaxable,
					vatRate,
					netAmount,
					taxAmount,
					purchaserCustomerGuid,
					purchaserTaxID,
					purchaserTaxName,
					purchaserAddTel,
					purchaserBankAccount,
					salesVendorGuid,
					salesTaxID,
					salesTaxName,
					salesAddTel,
					salesBankAccount,
					vatControl,
					invoiceCode,
				}) => ({
					governmentInvoice:
						governmentInvoice === '0'
							? false
							: governmentInvoice === '1'
							? true
							: false,
					invoiceDate,
					invoiceDocumentNo: invoiceNo ? invoiceNo : null,
					invoiceFormat:
						governmentInvoice === '0' ? vatInvoiceFormat : null,
					vatInvoiceTaxType,
					vatInputTaxable,
					vatRate,
					netAmount,
					taxAmount,
					foreignNetAmount:
						Number(netAmount) *
						Number(oldParentData.page1.goodsItemInfo.exchageRate),
					foreignTaxAmount:
						Number(taxAmount) *
						Number(oldParentData.page1.goodsItemInfo.exchageRate),
					purchaserCustomerGuid: purchaserCustomerGuid
						? purchaserCustomerGuid
						: null,
					purchaserTaxID: purchaserTaxID ? purchaserTaxID : null,
					purchaserTaxName: purchaserTaxName
						? purchaserTaxName
						: null,
					purchaserAddTel: purchaserAddTel ? purchaserAddTel : null,
					purchaserBankAccount: purchaserBankAccount
						? purchaserBankAccount
						: null,
					salesVendorGuid: salesVendorGuid ? salesVendorGuid : null,
					salesTaxID: salesTaxID ? salesTaxID : null,
					salesTaxName: salesTaxName ? salesTaxName : null,
					salesAddTel: salesAddTel ? salesAddTel : null,
					salesBankAccount: salesBankAccount
						? salesBankAccount
						: null,
					vatControl,
					invoiceCode: invoiceCode ? salesBankAccount : null,
					vatInvoiceFormat:
						governmentInvoice === '0' ? null : vatInvoiceFormat,
				})
			);

		const glaAccountDocumentItem1List =
			oldTab3Data.dataToBackEnd.goodsReceivedNoteList.map(
				({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				}) => ({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				})
			);

		const glaAccountDocumentItem2List =
			oldTab3Data.dataToBackEnd.newInvoiceList.map(
				({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				}) => ({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				})
			);

		const glaAccountDocumentItem3List =
			oldTab3Data.dataToBackEnd.priceVarianceList.map(
				({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				}) => ({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				})
			);

		let newData = {
			PurchaseOrderGuid:
				oldParentData.page1.goodsItemInfo.purchaseOrderGuid,
			PostingDate: oldParentData.page1.goodsItemInfo.postingDate,
			StaffGuid: oldParentData.page1.goodsItemInfo.employeeGuid
				? oldParentData.page1.goodsItemInfo.employeeGuid
				: null,
			Note: oldParentData.page1.goodsItemInfo.note,

			insertPurchaseOrderGoodsInvoicePurchaseOrderItemList:
				insertPurchaseOrderGoodsInvoicePurchaseOrderItemList,
			insertPurchaseOrderGoodsInvoiceInvoiceList:
				insertPurchaseOrderGoodsInvoiceInvoiceList,
			glaAccountDocumentItem1List: glaAccountDocumentItem1List,
			glaAccountDocumentItem2List: glaAccountDocumentItem2List,
			glaAccountDocumentItem3List: glaAccountDocumentItem3List,
		};

		return newData;
	};

	// 儲存
	const { error, sendRequest: axiosSaveData } = useAxios();

	const handleSubmit = () => {
		const newData = organizeData();
		console.log(newData);

		const url = `api/Logistics/LgsPurchaseOrderGoodsInvoice/insert`;
		const method = 'POST';
		const data = newData;

		axiosSaveData({ url, method, data }, (res) => {
			console.log(res);

			const newParentData = { ...parentData };
			newParentData.page3.receivingNumber = res;

			setParentData(newParentData);

			// res && handleNextPage();
		});
		console.log(error);
	};

	//#endregion

	//#region DialogWarning

	// 返回btn確認dialog

	const [isWarningOpen, setIsWarningOpen] = useState(false);
	const [warningMessage, setWarningMessage] = useState('');
	const [isRedirect, setIsRedirect] = useState(false);

	// Modal open
	const handleDialogWarning = () => {
		setIsWarningOpen(true);
	};

	//#endregion

	return (
		<>
			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				warningMessage={warningMessage}
				isRedirect={isRedirect}
			/>

			<Container>
				<Left>
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handlePrevPage();
							}}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="ps-14">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					<SaveSubmitBtn handleSubmit={handleSubmit} />
					<CancelSubmitBtn className={'btn-outline-primary'} />
				</Right>
			</Container>

			<TabPage
				wrapperStyle={{ minHeight: '78vh' }}
				liColWidth={'col-2'}
				tabNavList={tabNavList}
				tabContent={tabContent}
				defaultPage={0}
			/>
		</>
	);
}

export default Page3;
