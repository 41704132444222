import { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import FormControlInput from '../../../../../components/Inputs/FormControlInput';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icn_Lock.svg';
import { ReactComponent as ActivateIcon } from '../../../../../assets/images/Activate-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/icn_Trash.svg';
import { DialogConfirm } from '../DialogConfirm';
import { SubmitConfirm } from '../SubmitConfirm';
import { useTranslation } from 'react-i18next';
import { DialogStatusConfirm } from '../DialogStatusConfirm';

// #region styled-components

const Wrapper = styled.div`
	border-radius: 0 0 0.75rem 0.75rem;
`;

const NumberingRuleArea = styled.div`
	height: 610px;
	border-radius: 0;
	border: 1px solid var(--grey1);
`;

const BtnDelete = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--danger);
	border-radius: 10px;
	cursor: pointer;

	&:hover {
		background-color: var(--danger);
		svg g {
			path,
			rect {
				fill: white;
			}
		}
	}
`;
//#endregion

function DialogListStatus_RightCol_Edit(props) {
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const {
		leftActive,
		authorizedGuidName,
		rightColInfo,
		handleLeftColBtnClick,
		handleCenterRowClick,
		setRightActive,
		langList,
		getSpecList,
		salesKitGuid,
	} = props;

	// numbering rule 的參數
	const [data, setData] = useState({
		name01: '',
		name02: '',
		name03: '',
		displayOrder: '',
	});

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	// const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
	// const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
	const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
		useState(false);

	const [err, setErr] = useState({ name01: false, displayOrder: false });

	// const handleActivate = () => {
	// 	axios
	// 		.put(`api/Logistics/${leftActive}/activate`, {
	// 			[authorizedGuidName]: data[authorizedGuidName],
	// 			status: '0',
	// 			rowStamp: data.rowStamp,
	// 		})
	// 		.then((res) => navigate(-1));
	// };

	// const handleInActivate = () => {
	// 	axios
	// 		.put(`api/Logistics/${leftActive}/activate`, {
	// 			[authorizedGuidName]: data[authorizedGuidName],
	// 			status: '1',
	// 			rowStamp: data.rowStamp,
	// 		})
	// 		.then((res) => navigate(-1));
	// };

	const handleDelete = () => {
		axios
			.put(`api/Logistics/${leftActive}/delete`, {
				[authorizedGuidName]: data[authorizedGuidName],
				status: '4',
				rowStamp: data.rowStamp,
			})
			.then((res) => {
				console.log(res.data);
				handleLeftColBtnClick(leftActive);
				setIsDeleteDialogOpen(false);
				setRightActive('');
				getSpecList();
			});
	};

	const [twoOrThreeBtn, setTwoOrThreeBtn] = useState(''); // 開啟兩按鈕或三按鈕的dialog

	const checkInputs = (isActiveSaveDirectly, status, twoOrThreeBtn) => {
		// 開啟兩按鈕或三按鈕的dialog
		twoOrThreeBtn && setTwoOrThreeBtn(twoOrThreeBtn);

		// 檢查必填
		if (data?.name01 && isActiveSaveDirectly && status === '0') {
			// 啟動並存檔，不跳出存檔視窗
			setErr(false);
			handleSubmit('0');
		} else if (data?.name01 && isActiveSaveDirectly && status === '1') {
			// 停用並存檔，不跳出存檔視窗
			setErr(false);
			handleSubmit('1');
		} else if (data?.name01 && !isActiveSaveDirectly) {
			// 跳出存檔視窗，讓使用者選擇要啟動+存檔或只有存檔
			setErr(false);
			return setIsSubmitConfirmDialogOpen(true);
		} else {
			setErr(true);
		}
	};

	const handleSubmit = (status) => {
		console.log(data);

		const { name01, name02, name03, displayOrder, rowStamp } = data;

		axios
			.put(`api/Logistics/${leftActive}/update`, {
				[authorizedGuidName]: data[authorizedGuidName],
				salesKitGuid: salesKitGuid,
				name01,
				name02,
				name03,
				displayOrder,
				status,
				rowStamp,
			})
			.then((res) => {
				if (res.status === 200) {
					handleLeftColBtnClick(leftActive);
					setIsSubmitConfirmDialogOpen(false);

					// 回到detail
					handleCenterRowClick(data[authorizedGuidName]);

					getSpecList();
				} else {
					alert(res.status);
				}
			});
	};

	useEffect(() => {
		const { displayOrder, name01, name02, name03, rowStamp, status } =
			rightColInfo;

		if (Object.keys(rightColInfo).length !== 0) {
			setData({
				...data,
				displayOrder,
				status: status,
				rowStamp: rowStamp,
				name01: name01,
				name02: name02,
				name03: name03,
				[authorizedGuidName]: rightColInfo[authorizedGuidName],
			});
		}
	}, [rightColInfo]);

	return (
		<>
			<div className="h-100 bg-white position-relative">
				<div
					className={`authTitleWrapper d-flex justify-content-center ${
						rightColInfo.status === '0'
							? 'bg-grey1'
							: rightColInfo.status === '1'
							? 'bg-dangerlt'
							: ''
					}`}
					style={{
						borderRadius: '.75rem .75rem 0 0',
					}}
				>
					<h5
						className={`fw-bold text-left ${
							rightColInfo.status === '0'
								? 'text-black'
								: rightColInfo.status === '1'
								? 'text-danger'
								: ''
						} m-0`}
						style={{
							fontSize: '14px',
							lineHeight: '40px',
						}}
					>
						{rightColInfo.status === '0'
							? t('lgsSalesKit:sellable') //'可銷售'
							: rightColInfo.status === '1'
							? t('lgsSalesKit:notSellable') //'不可銷售'
							: ''}
					</h5>

					{rightColInfo.status === '1' && (
						<BtnDelete
							className="position-absolute top-0 end-0 mt-1 me-2"
							onClick={() => setIsDeleteDialogOpen(true)}
						>
							<DeleteIcon />
						</BtnDelete>
					)}
				</div>

				{/* DialogConfirm element */}
				<NumberingRuleArea>
					{/* <DialogConfirm
						t={t}
						isOpen={isActivateDialogOpen}
						setIsOpen={setIsActivateDialogOpen}
						handleClick={() => handleActivate()}
						message={t('dialog.activeConfirm')}
					/>
					<DialogConfirm
						t={t}
						isOpen={isInActivateDialogOpen}
						setIsOpen={setIsInActivateDialogOpen}
						handleClick={() => handleInActivate()}
						message={t('dialog.lockConfirm')}
					/> */}
					<DialogStatusConfirm
						t={t}
						isOpen={isDeleteDialogOpen}
						setIsOpen={setIsDeleteDialogOpen}
						handleClick={() => handleDelete()}
						message={t('dialog.deleteConfirm')}
					/>
					<SubmitConfirm
						t={t}
						isOpen={isSubmitConfirmDialogOpen}
						setIsOpen={setIsSubmitConfirmDialogOpen}
						handleClickInactive={() => handleSubmit('1')}
						handleClickActive={() => handleSubmit('0')}
						message={t('dialog.saveConfirm')}
						twoOrThreeBtn={twoOrThreeBtn}
					/>

					<div className="position-relative">
						<Wrapper className="bg-whit d-flex flex-column px-5 py-4 position-relative bg-white">
							<div className="col-12 mb-2">
								<FormControlInput
									id="displayOrder"
									type="number"
									labelText={t('lgsSalesKit:serialNumber')}
									value={data.displayOrder}
									onChange={(e) => {
										setData({
											...data,
											displayOrder: e.target.value,
										});

										setErr({
											...err,
											displayOrder:
												e.target.value === ''
													? true
													: false,
										});
									}}
									disabled={false}
									disabledCssType={0}
									isErr={err.displayOrder}
									errMsg={t('errMsg.mustFill')}
								/>
							</div>
							<div className="col-12 mb-2">
								<FormControlInput
									id="name01"
									labelText={t('lgsSalesKit:name')}
									value={data.name01}
									onChange={(e) => {
										setData({
											...data,
											name01: e.target.value,
										});

										setErr({
											...err,
											name01:
												e.target.value === ''
													? true
													: false,
										});
									}}
									disabled={false}
									disabledCssType={0}
									isErr={err.name01}
									errMsg={t('errMsg.mustFill')}
								/>
							</div>
							{langList[1] !== '' && (
								<div className="col-12 mb-2">
									<FormControlInput
										id="name02"
										labelText={`${t('lgsSalesKit:name')}( ${
											langList[1]
										} )`}
										value={data.name02}
										onChange={(e) => {
											setData({
												...data,
												name02: e.target.value,
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
								</div>
							)}
							{langList[2] !== '' && (
								<div className="col-12 mb-2">
									<FormControlInput
										id="name03"
										labelText={`${t('lgsSalesKit:name')}( ${
											langList[2]
										} )`}
										value={data.name03}
										onChange={(e) => {
											setData({
												...data,
												name03: e.target.value,
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
								</div>
							)}

							<div className="col-12 d-flex flex-column">
								{rightColInfo.status === '1' ? (
									<BtnNormal2
										className="btn-primary me-3 w-100 mb-2 py-1"
										handleClick={() =>
											checkInputs(true, '0')
										}
										word={t('lgsSalesKit:enable')} //"啟用"
										children={
											<ActivateIcon className="me-2" />
										}
										elementStyle={{ width: '88px' }}
									></BtnNormal2>
								) : (
									<BtnNormal2
										className="btn-outline-danger me-3 w-100 mb-2 py-1"
										handleClick={() =>
											checkInputs(true, '1')
										}
										word={t('lgsSalesKit:disable')} //"停用"
										children={
											<LockIcon className="lockIcon me-2" />
										}
										elementStyle={{ width: '88px' }}
									></BtnNormal2>
								)}

								<div className="d-flex">
									<BtnNormal2
										className="btn-primary mediumBtn me-2"
										handleClick={
											() =>
												checkInputs(
													false,
													'',
													rightColInfo.status === '0'
														? '2'
														: '3'
												)
											// 啟動時開啟兩btn，停用時開啟三btn確認框
										}
									>
										{t('button.saveButton')}
									</BtnNormal2>
									<BtnNormal2
										className="btn-outline-primary mediumBtn me-0"
										handleClick={() =>
											handleCenterRowClick(
												data[authorizedGuidName]
											)
										}
									>
										{t('button.cancelButton')}
									</BtnNormal2>
								</div>
							</div>
						</Wrapper>
					</div>
				</NumberingRuleArea>
			</div>
		</>
	);
}

export default DialogListStatus_RightCol_Edit;
