import styled from 'styled-components';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as CreateIcon } from '../../../../../assets/images/icn_Create.svg';
import { ReactComponent as Icn_check } from '../../../../../assets/images/icn_Check-r.svg';
import { DialogInvoice } from '../DialogInvoice';
import { DialogConfirm } from '../DialogConfirm';
import { useTranslation } from 'react-i18next';
import { DialogWarning } from '../DialogWarning';

// #region styled-components
const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody`
	& tr:hover .deleteBtn {
		display: block !important;
	}

	& tr:hover,
	& tr:hover td {
		cursor: pointer;
		background-color: var(--grey0);
	}
`;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-left: none !important;
		border-right: none !important;
	}

	position: relative;
	padding: 0;
`;

const TFoot = styled.tfoot`
	border: none;
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;

const Container = styled.div`
	display: flex;
	justify-content: start;
	margin: 16px 0;
`;

const Menu = styled.div`
	position: absolute;
	top: 0px;
	right: 0px;
	will-change: transform;
	padding: 0;
	margin-top: 3px;
	min-width: 64px;
	background-color: #ffffff;
	box-shadow: 2px 2px 6px -2px rgb(61 61 61 / 51%);
	border-radius: 0.25rem;
	color: var(--danger);

	&:hover,
	&:focus {
		color: var(--danger);
	}
`;

const Button = styled.div`
	//active-submit dropdownItem d-flex align-items-center pe-2 tex-left px-3 py-2
	display: flex;
	cursor: pointer;
	align-items: center;
	padding: 8px 16px;
	text-align: left;
	min-width: 64px;
	height: 32px;
	justify-content: center;
	svg {
		margin-right: 8px;
	}
`;
//#endregion

function InvoiceTable(props) {
	//#region state initiation
	const { t } = useTranslation(['common', 'lgsPurchaseOrderInvoice']); // i18n

	const {
		backEndData,
		parentData,
		pageData,
		setPageData,
		headTitleList,
		bodyTitleList,
		tableHeadStyles,
		tableBodyStyles,
		tableBodyData,
		setTableBodyData,
		decimalPlaceTl,
	} = props;
	// console.log(props);
	const { invoiceFormatTypeList, invoiceTaxRateTypeList } = backEndData;
	const { purchaseOrderGuidList } = parentData.page0;
	console.log('purchaseOrderGuidList', purchaseOrderGuidList);

	// cookies
	const [cookies] = useCookies();
	const CountryCode = cookies._company_country_code;

	// variable
	const isMultiSelect = purchaseOrderGuidList.length > 1; // 是否訂單多選
	let isAnyInvoice = tableBodyData.length > 0; // 是否已經有任何的發票

	console.log('pageData', pageData);
	//#endregion

	//#region function

	// 計算總額
	const evlAllAmount = (newPageData) => {
		let totalAmount = 0;
		let netAmount = 0;
		let taxAmount = 0;
		newPageData.invoiceItemList.forEach((item) => {
			totalAmount += Number(item.totalAmount);
			netAmount += Number(item.netAmount);
			taxAmount += Number(item.taxAmount);
		});
		setPageData({
			...newPageData,
			allAmount: {
				totalAmount: totalAmount,
				netAmount: netAmount,
				taxAmount: taxAmount,
			},
		});
	};

	// 前端給予新GUID
	function generateGuid() {
		var d = Date.now();
		if (
			typeof performance !== 'undefined' &&
			typeof performance.now === 'function'
		) {
			d += performance.now(); //use high-precision timer if available
		}
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g,
			function (c) {
				var r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d / 16);
				return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
			}
		);
	}
	//#endregion

	//#region DialogInvoice

	// state
	const [firstDialogName, setFirstDialogName] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState([]);

	const originInvoiceDialogData = {
		createEditDetail: '',
		invoiceTempGuid: '',
		governmentInvoice: '',
		invoiceReturn: '',
		vatControl: '',
		invoiceDate: '',
		invoiceCode: '',
		invoiceNo: '',
		vatInvoiceFormat: '',
		vatInvoiceFormatViewName: '',
		vatInvoiceTaxType: '',
		vatRate: '',
		vatInputTaxable: '0',
		netAmount: '',
		taxAmount: '',
		totalAmount: '',
		currencyName: '',
		purchaserCustomerViewName: '',
		purchaserCustomerGuid: '',
		purchaserTaxID: '',
		purchaserTaxName: '',
		purchaserAddTel: '',
		purchaserBankAccount: '',
		salesVendorViewName: '',
		salesVendorGuid: '',
		salesTaxID: '',
		salesTaxName: '',
		salesAddTel: '',
		salesBankAccount: '',
	};
	const [invoiceDialogData, setInvoiceDialogData] = useState(
		originInvoiceDialogData
	);

	const createInvoiceDialog = (btnKeyName, countryCode) => {
		// 0.此功能頁面特殊規則
		// 如果訂單多選，且已經新增一筆發票，則不能再新增發票
		if (isMultiSelect && isAnyInvoice) {
			setWarningMessage(
				t(
					'lgsPurchaseOrderInvoice:onlyOneInvoiceOrVoucherSplitAllowed'
				) + '！'
			);
			handleDialogWarning();
			return;
		}

		if (
			btnKeyName === 'govInvoiceBtn' &&
			(countryCode === 'TW' || countryCode === 'CN')
		) {
			// 當選擇"+(政府)發票"時，根據公司屬於的國家為TW或CN分別對應不同的發票畫面。如果不屬於TW或CN，則進入"其他憑證"的畫面。
			setFirstDialogName('govInvoiceBtn');
		} else {
			setFirstDialogName('otherInvoiceBtn');
		}

		setInvoiceDialogData({
			...originInvoiceDialogData,
			createEditDetail: 'create',
			invoiceTempGuid: generateGuid(),
			governmentInvoice: btnKeyName === 'govInvoiceBtn' ? '1' : '0',
			invoiceReturn: '0',
			vatControl: '1',
			vatInvoiceFormat:
				// 如果不是'govInvoiceBtn'則初始值 = ""
				btnKeyName === 'govInvoiceBtn' &&
				(countryCode === 'TW' || countryCode === 'CN')
					? invoiceFormatTypeList[0].formatTypeGuid
					: '',
			vatInvoiceFormatViewName: invoiceFormatTypeList[0].name01,
			vatInvoiceTaxType: invoiceTaxRateTypeList[0].rateTypeGuid,
			vatRate: invoiceTaxRateTypeList.find(
				(el) =>
					el.rateTypeGuid === invoiceTaxRateTypeList[0].rateTypeGuid
			).taxRate,
			// currencyName: parentData.page1.backEndData?.purchaseOrder.currencyName, // FIXME:
		});

		setIsOpen(true);
	};

	const editInvoiceDialog = (
		governmentInvoice,
		invoiceTempGuid,
		countryCode
	) => {
		if (
			governmentInvoice === '1' &&
			(countryCode === 'TW' || countryCode === 'CN')
		) {
			setFirstDialogName('govInvoiceBtn');
		} else if (governmentInvoice === '0') {
			setFirstDialogName('otherInvoiceBtn');
		}

		const newInvoiceDialogData = pageData.invoiceItemList.find(
			(el) => el.invoiceTempGuid === invoiceTempGuid
		);
		console.log(newInvoiceDialogData);
		newInvoiceDialogData.createEditDetail = 'edit';

		setInvoiceDialogData({
			...newInvoiceDialogData,
		});

		setIsOpen(true);
	};

	console.log('firstDialogName', firstDialogName);

	const deleteInvoiceItem = (e, invoiceTempGuid) => {
		e.stopPropagation();

		// 1.pageData
		const newPageData = { ...pageData };

		newPageData.invoiceItemList = newPageData.invoiceItemList.filter(
			(item) => item.invoiceTempGuid !== invoiceTempGuid
		);
		setPageData(newPageData);
		evlAllAmount(newPageData);

		// 2.tableBodyData
		let newTableBodyData = [...tableBodyData];

		newTableBodyData = newTableBodyData.filter(
			(item) => item.invoiceTempGuid !== invoiceTempGuid
		);
		setTableBodyData(newTableBodyData);
	};

	//#endregion

	//#region DialogWarning

	const [isWarningOpen, setIsWarningOpen] = useState(false);
	const [warningMessage, setWarningMessage] = useState('');

	// Modal open
	const handleDialogWarning = () => {
		setIsWarningOpen(true);
	};

	//#endregion

	return (
		<div className="position-relative">
			{/* DialogInvoice element */}
			<DialogInvoice
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				modalData={modalData}
				setModalData={setModalData}
				firstDialogName={firstDialogName}
				backEndData={backEndData}
				parentData={parentData}
				pageData={pageData}
				setPageData={setPageData}
				invoiceDialogData={invoiceDialogData}
				setInvoiceDialogData={setInvoiceDialogData}
				tableBodyData={tableBodyData}
				setTableBodyData={setTableBodyData}
				evlAllAmount={evlAllAmount}
				decimalPlaceTl={decimalPlaceTl}
			/>

			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				warningMessage={warningMessage}
			/>

			<div
				style={{ minHeight: '75vh' }}
				className="bg-white d-flex flex-column position-relative"
			>
				{/* button  */}
				<Container>
					<BtnNormal2
						className="btn-primary px-3 ms-3 me-1 mediumBtn"
						keyName="govInvoiceBtn"
						handleClick={() =>
							createInvoiceDialog('govInvoiceBtn', CountryCode)
						}
						word={t('lgsPurchaseOrderInvoice:governmentInvoice')}
					>
						<CreateIcon className="me-2" />
					</BtnNormal2>

					<BtnNormal2
						className="btn-primary px-3 mx-1 mediumBtn"
						keyName="otherInvoiceBtn"
						handleClick={() =>
							createInvoiceDialog('otherInvoiceBtn', CountryCode)
						}
						word={t('lgsPurchaseOrderInvoice:otherCertificate')}
					>
						<CreateIcon className="me-2" />
					</BtnNormal2>

					<h6 className="fw-bold fontSize75Rem text-end mt-auto mb-0 ms-auto me-3">
						{t('lgsPurchaseOrderInvoice:currency')}：
						{parentData.page0.purchaseOrderSelectedList[0]
							.currencyCode +
							' ' +
							parentData.page0.purchaseOrderSelectedList[0]
								.currencyName}
					</h6>
				</Container>

				<IndexTable className="table">
					<THead className="table-head">
						<TR>
							{headTitleList?.map((el) => (
								<TH
									key={`tableHead${headTitleList?.indexOf(
										el
									)}`}
									style={
										tableHeadStyles[
											headTitleList?.indexOf(el)
										]
									}
								>
									{el}
								</TH>
							))}
						</TR>
					</THead>
					<TBody className="">
						{tableBodyData?.map((data, rowIndex) => (
							<TR
								key={data.invoiceTempGuid}
								id={data.invoiceTempGuid}
								onClick={() =>
									editInvoiceDialog(
										data.governmentInvoice,
										data.invoiceTempGuid,
										CountryCode
									)
								}
							>
								{bodyTitleList?.map((el, inputIndex) => {
									// displayOrder
									if (el === 'displayOrder')
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`p-2 border`}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<div>{rowIndex + 1}</div>
											</TD>
										);
									// governmentInvoice || invoiceReturn
									if (
										el === 'governmentInvoice' ||
										el === 'invoiceReturn'
									)
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`p-2 border`}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												{data[el] === '1' && (
													<Icn_check />
												)}
											</TD>
										);

									// deleteBtn
									if (inputIndex === bodyTitleList.length - 1)
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`p-2 border`}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<div>{data[el]}</div>
												<Menu className="deleteBtn d-none">
													<Button
														onClick={(e) => {
															deleteInvoiceItem(
																e,
																data.invoiceTempGuid
															);
														}}
													>
														<span>
															{t(
																'common:button.deleteButton'
															)}
														</span>
													</Button>
												</Menu>
											</TD>
										);

									// others
									return (
										<TD
											style={
												tableBodyStyles[
													bodyTitleList?.indexOf(el)
												]
											}
											className={`p-2 border`}
											key={`${bodyTitleList?.indexOf(
												el
											)}`}
										>
											<div>{data[el]}</div>
										</TD>
									);
								})}
							</TR>
						))}
					</TBody>

					<TFoot>
						<FootTR
							id="total"
							key="total"
							hoverEffect={false}
							style={{ fontWeight: 'bold' }}
						>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end">
								{t('lgsPurchaseOrderInvoice:total')}
							</FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end"></FootTD>
							<FootTD className="p-2 text-end">
								{pageData.allAmount
									? pageData.allAmount.totalAmount.toFixed(
											decimalPlaceTl
									  )
									: 0}
							</FootTD>
							<FootTD className="p-2 text-end">
								{pageData.allAmount
									? pageData.allAmount.netAmount.toFixed(
											decimalPlaceTl
									  )
									: 0}
							</FootTD>
							<FootTD className="p-2 text-end">
								{pageData.allAmount
									? pageData.allAmount.taxAmount.toFixed(
											decimalPlaceTl
									  )
									: 0}
							</FootTD>
						</FootTR>
					</TFoot>
				</IndexTable>
			</div>
		</div>
	);
}

export default InvoiceTable;
