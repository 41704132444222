import React from 'react';

function ThreeColLayout({ LeftCol, CenterCol, RightCol }) {
	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="row">
						<div className="col-lg-4 px-4 pr-xl-6">{LeftCol}</div>
						<div className="col-lg-4 px-4 px-xl-5">{CenterCol}</div>
						<div className="col-lg-4 px-4 pl-xl-6">{RightCol}</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ThreeColLayout;
