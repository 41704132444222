// #region 程式說明--銷售商品主檔

/*  **銷售商品主檔**
    
    Author：
    2023/11/30: 子建: 初版
    
    **主要功能**：

    1.提供「列表」、「查詢」、「新增」、「檢視」、「修改」、「刪除」、「啟動」、「停用」、「刪除」、「異動記錄」功能
    2.列表：顯示所有銷售商品主檔，並提供搜尋功能。
    3.查詢：顯示單筆銷售商品主檔的資料，包括基本資料、規格與顏色、照片、銷售明細與價格。
    4.新增：新增銷售商品主檔及其規格與顏色、照片、銷售明細與價格。
    5.修改：修改銷售商品主檔及其規格與顏色、照片、銷售明細與價格。
    6.啟動：啟動銷售商品主檔。
    7.停用：停用銷售商品主檔。
    8.刪除：刪除銷售商品主檔。
    9.異動記錄：查看銷售商品主檔的異動記錄。
    
    **使用者步驟**：

    A. 銷售商品主檔列表
        1.從側邊欄點選「銷售商品主檔」，進入銷售商品主檔「列表」頁面。
        2.點選頁面上方的選單按鈕，將可以選擇進入「異動記錄」以查詢全部銷售商品主檔異動記錄。
        3.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行銷售商品主檔搜尋。
        4.使用者可點擊列表中每欄的標題，將以此標題為排序依據，並以反向排序或正向排序顯示銷售商品主檔列表資料。
        5.如果使用者已進行進階搜尋，則會列表上方出現已選擇的搜尋條件的小卡片，以顯示已選擇的搜尋條件。
        6.使用者可點擊上述的小卡片，以刪除該進階搜尋條件，或點擊「進階查詢」小卡片，以刪除所有進階搜尋條件。

    B. 進階搜尋
        1.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行銷售商品主檔搜尋。
        2.使用者可選擇之搜尋條件：商品代碼、名稱、狀態。
        3.使用者可選擇之搜尋邏輯：包括、不包括、等於、不等於、大於、小於、大於等於、小於等於。
        4.使用者可選擇之搜尋邏輯會根據其選擇的搜尋條件而有所變化，但不會出現上述方法以外的選項。
        5.使用者可於右方的輸入框輸入搜尋條件，輸入框的種類會有「文字」、「數字」、「日期」、「下拉選單」四種。
        6.使右方的輸入框種類會根據其選擇的搜尋條件而有所變化，但不會出現上述方法以外的選項。
        7.使用者可一個或多個搜尋條件，當多於一個搜尋條件時，會多出現一個搜尋邏輯的選項，會有「且」和「或」兩個選頂。
        8.「且」代表「且」搜尋邏輯，「或」代表「或」搜尋邏輯。
        9.當多於一個搜尋條件時，會出現一個刪除小按鈕於每一筆搜尋條件的右側，點選後會刪除該搜尋條件。
        10.在 dialog 中間會有一個橘色的刪除小按鈕，點選後會刪除全部搜尋條件。
        11.點選「確定」，進行銷售商品主檔搜尋，並回到銷售商品主檔「列表」頁面顯示搜尋結果。

    C. 新增銷售商品主檔
        1.如需新增銷售商品主檔及其資料，請點選「新增」以進入「新增」頁面。
        2.新增頁面分為上下兩部分，分別為「基本資料」和「規格與顏色、照片、銷售明細與價格」。
        3.必須要先完成「基本資料」，才能再看到「規格與顏色、照片、銷售明細與價格」。
		4.在「基本資料」部分，輸入銷售商品主檔基本資料。
		5.輸入完畢後，點選「存檔&下一步」以進行銷售商品主檔「基本資料」的新增。
		6.點選「銷售規格」後，開啟dialog，至少新增一個「規格」並使狀態為「可銷售」。
		7.點選「顏色」後，開啟dialog，至少新增一個「顏色」並使狀態為「可銷售」。
		8.每個「規格」與「顏色」代表一種分類，分別對應到自己的「照片、銷售明細與價格」。
		9.在切換不同的分類時，會對應不同的「照片、銷售明細與價格」，造成目前該分類正在輸入的資料消失。
		10.因此在切換分類時會跳出dialog，提醒使用者先儲存再切換。
        11.在「服務照片及銷售明細」部分，上傳服務照片及輸入銷售明細。
        12.在「價格」部分，除預設的「適用全部客戶」外(客戶分類Guid===null)，可點選新增「客戶分類」
		13.此時價格功能，再依照不同的分類，新增右側的「價格條件」。
		14.「照片、銷售明細與價格」皆有一個「複製」按鈕，點選後會開啟提示dialog，點選確認將儲存目前已輸入的資料。
		15.此時將再彈出一個複製的dialog，分別選取要複製來源的規格與顏色後，會將該對應區塊的資料(照片或銷售明細或價格)複製到目前的「規格與顏色」中。
        16.點選「儲存」時，使用者可選擇「存檔」或「存檔並啟用」。
        17.銷售商品主檔新增完成後，將會返回銷售商品主檔「查詢」。
        18.在新增的過程中，使用者可點選「取消」按鈕或頁面左上方的返回按鈕，以取消操作並返回銷售商品主檔列表。

    D. 查詢銷售商品主檔
        1.如需查詢銷售商品主檔服務及其資料，請點選「列表」頁面中的任一筆資料,以進入「查詢」頁面。
        2.查詢頁面分為上下兩部分，分別為「基本資料」和「規格與顏色、照片、銷售明細與價格」。
        3.在「基本資料」部分，顯示銷售商品主檔基本資料。
        4.在「規格與顏色、照片、銷售明細與價格」部分，規格與顏色、照片、銷售明細與價格。
		5.點選「銷售規格」或「顏色」按鈕，將切換下方不同對應的「照片、銷售明細與價格」頁面。
		6.點選「價格」區域內的「客戶分類」按鈕，將切換不同對應的「價格條件」。
        7.如需要修改銷售商品主檔及其資料，請點選頁面左上方的「修改」按鈕，以進入「修改」頁面。
        8.點選頁面左上方的返回按鈕，將返回銷售商品主檔列表。
        9.點選頁面上方的選單按鈕，將可以選擇「啟用」、「刪除」、「停用」或進入「異動記錄」以查詢該銷售商品主檔異動記錄。
        10.如銷售商品主檔正處於未啟用狀態，將可選擇「修改」、「啟用」、「刪除」或「異動記錄」。
        11.如銷售商品主檔正處於啟用狀態，將可選擇「修改」、「停用」或「異動記錄」。
        12.如銷售商品主檔正處於停用狀態，將可選擇「修改」、「啟用」或「異動記錄」。

    E. 修改銷售商品主檔
        1.修改頁面分為上下兩部分，分別為「基本資料」和「規格與顏色、照片、銷售明細與價格」。
		2.在「基本資料」部分，修改銷售商品主檔基本資料。
		3.點選「銷售規格」後，開啟dialog，可修改「規格」並調整狀態。
		4.點選「顏色」後，開啟dialog，可修改「顏色」並調整狀態。
		5.每個「規格」與「顏色」代表一種分類，分別對應到自己的「照片、銷售明細與價格」。
		6.在切換不同的分類時，會對應不同的「照片、銷售明細與價格」，造成目前該分類正在輸入的資料消失。
		7.因此在切換分類時會跳出dialog，提醒使用者先儲存再切換。
        8.在「服務照片及銷售明細」部分，上傳服務照片及輸入銷售明細。
        9.在「價格」部分，除預設的「適用全部客戶」外(客戶分類Guid===null)，可點選新增「客戶分類」
		10.此時價格功能，再依照不同的分類，新增右側的「價格條件」。
		11.「照片、銷售明細與價格」皆有一個「複製」按鈕，點選後會開啟提示dialog，點選確認將儲存目前已輸入的資料。
		12.此時將再彈出一個複製的dialog，分別選取要複製來源的規格與顏色後，會將該對應區塊的資料(照片或銷售明細或價格)複製到目前的「規格與顏色」中。
        13.輸入完畢後，點選「儲存」以進行銷售商品主檔的修改。
        14.點選「儲存」時，如該銷售商品主檔的狀態是「未啟用」或「停用」，使用者可選擇「存檔」或「存檔並啟用」。
        15.點選「儲存」時，如該銷售商品主檔的狀態是「啟用中」，修改會立即生效。
        16.銷售商品主檔修改完成後，將會返回銷售商品主檔查詢。
        17.在修改的過程中，使用者可點選「取消」按鈕或頁面左上方的返回按鈕，以取消操作並返回銷售商品主檔查詢。

    F. 銷售商品主檔異動記錄
        1.異動記錄以列表方式呈現，預設以時間正序排序。
        2.使用者可點擊列表中每欄的標題，將以此標題為排序依據，並以反向排序或正向排序顯示銷售商品主檔列表資料。
        3.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行異動記錄的進階搜尋。
        4.此進階查詢功能使用邏輯和「列表」頁面的進階查詢相同。

    **功能頁面**：

    (一)「新增」頁面
        1.使用者輸入：
            (1)基本資料：
                (A)商品代碼（非必填，但同公司之商品代碼不可重複）
                (B)名稱(「名稱一」為必填，「名稱二」和「名稱三」為依照多國語言選填)
                (C)幣別（必填，預設為公司貨幣）
				(D)未稅單價（必填）
				(E)銷售起始日期（必填）
				(F)銷售截止日期
                (G)商品分類（必填）
                (H)計量單位（必填）
				(I)註記
			(2)銷售規格與顏色：
				(A)規格（必填）
					(i)名稱(「名稱一」為必填，「名稱二」和「名稱三」為依照多國語言選填)
					(ii)狀態(此處可銷售0，不可銷售1，與平常狀態不同，至少一個可銷售)
				(B)顏色（必填）
					(i)名稱(「名稱一」為必填，「名稱二」和「名稱三」為依照多國語言選填)
					(ii)狀態(此處可銷售0，不可銷售1，與平常狀態不同，至少一個可銷售)
            (3)照片：
                (A)銷售商品照片
			(4)銷售明細：
                (A)銷售型態（必填）
                (B)物料Guid/服務Guid（擇一必填）
                (C)數量（必填）
                (D)價格拆分比例（必填）
                (E)備註
            (5)價格：
                (A)客戶分類
                (B)起始日期（必填）
                (C)結束日期
                (D)數量下限（必填，且為正整數）
                (E)數量上限（必填，且須大於數量下限）
                (F)未稅單價（必填）
                (G)備註

        2.儲存前檢查：
            (1)必填項目：如資料輸入有缺，將會顯示錯誤訊息於其輸入框下（請填寫資料）。
                (A)基本資料：名稱，幣別，未稅單價，銷售起始日期，商品分類，計量單位
				(B)銷售規格與顏色：
					(i)規格：名稱，狀態(可銷售)
					(ii)顏色：名稱，狀態(可銷售)
				(C)銷售明細：銷售型態，物料Guid/服務Guid，數量，價格拆分比例
                (D)價格條件：起始日期，數量下限，數量上限，未稅單價
                
            (2)輸入內容檢查：如有錯誤，將會顯示錯誤訊息於其輸入框下。
                (A)商品代碼重複：顯示「此代碼已被使用」
				(B)價格拆分比例：加總必須為100
                (C)數量上限少於數量下限：顯示「數量上限不能小於數量下限」

            (3)如輸入的資料無錯誤，則會進入儲存資料的流程。

    (二)「修改」頁面
        1.畫面邏輯幾乎與「新增」頁面相同。
        2.當銷售商品主檔的狀態不是「未啟用」時，不可修改「銷售商品分類」
*/

// #endregion 程式說明--銷售商品主檔

import { useState, useEffect, useCallback } from 'react';
import { salesKitAdvancedSearchData as searchContentList } from '../../../utils/advancedSearchData';
import styled from 'styled-components';
import useAxios from '../../../hooks/useAxios';
import { PageTitleBar } from '../../../components/exports';
import { useNavigate } from 'react-router-dom';
import { CreateBtnNormal } from '../../../components/Buttons/CreateBtnNormal';
import { SearchBtnSmall } from '../../../components/Buttons/SearchBtnSmall';
import { DropdownMenuArea } from '../../../components/DropdownMenuArea/DropdownMenuArea';
import { ReactComponent as LogIcon } from '../../../assets/images/icn_Log.svg';
import Table from '../../../components/Table/Table';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../../components/Modals/AdvancedSearchResultTag';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

// #region styled-components
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
	display: flex;
`;

//#endregion

function LgsSalesKit_Index() {
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	// 使用 useAuth Hook 取得 isLogin 和 checkAuth
	const { isLogin, checkAuth } = useAuth();

	// 在組件生命週期方法中呼叫 checkAuth 函式檢查登入狀態
	useEffect(() => {
		checkAuth();
	}, []);

	const [fetchedData, setFetchedData] = useState([]);
	const [displayColumns, setDisplayColumns] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const view = 'index';
	// const sortBy = 'orderDate';
	const [sortBy, setSortBy] = useState('salesKitCode');
	const [orderBy, setOrderBy] = useState('asc');
	const guidName = 'salesKitGuid';
	const pageTitle = '銷售商品主檔';
	const { isLoading, error, sendRequest: axiosIndexData } = useAxios();

	const [advancedSearchShow, setAdvancedSearchShow] = useState(false);

	const navigate = useNavigate();

	if (error) {
		console.log('error !', error);
	}

	const headTitleList = [
		'#',
		'商品代碼',
		'名稱',
		'商品分類',
		'幣別',
		'未稅單價',
		'備註',
	];

	const bodyTitleList = [
		'displayOrder',
		'salesKitCode',
		'name01',
		'salesKitClassCode&salesKitClassName',
		'currencyCode&currencyName',
		'listPrice',
		'status',
	];

	const tableStyles = [
		{
			minWidth: '72px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '220px',
			textAlign: 'left',
		},
		{
			minWidth: '220px',
			textAlign: 'left',
		},
		{
			minWidth: '128px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'left',
		},

		{
			minWidth: '256px',
			textAlign: 'left',
		},
	];

	const columnMustShow = ['displayOrder', 'salesKitCode', 'name01'];

	// 進階搜尋 一進去的第一筆的模板
	const advancedSearchDataTemp = {
		searchLogic: 'AND',
		column: searchContentList[0].column,
		searchOperator: '==',
		searchValue: '',
		searchContent: '',
		inputWarningContent: '',
		selectOptionList: searchContentList[0].selectOptionList,
	};

	const statusList = [
		{
			status: '0',
			name: '未啟用',
		},
		{
			status: '1',
			name: '啟用中',
		},
		{
			status: '2',
			name: '停用',
		},
	];

	// 進階搜尋實際資料
	const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
	// 進階搜尋畫面資料
	const [displayList, setDisplayList] = useState([]);

	// Advanced Search

	//todo: default sort col => orderDate

	/// 1. 第一次進入 index
	/// 2. 改變進階查詢
	/// 3. 改變筆數
	/// 4. 改變排序
	/// 都需要重新向後端拿資料, 所以合成一個 function 處理

	// advancedSearchDataList: 進階查詢資料
	// sort: 排序
	const fetchData = (
		advancedSearchDataList = null,
		pageSize = 20, // 每頁筆數
		sort = sortBy,
		pageNumber = 1
	) => {
		const url = `api/Logistics/LgsSalesKit/GetList`;
		console.log(advancedSearchDataList);
		// 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
		if (advancedSearchDataList) {
			// only "one" search data
			advancedSearchDataList[0].searchLogic = '';
		}

		axiosIndexData(
			{
				url,
				method: 'post',
				data: {
					sort,
					pageSize,
					page: pageNumber,
					advancedSearchDataList,
					TransactionCode: 'lgsSalesKit', //permission 由前端傳直給後端，判斷權限
				},
			},
			(res) => {
				console.log(res);
				const { indexData, permission, numberOfData } = res;

				// 分頁資訊
				setPaginationData({
					...paginationData,
					numberOfData: numberOfData,
					totalPage: Math.ceil(numberOfData / pageSize),
					pageNumber: pageNumber,
				});

				// table 資料
				setFetchedData({
					indexData,
					permission,
					paginationData,
				});

				function isArrayFn(obj) {
					if (typeof Array.isArray === 'function') {
						return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
					} else {
						// 否則就使用 toString 方法
						return (
							Object.prototype.toString.call(obj) ===
							'[object Array]'
						);
					}
				}

				/// 這和 api 回傳的 result 無關
				// 如果有 advancedSearchDataList, 且是 array
				// console.log('original', advancedSearchDataList);
				if (isArrayFn(advancedSearchDataList)) {
					// 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
					// 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
					advancedSearchDataList.map((el) => {
						searchContentList.map((item) => {
							// console.log(item);
							if (el.searchFilter === item.tableColumn) {
								el.searchFilter = item.searchFromDataBase;
							}
						});
					});

					// console.log('after edit', advancedSearchDataList);

					setDisplayList(advancedSearchDataList);
					setAdvancedSearchDataList(advancedSearchDataList);
				}

				handleAdvancedSearchModalClose();
				localStorage.setItem('Permission_LgsSalesKit', permission);
			}
		);
	};

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			return data;
		};

		return gettingData();
	}, []);

	const handleOpenAdvancedSearch = () => {
		console.log('handleOpenAdvancedSearch');
	};

	const handleAdvancedSearchModalClose = () => setAdvancedSearchShow(false);
	const handleAdvancedSearchModalShow = () => {
		setAdvancedSearchShow(true);
	};

	const deleteAllFilters = () => {
		setDisplayList([]);
		setAdvancedSearchDataList([]);
		fetchData();
	};

	const deleteFilter = (targetIndex) => {
		console.log('deleteFilter index', targetIndex);
		console.log(advancedSearchDataList[targetIndex]);
		console.log(displayList[targetIndex]);

		const newAdvancedSearchDataList = advancedSearchDataList.filter(
			(_, index) => index !== targetIndex
		);
		const newDisplayList = displayList.filter(
			(_, index) => index !== targetIndex
		);
		setDisplayList(newDisplayList);
		setAdvancedSearchDataList(newAdvancedSearchDataList);
		fetchData(
			newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
		);
	};

	useEffect(() => {
		// fetchIndexInfo();
		getLocalStorageData();
		fetchData();

		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);

	// console.log(advancedSearchDataList);

	return (
		<>
			<div id="indexView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={false}
				/>
				<Container>
					<Left>
						<CreateBtnNormal
							permission={fetchedData?.permission}
							word={t('button.createButton')}
						/>
					</Left>
					<Center></Center>
					<Right>
						<SearchBtnSmall
							permission={fetchedData?.permission}
							handleClick={handleAdvancedSearchModalShow}
						/>
						<DropdownMenuArea permission={fetchedData?.permission}>
							<div onClick={() => navigate('Log')}>
								<LogIcon />
								<span>{t('button.logButton')}</span>{' '}
							</div>
						</DropdownMenuArea>
					</Right>
				</Container>
				<div className="position-relative firstRowMinHeight bg-white d-flex flex-column p-4 position-relative overflow-auto">
					<AdvancedSearchResultTag
						advancedSearchDataList={advancedSearchDataList}
						deleteAllFilters={deleteAllFilters}
						deleteFilter={deleteFilter}
						ns="lgsSalesKit"
					/>
					{fetchedData?.indexData && (
						<Table
							guidName={guidName}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableData={fetchedData?.indexData}
							indexData={fetchedData?.indexData}
							sortBy={sortBy}
							setSortBy={setSortBy}
							displayColumns={displayColumns}
							setDisplayColumns={setDisplayColumns}
							paginationData={paginationData}
							setPaginationData={setPaginationData}
							fetchData={fetchData}
							fetchedData={fetchedData}
							tableStyles={tableStyles}
							columnMustShow={columnMustShow}
							rowClick={true}
							advancedSearchDataList={advancedSearchDataList}
							displaySetting={true}
							orderBy={orderBy}
							setOrderBy={setOrderBy}
						/>
					)}
				</div>
			</div>

			{advancedSearchShow && (
				<AdvancedSearchModal
					isOpen={advancedSearchShow}
					handleClose={handleAdvancedSearchModalClose}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					advancedSearchDataList={advancedSearchDataList}
					setAdvancedSearchDataList={setAdvancedSearchDataList}
					advancedSearchDataTemp={advancedSearchDataTemp}
					fetchData={fetchData}
					sortBy={sortBy}
					setSortBy={setSortBy}
					setFetchedData={setFetchedData}
					displayList={
						displayList.length > 0
							? displayList
							: [{ ...advancedSearchDataTemp }]
					}
					setDisplayList={setDisplayList}
					searchContentList={searchContentList}
					ns="lgsSalesKit"
					restSearchData={{ statusList }}
				/>
			)}
		</>
	);
}

export default LgsSalesKit_Index;
