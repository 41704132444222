import { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import FormControlInput from "../../../components/Inputs/FormControlInput";
import TextareaElement from "../../../components/Inputs/TextareaElement";
import { disabledStyle } from "../../../components/FormElement/shared/DisabledStyle";
import dayjs from "dayjs";
import axios from "axios";
import {
  convertFromNumberingRuleToRuleForUser,
  getRulePreview,
} from "./NumberingRule";

// #region styled-components

const ModalThead = styled.thead`
  //table row固定高
  padding: 0 0.75rem;
  cursor: pointer !important;
`;
const ModalTh = styled.th`
  //table row固定高
  padding: 0 0.75rem;
  cursor: pointer !important;
  border: none !important;
`;
const ModalLabel = styled.label`
  //table row固定高
  padding: 0 0.75rem;
  cursor: pointer !important;
`;
const ModalInput = styled.input`
  //table row固定高
  padding: 0 0.75rem;
  cursor: pointer !important;
`;

const SearchModalInput = styled.input`
  height: 35px;
  font-size: 14px;
  padding: 0 0.75rem;
  cursor: pointer !important;
  text-transform: uppercase;
`;

const ModalSearchBtn = styled.button`
  top: 1px;
  right: 1px;
  height: 33px;
  border-radius: 0 0.25rem 0.25rem 0;
`;

const Textarea = styled.div`
  border: 1px solid var(--grey2);
  border-radius: 4px;
  padding: 24px 28px;
  min-height: 88px;
  resize: none;
  &:focus-visible {
    outline: 0;
  }
  width: 600px;
  height: 160px;
  background-color: #f8f9fb;
`;

const Select = styled.select`
  font-size: 14px;
  border: 1px solid var(--grey2);
  border-radius: 4px;
  padding: 6px 8px;
  height: 32px;
  width: 160px;
  &:focus-visible {
    outline: 0;
  }

  :disabled {
    background: #f8f9fb;
  }
`;

//#endregion
function NumberingRuleDialog(props) {
  // console.log(props);
  const {
    modalOpen,
    handleClose,
    data,
    setData,
    handleSubmit,
    preview,
    setPreview,
    noRule,
    view,
    t,
  } = props;

  const [dialogData, setDialogData] = useState({
    ...data,
    isPrefix: data.prefix ? true : false,
    isPeriod: data.period ? true : false,
    isTimeSeparator: data.timeSeparator ? true : false,
    isSuffix: data.suffix ? true : false,
  });
  const [dialogPreview, setDialogPreview] = useState([]);
  const [dialogNoRule, setDialogNoRule] = useState("");

  const periodFormatDict = {
    y: "yy",
    ym: "yymm",
    ymd: "yymmdd",
    "y-m": "yy-mm",
    "y-m-d": "yy-mm-dd",
    Y: "yyyy",
    Ym: "yyyymm",
    Ymd: "yyyymmdd",
    "Y-m": "yyyy-mm",
    "Y-m-d": "yyyy-mm-dd",
  };

  const handleIsPrefixChange = (val) => {
    // console.log(val);
    setDialogData({
      ...dialogData,
      isPrefix: val,
      prefix: val && view !== "details" ? dialogData.prefix : "",
    });
  };
  const handleIsPeriodChange = (val) => {
    setDialogData({
      ...dialogData,
      isPeriod: val,
      period: val ? dialogData.period && view !== "details" : "",
    });
  };
  const handleIsTimeSeparatorChange = (val) => {
    setDialogData({
      ...dialogData,
      isTimeSeparator: val,
      timeSeparator: dialogData.val && view !== "details" ? timeSeparator : "",
    });
  };
  const handleIsSuffixChange = (val) => {
    setDialogData({
      ...dialogData,
      isSuffix: val,
      suffix: dialogData.val && view !== "details" ? suffix : "",
    });
  };

  const getCustomizedRule = () => {
    const {
      isPrefix,
      isPeriod,
      isTimeSeparator,
      isSuffix,
      prefix,
      period,
      timeSeparator,
      suffix,
      length,
      startNo,
      incremental,
    } = dialogData;

    const format = periodFormatDict[period] || "";

    let rule = "";

    if (isPrefix) {
      rule = `"${prefix}"`;
    }

    if (isPeriod) {
      rule += period;
    }

    if (isTimeSeparator) {
      rule += timeSeparator;
    }

    // 流水號
    rule += length;

    if (isSuffix) {
      rule += `"${suffix}"`;
    }

    setDialogNoRule(rule);

    const preview = getRulePreview({
      prefix,
      format,
      timeSeparator,
      startNo,
      incremental,
      suffix,
    });
    setDialogPreview(preview);
    //     .post('/api/logistics/lgsSalesOrderNoRule/getPrefixNumber', {
    //         dialogNoRule: dialogNoRule,
    //         number: dialogData.startNo,
    //         incremental: dialogData.incremental,
    //     })
    //     .then((res) => {
    //         setDialogPreview(res.data);
    //     })
    //     .catch((err) => console.log(err));

    // 回傳自定義規則的物件
    // return { rule, ruleForUser };
  };

  const handleCheckStartNumber = () => {
    let newStart = "";
    console.log(dialogData.length, dialogData.startNo);
    console.log(dialogData.startNo.length);
    if (dialogData.length > 15) dialogData.length = 15;
    if (dialogData.length < 0) dialogData.length = 1;

    if (dialogData.length > dialogData.startNo.length) {
      for (let i = 0; i < dialogData.length - dialogData.startNo.length; i++) {
        newStart += 0;
      }
      newStart += dialogData.startNo;
    } else if (dialogData.length < dialogData.startNo.length) {
      newStart = dialogData.startNo.slice(0, dialogData.length);
    } else {
      newStart = dialogData.startNo;
    }

    // console.log(newStart);
    setDialogData({ ...dialogData, startNo: newStart });
  };

  useEffect(() => {
    setDialogData((prevRuleData) => {
      if (prevRuleData.isPeriod && prevRuleData.period === "") {
        return {
          ...prevRuleData,
          period: "y",
        };
      } else if (!prevRuleData.isPeriod) {
        return {
          ...prevRuleData,
          period: "",
        };
      }
      return prevRuleData;
    });
  }, [dialogData.isPeriod]);

  useEffect(() => {
    setDialogData((prevRuleData) => {
      if (prevRuleData.isTimeSeparator && prevRuleData.timeSeparator === "") {
        return {
          ...prevRuleData,
          timeSeparator: "-",
        };
      } else if (!prevRuleData.isTimeSeparator) {
        return {
          ...prevRuleData,
          timeSeparator: "",
        };
      }
      return prevRuleData;
    });
  }, [dialogData.isTimeSeparator]);

  useEffect(() => {
    getCustomizedRule();
  }, [dialogData]);

  useEffect(() => {
    setDialogData({
      ...data,
      isPrefix: data.prefix ? true : false,
      isPeriod: data.period ? true : false,
      isTimeSeparator: data.timeSeparator ? true : false,
      isSuffix: data.suffix ? true : false,
    });
    setDialogNoRule(noRule);
    setDialogPreview(preview);
  }, [data]);

  // console.log(data?.status);

  return (
    <Modal
      isOpen={modalOpen}
      //onHide={handleClose}
      centered={true}
      // {...CurrencyModal}
      style={{ maxWidth: "648px" }}
    >
      <ModalHeader className="position-relative pt-4 pb-2 ps-4">
        <p style={{ fontSize: "20px" }} className="text-primary fw-bold mb-1">
          {t("lgsSalesOrderNoRule:autoNumberingSequence")}
        </p>
        <button
          style={{ top: "12px", right: "12px" }}
          type="button"
          className="close position-absolute btn"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="icn_Clear"
            viewBox="0 0 16 16"
            width="16"
            height="16"
          >
            <defs>
              <clipPath id="clip-path-clear22">
                <rect
                  id="Rectangle_3088"
                  fill="#686868"
                  width="10"
                  height="10"
                  data-name="Rectangle 3088"
                />
              </clipPath>
            </defs>
            <rect
              id="Rectangle_3086"
              fill="none"
              width="16"
              height="16"
              data-name="Rectangle 3086"
            />
            <g
              id="Group_6190"
              transform="translate(3 3)"
              data-name="Group 6190"
            >
              <g
                id="Group_6188"
                clipPath='url("#clip-path")'
                data-name="Group 6188"
              >
                <path
                  id="Path_1410"
                  fill="#686868"
                  transform="translate(0)"
                  d="M 1.134 0.188 a 0.669 0.669 0 0 0 -0.946 0.946 L 4.054 5 L 0.188 8.866 a 0.669 0.669 0 0 0 0.946 0.946 L 5 5.946 L 8.866 9.812 a 0.669 0.669 0 0 0 0.946 -0.946 L 5.946 5 L 9.812 1.134 A 0.669 0.669 0 0 0 8.866 0.188 L 5 4.054 Z"
                  data-name="Path 1410"
                />
              </g>
            </g>
          </svg>
        </button>
      </ModalHeader>
      <ModalBody className="px-4 pt-4 pb-0">
        <form asp-action="Index" method="get">
          <div className="modal-body p-0">
            <div className="wrap mx-3">
              <div
                className="mb-3"
                style={{ color: "#0C2556", fontWeight: "bold" }}
              >
                {t("lgsSalesOrderNoRule:noRule")}
              </div>
              <div style={{ height: "348px" }}>
                <div className="row">
                  <div className="form-group col-lg-5">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isPrefix"
                        name="isPrefix"
                        key="isPrefix"
                        onChange={(e) => handleIsPrefixChange(e.target.checked)}
                        checked={dialogData.isPrefix}
                        disabled={
                          view === "details" ||
                          (data?.status !== "0" && data?.status)
                        }
                      />
                      {t("lgsSalesOrderNoRule:prefix")}
                    </div>
                    <FormControlInput
                      id="prefix"
                      name="prefix"
                      key="prefix"
                      disabled={
                        view === "details" ||
                        !dialogData.isPrefix ||
                        (data?.status !== "0" && data?.status)
                      }
                      value={dialogData.prefix}
                      onChange={(e) => {
                        setDialogData({
                          ...dialogData,
                          prefix: e.target.value,
                        });
                      }}
                      elementStyle={{ width: "160px" }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-5">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="isPeriod"
                          name="isPeriod"
                          key="isPeriod"
                          onChange={(e) =>
                            handleIsPeriodChange(e.target.checked)
                          }
                          checked={dialogData.isPeriod}
                          disabled={
                            view === "details" ||
                            (data?.status !== "0" && data?.status)
                          }
                        />
                        {t("lgsSalesOrderNoRule:period")}
                      </label>
                    </div>
                    <Select
                      id="period"
                      name="period"
                      key="period"
                      type="select"
                      disabled={
                        view === "details" ||
                        !dialogData.isPeriod ||
                        (data?.status !== "0" && data?.status)
                      }
                      value={dialogData.period}
                      onChange={(e) => {
                        setDialogData({
                          ...dialogData,
                          period: e.target.value,
                        });
                      }}
                      className="wrapper d-flex flex-column mb-3"
                    >
                      {!dialogData.isPeriod && <option value=""></option>}
                      <option value="y">YY</option>
                      <option value="ym">YYMM</option>
                      <option value="ymd">YYMMDD</option>
                      <option value="y-m">YY-MM</option>
                      <option value="y-m-d">YY-MM-DD</option>
                      <option value="Y">YYYY</option>
                      <option value="Ym">YYYYMM</option>
                      <option value="Ymd">YYYYMMDD</option>
                      <option value="Y-m">YYYY-MM</option>
                      <option value="Y-m-d">YYYY-MM-DD</option>
                    </Select>
                  </div>
                  <div className="form-group col-lg-4">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="isTimeSeparator"
                          name="isTimeSeparator"
                          key="isTimeSeparator"
                          onChange={(e) =>
                            handleIsTimeSeparatorChange(e.target.checked)
                          }
                          checked={dialogData.isTimeSeparator}
                          disabled={
                            view === "details" ||
                            (data?.status !== "0" && data?.status)
                          }
                        />
                        {t("lgsSalesOrderNoRule:timeSeparator")}
                      </label>
                    </div>
                    <Select
                      id="timeSeparator"
                      name="timeSeparator"
                      key="timeSeparator"
                      disabled={
                        view === "details" ||
                        !dialogData.isTimeSeparator ||
                        (data?.status !== "0" && data?.status)
                      }
                      value={dialogData.timeSeparator}
                      onChange={(e) => {
                        setDialogData({
                          ...dialogData,
                          timeSeparator: e.target.value,
                        });
                      }}
                    >
                      {!dialogData.isTimeSeparator && (
                        <option value=""></option>
                      )}
                      <option value="-">-</option>
                      <option value="#">#</option>
                      <option value="*">*</option>
                      <option value="_">_</option>
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-lg-5">
                    <div className="mb-2">
                      <label className="">
                        {t("lgsSalesOrderNoRule:length")}
                      </label>
                    </div>
                    <FormControlInput
                      id="length"
                      name="length"
                      key="length"
                      type="number"
                      value={dialogData.length}
                      onChange={(e) => {
                        setDialogData({
                          ...dialogData,
                          length: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        handleCheckStartNumber(dialogData.startNo);
                      }}
                      disabled={
                        view === "details" ||
                        (data?.status !== "0" && data?.status)
                      }
                      property={{
                        max: 15,
                      }}
                    />
                  </div>
                  <div className="form-group col-lg-5">
                    <div className="mb-2">
                      <label className="">
                        {t("lgsSalesOrderNoRule:startNo")}
                      </label>
                    </div>
                    <FormControlInput
                      id="startNo"
                      name="startNo"
                      key="startNo"
                      type="number"
                      value={dialogData.startNo}
                      onChange={(e) => {
                        setDialogData({
                          ...dialogData,
                          startNo: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        handleCheckStartNumber(e.target.value);
                      }}
                      disabled={
                        view === "details" ||
                        (data?.status !== "0" && data?.status)
                      }
                    />
                  </div>
                  <div className="form-group col-lg-2">
                    <div className="mb-2">
                      <label className="">
                        {t("lgsSalesOrderNoRule:incremental")}
                      </label>
                    </div>
                    <FormControlInput
                      id="incremental"
                      name="incremental"
                      key="incremental"
                      type="number"
                      value={dialogData.incremental}
                      onChange={(e) => {
                        setDialogData({
                          ...dialogData,
                          incremental: e.target.value,
                        });
                      }}
                      property={{ min: "1" }}
                      disabled={
                        view === "details" ||
                        (data?.status !== "0" && data?.status)
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-5">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="isSuffix"
                          name="isSuffix"
                          key="isSuffix"
                          onChange={(e) =>
                            handleIsSuffixChange(e.target.checked)
                          }
                          checked={dialogData.isSuffix}
                          disabled={
                            view === "details" ||
                            (data?.status !== "0" && data?.status)
                          }
                        />
                        {t("lgsSalesOrderNoRule:suffix")}
                      </label>
                    </div>
                    <FormControlInput
                      id="suffix"
                      name="suffix"
                      key="suffix"
                      disabled={
                        view === "details" ||
                        !dialogData.isSuffix ||
                        (data?.status !== "0" && data?.status)
                      }
                      value={dialogData.suffix}
                      onChange={(e) => {
                        setDialogData({
                          ...dialogData,
                          suffix: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Textarea disabled>
              <div
                style={{
                  color: "#0C2556",
                  fontWeight: "bold",
                }}
                className="mb-3"
              >
                {t("lgsSalesOrderNoRule:preview")}
              </div>
              <div className="mb-2">{dialogPreview[0]}</div>
              <div className="mb-2">{dialogPreview[1]}</div>
              <div className="mb-2">{dialogPreview[2]}</div>
            </Textarea>
          </div>
          <div className="modal-footer justify-content-center border-0 pt-4 pb-5">
            <button
              type="button"
              className="btn btn-primary mediumBtn mx-1"
              onClick={() => {
                (!data?.status || data?.status === "0") &&
                  handleSubmit(
                    dialogNoRule,
                    dialogData,
                    dialogData.startNo,
                    dialogData.incremental
                  );
                handleClose();
              }}
            >
              {t("button.confirmButton")}
            </button>
            <button
              type="button"
              className="btn btn-outline-primarylllt mediumBtn mx-1"
              onClick={handleClose}
              // onClick="cancelTableModal(this, 'personnel')"
            >
              {t("button.cancelButton")}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default NumberingRuleDialog;
