import { useState, useEffect } from 'react';
import { Table } from '../../../../../../components/exports';
import { ReactComponent as Icn_check } from '../../../../../../assets/images/icn_Check-r.svg';
import { useTranslation } from 'react-i18next';

function LgsPurchaseOrderPage4Tab_PaymentTerm({ purchaseOrderData }) {
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);
	const [tableData, setTableData] = useState([]);
	const [tableFooter, setTableFooter] = useState([]);

	const headTitleList = [
		'#',
		t('lgsPurchaseOrder:paymentDateBasis'),
		t('lgsPurchaseOrder:paymentDateDays'),
		t('lgsPurchaseOrder:paymentDate'),
		t('lgsPurchaseOrder:paymentAmount'),
		t('lgsPurchaseOrder:paymentPercentage'),
		t('lgsPurchaseOrder:annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'baseOn',
		'days',
		'payDate',
		purchaseOrderData.page0.foreignCurrency ? 'foreignAmount' : 'amount',
		'percentage',
		'note',
	];

	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '304px',
			textAlign: 'center',
		},
	];

	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'right',
		},
		{
			width: '160px',
			textAlign: 'right',
		},
		{
			width: '304px',
			textAlign: 'left',
		},
	];

	// console.log('purchaseOrderData', purchaseOrderData);

	let purchaseOrderDetails = [];
	if (purchaseOrderData.page0.foreignCurrency) {
		purchaseOrderDetails = purchaseOrderData.page2.paymentTermList.map(
			({
				displayOrder,
				baseOn,
				days,
				payDate,
				foreignAmount,
				percentage,
				note,
			}) => ({
				displayOrder,
				baseOn,
				days,
				payDate,
				foreignAmount,
				percentage,
				note,
			})
		);
	} else {
		purchaseOrderDetails = purchaseOrderData.page2.paymentTermList.map(
			({
				displayOrder,
				baseOn,
				days,
				payDate,
				amount,
				percentage,
				note,
			}) => ({
				displayOrder,
				baseOn,
				days,
				payDate,
				amount,
				percentage,
				note,
			})
		);
	}

	let totalsCalculate = purchaseOrderData.page2.totals;
	console.log(purchaseOrderDetails);

	useEffect(() => {
		setTableData(purchaseOrderDetails);
		setTableFooter([
			'',
			'',
			'',
			t('lgsPurchaseOrder:totalAmount'),
			totalsCalculate.totalAmount,
			totalsCalculate.totalPercentage,
			'',
		]);
	}, []);
	console.log('purchaseOrderDetails', purchaseOrderDetails);

	return (
		<>
			{tableData && (
				<Table
					guidName="displayOrder"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					tableData={tableData}
					tableFooter={tableFooter}
				></Table>
			)}
		</>
	);
}

export default LgsPurchaseOrderPage4Tab_PaymentTerm;
