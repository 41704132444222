import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import FormControlInput from '../../../components/Inputs/FormControlInput';
import TextareaElement from '../../../components/Inputs/TextareaElement';
import { disabledStyle } from '../../../components/FormElement/shared/DisabledStyle';
import dayjs from 'dayjs';
import axios from 'axios';

// #region styled-components

const ModalThead = styled.thead`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalTh = styled.th`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
	border: none !important;
`;
const ModalLabel = styled.label`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalInput = styled.input`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;

const SearchModalInput = styled.input`
	height: 35px;
	font-size: 14px;
	padding: 0 0.75rem;
	cursor: pointer !important;
	text-transform: uppercase;
`;

const ModalSearchBtn = styled.button`
	top: 1px;
	right: 1px;
	height: 33px;
	border-radius: 0 0.25rem 0.25rem 0;
`;

const Textarea = styled.div`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 24px 28px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
	width: 600px;
	height: 160px;
	background-color: #f8f9fb;
`;

const Select = styled.select`
	font-size: 14px;
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	width: 160px;
	&:focus-visible {
		outline: 0;
	}

	:disabled {
		background: #f8f9fb;
	}
`;

//#endregion
function NumberingRuleDialog(props) {
	// console.log(props);
	const {
		modalOpen,
		handleClose,
		data,
		setData,
		handleSubmit,
		preview,
		setPreview,
	} = props;

	const [ruleData, setRuleData] = useState({
		isPrefix: false,
		isPeriod: false,
		isTimeSeparator: false,
		isSuffix: false,
		prefix: '',
		period: '',
		timeSeparator: '',
		suffix: '',
		length: 3,
		startNo: '001',
		incremental: 1,
	});
	const [rulePreview, setRulePreview] = useState([]);
	const [ruleForUser, setRuleForUser] = useState('');
	const [noRule, setNoRule] = useState('');

	const periodFormatDict = {
		y: 'yy',
		ym: 'yymm',
		ymd: 'yymmdd',
		'y-m': 'yy-mm',
		'y-m-d': 'yy-mm-dd',
		Y: 'yyyy',
		Ym: 'yyyymm',
		Ymd: 'yyyymmdd',
		'Y-m': 'yyyy-mm',
		'Y-m-d': 'yyyy-mm-dd',
	};

	const handleIsPrefixChange = (val) => {
		// console.log(val);
		setRuleData({
			...ruleData,
			isPrefix: val,
			prefix: val ? ruleData.prefix : '',
		});
	};
	const handleIsPeriodChange = (val) => {
		setRuleData({
			...ruleData,
			isPeriod: val,
			period: val ? ruleData.period : '',
		});
	};
	const handleIsTimeSeparatorChange = (val) => {
		setRuleData({
			...ruleData,
			isTimeSeparator: val,
			timeSeparator: ruleData.val ? timeSeparator : '',
		});
	};
	const handleIsSuffixChange = (val) => {
		setRuleData({
			...ruleData,
			isSuffix: val,
			suffix: ruleData.val ? suffix : '',
		});
	};

	const getCustomizedRule = () => {
		const {
			isPrefix,
			isPeriod,
			isTimeSeparator,
			isSuffix,
			prefix,
			period,
			timeSeparator,
			suffix,
			length,
			startNo,
			incremental,
		} = ruleData;

		let rule = '';
		let ruleForUser = '';

		if (isPrefix) {
			rule = `"${prefix}"`;
			ruleForUser = `"${prefix}"`;
		}

		if (isPeriod) {
			const periodFormat = periodFormatDict[period] || '';
			rule += period;
			ruleForUser += periodFormat;
		}

		if (isTimeSeparator) {
			rule += timeSeparator;
			ruleForUser += timeSeparator;
		}

		// 流水號
		rule += length;
		ruleForUser += '0'.repeat(length);

		if (isSuffix) {
			rule += `"${suffix}"`;
			ruleForUser += `"${suffix}"`;
		}

		setNoRule(rule);
		setRuleForUser(ruleForUser);

		// console.log(rule);
		// console.log(ruleForUser);

		getRulePreview(rule);
		// axios
		//     .post('/api/logistics/lgsPurchaseOrderNoRule/getPrefixNumber', {
		//         noRule: noRule,
		//         number: ruleData.startNo,
		//         incremental: ruleData.incremental,
		//     })
		//     .then((res) => {
		//         setRulePreview(res.data);
		//     })
		//     .catch((err) => console.log(err));

		// 回傳自定義規則的物件
		// return { rule, ruleForUser };
	};

	// const getRulePreview = () => {
	//     let previewPartial = {
	//         head: '',
	//         middle: [],
	//         end: '',
	//     };

	//     /// Head 字串組成
	//     // 插入「前綴字」到 head
	//     previewPartial.head += ruleData.prefix;

	//     // 插入「週期」到 head
	//     const dateFormat = periodFormatDict[ruleData.period]?.toUpperCase();
	//     const serialSample = dateFormat ? dayjs().format(dateFormat) : '';
	//     previewPartial.head += serialSample;

	//     // 插入「時間後方分隔字元」到 head
	//     previewPartial.head += ruleData.timeSeparator;

	//     /// Middle 第一個字串組成
	//     // 起始序號初始化
	//     let serial = '';
	//     for (let i = 0; i < ruleData.length - 1; i++) {
	//         serial += 0;
	//     }

	//     const firstPreviewItem = serial + Number(ruleData.startNo);
	//     previewPartial.middle.push(firstPreviewItem);

	//     const secondPreviewItem =
	//         serial + (Number(ruleData.startNo) + Number(ruleData.incremental));
	//     previewPartial.middle.push(secondPreviewItem);

	//     const thirdPreviewItem =
	//         serial +
	//         (Number(ruleData.startNo) +
	//             Number(ruleData.incremental) +
	//             Number(ruleData.incremental));
	//     previewPartial.middle.push(thirdPreviewItem);

	//     /// End 字串組成
	//     // 插入「後綴字」到 end
	//     previewPartial.end += ruleData.suffix;

	//     const newRuleSample = [...rulePreview];
	//     newRuleSample[0] =
	//         previewPartial.head + previewPartial.middle[0] + previewPartial.end;
	//     newRuleSample[1] =
	//         previewPartial.head + previewPartial.middle[1] + previewPartial.end;
	//     newRuleSample[2] =
	//         previewPartial.head + previewPartial.middle[2] + previewPartial.end;

	//     setRulePreview(newRuleSample);

	//     // console.log(previewPartial);
	// };

	const getRulePreview = (rule) => {
		// console.log(rule);
		const previewPartial = {
			head: '',
			middle: [],
			tail: '',
		};

		/// Head 字串組成
		// 插入「前綴字」到 head
		const firstIndex = rule.indexOf('"');
		const secondIndex = rule.indexOf('"', firstIndex + 1);
		if (firstIndex === 0) {
			const prefix = rule.substring(firstIndex + 1, secondIndex);
			// console.log(prefix);

			previewPartial.head = prefix;

			const stringNoPrefix = rule.slice(secondIndex);
			// console.log(stringNoPrefix);
			// 插入「週期」到 head
			if (periodFormatDict.hasOwnProperty(stringNoPrefix[1])) {
				const dateFormat =
					periodFormatDict[rule[secondIndex + 1]].toUpperCase();
				const datePrefix = dayjs().format(dateFormat);

				previewPartial.head += datePrefix;
			}
		}

		// 插入「時間後方分隔字元」到 head
		// console.log(previewPartial);
	};

	const handleCheckStartNumber = () => {
		let newStart = '';
		// console.log(ruleData.length, ruleData.startNo);
		// console.log(ruleData.startNo.length);

		if (ruleData.length > ruleData.startNo.length) {
			for (
				let i = 0;
				i < ruleData.length - ruleData.startNo.length;
				i++
			) {
				newStart += 0;
			}
			newStart += ruleData.startNo;
		} else if (ruleData.length < ruleData.startNo.length) {
			newStart = ruleData.startNo.slice(0, ruleData.length);
		} else {
			newStart = ruleData.startNo;
		}

		console.log(newStart);
		setRuleData({ ...ruleData, startNo: newStart });
	};

	useEffect(() => {
		setRuleData((prevRuleData) => {
			if (prevRuleData.isPeriod && prevRuleData.period === '') {
				return {
					...prevRuleData,
					period: 'y',
				};
			} else if (!prevRuleData.isPeriod) {
				return {
					...prevRuleData,
					period: '',
				};
			}
			return prevRuleData;
		});
	}, [ruleData.isPeriod]);

	useEffect(() => {
		setRuleData((prevRuleData) => {
			if (
				prevRuleData.isTimeSeparator &&
				prevRuleData.timeSeparator === ''
			) {
				return {
					...prevRuleData,
					timeSeparator: '-',
				};
			} else if (!prevRuleData.isTimeSeparator) {
				return {
					...prevRuleData,
					timeSeparator: '',
				};
			}
			return prevRuleData;
		});
	}, [ruleData.isTimeSeparator]);

	useEffect(() => {
		getCustomizedRule();
	}, [ruleData]);

	// useEffect(() => {
	//     axios
	//         .post('/api/logistics/lgsPurchaseOrderNoRule/getPrefixNumber', {
	//             noRule: noRule,
	//             number: ruleData.startNo,
	//             incremental: ruleData.incremental,
	//         })
	//         .then((res) => {
	//             setRulePreview(res.data);
	//         })
	//         .catch((err) => console.log(err));
	// }, [ruleData]);

	useEffect(() => {
		setRuleData({ ...ruleData, ...data });
	}, [data]);

	// console.log(ruleData);

	return (
		<Modal
			isOpen={modalOpen}
			//onHide={handleClose}
			centered={true}
			// {...CurrencyModal}
			style={{ maxWidth: '648px' }}
		>
			<ModalHeader className="position-relative pt-4 pb-2 ps-4">
				<p
					style={{ fontSize: '20px' }}
					className="text-primary fw-bold mb-1"
				>
					自動編號規則
				</p>
				<button
					style={{ top: '12px', right: '12px' }}
					type="button"
					className="close position-absolute btn"
					onClick={handleClose}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						id="icn_Clear"
						viewBox="0 0 16 16"
						width="16"
						height="16"
					>
						<defs>
							<clipPath id="clip-path-clear22">
								<rect
									id="Rectangle_3088"
									fill="#686868"
									width="10"
									height="10"
									data-name="Rectangle 3088"
								/>
							</clipPath>
						</defs>
						<rect
							id="Rectangle_3086"
							fill="none"
							width="16"
							height="16"
							data-name="Rectangle 3086"
						/>
						<g
							id="Group_6190"
							transform="translate(3 3)"
							data-name="Group 6190"
						>
							<g
								id="Group_6188"
								clipPath='url("#clip-path")'
								data-name="Group 6188"
							>
								<path
									id="Path_1410"
									fill="#686868"
									transform="translate(0)"
									d="M 1.134 0.188 a 0.669 0.669 0 0 0 -0.946 0.946 L 4.054 5 L 0.188 8.866 a 0.669 0.669 0 0 0 0.946 0.946 L 5 5.946 L 8.866 9.812 a 0.669 0.669 0 0 0 0.946 -0.946 L 5.946 5 L 9.812 1.134 A 0.669 0.669 0 0 0 8.866 0.188 L 5 4.054 Z"
									data-name="Path 1410"
								/>
							</g>
						</g>
					</svg>
				</button>
			</ModalHeader>
			<ModalBody className="px-4 pt-4 pb-0">
				<form asp-action="Index" method="get">
					<div className="modal-body p-0">
						<div className="wrap mx-3">
							<div
								className="mb-3"
								style={{ color: '#0C2556', fontWeight: 'bold' }}
							>
								規則
							</div>
							<div style={{ height: '348px' }}>
								<div className="row">
									<div className="form-group col-lg-5">
										<div className="form-check">
											<input
												className="form-check-input"
												type="checkbox"
												id="isPrefix"
												name="isPrefix"
												key="isPrefix"
												onChange={(e) =>
													handleIsPrefixChange(
														e.target.checked
													)
												}
												checked={ruleData.isPrefix}
											/>
											前綴字
										</div>
										<FormControlInput
											id="prefix"
											name="prefix"
											key="prefix"
											disabled={!ruleData.isPrefix}
											value={ruleData.prefix}
											onChange={(e) => {
												setRuleData({
													...ruleData,
													prefix: e.target.value,
												});
											}}
											elementStyle={{ width: '160px' }}
										/>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-lg-5">
										<div className="form-check">
											<label className="form-check-label">
												<input
													className="form-check-input"
													type="checkbox"
													id="isPeriod"
													name="isPeriod"
													key="isPeriod"
													onChange={(e) =>
														handleIsPeriodChange(
															e.target.checked
														)
													}
													checked={ruleData.isPeriod}
												/>
												週期
											</label>
										</div>
										<Select
											id="period"
											name="period"
											key="period"
											type="select"
											disabled={!ruleData.isPeriod}
											value={ruleData.period}
											onChange={(e) => {
												setRuleData({
													...ruleData,
													period: e.target.value,
												});
											}}
											className="wrapper d-flex flex-column mb-3"
										>
											{!ruleData.isPeriod && (
												<option value=""></option>
											)}
											<option value="y">YY</option>
											<option value="ym">YYMM</option>
											<option value="ymd">YYMMDD</option>
											<option value="y-m">YY-MM</option>
											<option value="y-m-d">
												YY-MM-DD
											</option>
											<option value="Y">YYYY</option>
											<option value="Ym">YYYYMM</option>
											<option value="Ymd">
												YYYYMMDD
											</option>
											<option value="Y-m">YYYY-MM</option>
											<option value="Y-m-d">
												YYYY-MM-DD
											</option>
										</Select>
									</div>
									<div className="form-group col-lg-4">
										<div className="form-check">
											<label className="form-check-label">
												<input
													className="form-check-input"
													type="checkbox"
													id="isTimeSeparator"
													name="isTimeSeparator"
													key="isTimeSeparator"
													onChange={(e) =>
														handleIsTimeSeparatorChange(
															e.target.checked
														)
													}
													checked={
														ruleData.isTimeSeparator
													}
												/>
												時間後方分隔字元
											</label>
										</div>
										<Select
											id="timeSeparator"
											name="timeSeparator"
											key="timeSeparator"
											disabled={!ruleData.isTimeSeparator}
											value={ruleData.timeSeparator}
											onChange={(e) => {
												setRuleData({
													...ruleData,
													timeSeparator:
														e.target.value,
												});
											}}
										>
											{!ruleData.isTimeSeparator && (
												<option value=""></option>
											)}
											<option value="-">-</option>
											<option value="#">#</option>
											<option value="*">*</option>
											<option value="_">_</option>
										</Select>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-lg-5">
										<div className="mb-2">
											<label className="">
												連續號碼長度
											</label>
										</div>
										<FormControlInput
											id="length"
											name="length"
											key="length"
											type="number"
											value={ruleData.length}
											onChange={(e) => {
												setRuleData({
													...ruleData,
													length: e.target.value,
												});
											}}
											onBlur={(e) => {
												handleCheckStartNumber(
													ruleData.startNo
												);
											}}
										/>
									</div>
									<div className="form-group col-lg-5">
										<div className="mb-2">
											<label className="">起始序號</label>
										</div>
										<FormControlInput
											id="startNo"
											name="startNo"
											key="startNo"
											type="number"
											value={ruleData.startNo}
											onChange={(e) => {
												setRuleData({
													...ruleData,
													startNo: e.target.value,
												});
											}}
											onBlur={(e) => {
												handleCheckStartNumber(
													e.target.value
												);
											}}
										/>
									</div>
									<div className="form-group col-lg-2">
										<div className="mb-2">
											<label className="">增量值</label>
										</div>
										<FormControlInput
											id="incremental"
											name="incremental"
											key="incremental"
											type="number"
											value={ruleData.incremental}
											onChange={(e) => {
												setRuleData({
													...ruleData,
													incremental: e.target.value,
												});
											}}
											property={{ min: '1' }}
										/>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-5">
										<div className="form-check">
											<label className="form-check-label">
												<input
													className="form-check-input"
													type="checkbox"
													id="isSuffix"
													name="isSuffix"
													key="isSuffix"
													onChange={(e) =>
														handleIsSuffixChange(
															e.target.checked
														)
													}
													checked={ruleData.isSuffix}
												/>
												後綴字
											</label>
										</div>
										<FormControlInput
											id="suffix"
											name="suffix"
											key="suffix"
											disabled={!ruleData.isSuffix}
											value={ruleData.suffix}
											onChange={(e) => {
												setRuleData({
													...ruleData,
													suffix: e.target.value,
												});
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						<Textarea disabled>
							<div
								style={{
									color: '#0C2556',
									fontWeight: 'bold',
								}}
								className="mb-3"
							>
								示例
							</div>
							<div className="mb-2">{rulePreview[0]}</div>
							<div className="mb-2">{rulePreview[1]}</div>
							<div className="mb-2">{rulePreview[2]}</div>
						</Textarea>
					</div>
					<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
						<button
							type="button"
							className="btn btn-primary mediumBtn mx-1"
							onClick={() => {
								handleSubmit(
									ruleData,
									ruleForUser,
									rulePreview,
									noRule
								);
								handleClose();
							}}
						>
							確定
						</button>
						<button
							type="button"
							className="btn btn-outline-primarylllt mediumBtn mx-1"
							onClick={handleClose}
							// onClick="cancelTableModal(this, 'personnel')"
						>
							取消
						</button>
					</div>
				</form>
			</ModalBody>
		</Modal>
	);
}

export default NumberingRuleDialog;
