import React, { useState } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { ReactComponent as Icn_chevronTop } from '../../../../../assets/images/icn_chevron-top.svg';
import { ReactComponent as Icn_Trash } from '../../../../../assets/images/icn_Trash.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import { DialogTwoCol } from './DialogTwoCol';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const TR = styled.tr`
	height: 40px;

	&:hover {
		background-color: transparent !important;
	}
	&:hover:nth-of-type(0) {
		background-color: var(--primaryllt) !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: none !important;
	background-color: #f7f8fc !important;
	text-align: left !important;
	font-weight: bold !important;

	&:nth-of-type(1) {
		width: 48px;
	}

	&:nth-of-type(2) {
		width: 96px;
	}

	&:nth-of-type(3) {
		width: 336px;
		padding: 0 !important;
		padding-right: 56px !important;
	}
	&:nth-of-type(6) {
		text-align: right !important;
	}
`;

const ChildTD = styled.td`
	height: 40px;
	text-align: center;

	&:first-of-type {
		width: 144px;
	}
`;

const OrderItemImg = styled.img`
	max-width: 96px;
	max-height: 88px;
	object-fit: cover;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
`;

// -----DialogTwoCol------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

function Page1_Item(props) {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);

	// prop
	const {
		dialogDataFromBE,
		parentData,
		newPurchaseOrderData,
		setNewPurchaseOrderData,
		localDecimalPlaceTl,
		localDecimalPlaceUp,
		convertToFloat,
		reCalculateTopInfo,
	} = props;

	const { inputTaxRate, vendorGuid, orderDate, currencyCode } =
		parentData.page0;

	console.log('newPurchaseOrderData', newPurchaseOrderData);

	// axios
	const { sendRequest: axiosGetVendorPrice } = useAxios(); // call api 計算 價格差異

	// 型態 => name轉換
	const purchaseCategoryCodeList = {
		M: t('lgsPurchaseOrder:material'),
		E: t('lgsPurchaseOrder:expense'),
		A: t('lgsPurchaseOrder:asset'),
	};

	//#endregion

	//#region handle input change
	const handleInputChange = (keyName, rowIndex, value, decimalPlace) => {
		// console.log(keyName, rowIndex, value, decimalPlace);

		// 1.更新newPurchaseOrderData
		const newData = [...newPurchaseOrderData];

		if (decimalPlace) {
			// 如果此input有decimalPlace限制
			const regex = new RegExp(`^(\\d+(\\.\\d{0,${decimalPlace}})?|)$`); // 只允許數字，並且小數點後最多decimalPlace位
			if (regex.test(value)) newData[rowIndex][keyName] = value;
			newData[rowIndex][`${keyName}Warning`] = '';
			setNewPurchaseOrderData(newData);
		} else {
			// 一般的input
			newData[rowIndex][keyName] = value; // input值
			newData[rowIndex][`${keyName}Warning`] = ''; // 清空錯誤訊息
			setNewPurchaseOrderData(newData);
		}

		// 2.重新計算topInfo數值
		reCalculateTopInfo(newData);
	};

	//#endregion

	//#region handle delete card
	const handleDeleteChange = (rowIndex) => {
		// 1.更新newPurchaseOrderData
		const newData = [...newPurchaseOrderData];

		newData.splice(rowIndex, 1);
		console.log('刪除後newPurchaseOrderData', newData);
		setNewPurchaseOrderData(newData);

		// 2.重新計算topInfo數值
		reCalculateTopInfo(newData);
	};

	//#endregion

	//#region Dialog Two Col
	const [isOpen, setIsOpen] = useState(false);
	const [indexDialogTwoCol, setIndexDialogTwoCol] = useState('');

	// DialogTwoCol input 黃色按鈕取消
	const handleCancelClick = (index) => {
		// 更新newPurchaseOrderData
		const newData = [...newPurchaseOrderData];
		newData[index]['uomCode'] = '';
		newData[index]['uomName01'] = '';
		newData[index]['uomGuid'] = '';
		setNewPurchaseOrderData(newData);
	};

	// DialogTwoCol Modal open
	const handleOpen = (index) => {
		setIsOpen(true);
		setIndexDialogTwoCol(index); // 獲取真實的index，傳給DialogTwoCol
	};

	//#endregion

	//#region 計算 "價格差異"
	const handleQuantityPurchaseBlur = async (
		e,
		materialGuid,
		index,
		netPrice
	) => {
		try {
			const url = `api/Logistics/LgsPurchaseOrder/getMaterialMasterVendorPrice?MaterialGuid=${materialGuid}&VendorGuid=${vendorGuid}&OrderDate=${orderDate}&Quantity=${e.target.value}&CurrencyCode=${currencyCode}`;

			axiosGetVendorPrice({ url }, (res) => {
				console.log('materialMasterVendorPrice', res);

				handleInputChange(`materialMasterVendorPrice`, index, res);

				// 假使先填"單價"再填"採購數量"，更新價格差異
				if (res !== 0) {
					netPrice &&
						handleInputChange(
							`diffPrice`,
							index,
							((netPrice - res) / res) * 100
						);
				}
			});
		} catch (error) {
			console.error(error);
		}
	};

	//#endregion

	return (
		<div className="position-relative">
			<IndexTableContainer className="firstRowMinHeight d-flex flex-column p-4 position-relative">
				<IndexTable className="table PurchaseOrderCardWrapper">
					<TBody>
						{newPurchaseOrderData?.map((data, index) => {
							return (
								<React.Fragment
									key={`"purchaseOrderDataItem"${index}`}
								>
									{/* 父層 */}
									<TR className="list-item table-item">
										<TD>{index + 1}</TD>
										<TD
											className={`text-left p-2 text-center`}
										>
											{/* 資料顯示轉換 -型態  */}
											{data.purchaseCategoryCode === 'M'
												? purchaseCategoryCodeList.M
												: data.purchaseCategoryCode ===
												  'A'
												? purchaseCategoryCodeList.A
												: data.purchaseCategoryCode ===
												  'E'
												? purchaseCategoryCodeList.E
												: data.purchaseCategoryCode}
										</TD>

										<TD
											className={`text-left p-2 text-center`}
										>
											{data.cardName}
										</TD>

										<TD className={`px-0`}>
											<div className="d-flex">
												<span className="flex-grow-1 collapsedItem">
													{data.quantityPurchase}
												</span>
												<span className="flex-grow-1 collapsedItem">
													{data.uomName01}
												</span>
											</div>
										</TD>
										<TD></TD>

										<TD className="text-end">
											<span className="me-5 collapsedItem">
												{data.netAmount}
											</span>

											{/* 刪除垃圾桶 */}
											<div
												className="d-inline-block"
												style={{ cursor: 'pointer' }}
												onClick={(e) =>
													handleDeleteChange(index, e)
												}
											>
												<Icn_Trash />
											</div>

											{/* 展開按鈕 */}
											<div
												className={`d-inline-block text-left p-2 text-center`}
												style={{ cursor: 'pointer' }}
												onClick={(e) => {
													// icon箭頭方向
													e.target.closest('svg')
														.style.transform === ''
														? (e.target.closest(
																'svg'
														  ).style.transform =
																'rotate(.5turn)')
														: (e.target.closest(
																'svg'
														  ).style.transform =
																'');

													// 是否顯示子層
													e.target
														.closest('tr')
														.nextElementSibling.classList.contains(
															'd-none'
														)
														? e.target
																.closest('tr')
																.nextElementSibling.classList.remove(
																	'd-none'
																) && alert(1)
														: e.target
																.closest('tr')
																.nextElementSibling.classList.add(
																	'd-none'
																);

													e.target.closest(
														'svg'
													).style.transform =
														'rotate("0.5turn")';
												}}
											>
												<Icn_chevronTop
													className="iconChevron"
													style={{
														transform:
															index === 0
																? ''
																: 'rotate(.5turn)',
													}}
												/>
											</div>
										</TD>
									</TR>

									{/* 子層 */}
									<tr
										// 1.第一個預設開啟
										// className={`${index !== 0 && 'd-none'} childInfoTr rounded-xxl`}
										// 2.全部預設開啟
										className={`childInfoTr rounded-xxl`}
									>
										{data.length !== 0 && (
											<>
												<ChildTD
													colSpan={2}
													className="p-0 border-0"
												>
													<div className="h-100 p-4">
														<OrderItemImg
															className="rounded-xxl"
															src={
																location.origin +
																data.imageUrl
															}
															alt="OrderItemImg"
														/>
													</div>
												</ChildTD>
												<ChildTD className="ps-0 pe-8 py-0 border-0">
													<div className="py-4 text-align-left text-grey4">
														<span>
															{data.cardChildName}
														</span>
														<br />

														{data?.cardChildName2 ? (
															<span>
																{
																	data?.cardChildName2
																}
															</span>
														) : (
															<span className="invisible">
																123
															</span>
														)}
													</div>
													<div className="pt-2 pb-4 text-align-left">
														<div
															className={`wrapper d-flex flex-column "mb-3`}
														>
															<TextareaElement
																style={{
																	minHeight:
																		'0px',
																}}
																id={`note${index}`}
																rows={2}
																value={
																	data.note ||
																	''
																}
																onChange={(
																	e
																) => {
																	handleInputChange(
																		`note`,
																		index,
																		e.target
																			.value
																	);
																}}
															/>
														</div>
													</div>
												</ChildTD>

												<ChildTD className="ps-0 pe-6 py-0 border-0">
													<div className="pt-4 d-flex text-align-left text-grey4">
														<div
															className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
														>
															<LabelNormal
																htmlFor={`quantityPurchase${index}`}
															>
																{t(
																	'lgsPurchaseOrder:purchaseQuantity'
																)}
															</LabelNormal>
															<InputElement
																style={{
																	minWidth:
																		'120px',
																}}
																className="flex-fill"
																type="number"
																min="1"
																id={`quantityPurchase${index}`}
																value={
																	data.quantityPurchase
																}
																onChange={(
																	e
																) => {
																	handleInputChange(
																		`quantityPurchase`,
																		index,
																		e.target
																			.value
																	);
																	handleInputChange(
																		`netAmount`,
																		index,
																		convertToFloat(
																			data.netPrice *
																				e
																					.target
																					.value,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceTl
																	);
																	handleInputChange(
																		`taxAmount`,
																		index,
																		convertToFloat(
																			(data.netPrice *
																				e
																					.target
																					.value *
																				inputTaxRate) /
																				100,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceTl
																	);
																}}
																onBlur={(e) =>
																	handleQuantityPurchaseBlur(
																		e,
																		data.materialGuid,
																		index,
																		data.netPrice
																	)
																}
															/>
															<span className="text-danger ms-1 mt-1">
																{
																	data.quantityPurchaseWarning
																}
															</span>
														</div>

														{/* 計量單位dialogTwoCol */}
														{/* input btn */}
														<div
															className={`wrapper d-flex flex-column mb-3 flex-fill`}
														>
															<LabelNormal
																htmlFor={`uomCode${index}`}
															>
																{t(
																	'lgsPurchaseOrder:unitOfMeasure'
																)}
															</LabelNormal>
															<DialogWrapper className="row flex-nowrap">
																<InputDialogElementData
																	type="text"
																	id={`uomCode${index}`}
																	value={
																		newPurchaseOrderData[
																			index
																		][
																			'uomGuid'
																		] || ''
																	}
																	disabled={
																		data.purchaseCategoryCode ===
																			'E' &&
																		false
																	}
																	onChange={() => {}}
																/>
																<InputDialogElementView
																	style={{
																		minWidth:
																			'85px',
																		backgroundColor:
																			data.purchaseCategoryCode ===
																			'E'
																				? '#fff'
																				: '#EFF2F5',
																		cursor: 'auto',
																	}}
																	disabled={
																		data.purchaseCategoryCode ===
																			'E' &&
																		false
																	}
																	value={
																		newPurchaseOrderData[
																			index
																		][
																			'uomName01'
																		] || ''
																	}
																	onChange={() => {}}
																/>

																<CancelInputBtn
																	onClick={() => {
																		data.purchaseCategoryCode ===
																			'E' &&
																			handleCancelClick(
																				index
																			);
																	}}
																	// 費用分類並且uomName01有值，則存在刪除按鈕
																	isValueExist={
																		(newPurchaseOrderData[
																			index
																		][
																			'purchaseCategoryCode'
																		] ===
																			'E' &&
																			newPurchaseOrderData[
																				index
																			][
																				'uomName01'
																			]) ||
																		false
																	}
																>
																	<Icn_InputYellowCancel />
																</CancelInputBtn>

																<DialogBtn
																	style={{
																		backgroundColor:
																			data.purchaseCategoryCode ===
																			'E'
																				? '#fff'
																				: '#EFF2F5',
																		cursor:
																			data.purchaseCategoryCode ===
																			'E'
																				? 'pointer'
																				: 'auto',
																	}}
																	onClick={
																		data.purchaseCategoryCode ===
																		'E'
																			? () => {
																					data.purchaseCategoryCode ===
																						'E' &&
																						handleOpen(
																							index
																						);
																			  }
																			: () => {}
																	}
																>
																	<Icn_InputDialog />
																</DialogBtn>
															</DialogWrapper>
														</div>

														{/* DialogTwoCol element */}
														<DialogTwoCol
															index={
																indexDialogTwoCol
															}
															purchaseCategoryCode={
																data.purchaseCategoryCode
															}
															props={props}
															isOpen={isOpen}
															setIsOpen={
																setIsOpen
															}
															dialogDataFromBE={
																dialogDataFromBE
															}
														/>
													</div>
													<div className="pb-0 d-flex text-align-left">
														<div
															className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
														>
															<LabelNormal
																htmlFor={`deliverDate${index}`}
															>
																{t(
																	'lgsPurchaseOrder:deliveryDate'
																)}
															</LabelNormal>
															<InputElement
																style={{
																	minWidth:
																		'135px',
																}}
																className="flex-fill"
																type="date"
																id={`deliverDate${index}`}
																value={
																	data.deliverDate
																}
																onChange={(
																	e
																) => {
																	handleInputChange(
																		`deliverDate`,
																		index,
																		e.target
																			.value
																	);
																}}
															/>
															<span className="text-danger ms-1 mt-1">
																{
																	data.deliverDateWarning
																}
															</span>
														</div>

														{/* 排版用假div */}
														<div
															className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
														>
															<span
																style={{
																	minWidth:
																		'135px',
																}}
																className="flex-fill"
															></span>
														</div>
													</div>
												</ChildTD>

												<ChildTD
													colSpan="2"
													className="ps-0 pe-4 py-0 border-0"
												>
													<div className="pt-4 d-flex text-align-left text-grey4">
														<div
															className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
														>
															<LabelNormal
																htmlFor={`netPrice${index}`}
															>
																{t(
																	'lgsPurchaseOrder:unitPriceExcludingTax'
																)}
															</LabelNormal>
															<InputElement
																style={{
																	minWidth:
																		'120px',
																}}
																className="flex-fill"
																type="number"
																id={`netPrice${index}`}
																value={
																	data.netPrice
																}
																onChange={(
																	e
																) => {
																	handleInputChange(
																		`netPrice`,
																		index,
																		e.target
																			.value,
																		localDecimalPlaceUp
																	);
																	handleInputChange(
																		`netAmount`,
																		index,
																		convertToFloat(
																			e
																				.target
																				.value *
																				data.quantityPurchase,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceTl
																	);
																	handleInputChange(
																		`taxAmount`,
																		index,
																		convertToFloat(
																			(e
																				.target
																				.value *
																				data.quantityPurchase *
																				inputTaxRate) /
																				100,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceTl
																	);
																}}
																onBlur={
																	// 假如是物料，並且單價改值，計算價格差異
																	(e) => {
																		data.materialMasterVendorPrice &&
																			handleInputChange(
																				`diffPrice`,
																				index,
																				(
																					((e
																						.target
																						.value -
																						data.materialMasterVendorPrice) /
																						data.materialMasterVendorPrice) *
																					100
																				).toFixed(
																					localDecimalPlaceUp
																				)
																			);
																	}
																}
															/>
															<span className="text-danger ms-1 mt-1">
																{
																	data.netPriceWarning
																}
															</span>
														</div>

														<div
															className={`wrapper row flex-column mb-3 flex-fill`}
														>
															<LabelNormal
																htmlFor={`netAmount${index}`}
															>
																{t(
																	'lgsPurchaseOrder:amountExcludingTax'
																)}
															</LabelNormal>
															<InputElement
																style={{
																	minWidth:
																		'120px',
																}}
																className="flex-fill"
																type="number"
																id={`netAmount${index}`}
																value={
																	data.netAmount
																}
																onChange={(
																	e
																) => {
																	handleInputChange(
																		`netAmount`,
																		index,
																		e.target
																			.value,
																		localDecimalPlaceTl
																	);
																	handleInputChange(
																		`netPrice`,
																		index,
																		convertToFloat(
																			e
																				.target
																				.value /
																				data.quantityPurchase,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceUp
																	);
																	handleInputChange(
																		`taxAmount`,
																		index,
																		convertToFloat(
																			(e
																				.target
																				.value *
																				inputTaxRate) /
																				100,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceTl
																	);
																}}
																onBlur={
																	// 假如是物料，並且單價改值，計算價格差異
																	(e) => {
																		data.materialMasterVendorPrice &&
																			handleInputChange(
																				`diffPrice`,
																				index,
																				((data.netPrice -
																					data.materialMasterVendorPrice) /
																					data.materialMasterVendorPrice) *
																					100
																			);
																	}
																}
															/>
															<span className="text-danger ms-1 mt-1">
																{
																	data.netAmountWarning
																}
															</span>
														</div>
													</div>
													{data.purchaseCategoryCode ===
														'M' && (
														<div className="pb-0 d-flex text-align-left">
															<div
																className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
															>
																<LabelNormal
																	htmlFor={`diffPrice${index}`}
																>
																	{t(
																		'lgsPurchaseOrder:priceDifferencePercentage'
																	)}
																</LabelNormal>
																<InputElement
																	style={{
																		minWidth:
																			'120px',
																		backgroundColor:
																			'#EFF2F5',
																	}}
																	className="flex-fill"
																	type="number"
																	id={`diffPrice${index}`}
																	value={
																		data.diffPrice
																	}
																	disabled
																/>
															</div>

															{/* 排版用假div */}
															<div
																className={`wrapper row flex-column mb-3 flex-fill`}
															>
																<InputElement
																	style={{
																		minWidth:
																			'120px',
																		padding:
																			'6px 8px',
																	}}
																	className="invisible"
																	disabled
																/>
															</div>
														</div>
													)}
												</ChildTD>
											</>
										)}
									</tr>
								</React.Fragment>
							);
						})}
					</TBody>
				</IndexTable>
			</IndexTableContainer>
		</div>
	);
}

export default Page1_Item;
