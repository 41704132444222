import { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
`;

function StatusBar(props) {
    const { status } = props;
    const { t, i18n } = useTranslation();
    console.log(status);

    const statusInfoObject = {
        0: {
            color: '#FFB435',
            backgroundColor: '#FFF9E7',
            message: t('common:status.inactive'),
        },
        1: {},
        2: {
            color: '#EA4132',
            backgroundColor: '#FFF1F1',
            message: t('common:status.lock'),
        },
    };

    return (
        status &&
        status !== '1' && (
            <Container
                className={`rounded-t-xxl`}
                style={{
                    color: statusInfoObject[status]?.color,
                    backgroundColor: statusInfoObject[status]?.backgroundColor,
                }}
            >
                {statusInfoObject[status].message}
            </Container>
        )
    );
}

export default StatusBar;
