import React from 'react';
import styled from 'styled-components';

const TFoot = styled.tfoot`
    border: none;
`;

const FootTR = styled.tr`
    border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
    border: none !important;
`;

function TableFooter({ indexData, tableFooter }) {
    return (
        <React.Fragment>
            {tableFooter?.length ? (
                <TFoot>
                    <FootTR
                        id="totalAmount"
                        key="totalAmount"
                        hoverEffect={false}
                        style={{ fontWeight: 'bold' }}
                    >
                        {tableFooter.map((el, index) => (
                            <FootTD
                                className="p-2 text-right"
                                key={`tableFooter${index}`}
                            >
                                {el}
                            </FootTD>
                        ))}
                    </FootTR>
                </TFoot>
            ) : (
                <TFoot></TFoot>
            )}
        </React.Fragment>
    );
}

export default TableFooter;
