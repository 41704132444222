import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Icn_Activate } from "../../../assets/images/icn_Activate.svg";
import { ReactComponent as Icn_Edit } from "../../../assets/images/icn_Edit.svg";
import { ReactComponent as Icn_Lock } from "../../../assets/images/icn_Lock.svg";
import { ReactComponent as LogIcon } from "../../../assets/images/icn_Log.svg";
import { ReactComponent as Icn_Trash } from "../../../assets/images/icn_Trash.svg";
import { BtnNormal2 } from "../../../components/Buttons/FunctionRenderButtons/UniversalBTN";
import { PageTitleBar } from "../../../components/exports";
import { DialogConfirm } from "./DialogConfirm";
import { DropdownMenuArea } from "./DropdownMenuArea";
import StatusBar from "./StatusBar";
import axios from "axios";

// #region styled-components

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const Left = styled.div`
  display: flex;
`;

const Center = styled.div`
  display: flex;
`;

const Right = styled.div`
  display: flex;
`;

const BtnNormal = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 32px;
  margin-right: 8px;
  svg {
    margin-right: 8px;
  }
`;

const Wrapper = styled.div`
  min-height: 720px;
`;

const TextareaElement = styled.textarea`
  border: 0px solid var(--grey2);
  border-radius: 4px;
  padding: 8px;
  min-height: 88px;
  resize: none;
  &:focus-visible {
    outline: 0;
  }
`;

const Textarea = styled.div`
  border: 0px solid var(--grey2);
  border-radius: 4px;
  padding: 8px;
  min-height: 88px;
  resize: none;
  &:focus-visible {
    outline: 0;
  }
  background-color: var(--primaryllt);
  /* width: 600px;
    height: 160px; */
`;

const NumberInputElement = styled.input`
  border: 1px solid var(--grey2);
  border-radius: 4px;
  padding: 6px 8px;
  height: 32px;
  &:focus-visible {
    outline: 0;
  }

  -webkit-inner-spin-button,
  -webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover::-webkit-inner-spin-button,
  &:hover::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    opacity: 0;
  }

  &:focus::-webkit-inner-spin-button,
  &:focus::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    opacity: 0;
  }
`;
const InputElement = styled.input`
  border: 1px solid var(--grey2);
  border-radius: 4px;
  padding: 6px 8px;
  height: 32px;

  &:focus-visible {
    outline: 0;
  }
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e3e8ee;
  cursor: auto;
`;

const SelectElement = styled.select`
  font-size: 14px;
  border: 1px solid var(--grey2);
  border-radius: 4px;
  padding: 6px 8px;

  height: 32px;
  &:focus-visible {
    outline: 0;
  }

  /* 下拉箭頭圖案 */
  background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
    linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
  background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
  background-size: 6px 6px, 6px 6px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
`;

const LabelNormal = styled.label`
  text-align: left;
  font-size: 12px;
  color: var(--grey4);
  margin-bottom: 6px;
`;

const NoInfoRow = styled.div`
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	margin-top: 16px;
	border-bottom: 1px solid var(--grey2);
	mb-3
`;

const DialogBtn = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--grey2);
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding-left: 16px;
  padding-right: 12px;
  cursor: pointer;
  height: 32px;
`; // dialog按鈕

const CancelInputBtn = styled.span`
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 3px;
  right: 32px;
  width: 24px;
  text-align: center;
  display: ${(props) => (props.isValueExist ? "inline" : "none")};
  cursor: pointer;
`; // 取消按鈕

const DeleteButton = styled(BtnNormal2)`
  &:hover {
    #Group_6119 {
      path,
      rect {
        fill: white;
      }
    }
  }
`;

//#endregion

const LgsSalesKitClass_Details = () => {
  const { t } = useTranslation(["common", "lgsSalesKitClass"]);
  const pageTitle = t("lgsSalesKitClass:pageTitle");
  const classGuid = useLocation().pathname.split("/").pop();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const [ruleForUser, setRuleForUser] = useState("");
  const [preview, setPreview] = useState([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);

  console.log(data);
  //啟用
  const handleActivate = () => {
    const updateContent = t("common:log.activate", {
      value: data.name01,
    });

    axios
      .put(`api/Logistics/lgsSalesKitClass/activate`, {
        salesKitClassGuid: data.salesKitClassGuid,
        salesKitClassCode: data.salesKitClassCode,
        name01: data.name01,
        name02: data.name02,
        name03: data.name03,
        status: "1",
        rowStamp: data.rowStamp,
        updateContent,
      })
      .then((res) => navigate(-1));
  };

  //停用
  const handleInActivate = () => {
    const updateContent = t("common:log.lock", {
      value: data.name,
    });

    axios
      .put(`api/Logistics/lgsSalesKitClass/activate`, {
        salesKitClassGuid: data.salesKitClassGuid,
        salesKitClassCode: data.salesKitClassCode,
        name01: data.name01,
        name02: data.name02,
        name03: data.name03,
        status: "2",
        rowStamp: data.rowStamp,
        updateContent,
      })
      .then((res) => navigate(-1));
  };

  //刪除
  const handleDelete = () => {
    const updateContent = t("common:log.delete", {
      value: data.name,
    });

    axios
      .put(`api/Logistics/lgsSalesKitClass/activate`, {
        salesKitClassGuid: data.salesKitClassGuid,
        salesKitClassCode: data.salesKitClassCode,
        name01: data.name01,
        name02: data.name02,
        name03: data.name03,
        status: "4",
        rowStamp: data.rowStamp,
        updateContent,
      })
      .then((res) => navigate(-1));
  };

  //這邊因為是 details 後端使用 root(/) 接，而不是使用 query(?) 接
  //寫法1
  useEffect(() => {
    axios(`api/Logistics/LgsSalesKitClass/getDetails/${classGuid}`).then(
      (res) => {
        console.log(res);
        setData(res.data);
      }
    );
  }, []);

  console.log("data:", data);

  return (
    <>
      <div id="detailsView">
        <PageTitleBar
          pageTitle={pageTitle}
          previousPageButton={true}
          backTo={-1}
        />

        {/* DialogConfirm element */}
        <DialogConfirm
          t={t}
          isOpen={isActivateDialogOpen}
          setIsOpen={setIsActivateDialogOpen}
          handleClick={() => handleActivate()}
          message={t("common:dialog.activeConfirm")}
        />
        <DialogConfirm
          t={t}
          isOpen={isInActivateDialogOpen}
          setIsOpen={setIsInActivateDialogOpen}
          handleClick={() => handleInActivate()}
          message={t("common:dialog.lockConfirm")}
        />
        <DialogConfirm
          t={t}
          isOpen={isDeleteDialogOpen}
          setIsOpen={setIsDeleteDialogOpen}
          handleClick={() => handleDelete()}
          message={t("common:dialog.deleteConfirm")}
        />

        <Container className="">
          <Left className="">
            <BtnNormal2
              permission={[]}
              classGuid={classGuid}
              className="btn-primary smallBtn"
              to={"Create"}
              key={"EditBtnDetails"}
              handleClick={(e) => navigate(`../Edit/${classGuid}`)}
              word={t("common:button.editButton")}
            >
              <Icn_Edit />
            </BtnNormal2>

            {/* 當 status = 0 or 2 時，顯示"啟用"按鈕 */}
            {(data.status === "0" || data.status === "2") && (
              <BtnNormal2
                className="btn-primary smallBtn"
                key={"EnableBtnDetails"}
                handleClick={() => setIsActivateDialogOpen(true)}
                word={t("common:button.activateButton")}
              >
                <Icn_Activate />
              </BtnNormal2>
            )}
          </Left>

          <Center className=""></Center>

          <Right className="">
            <DropdownMenuArea permission={data?.permission}>
              {/* 先判斷 status 是不是 1 ；不是的話再看是不是0。*/}
              {data?.status === "1"
                ? [
                    <div
                      className="d-flex justify-content-center align-items-center"
                      key={"changeRecord"}
                      onClick={() => navigate(`../Log/${classGuid}`)}
                    >
                      <LogIcon />
                      <span>{t("common:button.logButton")}</span>
                    </div>,
                    <div
                      className="d-flex justify-content-center align-items-center"
                      key={"disabled"}
                      onClick={() => setIsInActivateDialogOpen(true)}
                    >
                      <Icn_Lock />
                      <span>{t("common:button.lockButton")}</span>
                    </div>,
                  ]
                : data?.status === "0"
                ? [
                    <div
                      className="d-flex justify-content-center align-items-center"
                      key={"changeRecord"}
                      onClick={() => navigate(`../Log/${classGuid}`)}
                    >
                      <LogIcon />
                      <span>{t("common:button.logButton")}</span>
                    </div>,
                    <div
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => setIsDeleteDialogOpen(true)}
                    >
                      <Icn_Trash />
                      <span>{t("common:button.deleteButton")}</span>
                    </div>,
                  ]
                : [
                    <div
                      className="d-flex justify-content-center align-items-center"
                      key={"changeRecord"}
                      onClick={() => navigate(`../Log/${classGuid}`)}
                    >
                      <LogIcon />
                      <span>{t("button.logButton")}</span>
                    </div>,
                  ]}
            </DropdownMenuArea>
          </Right>
        </Container>

        <div className="position-relative">
          <StatusBar status={data?.status} />
          <Wrapper className="bg-white d-flex flex-column p-4 position-relative ">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-4 px-4 pr-xl-6">
                  <div className={`wrapper d-flex flex-column mb-3`}>
                    <LabelNormal>
                      {t("lgsSalesKitClass:details.classCode")}
                    </LabelNormal>
                    <div className="input-group">
                      <InputElement
                        className="form-control border-grey2"
                        placeholder=""
                        disabled={true}
                        value={data.salesKitClassCode || ""}
                        onChange={() => {}}
                        type="text"
                        id="classCode"
                        name="classCode"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "0px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 px-4 pr-xl-6"></div>

                <div className="col-lg-4 px-4 pr-xl-6"></div>
              </div>

              <div className="row">
                {/* #Col1---------------------  */}
                <div className="col-lg-4 px-4 pr-xl-6">
                  <div className={`wrapper d-flex flex-column mb-3`}>
                    <LabelNormal>
                      {t("lgsSalesKitClass:details.name01")}
                    </LabelNormal>
                    <div className="input-group">
                      <InputElement
                        className="form-control border-grey2"
                        placeholder=""
                        disabled={true}
                        value={data.name01 || ""}
                        onChange={() => {}}
                        type="text"
                        id="name01"
                        name="name01"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "0px",
                        }}
                        disabledCssType={0}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 px-4 pr-xl-6">
                  <div className={`wrapper d-flex flex-column mb-3`}>
                    <LabelNormal>
                      {t("lgsSalesKitClass:details.name02")}
                    </LabelNormal>
                    <div className="input-group">
                      <InputElement
                        className="form-control border-grey2"
                        placeholder=""
                        disabled={true}
                        value={data.name02 || ""}
                        onChange={() => {}}
                        type="text"
                        id="name02"
                        name="name02"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "0px",
                        }}
                        disabledCssType={0}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 px-4 pr-xl-6">
                  <div className={`wrapper d-flex flex-column mb-3`}>
                    <LabelNormal>
                      {t("lgsSalesKitClass:details.name03")}
                    </LabelNormal>
                    <div className="input-group">
                      <InputElement
                        className="form-control border-grey2"
                        placeholder=""
                        disabled={true}
                        value={data.name03 || ""}
                        onChange={() => {}}
                        type="text"
                        id="name03"
                        name="name03"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "0px",
                        }}
                        disabledCssType={0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    </>
  );
};

export default LgsSalesKitClass_Details;
