import { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as AddCard_plus } from '../../../assets/images/addCard_plus.svg';
import { ReactComponent as PictureSmallIcon } from '../../../assets/images/pictureSmallIcon.svg';

// #region styled-components

const AppendixCard = styled.div`
    height: 200px;
    width: 224px;
    margin-right: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: ${(props) =>
        props.view === 'details'
            ? '1px solid #EFF2F5'
            : props.activeCard
            ? '1px solid #1278E8'
            : '1px solid #EFF2F5'};
    overflow: hidden;
    cursor: pointer;
    overflow: hidden;
    cursor: pointer;
    position: relative;
`;

//#endregion
export default function ServiceAttachment({
    appendixList,
    setAppendixList,
    view,
}) {
    // console.log(appendixList, setAppendixList, view);

    // 被選中的附件分類
    const [activeTab, setActiveTab] = useState('');
    // 記住哪個 file 被按的 ref
    const fileInputRef = useRef(null);
    // 記住哪個 card 被按
    const [activeCard, setActiveCard] = useState('');

    // 上傳文件
    function handleFileUpload(e) {
        const target = e.target.files;
        if (target.length) {
            // 文件本體
            const file = target[0];
            // 文件路徑
            const blobURL = URL.createObjectURL(file);
            // 文件名稱
            let fileName = file.name ? file.name.split('.')[0] : 'file';
            // 文件類型
            const fileType = file.name ? file.name.split('.').pop() : 'file';

            console.log(file, blobURL, fileName, fileType);

            // handle the uploaded file here

            setAppendixList([
                ...appendixList,
                {
                    appendixName: fileName,
                    appendixUrl: blobURL,
                    appendixType: fileType,
                    displayOrder: appendixList.length + 1,
                },
            ]);
        }
    }

    /// ***: 為什麼一定要用 handleSelectTab 要改變 activeTab?
    /// ***: 為什麼不用傳 selectedTab 參數到 handleSelectTab, handleSelectTab 也知道 selectedTab 是什麼?
    /// AI 的解答如下:
    // selectedTab 是由 activeKey 屬性提供的而 onSelect 屬性則是用於在選擇標籤時觸發事件，從而更新 activeKey 狀態。
    // handleSelectTab 函數是作為 onSelect 屬性的回調函數，而 selectedTab 參數則是由 handleSelectTab 函數自動接收的，代表了選擇的標籤的值。
    // 因此，您不能直接在 selectedTab 屬性中使用 selectedTab 變量，因為這個變量是由 handleSelectTab 函數在用戶選擇標籤時接收的，並且是動態的。
    // 如果您需要將 activeKey 狀態更新為用戶選擇的標籤值，請繼續使用 handleSelectTab 函數，並在該函數中調用 setActiveTab 函數更新狀態
    const handleSelectTab = (selectedTab) => {
        setActiveTab(selectedTab);
    };

    // 雙擊下載
    // todo: 沒有彈出選擇儲存位置, 之後再處理
    const handleDoubleClickCard = (e, el, index) => {
        if (e.target.tagName.toLowerCase() !== 'input') {
            const fileName = el.appendixName;
            const file = appendixList[index].appendixUrl;

            const link = document.createElement('a');
            link.href = file;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    // 顯示卡片
    const handleCardImage = (info, data) => {
        const { attachmentGuid, appendixName, appendixUrl, appendixType } =
            info;
        const { appendixList } = data;
        const fileType =
            appendixType?.toUpperCase() ||
            appendixUrl.split('.').pop().toUpperCase();

        if (['PNG', 'JPG', 'JPGE', 'GIF'].some((value) => fileType === value)) {
            return <img className="h-100 w-100" src={appendixUrl} />;
        } else if (['DOC', 'DOCX'].some((value) => fileType === value)) {
            return (
                <h6 className="m-0 px-2 py-2 bg-primary rounded text-white">
                    {fileType}
                </h6>
            );
        } else if (['PDF', 'PPT', 'PPTX'].some((value) => fileType === value)) {
            return (
                <h6 className="m-0 px-2 py-2 bg-danger rounded text-white">
                    {fileType}
                </h6>
            );
        } else if (['XLS', 'XLSX'].some((value) => fileType === value)) {
            return (
                <h6 className="m-0 px-2 py-2 bg-success rounded text-white">
                    {fileType}
                </h6>
            );
        } else {
            return (
                <h6 className="m-0 px-2 py-2 bg-secondary rounded text-white">
                    {fileType}
                </h6>
            );
        }
    };

    const getattachmentGuidList = () => {
        axiosattachmentGuidList(
            {
                url: `api/logistics/Lgspurchaseorder/getcreatedatapage3`,
            },
            (res) => {
                setattachmentGuides(res.lgsattachmentGuidList);
                res.lgsattachmentGuidList.length &&
                    setActiveTab(
                        res.lgsattachmentGuidList[0].attachmentGuidGuid
                    );
            }
        );
    };

    // todo: 詳細講講 composition
    // 用於記錄 composition 狀態用
    let isOnComposition = false;

    const handleComposition = (e, index) => {
        const val = e.target.value;

        if (e.type === 'compositionend') {
            //composition結束，代表中文輸入完成
            console.log('compositionend');
            isOnComposition = false;
            handleChangeAppendixName(val, index);
        } else {
            //composition進行中，代表正在輸入中文
            isOnComposition = true;
        }
    };

    // 改名字
    const handleChangeAppendixName = (val, index) => {
        const newAppendixInfo = [...appendixList];
        newAppendixInfo[index].appendixName = val;
        // setAppendixList(newAppendixInfo);
    };

    // 刪卡片
    const handleDeleteAppendix = (appendixIndex) => {
        // 將要刪除的 item 把 action 改成 delete, 以供 UI render 和 之後後端處理資料
        const newAppendixInfo = [];
        appendixList.map((el, index) => {
            if (appendixIndex === index) {
                console.log(el);
                if (el.action === 'update') {
                    newAppendixInfo.push({ ...el, action: 'delete' });
                }
            } else {
                newAppendixInfo.push(el);
            }
        });

        // 因為 appendixList 要和 appendixList 保持一致性, 所以要改用 appendixList 的 action 是不是 delete 來決定要不要 render 此 item
        // 要刪除的 item 不會被放到 newAppendixData 裡
        const newAppendixData = [];
        appendixList.map((el, index) => {
            if (appendixIndex === index) {
                console.log(el);
                if (appendixList[index].action === 'update') {
                    newAppendixData.push(el);
                }
            } else {
                newAppendixData.push(el);
            }
        });

        // 要刪除的 item 不會被放到 newFileList 裡
        const newFileList = [];
        fileList.map((el, index) => {
            if (appendixIndex === index) {
                console.log(el);
                if (appendixList[index].action === 'update') {
                    newFileList.push(el);
                }
            } else {
                newFileList.push(el);
            }
        });

        const targetItem = [...appendixList].filter(
            (_, index) => index === appendixIndex
        );

        if (targetItem.action === 'update') {
            setDeleteItem(deleteItem);
        }

        setAppendixInfo(newAppendixInfo);
        setAppendixData(newAppendixData);
        setFileList(newFileList);
    };

    // 暫存改被修改的資料
    const handleTemporaryStore = () => {
        const newData = { ...purchaseOrderData };

        appendixList.map((item, index) => {
            item.displayOrder = index + 1;
        });

        newData.page3.appendixList = appendixList;
        newData.page3.appendixList = appendixList;
        newData.page3.fileList = fileList;

        setPurchaseOrderData(newData);
    };

    const handleSubmit = () => {
        handleTemporaryStore();
        handleNextPage();
    };

    console.log('appendixList', appendixList);

    return (
        <>
            <div className="px-4 py-6 d-flex flex-wrap">
                {appendixList?.map((el, appendixIndex) => {
                    console.log(el);

                    return (
                        <AppendixCard
                            key={el.appendixName + appendixIndex}
                            id={el.appendixName + appendixIndex}
                            onClick={() =>
                                setActiveCard(el.appendixUrl + appendixIndex)
                            }
                            {...(view !== 'create' && {
                                onDoubleClick: (e) => {
                                    // console.log(
                                    //     el,
                                    //     appendixIndex
                                    // );
                                    handleDoubleClickCard(e, el, appendixIndex);
                                },
                            })}
                            activeCard={
                                el.appendixUrl + appendixIndex === activeCard
                            }
                            view={view}
                        >
                            {view !== 'details' &&
                                el.attachmentGuid + appendixIndex ===
                                    activeCard && (
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '12px',
                                            right: '12px',
                                        }}
                                        onClick={() => {
                                            handleDeleteAppendix(appendixIndex);
                                        }}
                                    >
                                        <svg
                                            id="Group_6556"
                                            dataname="Group 6556"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                        >
                                            <defs>
                                                <clipPath id="clip-path-times1811DDDDDDD">
                                                    <rect
                                                        id="Rectangle_3429"
                                                        dataname="Rectangle 3429"
                                                        width="16"
                                                        height="16"
                                                        fill="#ea4132"
                                                    ></rect>
                                                </clipPath>
                                            </defs>
                                            <g
                                                id="Group_6556-2"
                                                dataname="Group 6556"
                                                clipPath="url(#clip-pathtimes1811DDDDDDD)"
                                            >
                                                <path
                                                    id="Path_1484"
                                                    dataname="Path 1484"
                                                    d="M6.785,12.011h0a5.226,5.226,0,1,1,5.226-5.226,5.226,5.226,0,0,1-5.226,5.226"
                                                    transform="translate(1.215 1.216)"
                                                    fill="#fff"
                                                ></path>
                                                <path
                                                    id="Path_1485"
                                                    dataname="Path 1485"
                                                    d="M8,16a7.995,7.995,0,1,1,5.657-2.34A8,8,0,0,1,8,16M8,9.126H8L10.868,12,12,10.87,9.124,8,12,5.128,10.868,4,8,6.872,5.127,4,4,5.128,6.869,8,4,10.87,5.127,12Z"
                                                    transform="translate(0.001 0.001)"
                                                    fill="#ea4132"
                                                ></path>
                                            </g>
                                        </svg>
                                    </span>
                                )}
                            <div
                                className="text-center d-flex align-items-center justify-content-center"
                                style={{
                                    height: '152px',
                                    width: '224px',
                                }}
                            >
                                {handleCardImage(
                                    el,
                                    appendixList[appendixIndex]
                                )}
                            </div>
                            <div
                                className="text-center bg-white"
                                style={{
                                    height: '48px',
                                    width: '224px',
                                    padding: '8px 16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                            >
                                <PictureSmallIcon />
                                <div
                                    style={{
                                        width: '124px',
                                        height: '30px',
                                        margin: '0 20px',
                                        borderRadius: '4px',
                                        border:
                                            view === 'details'
                                                ? 'none'
                                                : el.appendixUrl +
                                                      appendixIndex ===
                                                  activeCard
                                                ? '1px solid #E3E8EE'
                                                : 'none',
                                    }}
                                >
                                    <input
                                        style={{
                                            width: '120px',
                                            position: 'absolute',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            outline: 'none',
                                            border: 'none',
                                            textAlign: 'center',
                                            backgroundColor: '#ffffff',
                                        }}
                                        defaultValue={el.appendixName}
                                        onCompositionStart={(e) =>
                                            handleComposition(e, appendixIndex)
                                        }
                                        onCompositionUpdate={(e) =>
                                            handleComposition(e, appendixIndex)
                                        }
                                        onCompositionEnd={(e) =>
                                            handleComposition(e, appendixIndex)
                                        }
                                        onChange={(e) => {
                                            if (
                                                e.target instanceof
                                                    HTMLInputElement &&
                                                !isOnComposition
                                            ) {
                                                handleChangeAppendixName(
                                                    e.target.value,
                                                    appendixIndex
                                                );
                                            }
                                        }}
                                        autoFocus={
                                            el.appendixUrl + appendixIndex ===
                                            activeCard
                                        }
                                        disabled={view === 'details'}
                                    />
                                </div>
                            </div>
                        </AppendixCard>
                    );
                })}
                {view !== 'details' && (
                    <div
                        style={{
                            background: '#EFF2F5',
                            width: '224px',
                            height: '200px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px',
                            cursor: 'pointer',
                        }}
                        onClick={() => fileInputRef.current.click()}
                    >
                        <input
                            key={appendixList?.length}
                            type="file"
                            style={{
                                display: 'none',
                            }}
                            ref={fileInputRef}
                            onChange={(e) => handleFileUpload(e, activeTab)}
                        />
                        <AddCard_plus />
                    </div>
                )}
            </div>
        </>
    );
}
