import styled from 'styled-components';

// #region styled-components
const ModalInputWrapper = styled.div`
    width: 200px;
    text-align: left !important;
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.3125rem;
    color: #616e83;
`;

const ModalCheckbox = styled.input``;

const ModalCheckboxLabel = styled.label`
    margin-left: 0.5rem !important;
    display: inline-block;
    margin-bottom: 0.5rem;
`;
// #endregion

// index 顯示設定 Options UI
// Author: YL
export default function TableDisplaySettingOptions(props) {
    // console.log(props);
    const {
        displayOptions,
        setDisplayOptions,
        headTitleList,
        bodyTitleList,
        columnMustShow,
    } = props;
    const handleClickTableSetting = (checked, name) => {
        // console.log(checked, name);
        if (checked) {
            setDisplayOptions([...displayOptions, name]);
        } else {
            const res = displayOptions.filter((el) => {
                return el !== name;
            });

            // console.log(res);
            setDisplayOptions([...res]);
        }
        // console.log(displayOptions);
    };

    return (
        <>
            {bodyTitleList.map((el) => {
                const i = bodyTitleList.indexOf(el);
                // console.log(bodyTitleList[i]);
                return (
                    // displayOrder 不能不顯示
                    headTitleList[i] !== '#' && (
                        <ModalInputWrapper key={el}>
                            <ModalCheckbox
                                type="checkbox"
                                id={el}
                                onClick={(e) =>
                                    handleClickTableSetting(
                                        e.target.checked,
                                        el
                                    )
                                }
                                defaultChecked={
                                    displayOptions.includes(el) ? 'checked' : ''
                                }
                                disabled={columnMustShow.includes(el)}
                            />
                            <ModalCheckboxLabel htmlFor={bodyTitleList[i]}>
                                {headTitleList[i]}
                            </ModalCheckboxLabel>
                        </ModalInputWrapper>
                    )
                );
            })}
        </>
    );
}
