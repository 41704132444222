import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Icn_OpenModal } from '../../../assets/images/icn_OpenModal.svg';
import { BtnNormal2 } from '../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import Input from '../../../components/FormElement/Input2';
import SelectElement from '../../../components/Inputs/SelectElement';
import UomModal from '../../../components/Modals/UomModal';
import PageTitleBar from '../../../components/PageTitleBar/PageTitleBar';
import StatusBar from '../../../components/StatusBar/StatusBarNormal';
import {
    deepClone,
    generateUpdateLogContent,
    generateUpdateLogContentFromArray,
    generateUpdateLogContent_appendix,
} from '../../../utils';
import { TabPage } from '../LgsPurchaseOrder/exports';
import { DialogConfirm } from './DialogConfirm';
import ServiceAttachment from './ServiceAttachment';
import ServiceEstimatedPrice from './ServiceEstimatedPrice';
import { SubmitConfirm } from './SubmitConfirm';

// #region styled-components
const Wrapper = styled.form`
    /* min-height: 720px; */
    border-radius: 0 0 0.75rem 0.75rem;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
`;

const LabelNormal = styled.label`
    text-align: left;
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;

const InputElement = styled.input`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-right: 0px;
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    pointer-events: none;
`;

const DialogBtn = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 12px;
    cursor: pointer;
    height: 32px;
`; // dialog按鈕

//#endregion

export default function LgsServiceMaster_Edit() {
    const serviceGuid = useLocation().pathname.split('/').pop();
    const { t } = useTranslation(['common', 'lgsServiceMaster']);
    const navigate = useNavigate();
    const pageTitle = t('lgsServiceMaster:pageTitle');
    const [data, setData] = useState({
        serviceAttachmentList: [],
        serviceEstimatedPriceList: [],
        fileList: [],
        forSale: false,
        name01: '',
        name02: '',
        name03: '',
        serviceNo: '',
        uomGuid: '',
        uomCode: '',
        classGuid: '',
    });

    // 原資料
    const [originalContent, setOriginalContent] = useState({});

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
    const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
    const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
        useState(false);

    const [uomDialogOpen, setUomDialogOpen] = useState(false);
    const [uomCategoryList, setUomCategoryList] = useState([]);
    const [uomList, setUomList] = useState([]);
    const [serviceClassList, setServiceClassList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);

    const [error, setError] = useState({});

    // 分頁title
    const tabNavList = [
        t('lgsServiceMaster:details.attachment'),
        t('lgsServiceMaster:details.estimatedPrice'),
    ];

    // 分頁內容 component
    const tabContent = [
        <ServiceAttachment view="edit" data={data} setData={setData} />,
        <ServiceEstimatedPrice
            view="edit"
            currencyList={currencyList}
            data={data}
            setData={setData}
            error={error}
        />,
    ];

    const handleActivate = () => {
        const updateContent = t('log.activate', {
            value: data.name,
        });

        console.log(updateContent);
        axios
            .put(`api/Logistics/LgsServiceClass/activate`, {
                classGuid: data.classGuid,
                status: '1',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const handleInActivate = () => {
        const updateContent = t('log.lock', {
            value: data.name,
        });
        axios
            .put(`api/Logistics/LgsServiceClass/activate`, {
                classGuid: data.classGuid,
                status: '2',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const handleDelete = () => {
        const updateContent = t('log.delete', {
            value: data.name,
        });
        axios
            .put(`api/Logistics/LgsServiceClass/activate`, {
                classGuid: data.classGuid,
                status: '4',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };
    const handleInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleUomDialogOpen = (e) => {
        setUomDialogOpen(!uomDialogOpen);
    };

    const checkServiceNo = async (val) => {
        if (!val) {
            return {
                isError: true,
                message: `${t('errMsg.mustFill')}`,
            };
        }

        const res = await axios
            .get(
                `api/Logistics/lgsServiceMaster/verifyCode?ServiceNo=${val}&serviceGuid=${serviceGuid}`
            )
            .then((res) => {
                return {
                    isError: false,
                    message: '',
                };
            })
            .catch((err) => {
                const res = err.response.data;

                const tValue = `errMsg.${res.message}`;
                return {
                    isError: true,
                    message: `${t(tValue)}`,
                };
            });

        setError({ ...error, serviceNo: res });
        return res;
    };

    const checkTopInfo = async () => {
        console.log(data);
        console.log(error);

        const newErrorState = { ...error };
        const keysToCheck = [
            'serviceNo',
            'name01',
            'classGuid',
            'uomCode',
            'forSale',
        ];

        for (const key of keysToCheck) {
            if (!data[key]) {
                newErrorState[key] = {
                    isError: true,
                    message: `${t('errMsg.mustFill')}`,
                };
            } else {
                newErrorState[key] = {
                    isError: false,
                    message: '',
                };
            }
        }

        return newErrorState;
    };

    const checkEstimatedPriceList = async () => {
        const newErrorState = {
            serviceEstimatedPriceList: {}, // 初始化 serviceEstimatedPriceList 对象
        };
        const keysToCheck = ['startDate', 'unitPrice'];

        for (const checkKey of keysToCheck) {
            for (const element of data?.serviceEstimatedPriceList || []) {
                const _uuid = element._uuid; // uuid 存在于 element id 中

                if (!newErrorState['serviceEstimatedPriceList'][_uuid]) {
                    newErrorState['serviceEstimatedPriceList'][_uuid] = {};
                }

                if (!element || !element[checkKey]) {
                    newErrorState['serviceEstimatedPriceList'][_uuid][
                        checkKey
                    ] = {
                        isError: true,
                        message: `${t('errMsg.mustFill')}`,
                    };
                }
            }
        }

        return newErrorState;
    };

    const checkInputs = async () => {
        const topInfoError = await checkTopInfo();
        const serviceNoError = await checkServiceNo(data?.serviceNo);
        const estimatedPriceListError = await checkEstimatedPriceList();

        const newErrors = {
            ...topInfoError,
            serviceNo: serviceNoError,
            ...estimatedPriceListError,
        };

        setError(newErrors);
        return newErrors;
    };

    // 递归检查是否有子对象中的"isError"为true
    const checkIsErrorTrue = async (obj) => {
        if (obj && typeof obj === 'object' && Object.keys(obj).length > 0) {
            for (const prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    const property = obj[prop];

                    if (typeof property === 'object') {
                        if (
                            Object.keys(property).length > 0 &&
                            checkIsErrorTrue(property)
                        ) {
                            return true;
                        }
                    } else if (prop === 'isError' && property === true) {
                        return true;
                    }
                }
            }
            // 所有子属性都为 false，返回 false
            return false;
        } else {
            return false;
        }
    };

    const checkIsAnyErrors = async () => {
        const finalError = await checkInputs();

        // 遍历每个子对象
        for (const key in finalError) {
            if (finalError.hasOwnProperty(key)) {
                const childObject = finalError[key];

                // 检查子对象中的"isError"是否为true
                if (await checkIsErrorTrue(childObject)) {
                    return true;
                }
            }
        }

        // 如果所有子对象中的"isError"都为false，进行相应处理
        return false;
    };

    const confirmSubmit = async () => {
        const isValid = !(await checkIsAnyErrors());

        if (isValid) {
            if (data.status === '1') {
                return handleSubmit('1');
            } else {
                setIsSubmitConfirmDialogOpen(true);
            }
        } else {
            return false;
        }
    };

    const handleSubmit = async (status) => {
        console.log(originalContent, data);

        const {
            serviceAttachmentList,
            serviceEstimatedPriceList,
            serviceGuid,
            fileList,
            forSale,
            name01,
            name02,
            name03,
            serviceNo,
            uomGuid,
            uomCode,
            classGuid,
            rowStamp,
        } = data;
        const newContent = { ...data, status: status };

        let modifiedContent = { ...originalContent };
        delete modifiedContent.serviceEstimatedPriceList;
        delete modifiedContent.serviceAttachmentList;

        // console.log(newContent, originalContent);
        const updateContent = generateUpdateLogContent(
            'lgsServiceMaster',
            t,
            newContent,
            modifiedContent
        );

        const updateContent_serviceEstimatedPriceList =
            generateUpdateLogContentFromArray(
                'lgsServiceMaster',
                t,
                data.serviceEstimatedPriceList,
                originalContent.serviceEstimatedPriceList
            );

        const updateContent_serviceAttachmentList =
            generateUpdateLogContent_appendix(
                'lgsServiceMaster',
                t,
                data.serviceAttachmentList,
                originalContent.serviceAttachmentList
            );

        const content = `${updateContent} ${updateContent_serviceEstimatedPriceList} ${updateContent_serviceAttachmentList}`;

        const masterData = {
            serviceGuid,
            forSale,
            name01,
            name02,
            name03,
            serviceNo: serviceNo.toUpperCase(),
            uomGuid,
            uomCode,
            serviceClassGuid: classGuid,
            status,
            rowStamp,
            updateContent: content,
        };

        console.log(updateContent_serviceAttachmentList);

        const formData = new FormData();
        // 把所有資料都以 json string 塞進去 formData
        formData.append('UpdateServiceMaster', JSON.stringify(masterData));
        formData.append(
            'UpdateServiceEstimatedPriceList',
            JSON.stringify(serviceEstimatedPriceList)
        );
        formData.append(
            'UpdateServiceAttachmentList',
            JSON.stringify(serviceAttachmentList)
        );
        // 新增附件 - 以同樣的 key name 一筆筆 append
        fileList.forEach((el) => {
            formData.append('UpdateAppendixDataList', el);
        });

        axios({
            method: 'put',
            url: `api/Logistics/lgsServiceMaster/update`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then((res) => {
            navigate(-1);
        });
    };

    useEffect(() => {
        axios(`api/Logistics/LgsServiceMaster/getDetails/${serviceGuid}`).then(
            (res) => {
                const { serviceAttachmentList, serviceEstimatedPriceList } =
                    res.data;

                serviceAttachmentList.map((el) => (el.action = 'origin'));
                serviceEstimatedPriceList.map((el) => (el.action = 'origin'));

                setData((prevData) => ({
                    ...prevData,
                    ...res.data,
                    serviceAttachmentList: deepClone(serviceAttachmentList),
                    serviceEstimatedPriceList: deepClone(
                        serviceEstimatedPriceList
                    ),
                }));

                setOriginalContent((prevData) => ({
                    ...prevData,
                    ...res.data,
                    serviceAttachmentList: deepClone(serviceAttachmentList),
                    serviceEstimatedPriceList: deepClone(
                        serviceEstimatedPriceList
                    ),
                }));
            }
        );

        axios(`api/Logistics/LgsServiceMaster/getCreateData`).then((res) => {
            const serviceClassListResult = res.data?.serviceClassList?.map(
                (item) => {
                    item.id = item.classGuid;
                    item.value = item.classGuid;
                    item.name = item.classCode + item.name01;

                    return item;
                }
            );

            setUomCategoryList(res.data.uomCategoryList);
            setUomList(res.data.uomList);
            setServiceClassList(serviceClassListResult);
            setCurrencyList(res.data.currencyList);
        });
    }, []);

    useEffect(() => {
        console.log(data.classGuid);

        const defaultUomCode = serviceClassList.find(
            (item) => item.classGuid === data.classGuid
        )?.uomcode;

        const defaultUomName = uomList.find(
            (item) => item.uomcode === defaultUomCode
        )?.name01;

        if (defaultUomCode) {
            setData({
                ...data,
                uomCode: defaultUomCode,
                uomName: defaultUomName,
            });
        }
    }, [data.classGuid]);

    return (
        <div>
            <div id="detailsView">
                <PageTitleBar
                    pageTitle={pageTitle}
                    previousPageButton={true}
                    backTo={-1}
                />
                {/* DialogConfirm element */}
                <DialogConfirm
                    isOpen={isActivateDialogOpen}
                    setIsOpen={setIsActivateDialogOpen}
                    handleClick={() => handleActivate()}
                    message={t('dialog.activeConfirm')}
                />
                <DialogConfirm
                    isOpen={isInActivateDialogOpen}
                    setIsOpen={setIsInActivateDialogOpen}
                    handleClick={() => handleInActivate()}
                    message={t('dialog.lockConfirm')}
                />
                <DialogConfirm
                    isOpen={isDeleteDialogOpen}
                    setIsOpen={setIsDeleteDialogOpen}
                    handleClick={() => handleDelete()}
                    message={t('dialog.deleteConfirm')}
                />
                <SubmitConfirm
                    t={t}
                    isOpen={isSubmitConfirmDialogOpen}
                    setIsOpen={setIsSubmitConfirmDialogOpen}
                    handleClickInactive={() => handleSubmit('0')}
                    handleClickActive={() => handleSubmit('1')}
                    message={t('dialog.saveConfirm')}
                />
                <Container>
                    <Left></Left>
                    <Center></Center>
                    <Right>
                        <BtnNormal2
                            className="btn-primary mediumBtn"
                            handleClick={() => confirmSubmit()}
                        >
                            {t('button.saveButton')}
                        </BtnNormal2>
                        <BtnNormal2
                            className="btn-outline-primary mediumBtn"
                            handleClick={() => navigate(-1)}
                        >
                            {t('button.cancelButton')}
                        </BtnNormal2>
                    </Right>
                </Container>
                <div className="position-relative">
                    <StatusBar status={data?.status} />
                    <Wrapper className="bg-white d-flex flex-column p-4 position-relative mb-3">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            labelText={t(
                                                'lgsServiceMaster:details.serviceNo'
                                            )}
                                            type="text"
                                            id="serviceNo"
                                            name="serviceNo"
                                            maxLength="256"
                                            placeholder=""
                                            disabled={data.status !== '0'}
                                            disabledCssType={
                                                data.status !== '0' ? 2 : 0
                                            } // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={
                                                (data?.serviceNo &&
                                                    data.serviceNo.toUpperCase()) ||
                                                ''
                                            }
                                            onBlur={(e) =>
                                                checkServiceNo(e.target.value)
                                            }
                                            onChange={handleInputChange}
                                            isErr={error?.serviceNo?.isError}
                                            errMsg={error?.serviceNo?.message}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 py-1 "></div>
                                <div className="col-lg-4 px-4 py-1 "></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            labelText={t(
                                                'lgsServiceMaster:details.name01'
                                            )}
                                            type="text"
                                            id="name01"
                                            name="name01"
                                            placeholder=""
                                            maxLength="80"
                                            disabled={false}
                                            disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={data?.name01 || ''}
                                            onChange={handleInputChange}
                                            // onBlur={checkInputs}
                                            isErr={error?.name01?.isError}
                                            errMsg={error?.name01?.message}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            labelText={t(
                                                'lgsServiceMaster:details.name02'
                                            )}
                                            type="text"
                                            id="name02"
                                            name="name02"
                                            placeholder=""
                                            maxLength="80"
                                            disabled={false}
                                            disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={data?.name02 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            labelText={t(
                                                'lgsServiceMaster:details.name03'
                                            )}
                                            type="text"
                                            id="name03"
                                            name="name03"
                                            placeholder=""
                                            maxLength="80"
                                            disabled={false}
                                            disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={data?.name03 || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <SelectElement
                                            id="serviceClass"
                                            labelText={t(
                                                'lgsServiceMaster:details.serviceClass'
                                            )}
                                            name="classGuid"
                                            options={serviceClassList}
                                            value={data?.classGuid}
                                            onChange={handleInputChange}
                                            // onBlur={checkInputs}
                                            disabled={data.status !== '0'}
                                            disabledCssType={
                                                data.status !== '0' ? 2 : 0
                                            }
                                            isErr={error?.classGuid?.isError}
                                            errMsg={error?.classGuid?.message}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <div className="wrapper d-flex flex-column mb-3">
                                            <LabelNormal>
                                                {t(
                                                    'lgsServiceMaster:details.uomCode'
                                                )}
                                            </LabelNormal>
                                            <div className="input-group">
                                                <InputElement
                                                    className="form-control border-grey2"
                                                    placeholder=""
                                                    disabled={false}
                                                    value={
                                                        `${data?.uomCode} ${data?.uomName}` ||
                                                        ''
                                                    }
                                                    onChange={() => {}}
                                                    type="text"
                                                    id="uomCode"
                                                    name="uomCode"
                                                />
                                                <DialogBtn
                                                    style={{
                                                        backgroundColor: '#fff',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={
                                                        handleUomDialogOpen
                                                    }
                                                >
                                                    <Icn_OpenModal />
                                                </DialogBtn>
                                            </div>
                                            <span className="text-danger ms-1 mt-1">
                                                {error?.classGuid?.isError
                                                    ? error?.classGuid?.message
                                                    : ''}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <SelectElement
                                            id="serviceOriginCategoryCode"
                                            labelText={t(
                                                'lgsServiceMaster:details.forSale'
                                            )}
                                            name="forSale"
                                            options={[
                                                {
                                                    id: '0',
                                                    value: '0',
                                                    name: '不能銷售',
                                                },
                                                {
                                                    id: '1',
                                                    value: '1',
                                                    name: '可銷售',
                                                },
                                                {
                                                    id: '2',
                                                    value: '2',
                                                    name: '暫停銷售',
                                                },
                                            ]}
                                            value={data?.forSale}
                                            onChange={handleInputChange}
                                            // onBlur={checkInputs}
                                            disabled={false}
                                            disabledCssType={0}
                                            isErr={error?.forSale?.isError}
                                            errMsg={error?.forSale?.message}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wrapper>
                    <TabPage
                        liColWidth="col-2"
                        tabNavList={tabNavList}
                        tabContent={tabContent}
                        defaultPage={0}
                    />
                    <UomModal
                        modalOpen={uomDialogOpen}
                        handleClose={() => setUomDialogOpen(false)}
                        uomList={uomList}
                        uomCategoryList={uomCategoryList}
                        data={data}
                        setData={setData}
                    />
                </div>
            </div>
        </div>
    );
}
