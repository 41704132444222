import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import TopInfo from './TopInfo';
import { DialogWarning } from '../DialogWarning';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import BottomInfo from './BottomInfo';
import { TabPage } from '../../../LgsPurchaseOrder/exports';
import PaymentTerm from './TabContent/PaymentTerm/PaymentTerm';
import HistoryOverview from './TabContent/HistoryOverview/HistoryOverview';
import { useTranslation } from 'react-i18next';
import PaymentHistory from './TabContent/PaymentHistory/PaymentHistory';
import AccountDocumentTab from './TabContent/AccountDocumentTab/AccountDocumentTab';
// #region styled-components

const IndexTableContainer = styled.div``;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page2(props) {
	//#region state initiation
	const { t, i18n } = useTranslation();
	const {
		setDialogConfirmData,
		parentData,
		setParentData,
		purchaseOrderData,
		page,
		processSteps,
		handleNextPage,
		dialogBtnKeyName,
		setDialogBtnKeyName,
		isOpen,
		setIsOpen,
		modalData,
		setModalData,
		warningMessage,
		setWarningMessage,
		handlePrevPage,
		CurrencyCode,
		CurrencyName,
		DecimalPlaceTl,
	} = props;
	console.log(props);
	const [success, setSuccess] = useState(false);
	const [pageData, setPageData] = useState(parentData);
	const [accountList, setAccountList] = useState([]);

	// const paymentData = parentData.payment;

	//#endregion

	//#region variable initiation

	// 分頁title
	const tabNavList = [
		t('lgsPurchaseOrderPaymentClose:page2.tabs.paymentDetails'),
		t('lgsPurchaseOrderPaymentClose:page2.tabs.paymentTerms'),
		t('lgsPurchaseOrderPaymentClose:page2.tabs.transactionHistoryOverview'),
		t('lgsPurchaseOrderPaymentClose:page2.tabs.paymentHistory'),
		t('lgsPurchaseOrderPaymentClose:page2.tabs.accountingVoucher'),
	];

	// 分頁內容 component
	const tabContent = [
		<div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
			<BottomInfo
				pageData={pageData}
				setPageData={setPageData}
				topInfoData={purchaseOrderData?.topInfoData}
				purchaseOrderData={purchaseOrderData}
				dialogBtnKeyName={dialogBtnKeyName}
				setDialogBtnKeyName={setDialogBtnKeyName}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				modalData={modalData}
				setModalData={setModalData}
				CurrencyCode={CurrencyCode}
				CurrencyName={CurrencyName}
				DecimalPlaceTl={DecimalPlaceTl}
			/>
		</div>,
		<PaymentTerm parentData={parentData} />,
		<HistoryOverview parentData={parentData} />,
		<PaymentHistory
			purchaseOrderPayment={parentData.purchaseOrderPayment}
			foreignCurrency={parentData.purchaseOrder.foreignCurrency}
		/>,
		<AccountDocumentTab
			parentData={parentData}
			tab3Data={{
				// 前端畫面使用
				accountDocumentName: '會計憑證',
				accountList: accountList,

				// 後端需要的，分開3個list
				dataToBackEnd: {
					goodsReceivedNoteList: [],
					newInvoiceList: [],
					priceVarianceList: [],
				},
			}}
			DecimalPlaceTl={DecimalPlaceTl}
			foreignCurrency={parentData.purchaseOrder.foreignCurrency}
		/>,
	];

	//#endregion

	//#region function

	const { sendRequest: axiosPaymentSubmit } = useAxios();

	// 往下一頁之前的檢查
	const handleSubmit = () => {
		// handleNextPage();

		// const {purchaseOrderGuid}

		const {
			postingDate,
			employeeGuid,
			grossAmount,
			foreignGrossAmount,
			exchageRate,
			exchangeDifferenceAmount,
			note,
		} = pageData.payment;

		console.log(accountList);

		axiosPaymentSubmit(
			{
				url: `api/Logistics/LgsPurchaseOrderPaymentClose/insert`,
				method: 'POST',
				data: {
					purchaseOrderGuid: pageData.purchaseOrderGuid,
					postingDate,
					employeeGuid,
					grossAmount,
					foreignGrossAmount,
					exchageRate,
					exchangeDifferenceAmount,
					note,
					accountDocumentItem1List: accountList[0].length
						? [{ ...accountList[0], displayOrder: 0 }]
						: [],
					accountDocumentItem2List: accountList[1].length
						? [{ ...accountList[1], displayOrder: 1 }]
						: [],
					accountDocumentItem3List: accountList[2].length
						? [{ ...accountList[2], displayOrder: 2 }]
						: [],
				},
			},
			async (res) => {
				handleNextPage();
			}
		);
	};

	// state
	// axios
	const { sendRequest: axiosPaymentTermList } = useAxios();

	//#endregion

	useEffect(() => {
		axiosPaymentTermList(
			{
				url: `api/Logistics/LgsPurchaseOrderPaymentClose/getPage3`,
				method: 'POST',
				data: {
					purchaseOrderGuid: pageData.purchaseOrderGuid,
					postingDate: pageData.payment.postingDate,
					staffGuid: pageData.payment.employeeGuid,
				},
			},
			async (res) => {
				console.log(res);
				const {
					purchaseOrderHistoryOverview,
					purchaseOrderPayment,
					purchaseOrderPaymentTerm,
					entry1,
					entry2,
					entry3,
				} = res;

				setParentData({
					...parentData,
					purchaseOrderPaymentTerm: purchaseOrderPaymentTerm,
					purchaseOrderPayment: purchaseOrderPayment,
					purchaseOrderHistoryOverview: purchaseOrderHistoryOverview,
				});

				// let accountList = [];

				// // entry1
				// if (entry1.length) {
				//     const accountGuid = entry1[0].accountGuid;
				//     const accountNo = entry1[0].accountNo;
				//     const name01 = entry1[0].accountName;
				//     const amountDr = entry1[0].amountDr;

				//     accountList.push({
				//         accountGuid,
				//         accountNo,
				//         name01,
				//         amountDr,
				//     });
				// }

				// // entry1
				// if (entry2.length) {
				//     const accountGuid = entry2[0].accountGuid;
				//     const accountNo = entry1[0].accountNo;
				//     const name01 = entry2[0].accountName;
				//     const amountCr = entry2[0].amountCr;

				//     accountList.push({
				//         accountGuid,
				//         accountNo,
				//         name01,
				//         amountCr,
				//     });
				// }

				// // entry1
				// if (entry3.length) {
				//     const accountGuid = entry3[0].accountGuid;
				//     const accountNo = entry1[0].accountNo;
				//     const name01 = entry3[0].accountName;
				//     const amountCr = entry3[0].amountCr;

				//     accountList.push({
				//         accountGuid,
				//         accountNo,
				//         name01,
				//         amountCr,
				//     });
				// }

				// // entry2
				// if (entry2.length) {
				//     entry2[0].amountCr = Number(
				//         pageData.payment.grossAmount
				//     ).toFixed(DecimalPlaceTl);
				//     entry2[0].accountGuid = pageData.payment.accountGuid;
				//     entry2[0].accountNo = pageData.payment.accountNo;
				//     entry2[0].name01 = pageData.payment.accountName;
				// }

				// // entry13
				// if (entry3.length) {
				//     entry3[0].amountCr = Number(
				//         pageData.payment.grossAmount
				//     ).toFixed(DecimalPlaceTl);
				//     entry3[0].accountGuid = pageData.payment.accountGuid;
				//     entry3[0].accountNo = pageData.payment.accountNo;
				//     entry3[0].name01 = pageData.payment.accountName;
				// }
				// if (parentData.purchaseOrder.foreignCurrency) {
				//     const moneyQQ =
				//         pageData.payment.exchangeDifferenceAmount > 0
				//             ? priceVarianceList[0]
				//             : priceVarianceList[1];

				//     moneyQQ.amountDr =
				//         pageData.payment.exchangeDifferenceAmount;

				//     entry1.displayOrder = 1;
				//     entry2.displayOrder = 2;
				//     moneyQQ.displayOrder = 3;
				//     setAccountList([entry1, entry2, moneyQQ]);
				// } else {
				//     entry1.displayOrder = 1;
				//     entry2.displayOrder = 2;
				//     setAccountList([entry1, entry2]);
				// }

				setAccountList([entry1, entry2, entry3]);
			}
		);
	}, []);

	/**
	 * DialogWarning 返回btn確認dialog
	 **/
	const [isWarningOpen, setIsWarningOpen] = useState(false);

	// DialogWarning Modal open
	const handleDialogWarning = () => {
		setIsWarningOpen(true);
	};

	const handleCancel = () => {};

	console.log(pageData);

	return (
		<>
			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				warningMessage={warningMessage}
			/>

			<Container className="">
				<Left className="">
					{page !== 0 && (
						<BtnNormal
							className="layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center"
							key="prevBtn"
							onClick={() => handlePrevPage()}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right className="">
					<BtnNormal
						className="layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center"
						key="nextBtn"
						onClick={(e) => {
							e.preventDefault();
							handleSubmit(page);
						}}
					>
						{t('common:button.saveButton')}
					</BtnNormal>
					<BtnNormal
						className="layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center"
						key="cancelBtn"
						onClick={() => {
							handleCancel();
						}}
					>
						{t('common:button.cancelButton')}
					</BtnNormal>
				</Right>
			</Container>

			<div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
				<TopInfo
					pageData={pageData}
					setPageData={setPageData}
					topInfoData={purchaseOrderData?.topInfoData}
					purchaseOrderData={purchaseOrderData}
					dialogBtnKeyName={dialogBtnKeyName}
					setDialogBtnKeyName={setDialogBtnKeyName}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					modalData={modalData}
					setModalData={setModalData}
					CurrencyCode={CurrencyCode}
					CurrencyName={CurrencyName}
					DecimalPlaceTl={DecimalPlaceTl}
				/>
			</div>
			<TabPage
				tabNavList={tabNavList}
				tabContent={tabContent}
				defaultPage={0}
			/>
		</>
	);
}

export default Page2;
