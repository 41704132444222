import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import ThreeColLayout from '../../../../../components/ThreeColLayout/ThreeColLayout';
import { DialogCol } from '../DialogCol';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

//#region styled-components

const SelectElement = styled.select`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
`;

const TopTable = styled.table`
	font-size: 12px;
	color: #616e83;
	background-color: #f7f8fc;
	width: 100% !important;
	height: 128px !important;
	border: 1px solid var(--grey1) !important;
	border-collapse: separate;
	border-spacing: 0;
	border-radius: 10px;
`;

const TBody = styled.tbody``;

const TR = styled.tr`
	height: 32px;
	border: 2px solid var(--grey1) !important;
`;
const TH1 = styled.th`
	padding: 0px;
	height: 32px;
	width: 23% !important;
	text-align: center !important;
`;
const TH = styled.th`
	padding: 0px;
	height: 32px;
	width: 38.5% !important;
	text-align: center !important;
`;
const TD1 = styled.td`
	padding: 0px;
	height: 32px;
	width: 23% !important;
	border: 1px solid var(--grey1) !important;
	text-align: center !important;
`;
const TD = styled.td`
	padding-right: 8px;
	height: 32px;
	width: 38.5% !important;
	border: 1px solid var(--grey1) !important;

	text-align: right !important;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

const InputElement2 = styled.input`
	border: 1px solid var(--grey2);
	border-right: none;
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

const BottomInfo = ({
	pageData,
	setPageData,
	CurrencyCode,
	CurrencyName,
	DecimalPlaceTl,
}) => {
	const { t } = useTranslation(['common']);
	const { employeeList, bankingAccountList, purchaseOrder } = pageData;

	const handleEmployeeChange = (val) => {
		const { fullname } = employeeList.find((el) => el.employeeGuid === val);

		console.log(fullname);

		setPageData({
			...pageData,
			payment: {
				...pageData.payment,
				employeeGuid: val,
				employeeName: fullname,
			},
		});
	};

	const handleBankingAccountChange = (val) => {
		const { accountNo, name01, bankingAccountName } =
			bankingAccountList.find((el) => el.bankAccountGuid === val);

		setPageData({
			...pageData,

			payment: {
				...pageData.payment,
				bankAccountGuid: val,
				bankingAccountName: bankingAccountName,
				accountName: name01,
				accountNo: accountNo,
			},
		});
	};

	const handlePostingDateChange = (val) => {
		setPageData({
			...pageData,
			payment: { ...pageData.payment, postingDate: val },
		});
	};

	const handleGrossAmountChange = (val) => {
		setPageData({
			...pageData,
			payment: { ...pageData.payment, grossAmount: val },
		});
	};

	const handleForeignGrossAmountChange = (val) => {
		setPageData({
			...pageData,
			payment: {
				...pageData.payment,
				foreignGrossAmount: val,
				grossAmount: (val * pageData?.payment?.exchageRate).toFixed(
					DecimalPlaceTl
				),
				grossAmountShouldPay: (
					val * pageData?.purchaseOrder?.exchageRate
				).toFixed(DecimalPlaceTl),
				exchangeDifferenceAmount: (
					val * pageData?.payment?.exchageRate -
					val * pageData?.purchaseOrder?.exchageRate
				).toFixed(DecimalPlaceTl),
			},
		});
	};

	const handleNoteChange = (val) => {
		setPageData({
			...pageData,
			payment: { ...pageData.payment, note: val },
		});
	};

	const handleExChangeRateChange = (val) => {
		setPageData({
			...pageData,
			payment: {
				...pageData.payment,

				exchageRate: val,

				grossAmount: (
					val * pageData?.payment?.foreignGrossAmount
				).toFixed(DecimalPlaceTl),

				grossAmountShouldPay: (
					pageData?.purchaseOrder?.exchageRate *
					pageData?.payment?.foreignGrossAmount
				).toFixed(DecimalPlaceTl),

				exchangeDifferenceAmount: (
					val * pageData?.payment?.foreignGrossAmount -
					pageData?.purchaseOrder?.exchageRate *
						pageData?.payment?.foreignGrossAmount
				).toFixed(DecimalPlaceTl),
			},
		});
	};

	useEffect(() => {
		pageData?.payment?.bankAccountGuid &&
			handleBankingAccountChange(pageData?.payment?.bankAccountGuid);
	}, [pageData.payment?.bankAccountGuid]);

	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="row">
						<div className="col-lg-4 px-4 pr-xl-6">
							<div className="wrapper d-flex flex-column mb-3">
								<LabelNormal>
									{t(
										'lgsPurchaseOrderPaymentClose:page1.bottom.postingDate'
									)}
								</LabelNormal>
								<InputElement
									type="date"
									id="postingDate"
									value={pageData?.payment?.postingDate || ''}
									onChange={(e) =>
										handlePostingDateChange(e.target.value)
									}
								/>
								<span className="text-danger ms-1 mt-1">
									{pageData?.payment?.postingDateWarning}
								</span>
							</div>
							<div className="wrapper d-flex flex-column mb-3">
								<LabelNormal>
									{t(
										'lgsPurchaseOrderPaymentClose:page1.bottom.staff'
									)}
								</LabelNormal>
								<SelectElement
									id="staff"
									value={
										pageData?.payment?.employeeGuid || ''
									}
									onChange={(e) =>
										handleEmployeeChange(e.target.value)
									}
								>
									{employeeList?.map((el) => (
										<option
											key={el.employeeGuid}
											id={el.employeeGuid}
											value={el.employeeGuid}
										>
											{el.fullname}
										</option>
									))}
								</SelectElement>
								<span className="text-danger ms-1 mt-1">
									{pageData?.payment?.staffWarning}
								</span>
							</div>
						</div>
						<div className="col-lg-4 px-4 px-xl-5">
							<>
								<div className="d-flex flex-column">
									<LabelNormal>
										{t(
											'lgsPurchaseOrderPaymentClose:page1.bottom.transitionalBalanceAmount'
										)}
									</LabelNormal>
									<InputElement
										type="number"
										id="grossAmountActualPay"
										className="mb-3"
										value={
											pageData?.purchaseOrder
												?.receivingTransitionBalance ||
											''
										}
										min="0"
										onChange={(e) =>
											handleGrossAmountChange(
												e.target.value
											)
										}
										disabled
									/>
								</div>
								<div className="d-flex flex-column">
									<LabelNormal>
										{t(
											'lgsPurchaseOrderPaymentClose:page1.bottom.payableBalanceAmount'
										)}
									</LabelNormal>
									<InputElement
										type="number"
										id="grossAmountShouldPay"
										className="mb-3"
										value={
											pageData?.purchaseOrder
												?.accountsPayableBalance || 0
										}
										min="1"
										onChange={(e) =>
											handleGrossAmountChange(
												e.target.value
											)
										}
										disabled
									/>
								</div>
								<div className="d-flex flex-column">
									<LabelNormal>
										{t(
											'lgsPurchaseOrderPaymentClose:page1.bottom.varianceBalanceAmount'
										)}
									</LabelNormal>
									<InputElement
										type="number"
										id="exchangeDifferenceAmount"
										className="mb-3"
										value={
											pageData?.purchaseOrder
												?.varianceBalance || 0
										}
										min="1"
										onChange={(e) =>
											handleGrossAmountChange(
												e.target.value
											)
										}
										disabled
									/>
								</div>
							</>
						</div>
						<div className="col-lg-4 px-4 pl-xl-6">
							<div className="wrapper d-flex flex-column mb-3">
								<LabelNormal>
									{t(
										'lgsPurchaseOrderPaymentClose:page1.bottom.note'
									)}
								</LabelNormal>
								<TextareaElement
									id="note"
									rows="4"
									cols="30"
									value={pageData?.payment?.note || ''}
									onChange={(e) =>
										handleNoteChange(e.target.value)
									}
									disabled={false}
									disabledCssType={0}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BottomInfo;
