import { css } from 'styled-components';

export const disabledStyle = css`
	/* disabledCssType === 0 無樣式*/
	/* disabledCssType === 1 border下底線背景透明*/
	background-color: ${(props) =>
		props.disabledCssType === 1 && 'transparent'};
	border: ${(props) => props.disabledCssType === 1 && 'none'};
	border-bottom: ${(props) =>
		props.disabledCssType === 1 && '1px solid #e3e8ee'};
	border-radius: ${(props) => props.disabledCssType === 1 && '0px'};
	cursor: ${(props) => props.disabledCssType === 1 && 'auto'};

	/* disabledCssType === 2 border全部 背景深色*/
	background-color: ${(props) => props.disabledCssType === 2 && '#EFF2F5'};
	cursor: ${(props) => props.disabledCssType === 2 && 'auto'};
`;
