import React from 'react';
import styled from 'styled-components';
import { LabelNormal } from './shared/LabelNormal';
import { disabledStyle } from './shared/DisabledStyle';
import { ErrMsg } from './shared/ErrMsg';

// 使用範例
/* 
<Select
	wrapperClass="mb-3" // 外層class，可用來推下面margin
	labelText="國籍" // label文字
	id="test3" // input id&name ; label htmlFor
	isErr={false} // 是否顯示錯誤訊息
	errMsg={`必填文字`} // 錯誤訊息
	disabled={false}
	disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
	onChange={(e) => {
		setValue('dateFormate', e.target.value);
		const dateFormateValue = getValues('dateFormate');
		// console.log(dateFormateValue);
	}}
>
	<option value="1">yyyy/M/d</option>
	<option value="2">yyyy/MM/dd</option>
	<option value="3">yyyy-MM-dd</option>
	<option value="4">M/d/yyyy</option>
	<option value="5">MM/dd/yyyy</option>
	<option value="6">MM-dd-yyyy</option>
</Select>
*/

const SelectElement = styled.select`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}

	/* 下拉箭頭圖案 */
	background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
		linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
	background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
	background-size: 6px 6px, 6px 6px;
	background-repeat: no-repeat;
	-webkit-appearance: none;

	${disabledStyle}
`;

// children : options
function Select({
	labelText,
	id,
	value,
	placeholder,
	onChange,
	isErr,
	errMsg,
	wrapperClass,
	disabled,
	disabledCssType,
	children,
	register,
	validation,
}) {
	return (
		<div className={`wrapper d-flex flex-column ${wrapperClass}`}>
			<LabelNormal htmlFor={id}>{labelText}</LabelNormal>
			<SelectElement
				{...register(id, validation)}
				id={id}
				defaultValue={value}
				placeholder={placeholder}
				onChange={onChange}
				disabled={disabled}
				disabledCssType={disabledCssType}
			>
				{children}
			</SelectElement>
			<ErrMsg
				className={`text-danger mt-1 ${isErr ? 'd-block' : 'd-none'}`}
			>
				{errMsg}
			</ErrMsg>
		</div>
	);
}

export default Select;
