import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CopyIcon } from '../../assets/images/icn_Copy.svg';
import { BtnNormal } from './BtnNormal';

export const CopyCreateBtn = ({ className, to, handleClick }) => {
    // console.log(className, to);
    return (
        <BtnNormal
            className={'btn btn-primary'}
            to={'Create'}
            key={'CopyCreateBtn'}
            onClick={(e) => handleClick(e)}
        >
            <CopyIcon />
            <span>訂單複製</span>
        </BtnNormal>
    );
};
