import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../hooks/useAxios';
import { CopyCreateBtn } from '../../../../components/Buttons/CopyCreateBtn';
import { CancelSubmitBtn } from '../../../../components/Buttons/CancelSubmitBtn';
import { PageTitleBar } from '../exports';
import { useLocation } from 'react-router-dom';

import PageCopy from './Pages/PageCopy/PageCopy';
import Page0 from './Pages/Page0';
import Page1 from './Pages/Page1';
import Page2 from './Pages/Page2';
import Page3 from './Pages/Page3';
import Page4 from './Pages/page4/Page4';
import Page5 from './Pages/Page5';
import { DialogConfirm } from '../Edit/Pages/DialogConfirm';
import { useTranslation } from 'react-i18next';

// #region styled-components
// #endregion

function LgsPurchaseOrder_Create() {
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);

	const [purchaseOrderData, setPurchaseOrderData] = useState({
		isEverEnterPage: {
			page0: false,
			page1: false,
			page2: false,
			page3: false,
		},
		topInfoData: {},
		page0: {},
		page1: [],
		page2: { paymentTermList: [], totals: {} },
		page3: { appendixInfo: [], appendixData: [], fileList: [] },
		page4: {},
	});

	const [page, setPage] = useState(0);
	const [view, setView] = useState('create');
	const pageTitle = t('lgsPurchaseOrder:purchaseOrder');
	const permission = localStorage.getItem('Permission_LgsPurchaseOrder');

	//#region page1
	const [isSelectingCurrentOrder, setIsSelectingCurrentOrder] =
		useState(false);
	const { isLoading, error, sendRequest: axiosIndexData } = useAxios();
	const [fetchedData, setFetchedData] = useState([]);
	const [displayColumns, setDisplayColumns] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const [copyPurchaseOrderData, setCopyPurchaseOrderData] = useState({});
	const sortBy = 'orderDate';
	const guidName = 'purchaseOrderGuid';
	const location = useLocation();

	const processSteps = [
		t('lgsPurchaseOrder:basicInformation'),
		t('lgsPurchaseOrder:purchaseDetails'),
		t('lgsPurchaseOrder:paymentTerms'),
		t('lgsPurchaseOrder:attachments'),
		t('lgsPurchaseOrder:confirm'),
	];

	const headTitleList = [
		'#',
		t('lgsPurchaseOrder:vendorCode'),
		t('lgsPurchaseOrder:vendor'),
		t('lgsPurchaseOrder:orderDate'),
		t('lgsPurchaseOrder:orderNo'),
		t('lgsPurchaseOrder:currencyCode'),
		t('lgsPurchaseOrder:displayGrossAmount'),
		t('lgsPurchaseOrder:displayNetAmount'),
		t('lgsPurchaseOrder:displayTaxAmount'),
		t('lgsPurchaseOrder:grossAmount'),
		t('lgsPurchaseOrder:netAmount'),
		t('lgsPurchaseOrder:taxAmount'),
	];

	const bodyTitleList = [
		'displayOrder',
		'vendorCode',
		'vendor',
		'orderDate',
		'orderNo',
		'currencyCode',
		'displayNetAmount',
		'displayTaxAmount',
		'displayGrossAmount',
		'grossAmount',
		'netAmount',
		'taxAmount',
	];

	const tableStyles = [
		{
			minWidth: '54px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '240px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'left',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '72px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '112px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
	];

	const columnMustShow = ['displayOrder', 'orderDate', 'orderNo'];

	const fetchData = (pageSize = 2, sort = sortBy, pageNumber = 1) => {
		const pageString = pageNumber ? `&page=${pageNumber}` : '';
		const url = `api/Logistics/LgsPurchaseOrder/GetList?sort=${sort}&pagesize=${pageSize}${pageString}`;
		axiosIndexData({ url }, (res) => {
			console.log(res);
			const { indexData, permission, numberOfData, totalPage } = res;

			setPaginationData({
				...paginationData,
				numberOfData: numberOfData,
				totalPage: totalPage,
				pageNumber,
			});
			setFetchedData({
				indexData,
				permission,
				paginationData,
			});
		});
	};

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			return data;
		};

		return gettingData();
	}, []);

	//#endregion

	const handlePrevPage = () => {
		setPage(page - 1);
	};

	const handleNextPage = () => {
		setPage(page + 1);
	};

	/**
	 * DialogConfirm 返回btn確認dialog
	 **/
	const [isOpen, setIsOpen] = useState(false);

	// DialogConfirm Modal open
	const handleDialogConfirm = () => {
		setIsOpen(true);
	};

	useEffect(() => {
		// fetchIndexInfo();
		getLocalStorageData();

		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);

	useEffect(() => {
		console.log(location);
		const data = location.state?.data;
		console.log(data);
		if (data) {
			setCopyPurchaseOrderData(data);
		}
	}, []);

	return (
		<>
			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					backTo={-1}
					isDialogConfirm={true}
					handleDialogConfirm={handleDialogConfirm}
				/>

				{/* DialogConfirm element */}
				<DialogConfirm
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					backTo={-1}
				/>

				<div>
					{page === 'copy' && (
						<PageCopy
							page={page}
							setPage={setPage}
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							data={copyPurchaseOrderData}
							setData={setCopyPurchaseOrderData}
						/>
					)}
					{page === 0 && (
						<Page0
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							guidName={guidName}
							sortBy={sortBy}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableData={fetchedData?.indexData}
							indexData={fetchedData?.indexData}
							paginationData={paginationData}
							setPaginationData={setPaginationData}
							displayColumns={displayColumns}
							setDisplayColumns={setDisplayColumns}
							fetchData={fetchData}
							fetchedData={fetchedData}
							tableStyles={tableStyles}
							columnMustShow={columnMustShow}
							clickSelection={true}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copyPurchaseOrderData={copyPurchaseOrderData}
							setCopyPurchaseOrderData={setCopyPurchaseOrderData}
							view={view}
						/>
					)}
					{page === 1 && (
						<Page1
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copyPurchaseOrderData={copyPurchaseOrderData}
							setCopyPurchaseOrderData={setCopyPurchaseOrderData}
						/>
					)}
					{page === 2 && (
						<Page2
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableStyles={tableStyles}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copyPurchaseOrderData={copyPurchaseOrderData}
							setCopyPurchaseOrderData={setCopyPurchaseOrderData}
						/>
					)}
					{page === 3 && (
						<Page3
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableStyles={tableStyles}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							copyPurchaseOrderData={copyPurchaseOrderData}
							setCopyPurchaseOrderData={setCopyPurchaseOrderData}
						/>
					)}
					{page === 4 && (
						<Page4
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copyPurchaseOrderData={copyPurchaseOrderData}
							setCopyPurchaseOrderData={setCopyPurchaseOrderData}
						/>
					)}
					{page === 5 && (
						<Page5
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							setPage={setPage}
							page={page}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							copyPurchaseOrderData={copyPurchaseOrderData}
							setCopyPurchaseOrderData={setCopyPurchaseOrderData}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default LgsPurchaseOrder_Create;
