import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import useAxios from '../../../../../hooks/useAxios';
import { ReactComponent as Icn_chevronCircleDown } from '../../../../../assets/images/icn_chevronCircle-down.svg';
import { ReactComponent as Icn_AlertExclamation } from '../../../../../assets/images/icn_AlertExclamation.svg';
import TopInfo from './TopInfo';
import Page1_Item from './Page1_Item';
import { Dialog } from './Dialog';
import { DialogDouble } from './DialogDouble';
import { PrevBtnSmall } from '../../../../../components/Buttons/PrevBtnSmall';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { useTranslation } from 'react-i18next';

//#region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

// 錯誤popup
const UnableBtnErrPopup = styled.div`
	top: -44px;
	left: 0;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
	z-index: 2000;
`;

// 摺疊父子元件
const Wrapper = styled.div`
	min-height: 740px;
`;

const WrapperGrey = styled.div`
	background-color: #f7f8fc;
	border: 1px solid var(--grey1);
`;

const ToggleBtn = styled.h6`
	color: var(--primary);
	font-size: 14px;
	margin-top: 16px;
	margin-bottom: 0;
	cursor: pointer;
`;

//#endregion

function Page1(props) {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);

	const {
		purchaseOrderData,
		setPurchaseOrderData,
		page,
		processSteps,
		handlePrevPage,
		handleNextPage,
		copyPurchaseOrderData,
	} = props;

	// state
	const [dialogDataFromBE, setDialogDataFromBE] = useState({});
	const [toggleBtnAllOpen, setToggleBtnAllOpen] = useState(true); // 展開/收合全部 btn
	const [topInfoData, setTopInfoData] = useState({}); // topInfoData
	const [newPurchaseOrderData, setNewPurchaseOrderData] = useState([]); // page1此頁面資料管控
	const [showUnableBtnPopup, setShowUnableBtnPopup] = useState(''); // 錯誤訊息共用程式，哪一個index顯示錯誤alert

	// axios
	const { sendRequest: axiosDataPage1 } = useAxios();
	const { sendRequest: axiosGetVendorPrice } = useAxios();

	console.log('newPurchaseOrderData', newPurchaseOrderData);
	console.log('copyPurchaseOrderData', copyPurchaseOrderData);

	const [cookies] = useCookies();

	// newPurchaseOrderData(card)主要keyName
	const cardKeyNameList = [
		'displayOrder',
		'purchaseCategoryCode',
		'cardName',
		'cardChildName',
		'cardChildName2',
		'imageUrl',
		'name',
		'note',
		'quantityPurchase',
		'deliverDate',
		'netPrice',
		'netAmount',
		'materialMasterVendorPrice',
		'diffPrice',
	];

	//#endregion

	//#region 換頁時驗證並暫存data到父層
	const handleChangePage = (direction) => {
		// 送出前驗證
		let newData = newPurchaseOrderData;
		let falseCount = 0;

		newData.map((el) => {
			// console.log(el);

			// required必填欄位驗證
			let array = [
				'quantityPurchase',
				'deliverDate',
				'netPrice',
				'netAmount',
			];
			array.forEach((keyName) => {
				if (el[keyName] === '') {
					// 加入錯誤訊息
					el[`${keyName}Warning`] = t('lgsPurchaseOrder:fillData');
					falseCount++;
				}
			});
		});
		setNewPurchaseOrderData([...newData]);
		console.log(newData);

		// 存資料到父層
		const saveToParent = () => {
			let parentData = { ...purchaseOrderData };
			parentData.page1 = [...newPurchaseOrderData];
			parentData.topInfoData = { ...topInfoData };

			setPurchaseOrderData(parentData);
		};

		// a.通過驗證存到父層，並到下一頁
		// console.log(falseCount);
		if (falseCount === 0 && direction === 'next') {
			saveToParent();
			handleNextPage();
		}

		// b.不須通過驗證，將現有資料存到父層，並到上一頁
		if (direction === 'prev') {
			saveToParent();
			handlePrevPage();
		}
	};
	console.log('parentData', purchaseOrderData);

	//#endregion

	//#region 浮點數限制與轉換

	// 1.小數點位數限制
	const localDecimalPlaceTl = parseInt(
		cookies._company_currency_decimalplace_tl
	);
	const localDecimalPlaceUp = parseInt(
		cookies._company_currency_decimalplace_up
	);
	const foreignDecimalPlaceTl = parseInt(
		purchaseOrderData.page0.decimalPlaceTl
	);

	// 2.轉換浮點數，並四捨五入到小數第decimalPlaces位
	function convertToFloat(inputStr, decimalPlaces) {
		let floatNum = parseFloat(inputStr);

		if (!isNaN(floatNum)) {
			floatNum =
				Math.round(floatNum * Math.pow(10, decimalPlaces)) /
				Math.pow(10, decimalPlaces);
		}

		return floatNum;
	}

	//#endregion

	//#region card內容改變，重新計算topInfo數值
	const reCalculateTopInfo = (newData) => {
		// foreignCurrency;
		if (purchaseOrderData.page0.foreignCurrency) {
			let newForeignNetAmount = 0;
			newData.forEach((el) => {
				newForeignNetAmount += el.netAmount;
			});

			let newNetAmount = convertToFloat(
				newForeignNetAmount * purchaseOrderData.page0.exchangeRate,
				localDecimalPlaceTl
			);

			let newInputTaxRate = purchaseOrderData.page0.inputTaxRate / 100;

			const newTopInfo = {
				foreignNetAmount: convertToFloat(
					newForeignNetAmount,
					foreignDecimalPlaceTl
				),
				foreignTaxAmount: convertToFloat(
					newForeignNetAmount * newInputTaxRate,
					foreignDecimalPlaceTl
				),
				foreignGrossAmount: convertToFloat(
					newForeignNetAmount + newForeignNetAmount * newInputTaxRate,
					foreignDecimalPlaceTl
				),
				netAmount: convertToFloat(newNetAmount, localDecimalPlaceTl),
				taxAmount: convertToFloat(
					newNetAmount * newInputTaxRate,
					localDecimalPlaceTl
				),
				grossAmount: convertToFloat(
					newNetAmount + newNetAmount * newInputTaxRate,
					localDecimalPlaceTl
				),
			};

			setTopInfoData({ ...topInfoData, ...newTopInfo });
		} else {
			let newNetAmount = 0;
			newData.forEach((el) => {
				newNetAmount += el.netAmount;
			});

			let newInputTaxRate = purchaseOrderData.page0.inputTaxRate / 100;

			const newTopInfo = {
				foreignNetAmount: 0,
				foreignTaxAmount: 0,
				foreignGrossAmount: 0,
				netAmount: convertToFloat(newNetAmount, localDecimalPlaceTl),
				taxAmount: convertToFloat(
					newNetAmount * newInputTaxRate,
					localDecimalPlaceTl
				),
				grossAmount: convertToFloat(
					newNetAmount + newNetAmount * newInputTaxRate,
					localDecimalPlaceTl
				),
			};

			setTopInfoData({ ...topInfoData, ...newTopInfo });
		}
	};
	console.log('topInfoData', topInfoData);

	//#endregion

	//#region 滑鼠移動到新增按鈕(物料/資產)，提示訊息
	const handleMouseEnter = (categoryControl) => {
		if (categoryControl === 'M') {
			setShowUnableBtnPopup('M');
		} else if (categoryControl === 'A') {
			setShowUnableBtnPopup('A');
		}
	};

	const handleMouseLeave = () => {
		setShowUnableBtnPopup('');
	};
	// console.log(showUnableBtnPopup);

	//#endregion

	//#region 第一次獲得後端card資料(複製訂單)
	const createOrganizeItemListFromCopy = async (
		dataFromCopy,
		dialogDataFromBE
	) => {
		let itemListFromCopy = [];

		// 如果有換廠商，改變categoryControl，則先篩選
		if (purchaseOrderData.page0.categoryControl === 'M') {
			itemListFromCopy = [...dataFromCopy.purchaseOrderItemList].filter(
				(item) => {
					return item.purchaseCategoryCode !== 'M';
				}
			);
		} else if (purchaseOrderData.page0.categoryControl === 'A') {
			itemListFromCopy = [...dataFromCopy.purchaseOrderItemList].filter(
				(item) => {
					return item.purchaseCategoryCode !== 'A';
				}
			);
		} else if (purchaseOrderData.page0.categoryControl === '0') {
			itemListFromCopy = [...dataFromCopy.purchaseOrderItemList];
		}
		console.log(itemListFromCopy);

		const organizeItemList = itemListFromCopy.map(async (obj) => {
			if (obj.purchaseCategoryCode === 'M') {
				// call api 計算 價格差異
				let materialMasterVendorPrice = '';
				let diffPrice = '';
				const handleQuantityPurchaseBlur = async () => {
					try {
						const url = `api/Logistics/LgsPurchaseOrder/getMaterialMasterVendorPrice?MaterialGuid=${obj.materialGuid}&VendorGuid=${purchaseOrderData.page0.vendorGuid}&OrderDate=${purchaseOrderData.page0.orderDate}&Quantity=${obj.quantityPurchase}&CurrencyCode=${purchaseOrderData.page0.currencyCode}`;

						await axiosGetVendorPrice({ url }, (res) => {
							// console.log('materialMasterVendorPrice', res);

							materialMasterVendorPrice = res;

							if (res !== 0) {
								let diffPriceOrigin =
									((obj.netPrice - res) / res) * 100;
								diffPrice =
									Math.round(
										diffPriceOrigin *
											Math.pow(10, localDecimalPlaceUp)
									) / Math.pow(10, localDecimalPlaceUp);
							}
						});
					} catch (error) {
						console.error(error);
					}
				};

				await handleQuantityPurchaseBlur();

				return {
					aDataFromBackEnd: true,
					purchaseCategoryCode: obj.purchaseCategoryCode,
					purchaseOrderItemGuid: obj.purchaseOrderItemGuid,
					cardChildName: obj.materialName,
					cardName: obj.materialCode,
					deliverDate: obj.deliverDate.split('T')[0],
					deliverDateWarning: '',
					diffPrice: diffPrice,
					diffPriceWarning: '',
					displayOrder: obj.displayOrder,
					imageUrl: obj.imageUrl,
					materialGuid: obj.materialGuid,
					materialMasterVendorPrice: materialMasterVendorPrice,
					materialMasterVendorPriceWarning: '',
					name: obj.materialCode + ' ' + obj.materialName,
					netAmount: obj.netAmount,
					netAmountWarning: '',
					netPrice: obj.netPrice,
					netPriceWarning: '',
					note: obj.note,
					noteWarning: '',
					quantityPurchase: obj.quantityPurchase,
					quantityPurchaseWarning: '',
					quantityDelivered: obj.quantityDelivered || 0,
					taxAmount: obj.taxAmount,
					taxAmountWarning: '',
					receiveInspection: obj.inspection,
					uomCode: obj.uomcode,
					uomName01: dialogDataFromBE.materialMasterList.filter(
						(el) => el.materialGuid === obj.materialGuid
					)[0].uomName,
					rowStamp: obj.rowStamp,
				};
			} else if (obj.purchaseCategoryCode === 'E') {
				// let uomItem = dataFromCopy.uomList.filter(
				// 	(el) => el.uomcode === obj.uomcode
				// )[0];
				// console.log(uomItem);

				console.log(dialogDataFromBE);
				let expenseClassItem = dialogDataFromBE.expenseClassList.filter(
					(el) => el.expenseClassGuid === obj.expenseClassGuid
				)[0];

				return {
					aDataFromBackEnd: true,
					accountGuid: obj.accountGuid,
					purchaseCategoryCode: obj.purchaseCategoryCode,
					purchaseOrderItemGuid: obj.purchaseOrderItemGuid,
					cardChildName: obj.sectorCode + ' ' + obj.sectorName,
					cardChildName2: obj.accountCode + ' ' + obj.accountName,
					cardName:
						expenseClassItem.expenseClassCode +
						' ' +
						expenseClassItem.name01,
					deliverDate: obj.deliverDate.split('T')[0],
					deliverDateWarning: '',
					displayOrder: obj.displayOrder,
					expenseClassGuid: obj.expenseClassGuid,
					imageUrl: obj.imageUrl,
					name:
						expenseClassItem.expenseClassCode +
						' ' +
						expenseClassItem.name01,
					netAmount: obj.netAmount,
					netAmountWarning: '',
					netPrice: obj.netPrice,
					netPriceWarning: '',
					note: obj.note,
					noteWarning: '',
					quantityPurchase: obj.quantityPurchase,
					quantityPurchaseWarning: '',
					quantityDelivered: obj.quantityDelivered || 0,
					sectorClassGuid: obj.sectorClassGuid,
					sectorGuid: obj.sectorGuid,
					taxAmount: obj.taxAmount,
					taxAmountWarning: '',
					uomCode: obj.uomcode,
					uomGuid: dialogDataFromBE.uomList.filter(
						(el) => el.uomcode === obj.uomcode
					)[0].uomguid,
					uomName01: dialogDataFromBE.uomList.filter(
						(el) => el.uomcode === obj.uomcode
					)[0].name01,
					rowStamp: obj.rowStamp,
				};
			} else if (obj.purchaseCategoryCode === 'A') {
				return {
					aDataFromBackEnd: true,
					assetsGuid: obj.assetGuid,
					purchaseCategoryCode: obj.purchaseCategoryCode,
					purchaseOrderItemGuid: obj.purchaseOrderItemGuid,
					cardChildName: obj.assetName,
					cardName: obj.assetCode,
					deliverDate: obj.deliverDate.split('T')[0],
					displayOrder: obj.displayOrder,
					imageUrl: obj.imageUrl,
					name: obj.assetCode + ' ' + obj.assetName,
					netAmount: obj.netAmount,
					netAmountWarning: '',
					netPrice: obj.netPrice,
					netPriceWarning: '',
					note: obj.note,
					noteWarning: '',
					quantityPurchase: obj.quantityPurchase,
					quantityPurchaseWarning: '',
					quantityDelivered: obj.quantityDelivered || 0,
					taxAmount: obj.taxAmount,
					taxAmountWarning: '',
					uomCode: obj.uomCode,
					// TODO 此處assetsGuid 兩個來源一個有s一個沒有 資料庫問題 未來要解
					uomName01: dialogDataFromBE.assetsList.filter(
						(el) => el.assetsGuid === obj.assetGuid
					)[0].uomName,
					rowStamp: obj.rowStamp,
				};
			}

			return obj;
		});

		const result = await Promise.all(organizeItemList);
		console.log(result);
		setNewPurchaseOrderData(result);

		reCalculateTopInfo(result);
	};

	const isEverEnterPage = purchaseOrderData.isEverEnterPage.page1;
	// console.log(isEverEnterPage);

	//#endregion

	//#region useEffect
	useEffect(() => {
		// 1.axios get BE data
		const url = `api/Logistics/LgsPurchaseOrder/getCreateDatapage1`;
		axiosDataPage1({ url }, (res) => {
			setDialogDataFromBE(res);

			// 2.如果複製訂單，並且第一次進入此頁
			if (
				Object.keys(copyPurchaseOrderData).length !== 0 &&
				copyPurchaseOrderData.purchaseOrderItemList.length !== 0 &&
				!isEverEnterPage
			) {
				createOrganizeItemListFromCopy(copyPurchaseOrderData, res);

				// 標記是否曾拿取過資料
				let newData = { ...purchaseOrderData };
				newData.isEverEnterPage.page1 = true;

				setPurchaseOrderData(newData);
			}
		});

		// 3. 回到此頁時，從parent回復資料
		// 有資料則回復資料
		Object.keys(purchaseOrderData.page1).length !== 0 &&
			setNewPurchaseOrderData(purchaseOrderData.page1);

		// 更新topInfoData
		setTopInfoData(purchaseOrderData.topInfoData);

		// 重新計算金額，更新topInfo金額
		reCalculateTopInfo(purchaseOrderData.page1);
	}, []);
	console.log('BackEndData', dialogDataFromBE);

	//#endregion

	return (
		<>
			{/* 上下步驟 */}
			<Container>
				<Left>
					{page !== 0 && (
						<PrevBtnSmall
							handleClick={() => {
								handleChangePage('prev');
							}}
						/>
					)}
				</Left>
				<Center>
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					{page < processSteps.length - 1 ? (
						<NextBtnSmall
							handleClick={() => {
								handleChangePage('next');
							}}
						/>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>

			{/* 主功能 */}
			<Wrapper className="row bg-white rounded-xxl px-6 pt-4 pb-11 mx-0">
				<div className="col-12 px-0 ">
					{/* topInfo */}
					<TopInfo
						topInfoData={topInfoData}
						purchaseOrderData={purchaseOrderData}
					/>
					<hr className="mt-5 mb-4" />

					{/* button row */}
					<div className="position-relative d-flex mb-3">
						{/* 無法點擊按鈕-提示訊息 */}
						{showUnableBtnPopup && (
							<UnableBtnErrPopup className="unableBtnPopup position-absolute d-flex justify-content-center align-items-center bg-white rounded-1 py-2 px-3">
								<span className="text-danger">
									<Icn_AlertExclamation className="me-2" />
									{t(
										'lgsPurchaseOrder:purchaseNotAllowedForOrderType'
									)`${
										showUnableBtnPopup === 'M'
											? t('lgsPurchaseOrder:material')
											: showUnableBtnPopup === 'A'
											? t('lgsPurchaseOrder:asset')
											: ''
									}。`}
								</span>
							</UnableBtnErrPopup>
						)}

						{/* 3 button dialog */}
						<Dialog
							btnWord={t('lgsPurchaseOrder:material')}
							btnKeyName="material"
							newPurchaseOrderData={newPurchaseOrderData}
							setNewPurchaseOrderData={setNewPurchaseOrderData}
							cardKeyNameList={cardKeyNameList}
							dialogDataFromBE={dialogDataFromBE}
							// 判斷是否有權限click
							categoryControl={
								purchaseOrderData.page0.categoryControl === 'M'
									? 'disabled'
									: ''
							}
							// 是否顯示無法click提示訊息
							handleMouseEnter={() =>
								purchaseOrderData.page0.categoryControl ===
									'M' &&
								handleMouseEnter(
									purchaseOrderData.page0.categoryControl
								)
							}
							handleMouseLeave={() =>
								purchaseOrderData.page0.categoryControl ===
									'M' &&
								handleMouseLeave(
									purchaseOrderData.page0.categoryControl
								)
							}
						/>

						<DialogDouble
							btnWord={t('lgsPurchaseOrder:expenseCategory')}
							btnKeyName="expenseClass"
							btnWord2={t('lgsPurchaseOrder:department')}
							btnKeyName2="sector"
							newPurchaseOrderData={newPurchaseOrderData}
							setNewPurchaseOrderData={setNewPurchaseOrderData}
							cardKeyNameList={cardKeyNameList}
							dialogDataFromBE={dialogDataFromBE}
							categoryControl={
								purchaseOrderData.page0.categoryControl
							}
						/>

						<Dialog
							btnWord={t('lgsPurchaseOrder:asset')}
							btnKeyName="assets"
							newPurchaseOrderData={newPurchaseOrderData}
							setNewPurchaseOrderData={setNewPurchaseOrderData}
							cardKeyNameList={cardKeyNameList}
							dialogDataFromBE={dialogDataFromBE}
							// 判斷是否有權限click
							categoryControl={
								purchaseOrderData.page0.categoryControl === 'A'
									? 'disabled'
									: ''
							}
							// 是否顯示無法click提示訊息
							handleMouseEnter={() =>
								purchaseOrderData.page0.categoryControl ===
									'A' &&
								handleMouseEnter(
									purchaseOrderData.page0.categoryControl
								)
							}
							handleMouseLeave={() =>
								purchaseOrderData.page0.categoryControl ===
									'A' &&
								handleMouseLeave(
									purchaseOrderData.page0.categoryControl
								)
							}
						/>

						{/* 展開/收折全部 BTN */}
						<div className="ms-auto">
							{toggleBtnAllOpen ? (
								<ToggleBtn
									onClick={() => {
										// 箭頭btn向下
										document
											.querySelectorAll('.iconChevron')
											.forEach(
												(el) =>
													(el.style.transform =
														'rotate(0.5turn)')
											);

										// 隱藏全部子層
										document
											.querySelectorAll('.childInfoTr')
											.forEach((item) =>
												item.classList.add('d-none')
											);
										setToggleBtnAllOpen(false);
									}}
								>
									{t('lgsPurchaseOrder:foldAll')}
									{/* 箭頭btn向上 */}
									<Icn_chevronCircleDown
										className="ms-2 mb-1"
										style={{
											transform: 'rotate(0.5turn)',
										}}
									/>
								</ToggleBtn>
							) : (
								<ToggleBtn
									onClick={() => {
										// 箭頭btn向上
										document
											.querySelectorAll('.iconChevron')
											.forEach(
												(el) =>
													(el.style.transform = '')
											);

										// 顯示子層
										document
											.querySelectorAll('.childInfoTr')
											.forEach((item) =>
												item.classList.remove('d-none')
											);
										setToggleBtnAllOpen(true);
									}}
								>
									{t('lgsPurchaseOrder:expandAll')}
									{/* 箭頭btn向下 */}
									<Icn_chevronCircleDown className="ms-2 mb-1" />
								</ToggleBtn>
							)}
						</div>
					</div>

					{/* card */}
					<WrapperGrey className="row rounded-xxl">
						<Page1_Item
							dialogDataFromBE={dialogDataFromBE}
							parentData={purchaseOrderData}
							newPurchaseOrderData={newPurchaseOrderData}
							setNewPurchaseOrderData={setNewPurchaseOrderData}
							cardKeyNameList={cardKeyNameList}
							topInfoData={topInfoData}
							setTopInfoData={setTopInfoData}
							localDecimalPlaceTl={localDecimalPlaceTl}
							localDecimalPlaceUp={localDecimalPlaceUp}
							convertToFloat={convertToFloat}
							reCalculateTopInfo={reCalculateTopInfo}
						/>
					</WrapperGrey>
				</div>
			</Wrapper>
		</>
	);
}

export default Page1;
