import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import ThreeColLayout from '../../../../../components/ThreeColLayout/ThreeColLayout';
import { DialogCol } from '../DialogCol';
import { useTranslation } from 'react-i18next';

//#region styled-components
const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

// -----DialogCol------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

const TopInfo = ({
	pageData,
	setPageData,
	dialogBtnKeyName,
	setDialogBtnKeyName,
	isOpen,
	setIsOpen,
	modalData,
	setModalData,
	backEndData,
}) => {
	const { t } = useTranslation();
	const handleInput = (inputName) => {
		let newPageData = { ...pageData };
		newPageData.goodsItemInfo[inputName] = event.target.value;
		newPageData.goodsItemInfo[`${inputName}Warning`] = ''; // 清空錯誤訊息

		setPageData(newPageData);
	};
	// console.log('pageData', pageData);
	function LeftCol1() {
		//#region Dialog Col

		// DialogCol input 黃色按鈕取消
		const handleCancelClick = () => {
			const newData = { ...pageData };
			newData.goodsItemInfo.employeeGuid = '';
			newData.goodsItemInfo.employeeName = '';
			// newData.goodsItemInfo.inspectDate = '';
			// console.log(newData);

			setPageData(newData);
		};

		// DialogCol Modal open
		const handleOpen = (btnKeyName) => {
			setDialogBtnKeyName(btnKeyName);
			setModalData(backEndData.employeeList);
			setIsOpen(true);
		};

		//#endregion

		//取得今日日期
		const [inspectDate, setInspectDate] = useState('');
		useEffect(() => {
			const currentDate = new Date();
			const formattedDate = currentDate.toISOString().split('T')[0];
			setInspectDate(formattedDate);
		}, []);

		useEffect(() => {
			//console.log(inspectDate);
		}, [inspectDate]);

		const handlePostingDateChange = (val) => {
			setPageData({
				...pageData,
				goodsItemInfo: { ...pageData.goodsItemInfo, inspectDate: val },
			});
			console.log(pageData);
		};

		return (
			<>
				<div className="row mb-3">
					<div className="col-lg-12">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`inspectDate`}>
								{t(
									'lgsPurchaseOrderGoodsInspect:topInfo.inspectionDate'
								)}
							</LabelNormal>
							<InputElement
								type="date"
								id={`inspectDate`}
								className=""
								// defaultValue={todayDate}
								// defaultValue={inspectDate || ''}

								value={
									pageData?.goodsItemInfo?.inspectDate || ''
								}
								onChange={(e) =>
									handlePostingDateChange(e.target.value)
								}
								// defaultValue={
								// 	pageData.goodsItemInfo.inspectDate
								// }
								// onChange={() => handleInput('inspectDate')}
							/>

							<span className="text-danger ms-1 my-0">
								{pageData.goodsItemInfo.inspectDateWarning}
							</span>
						</div>
					</div>
				</div>

				{/* <div className="row mb-3">
					<div className="col-lg-12">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`inspectDate`}>
								檢驗日期1
							</LabelNormal>
							<InputElement
								type="date"
								id={`inspectDate`}
								className=""
								// defaultValue={todayDate}
								// defaultValue={inspectDate || ''}
								defaultValue={
									pageData.goodsItemInfo.inspectDate
								}
								onChange={() => handleInput('inspectDate')}
							/>

							<span className="text-danger ms-1 my-0">
								{pageData.goodsItemInfo.inspectDateWarning}
							</span>
						</div>
					</div>
				</div> */}

				<div className="d-flex flex-column">
					<LabelNormal htmlFor={`staff`}>
						{t('lgsPurchaseOrderGoodsInspect:topInfo.inspector')}
					</LabelNormal>

					<DialogWrapper className="row flex-nowrap">
						<InputDialogElementData
							type="text"
							value={pageData.goodsItemInfo.employeeGuid || ''}
							onChange={() => {}}
						/>
						<InputDialogElementView
							style={{
								minWidth: '85px',
								cursor: 'auto',
							}}
							value={pageData.goodsItemInfo.employeeName || ''}
							onChange={() => {}}
						/>

						<CancelInputBtn
							onClick={() => {
								handleCancelClick();
							}}
							isValueExist={pageData.goodsItemInfo.employeeName}
						>
							<Icn_InputYellowCancel />
						</CancelInputBtn>

						<DialogBtn
							style={{
								cursor: 'pointer',
							}}
							onClick={() => handleOpen('employee')}
						>
							<Icn_InputDialog />
						</DialogBtn>
					</DialogWrapper>
					<span className="text-danger ms-1 my-0">
						{pageData.goodsItemInfo.employeeNameWarning}
					</span>
					{/* DialogCol element */}
					<DialogCol
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						modalData={modalData}
						setModalData={setModalData}
						btnKeyName={dialogBtnKeyName}
						pageData={pageData}
						setPageData={setPageData}
					/>
				</div>

				<div className="row pt-3">
					<div className="col-lg-6">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderNo`}>
								{t(
									'lgsPurchaseOrderGoodsInspect:topInfo.netReceivedQuantity'
								)}
							</LabelNormal>
							<InputElement
								type="number"
								id={`orderNo`}
								className="mb-3"
								disabled
								defaultValue={
									pageData.goodsItemInfo.netReceivedQuantity
								}
							/>
						</div>
					</div>

					<div className="col-lg-6 ps-0">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderDate`}>
								{t(
									'lgsPurchaseOrderGoodsInspect:topInfo.inspectedQuantity'
								)}
							</LabelNormal>
							<InputElement
								type="number"
								id={`orderDate`}
								className="mb-3"
								disabled
								defaultValue={
									pageData.goodsItemInfo.quantityInspect ?? 0
								}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	function CenterCol1() {
		return (
			<>
				<div className={`wrapper d-flex flex-column mb-3`}>
					<LabelNormal htmlFor={'note'}>
						{t('lgsPurchaseOrderGoodsInspect:topInfo.annotation')}
					</LabelNormal>
					<TextareaElement
						//style={elementStyle}
						id={'note'}
						rows={'4'}
						cols={'30'}
						defaultValue={pageData.goodsItemInfo.note}
						onBlur={() => handleInput('note')}
						// onChange={() => handleInput('note')}--為何onChange 不行?
						disabled={false}
						// disabledCssType={0}
					/>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderNo`}>
								{t(
									'lgsPurchaseOrderGoodsInspect:topInfo.pendingInspectionQuantity'
								)}
							</LabelNormal>
							<InputElement
								type="number"
								id={`orderNo`}
								className="mb-3"
								disabled
								defaultValue={
									pageData.goodsItemInfo
										.pendingInspectionQuantity
								}
							/>
						</div>
					</div>

					<div className="col-lg-6 ps-0">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderDate`}>
								{t(
									'lgsPurchaseOrderGoodsInspect:topInfo.currentInspectionQuantity'
								)}
							</LabelNormal>
							<InputElement
								type="number"
								id={`orderDate`}
								className="mb-3"
								disabled
								defaultValue={
									pageData.goodsItemInfo
										.thisQuantityInspect || 0
								}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	function RightCol1() {
		return (
			<>
				<div className="d-flex flex-column">
					<LabelNormal htmlFor={`vendor`}>
						{t('lgsPurchaseOrderGoodsInspect:topInfo.vendor')}
					</LabelNormal>
					<InputElement
						type="text"
						id={`vendor`}
						className="mb-3"
						disabled
						defaultValue={pageData.goodsItemInfo.vendorName}
					/>
				</div>
				<div className="row">
					<div className="col-lg-7">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderNo`}>
								{t(
									'lgsPurchaseOrderGoodsInspect:topInfo.receiptNumber'
								)}
							</LabelNormal>
							<InputElement
								type="text"
								id={`orderNo`}
								className="mb-3"
								disabled
								defaultValue={pageData.goodsItemInfo.documentNo}
							/>
						</div>
					</div>

					<div className="col-lg-5 ps-0">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderDate`}>
								{t(
									'lgsPurchaseOrderGoodsInspect:topInfo.receiveDate'
								)}
							</LabelNormal>
							<InputElement
								type="date"
								id={`orderDate`}
								className="mb-3"
								disabled
								defaultValue={
									pageData.goodsItemInfo.postingDate?.split(
										'T'
									)[0]
								}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-7">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderNo`}>
								{t(
									'lgsPurchaseOrderGoodsInspect:topInfo.remainingQuantity'
								)}
							</LabelNormal>
							<InputElement
								type="number"
								id={`orderNo`}
								className="mb-3"
								disabled
								defaultValue={String(
									pageData.goodsItemInfo
										.pendingInspectionQuantity -
										pageData.goodsItemInfo
											.thisQuantityInspect
								)}
							/>
						</div>
					</div>

					<div className="col-lg-5 ps-0">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderDate`}>
								{t(
									'lgsPurchaseOrderGoodsInspect:topInfo.unitOfMeasure'
								)}
							</LabelNormal>
							<InputElement
								type="text"
								id={`orderDate`}
								className="mb-3"
								disabled
								defaultValue={pageData.goodsItemInfo.uomName}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<ThreeColLayout
			LeftCol={<LeftCol1 />}
			CenterCol={<CenterCol1 />}
			RightCol={<RightCol1 />}
		/>
	);
};

export default TopInfo;
