import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CopyIcon } from '../../assets/images/icn_Copy.svg';
import { BtnNormal } from './BtnNormal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const CancelSubmitBtn = ({ className, to }) => {
	// console.log(className, to);
	const { t } = useTranslation(['common']); // i18n
	const navigate = useNavigate();
	return (
		<BtnNormal
			className="layout-title-searchBtn layout-title-item btn btn-outline-primary d-flex justify-content-center align-items-center me-2"
			// className={`btn ${className}`}
			to={'Create'}
			key={'CopyCreateBtn'}
			onClick={() => {
				navigate('..');
			}}
		>
			<span>{t('common:button.cancelButton')}</span>
		</BtnNormal>
	);
};
