import useSortableData from '../../../../../hooks/useSortableData';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useHover from '../../../../../hooks/useHover';
import Modal from '../../../../../components/Modals/IndexTableDisplayModal';
import Pagination from '../../../../../components/Pagination/Pagination';
import { useTranslation } from 'react-i18next';

// #region styled-components

const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
`;

const TH = styled.th`
	vertical-align: middle;
	border: none;
`;

const TBody = styled.tbody``;

const TD = styled.td`
	border: none;
`;

//#endregion

function Page0Partial(props) {
	const { t } = useTranslation(['common', 'lgsPurchaseOrderInvoice']); // i18n

	// console.log(props);
	const {
		guidName,
		sortBy,
		headTitleList,
		bodyTitleList,
		indexData,
		displayColumns,
		setDisplayColumns,
		paginationData,
		setPaginationData,
		fetchData,
		fetchedData,
		tableStyles,
		columnMustShow,
		rowClick,
		advancedSearchDataList,
		clickSelection,
		singleChecked,
		setSingleChecked,
	} = props;

	const [hoverRef, isHovered] = useHover();
	const [hoverRef1, isHovered1] = useHover();
	const [orderBy, setOrderBy] = useState('desc');
	const [UISort, setUISort] = useState(sortBy);
	const [displayOptions, setDisplayOptions] = useState(displayColumns);

	// for checkbox column
	// const [isCheckAll, setIsCheckAll] = useState(false);
	// const [singleChecked, setSingleChecked] = useState([]);
	const [list, setList] = useState([]);

	const { items, requestSort, sortConfig } = useSortableData(indexData);
	const navigate = useNavigate();
	const location = useLocation();

	const statusSpanObj = {
		0: <span></span>,
		1: (
			<span>
				<span
					className="bg-warning d-inline-block mr-2"
					style={{
						width: '8px',
						height: '8px',
						borderRadius: '50%',
					}}
				></span>
				<span className="text-grey4">
					{t('common:status.inactive')}
				</span>
			</span>
		),
		2: (
			<span>
				<span
					className="bg-danger d-inline-block mr-2"
					style={{
						width: '8px',
						height: '8px',
						borderRadius: '50%',
					}}
				></span>
				<span className="text-grey4">{t('common:status.lock')}</span>
			</span>
		),
	};

	// #region functions

	// !!! 好像沒有用的 function => 本意為希望 tableHeader 保持和 UI 排序一致, 但測試中好像不需要也會排序一致?
	// TODO: 暫先註解, 找出 function 使用時機後再處理
	// const preferredOrder = (indexData, bodyTitleList) => {
	//     let newObject = {};
	//     for (let i = 0; i < bodyTitleList.length; i++) {
	//         if (indexData.hasOwnProperty(bodyTitleList[i])) {
	//             newObject[bodyTitleList[i]] = indexData[bodyTitleList[i]];
	//         }
	//     }
	//     return newObject;
	// };

	const getClassNamesFor = (name) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	const handleRowClick = (newGuid) => {
		// === 1 -> 多選
		if (clickSelection === 1) {
			// 找出選中的 row
			const index = singleChecked.indexOf(newGuid);

			if (index !== -1) {
				setSingleChecked(
					singleChecked.filter((guid) => guid !== newGuid)
				);
			} else {
				setSingleChecked([...singleChecked, newGuid]);
			}
		} else {
			navigate(`${location.pathname}/Details/${guid}`, {
				state: {
					permission: fetchedData.permission,
				},
			});
		}
	};

	/// === 2 單選
	// if (index !== -1) {
	// 	// 本來是 checked 的, 變成不選
	// 	setSingleChecked('');
	// } else {
	// 	// 本來是不選的, 變成 checked
	// 	setSingleChecked(newGuid);
	// }

	const handleSortChange = (newTarget) => {
		const oldTarget = UISort;
		setUISort(newTarget);

		if (oldTarget === newTarget)
			return setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');

		return setOrderBy('asc');
	};

	// const handleSelectAll = (e) => {
	//     e.stopPropagation();
	//     setIsCheckAll(!isCheckAll);
	//     setSingleChecked(list.map((li) => li));
	//     if (isCheckAll) {
	//         setSingleChecked([]);
	//     }
	// };

	// const checkingIsCheckAll = useCallback(() => {
	//     const checkIsCheckAll = () => {
	//         if (list.length === singleChecked.length) {
	//             setIsCheckAll(true);
	//         } else {
	//             setIsCheckAll(false);
	//         }
	//     };

	//     singleChecked.length && checkIsCheckAll();
	// });

	// const handleSelect = (e) => {
	//     e.stopPropagation();
	//     const { id, checked } = e.target;
	//     setSingleChecked([...singleChecked, id]);
	//     if (!checked) {
	//         setSingleChecked(singleChecked.filter((item) => item !== id));
	//     }
	// };orderBy

	useEffect(() => {
		let guidList = items.map((el) => el[guidName]);

		setList(guidList);

		// return checkingIsCheckAll();
	}, [singleChecked]);

	// console.log(singleChecked);

	//#endregion

	return (
		<>
			<IndexTableContainer>
				<IndexTable
					className="table"
					style={{
						// display: 'block' 可讓 scroll bar 接在 table 下面
						// display: 'block',
						border: '1px solid var(--grey1)',
						overflowX: 'auto',
						whiteSpace: 'nowrap',
						borderCollapse: 'collapse',
						borderSpacing: '0',
						wordWrap: 'break-word',
						wordBreak: 'break-all',
					}}
				>
					<THead
						className="table-head"
						items={items}
						requestSort={requestSort}
						sortConfig={sortConfig}
					>
						<TR ref={hoverRef} className="d-flex">
							{clickSelection ? (
								clickSelection === 2 ? (
									<TH>
										<input
											type="checkbox"
											onChange={(e) => {
												handleSelectAll(e);
											}}
											checked={isCheckAll}
										/>
									</TH>
								) : (
									<TH>
										<input
											type="checkbox"
											style={{ visibility: 'hidden' }}
										/>
									</TH>
								)
							) : null}
							{headTitleList?.map((el) => {
								// if
								return (
									<TH
										style={
											tableStyles[
												headTitleList.indexOf(el)
											]
										}
										key={`tableHead${headTitleList.indexOf(
											el
										)}`}
										className={`
                                            px-2 w-100 cursor-pointer
                                            ${
												!displayColumns.includes(
													bodyTitleList[
														headTitleList.indexOf(
															el
														)
													]
												)
													? 'd-none'
													: 'd-block'
											}
                                            ${
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											}
                                            ${getClassNamesFor(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											)}
                                                `}
										onClick={() => {
											requestSort(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											);
											handleSortChange(
												bodyTitleList[
													headTitleList.indexOf(el)
												]
											);
										}}
									>
										{el}
										{bodyTitleList[
											headTitleList.indexOf(el)
										] === UISort.split(' ')[0] &&
											(orderBy === 'desc' ? (
												<span className="ml-1">▼</span>
											) : (
												<span className="ml-1">▲</span>
											))}
									</TH>
								);
							})}
						</TR>
					</THead>
					<TBody>
						{items?.map((data) => (
							<TR
								className="list-item table-item d-flex"
								id={data[guidName]}
								key={data[guidName]}
								onClick={(e) =>
									rowClick && handleRowClick(data[guidName])
								}
								singleChecked={singleChecked.includes(
									data[guidName]
								)}
							>
								{clickSelection ? (
									<TD>
										<input
											id={data[guidName]}
											type="checkbox"
											// onClick={(e) => e.stopPropagation()}
											// onChange={(e) => handleSelect(e)}
											onChange={() => {}}
											checked={singleChecked.includes(
												data[guidName]
											)}
										/>
									</TD>
								) : null}
								{bodyTitleList.map((el) => {
									if (el === 'status')
										return (
											<TD
												style={
													tableStyles[
														bodyTitleList.indexOf(
															el
														)
													]
												}
												className={`p-2 w-100
                                                    ${
														!displayColumns.includes(
															el
														)
															? 'd-none'
															: 'd-block'
													}`}
												key={`${el}${data[guidName]}`}
											>
												{statusSpanObj[data[el]]}
											</TD>
										);
									return (
										<TD
											style={{
												...tableStyles[
													bodyTitleList.indexOf(el)
												],
												overflow: 'hidden',
											}}
											className={`p-2 w-100
                                                ${
													!displayColumns.includes(el)
														? 'd-none'
														: 'd-block'
												}`}
											key={`${el}${data[guidName]}`}
										>
											{data[el]}
										</TD>
									);
								})}
							</TR>
						))}
					</TBody>
				</IndexTable>
				<Pagination
					paginationData={paginationData}
					setPaginationData={setPaginationData}
					fetchData={fetchData}
					sortBy={sortBy}
					advancedSearchDataList={advancedSearchDataList}
				/>
			</IndexTableContainer>
			<div ref={hoverRef1}>
				{
					<Modal
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						guidName={guidName}
						isHovered={isHovered || isHovered1}
						displayColumns={displayColumns}
						setDisplayColumns={setDisplayColumns}
						displayOptions={displayOptions}
						setDisplayOptions={setDisplayOptions}
						columnMustShow={columnMustShow}
					/>
				}
			</div>
		</>
	);
}

export default Page0Partial;
