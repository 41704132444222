import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { SaveSubmitBtn } from '../../../../../components/Buttons/SaveSubmitBtn';
import { CancelSubmitBtn } from '../../../../../components/Buttons/CancelSubmitBtn';
import useAxios from '../../../../../hooks/useAxios';
import AccountDocumentTab from './AccountDocumentTab/AccountDocumentTab';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import { useTranslation } from 'react-i18next';

// #region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page2(props) {
	//#region state initialization

	const { t } = useTranslation(['common', 'lgsPurchaseOrderInvoiceReturn']); // i18n

	const { parentData, page, processSteps, handlePrevPage, handleNextPage } =
		props;

	const decimalPlaceTl =
		parentData.page0.invoiceSelectedList[0].decimalPlaceTl;

	// state
	const [tabData, setTabData] = useState([]);
	const [orgData, setOrgData] = useState(null);

	// axios
	const { sendRequest: axiosDataPage2 } = useAxios();

	//#endregion

	//#region useEffect

	useEffect(() => {
		const url = `api/Logistics/LgsPurchaseOrderInvoiceReturn/getPage3`;
		const method = 'POST';

		const data = {
			InvoiceGuid: parentData.page0.invoiceGuidList,
		};

		axiosDataPage2({ url, method, data }, async (res) => {
			console.log(res);

			// 會計憑證
			const { entry1, entry2, entry3 } = res;

			// 給予排序，後端需要(將三個list內元件，依序給予序號)
			let displayOrder = 1;

			// 處理小數點位數
			const processEntry = (entry) => {
				entry.forEach((el) => {
					if (el.amountCr)
						el.amountCr = Number(el.amountCr).toFixed(3);

					if (el.amountDr)
						el.amountDr = Number(el.amountDr).toFixed(
							decimalPlaceTl
						);

					// 給displayOrder
					el.displayOrder = displayOrder;
					displayOrder++;
				});
			};

			processEntry(entry1);
			processEntry(entry2);
			processEntry(entry3);

			// 合體(前端畫面使用)
			let accountList = [...entry1, ...entry2, ...entry3];
			console.log(accountList);

			setTabData({
				// 前端畫面使用
				accountDocumentName: res.accountDocumentName,
				accountList: accountList,

				// 後端需要的，分開3個list
				dataToBackEnd: {
					entry1: entry1,
					entry2: entry2,
					entry3: entry3,
				},
			});

			// 整理給後端的資料
			setOrgData({
				...data,
				entry1: entry1,
				entry2: entry2,
				entry3: entry3,
			});
		});
	}, []);

	console.log('parentData', parentData);
	console.log('tabData', tabData);
	console.log('orgData', orgData);
	//#endregion

	//#region submit送出給後端

	// 儲存
	const { error, sendRequest: axiosSaveData } = useAxios();

	const handleSubmit = () => {
		console.log(orgData);

		const url = `api/Logistics/LgsPurchaseOrderInvoiceReturn/insert`;
		const method = 'POST';
		const data = orgData;

		axiosSaveData({ url, method, data }, (res) => {
			console.log(res);
			res && handleNextPage();
		});
		console.log(error);
	};

	//#endregion

	return (
		<>
			<Container>
				<Left>
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handlePrevPage();
							}}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="ps-14">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					<SaveSubmitBtn handleSubmit={handleSubmit} />
					<CancelSubmitBtn className={'btn-outline-primary'} />
				</Right>
			</Container>

			<div className="position-relative">
				<div
					className="bg-white rounded-xxl d-flex flex-column p-0"
					style={{ minHeight: '78vh' }}
				>
					<AccountDocumentTab
						parentData={parentData}
						tabData={tabData}
					/>
				</div>
			</div>
		</>
	);
}

export default Page2;
