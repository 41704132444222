import { useCookies } from 'react-cookie';

const useAuth = () => {
	const [cookies, setCookie] = useCookies();

	// 檢查 cookies 的有效性
	const isLogin = cookies['_system_identityserver'];
	// console.log('isLogin', isLogin);

	// 根據 cookies 的值執行相應的邏輯
	const checkAuth = () => {
		if (!isLogin) {
			// cookies 失效或過期，導向登入頁面
			window.location.href = 'AuthIdentityServer/Auth/Login';
		}
	};

	return { isLogin, checkAuth };
};

export default useAuth;
