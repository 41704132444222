const dangerIcon = `
    <svg id="icn_Danger-dangerIcon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
      <defs>
        <clipPath id="clip-path-dangerIcon">
          <rect id="Rectangle_3358" data-name="Rectangle 3358" width="16" height="16" fill="#ea4132"/>
        </clipPath>
      </defs>
      <g id="Group_6517" data-name="Group 6517" clip-path="url(#clip-path-dangerIcon)">
        <path id="Path_1437" data-name="Path 1437" d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0" fill="#ea4132" fill-rule="evenodd"/>
        <path id="Path_1438" data-name="Path 1438" d="M8,3.2a.8.8,0,0,1,.8.8V8.8a.8.8,0,0,1-1.6,0V4A.8.8,0,0,1,8,3.2" fill="#fff"/>
        <path id="Path_1439" data-name="Path 1439" d="M8,11.2a.8.8,0,1,0,.8.8.8.8,0,0,0-.8-.8" fill="#fff"/>
      </g>
    </svg>
`;

export default dangerIcon;
