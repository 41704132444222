import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { BtnNormal2 } from '../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { DialogCol } from '../Dialog/DialogCol';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../../assets/images/icn_Edit.svg';
import { ReactComponent as Icn_chevronTop } from '../../../../assets/images/icn_chevron-top.svg';
import { ReactComponent as CreateIcon } from '../../../../assets/images/icn_Create.svg';
import { ReactComponent as Icn_deleted } from '../../../../assets/images/icn_DeleteCross.svg';
import { DialogListStatus } from '../Dialog/DialogListStatus/DialogListStatus';
import axios from 'axios';
import Attachment from './Attachment';
import SalesDetails from './SalesDetails';
import PriceDetails from './PriceDetails';
import { DialogCustomerClass } from '../Dialog/DialogCustomerClass';
import { DialogConfirm } from '../Dialog/DialogConfirm';
import { DialogTwoCol } from '../Dialog/DialogTwoCol';
import { DialogCopyConfirm } from '../Dialog/DialogCopyConfirm';
import { DialogCustomerClassDeleteConfirm } from '../Dialog/DialogCustomerClassDeleteConfirm';

// #region styled-components

const EditStyle = styled(EditIcon)`
	path {
		fill: var(--primary);
	}
`;

const CustomerClassList = styled.div`
	height: 280px;
	overflow-y: scroll;
	border-radius: 0;
	border: 1px solid var(--grey1);
`;

const BtnCreate = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	cursor: pointer;
`;

//#endregion

function BottomInfo(props) {
	// i18n
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const {
		pageData,
		setPageData,
		tableBodyData,
		setTableBodyData,
		dialogBtnKeyName,
		setDialogBtnKeyName,
		isOpen,
		setIsOpen,
		modalData,
		setModalData,
		langList,
		getSpecList,
		specList,
		salesKitGuid,
		error,
		baseInfoList,
		selectedCustomerClassList,
		setSelectedCustomerClassList,
		isDetailRoute,
		isEditRoute,
		getDetails,
		handleFinalSubmit,
		isCopyDialogOpen,
		setIsCopyDialogOpen,
	} = props;
	// console.log(props);

	console.log(salesKitGuid);

	const [specSelectedList, setSpecSelectedList] = useState([]); // 選擇的規格與顏色 [guid, guid]
	const [isSpecDialogOpen, setIsSpecDialogOpen] = useState(false); // 是否開啟規格dialog
	const [itemDisplayList, setItemDisplayList] = useState({
		imgArea: true,
		salesDetailArea: true,
		priceArea: true,
	}); // 是否顯示詳細資訊區塊
	const [dialogConfirmData, setDialogConfirmData] = useState({
		isOpen: false,
		title: t(
			'lgsSalesKit:switchSpecificationOrColorWillDeleteNewInputData'
		), // 切換規格或顏色將會刪除新輸入的資料
		confirmInfo: { guid: '', specName: '' },
	}); // 確認"切換"規格或顏色視窗
	const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

	const [dialogCopyConfirmData, setDialogCopyConfirmData] = useState({
		isOpen: false,
		title: '',
	}); // 確認"複製"規格或顏色視窗

	const [dialogCustomerClassDeleteData, setDialogCustomerClassDeleteData] =
		useState({
			isOpen: false,
			title: t('lgsSalesKit:deleteCustomerCategory'), // 刪除客戶分類
			customerClassGuid: '',
		}); // 確認"刪除"客戶分類
	const [isCustomerClassDeleteClicked, setIsCustomerClassDeleteClicked] =
		useState(false);

	const [copyFunctionName, setCopyFunctionName] = useState(''); // "複製"的名稱 (copyAppendix copyItem copyPrice)

	// 規格或顏色視窗
	const [authorized, setAuthorized] = useState(false); //  LgsSalesKitSize / LgsSalesKitColor
	const [authorizedGuidName, setAuthorizedGuidName] = useState(false); // sizeGuid / colorGuid

	// 左邊表格的資料
	const [middleIndexList, setMiddleIndexList] = useState([]);
	const [middleOriginIndexList, setMiddleOriginIndexList] = useState([]);

	// 客戶分類List
	const [isDialogCustomerClassOpen, setIsDialogCustomerClassOpen] =
		useState(false); // 客戶分類dialog
	const [customerClassBtnActive, setCustomerClassBtnActive] = useState(''); //客戶分類BTN 預設為空

	console.log('specList', specList);

	// 選擇規格 顏色
	const handleSpecSelected = (guid, specName) => {
		let newData = [];
		let sizeDisplayOrder = '';
		let colorDisplayOrder = '';

		// newDisplayOrder
		const getDisplayOrder = (list, key, guid) => {
			const matchedItem = list.find((el) => el[key] === guid);
			return matchedItem ? matchedItem.displayOrder : null;
		};

		if (specName === 'lgsSalesKitSize') {
			newData = [guid, specSelectedList[1]];

			sizeDisplayOrder = getDisplayOrder(
				specList.lgsSalesKitSize,
				'sizeGuid',
				guid
			);
			colorDisplayOrder = getDisplayOrder(
				specList.lgsSalesKitColor,
				'colorGuid',
				specSelectedList[1]
			);
		} else if (specName === 'lgsSalesKitColor') {
			newData = [specSelectedList[0], guid];

			sizeDisplayOrder = getDisplayOrder(
				specList.lgsSalesKitSize,
				'sizeGuid',
				specSelectedList[0]
			);
			colorDisplayOrder = getDisplayOrder(
				specList.lgsSalesKitColor,
				'colorGuid',
				guid
			);
		}

		setSpecSelectedList(newData);

		console.log(
			sizeDisplayOrder,
			colorDisplayOrder,
			newData[0],
			newData[1]
		);

		getDetails(sizeDisplayOrder, colorDisplayOrder, newData[0], newData[1]); // 獲取最新的資料
	};

	// 開啟規格視窗
	const handleOpenSpecDialog = (authorizedName, authorizedGuidName) => {
		console.log(authorizedGuidName);
		setDialogBtnKeyName(
			`${
				authorizedGuidName === 'sizeGuid'
					? 'size'
					: authorizedGuidName === 'colorGuid'
					? 'color'
					: ''
			}`
		);

		setIsSpecDialogOpen(true);
		setAuthorized(authorizedName);
		setAuthorizedGuidName(authorizedGuidName);
		handleLeftColBtnClick(authorizedName); // 獲取表格資料
	};

	// 點擊規格視窗 左欄
	const handleLeftColBtnClick = async (authorizedName) => {
		console.log(authorizedName);

		await axios
			.post(
				`api/Logistics/${authorizedName}/getList`,
				JSON.stringify(salesKitGuid), // 銷售主檔guid // TODO:
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			.then((res) => {
				console.log(res.data);
				setMiddleIndexList(res.data.indexData);
				setMiddleOriginIndexList(res.data.indexData); // 保留原始資料，作為"新增"時，可參考的對象
			});
	};

	console.log(specList);

	// 客戶分類dialog
	const handleOpenDialogCustomerClass = (btnKeyName, listName) => {
		setDialogBtnKeyName(btnKeyName);

		// 已選擇的客戶類別GUID
		const selectedCustomerClassGuidList = selectedCustomerClassList.map(
			(el) => el.customerClassGuid
		);

		// baseInfoList[listName]的customerClassGuid不包含selectedCustomerClassGuidList已經有的guid
		// 剩下的就是可以選擇的
		const temp = baseInfoList[listName].filter(
			(el) =>
				!selectedCustomerClassGuidList.includes(el.customerClassGuid)
		);
		// console.log(temp);

		setModalData(temp);
		setIsDialogCustomerClassOpen(true);
	};

	const handleCustomerClassBtnClick = (customerClassGuid) => {
		setCustomerClassBtnActive(customerClassGuid);
	};

	// console.log(dialogConfirmData);
	useEffect(() => {
		if (isConfirmBtnClicked) {
			handleSpecSelected(
				dialogConfirmData.confirmInfo.guid,
				dialogConfirmData.confirmInfo.specName
			);
			setIsConfirmBtnClicked(false);
		}

		if (isCustomerClassDeleteClicked) {
			console.log(dialogCustomerClassDeleteData);

			// 刪除左欄客戶分類
			setSelectedCustomerClassList(
				selectedCustomerClassList.filter((el) => {
					return (
						el.customerClassGuid !==
						dialogCustomerClassDeleteData.customerClassGuid
					);
				})
			);

			// 刪除價格條件
			const newData = { ...pageData };

			const newPriceDetailsList = newData.priceDetailsList.map((el) => {
				if (
					el.customerClassGuid ===
					dialogCustomerClassDeleteData.customerClassGuid
				) {
					if (
						el.action === 'native' ||
						el.action === 'update' ||
						el.action === 'delete'
					) {
						return { ...el, action: 'delete' };
					} else return el;
				} else {
					return el;
				}
			});

			// 將 'insert' 的元素從陣列中刪除
			const filteredPriceDetailsList = newPriceDetailsList.filter(
				(el) => el.action !== 'insert'
			);

			console.log(filteredPriceDetailsList);

			setPageData({
				...newData,
				priceDetailsList: [...filteredPriceDetailsList],
			});

			setIsCustomerClassDeleteClicked(false);
		}
	}, [isConfirmBtnClicked, isCustomerClassDeleteClicked]);

	useEffect(() => {
		let newPageData = { ...pageData };

		console.log(specList);
		if (
			specList.length !== 0 &&
			specList?.lgsSalesKitSize?.length !== 0 &&
			specList?.lgsSalesKitColor?.length !== 0
		) {
			setSpecSelectedList([
				specList.lgsSalesKitSize[0].sizeGuid,
				specList.lgsSalesKitColor[0].colorGuid,
			]);

			setPageData({
				...newPageData,
				goodsItemInfo: {
					...pageData.goodsItemInfo,
					sizeGuid: specList.lgsSalesKitSize[0].sizeGuid,
					colorGuid: specList.lgsSalesKitColor[0].colorGuid,
				},
			});
		}
	}, [specList, isDetailRoute, isEditRoute]); // 每次換頁要重置specSelectedList

	return (
		<div className="position-relative">
			{/* DialogCol element */}
			<DialogCol
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				modalData={modalData}
				setModalData={setModalData}
				btnKeyName={dialogBtnKeyName}
				pageData={pageData}
				setPageData={setPageData}
				tableBodyData={tableBodyData}
				setTableBodyData={setTableBodyData}
			/>
			<DialogListStatus
				isSpecDialogOpen={isSpecDialogOpen}
				setIsSpecDialogOpen={setIsSpecDialogOpen}
				authorized={authorized}
				authorizedGuidName={authorizedGuidName}
				handleLeftColBtnClick={handleLeftColBtnClick}
				middleIndexList={middleIndexList}
				setMiddleIndexList={setMiddleIndexList}
				middleOriginIndexList={middleOriginIndexList}
				langList={langList}
				getSpecList={getSpecList}
				salesKitGuid={salesKitGuid}
			/>
			<DialogCustomerClass
				isDialogCustomerClassOpen={isDialogCustomerClassOpen}
				setIsDialogCustomerClassOpen={setIsDialogCustomerClassOpen}
				modalData={modalData}
				setModalData={setModalData}
				btnKeyName={dialogBtnKeyName}
				pageData={pageData}
				setPageData={setPageData}
				tableBodyData={tableBodyData}
				setTableBodyData={setTableBodyData}
				selectedCustomerClassList={selectedCustomerClassList}
				setSelectedCustomerClassList={setSelectedCustomerClassList}
			/>
			<DialogConfirm
				dialogConfirmData={dialogConfirmData}
				setDialogConfirmData={setDialogConfirmData}
				setIsConfirmBtnClicked={setIsConfirmBtnClicked}
			/>
			<DialogCopyConfirm
				dialogCopyConfirmData={dialogCopyConfirmData}
				setDialogCopyConfirmData={setDialogCopyConfirmData}
				setIsCopyDialogOpen={setIsCopyDialogOpen}
				setCopyFunctionName={setCopyFunctionName}
				handleFinalSubmit={handleFinalSubmit}
			/>

			{/* DialogTwoCol 複製 element */}
			<DialogTwoCol
				isCopyDialogOpen={isCopyDialogOpen}
				setIsCopyDialogOpen={setIsCopyDialogOpen}
				specList={specList}
				specSelectedList={specSelectedList}
				copyFunctionName={copyFunctionName}
				salesKitGuid={salesKitGuid}
				getDetails={getDetails}
			/>

			<DialogCustomerClassDeleteConfirm
				dialogCustomerClassDeleteData={dialogCustomerClassDeleteData}
				setDialogCustomerClassDeleteData={
					setDialogCustomerClassDeleteData
				}
				setIsCustomerClassDeleteClicked={
					setIsCustomerClassDeleteClicked
				}
			/>
			<div className="specArea">
				<div className="d-flex mb-3">
					<BtnNormal2
						className="smallBtn"
						keyName="advance-search"
						elementStyle={{
							border: 'none',
							width: '120px',
						}}
						handleClick={() => {
							isDetailRoute
								? {}
								: handleOpenSpecDialog(
										'LgsSalesKitSize',
										'sizeGuid'
								  );
						}}
						word={
							isDetailRoute ? (
								<></>
							) : (
								<EditStyle>
									<EditIcon />
								</EditStyle>
							)
						}
					>
						<div className="me-3">
							{t('lgsSalesKit:salesSpecification')}
						</div>
						{/* 銷售規格 */}
					</BtnNormal2>
					<div className="text-danger ms-1 my-1">
						{pageData.goodsItemInfo.sizeGuidWarning}
					</div>

					{specList.lgsSalesKitSize?.map((el) => {
						return (
							<BtnNormal2
								className={`${
									specSelectedList.includes(el.sizeGuid)
										? 'btn-primary'
										: 'btn-outline-primary'
								} smallBtn me-3`}
								key={el.sizeGuid}
								keyName={el.sizeGuid}
								elementStyle={{
									borderColor: 'var(--bs-primarylt)',
									width: '120px',
								}}
								handleClick={() => {
									isDetailRoute
										? handleSpecSelected(
												el.sizeGuid,
												'lgsSalesKitSize'
										  )
										: setDialogConfirmData({
												isOpen: true,
												title: t(
													'lgsSalesKit:switchSpecificationOrColorWillDeleteNewInputData'
												),
												// 切換規格或顏色將會刪除新輸入的資料
												confirmInfo: {
													guid: el.sizeGuid,
													specName: 'lgsSalesKitSize',
												},
										  });
								}}
								word={el.name01}
							></BtnNormal2>
						);
					})}
				</div>

				<div className="d-flex">
					<BtnNormal2
						className="smallBtn"
						keyName="advance-search"
						elementStyle={{
							border: 'none',
							width: '120px',
						}}
						handleClick={() => {
							isDetailRoute
								? {}
								: handleOpenSpecDialog(
										'LgsSalesKitColor',
										'colorGuid'
								  );
						}}
						word={
							isDetailRoute ? (
								<></>
							) : (
								<EditStyle>
									<EditIcon />
								</EditStyle>
							)
						}
					>
						<div className="ms-4 me-3">
							{t('lgsSalesKit:color')}
						</div>
						{/* 顏色 */}
					</BtnNormal2>
					<div className="text-danger ms-1 my-1">
						{pageData.goodsItemInfo.colorGuidWarning}
					</div>

					{specList.lgsSalesKitColor?.map((el) => {
						return (
							<BtnNormal2
								className={`${
									specSelectedList.includes(el.colorGuid)
										? 'btn-primary'
										: 'btn-outline-primary'
								} smallBtn me-3`}
								key={el.colorGuid}
								keyName={el.colorGuid}
								elementStyle={{
									borderColor: 'var(--bs-primarylt)',
									width: '120px',
								}}
								handleClick={() => {
									isDetailRoute
										? handleSpecSelected(
												el.colorGuid,
												'lgsSalesKitColor'
										  )
										: setDialogConfirmData({
												isOpen: true,
												title: t(
													'lgsSalesKit:switchSpecificationOrColorWillDeleteNewInputData'
												),
												// 切換規格或顏色將會刪除新輸入的資料
												confirmInfo: {
													guid: el.colorGuid,
													specName:
														'lgsSalesKitColor',
												},
										  });
								}}
								word={el.name01}
							></BtnNormal2>
						);
					})}
				</div>
			</div>
			<div className="border-bottom border-grey1 w-100 my-4"></div>
			{specList.length !== 0 &&
				specList?.lgsSalesKitSize?.length !== 0 &&
				specList?.lgsSalesKitColor?.length !== 0 && (
					<>
						{/* 照片 */}
						<div className="imgArea">
							<div className="d-flex">
								<button
									className="border-0 bg-transparent fw-bold fontSize125Rem"
									onClick={() =>
										setItemDisplayList({
											...itemDisplayList,
											imgArea: !itemDisplayList.imgArea,
										})
									}
								>
									<span className="me-3">
										{t('lgsSalesKit:photo')}
									</span>
									{/* 照片 */}
									<Icn_chevronTop
										className="iconChevron"
										style={{
											transform: itemDisplayList.imgArea
												? ''
												: 'rotate(.5turn)',
										}}
									/>
								</button>
								{isEditRoute && (
									<BtnNormal2
										className={`btn-warning smallBtn ms-3`}
										key={'salesDetailCopyBtn'}
										keyName={'salesDetailCopyBtn'}
										elementStyle={{
											width: '56px',
										}}
										handleClick={() => {
											isDetailRoute
												? {}
												: setDialogCopyConfirmData({
														isOpen: true,
														title: t(
															'lgsSalesKit:beforeSelectingCopyData'
														),
														// 選擇複製資料前
												  });
											setCopyFunctionName('copyAppendix');
										}}
										word={t('lgsSalesKit:copy')}
										// 複製
									></BtnNormal2>
								)}
							</div>

							{itemDisplayList.imgArea && (
								<Attachment
									// view="create"
									data={pageData}
									setData={setPageData}
									isDetailRoute={isDetailRoute}
								/>
							)}
						</div>

						<div className="border-bottom border-grey1 w-100 my-4"></div>

						{/* 銷售明細 */}
						<div className="salesDetailArea">
							<div className="d-flex">
								<button
									className="border-0 bg-transparent fw-bold fontSize125Rem"
									onClick={() =>
										setItemDisplayList({
											...itemDisplayList,
											salesDetailArea:
												!itemDisplayList.salesDetailArea,
										})
									}
								>
									<span className="me-3">
										{t('lgsSalesKit:salesDetails')}
									</span>
									{/* 銷售明細 */}
									<Icn_chevronTop
										className="iconChevron"
										style={{
											transform:
												itemDisplayList.salesDetailArea
													? ''
													: 'rotate(.5turn)',
										}}
									/>
								</button>
								{isEditRoute && (
									<BtnNormal2
										className={`btn-warning smallBtn ms-3`}
										key={'salesDetailCopyBtn'}
										keyName={'salesDetailCopyBtn'}
										elementStyle={{
											width: '56px',
										}}
										handleClick={() => {
											isDetailRoute
												? {}
												: setDialogCopyConfirmData({
														isOpen: true,
														title: t(
															'lgsSalesKit:beforeSelectingCopyData'
														),
														// 選擇複製資料前
												  });
											setCopyFunctionName('copyItem');
										}}
										word={t('lgsSalesKit:copy')}
										// 複製
									></BtnNormal2>
								)}
							</div>

							{itemDisplayList.salesDetailArea && (
								<SalesDetails
									// view="create"
									data={pageData}
									setData={setPageData}
									error={error}
									baseInfoList={baseInfoList}
									isDetailRoute={isDetailRoute}
									isEditRoute={isEditRoute}
								/>
							)}
						</div>

						<div className="border-bottom border-grey1 w-100 my-4"></div>

						{/* 價格 */}
						<div className="priceArea">
							<div className="d-flex">
								<button
									className="border-0 bg-transparent fw-bold fontSize125Rem"
									onClick={() =>
										setItemDisplayList({
											...itemDisplayList,
											priceArea:
												!itemDisplayList.priceArea,
										})
									}
								>
									<span className="me-3">
										{t('lgsSalesKit:price')}
									</span>
									{/* 價格 */}
									<Icn_chevronTop
										className="iconChevron"
										style={{
											transform: itemDisplayList.priceArea
												? ''
												: 'rotate(.5turn)',
										}}
									/>
								</button>
								{isEditRoute && (
									<BtnNormal2
										className={`btn-warning smallBtn ms-3`}
										key={'salesDetailCopyBtn'}
										keyName={'salesDetailCopyBtn'}
										elementStyle={{
											width: '56px',
										}}
										handleClick={() => {
											isDetailRoute
												? {}
												: setDialogCopyConfirmData({
														isOpen: true,
														title: t(
															'lgsSalesKit:beforeSelectingCopyData'
														),
														// 選擇複製資料前
												  });
											setCopyFunctionName('copyPrice');
										}}
										word={t('lgsSalesKit:copy')}
										// 複製
									></BtnNormal2>
								)}
							</div>
							{itemDisplayList.priceArea && (
								<div className="d-flex">
									<section className="customerClassArea col-2 ps-0 pe-1">
										<div className="d-flex justify-content-between my-2">
											<div
												className={`d-flex align-items-end ${
													isDetailRoute && 'mt-2'
												}`}
												style={{
													fontSize: '16px',
													fontWeight: 'bold',
													marginLeft: '8px',
												}}
											>
												{t('lgsSalesKit:customer')}
											</div>
											{/* 客戶 */}
											{!isDetailRoute && (
												<BtnCreate
													className={`mt-1 me-1 border-0 bg-primary`}
													onClick={() =>
														handleOpenDialogCustomerClass(
															'customerClass',
															'customerClassList'
														)
													}
												>
													<CreateIcon />
												</BtnCreate>
											)}
										</div>
										<div className="">
											<div
												className="authTitleWrapper d-flex justify-content-center"
												style={{
													borderRadius:
														'.75rem .75rem 0 0',
													backgroundColor:
														'var(--grey1)',
												}}
											>
												<h5
													className="fontSize875Rem fw-bold text-left text-secondary m-0 "
													style={{
														lineHeight: '40px',
													}}
												>
													{t('lgsSalesKit:category')}
												</h5>
												{/* 分類 */}
											</div>

											<CustomerClassList className="list-group  menubar-list">
												{/* 適用全部客戶 (客戶分類==="") */}
												<div
													className="list-group-item list-group-item-action py-2 border-0"
													onClick={() =>
														handleCustomerClassBtnClick(
															''
														)
													}
													style={{
														cursor: 'pointer',
														minHeight: '40px',
														backgroundColor:
															customerClassBtnActive ===
															''
																? 'var(--grey1)'
																: 'transparent',
													}}
												>
													<div className="d-flex w-100 justify-content-between">
														<span>
															{t(
																'lgsSalesKit:applyToAllCustomers'
															)}
														</span>
														{/* 適用全部客戶 */}
													</div>
												</div>

												{/* 客戶分類 */}
												{selectedCustomerClassList.map(
													(el) => {
														return (
															<div
																key={
																	el.customerClassGuid
																}
																className="list-group-item list-group-item-action py-2 border-0"
																onClick={() =>
																	handleCustomerClassBtnClick(
																		el.customerClassGuid
																	)
																}
																style={{
																	cursor: 'pointer',
																	minHeight:
																		'40px',
																	backgroundColor:
																		customerClassBtnActive ===
																		el.customerClassGuid
																			? 'var(--grey1)'
																			: 'transparent',
																}}
															>
																<div className="d-flex w-100 justify-content-between">
																	<span>
																		{
																			el.name01
																		}
																	</span>

																	{isEditRoute &&
																		customerClassBtnActive ===
																			el.customerClassGuid && (
																			<span
																				className="ps-2"
																				onClick={() =>
																					setDialogCustomerClassDeleteData(
																						{
																							isOpen: true,
																							title: t(
																								'lgsSalesKit:deleteCustomerCategory'
																							), // 刪除客戶分類
																							customerClassGuid:
																								el.customerClassGuid,
																						}
																					)
																				}
																			>
																				<Icn_deleted />
																			</span>
																		)}
																</div>
															</div>
														);
													}
												)}
											</CustomerClassList>
										</div>
									</section>
									<section className=" priceDetailsArea col-10 ps-1 pe-0">
										<PriceDetails
											// view="create"
											data={pageData}
											setData={setPageData}
											error={error}
											baseInfoList={baseInfoList}
											customerClassActiveGuid={
												customerClassBtnActive
											}
											isDetailRoute={isDetailRoute}
											isEditRoute={isEditRoute}
										/>
									</section>
								</div>
							)}
						</div>
					</>
				)}
		</div>
	);
}

export default BottomInfo;
