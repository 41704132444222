//import React from 'react';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BtnNormal2 } from "../../../components/Buttons/FunctionRenderButtons/UniversalBTN";
import FormControlInput from "../../../components/Inputs/FormControlInput";
import { PageTitleBar } from "../../../components/exports";
//import { StepByStepBar } from '../../../components/StepByStepBar/StepByStepBar';
//import { StepByStepBar } from '../../../../components/StepByStepBar/StepByStepBar';
//import { NextBtnSmall } from '../../../../components/Buttons/NextBtnSmall';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ErrMsg } from "../../../components/FormElement/shared/ErrMsg";
import { DialogConfirm } from "./DialogConfirm";
import {
  convertFromNumberingRuleToRuleForUser,
  getRulePreview,
} from "./NumberingRule";
import NumberingRuleDialog from "./NumberingRuleDialog";
import { SubmitConfirm } from "./SubmitConfirm";

// #region styled-components
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const Left = styled.div`
  display: flex;
`;

const Center = styled.div`
  display: flex;
`;

const Right = styled.div`
  display: flex;
`;

const BtnNormal = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 32px;
  margin-right: 8px;
  svg {
    margin-right: 8px;
  }
`;

const Wrapper = styled.div`
  min-height: 720px;
`;

const TextareaElement = styled.textarea`
  border: 1px solid var(--grey2);
  border-radius: 4px;
  padding: 8px;
  min-height: 88px;
  resize: none;
  &:focus-visible {
    outline: 0;
  }
`;

const Textarea = styled.div`
  border: 0px solid var(--grey2);
  border-radius: 4px;
  padding: 8px;
  min-height: 88px;
  resize: none;
  &:focus-visible {
    outline: 0;
  }
  background-color: var(--primaryllt);
  /* width: 600px;
    height: 160px; */
`;

const NumberInputElement = styled.input`
  border: 1px solid var(--grey2);
  border-radius: 4px;
  padding: 6px 8px;
  height: 32px;
  &:focus-visible {
    outline: 0;
  }

  -webkit-inner-spin-button,
  -webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover::-webkit-inner-spin-button,
  &:hover::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    opacity: 0;
  }

  &:focus::-webkit-inner-spin-button,
  &:focus::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    opacity: 0;
  }
`;
const InputElement = styled.input`
  font-size: 14px;
  border: 1px solid var(--grey2);
  border-radius: 4px;
  padding: 6px 8px;
  height: 32px;
  &:focus-visible {
    outline: 0;
  }
`;

const SelectElement = styled.select`
  font-size: 14px;
  border: 1px solid var(--grey2);
  border-radius: 4px;
  padding: 6px 8px;

  height: 32px;
  &:focus-visible {
    outline: 0;
  }

  /* 下拉箭頭圖案 */
  background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
    linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
  background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
  background-size: 6px 6px, 6px 6px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
`;

const LabelNormal = styled.label`
  text-align: left;
  font-size: 12px;
  color: var(--grey4);
  margin-bottom: 6px;
`;

const NoInfoRow = styled.div`
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	margin-top: 16px;
	border-bottom: 1px solid var(--grey2);
	mb-3
`;

const DialogBtn = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--grey2);
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding-left: 16px;
  padding-right: 12px;
  cursor: pointer;
  height: 32px;
`; // dialog按鈕

const CancelInputBtn = styled.span`
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 3px;
  right: 32px;
  width: 24px;
  text-align: center;
  display: ${(props) => (props.isValueExist ? "inline" : "none")};
  cursor: pointer;
`; // 取消按鈕
//#endregion

const LgsSalesOrderType_Create = () => {
  const { t } = useTranslation();
  const pageTitle = t("lgsSalesOrderType:pageTitle");
  const typeGuid = useLocation().pathname.split("/").pop();
  const [modalOpen, setModalOpen] = useState(false);
  const [purchaseOrderNoRuleList, setPurchaseOrderNoRuleList] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState({
    // numbering rule 組成參數
    typeCode: "",
    name01: "",
    name02: "",
    name03: "",
    noRuleGuid: "",
    noRule: "",
    prefix: "",
    period: "",
    separator: "",
    suffix: "",
    length: "",
    startNo: "",
    incremental: "",
    length: "",
  });

  //需回傳給後端資料
  const [backData, setBackData] = useState({
    // numbering rule 組成參數
    typeCode: "",
    name01: "",
    name02: "",
    name03: "",
    noRuleGuid: "",
    status: "",
  });

  const [ruleForUser, setRuleForUser] = useState("");
  console.log("data：", data); //要用 ,不可以使用+

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
  const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
    useState(false);

  // const [err, setErr] = useState(false);
  const [error, setError] = useState({
    typeCode: false,
    name: false,

    rule: false,
  });

  useEffect(() => {
    axios.post(`api/Logistics/LgsSalesOrderType/getPageInfo`).then((res) => {
      console.log("後端資料：", res.data);
      setPurchaseOrderNoRuleList(res.data.result.salesOrderNoRuleList);
    });
  }, []);

  const [preview, setPreview] = useState([]);

  // console.log(categoryControlList);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleActivate = () => {
    axios
      .put(`api/Logistics/LgsSalesOrderType/activate`, {
        typeCode: data.typeCode,
        status: "1",
        rowStamp: data.rowStamp,
      })
      .then((res) => navigate(-1));
  };

  const handleInActivate = () => {
    axios
      .put(`api/Logistics/LgsSalesOrderType/activate`, {
        typeCode: data.typeCode,
        status: "2",
        rowStamp: data.rowStamp,
      })
      .then((res) => navigate(-1));
  };

  const handleDelete = () => {
    axios
      .put(`api/Logistics/LgsSalesOrderType/activate`, {
        typeCode: data.typeCode,
        status: "4",
        rowStamp: data.rowStamp,
      })
      .then((res) => navigate(-1));
  };

  const handleDialogConfirm = (
    dialogNoRule,
    dialogData,
    dialogStartNo,
    dialogIncrement
  ) => {
    console.log(dialogNoRule, dialogData, dialogStartNo, dialogIncrement);
    setData({
      ...data,
      ...dialogData,
      startNo: dialogStartNo,
      incremental: dialogIncrement,
    });

    setNoRule(dialogNoRule);
  };

  const handleSubmit = (status) => {
    console.log(data);

    const updateContent = t("log.create", {
      value: name,
    });

    console.log(updateContent);
    const { typeCode, name01, name02, name03, noRuleGuid } = data;
    axios
      .post(`api/Logistics/LgsSalesOrderType/insert`, {
        typeCode,
        name01,
        name02,
        name03,
        noRuleGuid,
        status,
        updateContent,
      })
      .then((res) => navigate(-1));
  };

  const checkInputs = async () => {
    // instance event -> dialog shows or not
    const errorList = {};

    // warnings show or not
    const newErrorState = { ...error };

    if (data?.typeCode) {
      errorList.typeCode = false;
      newErrorState.typeCode = false;
    } else {
      errorList.typeCode = true;
      newErrorState.typeCode = true;
      errorList.typeCodeExisted = false;
      newErrorState.typeCodeExisted = false;
    }

    if (data?.name01) {
      errorList.name = false;
      newErrorState.name = false;
    } else {
      errorList.name = true;
      newErrorState.name = true;
    }

    if (data?.noRule) {
      errorList.rule = false;
      newErrorState.rule = false;
    } else {
      errorList.rule = true;
      newErrorState.rule = true;
    }

    // const keys = Object.keys(error);
    console.log(errorList);

    // change UI (if errors occur)
    setError(newErrorState);

    // determine if dialog shows or not
    const isValid = Object.values(errorList).every((el) => el === false);

    console.log(isValid);

    return isValid && setIsSubmitConfirmDialogOpen(true);
  };

  console.log(error);

  const checkTypeCode = async (e) => {
    const newErrorState = { ...error };

    console.log(newErrorState);

    await axios
      .get(`api/Logistics/LgsSalesOrderType/codeVerify?code=${e.target.value}`)
      .then((res) => {
        console.log("check代碼", res.data);

        // 在請求成功時，根據 res.data.status 設置 newErrorState
        if (res.data.status === "1") {
          //   console.log("XXXx");
          newErrorState.typeCode = false;
          newErrorState.typeCodeExisted = false;
          setError(newErrorState);
        } else if (res.data.status === "0") {
          // 在請求失敗時，根據 res.data.status 設置 newErrorState
          newErrorState.typeCode = false;
          newErrorState.typeCodeExisted = true;
          setError(newErrorState);

          setData({
            ...data,
            typeCode: "",
          });
        }
      });
  };

  //   await axios
  //     .get(`api/Logistics/LgsSalesOrderType/codeVerify?code=${e.target.value}`)
  //     .then((res) => {
  //       console.log("check代碼", res.data);

  //       newErrorState.typeCode = false;
  //       newErrorState.typeCodeExisted = true;
  //       setError(newErrorState);
  //     })
  //     .catch((err) => {
  //       console.log(err);

  //       newErrorState.typeCode = false;
  //       newErrorState.typeCodeExisted = true;
  //       setError(newErrorState);

  //       setData({
  //         ...data,
  //         typeCode: "",
  //       });
  //     });
  // };

  return (
    <>
      <div id="detailsView">
        <PageTitleBar
          pageTitle={pageTitle}
          previousPageButton={true}
          backTo={-1}
        />

        {/* DialogConfirm element */}
        <DialogConfirm
          t={t}
          isOpen={isActivateDialogOpen}
          setIsOpen={setIsActivateDialogOpen}
          handleClick={() => handleActivate()}
          message={t("common:dialog.activeConfirm")}
        />
        <DialogConfirm
          t={t}
          isOpen={isInActivateDialogOpen}
          setIsOpen={setIsInActivateDialogOpen}
          handleClick={() => handleInActivate()}
          message={t("dialog.lockConfirm")}
        />
        <DialogConfirm
          t={t}
          isOpen={isDeleteDialogOpen}
          setIsOpen={setIsDeleteDialogOpen}
          handleClick={() => handleDelete()}
          message={t("dialog.deleteConfirm")}
        />
        <SubmitConfirm
          t={t}
          isOpen={isSubmitConfirmDialogOpen}
          setIsOpen={setIsSubmitConfirmDialogOpen}
          handleClickInactive={() => handleSubmit("0")}
          handleClickActive={() => handleSubmit("1")}
          message={t("dialog.saveConfirm")}
        />
        <Container className="">
          <Left className=""></Left>

          <Center className=""></Center>

          <Right className="">
            <BtnNormal2
              className="btn-primary mediumBtn"
              handleClick={() => checkInputs()}
            >
              <span>{t("button.saveButton")}</span>
            </BtnNormal2>
            <BtnNormal2
              className="btn-outline-primary mediumBtn"
              handleClick={() => navigate(-1)}
            >
              <span>{t("button.cancelButton")}</span>
            </BtnNormal2>
          </Right>
        </Container>

        <div className="position-relative">
          <Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-4 px-4 pr-xl-6">
                  <div className={`wrapper d-flex flex-column mb-3`}>
                    <LabelNormal htmlFor={"vendorSales"}>
                      {t("lgsSalesOrderType:typeCode")}
                    </LabelNormal>
                    <InputElement
                      type={"text"}
                      id={"typeCode"}
                      value={data.typeCode.slice(0, 15)}
                      onChange={(e) => {
                        const value = e.target.value.replace(
                          /[^a-zA-Z0-9]/g,
                          ""
                        );
                        setData({
                          ...data,
                          typeCode: e.target.value.slice(0, 15).toUpperCase(),
                        });
                      }}
                      onBlur={checkTypeCode}
                      disabled={false}
                      disabledCssType={0}
                    />
                    <ErrMsg
                      className={`text-danger mt-1 ${
                        error?.typeCode ? "d-block" : "d-none"
                      }`}
                    >
                      {t("errMsg.mustFill")}
                    </ErrMsg>

                    <ErrMsg
                      className={`text-danger mt-1 ${
                        error?.typeCodeExisted ? "d-block" : "d-none"
                      }`}
                    >
                      此代碼已存在請重新輸入
                    </ErrMsg>
                  </div>
                </div>
                <div className="col-lg-4 px-4 py-1 "></div>

                <div className="col-lg-4 px-4 py-1 "></div>
              </div>

              <div className="row">
                {/* #Col1---------------------  */}
                <div className="col-lg-4 px-4 py-1 ">
                  <div className={`wrapper d-flex flex-column mb-3`}>
                    <LabelNormal htmlFor={"vendorSales"}>
                      {t("lgsSalesOrderType:name01")}
                    </LabelNormal>
                    <InputElement
                      type={"text"}
                      id={"name01"}
                      value={data.name01}
                      onChange={(e) => {
                        setData({
                          ...data,
                          name01: e.target.value,
                        });
                      }}
                      disabled={false}
                      disabledCssType={0}
                    />
                    <ErrMsg
                      className={`text-danger mt-1 ${
                        error?.name ? "d-block" : "d-none"
                      }`}
                    >
                      {t("errMsg.mustFill")}
                    </ErrMsg>
                  </div>
                </div>
                {/* #endCol1---------------------  */}
                {/* #Col2---------------------  */}
                <div className="col-lg-4 px-4 py-1 ">
                  <FormControlInput
                    id="name"
                    labelText={t("lgsSalesOrderType:name02")}
                    value={data.name02}
                    onChange={(e) => {
                      setData({
                        ...data,
                        name02: e.target.value,
                      });
                    }}
                    disabled={false}
                    disabledCssType={0}
                    //isErr={err}
                    errMsg="此欄位為必填欄位"
                  />
                </div>

                {/* #endCol2---------------------  */}

                {/* #Col3---------------------  */}
                <div className="col-lg-4 px-4 py-1 ">
                  <FormControlInput
                    id="name"
                    labelText={t("lgsSalesOrderType:name03")}
                    value={data.name03}
                    onChange={(e) => {
                      setData({
                        ...data,
                        name03: e.target.value,
                      });
                    }}
                    disabled={false}
                    disabledCssType={0}
                    //isErr={err}
                    errMsg={t("errMsg.mustFill")}
                  />
                </div>
                {/* #endCol3---------------------  */}
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-lg-4 px-4 py-1 ">
                    <div className="form-check">
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-xl-3">
                  <hr />
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-lg-4 px-4 py-1 ">
                    <div className={`wrapper d-flex flex-column mb-3`}>
                      <LabelNormal htmlFor={"orderTypeGuid"}>
                        {t("lgsSalesOrderType:noRuleName")}
                      </LabelNormal>
                      <SelectElement
                        id={"noRuleGuid"}
                        value={data.name}
                        onChange={(e) => {
                          const options = e.target.options; //(e) event的 target(這邊指的是select)

                          const content5 = options[options.selectedIndex].text; //這邊指的是選到第幾個的文字

                          const content2 = options[options.selectedIndex];

                          setData({
                            ...data,

                            noRule: content2.getAttribute("noRule"),
                            noRuleName: e.target.value,

                            noRuleGuid: content2.getAttribute("noRuleGuid"),

                            incremental: content2.getAttribute("incremental"),

                            startNo: content2.getAttribute("startNo"),
                          });

                          console.log(data);
                          console.log(
                            content2.getAttribute("noRule"),
                            content2.getAttribute("startNo")
                          );

                          const {
                            prefix,
                            suffix,
                            timeSeparator,
                            format,
                            content,
                            period,
                          } = convertFromNumberingRuleToRuleForUser({
                            ...data,

                            noRule: content2.getAttribute("noRule"),
                            noRuleName: e.target.value,

                            noRuleGuid: content2.getAttribute("noRuleGuid"),

                            incremental: content2.getAttribute("incremental"),

                            startNo: content2.getAttribute("startNo"),
                          });
                          const ruleForUser = `${prefix}${format}${timeSeparator}${content}${suffix}`;
                          setRuleForUser(ruleForUser);

                          console.log(
                            prefix,
                            suffix,
                            timeSeparator,
                            format,
                            content,
                            period
                          );

                          const result = getRulePreview({
                            ...data,
                            prefix: prefix.replace(new RegExp('"', "g"), ""),
                            suffix: suffix.replace(new RegExp('"', "g"), ""),
                            timeSeparator: timeSeparator,
                            format: format,
                            content: content,
                            length: data.startNo.length,
                            isPrefix: prefix ? true : false,
                            isSuffix: suffix ? true : false,
                            isPeriod: format ? true : false,
                            isTimeSeparator: timeSeparator ? true : false,
                            period: period,
                            rowStamp: data.rowStamp,
                            status: data.status,

                            startNo: content2.getAttribute("startNo"),
                            incremental: content2.getAttribute("incremental"),
                          });
                          setPreview(result);

                          // 前綴字230503-878787後綴字
                          // 前綴字230503-878792後綴字
                          // 前綴字230503-878797後綴字
                        }}
                      >
                        <option>--- Please select---</option>
                        {purchaseOrderNoRuleList.map((el) => (
                          <option
                            key={el.noRuleGuid}
                            id={el.noRuleGuid}
                            value={el.name}
                            onChange={() => {}}
                            startno={el.startNo}
                            incremental={el.incremental}
                            norule={el.noRule}
                            noruleguid={el.noRuleGuid}
                          >
                            {el.name}
                          </option>
                        ))}
                      </SelectElement>
                      <ErrMsg
                        className={`text-danger mt-1 ${
                          error?.rule ? "d-block" : "d-none"
                        }`}
                      >
                        {t("errMsg.mustFill")}
                      </ErrMsg>

                      <span className="text-danger ms-1 mt-1">
                        {data.orderTypeCodeWarning}
                      </span>
                    </div>
                    <div className="col-lg-4 px-4 py-1 "></div>

                    <div className="col-lg-4 px-4 py-1 "></div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-4 px-4 py-1 ">
                    <div className={`wrapper d-flex flex-column mb-3`}>
                      <LabelNormal htmlFor={"orderTypeGuid"}>
                        {t("lgsSalesOrderType:preview")}
                      </LabelNormal>
                      <Textarea disabled>
                        <div className="mb-1">{preview[0]}</div>
                        <div className="mb-1">{preview[1]}</div>
                        <div className="mb-1">{preview[2]}</div>
                      </Textarea>
                    </div>
                    <div className="col-lg-4 px-4 py-1 "></div>

                    <div className="col-lg-4 px-4 py-1 "></div>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
      <NumberingRuleDialog
        modalOpen={modalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
        data={data}
        setData={setData}
        preview={preview}
        setPreview={setPreview}
      />
    </>
  );
};

export default LgsSalesOrderType_Create;
