import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TopInfo from '../Component/TopInfo';
import PageTitleBar from '../../../../components/PageTitleBar/PageTitleBar';
import { BtnNormal } from '../../../../components/Buttons/BtnNormal';
import { CancelSubmitBtn } from '../../../../components/Buttons/CancelSubmitBtn';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

// #region styled-components

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function LgsSalesKit_Create() {
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const [isOpen, setIsOpen] = useState(false);
	const [pageData, setPageData] = useState({
		goodsItemInfo: {
			salesKitCode: '',
			name01: '',
			name02: '',
			name03: '',
			currencyGuid: '',
			currencyCode: '',
			listPrice: '',
			startDate: '',
			endDate: '',
			salesKitClassGuid: '',
			uomCode: '',
			note: '',
		},
		appendixList: [],
		salesDetailsList: [],
		priceDetailsList: [],
	});
	const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
	const [modalData, setModalData] = useState([]);
	const [cookies, setCookie] = useCookies();
	const cookieLangList = [
		cookies['_system_language01'],
		cookies['_system_language02'] ? cookies['_system_language02'] : '',
		cookies['_system_language03'] ? cookies['_system_language03'] : '',
	];
	const [langList, setLangList] = useState(cookieLangList);
	const defaultCurrencyCode = cookies['_company_currency_code']; // 公司幣別
	const [baseInfoList, setBaseInfoList] = useState({}); // 基礎的所有選單list
	console.log(baseInfoList);

	const pageTitle = t('lgsSalesKit:salesProductMasterFile'); //'銷售商品主檔';
	const navigate = useNavigate();

	// 上一頁
	const handleDialogConfirm = () => {
		navigate('/Logistics/LgsSalesKit');
	};

	// 基礎資料
	const getPageInfoBaseList = async () => {
		await axios
			.post(`api/Logistics/LgsSalesKit/getPageInfo`)
			.then((res) => {
				setBaseInfoList({ ...res.data.result });

				const currency = [...res.data.result.currencyList].find(
					(el) => el.currencyCode === defaultCurrencyCode
				);

				if (defaultCurrencyCode) {
					setPageData({
						...pageData,
						goodsItemInfo: {
							...pageData.goodsItemInfo,

							// 預設幣別
							currencyCode: defaultCurrencyCode,
							currencyGuid: currency.currencyGuid,
							currencyName: currency.name01,
						},
					});
				}
			});
	};

	// 新增並往下一步(此處為進到edit頁面)
	const handleSubmit = () => {
		let falseCount = 0;

		// 驗證欄位必填
		let newPageData = { ...pageData };

		const fillDataVerifyList = [
			'salesKitClassGuid',
			'name01',
			'uomCode',
			'currencyGuid',
			'listPrice',
			'startDate',
		];

		fillDataVerifyList.forEach((el) => {
			if (pageData.goodsItemInfo[el] === '') {
				newPageData.goodsItemInfo[el + 'Warning'] = t(
					'common:errMsg.mustFill'
				);
				falseCount++;
			}
		});

		setPageData({ ...newPageData });

		// 驗證商品代碼不重複
		if (pageData.goodsItemInfo.salesKitCodeWarning !== '') falseCount++;

		if (falseCount === 0) {
			const newData = {
				...pageData.goodsItemInfo,

				// log
				updateContent: t('log.create', {
					value: pageData.goodsItemInfo.name01,
				}),
			};
			console.log(newData);

			const formData = new FormData();
			Object.keys(newData).forEach((key) => {
				formData.append(`SalesKit.${key}`, newData[key]);
			});

			axios
				.post(`api/Logistics/LgsSalesKit/insert`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((res) => {
					console.log(res);
					navigate(`/Logistics/LgsSalesKit/Edit/${res.data}`); // 跳轉edit頁面
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	useEffect(() => {
		getPageInfoBaseList();
	}, []);

	return (
		<>
			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					backTo={-1}
					isDialogConfirm={true}
					handleDialogConfirm={handleDialogConfirm}
				/>

				<>
					<Container className="">
						<Left className=""></Left>
						<Center className=""></Center>
						<Right className="">
							<BtnNormal
								className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
								key={'saveAndNextBtn'}
								onClick={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								{t('lgsSalesKit:saveAndNext')}
								{/* 存檔 & 下一步 */}
							</BtnNormal>
							<CancelSubmitBtn
								className={'btn-outline-primary'}
							/>
						</Right>
					</Container>

					<div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
						<TopInfo
							pageData={pageData}
							setPageData={setPageData}
							dialogBtnKeyName={dialogBtnKeyName}
							setDialogBtnKeyName={setDialogBtnKeyName}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							modalData={modalData}
							setModalData={setModalData}
							baseInfoList={baseInfoList}
							langList={langList}
						/>
					</div>
				</>
			</div>
		</>
	);
}

export default LgsSalesKit_Create;
