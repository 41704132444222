//<!-- Plant Modal -->
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ReactComponent as Icn_InputYellowCancel } from '../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_Search2 } from '../../assets/images/icn_Search2.svg';

// #region styled-components

const ModalThead = styled.thead`
    //table row固定高
    padding: 0 0.75rem;
    cursor: pointer !important;
`;
const ModalTh = styled.th`
    //table row固定高
    padding: 0 0.75rem;
    cursor: pointer !important;
    border: none !important;
`;
const ModalLabel = styled.label`
    //table row固定高
    padding: 0 0.75rem;
    cursor: pointer !important;
`;
const ModalInput = styled.input`
    //table row固定高
    padding: 0 0.75rem;
    cursor: pointer !important;
`;

const SearchModalInput = styled.input`
    height: 35px;
    font-size: 14px;
    padding: 0 0.75rem;
    cursor: pointer !important;
    text-transform: uppercase;
    border-color: #e3e8ee;

    ::placeholder {
        color: #a1aab9;
        opacity: 1; /* Firefox */
    }
`;

const ModalSearchBtn = styled.button`
    top: 1px;
    right: 1px;
    height: 33px;
    border-radius: 0 0.25rem 0.25rem 0;
`;

const Ul = styled.ul`
    height: 400px;
    overflow-y: scroll;
    border-radius: 0 0 0.75rem 0.75rem;
    padding: 0;
`;

const Li = styled.li`
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: #616e83;

    &:hover {
        background-color: #f9fafd;
    }

    ${(props) => (props.active === true ? 'background-color: #f9fafd;' : '')};
`;
//#endregion

export default function UomModal(props) {
    const { t } = useTranslation(['common', 'lgsPurchaseOrder']);
    const { modalOpen, handleClose, uomCategoryList, uomList, data, setData } =
        props;

    const searchModalInput = useRef('');
    const [selectedCategoryCode, setSelectedCategoryCode] = useState('0');
    const [selectedUom, setSelectedUom] = useState(null);
    const [filteredUomList, setFilteredUomList] = useState(null);

    /**
     *搜尋Modal
     **/
    const searchModalBtn = () => {
        if (searchModalInput.current.value) {
            //取得搜尋文字
            let searchModalInputText =
                searchModalInput.current.value.toUpperCase();

            // 取消選定「計量單位型態」
            setSelectedCategoryCode(null);

            // filter 出所有付合條件的計量單位
            const filteredData = uomList.filter((el) => {
                return (
                    el.uomcode.toUpperCase().includes(searchModalInputText) ||
                    el.name01.toUpperCase().includes(searchModalInputText)
                );
            });

            setFilteredUomList(filteredData);
        }
    };

    /**
     *取消搜尋
     **/
    const cancelSearchModalBtn = (i) => {
        // 清空搜尋欄位
        searchModalInput.current.value = '';

        // 清空查詢結果
        setFilteredUomList(null);
        setSelectedCategoryCode('0');
    };

    const modalCancel = () => {
        handleClose();
        //setSelectedUom([]); //清空選取資料
    };

    const selectUomCategory = (categoryCode) => {
        setSelectedCategoryCode(categoryCode);
    };

    const selectUom = (uom) => {
        // console.log(uom);
        setSelectedUom(uom);
    };

    const handleSubmit = () => {
        const { uomguid, uomcode, name01 } = selectedUom;

        setData({
            ...data,
            uomGuid: uomguid,
            uomCode: uomcode,
            uomName: name01,
        });
    };

    useEffect(() => {
        const defaultUom = uomList.find((uom) => uom.uomcode === data.uomCode);
        const defaultUomCategory = defaultUom?.uomcategoryCode || '0';

        setSelectedUom(defaultUom);
        setSelectedCategoryCode(defaultUomCategory);
    }, [data.uomCode]);

    // console.log(filteredUomList);

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                //onHide={handleClose}
                centered={true}
                style={{ maxWidth: '624px' }}
            >
                <ModalHeader className="position-relative pt-4 pb-2 ps-4">
                    <p
                        style={{ fontSize: '20px' }}
                        className="text-primary fw-bold mb-1"
                    >
                        {t('uom.uomCode')}
                    </p>
                    <button
                        style={{ top: '12px', right: '12px' }}
                        type="button"
                        className="close position-absolute btn"
                        onClick={handleClose}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="icn_Clear"
                            viewBox="0 0 16 16"
                            width="16"
                            height="16"
                        >
                            <defs>
                                <clipPath id="clip-path-clear22">
                                    <rect
                                        id="Rectangle_3088"
                                        fill="#686868"
                                        width="10"
                                        height="10"
                                        data-name="Rectangle 3088"
                                    />
                                </clipPath>
                            </defs>
                            <rect
                                id="Rectangle_3086"
                                fill="none"
                                width="16"
                                height="16"
                                data-name="Rectangle 3086"
                            />
                            <g
                                id="Group_6190"
                                transform="translate(3 3)"
                                data-name="Group 6190"
                            >
                                <g
                                    id="Group_6188"
                                    clipPath='url("#clip-path")'
                                    data-name="Group 6188"
                                >
                                    <path
                                        id="Path_1410"
                                        fill="#686868"
                                        transform="translate(0)"
                                        d="M 1.134 0.188 a 0.669 0.669 0 0 0 -0.946 0.946 L 4.054 5 L 0.188 8.866 a 0.669 0.669 0 0 0 0.946 0.946 L 5 5.946 L 8.866 9.812 a 0.669 0.669 0 0 0 0.946 -0.946 L 5.946 5 L 9.812 1.134 A 0.669 0.669 0 0 0 8.866 0.188 L 5 4.054 Z"
                                        data-name="Path 1410"
                                    />
                                </g>
                            </g>
                        </svg>
                    </button>
                </ModalHeader>
                <ModalBody className="px-6 pt-6 pb-0">
                    <form asp-action="Index" method="get">
                        <div className="modal-body p-0">
                            <div className="wrap mx-3">
                                <div className="row">
                                    <div className="position-relative col-12 px-2 mb-3">
                                        <div className="position-relative w-50">
                                            <SearchModalInput
                                                type="text"
                                                name="searchModalInput"
                                                ref={searchModalInput}
                                                className="form-control searchModalInput"
                                                placeholder={t(
                                                    'lgsPurchaseOrder:dialogCol.quickSearch'
                                                )}
                                            />
                                            <ModalSearchBtn
                                                type="button"
                                                className="searchModalBtn position-absolute border-0 btn btn-primaryllt px-3 d-flex align-items-center"
                                                onClick={() => searchModalBtn()}
                                            >
                                                <Icn_Search2 />
                                            </ModalSearchBtn>
                                            {searchModalInput?.current
                                                ?.value && (
                                                <div
                                                    className="cancelSearchModalBtn position-absolute"
                                                    style={{
                                                        top: '5px',
                                                        right: '-22px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        cancelSearchModalBtn()
                                                    }
                                                >
                                                    <Icn_InputYellowCancel />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="col-6 px-2"
                                        style={{ height: '440px' }}
                                    >
                                        <div
                                            style={{
                                                height: '100%',
                                                border: '1px solid #EFF2F5',
                                                borderRadius:
                                                    '0.75rem 0.75rem 0 0',
                                            }}
                                        >
                                            <div
                                                className="px-2 d-flex justify-content-center align-items-center"
                                                style={{
                                                    borderRadius:
                                                        '0.75rem 0.75rem 0 0',
                                                    height: '40px',
                                                    color: '#0C2556',
                                                    backgroundColor: '#F8F9FB',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {t('uom.uomType')}
                                            </div>
                                            <Ul>
                                                {uomCategoryList?.map(
                                                    (category) => (
                                                        <Li
                                                            key={
                                                                category.categoryCode
                                                            }
                                                            onClick={() =>
                                                                selectUomCategory(
                                                                    category.categoryCode
                                                                )
                                                            }
                                                            active={
                                                                category.categoryCode ===
                                                                selectedCategoryCode
                                                            }
                                                        >
                                                            {category.nameZf}
                                                        </Li>
                                                    )
                                                )}
                                            </Ul>
                                        </div>
                                    </div>
                                    <div
                                        className="col-6 px-2"
                                        style={{ height: '442px' }}
                                    >
                                        <div
                                            style={{
                                                height: '100%',
                                                border: '1px solid #EFF2F5',
                                                borderRadius:
                                                    '0.75rem 0.75rem 0 0',
                                            }}
                                        >
                                            <div
                                                className="px-2 d-flex justify-content-center align-items-center"
                                                style={{
                                                    borderRadius:
                                                        '0.75rem 0.75rem 0 0',
                                                    height: '40px',
                                                    color: '#0C2556',
                                                    backgroundColor: '#F8F9FB',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {t('uom.uomName')}
                                            </div>
                                            <Ul
                                                style={{
                                                    height: '400px',
                                                    overflowY: 'scroll',
                                                    borderRadius:
                                                        '0 0 0.75rem 0.75rem',
                                                    scrollbarWidth: 'thin',
                                                    listStyleType: 'none',
                                                }}
                                            >
                                                {!filteredUomList
                                                    ? uomList
                                                          .filter(
                                                              (uom) =>
                                                                  uom.uomcategoryCode ===
                                                                  selectedCategoryCode
                                                          )
                                                          .map((uom, index) => (
                                                              <Li
                                                                  key={index}
                                                                  onClick={() =>
                                                                      selectUom(
                                                                          uom
                                                                      )
                                                                  }
                                                                  active={
                                                                      uom.uomguid ===
                                                                      selectedUom?.uomguid
                                                                  }
                                                              >
                                                                  {`${uom.uomcode} ${uom.name01}`}
                                                              </Li>
                                                          ))
                                                    : filteredUomList.map(
                                                          (uom, index) => (
                                                              <Li
                                                                  key={index}
                                                                  onClick={() =>
                                                                      selectUom(
                                                                          uom
                                                                      )
                                                                  }
                                                                  active={
                                                                      uom.uomguid ===
                                                                      selectedUom?.uomguid
                                                                  }
                                                              >
                                                                  {`${uom.uomcode} ${uom.name01}`}
                                                              </Li>
                                                          )
                                                      )}
                                            </Ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="border-grey1 mt-5 mb-0" />
                        <div className="modal-footer justify-content-center border-0 pt-4 pb-5">
                            <button
                                type="button"
                                className="btn btn-primary mediumBtn mx-1"
                                onClick={() => {
                                    handleSubmit();
                                    handleClose();
                                }}
                            >
                                {t('button.confirmButton')}
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-primarylllt mediumBtn mx-1"
                                onClick={() => {
                                    handleClose();
                                }}
                                // onClick="cancelTableModal(this, 'personnel')"
                            >
                                {t('button.cancelButton')}
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
}
