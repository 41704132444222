import { useState, useEffect } from 'react';
import styled from 'styled-components';
import FormControlInput from '../../../../../components/Inputs/FormControlInput';
import { ReactComponent as EditIcon } from '../../../../../assets/images/icn_Edit.svg';
import { useTranslation } from 'react-i18next';

// #region styled-components
const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;
const Wrapper = styled.div`
	border-radius: 0 0 0.75rem 0.75rem;
`;
const Textarea = styled.div`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
	background-color: #efefef4d;
`;

const NumberingRuleArea = styled.div`
	height: 610px;
	border-radius: 0;
	border: 1px solid var(--grey1);
`;

const BtnCreate = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	cursor: pointer;
`;
//#endregion

function DialogListStatus_RightCol_Details(props) {
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const { rightColInfo, setRightActive, langList } = props;

	const [data, setData] = useState({
		name01: '',
		name02: '',
		name03: '',
		displayOrder: '',
	});

	useEffect(() => {
		const { displayOrder, name01, name02, name03, status } = rightColInfo;

		// 存放 numbering rule(db)
		// setNoRule(noRule);

		if (Object.keys(rightColInfo).length !== 0) {
			// 把 numbering rule(db) 拆解

			setData({
				...data,
				displayOrder,
				name01,
				name02,
				name03,
				status,
			});
		}
	}, [rightColInfo]);

	return (
		<>
			<div className="h-100 bg-white position-relative">
				<div
					className={`authTitleWrapper d-flex justify-content-center ${
						rightColInfo.status === '0'
							? 'bg-grey1'
							: rightColInfo.status === '1'
							? 'bg-dangerlt'
							: ''
					}`}
					style={{
						borderRadius: '.75rem .75rem 0 0',
					}}
				>
					<h5
						className={`fw-bold text-left ${
							rightColInfo.status === '0'
								? 'text-black'
								: rightColInfo.status === '1'
								? 'text-danger'
								: ''
						} m-0`}
						style={{
							fontSize: '14px',
							lineHeight: '40px',
						}}
					>
						{rightColInfo.status === '0'
							? t('lgsSalesKit:sellable') //'可銷售'
							: rightColInfo.status === '1'
							? t('lgsSalesKit:notSellable') //'不可銷售'
							: ''}
					</h5>

					<BtnCreate
						className="position-absolute top-0 end-0 mt-1 me-2 border-0 bg-primary"
						onClick={() => setRightActive('edit')}
					>
						<EditIcon />
					</BtnCreate>
				</div>

				{/* DialogConfirm element */}
				<NumberingRuleArea>
					<div className="position-relative">
						<Wrapper className="bg-whit d-flex flex-column px-5 py-4 position-relative bg-white">
							<div className="col-12 mb-2">
								<FormControlInput
									id="displayOrder"
									labelText={t('lgsSalesKit:serialNumber')}
									value={data.displayOrder}
									onChange={(e) => {
										setData({
											...data,
											displayOrder: e.target.value,
										});
									}}
									disabled={true}
									disabledCssType={1}
								/>
							</div>
							<div className="col-12 mb-2">
								<FormControlInput
									id="name01"
									labelText={t('lgsSalesKit:name')}
									value={data.name01}
									onChange={(e) => {
										setData({
											...data,
											name01: e.target.value,
										});
									}}
									disabled={true}
									disabledCssType={1}
								/>
							</div>
							{langList[1] !== '' && (
								<div className="col-12 mb-2">
									<FormControlInput
										id="name02"
										labelText={`${t('lgsSalesKit:name')}( ${
											langList[1]
										} )`}
										value={data.name02}
										onChange={(e) => {
											setData({
												...data,
												name02: e.target.value,
											});
										}}
										disabled={true}
										disabledCssType={1}
									/>
								</div>
							)}
							{langList[2] !== '' && (
								<div className="col-12 mb-2">
									<FormControlInput
										id="name03"
										labelText={`${t('lgsSalesKit:name')}( ${
											langList[2]
										} )`}
										value={data.name03}
										onChange={(e) => {
											setData({
												...data,
												name03: e.target.value,
											});
										}}
										disabled={true}
										disabledCssType={1}
									/>
								</div>
							)}
						</Wrapper>
					</div>
				</NumberingRuleArea>
			</div>
		</>
	);
}

export default DialogListStatus_RightCol_Details;
