import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useAxios from '../../../../hooks/useAxios';
import { CopyCreateBtn } from '../../../../components/Buttons/CopyCreateBtn';
import { CancelSubmitBtn } from '../../../../components/Buttons/CancelSubmitBtn';
import { PageTitleBar } from '../exports';

import { DialogConfirm } from './Pages/DialogConfirm';
import Page0 from './Pages/Page0';
import Page1 from './Pages/Page1';
import Page2 from './Pages/Page2';
import Page3 from './Pages/Page3';
import Page4 from './Pages/page4/Page4';
import Page5 from './Pages/Page5';
import { useTranslation } from 'react-i18next';

// #region styled-components
const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕
// #endregion

function LgsPurchaseOrder_Edit() {
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);
	const [purchaseOrderData, setPurchaseOrderData] = useState({
		isEverEnterPage: {
			page0: false,
			page1: false,
			page2: false,
			page3: false,
		},
		topInfoData: {},
		page0: {},
		page1: [],
		page2: { paymentTermList: [], totals: {}, deletedTermList: [] },
		page3: {
			appendixInfo: [],
			appendixData: [],
			fileList: [],
			deleteList: [],
		},
		page4: {},
	});

	// const [purchaseOrderData, setPurchaseOrderData] = useState({
	// 	isEverEnterPage: {
	// 		page0: false,
	// 		page1: false,
	// 		page2: false,
	// 		page3: false,
	// 	},
	// 	topInfoData: {
	// 		foreignGrossAmount: 22.4285,
	// 		foreignNetAmount: 21.9885,
	// 		foreignTaxAmount: 0.44,
	// 		grossAmount: 672.856,
	// 		netAmount: 659.656,
	// 		taxAmount: 13.2,
	// 	},
	// 	page0: {
	// 		purchaseOrderGuid: '570CFF2F-154B-4D05-93B2-08DB2F2B8B61',
	// 		categoryControl: '0',
	// 		currencyCode: 'USD',
	// 		currencyName: '美元',
	// 		currencyShow: 'USD 美元',
	// 		decimalPlaceTl: 4,
	// 		exchangeRate: '30',
	// 		foreignCurrency: true,
	// 		inputTaxName: '發票稅別K',
	// 		inputTaxCode: 'KK',
	// 		inputTaxGuid: 'ded79049-b5a7-409d-06fd-08da122902e6',
	// 		inputTaxRate: 2.001,
	// 		note: 'note',
	// 		orderDate: '2023-03-24',
	// 		orderTypeCode: 'IRyS',
	// 		plantName: '瑪利亞之牆',
	// 		sectorName: '部門1',
	// 		shipToPlantGuid: 'e7fa9bc6-b36b-42ad-d6b7-08da33b37111',
	// 		shipToSectorGuid: 'ffb03c54-93eb-46df-15a1-08d9b578b862',
	// 		shipToStaffGuid: '12e45ba4-c275-4d49-dc2b-08d9dfb10871',
	// 		staffName: '員工01',
	// 		vendorCode: 'GONFREECSS',
	// 		vendorGuid: '56c1faf2-97d1-ec11-8c6c-00155d079200',
	// 		vendorName: '小傑·富力士',
	// 		vendorSales: 'aaa',
	// 		vendorSalesPhone: '0912356789',
	// 		rowStamp: 'AAAAACmE',
	// 	},
	// 	page1: [
	// 		{
	// 			aDataFromBackEnd: true,
	// 			action: 'update',
	// 			purchaseCategoryCode: 'M',
	// 			purchaseOrderItemGuid: '7d7881a6-07cd-ed11-a181-00155d070709',
	// 			cardChildName: '綠色油漆',
	// 			cardName: 'GREENPAINT',
	// 			deliverDate: '2023-04-01',
	// 			deliverDateWarning: '',
	// 			diffPrice: '916.65',
	// 			diffPriceWarning: '',
	// 			displayOrder: 1,
	// 			imageUrl: undefined,
	// 			materialGuid: 'fd571b61-e6ab-ec11-8c68-00155d079200',
	// 			materialMasterVendorPrice: 2,
	// 			materialMasterVendorPriceWarning: '',
	// 			name: 'GREENPAINT 綠色油漆',
	// 			netAmount: 650.656,
	// 			netAmountWarning: '',
	// 			netPrice: 20.333,
	// 			netPriceWarning: '',
	// 			note: 'note m',
	// 			noteWarning: '',
	// 			quantityPurchase: 32,
	// 			quantityPurchaseWarning: '',
	// 			quantityDelivered: 12,
	// 			receiveInspection: 1,
	// 			rowStamp: 'AAAAAAACMlA=',
	// 			taxAmount: 13.0196,
	// 			taxAmountWarning: '',
	// 			uomCode: 'GRM',
	// 			uomName01: '公克',
	// 		},
	// 		{
	// 			aDataFromBackEnd: true,
	// 			action: 'update',
	// 			assetsGuid: '3a4a5388-cc67-4e69-7dcc-08daa682a63e',
	// 			purchaseCategoryCode: 'A',
	// 			purchaseOrderItemGuid: '7e7881a6-07cd-ed11-a181-00155d070709',
	// 			cardChildName: '首次事後測試',
	// 			cardName: 'R2210#001',
	// 			deliverDate: '2023-03-30',
	// 			displayOrder: 2,
	// 			imageUrl: null,
	// 			name: 'R2210#001 首次事後測試',
	// 			netAmount: 9,
	// 			netAmountWarning: '',
	// 			netPrice: 3,
	// 			netPriceWarning: '',
	// 			note: 'note a',
	// 			noteWarning: '',
	// 			quantityPurchase: 3,
	// 			quantityPurchaseWarning: '',
	// 			quantityDelivered: 0,
	// 			rowStamp: 'AAAAAAACMlI=',
	// 			taxAmount: 0.1801,
	// 			taxAmountWarning: '',
	// 			uomCode: undefined,
	// 			uomName01: '公克',
	// 		},
	// 		{
	// 			aDataFromBackEnd: true,
	// 			accountGuid: '92c24800-e65e-eb11-8c61-00155d079200',
	// 			action: 'delete',
	// 			purchaseCategoryCode: 'E',
	// 			purchaseOrderItemGuid: '7c7881a6-07cd-ed11-a181-00155d070709',
	// 			cardChildName: 'ES2 部門1',
	// 			cardChildName2: '6120 交際費',
	// 			cardName: 'CAR GOCAR',
	// 			deliverDate: '2023-03-28',
	// 			deliverDateWarning: '',
	// 			displayOrder: 3,
	// 			expenseClassGuid: '83e2a37c-0105-4eba-ab58-08da4f42db5c',
	// 			imageUrl: undefined,
	// 			name: 'CAR GOCAR',
	// 			netAmount: 20.222,
	// 			netAmountWarning: '',
	// 			netPrice: 10.111,
	// 			netPriceWarning: '',
	// 			note: 'note e',
	// 			noteWarning: '',
	// 			quantityPurchase: 2,
	// 			quantityPurchaseWarning: '',
	// 			quantityDelivered: 0,
	// 			rowStamp: 'AAAAAAACMls=',
	// 			sectorClassGuid: '4f4eb599-33e2-4c4e-9972-637066b10745',
	// 			sectorGuid: 'ffb03c54-93eb-46df-15a1-08d9b578b862',
	// 			taxAmount: 0.4046,
	// 			taxAmountWarning: '',
	// 			uomCode: 'MTQ',
	// 			uomGuid: '5895c5b8-9413-4210-0a7d-08d9d99c10b4',
	// 			uomName01: '立方公尺',
	// 		},
	// 	],
	// 	page2: {
	// 		paymentTermList: [
	// 			{
	// 				foreignAmount: 3,
	// 				baseOn: '0',
	// 				days: 1,
	// 				displayOrder: 1,
	// 				note: 'q1',
	// 				payDate: '2023-03-28',
	// 				percentage: 30,
	// 			},
	// 			{
	// 				foreignAmount: 4,
	// 				baseOn: '2',
	// 				days: 2,
	// 				displayOrder: 2,
	// 				note: 'w1',
	// 				payDate: '2023-03-29',
	// 				percentage: 30,
	// 			},
	// 			{
	// 				active: true,
	// 				foreignAmount: 8780,
	// 				baseOn: '1',
	// 				days: 3,
	// 				displayOrder: 3,
	// 				note: 'e1',
	// 				payDate: '2023-03-30',
	// 				percentage: 40,
	// 			},
	// 		],
	// 		totals: {
	// 			totalAmount: 8787,
	// 			totalPercentage: 100,
	// 		},
	// 	},
	// 	page3: {
	// 		appendixInfo: [],
	// 		appendixData: [],
	// 		fileList: [],
	// 	},
	// 	page4: {},
	// });
	const [editInfo, setEditInfo] = useState({});
	const [page, setPage] = useState(0);
	const [view, setView] = useState('create');
	const pageTitle = `${t('lgsPurchaseOrder:purchaseOrder')} ${
		editInfo.purchaseOrder?.orderNo ? editInfo.purchaseOrder.orderNo : ''
	}`;
	const permission = localStorage.getItem('Permission_LgsPurchaseOrder');

	const guid = useLocation().pathname.split('/').pop();
	const { sendRequest: axiosEditData } = useAxios();
	useEffect(() => {
		const url = `api/Logistics/LgsPurchaseOrder/getEditDatapage0?guid=${guid}`;
		axiosEditData({ url }, (res) => {
			setEditInfo(res);
		});
	}, []);
	// console.log(editInfo);

	//#region page1
	const [isSelectingCurrentOrder, setIsSelectingCurrentOrder] =
		useState(false);
	const { isLoading, error, sendRequest: axiosIndexData } = useAxios();

	const [fetchedData, setFetchedData] = useState([]);
	const [displayColumns, setDisplayColumns] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const sortBy = 'orderDate';
	const guidName = 'purchaseOrderGuid';

	const processSteps = [
		t('lgsPurchaseOrder:basicInformation'),
		t('lgsPurchaseOrder:purchaseDetails'),
		t('lgsPurchaseOrder:paymentTerms'),
		t('lgsPurchaseOrder:attachments'),
		t('lgsPurchaseOrder:confirm'),
	];

	const headTitleList = [
		'#',
		t('lgsPurchaseOrder:vendorCode'),
		t('lgsPurchaseOrder:vendor'),
		t('lgsPurchaseOrder:orderDate'),
		t('lgsPurchaseOrder:orderNo'),
		t('lgsPurchaseOrder:currencyCode'),
		t('lgsPurchaseOrder:displayGrossAmount'),
		t('lgsPurchaseOrder:displayNetAmount'),
		t('lgsPurchaseOrder:displayTaxAmount'),
		t('lgsPurchaseOrder:grossAmount'),
		t('lgsPurchaseOrder:netAmount'),
		t('lgsPurchaseOrder:taxAmount'),
	];

	const bodyTitleList = [
		'displayOrder',
		'vendorCode',
		'vendor',
		'orderDate',
		'orderNo',
		'currencyCode',
		'displayNetAmount',
		'displayTaxAmount',
		'displayGrossAmount',
		'grossAmount',
		'netAmount',
		'taxAmount',
	];

	const tableStyles = [
		{
			minWidth: '54px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '240px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'left',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '72px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '112px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
	];

	const columnMustShow = ['displayOrder', 'orderDate', 'orderNo'];

	const fetchData = (pageSize = 2, sort = sortBy, pageNumber = 1) => {
		const pageString = pageNumber ? `&page=${pageNumber}` : '';
		const url = `api/Logistics/LgsPurchaseOrder/GetList?sort=${sort}&pagesize=${pageSize}${pageString}`;
		axiosIndexData({ url }, (res) => {
			console.log(res);
			const { indexData, permission, numberOfData, totalPage } = res;

			setPaginationData({
				...paginationData,
				numberOfData: numberOfData,
				totalPage: totalPage,
				pageNumber,
			});
			setFetchedData({
				indexData,
				permission,
				paginationData,
			});
		});
	};

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			return data;
		};

		return gettingData();
	}, []);

	const handleClick = (e) => {
		e.preventDefault();
		setIsSelectingCurrentOrder(true);
		fetchData();
	};

	useEffect(() => {
		// fetchIndexInfo();
		getLocalStorageData();

		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);
	//#endregion

	const handlePrevPage = () => {
		setPage(page - 1);
	};

	const handleNextPage = () => {
		setPage(page + 1);
	};

	/**
	 * DialogConfirm 返回btn確認dialog
	 **/
	const [isOpen, setIsOpen] = useState(false);

	// DialogConfirm Modal open
	const handleDialogConfirm = () => {
		setIsOpen(true);
	};

	return (
		<>
			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					backTo={-1}
					isDialogConfirm={true}
					handleDialogConfirm={handleDialogConfirm}
				/>

				{/* DialogConfirm element */}
				<DialogConfirm
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					backTo={-1}
				/>

				<div>
					{page === 0 && (
						<Page0
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							guidName={guidName}
							sortBy={sortBy}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableData={fetchedData?.indexData}
							indexData={fetchedData?.indexData}
							paginationData={paginationData}
							setPaginationData={setPaginationData}
							displayColumns={displayColumns}
							setDisplayColumns={setDisplayColumns}
							fetchData={fetchData}
							fetchedData={fetchedData}
							tableStyles={tableStyles}
							columnMustShow={columnMustShow}
							clickSelection={true}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
						/>
					)}
					{page === 1 && (
						<Page1
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
						/>
					)}
					{page === 2 && (
						<Page2
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableStyles={tableStyles}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
						/>
					)}
					{page === 3 && (
						<Page3
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableStyles={tableStyles}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
						/>
					)}
					{page === 4 && (
						<Page4
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
						/>
					)}
					{page === 5 && (
						<Page5
							purchaseOrderData={purchaseOrderData}
							setPurchaseOrderData={setPurchaseOrderData}
							setPage={setPage}
							page={page}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default LgsPurchaseOrder_Edit;
