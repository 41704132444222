import { useState, useEffect } from 'react';
import StandardCostTable from './StandardCostTable';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

function StandardCostTab({ parentData, tab4Data }) {
	//#region state initiation
	const { t } = useTranslation(['common', 'lgsPurchaseOrderGoodsInvoice']); // i18n

	const [tableBodyData, setTableBodyData] = useState([]);
	const [cookies] = useCookies();
	console.log('tab4Data', tab4Data);
	console.log('tableBodyData', tableBodyData);

	//#endregion

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsPurchaseOrderGoodsInvoice:purchaseCategoryCode'),
		t('lgsPurchaseOrderGoodsInvoice:purchaseContent'),
		t('lgsPurchaseOrderGoodsInvoice:quantityReceive'),
		t('lgsPurchaseOrderGoodsInvoice:uomName'),
		t('lgsPurchaseOrderGoodsInvoice:purchaseUnitPrice'),
		t('lgsPurchaseOrderGoodsInvoice:purchaseAmount'),
		t('lgsPurchaseOrderGoodsInvoice:standardUnitPrice'),
		t('lgsPurchaseOrderGoodsInvoice:standardTotalCost'),
		t('lgsPurchaseOrderGoodsInvoice:differenceUnitPrice'),
		t('lgsPurchaseOrderGoodsInvoice:differenceAmount'),
		t('lgsPurchaseOrderGoodsInvoice:diffPercent'),
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'purchaseCategoryCode',
		'purchaseContent',
		'quantityReceive',
		'uomName',
		'netPrice',
		'purchasePrice',
		'standardNetPrice',
		'standardPurchasePrice',
		'diffNetPrice',
		'diffPurchasePrice',
		'diffPercent',
	];

	// 不同 input 的 type
	const inputProperties = [{}, {}, {}, {}, {}, {}, {}];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '64px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '64px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '64px',
			textAlign: 'left',
		},
		{
			width: '240px',
			textAlign: 'left',
		},
		{
			width: '112px',
			textAlign: 'right',
		},
		{
			width: '64px',
			textAlign: 'left',
		},
		{
			width: '80px',
			textAlign: 'right',
		},
		{
			width: '112px',
			textAlign: 'right',
		},
		{
			width: '80px',
			textAlign: 'right',
		},
		{
			width: '112px',
			textAlign: 'right',
		},
		{
			width: '80px',
			textAlign: 'right',
		},
		{
			width: '112px',
			textAlign: 'right',
		},
		{
			width: '80px',
			textAlign: 'right',
		},
	];

	//#endregion

	useEffect(() => {
		// 使用篩選後的tab4Data
		setTableBodyData(tab4Data.purchaseOrderItemList);
	}, []);

	return (
		<div className="position-relative">
			<div className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
				<h6 className="fw-bold fontSize75Rem text-end mt-2 mb-3 me-3">
					{t('lgsPurchaseOrderGoodsInvoice:currency')}：
					{cookies['_company_currency_code'] +
						' ' +
						cookies['_company_currency_name']}
				</h6>

				<StandardCostTable
					tab4Data={tab4Data}
					parentData={parentData}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					tableBodyData={tableBodyData}
					inputProperties={inputProperties}
				/>
			</div>
		</div>
	);
}

export default StandardCostTab;
