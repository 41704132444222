import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PageTitleBar } from '../../../components/exports';
import { useLocation, useNavigate } from 'react-router-dom';
import { BtnNormal2 } from '../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import NumberingRuleDialog from './NumberingRuleDialog';
import { DialogConfirm } from './DialogConfirm';
import { SubmitConfirm } from './SubmitConfirm';
import { ErrMsg } from '../../../components/FormElement/shared/ErrMsg';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// #region styled-components
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

const BtnNormal = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: 32px;
	margin-right: 8px;
	svg {
		margin-right: 8px;
	}
`;

const Wrapper = styled.div`
	min-height: 720px;
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const Textarea = styled.div`
	border: 0px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
	background-color: var(--primaryllt);
	/* width: 600px;
    height: 160px; */
`;

const NumberInputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}

	-webkit-inner-spin-button,
	-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:hover::-webkit-inner-spin-button,
	&:hover::-webkit-outer-spin-button {
		-webkit-appearance: none !important;
		opacity: 0;
	}

	&:focus::-webkit-inner-spin-button,
	&:focus::-webkit-outer-spin-button {
		-webkit-appearance: none !important;
		opacity: 0;
	}
`;
const InputElement = styled.input`
	font-size: 14px;
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
`;

const SelectElement = styled.select`
	font-size: 14px;
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;

	height: 32px;
	&:focus-visible {
		outline: 0;
	}

	/* 下拉箭頭圖案 */
	background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
		linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
	background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
	background-size: 6px 6px, 6px 6px;
	background-repeat: no-repeat;
	-webkit-appearance: none;
`;

const LabelNormal = styled.label`
	text-align: left;
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const NoInfoRow = styled.div`
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	margin-top: 16px;
	border-bottom: 1px solid var(--grey2);
	mb-3
`;

const DialogBtn = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 12px;
	cursor: pointer;
	height: 32px;
`; // dialog按鈕

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕
//#endregion

const LgsAppendixClass_Create = () => {
	const { t } = useTranslation();
	const pageTitle = t('lgsAppendixClass:pageTitle');
	const appendixClassGuid = useLocation().pathname.split('/').pop();
	const [modalOpen, setModalOpen] = useState(false);

	const navigate = useNavigate();
	const [data, setData] = useState({
		appendixClassGuid: '',
		appendixClassCode: '',
		name01: '',
		name02: '',
		name03: '',
		purchaseOrder: '1',
		salesOrder: '2',
		status: '',
	});

	//需回傳給後端資料
	const [backData, setBackData] = useState({
		// numbering rule 組成參數
		appendixClassGuid: '',
		appendixClassCode: '',
		name01: '',
		name02: '',
		name03: '',
		purchaseOrder: '1',
		salesOrder: '1',
		status: '',
	});

	//console.log('data：', data); //要用 ,不可以使用+
	// console.log('backData', backData); //要用 ,不可以使用+

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
	const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
	const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
		useState(false);

	// const [err, setErr] = useState(false);
	const [error, setError] = useState({
		appendixClassCode: false,
		name01: false,
		// categoryControl: false,
		// rule: false,
	});

	// useEffect(() => {
	// 	axios.get(`api/Logistics/LgsAppendixClass/getData`).then((res) => {
	// 		console.log('後端資料：', res.data); //要用 ,不可以使用+
	// 		setPurchaseOrderNoRuleList(res.data.purchaseOrderNoRuleList);
	// 	});
	// }, []);

	const [preview, setPreview] = useState([]);

	const [selectedCategory, setSelectedCategory] = useState('');

	const handleActivate = () => {
		axios
			.put(`api/Logistics/LgsAppendixClass/activate`, {
				appendixClassCode: data.appendixClassCode,
				status: '1',
				rowStamp: data.rowStamp,
			})
			.then((res) => navigate(-1));
	};

	const handleInActivate = () => {
		axios
			.put(`api/Logistics/LgsAppendixClass/activate`, {
				appendixClassCode: data.appendixClassCode,
				status: '2',
				rowStamp: data.rowStamp,
			})
			.then((res) => navigate(-1));
	};

	const handleDelete = () => {
		axios
			.put(`api/Logistics/LgsAppendixClass/activate`, {
				appendixClassCode: data.appendixClassCode,
				status: '4',
				rowStamp: data.rowStamp,
			})
			.then((res) => navigate(-1));
	};

	// const handleDialogConfirm = (
	// 	dialogNoRule,
	// 	dialogData,
	// 	dialogStartNo,
	// 	dialogIncrement
	// ) => {
	// 	console.log(dialogNoRule, dialogData, dialogStartNo, dialogIncrement);
	// 	setData({
	// 		...data,
	// 		...dialogData,
	// 		startNo: dialogStartNo,
	// 		incremental: dialogIncrement,
	// 	});

	// 	setNoRule(dialogNoRule);
	// };

	const handleSubmit = (status) => {
		console.log(data);

		const updateContent = t('log.create', {
			value: name,
		});

		console.log(updateContent);
		const { appendixClassCode, name01, name02, name03 } = data;
		axios
			.post(`api/Logistics/LgsAppendixClass/insert`, {
				appendixClassCode,
				name01,
				name02,
				name03,
				purchaseOrder: true,
				salesOrder: true,
				status,
				updateContent,
			})
			.then((res) => navigate(-1));
	};

	const checkInputs = async () => {
		// instance event -> dialog shows or not
		const errorList = {};

		// warnings show or not
		const newErrorState = { ...error };

		if (data?.appendixClassCode) {
			errorList.appendixClassCode = false;
			newErrorState.appendixClassCode = false;
		} else {
			errorList.appendixClassCode = true;
			newErrorState.appendixClassCode = true;
			errorList.appendixClassCodeExisted = false;
			newErrorState.appendixClassCodeExisted = false;
		}

		if (data?.name01) {
			errorList.name = false;
			newErrorState.name = false;
		} else {
			errorList.name = true;
			newErrorState.name = true;
		}

		// const keys = Object.keys(error);
		//console.log(errorList);

		// change UI (if errors occur)
		setError(newErrorState);

		// determine if dialog shows or not
		const isValid = Object.values(errorList).every((el) => el === false);

		//console.log(isValid);

		return isValid && setIsSubmitConfirmDialogOpen(true);
	};

	//console.log(error);

	const checkAppendixClassCode = async (e) => {
		const newErrorState = { ...error };

		await axios
			.get(
				`api/Logistics/LgsAppendixClass/verifyCode?appendixClassCode=${e.target.value}`
			)
			.then((res) => {
				//console.log('check代碼', res.data);

				newErrorState.appendixClassCode = false;
				newErrorState.appendixClassCodeExisted = false;
				setError(newErrorState);
			})
			.catch((err) => {
				console.log(err);

				newErrorState.appendixClassCode = false;
				newErrorState.appendixClassCodeExisted = true;
				setError(newErrorState);

				setData({
					...data,
					appendixClassCode: '',
				});
			});
	};

	return (
		<>
			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					backTo={-1}
				/>

				{/* DialogConfirm element */}
				<DialogConfirm
					t={t}
					isOpen={isActivateDialogOpen}
					setIsOpen={setIsActivateDialogOpen}
					handleClick={() => handleActivate()}
					message={t('common:dialog.activeConfirm')}
				/>
				<DialogConfirm
					t={t}
					isOpen={isInActivateDialogOpen}
					setIsOpen={setIsInActivateDialogOpen}
					handleClick={() => handleInActivate()}
					message={t('dialog.lockConfirm')}
				/>
				<DialogConfirm
					t={t}
					isOpen={isDeleteDialogOpen}
					setIsOpen={setIsDeleteDialogOpen}
					handleClick={() => handleDelete()}
					message={t('dialog.deleteConfirm')}
				/>
				<SubmitConfirm
					t={t}
					isOpen={isSubmitConfirmDialogOpen}
					setIsOpen={setIsSubmitConfirmDialogOpen}
					handleClickInactive={() => handleSubmit('0')}
					handleClickActive={() => handleSubmit('1')}
					message={t('dialog.saveConfirm')}
				/>
				<Container className="">
					<Left className=""></Left>

					<Center className=""></Center>

					<Right className="">
						<BtnNormal2
							className="btn-primary mediumBtn"
							handleClick={() => checkInputs()}
						>
							<span>{t('button.saveButton')}</span>
						</BtnNormal2>
						<BtnNormal2
							className="btn-outline-primary mediumBtn"
							handleClick={() => navigate(-1)}
						>
							<span>{t('button.cancelButton')}</span>
						</BtnNormal2>
					</Right>
				</Container>

				<div className="position-relative">
					<Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
						<div className="col-12">
							<div className="row">
								<div className="col-lg-4 px-4 pr-xl-6">
									<div
										className={`wrapper d-flex flex-column mb-3`}
									>
										<LabelNormal htmlFor={'vendorSales'}>
											{t(
												'lgsAppendixClass:appendixClassCode'
											)}
										</LabelNormal>
										<InputElement
											type={'text'}
											id={'appendixClassCode'}
											value={data.appendixClassCode.slice(
												0,
												15
											)}
											onChange={(e) => {
												const value =
													e.target.value.replace(
														/[^a-zA-Z0-9]/g,
														''
													);
												setData({
													...data,
													appendixClassCode:
														e.target.value
															.slice(0, 15)
															.toUpperCase(),
												});
											}}
											onBlur={checkAppendixClassCode}
											disabled={false}
											disabledCssType={0}
										/>
										<ErrMsg
											className={`text-danger mt-1 ${
												error?.appendixClassCode
													? 'd-block'
													: 'd-none'
											}`}
										>
											{t('errMsg.mustFill')}
										</ErrMsg>

										<ErrMsg
											className={`text-danger mt-1 ${
												error?.appendixClassCodeExisted
													? 'd-block'
													: 'd-none'
											}`}
										>
											此代碼已存在請重新輸入
										</ErrMsg>
									</div>
								</div>
								<div className="col-lg-4 px-4 py-1 "></div>

								<div className="col-lg-4 px-4 py-1 "></div>
							</div>

							<div className="row">
								{/* #Col1---------------------  */}
								<div className="col-lg-4 px-4 py-1 ">
									<div
										className={`wrapper d-flex flex-column mb-3`}
									>
										<LabelNormal htmlFor={'vendorSales'}>
											{t('lgsAppendixClass:name01')}
										</LabelNormal>
										<InputElement
											type={'text'}
											id={'name01'}
											value={data.name01}
											onChange={(e) => {
												setData({
													...data,
													name01: e.target.value,
												});
											}}
											disabled={false}
											disabledCssType={0}
										/>
										<ErrMsg
											className={`text-danger mt-1 ${
												error?.name
													? 'd-block'
													: 'd-none'
											}`}
										>
											{t('errMsg.mustFill')}
										</ErrMsg>
									</div>
								</div>
								{/* #endCol1---------------------  */}
							</div>

							<div className="row">
								{/* #Name02---------------------  */}
								<div className="col-lg-4 px-4 py-1 ">
									<div
										className={`wrapper d-flex flex-column mb-3`}
									>
										<LabelNormal htmlFor={'vendorSales'}>
											{t('lgsAppendixClass:name02')}
										</LabelNormal>
										<InputElement
											type={'text'}
											id={'name02'}
											value={data.name02}
											onChange={(e) => {
												setData({
													...data,
													name02: e.target.value,
												});
											}}
											disabled={false}
											disabledCssType={0}
										/>
										{/* <ErrMsg
											className={`text-danger mt-1 ${
												error?.name
													? 'd-block'
													: 'd-none'
											}`}
										>
											{t('errMsg.mustFill')}
										</ErrMsg> */}
									</div>
								</div>
								{/* #endName02---------------------  */}
							</div>

							<div className="row">
								{/* #Name03---------------------  */}
								<div className="col-lg-4 px-4 py-1 ">
									<div
										className={`wrapper d-flex flex-column mb-3`}
									>
										<LabelNormal htmlFor={'vendorSales'}>
											{t('lgsAppendixClass:name03')}
										</LabelNormal>
										<InputElement
											type={'text'}
											id={'name03'}
											value={data.name03}
											onChange={(e) => {
												setData({
													...data,
													name03: e.target.value,
												});
											}}
											disabled={false}
											disabledCssType={0}
										/>
										{/* <ErrMsg
											className={`text-danger mt-1 ${
												error?.name
													? 'd-block'
													: 'd-none'
											}`}
										>
											{t('errMsg.mustFill')}
										</ErrMsg> */}
									</div>
								</div>
								{/* #endName03---------------------  */}
							</div>
						</div>
					</Wrapper>
				</div>
			</div>
		</>
	);
};

export default LgsAppendixClass_Create;
