import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAxios from '../../../../../../hooks/useAxios';
import GoodsHistoryTable from './GoodsHistoryTable';
import { useTranslation } from 'react-i18next';

function GoodsHistory() {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);

	// state
	const [fetchedData, setFetchedData] = useState([]);

	// variable
	const guidName = 'goodsGuid';
	const sortBy = 'displayOrder';
	const purchaseOrderGuid = useLocation().pathname.split('/').pop();

	// axios
	const { sendRequest: axiosPurchaseOrderGoods } = useAxios();

	// table title list
	const headTitleList = [
		'#',
		t('lgsPurchaseOrder:receiptNumber'),
		t('lgsPurchaseOrder:receivingDate'),
		t('lgsPurchaseOrder:receiver'),
		t('lgsPurchaseOrder:receivingAmount'),
		t('lgsPurchaseOrder:itemNumber'),
		t('lgsPurchaseOrder:purchaseContent'),
		t('lgsPurchaseOrder:receivedQuantity'),
		t('lgsPurchaseOrder:unit'),
		t('lgsPurchaseOrder:batchNumber'),
		t('lgsPurchaseOrder:annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'documentNo',
		'postingDate',
		'staff',
		'goodsReceiveAmount',
		'',
		'',
		'',
		'',
		'batchNo',
		'note',
	];

	//#endregion

	//#region useEffect

	useEffect(() => {
		axiosPurchaseOrderGoods(
			{
				url: `api/Logistics/LgsPurchaseOrder/purchaseOrderGoods?guid=${purchaseOrderGuid}`,
			},
			(res) => {
				setFetchedData(res?.data);
			}
		);
	}, []);

	//#endregion

	return (
		<div id="indexView">
			{fetchedData && (
				<GoodsHistoryTable
					guidName={guidName}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					indexData={fetchedData}
					sortBy={sortBy}
				/>
			)}

			{/* {fetchedData?.dataList && (
                <GoodsHistoryTable
                    guidName={guidName}
                    headTitleList={headTitleList}
                    bodyTitleList={bodyTitleList}
                    indexData={fetchedData.dataList}
                    sortBy={sortBy}
                />
            )} */}
		</div>
	);
}

export default GoodsHistory;
