import styled from 'styled-components';
import React, { useState } from 'react';
import { ReactComponent as GroupIcon } from '../../../assets/images/icn_Group.svg';

// #region styled-components

const BtnSmall = styled.button`
	height: 35px;
	width: 35px;
	padding-left: 0;
	padding-right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 8px;
`;

const ToggleListArea = styled.div`
	position: relative;
`;

const ToggleListBtnSmall = styled(BtnSmall)`
	position: relative;

	&:hover {
		svg path {
			fill: #ffffff;
		}
	}
`;

const DropdownMenu = styled.div`
	position: absolute;
	transform: translate3d(-93px, 35px, 0px);
	top: 0px;
	left: 0px;
	will-change: transform;
	padding: 0;
	margin-top: 8px;
	min-width: 136px;
	background-color: #ffffff;
	box-shadow: 2px 4px 12px -1px rgb(61 61 61 / 51%);
	border-radius: 0.25rem;
	color: var(--grey4);
`;

const DropdownItem = styled.div`
	display: flex;
	cursor: pointer;
	align-items: center;
	padding: 8px 16px;
	text-align: left;
	min-width: 136px;
	min-height: 32px;
	svg {
		margin-right: 8px;
	}

	&:hover,
	&:focus {
		color: var(--primaryhover);
		background-color: var(--primarylt);
	}
`;
//#endregion

export const DropdownMenuArea = ({ view, buttonList, children }) => {
	const [buttonListShow, setButtonListShow] = useState(false);

	//console.log(children);
	const items = React.Children.map(children, (child) => {
		//console.log(child);
		return <DropdownItem>{child}</DropdownItem>;
	});

	return (
		<ToggleListArea key={'ToggleListArea'}>
			<ToggleListBtnSmall
				className="btn btn-outline-primary"
				onClick={() => setButtonListShow(!buttonListShow)}
			>
				<GroupIcon />
			</ToggleListBtnSmall>
			<DropdownMenu style={{ zIndex: '2' }}>
				{buttonListShow && items}
			</DropdownMenu>
		</ToggleListArea>
	);
};
