import { useState, useEffect } from 'react';
import styled from 'styled-components';
import FormControlInput from '../../../../components/Inputs/FormControlInput';
import NumberingRuleDialog from '../dialog/NumberingRuleDialog';
import {
	convertFromNumberingRuleToRuleForUser,
	getRulePreview,
	periodFormatDict,
} from '../dialog/NumberingRule';
import { ReactComponent as EditIcon } from '../../../../assets/images/icn_Edit.svg';
import { useTranslation } from 'react-i18next';

// #region styled-components
const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;
const Wrapper = styled.div`
	border-radius: 0 0 0.75rem 0.75rem;
`;
const Textarea = styled.div`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
	background-color: #efefef4d;
`;

const NumberingRuleArea = styled.div`
	height: 630px;
	border-radius: 0;
	border: 1px solid var(--grey1);
`;

const BtnCreate = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	cursor: pointer;
`;
//#endregion

function LgsDocumentNoRule_Details(props) {
	const { t } = useTranslation(['common', 'lgsDocumentNoRule']);

	const { rightColInfo, setRightActive } = props;

	const [view, setView] = useState('details');
	const [modalOpen, setModalOpen] = useState(false);

	// numbering rule 的參數
	const [data, setData] = useState({
		// 非 numbering rule 組成參數
		noRuleGUid: '',
		status: '',
		rowStamp: '',

		// numbering rule 組成參數
		prefix: '',
		period: '',
		separator: '',
		suffix: '',
		length: '',
		startNo: '',
		incremental: '',
		length: 0,
	});

	const [dialogData, setDialogData] = useState({});

	// 真正的 numbering rule
	const [noRule, setNoRule] = useState('');
	// 給使用者看的 numbering rule
	const [ruleForUser, setRuleForUser] = useState('');
	// numbering rule 的預期結果示範
	const [preview, setPreview] = useState([]);

	const handleDialogConfirm = (
		dialogNoRule,
		dialogData,
		dialogStartNo,
		dialogIncrement
	) => {
		console.log(dialogNoRule, dialogData, dialogStartNo, dialogIncrement);
		setData({
			...data,
			...dialogData,
			startNo: dialogStartNo,
			incremental: dialogIncrement,
		});

		setNoRule(dialogNoRule);
	};

	const handleEditNoRule = () => {
		setRightActive('edit');
	};

	useEffect(() => {
		const {
			// displayOrder, // 沒有用到
			incremental,
			name,
			noRule,
			noRuleGuid,
			rowStamp,
			startNo,
			status,
		} = rightColInfo;

		// 存放 numbering rule(db)
		// setNoRule(noRule);

		if (Object.keys(rightColInfo).length !== 0) {
			// 把 numbering rule(db) 拆解
			const {
				prefix,
				suffix,
				timeSeparator,
				format,
				content,
				period,
				length,
			} = convertFromNumberingRuleToRuleForUser(noRule, startNo);

			console.log(
				prefix,
				suffix,
				timeSeparator,
				format,
				content,
				period,
				length
			);

			setData({
				...data,
				// 存放 numbering rule data
				noRuleGuid: noRuleGuid,
				status: status,
				rowStamp: rowStamp,
				startNo: startNo,
				name: name,
				incremental: incremental,
				// 把拆解後的參數, 更新到 data 裡
				prefix: prefix,
				suffix: suffix,
				timeSeparator: timeSeparator,
				period: period,
				length: length,
				format: format,
			});

			const noRuleForUser = `${prefix}${format}${timeSeparator}${content}${suffix}`;
			setRuleForUser(noRuleForUser);

			const rule = {
				prefix,
				timeSeparator,
				format: periodFormatDict[period],
				startNo: startNo,
				incremental: incremental,
				suffix,
			};

			// 產生 preview
			const preview = getRulePreview(rule);
			setPreview(preview);
		}
	}, [rightColInfo]);

	return (
		<>
			<div className="h-100 bg-white position-relative">
				<div
					className={`authTitleWrapper d-flex justify-content-center ${
						rightColInfo.status === '0'
							? 'bg-warninglt'
							: rightColInfo.status === '1'
							? 'bg-grey1'
							: rightColInfo.status === '2'
							? 'bg-dangerlt'
							: ''
					}`}
					style={{
						borderRadius: '.75rem .75rem 0 0',
					}}
				>
					<h5
						className={`fw-bold text-left ${
							rightColInfo.status === '0'
								? 'text-warning'
								: rightColInfo.status === '1'
								? 'text-black'
								: rightColInfo.status === '2'
								? 'text-danger'
								: ''
						} m-0`}
						style={{
							fontSize: '14px',
							lineHeight: '40px',
						}}
					>
						{rightColInfo.status === '0'
							? t('common:status.inactive')
							: rightColInfo.status === '1'
							? t('lgsDocumentNoRule:rule')
							: rightColInfo.status === '2'
							? t('common:status.lock')
							: ''}
					</h5>

					<BtnCreate
						className="position-absolute top-0 end-0 mt-1 me-2 border-0 bg-primary"
						onClick={() => handleEditNoRule()}
					>
						<EditIcon />
					</BtnCreate>
				</div>

				{/* DialogConfirm element */}
				<NumberingRuleArea>
					<div className="position-relative">
						<Wrapper className="bg-whit d-flex flex-column px-5 py-4 position-relative bg-white">
							<div className="col-12 mb-4">
								<FormControlInput
									id="name"
									labelText={t('lgsDocumentNoRule:name')}
									value={data.name}
									onChange={(e) => {
										setData({
											...data,
											name: e.target.value,
										});
									}}
									disabled={true}
									disabledCssType={1}
								/>
								<FormControlInput
									id="noRule"
									labelText={t('lgsDocumentNoRule:noRule')}
									value={ruleForUser}
									onChange={(e) => {
										setData({
											...data,
											name: e.target.value,
										});
									}}
									disabled={true}
									disabledCssType={1}
								>
									<div
										style={{
											width: '306px',
											height: '32px',
											position: 'absolute',
											// background: 'red',
											top: '24px',
											cursor: 'pointer',
										}}
										onClick={() => setModalOpen(true)}
									></div>
								</FormControlInput>

								<FormControlInput
									id="startNo"
									labelText={t('lgsDocumentNoRule:startNo')}
									value={data?.startNo}
									onChange={(e) => {
										setData({
											...data,
											startNo: e.target.value,
										});
									}}
									disabled={true}
									disabledCssType={1}
								/>
								<FormControlInput
									id="incremental"
									labelText={t(
										'lgsDocumentNoRule:incremental'
									)}
									value={data.incremental}
									onChange={(e) => {
										setData({
											...data,
											incremental: e.target.value,
										});
									}}
									disabled={true}
									disabledCssType={1}
								/>
								<LabelNormal>
									{t('lgsDocumentNoRule:preview')}
								</LabelNormal>
								<Textarea disabled>
									<div className="mb-1">{preview[0]}</div>
									<div className="mb-1">{preview[1]}</div>
									<div className="mb-1">{preview[2]}</div>
								</Textarea>
							</div>
						</Wrapper>
					</div>
				</NumberingRuleArea>
			</div>

			<NumberingRuleDialog
				modalOpen={modalOpen}
				handleClose={() => {
					setModalOpen(false);
				}}
				data={{
					...data,
					prefix: data.prefix?.replace(new RegExp('"', 'g'), ''),
					suffix: data.suffix?.replace(new RegExp('"', 'g'), ''),
				}}
				setData={setData}
				noRule={noRule}
				preview={preview}
				setPreview={setPreview}
				handleSubmit={handleDialogConfirm}
				view={view}
				t={t}
				dialogData={dialogData}
				setDialogData={setDialogData}
			/>
		</>
	);
}

export default LgsDocumentNoRule_Details;
