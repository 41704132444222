import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ActivateIcon } from '../../../../assets/images/Activate-icon.svg';
import { ReactComponent as LockIcon } from '../../../../assets/images/icn_Lock.svg';
import { ReactComponent as Icn_OpenModal } from '../../../../assets/images/icn_OpenModal.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icn_Trash.svg';
import { BtnNormal2 } from '../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import FormControlInput from '../../../../components/Inputs/FormControlInput';
import { generateUpdateLogContent } from '../../../../utils';
import { DialogConfirm } from '../dialog/DialogConfirm';
import {
    convertFromNumberingRuleToRuleForUser,
    getRulePreview,
    periodFormatDict,
} from '../dialog/NumberingRule';
import NumberingRuleDialog from '../dialog/NumberingRuleDialog';
import { SubmitConfirm } from '../dialog/SubmitConfirm';

// #region styled-components
const LabelNormal = styled.label`
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;
const InputElement = styled.input`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    background-color: ${(prop) => prop.isDisabled && '#efefef4d'};
`;

const DialogBtn = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0px;
    padding-left: 16px;
    padding-right: 12px;

    height: 32px;

    background-color: ${(prop) => prop.isDisabled && '#efefef4d'};
    cursor: ${(prop) => !prop.isDisabled && 'pointer;'};
`; // dialog按鈕
const Wrapper = styled.div`
    border-radius: 0 0 0.75rem 0.75rem;
`;
const Textarea = styled.div`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
    background-color: #efefef4d;
`;

const NumberingRuleArea = styled.div`
    height: 630px;
    border-radius: 0;
    border: 1px solid var(--grey1);
`;

const BtnDelete = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--danger);
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: var(--danger);
        svg g {
            path,
            rect {
                fill: white;
            }
        }
    }
`;
//#endregion

function LgsDocumentNoRule_Edit(props) {
    const { t } = useTranslation(['common', 'lgsDocumentNoRule']);

    const {
        leftActive,
        rightColInfo,
        handleLeftColBtnClick,
        handleCenterRowClick,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    // numbering rule 的參數
    const [data, setData] = useState({
        // 非 numbering rule 組成參數
        name: '',
        noRuleGuid: '',
        status: '',
        rowStamp: '',

        // numbering rule 組成參數
        prefix: '',
        period: '',
        separator: '',
        suffix: '',
        length: '',
        startNo: '',
        incremental: '',
        length: 0,
    });

    const [dialogData, setDialogData] = useState({});

    // 真正的 numbering rule
    const [noRule, setNoRule] = useState('');

    const [originalDataForLog, setOriginalDataForLog] = useState({
        // The parameters which were needed for logging page
        name: '',
        noRule: '',
        startNo: '',
        incremental: '',
        status: '',
    });

    // 給使用者看的 numbering rule

    const [ruleForUser, setRuleForUser] = useState('');

    // numbering rule 的預期結果示範
    const [preview, setPreview] = useState([]);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
    const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
    const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
        useState(false);

    const [err, setErr] = useState(false);

    const handleActivate = () => {
        const updateContent = t('log.activate', {
            value: data.name,
        });

        console.log(updateContent);

        axios
            .put(`api/Logistics/lgsDocumentNoRule/activate`, {
                noRuleGuid: data.noRuleGuid,
                status: '1',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const handleInActivate = () => {
        const updateContent = t('log.lock', {
            value: data.name,
        });

        axios
            .put(`api/Logistics/lgsDocumentNoRule/activate`, {
                noRuleGuid: data.noRuleGuid,
                status: '2',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const handleDelete = () => {
        const updateContent = t('log.delete', {
            value: data.name,
        });

        const { name, noRuleGuid, startNo, incremental, rowStamp } = data;

        axios
            .put(`api/Logistics/${leftActive}/delete`, {
                name,
                noRule,
                noRuleGuid,
                startNo,
                incremental,
                status: '4',
                rowStamp,
                updateContent,
            })
            .then((res) => {
                console.log(res.data);
                handleLeftColBtnClick(leftActive);
            });
    };

    const handleDialogConfirm = (
        dialogNoRule,
        dialogData,
        dialogStartNo,
        dialogIncrement
    ) => {
        console.log(dialogNoRule, dialogData, dialogStartNo, dialogIncrement);

        setData({
            ...data,
            ...dialogData,
            startNo: dialogStartNo,
            incremental: dialogIncrement,
        });

        console.log(
            dialogNoRule,
            originalDataForLog.noRule,
            dialogNoRule !== originalDataForLog.noRule
        );

        setNoRule(dialogNoRule);
    };

    const checkInputs = (isActiveSaveDirectly, status) => {
        if (data?.name && isActiveSaveDirectly && status === '1') {
            // 啟動並存檔，不跳出存檔視窗
            setErr(false);
            handleSubmit('1');
        } else if (data?.name && isActiveSaveDirectly && status === '2') {
            // 停用並存檔，不跳出存檔視窗
            setErr(false);
            handleSubmit('2');
        } else if (data?.name && !isActiveSaveDirectly) {
            // 跳出存檔視窗，讓使用者選擇要啟動+存檔或只有存檔
            setErr(false);
            return setIsSubmitConfirmDialogOpen(true);
        } else {
            setErr(true);
        }
    };

    const handleSubmit = (status) => {
        console.log(data);
        console.log(originalDataForLog);

        const newData = { ...data, status: status };
        const updateContent = generateUpdateLogContent(
            'purchaseOrderNoRule',
            t,
            newData,
            originalDataForLog
        );

        const { name, noRuleGuid, startNo, incremental, rowStamp } = data;

        if (updateContent) {
            axios
                .put(`api/Logistics/${leftActive}/update`, {
                    name,
                    noRule,
                    noRuleGuid,
                    startNo,
                    incremental,
                    status,
                    rowStamp,
                    updateContent,
                })
                .then((res) => {
                    if (res.status === 200) {
                        handleLeftColBtnClick(leftActive);
                        setIsSubmitConfirmDialogOpen(false);

                        // 回到detail
                        handleCenterRowClick(data.noRuleGuid);
                    } else {
                        alert(res.status);
                    }
                });
        } else {
            alert(123);
        }
    };

    useEffect(() => {
        const {
            incremental,
            name,
            noRule,
            noRuleGuid,
            rowStamp,
            startNo,
            status,
        } = rightColInfo;

        // 存放 numbering rule(db)
        setNoRule(noRule);

        if (Object.keys(rightColInfo).length !== 0) {
            // 把 numbering rule(db) 拆解
            const {
                prefix,
                suffix,
                timeSeparator,
                format,
                content,
                period,
                length,
            } = convertFromNumberingRuleToRuleForUser(noRule, startNo);

            console.log(
                prefix,
                suffix,
                timeSeparator,
                format,
                content,
                period,
                length
            );

            setData({
                ...data,
                // 存放 numbering rule data
                noRuleGuid: noRuleGuid,
                status: status,
                rowStamp: rowStamp,
                startNo: startNo,
                name: name,
                incremental: incremental,
                // 把拆解後的參數, 更新到 data 裡
                prefix: prefix,
                suffix: suffix,
                timeSeparator: timeSeparator,
                period: period,
                length: length,
                format: format,
            });

            const noRuleForUser = `${prefix}${format}${timeSeparator}${content}${suffix}`;
            setRuleForUser(noRuleForUser);

            const rule = {
                prefix,
                timeSeparator,
                format: periodFormatDict[period],
                startNo: startNo,
                incremental: incremental,
                suffix,
            };

            console.log(rule);

            // 產生 preview
            const preview = getRulePreview(rule);
            setPreview(preview);
        }
    }, [rightColInfo]);

    // 計算 numbering rule(UI) & 產生 preview
    useEffect(() => {
        // 把 numbering rule(db) 拆解
        const {
            prefix,
            suffix,
            timeSeparator,
            format,
            content,
            period,
            length,
        } = convertFromNumberingRuleToRuleForUser(noRule, data.startNo);

        const noRuleForUser = `${prefix}${format}${timeSeparator}${content}${suffix}`;
        setRuleForUser(noRuleForUser);

        const rule = {
            prefix,
            timeSeparator,
            format: periodFormatDict[period],
            startNo: data.startNo,
            incremental: data.incremental,
            suffix,
        };

        // 產生 preview
        const preview = getRulePreview(rule);
        setPreview(preview);
    }, [noRule, data.startNo, data.incremental]);

    return (
        <>
            <div className="h-100 bg-white position-relative">
                <div
                    className={`authTitleWrapper d-flex justify-content-center ${
                        rightColInfo.status === '0'
                            ? 'bg-warninglt'
                            : rightColInfo.status === '1'
                            ? 'bg-grey1'
                            : rightColInfo.status === '2'
                            ? 'bg-dangerlt'
                            : ''
                    }`}
                    style={{
                        borderRadius: '.75rem .75rem 0 0',
                    }}
                >
                    <h5
                        className={`fw-bold text-left ${
                            rightColInfo.status === '0'
                                ? 'text-warning'
                                : rightColInfo.status === '1'
                                ? 'text-black'
                                : rightColInfo.status === '2'
                                ? 'text-danger'
                                : ''
                        } m-0`}
                        style={{
                            fontSize: '14px',
                            lineHeight: '40px',
                        }}
                    >
                        {rightColInfo.status === '0'
                            ? '未啟用'
                            : rightColInfo.status === '1'
                            ? '規則'
                            : rightColInfo.status === '2'
                            ? '停用'
                            : ''}
                    </h5>

                    {(rightColInfo.status === '0' ||
                        rightColInfo.status === '2') && (
                        <BtnDelete
                            className="position-absolute top-0 end-0 mt-1 me-2"
                            onClick={() => setIsDeleteDialogOpen(true)}
                        >
                            <DeleteIcon />
                        </BtnDelete>
                    )}
                </div>

                {/* DialogConfirm element */}
                <NumberingRuleArea>
                    <DialogConfirm
                        t={t}
                        isOpen={isActivateDialogOpen}
                        setIsOpen={setIsActivateDialogOpen}
                        handleClick={() => handleActivate()}
                        message={t('dialog.activeConfirm')}
                    />
                    <DialogConfirm
                        t={t}
                        isOpen={isInActivateDialogOpen}
                        setIsOpen={setIsInActivateDialogOpen}
                        handleClick={() => handleInActivate()}
                        message={t('dialog.lockConfirm')}
                    />
                    <DialogConfirm
                        t={t}
                        isOpen={isDeleteDialogOpen}
                        setIsOpen={setIsDeleteDialogOpen}
                        handleClick={() => handleDelete()}
                        message={t('dialog.deleteConfirm')}
                    />
                    <SubmitConfirm
                        t={t}
                        isOpen={isSubmitConfirmDialogOpen}
                        setIsOpen={setIsSubmitConfirmDialogOpen}
                        handleClickInactive={() => handleSubmit('0')}
                        handleClickActive={() => handleSubmit('1')}
                        message={t('dialog.saveConfirm')}
                    />

                    <div className="position-relative">
                        <Wrapper className="bg-whit d-flex flex-column px-5 py-4 position-relative bg-white">
                            <div className="col-12 mb-4">
                                <FormControlInput
                                    id="name"
                                    labelText={t('lgsDocumentNoRule:name')}
                                    value={data.name}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            name: e.target.value,
                                        });
                                    }}
                                    disabled={false}
                                    disabledCssType={0}
                                    isErr={err}
                                    errMsg={t('errMsg.mustFill')}
                                />

                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal>
                                        {t('lgsDocumentNoRule:noRule')}
                                    </LabelNormal>
                                    <div
                                        className="input-group vendorGuidInput"
                                        id="vendorGuidInput"
                                    >
                                        <InputElement
                                            className="form-control border-right-0 item-view-noRule border-grey2"
                                            placeholder=""
                                            value={ruleForUser}
                                            onChange={() => {}}
                                            type="text"
                                            id="noRule"
                                            name="noRule"
                                            style={{
                                                cursor: 'pointer',
                                                pointerEvents: 'none',
                                                borderRight: 0,
                                            }}
                                            isDisabled={data?.status !== '0'}
                                        />

                                        <DialogBtn
                                            isDisabled={data?.status !== '0'}
                                            onClick={() => {
                                                data?.status !== '0'
                                                    ? ''
                                                    : setModalOpen(true);
                                            }}
                                        >
                                            <Icn_OpenModal />
                                        </DialogBtn>
                                    </div>
                                </div>
                                <FormControlInput
                                    id="startNo"
                                    labelText={t('lgsDocumentNoRule:startNo')}
                                    type="number"
                                    value={data?.startNo}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            startNo: e.target.value,
                                        });
                                    }}
                                    disabled={true}
                                    disabledCssType={0}
                                />
                                <FormControlInput
                                    id="incremental"
                                    labelText={t(
                                        'lgsDocumentNoRule:incremental'
                                    )}
                                    value={data.incremental}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            incremental: e.target.value,
                                        });
                                    }}
                                    disabled={true}
                                    disabledCssType={0}
                                />
                                <LabelNormal>
                                    {t('lgsDocumentNoRule:preview')}
                                </LabelNormal>
                                <Textarea disabled>
                                    <div className="mb-1">{preview[0]}</div>
                                    <div className="mb-1">{preview[1]}</div>
                                    <div className="mb-1">{preview[2]}</div>
                                </Textarea>
                            </div>

                            <div className="col-12 d-flex">
                                {rightColInfo.status === '0' ||
                                rightColInfo.status === '2' ? (
                                    <BtnNormal2
                                        className="btn-primary me-3"
                                        handleClick={() =>
                                            checkInputs(true, '1')
                                        }
                                        word="啟用"
                                        children={
                                            <ActivateIcon className="me-2" />
                                        }
                                        elementStyle={{ width: '88px' }}
                                    ></BtnNormal2>
                                ) : (
                                    <BtnNormal2
                                        className="btn-outline-danger me-3"
                                        handleClick={() =>
                                            checkInputs(true, '2')
                                        }
                                        word="停用"
                                        children={
                                            <LockIcon className="lockIcon me-2" />
                                        }
                                        elementStyle={{ width: '88px' }}
                                    ></BtnNormal2>
                                )}

                                <BtnNormal2
                                    className="btn-primary mediumBtn me-3"
                                    handleClick={() => checkInputs(false)}
                                >
                                    {t('button.saveButton')}
                                </BtnNormal2>
                                <BtnNormal2
                                    className="btn-outline-primary mediumBtn me-0"
                                    handleClick={() =>
                                        handleCenterRowClick(data.noRuleGuid)
                                    }
                                >
                                    {t('button.cancelButton')}
                                </BtnNormal2>
                            </div>
                        </Wrapper>
                    </div>
                </NumberingRuleArea>
            </div>
            <NumberingRuleDialog
                modalOpen={modalOpen}
                handleClose={() => {
                    setModalOpen(false);
                }}
                data={{
                    ...data,
                    prefix: data.prefix?.replace(new RegExp('"', 'g'), ''),
                    suffix: data.suffix?.replace(new RegExp('"', 'g'), ''),
                }}
                setData={setData}
                noRule={noRule}
                preview={preview}
                setPreview={setPreview}
                handleSubmit={handleDialogConfirm}
                t={t}
                dialogData={dialogData}
                setDialogData={setDialogData}
            />
        </>
    );
}

export default LgsDocumentNoRule_Edit;
