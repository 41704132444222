import styled from 'styled-components';
import { useState } from 'react';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import { DialogCol } from '../DialogCol';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}

	background-color: ${(prop) =>
		prop.active ? '#fbfcfd !important' : '#fff'};

	position: relative;
	padding: 7px 8px !important;
	max-height: 40px;
`;

const Container = styled.div`
	display: flex;
	justify-content: start;
	margin: 16px 0;
`;

const Input = styled.input`
	padding-inline: 8px;
	border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
	border-radius: 4px;

	/* 去除 type="number" 上下按鈕 */
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	::placeholder {
		color: var(--grey3);
	}

	&:disabled {
		border: none;
		background-color: transparent;
	}
`;

// -----Dialog------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	height: 25px;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 25px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: -1px;
	right: 24px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

function GoodsTable(props) {
	//#region state initiation
	// i18n
	const { t } = useTranslation(['common', 'lgsPurchaseOrderGoodsReturn']);

	const {
		pageData,
		setPageData,
		headTitleList,
		bodyTitleList,
		tableHeadStyles,
		tableBodyStyles,
		tableBodyData,
		setTableBodyData,
		inputProperties,
		backEndData,
		dialogBtnKeyName,
		setDialogBtnKeyName,
		isOpen,
		setIsOpen,
		modalData,
		setModalData,
	} = props;
	// console.log(props);

	console.log('tableBodyData', tableBodyData);
	//#endregion

	//#region function

	const handleReturnAll = () => {
		const newData = [...tableBodyData];
		newData.forEach((el) => {
			el.quantityReturn = el.quantityReceive;
		});

		setTableBodyData([...newData]);

		const newPageData = { ...pageData };
		newPageData.purchaseOrderGoodsItemList = [...newData];
		setPageData({ ...newPageData });
	};
	// console.log('pageData', pageData);

	const handleDoubleClick = (rowIndex) => {
		console.log(rowIndex);
		const newData = [...tableBodyData];
		newData[rowIndex].quantityReturn = newData[rowIndex].quantityReceive;
		setTableBodyData([...newData]);

		const newPageData = { ...pageData };
		newPageData.purchaseOrderGoodsItemList = [...newData];
		setPageData({ ...newPageData });
	};

	const handleEditTable = (e, index) => {
		const newList = [...tableBodyData];
		newList.forEach((el) => delete el?.active);
		newList[index].active = true;

		setTableBodyData(newList);
	};

	// input改變時
	const handleInputChange = (value, rowIndex, inputIndex, toFloat) => {
		// console.log(value);
		const newData = [...tableBodyData];

		if (toFloat) {
			// 如果此input有decimalPlace限制
			// const regex = new RegExp(`^(\\d+(\\.\\d{0,${decimalPlace}})?|)$`); // 只允許數字，並且小數點後最多decimalPlace位
			// if (regex.test(value))
			// 	newData[rowIndex][bodyTitleList[inputIndex]] = value;
			// setTableBodyData(newData);
		} else {
			newData[rowIndex][bodyTitleList[inputIndex]] = value;
			setTableBodyData(newData);

			const newPageData = { ...pageData };
			newPageData.purchaseOrderGoodsItemList = [...newData];
			setPageData({ ...newPageData });
		}
	};

	const handleInputBlur = (value, rowIndex, inputIndex, inputName) => {
		const newData = [...tableBodyData];

		if (inputName === 'quantityReturn') {
			const quantityReceive = newData[rowIndex]['quantityReceive'];

			// 收貨數量 不大於 未收數量
			if (Number(value) > Number(quantityReceive)) {
				newData[rowIndex]['quantityReturn'] = quantityReceive;
				setTableBodyData(newData);
			} else if (Number(value) < 0) {
				// 未收數量不為負數
				newData[rowIndex]['quantityReturn'] = 0;
				setTableBodyData(newData);
			}

			const newPageData = { ...pageData };
			newPageData.purchaseOrderGoodsItemList = [...newData];
			setPageData({ ...newPageData });
		}

		// 手動批次編號，判斷是否滿碼
		if (inputName === 'batchNo' && newData[rowIndex].batchNoAuto === '0') {
			const { batchNumberingFull, batchNumberingLength } =
				newData[rowIndex];
			const inputValueLength = value.length;

			// console.log(
			// 	batchNumberingFull,
			// 	batchNumberingLength,
			// 	inputValueLength
			// );

			if (
				batchNumberingFull &&
				inputValueLength !== batchNumberingLength
			) {
				newData[rowIndex][inputName] = '';
			} else {
				newData[rowIndex][`${inputName}Warning`] = '';
			}

			setTableBodyData(newData);

			const newPageData = { ...pageData };
			newPageData.purchaseOrderGoodsItemList = [...newData];
			setPageData({ ...newPageData });
		}
	};

	// 採購型態 code轉換文字
	const handlePurchaseCategoryName = (purchaseCategoryCode) => {
		switch (purchaseCategoryCode) {
			case 'M':
				return '物料';
			case 'E':
				return '費用';
			case 'A':
				return '資產';
		}
	};

	// 採購內容 串字串
	const handlePurchaseContent = (data) => {
		const {
			purchaseCategoryCode,
			materialNo,
			materialName,
			assetsName,
			assetsNo,
			expenseClassCode,
			expenseClassName,
			sectorCode,
			sectorName,
		} = data;

		switch (purchaseCategoryCode) {
			case 'M':
				return `${materialNo} : ${materialName}`;
			case 'E':
				return `${expenseClassCode} ${expenseClassName} : ${sectorCode} ${sectorName}`;
			case 'A':
				return `${assetsNo} : ${assetsName}`;
		}
	};

	//#endregion

	//#region DialogCol

	const [indexDialog, setIndexDialog] = useState('');

	// DialogCol input 黃色按鈕取消
	const handleCancelClick = (btnName, rowIndex) => {
		const newData = { ...pageData };

		// 兩種使用
		if (btnName === 'cube') {
			newData.purchaseOrderGoodsItemList[rowIndex].cubeGuid = '';
			newData.purchaseOrderGoodsItemList[rowIndex].cubeName = '';
		} else if (btnName === 'qualityCategory') {
			newData.purchaseOrderGoodsItemList[rowIndex].qualityCategory = '';
			newData.purchaseOrderGoodsItemList[rowIndex].qualityCategoryName =
				'';
		}
		console.log(newData);

		setPageData(newData);
	};

	// DialogCol Modal open
	const handleOpen = (btnName, rowIndex) => {
		// 兩種使用
		if (btnName === 'cube') {
			setDialogBtnKeyName('cube');
			setModalData(backEndData.materialCubeList);
		} else if (btnName === 'qualityCategory') {
			setDialogBtnKeyName('qualityCategory');
			setModalData(backEndData.defaultQualityCategoryList);
		}

		setIndexDialog(rowIndex); // 獲取真實的index，傳給DialogCol
		setIsOpen(true);
	};

	//#endregion

	return (
		<div className="position-relative">
			{/* DialogCol element */}
			<DialogCol
				indexDialog={indexDialog}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				modalData={modalData}
				setModalData={setModalData}
				btnKeyName={dialogBtnKeyName}
				pageData={pageData}
				setPageData={setPageData}
				tableBodyData={tableBodyData}
				setTableBodyData={setTableBodyData}
			/>

			<IndexTableContainer className="bg-white d-flex flex-column position-relative">
				<Container>
					<BtnNormal2
						className="btn-primary ms-3 smallBtn"
						keyName="advance-search"
						handleClick={handleReturnAll}
						word={t('lgsPurchaseOrderGoodsReturn:returnAll')}
					></BtnNormal2>
				</Container>
				<IndexTable className="table">
					<THead className="table-head">
						<TR>
							{headTitleList?.map((el) => (
								<TH
									key={`tableHead${headTitleList?.indexOf(
										el
									)}`}
									style={
										tableHeadStyles[
											headTitleList?.indexOf(el)
										]
									}
								>
									{el}
								</TH>
							))}
						</TR>
					</THead>
					<TBody className="">
						{tableBodyData?.map((data, rowIndex) => (
							<TR
								key={`GoodsTableRow${rowIndex}`}
								onClick={(e) => handleEditTable(e, rowIndex)}
							>
								{bodyTitleList?.map((el, inputIndex) => {
									// 不可編輯欄位(不為input)
									if (
										[
											'displayOrder',
											'purchaseCategoryCode',
											'purchaseContent',
											'uomName',
											'quantityReceive',
										].includes(el)
									)
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<div className="px-2">
													{el ===
													'purchaseCategoryCode'
														? handlePurchaseCategoryName(
																data[el]
														  )
														: data[el]}
													{el === 'purchaseContent' &&
														handlePurchaseContent(
															data
														)}
												</div>
											</TD>
										);

									// 退貨數量
									if (el === 'quantityReturn') {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<Input
													style={{
														minWidth: '24px',
														width: '100%',
														outline: 'none',
													}}
													className={`${'text-end'}`}
													value={data[el] || ''}
													active={data.active}
													type={
														inputProperties[
															inputIndex
														]?.type
													}
													onChange={(e) => {
														handleInputChange(
															e.target.value,
															rowIndex,
															inputIndex
														);
													}}
													onDoubleClick={() =>
														handleDoubleClick(
															rowIndex
														)
													}
													onBlur={(e) =>
														handleInputBlur(
															e.target.value,
															rowIndex,
															inputIndex,
															el
														)
													}
												/>
											</TD>
										);
									}

									// 物料倉位
									if (
										data.purchaseCategoryCode === 'M' &&
										el === 'materialCube'
									) {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<DialogWrapper className="row flex-nowrap">
													<InputDialogElementData
														type="text"
														value={
															data.cubeGuid || ''
														}
														onChange={() => {}}
													/>
													<InputDialogElementView
														style={{
															minWidth: '85px',
															cursor: 'auto',
															border: 'none', //TODO: ?可編輯的話就拿掉
														}}
														value={
															data.cubeName || ''
														}
														onChange={() => {}}
													/>
													<CancelInputBtn
														onClick={() => {
															handleCancelClick(
																'cube',
																rowIndex
															);
														}}
														isValueExist={
															false &&
															data.cubeGuid //TODO: ?可編輯的話就拿掉false
														}
													>
														<Icn_InputYellowCancel />
													</CancelInputBtn>

													<DialogBtn
														style={{
															cursor: 'pointer',
															display: 'none', //TODO: ?可編輯的話就拿掉
														}}
														onClick={() =>
															handleOpen(
																'cube',
																rowIndex
															)
														}
													>
														<Icn_InputDialog />
													</DialogBtn>
												</DialogWrapper>
											</TD>
										);
									} else if (el === 'materialCube') {
										// 不為資產與費用時，不可編輯
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											></TD>
										);
									}

									// 品質型態
									if (
										data.purchaseCategoryCode === 'M' &&
										el === 'qualityCategory'
									) {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<DialogWrapper className="row flex-nowrap">
													<InputDialogElementData
														type="text"
														value={
															data.qualityCategory ||
															''
														}
														onChange={() => {}}
													/>
													<InputDialogElementView
														style={{
															minWidth: '85px',
															cursor: 'auto',
															border: 'none', //TODO: ?可編輯的話就拿掉
														}}
														value={
															data.qualityCategoryName ||
															''
														}
														onChange={() => {}}
													/>
													<CancelInputBtn
														onClick={() => {
															handleCancelClick(
																'qualityCategory',
																rowIndex
															);
														}}
														isValueExist={
															false &&
															qualityCategory //TODO: ?可編輯的話就拿掉false
														}
													>
														<Icn_InputYellowCancel />
													</CancelInputBtn>

													<DialogBtn
														style={{
															cursor: 'pointer',
															display: 'none', //TODO: ?可編輯的話就拿掉
														}}
														onClick={() =>
															handleOpen(
																'qualityCategory',
																rowIndex
															)
														}
													>
														<Icn_InputDialog />
													</DialogBtn>
												</DialogWrapper>
											</TD>
										);
									} else if (el === 'qualityCategory') {
										// 不為資產與費用時，不可編輯
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											></TD>
										);
									}

									// 批次編號
									if (el === 'batchNo') {
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<Input
													style={{
														minWidth: '24px',
														width: '100%',
														outline: 'none',
														border: 'none', //TODO: ?可編輯的話就拿掉
													}}
													className={`${'text-end'}`}
													value={
														data['batchNo'] || ''
													}
													disabled={
														// data[
														// 	'purchaseCategoryCode'
														// ] !== 'M' ||
														// data['batchNoAuto'] ===
														// 	'1'
														true //TODO: ?可編輯的話就移除註解
													}
													active={data.active}
													type={
														inputProperties[
															inputIndex
														]?.type
													}
													placeholder={
														data['batchNoAuto'] ===
															'0' &&
														data.batchNumberingFull
															? `${t(
																	'lgsPurchaseOrderGoodsReturn:requiredAndMustBeFullWidth'
															  )}(${
																	data.batchNumberingLength
															  }${t(
																	'lgsPurchaseOrderGoodsReturn:code'
															  )})`
															: ''
													}
													onChange={(e) => {
														handleInputChange(
															e.target.value,
															rowIndex,
															inputIndex
														);
													}}
													onBlur={(e) =>
														handleInputBlur(
															e.target.value,
															rowIndex,
															inputIndex,
															el
														)
													}
												/>
											</TD>
										);
									}

									return (
										<TD
											style={
												tableBodyStyles[
													bodyTitleList?.indexOf(el)
												]
											}
											className={`border`}
											active={data.active}
											key={`${bodyTitleList?.indexOf(
												el
											)}`}
										>
											<Input
												style={{
													minWidth: '24px',
													width: '100%',
													outline: 'none',
												}}
												className={``}
												value={data[el] || ''}
												active={data.active}
												type={
													inputProperties[inputIndex]
														?.type
												}
												onChange={(e) => {
													handleInputChange(
														e.target.value,
														rowIndex,
														inputIndex
													);
												}}
											/>
										</TD>
									);
								})}
							</TR>
						))}
					</TBody>
				</IndexTable>
			</IndexTableContainer>
		</div>
	);
}

export default GoodsTable;
