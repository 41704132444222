import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import TopInfo from './TopInfo';
import { DialogWarning } from '../DialogWarning';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import BottomInfo from './BottomInfo';
import { TabPage } from '../../../LgsPurchaseOrder/exports';
import PaymentTerm from './TabContent/PaymentTerm/PaymentTerm';
import HistoryOverview from './TabContent/HistoryOverview/HistoryOverview';
import { useTranslation } from 'react-i18next';
import PaymentHistory from './TabContent/PaymentHistory/PaymentHistory';
import AccountDocumentTab from './TabContent/AccountDocumentTab/AccountDocumentTab';
// #region styled-components

const IndexTableContainer = styled.div``;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page2(props) {
	//#region state initiation
	const { t, i18n } = useTranslation();
	const {
		setDialogConfirmData,
		parentData,
		setParentData,
		purchaseOrderData,
		page,
		processSteps,
		handleNextPage,
		dialogBtnKeyName,
		setDialogBtnKeyName,
		isOpen,
		setIsOpen,
		modalData,
		setModalData,
		backEndData,
		setBackEndData,
		warningMessage,
		setWarningMessage,
		handlePrevPage,
		CurrencyCode,
		CurrencyName,
		DecimalPlaceTl,
	} = props;
	// console.log(props);
	const [success, setSuccess] = useState(false);
	const [pageData, setPageData] = useState(parentData);
	const [accountList, setAccountList] = useState([]);
	console.log(props);
	//console.log(pageData);

	//#endregion

	//#region variable initiation

	// 分頁title
	const tabNavList = [
		t('lgsPurchaseOrderPaymentReturn:page2.tabs.paymentDetails'),
		t('lgsPurchaseOrderPaymentReturn:page2.tabs.paymentTerms'),
		t(
			'lgsPurchaseOrderPaymentReturn:page2.tabs.transactionHistoryOverview'
		),
		t('lgsPurchaseOrderPaymentReturn:page2.tabs.paymentHistory'),
		t('lgsPurchaseOrderPaymentReturn:page2.tabs.accountingVoucher'),
	];

	// 分頁內容 component
	// state
	const [tabData, setTabData] = useState([]);
	const tabContent = [
		<div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
			<BottomInfo
				pageData={pageData}
				setPageData={setPageData}
				topInfoData={purchaseOrderData?.topInfoData}
				purchaseOrderData={purchaseOrderData}
				dialogBtnKeyName={dialogBtnKeyName}
				setDialogBtnKeyName={setDialogBtnKeyName}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				modalData={modalData}
				setModalData={setModalData}
				backEndData={backEndData}
				CurrencyCode={CurrencyCode}
				CurrencyName={CurrencyName}
				DecimalPlaceTl={DecimalPlaceTl}
			/>
		</div>,
		<PaymentTerm parentData={parentData} />,
		<HistoryOverview parentData={parentData} />,
		<PaymentHistory
			purchaseOrderPayment={parentData.purchaseOrderPayment}
			foreignCurrency={parentData.purchaseOrder.foreignCurrency}
		/>,

		<AccountDocumentTab
			parentData={parentData}
			tabData={{
				//前端畫面
				accountDocumentName: '會計憑證',
				tabData,

				//後端
				dataToBackEnd: {
					goodsReceivedNoteList: [],
					newInvoiceList: [],
					priceVarianceList: [],
				},
			}}
			// tab3Data={{
			// 	// 前端畫面使用
			// 	accountDocumentName: '會計憑證',
			// 	accountList: accountList,

			// 	// 後端需要的，分開3個list
			// 	dataToBackEnd: {
			// 		goodsReceivedNoteList: [],
			// 		newInvoiceList: [],
			// 		priceVarianceList: [],
			// 	},
			// }}
			DecimalPlaceTl={DecimalPlaceTl}
			foreignCurrency={parentData.purchaseOrder.foreignCurrency}
		/>,
	];

	//#endregion

	//#region function

	// 暫存改被修改的資料
	const handleTemporaryStore = () => {
		const newParentData = { ...parentData };
		const newPageData = { ...pageData };

		// 在換頁時，先新增採購金額欄位(purchasePrice,purchaseForeignPrice)
		newPageData.purchaseOrderItemList.forEach((el) => {
			el.purchaseForeignPrice = (
				el.foreignNetPrice * el.quantityReceive
			).toFixed(newPageData.backEndData.purchaseOrder.decimalPlaceTl);

			el.purchasePrice = (el.netPrice * el.quantityReceive).toFixed(
				newPageData.backEndData.purchaseOrder.decimalPlaceTl
			);
		});

		newParentData.page1 = newPageData;
		// 標記是否曾拿取過資料
		newParentData.isEverEnterPage.page1 = true;

		setParentData(newParentData);
	};

	const { sendRequest: axiosPaymentSubmit } = useAxios();

	// 往下一頁之前的檢查
	//console.log(pageData);
	const handleSubmit = () => {
		// handleNextPage();

		// const {purchaseOrderGuid}

		const {
			postingDate,
			employeeGuid,
			accountGuid,
			grossAmount,
			foreignGrossAmount,
			exchageRate,
			exchangeDifferenceAmount,
			note,
		} = pageData.payment;
		console.log(pageData.payment.exchangeDifferenceAmount);

		//儲存API-----
		axiosPaymentSubmit(
			{
				url: `api/Logistics/LgsPurchaseOrderPaymentReturn/insert`,
				method: 'POST',
				data: {
					purchaseOrderGuid: pageData.purchaseOrderGuid,
					postingDate,
					staffGuid: employeeGuid,
					accountGuid,
					grossAmount,
					foreignGrossAmount,
					exchageRate,
					exchangeDifferenceAmount:
						pageData.payment.exchangeDifferenceAmount,
					note,
					entry1: [tabData.accountList[0]],
					entry2: [tabData.accountList[1]],
					entry3: tabData.accountList[2]
						? [tabData.accountList[2]]
						: null,
				},
			},

			async (res) => {
				handleNextPage();
			}
		);
	};

	// axios
	const { sendRequest: axiosPaymentTermList } = useAxios();

	//#endregion
	console.log(pageData.payment);
	console.log('206', parentData);
	useEffect(() => {
		axiosPaymentTermList(
			{
				url: `api/Logistics/LgsPurchaseOrderPaymentReturn/getPage3`,
				method: 'POST',
				data: {
					purchaseOrderGuid: parentData.purchaseOrderGuid,
					//以下是 Linden 所補
					postingDate: parentData.payment.postingDate,
					staffGuid: parentData.payment.employeeGuid,
					accountGuid: parentData.payment.accountGuid,
					grossAmount: parentData.payment.grossAmount,
					foreignGrossAmount: parentData.payment.foreignGrossAmount,
					exchageRate: parentData.payment.exchageRate,
					exchangeDifferenceAmount:
						parentData.payment.exchangeDifferenceAmount,
					note: parentData.payment.note,
				},
			},
			async (res) => {
				//console.log('227res', res);

				//tab 頁面資料(韓會計憑證)
				const {
					purchaseOrderHistoryOverview,
					purchaseOrderPayment,
					purchaseOrderPaymentTerm,
					entry1,
					entry2,
					entry3,
				} = res;

				// 給予排序，後端需要(將三個list內元件，依序給予序號)
				let displayOrder = 1;

				// 處理小數點位數
				const processEntry = (entry) => {
					//console.log(entry);
					entry.forEach((el) => {
						if (el.amountCr)
							el.amountCr = Number(el.amountCr).toFixed(
								DecimalPlaceTl
							);

						if (el.amountDr)
							el.amountDr = Number(el.amountDr).toFixed(
								DecimalPlaceTl
							);

						// 給displayOrder
						el.displayOrder = displayOrder;
						displayOrder++;
					});
				};
				processEntry(entry1);
				processEntry(entry2);
				processEntry(entry3);

				// 合體(前端畫面使用)
				let accountList = [...entry1, ...entry2, ...entry3];
				//console.log('267accountList', accountList);
				setTabData({
					// 前端畫面使用
					accountDocumentName: res.accountDocumentName,
					accountList: accountList,

					// 後端需要的，分開3個list
					dataToBackEnd: {
						entry1: entry1,
						entry2: entry2,
						entry3: entry3,
					},
				});

				//-----------以下為 潤林的寫法
				setParentData({
					...parentData,
					purchaseOrderPaymentTerm: purchaseOrderPaymentTerm,
					purchaseOrderPayment: purchaseOrderPayment,
					purchaseOrderHistoryOverview: purchaseOrderHistoryOverview,
				});

				// }
				if (res.entry3 && res.entry3.length > 0) {
					res.entry3[0].displayOrder = 3;
				} else {
					res.entry3 = [];
				}

				res.entry1[0].displayOrder = 1;
				res.entry2[0].displayOrder = 2;
				setAccountList([res.entry1, res.entry2, res.entry3]);
			}
		);
	}, []);
	//console.log(parentData);
	//console.log('325accountList', accountList);
	//console.log('332tabData', tabData);
	/**
	 * DialogWarning 返回btn確認dialog
	 **/
	const [isWarningOpen, setIsWarningOpen] = useState(false);

	// DialogWarning Modal open
	const handleDialogWarning = () => {
		setIsWarningOpen(true);
	};

	const handleCancel = () => {};

	return (
		<>
			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				warningMessage={warningMessage}
			/>

			<Container className="">
				<Left className="">
					{page !== 0 && (
						<BtnNormal
							className="layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center"
							key="prevBtn"
							onClick={() => handlePrevPage()}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right className="">
					<BtnNormal
						className="layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center"
						key="nextBtn"
						onClick={(e) => {
							e.preventDefault();
							handleSubmit(page);
						}}
					>
						{t('common:button.saveButton')}
					</BtnNormal>
					<BtnNormal
						className="layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center"
						key="cancelBtn"
						onClick={() => {
							handleCancel();
						}}
					>
						{t('common:button.cancelButton')}
					</BtnNormal>
				</Right>
			</Container>

			<div className="row bg-white rounded-xxl px-6 py-4 mx-0 mb-3">
				<TopInfo
					pageData={pageData}
					setPageData={setPageData}
					topInfoData={purchaseOrderData?.topInfoData}
					purchaseOrderData={purchaseOrderData}
					dialogBtnKeyName={dialogBtnKeyName}
					setDialogBtnKeyName={setDialogBtnKeyName}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					modalData={modalData}
					setModalData={setModalData}
					CurrencyCode={CurrencyCode}
					CurrencyName={CurrencyName}
					DecimalPlaceTl={DecimalPlaceTl}
				/>
			</div>
			<TabPage
				tabNavList={tabNavList}
				tabContent={tabContent}
				defaultPage={0}
			/>
		</>
	);
}

export default Page2;
