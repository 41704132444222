import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import useAxios from '../../../hooks/useAxios';
import { CopyCreateBtn } from '../../../components/Buttons/CopyCreateBtn';
import { CancelSubmitBtn } from '../../../components/Buttons/CancelSubmitBtn';
import PageTitleBar from '../../../components/PageTitleBar/PageTitleBar';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import Page0 from './Pages/Page0/Page0';
import Page1 from './Pages/Page1/Page1';
import Page2 from './Pages/Page2/Page2';
import Page3 from './Pages/Page3/Page3';
import { DialogConfirm } from './Pages/DialogConfirm';

function LgsPurchaseOrderPaymentReturn() {
	const emptyPaymentData = {
		isEverEnterPage: {
			page0: false,
			page1: false,
			page2: false,
		},
		purchaseOrder: {},
		payment: {},
	};

	const { t, i18n } = useTranslation();
	const [parentData, setParentData] = useState(emptyPaymentData);
	const [cookies, setCookie] = useCookies();

	const CurrencyCode = cookies._company_currency_code;
	const CurrencyName = cookies._company_currency_name;
	const DecimalPlaceTl = cookies._company_currency_decimalplace_tl;

	const [page, setPage] = useState(0);
	const [view, setView] = useState('create');
	const pageTitle = t('lgsPurchaseOrderPaymentReturn:pageTitle');
	const permission = localStorage.getItem('Permission_LgsPurchaseOrder');

	//#region page1
	const [isSelectingCurrentOrder, setIsSelectingCurrentOrder] =
		useState(false);
	const { isLoading, error, sendRequest: axiosIndexData } = useAxios();
	const [fetchedData, setFetchedData] = useState([]);
	const [displayColumns, setDisplayColumns] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const [copyPurchaseOrderData, setCopyPurchaseOrderData] = useState({});
	const [backEndData, setBackEndData] = useState({});
	const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState([]);
	const [warningMessage, setWarningMessage] = useState('');

	const sortBy = 'orderDate';
	const guidName = 'paymentGuid';
	const location = useLocation();

	const processSteps = [
		t('lgsPurchaseOrderPaymentReturn:steps.step0'),
		t('lgsPurchaseOrderPaymentReturn:steps.step1'),
		t('lgsPurchaseOrderPaymentReturn:steps.step2'),
	];

	const headTitleList = [
		'#',
		'廠商代碼',
		'廠商名稱',
		'採購日期',
		'採購訂單編號',
		'幣別',
		'採購應稅總額',
		'採購未稅總額',
		'採購稅額',
		'本地採購應稅總額',
		'本地採購未稅總額',
		'本地採購稅額',
	];

	const bodyTitleList = [
		'displayOrder',
		'vendorCode',
		'vendor',
		'orderDate',
		'orderNo',
		'currencyCode',
		'displayNetAmount',
		'displayTaxAmount',
		'displayGrossAmount',
		'grossAmount',
		'netAmount',
		'taxAmount',
	];

	const tableStyles = [
		{
			minWidth: '54px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '240px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'left',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '72px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '120px',
			textAlign: 'center',
		},
		{
			minWidth: '112px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
	];

	const columnMustShow = ['displayOrder', 'orderDate', 'orderNo'];

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			return data;
		};

		return gettingData();
	}, []);

	//#endregion

	const handlePrevPage = () => {
		setPage(page - 1);
	};

	const handleNextPage = () => {
		setPage(page + 1);
	};

	/**
	 * DialogConfirm 返回btn確認dialog
	 **/
	const [dialogConfirmData, setDialogConfirmData] = useState({
		btnName: '',
		isOpen: false,
		goTo: '',
		title: '',
	});
	const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

	// DialogConfirm Modal open
	const handleDialogConfirm = () => {
		const newData = {
			btnName: 'goToRoot',
			isOpen: true,
			goTo: 'prePage',
			title: '確定離開採購付款退回頁面？',
		};
		setDialogConfirmData(newData);
	};

	useEffect(() => {
		// fetchIndexInfo();
		getLocalStorageData();

		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);

	return (
		<>
			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					goTo={-1}
					isDialogConfirm={true}
					handleDialogConfirm={handleDialogConfirm}
				/>

				{/* DialogConfirm element */}
				<DialogConfirm
					dialogConfirmData={dialogConfirmData}
					setDialogConfirmData={setDialogConfirmData}
					page={page}
					setPage={setPage}
					setIsConfirmBtnClicked={setIsConfirmBtnClicked}
				/>

				<div>
					{page === 0 && (
						<Page0
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handleNextPage={handleNextPage}
							emptyPaymentData={emptyPaymentData}
						/>
					)}
					{page === 1 && (
						<Page1
							dialogConfirmData={dialogConfirmData}
							setDialogConfirmData={setDialogConfirmData}
							parentData={parentData}
							setParentData={setParentData}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableStyles={tableStyles}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copyPurchaseOrderData={copyPurchaseOrderData}
							setCopyPurchaseOrderData={setCopyPurchaseOrderData}
							dialogBtnKeyName={dialogBtnKeyName}
							setDialogBtnKeyName={setDialogBtnKeyName}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							modalData={modalData}
							setModalData={setModalData}
							backEndData={backEndData}
							warningMessage={warningMessage}
							setWarningMessage={setWarningMessage}
							setBackEndData={setBackEndData}
							CurrencyCode={CurrencyCode}
							CurrencyName={CurrencyName}
							DecimalPlaceTl={DecimalPlaceTl}
						/>
					)}
					{page === 2 && (
						<Page2
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copyPurchaseOrderData={copyPurchaseOrderData}
							setCopyPurchaseOrderData={setCopyPurchaseOrderData}
							dialogConfirmData={dialogConfirmData}
							setDialogConfirmData={setDialogConfirmData}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableStyles={tableStyles}
							dialogBtnKeyName={dialogBtnKeyName}
							setDialogBtnKeyName={setDialogBtnKeyName}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							modalData={modalData}
							setModalData={setModalData}
							backEndData={backEndData}
							warningMessage={warningMessage}
							setWarningMessage={setWarningMessage}
							setBackEndData={setBackEndData}
							CurrencyCode={CurrencyCode}
							CurrencyName={CurrencyName}
							DecimalPlaceTl={DecimalPlaceTl}
						/>
					)}
					{page === 3 && (
						<Page3
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							copyPurchaseOrderData={copyPurchaseOrderData}
							setCopyPurchaseOrderData={setCopyPurchaseOrderData}
							dialogConfirmData={dialogConfirmData}
							setDialogConfirmData={setDialogConfirmData}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableStyles={tableStyles}
							dialogBtnKeyName={dialogBtnKeyName}
							setDialogBtnKeyName={setDialogBtnKeyName}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							modalData={modalData}
							setModalData={setModalData}
							backEndData={backEndData}
							warningMessage={warningMessage}
							setWarningMessage={setWarningMessage}
							setBackEndData={setBackEndData}
							emptyPaymentData={emptyPaymentData}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default LgsPurchaseOrderPaymentReturn;
