import dayjs from 'dayjs';
export const periodFormatDict = {
    y: 'yy',
    ym: 'yymm',
    ymd: 'yymmdd',
    'y-m': 'yy-mm',
    'y-m-d': 'yy-mm-dd',
    Y: 'yyyy',
    Ym: 'yyyymm',
    Ymd: 'yyyymmdd',
    'Y-m': 'yyyy-mm',
    'Y-m-d': 'yyyy-mm-dd',
};

const convertRuleToPreOrSuf = (rule) => {
    let output = '';
    const arr = rule.split('');
    const SPLITE_RULE = '"';

    for (let x = 0; x < arr.length; x++) {
        if (!(arr[x] > '0' && arr[x] <= '9')) {
            if (arr[x] === SPLITE_RULE) {
                output += SPLITE_RULE;
                while (arr[++x] !== SPLITE_RULE) {
                    // console.log(arr[x]);
                    output += arr[x];
                }
                output += SPLITE_RULE;
            }
        } else {
            // console.log(arr[x]);
            output += '@';
        }
    }

    // console.log(output);

    const prefix = output.split('@')[0] || '';
    const suffix = output.split('@')[1] || output.split('@')[2] || '';
    // console.log(prefix, suffix);
    return { prefix, suffix };
};

const checkIsTimeSeparator = (rule) => {
    let output = '';
    const timeSeparatorList = ['#', '-', '*', '_'];

    for (let x = 0; x < rule.length; x++) {
        if (timeSeparatorList.includes(rule[x])) {
            output += rule[x];
        }
    }

    return output;
};

function convertRuleToTime(rule) {
    const SPLITE_RULE = '"'; // assuming SPLITE_RULE is a constant variable
    const charRule = rule.split('');
    let output = '';

    for (let x = 0; x < charRule.length; x++) {
        if (charRule[x] === SPLITE_RULE) {
            // 篩掉前綴字為"y" 或 "Y"
            while (x < charRule.length - 1 && charRule[++x] !== SPLITE_RULE);
        } else {
            if (charRule[x] === 'y' || charRule[x] === 'Y') {
                let shouldContinue = true;
                while (shouldContinue && x < charRule.length - 1) {
                    output += charRule[x];
                    x++;
                    if (
                        (checkIsTimeSeparator(charRule[x]) &&
                            charRule[x + 1] > '0' &&
                            charRule[x + 1] <= '9') ||
                        (charRule[x] > '0' && charRule[x] <= '9')
                    ) {
                        shouldContinue = false;
                    }
                }
            }
        }
    }

    // console.log('period', output);

    return output;
}

export const convertFromNumberingRuleToRuleForUser = (
    numberingRule,
    startNo
) => {
    const { prefix, suffix } = convertRuleToPreOrSuf(numberingRule);

    // 使用 String.replace() 方法，將目標子字串替換為空字串
    // 要先暫時去掉時間, 以免下面在找 ['#', '-', '*', '_'] 時, 誤將時間裡的 '-' 當作是 numberingRule 中的 '-'
    const noRuleNoFormat = numberingRule.replace(
        convertRuleToTime(numberingRule),
        ''
    );
    const timeSeparator = checkIsTimeSeparator(noRuleNoFormat);

    const format = periodFormatDict[convertRuleToTime(numberingRule)] || '';

    const period = convertRuleToTime(numberingRule);

    const content = '0'.repeat(startNo.length);

    const length = content.length;

    return { prefix, suffix, timeSeparator, format, content, period, length };
};

const buildPreview = (
    prefix,
    format,
    timeSeparator,
    startNo,
    incremental,
    suffix,
    multiple = 0
) => {
    // console.log(
    //     prefix,
    //     format,
    //     timeSeparator,
    //     startNo,
    //     incremental,
    //     suffix,
    //     multiple
    // );
    let string = '';
    if (prefix) {
        string += prefix;
    }
    // console.log(string);
    if (format) {
        string += dayjs().format(format.toUpperCase());
    }
    // console.log(string);
    if (timeSeparator) {
        string += timeSeparator;
    }
    // console.log(string);s
    if (startNo) {
        // 將原始字串轉為數字並加上指定的數字
        let result = parseInt(startNo) + incremental * multiple;

        // 將結果轉為字串並使用 padStart 方法填充前面的 0
        result = result.toString().padStart(startNo.length, '0');

        string += result;
    }
    // console.log(string);
    if (suffix) {
        string += suffix;
    }

    // console.log(string);
    return string;
};

export const getRulePreview = (rule) => {
    const result = ['001', '002', '003'];
    const { prefix, format, timeSeparator, startNo, incremental, suffix } =
        rule;

    const firstPreview = buildPreview(
        prefix?.replace(new RegExp('"', 'g'), ''),
        format,
        timeSeparator,
        startNo,
        incremental,
        suffix?.replace(new RegExp('"', 'g'), ''),
        0
    );

    const secondPreview = buildPreview(
        prefix?.replace(new RegExp('"', 'g'), ''),
        format,
        timeSeparator,
        startNo,
        incremental,
        suffix?.replace(new RegExp('"', 'g'), ''),
        1
    );

    const thirdPreview = buildPreview(
        prefix?.replace(new RegExp('"', 'g'), ''),
        format,
        timeSeparator,
        startNo,
        incremental,
        suffix?.replace(new RegExp('"', 'g'), ''),
        2
    );

    // console.log(firstPreview);

    // console.log(secondPreview);

    // console.log(thirdPreview);

    result[0] = firstPreview;
    result[1] = secondPreview;
    result[2] = thirdPreview;

    return result;
};
