import { useState, useEffect, useRef } from 'react';
import useOnClickOutside from '../../hooks/useOnClickOutside.js';
import styled from 'styled-components';
import { ReactComponent as SettingIcon } from '../../assets/images/icn_Setting.svg';
import { BtnLarge } from '../Buttons/BtnLarge.jsx';
import TableDisplaySettingOptions from '../TableDisplaySettingOptions/TableDisplaySettingOptions.jsx';
import { useTranslation } from 'react-i18next';

// #region styled-components
const Menu = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    will-change: transform;
    padding: 0;
    margin-top: 8px;
    min-width: 136px;
    background-color: #ffffff;
    box-shadow: 2px 4px 12px -1px rgb(61 61 61 / 51%);
    border-radius: 0.25rem;
    color: var(--grey4);

    &:hover,
    &:focus {
        color: var(--primaryhover);
        background-color: var(--primarylt);
    }
`;

const Button = styled.div`
    //active-submit dropdownItem d-flex align-items-center pe-2 tex-left px-3 py-2
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 8px 16px;
    text-align: left;
    min-width: 136px;
    min-height: 32px;
    svg {
        margin-right: 8px;
    }
`;

const Backboard = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--grey4);
    z-index: 1000;
    opacity: 0.6;
`;

const BigBoy = styled.div`
    height: 500px;
    width: 500px;
    background: red;
    z-index: 1002;
`;

const ModalContent = styled.div`
    width: 520px;
    z-index: 1002;
    border-radius: 8px;
`;

const ModalHeader = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
`;

const ModalHeaderTitle = styled.div`
    color: var(--primary);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1.5;
`;

const ModalHeaderButton = styled.div`
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    cursor: pointer;
`;

const ModalBody = styled.div`
    max-width: 400px;
    margin: 30px 40px;
`;

const ModalBodyInner = styled.div`
    color: var(--grey4);
    padding: 0;
`;

const ModalInputWrapper = styled.div`
    width: 200px;
    text-align: left !important;
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.3125rem;
    /* padding-left: 1.5rem; */
    color: #616e83;
`;

const ModalCheckbox = styled.input``;

const ModalCheckboxLabel = styled.label`
    margin-left: 0.5rem !important;
    display: inline-block;
    margin-bottom: 0.5rem;
`;

const ModalFooter = styled.div`
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 24px 0;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
`;
// #endregion

// index 顯示設定 modal & hover button
// Author: YL
function IndexTableDisplayModal(props) {
    // console.log(props);
    const { t, i18n } = useTranslation();
    const {
        guidName, // page name
        isHovered,
        displayColumns, // 父層記錄要顯示的 column, 留在父
        setDisplayColumns,
        displayOptions, // 父子都記錄要顯示的 column, 可由子改變
        setDisplayOptions,
        headTitleList,
        bodyTitleList,
        columnMustShow, // 記錄一定要顯示的 column
    } = props;
    // PS. 因為子層改變 displayOptions 後可能會取消, 所以需要 displayColumns 記錄原值

    // 記錄滑鼠是否在 index table 的 header bar 上
    const [insideTheHeadBar, setInsideTheHeadBar] = useState(false);

    useEffect(() => {
        setInsideTheHeadBar(isHovered);
    }, [props]);

    // Create a ref that we add to the element for which we want to detect outside clicks
    const ref = useRef();

    // State for our modal
    const [isModalOpen, setModalOpen] = useState(false);
    // Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(ref, () => {
        setModalOpen(false);
        setInsideTheHeadBar(false);
    });

    const handleDisplayLocalStorage = () => {
        setDisplayColumns([...displayOptions]);

        // 把要顯示的 column 存到 localStorage
        localStorage.setItem(
            `${guidName}DisplayColumns`,
            JSON.stringify(displayOptions)
        );
        setModalOpen(false);
    };

    const handleCancelTableSetting = () => {
        setDisplayOptions([...displayColumns]);
        setModalOpen(false);
    };

    return (
        <div>
            {isModalOpen ? (
                <>
                    <Backboard />
                    <div
                        ref={ref}
                        className="position-fixed"
                        style={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1001,
                        }}
                    >
                        <ModalContent className="bg-white">
                            <ModalHeader>
                                <ModalHeaderTitle>
                                    {t('common:displaySetting')}
                                </ModalHeaderTitle>
                                <ModalHeaderButton
                                    onClick={() => handleCancelTableSetting()}
                                >
                                    X
                                </ModalHeaderButton>
                            </ModalHeader>
                            <ModalBody
                                className="row"
                                style={{ maxHeight: '200px' }}
                            >
                                {
                                    <TableDisplaySettingOptions
                                        displayOptions={displayOptions}
                                        setDisplayOptions={setDisplayOptions}
                                        headTitleList={headTitleList}
                                        bodyTitleList={bodyTitleList}
                                        columnMustShow={columnMustShow}
                                    />
                                }
                            </ModalBody>
                            <ModalFooter>
                                <BtnLarge
                                    className={'btn btn-primary'}
                                    onClick={() => handleDisplayLocalStorage()}
                                >
                                    {t('common:button.confirmButton')}
                                </BtnLarge>
                                <BtnLarge
                                    className={'btn btn-outline-primarylt'}
                                    onClick={() => handleCancelTableSetting()}
                                >
                                    {t('common:button.cancelButton')}
                                </BtnLarge>
                            </ModalFooter>
                        </ModalContent>
                    </div>
                </>
            ) : insideTheHeadBar ? (
                <Menu style={{ zIndex: '2' }}>
                    <Button
                        onClick={() => {
                            setModalOpen(true);
                            setInsideTheHeadBar(false);
                        }}
                    >
                        <SettingIcon />
                        <span>{t('common:displaySetting')}</span>
                    </Button>
                </Menu>
            ) : null}
        </div>
    );
}

export default IndexTableDisplayModal;
