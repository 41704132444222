import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { SaveSubmitBtn } from '../../../../../components/Buttons/SaveSubmitBtn';
import { CancelSubmitBtn } from '../../../../../components/Buttons/CancelSubmitBtn';
import TabPage from '../../../../../components/TabPage/TabPage';
import useAxios from '../../../../../hooks/useAxios';
import GoodsItemTab from './GoodsItemTab/GoodsItemTab';
import AccountDocumentTab from './AccountDocumentTab/AccountDocumentTab';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import { useTranslation } from 'react-i18next';

// #region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page2(props) {
	//#region state initialization

	const { t } = useTranslation(['common', 'lgsPurchaseOrderGoodsReturn']); // i18n

	const {
		parentData,
		setParentData,
		page,
		processSteps,
		handlePrevPage,
		handleNextPage,
	} = props;

	const exchangeRate = parentData.page1.goodsItemInfo.exchageRate;
	const { decimalPlaceTl } = parentData.page1.backEndData.purchaseOrderGoods;

	// state
	const [tab2Data, setTab2Data] = useState([]);

	// axios
	const { sendRequest: axiosDataPage2 } = useAxios();

	//#endregion

	//#region 2個分頁tab

	// 分頁title
	const tabNavList = [
		t('lgsPurchaseOrderGoodsReturn:returnDetails'),
		t('lgsPurchaseOrderGoodsReturn:accountingVoucher'),
	];

	// 分頁內容 2頁 component
	const tabContent = [
		<GoodsItemTab parentData={parentData} />,
		<AccountDocumentTab parentData={parentData} tab2Data={tab2Data} />,
	];
	//#endregion

	//#region useEffect

	useEffect(() => {
		const url = `api/Logistics/lgsPurchaseOrderGoodsReturn/getPage3`;
		const method = 'POST';

		const purchaseOrderItemList = [];
		parentData.page1.purchaseOrderGoodsItemList
			.filter(
				(item) => item.quantityReturn && item.quantityReturn !== '0'
			)
			.forEach((el) => {
				let newList = {
					PurchaseOrderItemGuid: el.purchaseOrderItemGuid || null,
					PurchaseCategoryCode: el.purchaseCategoryCode || null,
					MaterialGuid: el.materialGuid || null,
					MaterialClassGuid: el.materialClassGuid || null,
					AssetsClassGuid: el.assetsClassGuid || null,
					ExpenseClassGuid: el.expenseClassGuid || null,
					NetPrice: el.netPrice || null,
					ForeignNetPrice: el.foreignNetPrice || null,
					QuantityReturn: el.quantityReturn || null,
				};

				purchaseOrderItemList.push(newList);
			});
		console.log(purchaseOrderItemList);

		const data = {
			PostingDate: parentData.page1.goodsItemInfo.postingDate,
			PurchaseOrderItemList: purchaseOrderItemList,
		};

		axiosDataPage2({ url, method, data }, async (res) => {
			console.log(res);

			// 1.會計憑證
			const { goodsReceivedNoteList, invoiceList, priceVarianceList } =
				res;

			// 給予排序，後端需要(將三個list內元件，依序給予序號)
			let displayOrder = 1;

			// 1-1.處理小數點位數
			function processItem(el) {
				if (el.amountCr)
					el.amountCr = el.amountCr.toFixed(decimalPlaceTl);
				if (el.amountDr)
					el.amountDr = el.amountDr.toFixed(decimalPlaceTl);

				// 給displayOrder
				el.displayOrder = displayOrder;
				displayOrder++;
			}

			goodsReceivedNoteList.forEach(processItem);
			invoiceList.forEach(processItem);
			priceVarianceList.forEach(processItem);

			// 1-2.合體(前端畫面使用)
			let accountList = [
				...goodsReceivedNoteList,
				...invoiceList,
				...priceVarianceList,
			];
			console.log(accountList);

			setTab2Data({
				// 前端畫面使用
				accountDocumentName: res.accountDocumentName,
				accountList: accountList,

				// 後端需要的，分開3個list
				dataToBackEnd: {
					goodsReceivedNoteList: goodsReceivedNoteList,
					invoiceList: invoiceList,
					priceVarianceList: priceVarianceList,
				},
			});
		});
	}, []);

	console.log('parentData', parentData);
	console.log('tab2Data', tab2Data);
	//#endregion

	//#region submit送出給後端

	// 整理給後端的資料
	const organizeData = () => {
		const oldParentData = { ...parentData };
		const oldTab2Data = { ...tab2Data };

		// 整理資料

		const insertPurchaseOrderGoodsReturnPurchaseOrderGoodsItemList =
			oldParentData.page1.purchaseOrderGoodsItemList
				.filter(
					(item) => item.quantityReturn && item.quantityReturn !== '0' // 清除沒有退貨數量
				)
				.map(
					({
						purchaseOrderItemGuid,
						displayOrder,
						purchaseCategoryCode,
						materialGuid,
						qualityCategoryCode,
						quantityReturn,
						netPrice,
						foreignNetPrice,
						note,
						cubeGuid,
						batchNo,
						goodsItemGuid,
					}) => ({
						purchaseOrderItemGuid,
						displayOrder,
						purchaseCategoryCode,
						materialGuid,
						qualityCategory: qualityCategoryCode,
						quantityReturn,
						netPrice,
						foreignNetPrice,
						note,
						materialCubeGuid: cubeGuid,
						batchNo,
						parentDocumentItemGuid: goodsItemGuid,
					})
				);

		const glaAccountDocumentItem1List =
			oldTab2Data.dataToBackEnd.goodsReceivedNoteList.map(
				({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				}) => ({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				})
			);

		const glaAccountDocumentItem2List =
			oldTab2Data.dataToBackEnd.invoiceList.map(
				({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				}) => ({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				})
			);

		const glaAccountDocumentItem3List =
			oldTab2Data.dataToBackEnd.priceVarianceList.map(
				({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				}) => ({
					purchaseOrderItemGuid,
					displayOrder,
					accountGuid,
					note,
					amountDr,
					amountCr,
				})
			);

		let newData = {
			PostingDate: oldParentData.page1.goodsItemInfo.postingDate,
			StaffGuid: oldParentData.page1.goodsItemInfo.employeeGuid
				? oldParentData.page1.goodsItemInfo.employeeGuid
				: null,
			Note: oldParentData.page1.goodsItemInfo.note,
			ParentDocumentGuid: oldParentData.page1.goodsItemInfo.goodsGuid,
			GoodsReturnAmount:
				oldParentData.page1.totalPriceInfo.totalReturnPrice,
			ForeignGoodsReturnAmount:
				oldParentData.page1.totalPriceInfo.totalReturnForeignPrice,

			insertPurchaseOrderGoodsReturnPurchaseOrderGoodsItemList:
				insertPurchaseOrderGoodsReturnPurchaseOrderGoodsItemList,
			glaAccountDocumentItem1List: glaAccountDocumentItem1List,
			glaAccountDocumentItem2List: glaAccountDocumentItem2List,
			glaAccountDocumentItem3List: glaAccountDocumentItem3List,
		};

		return newData;
	};

	// 儲存
	const { error, sendRequest: axiosSaveData } = useAxios();

	const handleSubmit = () => {
		const newData = organizeData();
		console.log(newData);

		const url = `api/Logistics/LgsPurchaseOrderGoodsReturn/insert`;
		const method = 'POST';
		const data = newData;

		axiosSaveData({ url, method, data }, (res) => {
			console.log(res);

			const newParentData = { ...parentData };
			newParentData.page2.returnDocumentNo = res;

			setParentData(newParentData);
			res && handleNextPage();
		});
		console.log(error);
	};

	//#endregion

	return (
		<>
			<Container>
				<Left>
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handlePrevPage();
							}}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="ps-14">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					<SaveSubmitBtn handleSubmit={handleSubmit} />
					<CancelSubmitBtn className={'btn-outline-primary'} />
				</Right>
			</Container>

			<TabPage
				wrapperStyle={{ minHeight: '78vh' }}
				liColWidth={'col-2'}
				tabNavList={tabNavList}
				tabContent={tabContent}
				defaultPage={0}
			/>
		</>
	);
}

export default Page2;
