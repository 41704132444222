import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ReactComponent as CreateIcon } from '../../../../../assets/images/icn_Create.svg';
import { ReactComponent as CreateGreyIcon } from '../../../../../assets/images/icn_Create_Grey.svg';
import { ReactComponent as Icn_Cancel } from '../../../../../assets/images/icn_Cancel.svg';
import { ReactComponent as Icn_Search2 } from '../../../../../assets/images/icn_Search2.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import useSortableData from '../../../../../hooks/useSortableData';
import { useTranslation } from 'react-i18next';

// #region styled-components
const ModalThead = styled.thead`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalTh = styled.th`
	padding: 0 0.75rem;
	cursor: pointer !important;
	border: none !important;
`;
const ModalLabel = styled.label`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalInput = styled.input`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const SearchModalInput = styled.input`
	font-size: 14px;
	width: 240px;
	height: 32px;
	padding: 0 0.75rem;
	cursor: pointer !important;
	text-transform: uppercase;
`;
const ModalSearchBtn = styled.button`
	top: 1px;
	right: 74px;
	height: 30px;
	border-radius: 0 0.25rem 0.25rem 0;
`;
// #endregion

export const Dialog = ({
	dialogDataFromBE,
	btnWord,
	btnKeyName,
	newPurchaseOrderData,
	setNewPurchaseOrderData,
	cardKeyNameList,
	categoryControl,
	handleMouseEnter,
	handleMouseLeave,
}) => {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);

	// state
	const [modal, setModal] = useState(false);
	const [modalData, setModalData] = useState([]);

	// ref
	const searchModalInput = useRef('');

	// base function
	const toggle = () => setModal(!modal);

	//#endregion

	//#region  clickCreateModal(createBtn)

	const clickCreateModal = (e, btnKeyName) => {
		e.preventDefault();
		// console.log(btnKeyName);

		if (btnKeyName === 'material') {
			setModalData(dialogDataFromBE.materialMasterList);
		} else if (btnKeyName === 'assets') {
			setModalData(dialogDataFromBE.assetsList);
		}

		toggle();
	};

	//#endregion

	//#region Modal搜尋

	// 搜尋
	const searchModalBtn = () => {
		//取得搜尋文字
		let searchModalInputText = searchModalInput.current.value.toUpperCase();

		let modalTrList = document.querySelectorAll('.modalTr');

		if (searchModalInputText !== '') {
			//隱藏全部欄位
			modalTrList.forEach((item) => {
				item.classList.add('d-none');
			});

			// 搜尋每個Tr中的每個Td欄位
			modalTrList.forEach((item) => {
				// console.log(item.children);

				[...item.children].forEach((td) => {
					if (
						td.textContent
							.toUpperCase()
							.includes(searchModalInputText)
					) {
						td.parentElement.classList.remove('d-none');
					}
				});
			});

			//顯示取消搜尋btn
			document
				.querySelector('.cancelSearchModalBtn')
				.classList.remove('d-none');
		}
	};

	// 取消搜尋
	const cancelSearchModalBtn = (i) => {
		// 顯示全部欄位
		document.querySelectorAll('.modalTr').forEach((item) => {
			item.classList.remove('d-none');
		});

		// 清空搜尋欄位
		searchModalInput.current.value = '';

		// 隱藏取消搜尋btn
		document.querySelector('.cancelSearchModalBtn').classList.add('d-none');
	};

	//#endregion

	//#region Modal clickTableRow

	function clickTableRow(e) {
		//點選tr 使checkbox被選取
		let majorCheck =
			e.target.parentElement.children[0].querySelector('input');
		//避免input無法直接點擊
		if (e.nodeName != 'INPUT' && e.nodeName != 'LABEL') {
			majorCheck.checked = !majorCheck.checked;
		}
	}

	//#endregion

	//#region Modal儲存

	function modalSave() {
		toggle();

		// 選取哪些checkbox
		const handleSelectedData = () => {
			const selectedId = [];
			const selectedList = [];

			document
				.querySelectorAll('.modal-content [type=checkbox]')
				.forEach((el) => {
					el.checked && selectedId.push(el.id);
				});
			// console.log(selectedId);

			selectedId.forEach((id) => {
				let item = modalData.find(
					(el) => el[`${btnKeyName}Guid`] == id
				);

				selectedList.push(item);
			});
			// console.log(selectedList);

			return selectedList;
		};

		// 新增卡片
		const handleAddCard = () => {
			let newArray = [...newPurchaseOrderData];
			let selectedList = handleSelectedData();
			console.log(selectedList);

			selectedList.forEach((item, index) => {
				const newCardData = {};
				cardKeyNameList.map((columnName) => {
					newCardData[columnName] = '';

					// 更新或新增欄位

					// 1
					columnName === 'displayOrder' &&
						(newCardData[columnName] =
							newPurchaseOrderData.length + 1 + index);

					// 2
					if (columnName === 'purchaseCategoryCode') {
						if (btnKeyName === 'material') {
							newCardData[columnName] = 'M';
						} else if (btnKeyName === 'expenseClass') {
							newCardData[columnName] = 'E';
						} else if (btnKeyName === 'assets') {
							newCardData[columnName] = 'A';
						}
					}

					// 3
					if (columnName === 'cardName') {
						newCardData[columnName] = item[`${btnKeyName}No`];
					}

					// 4
					if (columnName === 'cardChildName') {
						newCardData[columnName] = item[`name01`];
					}

					if (columnName === 'name') {
						newCardData[columnName] =
							item[`${btnKeyName}No`] + ' ' + item[`name01`];
					}

					// 5
					if (columnName === 'imageUrl') {
						newCardData[columnName] = item[`imageUrl`];
					}

					// 6 第一筆選擇的[交貨日期]，作為後面尚未輸入的筆數的預設值。
					columnName === 'deliverDate' &&
						(newCardData[columnName] =
							newArray[0]?.deliverDate || '');

					// 7
					newCardData[`${btnKeyName}Guid`] =
						item[`${btnKeyName}Guid`];

					// 8 uom
					newCardData[`uomCode`] = item.uomcode;
					newCardData[`uomName01`] = item.uomName;

					// 9 如果是物料，有檢驗
					if (btnKeyName === 'material')
						newCardData['receiveInspection'] =
							item.receiveInspection;

					// 10 修改頁面，新增action屬性
					newCardData.action = 'insert';
				});

				console.log(newCardData);
				newArray.push(newCardData);
			});

			console.log(newArray);
			setNewPurchaseOrderData(newArray);
		};

		handleAddCard();
	}

	//#endregion

	//#region Modal取消(關閉)

	const modalCancel = () => {
		toggle();
	};

	//#endregion

	//#region Modal排序

	let headTitleList = [];
	let bodyTitleList = [];

	if (btnKeyName === 'material') {
		headTitleList = [
			t('lgsPurchaseOrder:dialogCol.code'),
			t('lgsPurchaseOrder:dialogCol.name'),
			t('lgsPurchaseOrder:dialogCol.category'),
		];
		bodyTitleList = ['materialNo', 'name01', 'materialClassName'];
	} else if (btnKeyName === 'assets') {
		headTitleList = [
			t('lgsPurchaseOrder:dialogCol.code'),
			t('lgsPurchaseOrder:dialogCol.name'),
			t('lgsPurchaseOrder:dialogCol.category'),
		];
		bodyTitleList = ['assetsNo', 'name01', 'assetsClassName'];
	}

	const [orderBy, setOrderBy] = useState('asc');
	const [UISort, setUISort] = useState(
		btnKeyName === 'material' ? 'materialNo' : 'assetsNo'
	);
	const { items, requestSort, sortConfig } = useSortableData(
		modalData,
		bodyTitleList[0],
		'ascending'
	);

	const handleSortChange = (newTarget) => {
		const oldTarget = UISort;
		setUISort(newTarget);

		if (oldTarget === newTarget) {
			setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
		} else {
			setOrderBy('asc');
		}

		setModalData(items);
	};

	//#endregion

	return (
		<div>
			{/* 開關button */}
			<BtnNormal2
				className="btn-primary d-inline-block me-2 smallBtn"
				keyName={btnKeyName}
				word={btnWord}
				handleClick={(e) =>
					categoryControl !== 'disabled' &&
					clickCreateModal(e, btnKeyName)
				}
				handleMouseEnter={() => handleMouseEnter(categoryControl)}
				handleMouseLeave={() => handleMouseLeave(categoryControl)}
				specialDisabled={categoryControl === 'disabled' ? true : false}
			>
				{categoryControl === 'disabled' ? (
					<CreateGreyIcon className="me-2" />
				) : (
					<CreateIcon className="me-2" />
				)}
			</BtnNormal2>

			{/* Modal */}
			<Modal
				isOpen={modal}
				toggle={toggle}
				centered={true}
				style={{
					maxWidth: '824px',
					border: 'none',
				}}
			>
				<ModalHeader className="position-relative pt-4 pb-2 ps-4">
					<p
						style={{ fontSize: '20px', color: 'var(--primary)' }}
						className="fw-bold mb-1"
					>
						{btnWord}
					</p>
					<button
						style={{ top: '12px', right: '12px' }}
						type="button"
						className="close position-absolute btn"
						onClick={toggle}
					>
						<Icn_Cancel />
					</button>
				</ModalHeader>
				<ModalBody className="px-6 pt-6 pb-0">
					<form asp-action="Index" method="get">
						<div className="modal-body p-0">
							<div className="wrap mx-2">
								<div className="row">
									<div className="position-relative col-12 col-md-5 p-0 mb-3">
										<SearchModalInput
											type="text"
											name="searchModalInput"
											ref={searchModalInput}
											className="form-control searchModalInput"
											placeholder={t(
												'lgsPurchaseOrder:dialogCol.quickSearch'
											)}
										/>
										<ModalSearchBtn
											type="button"
											className="searchModalBtn position-absolute border-0 btn btn-primaryllt px-3 d-flex align-items-center"
											onClick={() => searchModalBtn()}
										>
											<Icn_Search2 />
										</ModalSearchBtn>
										<a
											className="cancelSearchModalBtn position-absolute d-none"
											style={{
												top: '4px',
												right: '48px',
												cursor: 'pointer',
											}}
											onClick={() =>
												cancelSearchModalBtn()
											}
										>
											<Icn_InputYellowCancel />
										</a>
									</div>
								</div>

								<div
									style={{ height: '440px' }}
									className="row"
								>
									<div
										style={{
											height: '440px',
											overflowY: 'scroll',
										}}
										className="col-12 px-0 border border-primaryllt rounded-xl"
									>
										<table className="table tableModal">
											<ModalThead
												className="table-head position-sticky"
												style={{ top: '0' }}
												items={items}
												requestSort={requestSort}
												sortConfig={sortConfig}
											>
												<tr>
													<ModalTh className="p-0">
														<ModalLabel className="m-0 w-100 text-center">
															<ModalInput
																className="totalCheckBtn d-none"
																type="checkbox"
															/>
															<span></span>
														</ModalLabel>
													</ModalTh>
													{headTitleList?.map(
														(el, index) => {
															return (
																<th
																	key={
																		bodyTitleList[
																			index
																		] +
																		index
																	}
																	onClick={() => {
																		requestSort(
																			bodyTitleList[
																				headTitleList.indexOf(
																					el
																				)
																			]
																		);
																		handleSortChange(
																			bodyTitleList[
																				headTitleList.indexOf(
																					el
																				)
																			]
																		);
																	}}
																>
																	{el}
																	{bodyTitleList[
																		headTitleList.indexOf(
																			el
																		)
																	] ===
																		UISort &&
																		(orderBy ===
																		'desc' ? (
																			<span className="ml-1">
																				▼
																			</span>
																		) : (
																			<span className="ml-1">
																				▲
																			</span>
																		))}
																</th>
															);
														}
													)}
												</tr>
											</ModalThead>
											<tbody>
												{/* 物料 */}
												{items.map((item) => {
													return (
														<tr
															key={
																item[
																	`${btnKeyName}Guid`
																]
															}
															className="modalTr list-item table-item tableData"
															onClick={(e) =>
																clickTableRow(e)
															}
														>
															<td className="text-center p-0">
																<label className="m-0 w-100 px-2">
																	<input
																		type="checkbox"
																		id={
																			item[
																				`${btnKeyName}Guid`
																			]
																		}
																		value={
																			item[
																				`${btnKeyName}Guid`
																			]
																		}
																		className="mt-2"
																	/>
																</label>
															</td>
															<td className="text-start">
																{btnKeyName ===
																'expenseClass'
																	? item[
																			`${btnKeyName}Code`
																	  ]
																	: item[
																			`${btnKeyName}No`
																	  ]}
															</td>
															<td className="text-start">
																{item[`name01`]}
															</td>
															{btnKeyName !==
																'expenseClass' && (
																<td className="text-start">
																	{
																		item[
																			`${btnKeyName}ClassName`
																		]
																	}
																</td>
															)}
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<hr className="border-grey1 mt-5 mb-0" />
						<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
							<button
								type="button"
								className="btn btn-primary mediumBtn mx-1"
								onClick={modalSave}
							>
								{t('button.confirmButton')}
							</button>
							<button
								type="button"
								className="btn btn-outline-primarylllt mediumBtn mx-1"
								onClick={modalCancel}
							>
								{t('button.cancelButton')}
							</button>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</div>
	);
};
