import React, { useEffect, useRef, useState } from 'react';
import Input from '../../../../../../components/FormElement/Input';
import Textarea from '../../../../../../components/FormElement/Textarea';
import ThreeColLayout from '../../../../../../components/ThreeColLayout/ThreeColLayout';
import { useTranslation } from 'react-i18next';

const LgsPurchaseOrder_BaseInfo = ({
	register,
	errors,
	getValues,
	setValue,
	detailsInfo,
}) => {
	const {
		vendorSales,
		vendorSalesPhone,
		shipToPlant,
		shipToSector,
		shipToStaff,
		note,
	} = detailsInfo;

	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);

	function LeftCol1() {
		const [valueShow, setValueShow] = useState('');
		return (
			<>
				<Input
					wrapperClass="mb-3"
					labelText={t('lgsPurchaseOrder:vendorSalesRepresentative')}
					type="text"
					id="vendorSales"
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={vendorSales || ''}
				/>
				<Input
					wrapperClass="mb-3" // 外層class，可用來推下面margin
					labelText={t(
						'lgsPurchaseOrder:vendorSalesRepresentativePhone'
					)} // label文字
					type="tel" // input型態
					id="vendorSalesPhone" // input id&name ; label htmlFor
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={vendorSalesPhone || ''}
				/>
			</>
		);
	}

	function CenterCol1() {
		return (
			<>
				<Input
					wrapperClass="mb-3"
					labelText={t('lgsPurchaseOrder:receivingPlant')}
					type="text"
					id="shipToPlant"
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={shipToPlant || ''}
					onChange={() => {}}
				/>
				<Input
					wrapperClass="mb-3" // 外層class，可用來推下面margin
					labelText={t('lgsPurchaseOrder:receivingDepartment')} // label文字
					type="text" // input型態
					id="shipToSector" // input id&name ; label htmlFor
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={shipToSector || ''}
					onChange={() => {}}
				/>
				<Input
					wrapperClass="mb-3" // 外層class，可用來推下面margin
					labelText={t('lgsPurchaseOrder:receiver')} // label文字
					type="text" // input型態
					id="shipToStaff" // input id&name ; label htmlFor
					placeholder=""
					disabled={true}
					disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register}
					value={shipToStaff || ''}
					onChange={() => {}}
				/>
			</>
		);
	}

	function RightCol1() {
		return (
			<>
				<Textarea
					wrapperClass="mb-3" // 外層class，可用來推下面margin
					labelText={t('lgsPurchaseOrder:annotation')} // label文字
					id="note" // input id&name ; label htmlFor
					rows="4"
					cols="30"
					//   placeholder="資料顯示-靠左"
					disabled={true}
					disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
					register={register} // react-hook-form
					getValues={getValues}
					value={note || ''}
					onChange={(e) => {
						setValue('note', e.target.value);
						const noteValue = getValues('note');
						// console.log(noteValue);
					}}
				/>
			</>
		);
	}

	return (
		<div className="px-3 py-5">
			<ThreeColLayout
				LeftCol={<LeftCol1 />}
				CenterCol={<CenterCol1 />}
				RightCol={<RightCol1 />}
			/>
		</div>
	);
};

export default LgsPurchaseOrder_BaseInfo;
