// #region 程式說明--進銷存憑證編號規則
/*
 	  **進銷存憑證編號規則**
    
      Author：
      2023/11/09: 張子建: 初版
    
      **主要功能**：
    
      1.進銷存憑證編號規則統一管理，分為左中右三欄。
      2.目前憑證包含：
	  	(1)採購收貨單編號規則
		(2)採購退貨單編號規則
		(3)採購收貨檢驗單編號規則
		(4)銷售出貨單編號規則
		(5)銷售退貨單編號規則
    
      **使用者步驟**：
    
      1.使用者左欄選擇一項憑證，中間欄將顯示此憑證的編號規則。。
      2.中間欄選擇一項編號規則，右欄將顯示此編號規則的詳細內容。
      3.使用者可以選擇右欄的編輯按鈕，進入編輯規則內容。
	  4.於編輯規則內容中，可以修改編號規則內容，透過下方按鈕實現儲存、啟用、停用。
    
      **四個功能分區**：
    
      (一)左欄『憑證』
          1.使用者選擇：
              (1)必要條件：
			  	 1.sidebar的"進銷存憑證編號規則"，必須要至少有一項憑證的權限才能進入此頁面
				 2.進入此頁面時，左欄顯示相對應具有權限的憑證。
              (2)非必要條件：
                 無
    
      (二)中間欄『編號規則列表』、(三)右欄『顯示規則內容』
          1.排序
              (1)編號名稱
              (2)編號規則
              (3)狀態
		  2.新增按鈕
			  (1)若左欄無憑證，則無新增按鈕
			  (2)按下"新增"按鈕，右欄顯示新增規則內容，可直接編輯，狀態預設為未啟動
			  (3)可編輯編號名稱與編號規則，其中編號規則透過dialog編輯
			  (4)dialog編輯規則，按下確定後，將更新右欄的1.編號規則 2.連續號起始號碼 3.增量值 4.示例
			  (5)當編輯新增欄位時，中間欄的最下方將顯示一筆新的資料，並且跟著使用者輸入的內容即時更新
			  (6)當按下啟用時，直接啟用並儲存
			  (7)由於新增預設為"未啟用狀態"，因此無停用按鈕
			  (8)當按下"儲存"時，彈出確認視窗，確認是否存檔，或是啟動並且存檔
			  (9)按下"啟動"或確認視窗中的"存檔"或"啟動並存檔"時，中間欄內容即時更新，並且右欄返回此筆編號規則的"檢視"
			  (10)若沒有按下"啟用"或"儲存"，則內容不會被儲存
			  (11)按下"取消"時，右欄關閉消失
          3.檢視編號規則內容
		  	  (1)當按下中間欄內的任一表格條列內容時，右欄顯示詳細內容
			  (2)右欄顯示的內容最上方為狀態
			  	1.顯示"規則"，代表"啟動中"
				2.顯示"停用"，代表"停用中"
				3.顯示"未啟用"、、代表"未啟用"
			  (3)右欄顯示的內容不可編輯
			  (4)按下右欄右上角的編輯按鈕，進入編輯規則內容
          4.編輯編號規則內容
		  	  (1)右上角刪除按鈕:
			    1.當狀態為"未啟用"與"停用"時顯示
				2.按下刪除按鈕時，彈出確認視窗
			  (2)可編輯編號名稱與編號規則，其中編號規則透過dialog編輯
			  (3)dialog編輯規則，按下確定後，將更新右欄的1.編號規則 2.連續號起始號碼 3.增量值 4.示例
			  (4)狀態為"未啟用"與"停用"時，顯示"啟用"按鈕，當按下啟用時，直接啟用並儲存
			  (5)狀態為"啟用"時，顯示"停用"按鈕，當按下停用時，直接停用並儲存
			  (6)當按下"儲存"時，彈出確認視窗，確認是否存檔，或是啟動並且存檔
			  (7)按下"啟動"或"停用"或確認視窗中的"存檔"或"啟動並存檔"時，中間欄內容即時更新，並且右欄返回此筆編號規則的"檢視"
			  (8)若沒有按下"啟用"或"儲存"，則內容不會被儲存
			  (9)按下"取消"時，右欄返回此筆編號規則的"檢視"

		(四)右上角『異動紀錄』
          1.將依照使用者左欄選到的憑證，跳轉頁面顯示該憑證的異動紀錄
*/
// #endregion 程式說明--進銷存憑證編號規則

import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { PageTitleBar } from '../../../components/exports';
import { useNavigate } from 'react-router-dom';
import { DropdownMenuArea } from './DropdownMenuArea';
import { ReactComponent as LogIcon } from '../../../assets/images/icn_Log.svg';
import { ReactComponent as CreateIcon } from '../../../assets/images/icn_Create.svg';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import LgsDocumentNoRule_Edit from './rightCol/LgsDocumentNoRule_Edit';
import LgsDocumentNoRule_Create from './rightCol/LgsDocumentNoRule_Create';
import useHover from '../../../hooks/useHover';
import useSortableData from '../../../hooks/useSortableData';
import LgsDocumentNoRule_Details from './rightCol/LgsDocumentNoRule_Details';

// #region styled-components
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
	display: flex;
`;

const MenuBarList = styled.div`
	height: 630px;
	overflow-y: scroll;
	border-radius: 0;
	border: 1px solid var(--grey1);
`;

// center table
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
`;

const TH = styled.th`
	vertical-align: middle;
	border: none;
`;

const TBody = styled.tbody``;

const TD = styled.td`
	border: none;
`;

const BtnCreate = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	cursor: pointer;
`;

//#endregion

function LgsPurchaseSalesNoRule_Index() {
	const { t } = useTranslation(['common', 'lgsDocumentNoRule']);
	const navigate = useNavigate();

	const [displayColumns, setDisplayColumns] = useState([]);
	const [sortBy, setSortBy] = useState('name desc');
	const guidName = 'noRuleGuid';
	const pageTitle = t('lgsDocumentNoRule:pageTitle');

	const [leftActive, setLeftActive] = useState('');
	const [centerActive, setCenterActive] = useState('');
	const [rightActive, setRightActive] = useState(''); // create,edit,details
	const [rightColInfo, setRightColInfo] = useState({});

	const headTitleList = [
		'#',
		t('lgsDocumentNoRule:name'),
		t('lgsDocumentNoRule:noRule'),
		t('lgsDocumentNoRule:status'),
	];

	const bodyTitleList = ['displayOrder', 'name', 'noRule', 'status'];

	const tableStyles = [
		{
			maxWidth: '56px',
			minWidth: '56px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '208px',
			textAlign: 'left',
		},
		{
			minWidth: '100px',
			textAlign: 'left',
		},
	];

	// 左欄LIST
	const noRuleList = [
		{
			authorized: 'lgsPurchaseOrderGoodsNoRule',
			name: t('lgsDocumentNoRule:lgsPurchaseOrderGoodsNoRule'),
		},
		{
			authorized: 'lgsPurchaseOrderGoodsReturnNoRule',
			name: t('lgsDocumentNoRule:lgsPurchaseOrderGoodsReturnNoRule'),
		},
		{
			authorized: 'lgsPurchaseOrderGoodsInspectNoRule',
			name: t('lgsDocumentNoRule:lgsPurchaseOrderGoodsInspectNoRule'),
		},
		{
			authorized: 'lgsSalesOrderIssueNoRule',
			name: t('lgsDocumentNoRule:lgsSalesOrderIssueNoRule'),
		},
		{
			authorized: 'lgsSalesOrderIssueReturnNoRule',
			name: t('lgsDocumentNoRule:lgsSalesOrderIssueReturnNoRule'),
		},
	];

	// 憑證權限(左欄BTN)
	const [userPermission, setUserPermission] = useState([]);
	const [cookies] = useCookies();
	let authorizedList = noRuleList.map((el) => el.authorized);

	const getUserPermission = async () => {
		await axios
			.get(
				`api/Auth/UserPermission/SideBar?orgType=${cookies._system_organization_type}`
			)
			.then((res) => {
				setUserPermission(res.data.authData);
				// console.log(res.data);

				// 設定左欄預設選取項目
				const commonItems = authorizedList.filter((el) => {
					return res.data.authData.includes(el);
				});
				if (commonItems.length > 0) {
					setLeftActive(commonItems[0]);
					handleLeftColBtnClick(commonItems[0]);
				}
			})
			.catch((err) => console.log(err));
	};

	// middleList
	const [middleIndexList, setMiddleIndexList] = useState([]); // 實際使用的資料
	const [middleOriginIndexList, setMiddleOriginIndexList] = useState([]);

	// 點擊左欄，重新獲取中欄資料
	const handleLeftColBtnClick = async (authorized) => {
		setLeftActive(authorized);
		setCenterActive(''); // 重置中欄
		setRightActive(''); // 隱藏右欄

		// console.log(authorized);
		const indexData = {
			transactionCode: authorized,
		};

		await axios
			.post(`api/Logistics/${authorized}/getList`, indexData)
			.then((res) => {
				console.log(res.data);
				setMiddleIndexList(res.data.indexData);
				setMiddleOriginIndexList(res.data.indexData); // 保留原始資料，作為"新增"時，可參考的對象
			});
	};

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			return data;
		};

		return gettingData();
	}, []);

	// center
	const [hoverRef, isHovered] = useHover();
	const { items, requestSort, sortConfig } = useSortableData(middleIndexList);
	const [orderBy, setOrderBy] = useState('desc');

	const statusSpanObj = {
		0: (
			<span>
				<span
					className="bg-warning d-inline-block me-2"
					style={{
						width: '8px',
						height: '8px',
						borderRadius: '50%',
					}}
				></span>
				<span className="text-grey4">
					{t('common:status.inactive')}
				</span>
			</span>
		),
		1: <span></span>,
		2: (
			<span>
				<span
					className="bg-danger d-inline-block me-2"
					style={{
						width: '8px',
						height: '8px',
						borderRadius: '50%',
					}}
				></span>
				<span className="text-grey4">{t('status.lock')}</span>
			</span>
		),
	};

	const getClassNamesFor = (name) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	// getDetail
	const handleRowClick = async (guid) => {
		console.log('handleRowClick', guid);

		setCenterActive(guid);
		setRightActive('details');

		await axios
			.get(`api/Logistics/${leftActive}/getDetails/${guid}`)
			.then((res) => {
				console.log(res.data);
				setRightColInfo(res.data);
			});
	};

	const handleSortChange = (newTarget) => {
		let newSortBy;
		let newOrderBy;

		const oldTarget = sortBy.split(' ')[0];

		console.log(oldTarget);
		console.log(newTarget);
		console.log(orderBy);

		if (oldTarget === newTarget) {
			newOrderBy = orderBy === 'desc' ? 'asc' : 'desc';
		} else {
			newOrderBy = 'asc';
		}

		console.log(newOrderBy);

		newSortBy = `${newTarget} ${newOrderBy}`;

		setSortBy(newSortBy);
		setOrderBy(newOrderBy);

		requestSort(newTarget);
		console.log(sortConfig);
	};

	// 新增按鈕
	const handleCreateNoRule = () => {
		setRightActive('create');

		// 使用原始資料，讓使用者重複click時，還是原本的資料+一個空白的資料
		setMiddleIndexList([
			...middleOriginIndexList,
			{
				noRule: '3',
				status: '0',
				name: '',
			},
		]);
	};

	useEffect(() => {
		getUserPermission();
	}, []);

	useEffect(() => {
		getLocalStorageData();
		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);

	return (
		<>
			<div id="indexView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={false}
				/>
				<Container>
					<Left></Left>
					<Center></Center>
					<Right>
						<DropdownMenuArea>
							<div onClick={() => navigate(`Log/${leftActive}`)}>
								<LogIcon />
								<span>{t('button.logButton')}</span>
							</div>
						</DropdownMenuArea>
					</Right>
				</Container>

				<fieldset className="indexView">
					<article className="row align-content-start">
						<section className="col-12 px-0 bg-white rounded-xxl">
							<div id="periodIndexContainer">
								<div className="tab-content">
									<div
										className="tab-pane fade show active"
										id="otherPanel"
										role="tabpanel"
										aria-labelledby="otherPanel-tab"
									>
										<div className="p-3">
											<div
												style={{ height: '675px' }}
												className="row mx-0"
											>
												{/* @* 左欄 *@ */}
												<section className="left col-2 ps-0 pe-2">
													<div className="h-100 bg-white">
														<div
															className="authTitleWrapper d-flex justify-content-center"
															style={{
																borderRadius:
																	'.75rem .75rem 0 0',
																backgroundColor:
																	'var(--grey1)',
															}}
														>
															<h5
																className="fontSize1Rem fw-bold text-left text-secondary m-0 "
																style={{
																	fontSize:
																		'40px',
																	lineHeight:
																		'40px',
																}}
															>
																{t(
																	'lgsDocumentNoRule:certificate'
																)}
															</h5>
														</div>

														<MenuBarList className="list-group  menubar-list">
															{noRuleList.map(
																(el) => {
																	return (
																		userPermission.includes(
																			el.authorized
																		) && (
																			<div
																				key={
																					el.authorized
																				}
																				className="list-group-item list-group-item-action py-2 border-0"
																				onClick={() =>
																					handleLeftColBtnClick(
																						el.authorized
																					)
																				}
																				style={{
																					cursor: 'pointer',
																					minHeight:
																						'40px',
																					backgroundColor:
																						leftActive ===
																						el.authorized
																							? 'var(--grey1)'
																							: 'transparent',
																				}}
																			>
																				<div className="d-flex w-100 justify-content-between">
																					<span>
																						{
																							el.name
																						}
																					</span>
																				</div>
																			</div>
																		)
																	);
																}
															)}
														</MenuBarList>
													</div>
												</section>
												{/* @* 中欄 *@ */}
												<div className="center col-6 px-2">
													<div
														style={{
															height: '670px',
															overflowY: 'scroll',
															border: '1px solid var(--grey1)',
															borderRadius:
																'.75rem .75rem 0 0',
														}}
														className="bg-white position-relative"
													>
														<BtnCreate
															className={`position-absolute top-0 end-0 mt-1 me-1 border-0 bg-primary ${
																authorizedList.filter(
																	(el) => {
																		return userPermission.includes(
																			el
																		);
																	}
																).length === 0
																	? 'd-none'
																	: ''
															} `}
															onClick={() =>
																handleCreateNoRule()
															}
														>
															<CreateIcon />
														</BtnCreate>

														<IndexTableContainer>
															<IndexTable
																className="table"
																style={{
																	overflowX:
																		'auto',
																	whiteSpace:
																		'nowrap',
																	borderCollapse:
																		'collapse',
																	borderSpacing:
																		'0',
																	wordWrap:
																		'break-word',
																	wordBreak:
																		'break-all',
																}}
															>
																<THead
																	className="table-head"
																	items={
																		items
																	}
																	requestSort={
																		requestSort
																	}
																	sortConfig={
																		sortConfig
																	}
																>
																	<TR
																		ref={
																			hoverRef
																		}
																		className="d-flex"
																	>
																		{headTitleList?.map(
																			(
																				el
																			) => {
																				return (
																					<TH
																						style={
																							tableStyles[
																								headTitleList.indexOf(
																									el
																								)
																							]
																						}
																						key={`tableHead${headTitleList.indexOf(
																							el
																						)}`}
																						className={`px-2 w-100 cursor-pointer bg-grey1 ${
																							!displayColumns.includes(
																								bodyTitleList[
																									headTitleList.indexOf(
																										el
																									)
																								]
																							)
																								? 'd-none'
																								: 'd-block'
																						} ${
																							bodyTitleList[
																								headTitleList.indexOf(
																									el
																								)
																							]
																						} ${getClassNamesFor(
																							bodyTitleList[
																								headTitleList.indexOf(
																									el
																								)
																							]
																						)}`}
																						onClick={() => {
																							// requestSort(
																							//     bodyTitleList[
																							//         headTitleList.indexOf(el)
																							//     ]
																							// );
																							bodyTitleList[
																								headTitleList.indexOf(
																									el
																								)
																							] !==
																								'displayOrder' &&
																								handleSortChange(
																									bodyTitleList[
																										headTitleList.indexOf(
																											el
																										)
																									]
																								);
																						}}
																					>
																						{
																							el
																						}
																						{bodyTitleList[
																							headTitleList.indexOf(
																								el
																							)
																						] ===
																							sortBy.split(
																								' '
																							)[0] &&
																							(orderBy ===
																							'desc' ? (
																								<span className="ml-1">
																									▼
																								</span>
																							) : (
																								<span className="ml-1">
																									▲
																								</span>
																							))}
																					</TH>
																				);
																			}
																		)}
																	</TR>
																</THead>
																<TBody>
																	{items?.map(
																		(
																			data,
																			index
																		) => (
																			<TR
																				className="list-item table-item d-flex"
																				id={
																					data[
																						guidName
																					]
																				}
																				key={
																					data[
																						guidName
																					]
																				}
																				onClick={(
																					e
																				) =>
																					handleRowClick(
																						data[
																							guidName
																						]
																					)
																				}
																			>
																				{bodyTitleList.map(
																					(
																						el
																					) => {
																						if (
																							el ===
																							'status'
																						)
																							return (
																								<TD
																									style={
																										tableStyles[
																											bodyTitleList.indexOf(
																												el
																											)
																										]
																									}
																									className={`p-2 w-100 ${
																										!displayColumns.includes(
																											el
																										)
																											? 'd-none'
																											: 'd-block'
																									} ${
																										centerActive ===
																										data[
																											guidName
																										]
																											? ' bg-grey1'
																											: ''
																									}`}
																									key={`${el}${data[guidName]}`}
																								>
																									{
																										statusSpanObj[
																											data[
																												el
																											]
																										]
																									}
																								</TD>
																							);
																						return (
																							<TD
																								style={{
																									...tableStyles[
																										bodyTitleList.indexOf(
																											el
																										)
																									],
																									overflow:
																										'hidden',
																								}}
																								// style={
																								//     tableStyles[
																								//         bodyTitleList.indexOf(el)
																								//     ]
																								// }
																								className={`p-2 w-100 ${
																									!displayColumns.includes(
																										el
																									)
																										? 'd-none'
																										: 'd-block'
																								} ${
																									centerActive ===
																									data[
																										guidName
																									]
																										? ' bg-grey1'
																										: ''
																								}`}
																								key={`${el}${data[guidName]}`}
																							>
																								{el !==
																								'displayOrder'
																									? data[
																											el
																									  ]
																									: index +
																									  1}
																							</TD>
																						);
																					}
																				)}
																			</TR>
																		)
																	)}
																</TBody>
															</IndexTable>
														</IndexTableContainer>
													</div>
												</div>

												<div className="right col-4 ps-2 pe-0">
													{rightActive ===
													'create' ? (
														<LgsDocumentNoRule_Create
															leftActive={
																leftActive
															}
															handleLeftColBtnClick={
																handleLeftColBtnClick
															}
															setMiddleIndexList={
																setMiddleIndexList
															}
															middleOriginIndexList={
																middleOriginIndexList
															}
														/>
													) : rightActive ===
													  'details' ? (
														<LgsDocumentNoRule_Details
															rightColInfo={
																rightColInfo
															}
															setRightActive={
																setRightActive
															}
														/>
													) : rightActive ===
													  'edit' ? (
														<LgsDocumentNoRule_Edit
															leftActive={
																leftActive
															}
															rightColInfo={
																rightColInfo
															}
															setRightActive={
																setRightActive
															}
															handleLeftColBtnClick={
																handleLeftColBtnClick
															}
															handleCenterRowClick={
																handleRowClick
															}
														/>
													) : null}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</article>
				</fieldset>
			</div>
		</>
	);
}

export default LgsPurchaseSalesNoRule_Index;
