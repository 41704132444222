import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useContext,
} from "react";
import styled from "styled-components";
import axios from "axios";
import FunctionActionButtonBar from "../../../components/FunctionActionButtonBar/FunctionActionButtonBar";
import { PageTitleBar } from "../LgsPurchaseOrder/exports";
import { BtnNormal2 } from "../../../components/Buttons/FunctionRenderButtons/UniversalBTN";
import FormControlInput from "../../../components/Inputs/FormControlInput";
import InputWithDialog from "../../../components/Inputs/InputWithDialog";
import TextareaElement from "../../../components/Inputs/TextareaElement";
import dayjs from "dayjs";
import NumberingRuleDialog from "./NumberingRuleDialog";
import StatusBar from "./StatusBar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import {
  convertFromNumberingRuleToRuleForUser,
  getRulePreview,
  periodFormatDict,
} from "./NumberingRule";
import { ReactComponent as EditIcon } from "../../../assets/images/icn_Edit.svg";
import { ReactComponent as ActivateIcon } from "../../../assets/images/Activate-icon.svg";
import { SearchBtnSmall } from "../../../components/Buttons/SearchBtnSmall";
import { DropdownMenuArea } from "./DropdownMenuArea";
import { ReactComponent as LogIcon } from "../../../assets/images/icn_Log.svg";
import showAlert from "../../../utils/swal";
import { DialogConfirm } from "./DialogConfirm";
import { ReactComponent as Icn_Trash } from "../../../assets/images/icn_Trash.svg";
import { ReactComponent as Icn_Lock } from "../../../assets/images/icn_Lock.svg";
import { useTranslation } from "react-i18next";
import { Context } from "../../../App";

// #region styled-components
const LabelNormal = styled.label`
  font-size: 12px;
  color: var(--grey4);
  margin-bottom: 6px;
`;
const Wrapper = styled.div`
  min-height: 720px;

  border-radius: 0 0 0.75rem 0.75rem;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const Left = styled.div`
  display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
  display: flex;
`;
const Textarea = styled.div`
  border: 1px solid var(--grey2);
  border-radius: 4px;
  padding: 8px;
  min-height: 88px;
  resize: none;
  &:focus-visible {
    outline: 0;
  }
  background-color: #efefef4d;
  /* width: 600px;
    height: 160px; */
`;

const DeleteButton = styled(BtnNormal2)`
  &:hover {
    #Group_6119 {
      path,
      rect {
        fill: white;
      }
    }
  }
`;
//#endregion

function LgsSalesOrderNoRule_Details() {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [view, setView] = useState("details");
  const pageTitle = t("lgsSalesOrderNoRule:pageTitle");
  const permission = localStorage.getItem("Permission_LgsSalesOrderNoRule");
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  //const { noRuleGuid } = useLocation().pathname.split("/").pop();
  const { pathname } = useLocation();
  const guidIndex = pathname.lastIndexOf("/") + 1;
  const noRuleGuid = pathname.substring(guidIndex);
  console.log(noRuleGuid);

  // numbering rule 的參數
  const [data, setData] = useState({
    // 非 numbering rule 組成參數
    noRuleGUid: "",
    status: "",
    rowStamp: "",

    // numbering rule 組成參數
    prefix: "",
    period: "",
    separator: "",
    suffix: "",
    length: "",
    startNo: "",
    incremental: "",
    length: 0,
  });

  // 真正的 numbering rule
  const [noRule, setNoRule] = useState("");
  // 給使用者看的 numbering rule
  const [ruleForUser, setRuleForUser] = useState("");
  // numbering rule 的預期結果示範
  const [preview, setPreview] = useState([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);

  const handleCreatePurchaseOrderNumberingRule = () => {};

  const handleCancelCreate = () => {};

  const handleActivate = () => {
    const updateContent = t("common:log.activate", {
      value: data.name,
    });

    axios
      .put(`api/Logistics/LgsSalesOrderNoRule/activate`, {
        noRuleGuid: data.noRuleGuid,
        name: data.name,
        noRule: data.noRule,
        startNo: data.startNo,
        incremental: data.incremental,
        status: "1",
        rowStamp: data.rowStamp,
        updateContent,
      })
      .then((res) => navigate(-1));
  };

  const handleInActivate = () => {
    const updateContent = t("common:log.lock", {
      value: data.name,
    });

    console.log(data);

    axios
      .put(`api/Logistics/LgsSalesOrderNoRule/activate`, {
        noRuleGuid: data.noRuleGuid,
        name: data.name,
        noRule: data.noRule,
        startNo: data.startNo,
        incremental: data.incremental,
        status: "2",
        rowStamp: data.rowStamp,
        updateContent,
      })
      .then((res) => navigate(-1));
  };

  const handleDelete = () => {
    const updateContent = t("common:log.delete", {
      value: data.name,
    });

    axios
      .put(`api/Logistics/LgsSalesOrderNoRule/activate`, {
        noRuleGuid: data.noRuleGuid,
        name: data.name,
        noRule: data.noRule,
        startNo: data.startNo,
        incremental: data.incremental,
        status: "4",
        rowStamp: data.rowStamp,
        updateContent,
      })
      .then((res) => navigate(-1));
  };

  const handleDialogConfirm = (
    dialogNoRule,
    dialogData,
    dialogStartNo,
    dialogIncrement
  ) => {
    console.log(dialogNoRule, dialogData, dialogStartNo, dialogIncrement);
    setData({
      ...data,
      ...dialogData,
      startNo: dialogStartNo,
      incremental: dialogIncrement,
    });

    setNoRule(dialogNoRule);
  };

  const handleSubmit = () => {
    console.log(data);

    const { name, noRuleGuid, startNo, incremental, status, rowStamp } = data;
    axios
      .put(`api/Logistics/LgsSalesOrderNoRule/update`, {
        name,
        noRule,
        noRuleGuid,
        startNo,
        incremental,
        status,
        rowStamp,
      })
      .then((res) => navigate(-1));
  };

  // 取得使用者資料
  useEffect(() => {
    axios(`api/Logistics/LgsSalesOrderNoRule/getDetails/${noRuleGuid}`).then(
      (res) => {
        const {
          // displayOrder, // 沒有用到
          incremental,
          name,
          noRule,
          noRuleGuid,
          rowStamp,
          startNo,
          status,
        } = res.data;
        console.log(res.data);
        // 存放 numbering rule data
        setData({
          ...data,
          noRuleGuid: noRuleGuid,
          noRule: noRule,
          status: status,
          rowStamp: rowStamp,
          startNo: startNo,
          name: name,
          incremental: incremental,
        });

        // 存放 numbering rule(db)
        setNoRule(noRule);
      }
    );
  }, []);

  // 計算 numbering rule(UI) & 產生 preview
  useEffect(() => {
    // 把 numbering rule(db) 拆解
    const { prefix, suffix, timeSeparator, format, content, period, length } =
      convertFromNumberingRuleToRuleForUser(noRule, data.startNo);

    console.log(prefix, suffix, timeSeparator, format, content, period, length);
    // 把拆解後的參數, 更新到 data 裡
    setData({
      ...data,
      prefix: prefix,
      suffix: suffix,
      timeSeparator: timeSeparator,
      period: period,
      length: length,
      format: format,
    });

    const noRuleForUser = `${prefix}${format}${timeSeparator}${content}${suffix}`;
    setRuleForUser(noRuleForUser);
    console.log(noRuleForUser);

    const rule = {
      prefix,
      timeSeparator,
      format: periodFormatDict[period],
      startNo: data.startNo,
      incremental: data.incremental,
      suffix,
    };

    // 產生 preview
    const preview = getRulePreview(rule);
    setPreview(preview);
  }, [noRule]);

  return (
    <>
      <div id="detailsView">
        <PageTitleBar
          pageTitle={pageTitle}
          previousPageButton={true}
          backTo={-1}
          // isDialogConfirm={true}
          // handleDialogConfirm={handleDialogConfirm}
        />
        {/* DialogConfirm element */}
        <DialogConfirm
          t={t}
          isOpen={isActivateDialogOpen}
          setIsOpen={setIsActivateDialogOpen}
          handleClick={() => handleActivate()}
          message={t("common:dialog.activeConfirm")}
        />
        <DialogConfirm
          t={t}
          isOpen={isInActivateDialogOpen}
          setIsOpen={setIsInActivateDialogOpen}
          handleClick={() => handleInActivate()}
          message={t("common:dialog.lockConfirm")}
        />
        <DialogConfirm
          t={t}
          isOpen={isDeleteDialogOpen}
          setIsOpen={setIsDeleteDialogOpen}
          handleClick={() => handleDelete()}
          message={t("common:dialog.deleteConfirm")}
        />
        <Container>
          <Left>
            <BtnNormal2
              className="btn-primary smallBtn"
              handleClick={() => navigate(`../Edit/${noRuleGuid}`)}
              word={t("common:button.editButton")}
            >
              <EditIcon className="me-2" />
            </BtnNormal2>
            {data?.status !== "1" && (
              <BtnNormal2
                className="btn-primary smallBtn"
                handleClick={() => setIsActivateDialogOpen(true)}
                word={t("common:button.activateButton")}
              >
                <ActivateIcon className="me-2" />
              </BtnNormal2>
            )}
          </Left>
          <Center></Center>
          <Right>
            {/* 當 status = 0 時，顯示"刪除"按鈕 */}
            {/* {data.status === '0' && (
                            <DeleteButton
                                className="layout-title-item btn btn-outline-danger d-flex justify-content-center align-items-center smallSquareBtn me-0"
                                handleClick={() => setIsDeleteDialogOpen(true)}
                            >
                                <Icn_Trash />
                            </DeleteButton>
                        )} */}
            <DropdownMenuArea permission={data?.permission}>
              {data?.status === "1"
                ? [
                    <div
                      key="log"
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => navigate(`../Log/${noRuleGuid}`)}
                    >
                      <LogIcon />
                      <span>{t("common:button.logButton")}</span>
                    </div>,
                    <div
                      key="inactive"
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => setIsInActivateDialogOpen(true)}
                    >
                      <Icn_Lock />
                      <span>{t("common:button.lockButton")}</span>
                    </div>,
                  ]
                : [
                    <div
                      key="log"
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => navigate(`../Log/${noRuleGuid}`)}
                    >
                      <LogIcon />
                      <span>{t("common:button.logButton")}</span>
                    </div>,
                    <div
                      key="delete"
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => setIsDeleteDialogOpen(true)}
                    >
                      <Icn_Trash />
                      <span>{t("common:button.deleteButton")}</span>
                    </div>,
                  ]}
            </DropdownMenuArea>
          </Right>
        </Container>
        <div className="position-relative">
          <StatusBar status={data?.status} />
          <Wrapper className="bg-whit d-flex flex-column p-4 position-relative bg-white">
            <div className="col-12">
              <div className="row"></div>
              <div className="col-lg-4 px-4 pr-xl-6">
                <FormControlInput
                  id="name"
                  labelText={t("lgsSalesOrderNoRule:name")}
                  value={data.name}
                  onChange={(e) => {
                    setData({
                      ...data,
                      name: e.target.value,
                    });
                  }}
                  disabled={true}
                  disabledCssType={1}
                />
                <FormControlInput
                  id="noRule"
                  labelText={t("lgsSalesOrderNoRule:noRule")}
                  value={ruleForUser}
                  onChange={(e) => {
                    setData({
                      ...data,
                      name: e.target.value,
                    });
                  }}
                  disabled={true}
                  disabledCssType={1}
                >
                  <div
                    style={{
                      width: "306px",
                      height: "32px",
                      position: "absolute",
                      // background: 'red',
                      top: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() => setModalOpen(true)}
                  ></div>
                </FormControlInput>
                {/* <InputWithDialog
                                    id="noRule"
                                    labelText="編號規則"
                                    value={ruleForUser}
                                    onChange={(e) => {}}
                                    disabled={true}
                                    disabledCssType={0}
                                    handleDialogShow={() => {
                                        setModalOpen(true);
                                    }}
                                /> */}
                <FormControlInput
                  id="startNo"
                  labelText={t("lgsSalesOrderNoRule:startNo")}
                  value={data?.startNo}
                  onChange={(e) => {
                    setData({
                      ...data,
                      startNo: e.target.value,
                    });
                  }}
                  disabled={true}
                  disabledCssType={1}
                />
                <FormControlInput
                  id="incremental"
                  labelText={t("lgsSalesOrderNoRule:incremental")}
                  value={data.incremental}
                  onChange={(e) => {
                    setData({
                      ...data,
                      incremental: e.target.value,
                    });
                  }}
                  disabled={true}
                  disabledCssType={1}
                />
                <LabelNormal>{t("lgsSalesOrderNoRule:preview")}</LabelNormal>
                <Textarea disabled>
                  <div className="mb-1">{preview[0]}</div>
                  <div className="mb-1">{preview[1]}</div>
                  <div className="mb-1">{preview[2]}</div>
                </Textarea>
              </div>
              <div className="col-lg-4 px-4 pr-xl-5"></div>
              <div className="col-lg-4 px-4 pr-xl-6"></div>
            </div>
          </Wrapper>
        </div>
      </div>
      <NumberingRuleDialog
        modalOpen={modalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
        data={{
          ...data,
          prefix: data.prefix?.replace(new RegExp('"', "g"), ""),
          suffix: data.suffix?.replace(new RegExp('"', "g"), ""),
        }}
        setData={setData}
        noRule={noRule}
        preview={preview}
        setPreview={setPreview}
        handleSubmit={handleDialogConfirm}
        view={view}
        t={t}
      />
    </>
  );
}

export default LgsSalesOrderNoRule_Details;
