import React from 'react';
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Icn_Cancel } from '../../../assets/images/icn_Cancel.svg';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// #region styled-components
const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	width: 100%;
	height: 100%;
`;

const ModalDialog = styled.div`
	max-width: 448px !important;
	min-height: calc(100% - 10.5rem);
`;

// #endregion

export const DialogConfirm = ({
	isOpen,
	setIsOpen,
	backTo,
	data,
	fetchData,
}) => {
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);
	const [selectedList, setSelectedList] = useState();

	console.log(data);
	/**
	 * Modal儲存
	 **/
	function modalSave() {
		const handleSelectedData = () => {
			const selectedList = [];
			let item = {};
			item.employeeGuid = data.employeeGuid;
			item.status = '1';
			selectedList.push(item);
			return selectedList;
		};

		console.log(selectedList);
		let aa = handleSelectedData();
		console.log(aa);

		axios
			.post(
				`api/GeneralAccount/GlaAccountant/delete`,
				JSON.stringify(aa),
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			.then(() => fetchData());

		handleClose();
	}
	/**
	 * Modal cancel close
	 **/
	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			{isOpen && (
				<ModalWrapper onClick={handleClose}>
					<ModalDialog
						className="modal-dialog d-flex align-items-center"
						onClick={(e) => e.stopPropagation()}
					>
						<div
							className="modal-content border-0 bg-white"
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
								borderRadius: '12px',
								backgroundColor: 'white',
							}}
						>
							<div className="modal-header border-0 px-4 pt-5 pb-4">
								<h5 className="w-100 text-center text-danger fw-bold fontSize1Rem mb-0">
									{/* {t(
										'lgsPurchaseOrder:dialogCol.confirmLeavePurchaseOrderPage'
									)} */}
									{'確定刪除會計人員' +
										':' +
										data.employeeName}
									？
								</h5>
								<button
									style={{ top: '12px', right: '12px' }}
									type="button"
									className="btn close position-absolute "
									data-dismiss="modal"
									aria-label="Close"
									onClick={handleClose}
								>
									<Icn_Cancel />
								</button>
							</div>

							<div className="modal-body text-center px-11 py-0">
								<h6 className="mb-2 fontSize875Rem">
									{/* {t('lgsPurchaseOrder:dialogCol.reminder')}！ */}
								</h6>
								<h6 className="mb-0 fontSize875Rem">
									{/* {t(
										'lgsPurchaseOrder:dialogCol.dataWillNotBeSavedAfterLeavingPage'
									)} */}
								</h6>
							</div>

							<div className="modal-footer justify-content-center border-0 py-5">
								<button
									type="button"
									className="btn btn-primary mediumBtn mx-2"
									style={{
										margin: '0 20px',
										width: '120px',
									}}
									onClick={modalSave}
								>
									{t('button.confirmButton')}
								</button>

								<button
									type="button"
									className="btn btn-outline-primarylllt mediumBtn mx-2"
									onClick={handleClose}
								>
									{t('button.cancelButton')}
								</button>
							</div>
						</div>
					</ModalDialog>
				</ModalWrapper>
			)}
		</>
	);
};
