import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import ThreeColLayout from '../../../../../components/ThreeColLayout/ThreeColLayout';
import { DialogCol } from '../DialogCol';
import { useTranslation } from 'react-i18next';

//#region styled-components
const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

// -----DialogCol------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

const TopInfo = ({
	pageData,
	setPageData,
	dialogBtnKeyName,
	setDialogBtnKeyName,
	isOpen,
	setIsOpen,
	modalData,
	setModalData,
	backEndData,
}) => {
	// i18n
	const { t } = useTranslation(['common', 'lgsPurchaseOrderGoodsInvoice']);

	const handleInput = (inputName) => {
		let newPageData = { ...pageData };
		newPageData.goodsItemInfo[inputName] = event.target.value;
		newPageData.goodsItemInfo[`${inputName}Warning`] = ''; // 清空錯誤訊息

		setPageData(newPageData);
		console.log('pageData', newPageData);
	};

	function LeftCol1() {
		//#region Dialog Col

		// DialogCol input 黃色按鈕取消
		const handleCancelClick = () => {
			const newData = { ...pageData };
			newData.goodsItemInfo.employeeGuid = '';
			newData.goodsItemInfo.employeeName = '';
			console.log(newData);

			setPageData(newData);
		};

		// DialogCol Modal open
		const handleOpen = (btnKeyName) => {
			setDialogBtnKeyName(btnKeyName);
			setModalData(backEndData.employeeList);
			setIsOpen(true);
		};

		//#endregion

		return (
			<>
				<div className="row">
					<div className="col-lg-7">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`postingDate`}>
								{t('lgsPurchaseOrderGoodsInvoice:postingDate')}
							</LabelNormal>
							<InputElement
								type="date"
								id={`postingDate`}
								className=""
								defaultValue={
									pageData.goodsItemInfo.postingDate
								}
								onChange={() => handleInput('postingDate')}
							/>
							<span className="text-danger ms-1 my-1">
								{pageData.goodsItemInfo.postingDateWarning}
							</span>
						</div>
					</div>

					<div className="col-lg-5 ps-0">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`currencyName`}>
								{t('lgsPurchaseOrderGoodsInvoice:currencyName')}
							</LabelNormal>
							<InputElement
								type="text"
								id={`currencyName`}
								className="mb-3"
								disabled
								defaultValue={
									pageData.goodsItemInfo.currencyName
								}
							/>
						</div>
					</div>
				</div>

				<div className="d-flex flex-column">
					<LabelNormal htmlFor={`staff`}>
						{t('lgsPurchaseOrderGoodsInvoice:receiver')}
					</LabelNormal>
					<DialogWrapper className="row flex-nowrap">
						<InputDialogElementData
							type="text"
							value={pageData.goodsItemInfo.employeeGuid || ''}
							onChange={() => {}}
						/>
						<InputDialogElementView
							style={{
								minWidth: '85px',
								cursor: 'auto',
							}}
							value={pageData.goodsItemInfo.employeeName || ''}
							onChange={() => {}}
						/>

						<CancelInputBtn
							onClick={() => {
								handleCancelClick();
							}}
							isValueExist={pageData.goodsItemInfo.employeeName}
						>
							<Icn_InputYellowCancel />
						</CancelInputBtn>

						<DialogBtn
							style={{
								cursor: 'pointer',
							}}
							onClick={() => handleOpen('employee')}
						>
							<Icn_InputDialog />
						</DialogBtn>
					</DialogWrapper>
					{/* DialogCol element */}
					<DialogCol
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						modalData={modalData}
						setModalData={setModalData}
						btnKeyName={dialogBtnKeyName}
						pageData={pageData}
						setPageData={setPageData}
					/>
				</div>
			</>
		);
	}

	function CenterCol1() {
		return (
			<>
				<div className={`wrapper d-flex flex-column mb-3`}>
					<LabelNormal htmlFor={'note'}>
						{t('lgsPurchaseOrderGoodsInvoice:note2')}
					</LabelNormal>
					<TextareaElement
						//style={elementStyle}
						id={'note'}
						rows={'4'}
						cols={'30'}
						defaultValue={pageData.goodsItemInfo.note}
						onBlur={() => handleInput('note')}
						disabled={false}
					/>
				</div>
			</>
		);
	}

	function RightCol1() {
		return (
			<>
				<div className="d-flex flex-column">
					<LabelNormal htmlFor={`vendor`}>
						{t('lgsPurchaseOrderGoodsInvoice:vendor2')}
					</LabelNormal>
					<InputElement
						type="text"
						id={`vendor`}
						className="mb-3"
						disabled
						defaultValue={pageData.goodsItemInfo.vendorName}
					/>
				</div>
				<div className="row">
					<div className="col-lg-7">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderNo`}>
								{t('lgsPurchaseOrderGoodsInvoice:orderNo')}
							</LabelNormal>
							<InputElement
								type="text"
								id={`orderNo`}
								className="mb-3"
								disabled
								defaultValue={pageData.goodsItemInfo.orderNo}
							/>
						</div>
					</div>

					<div className="col-lg-5 ps-0">
						<div className="d-flex flex-column">
							<LabelNormal htmlFor={`orderDate`}>
								{t('lgsPurchaseOrderGoodsInvoice:orderDate')}
							</LabelNormal>
							<InputElement
								type="date"
								id={`orderDate`}
								className="mb-3"
								disabled
								defaultValue={
									pageData.goodsItemInfo.orderDate?.split(
										'T'
									)[0]
								}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<ThreeColLayout
			LeftCol={<LeftCol1 />}
			CenterCol={<CenterCol1 />}
			RightCol={<RightCol1 />}
		/>
	);
};

export default TopInfo;
