import { useState, useEffect } from 'react';
import useAxios from '../../../../../../../hooks/useAxios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import HistoryOverviewTable from './HistoryOverviewTable';
import { useTranslation } from 'react-i18next';

function LgsPurchaseOrder_HistoryOverview(props) {
	const { t } = useTranslation();

	console.log(props.parentData);
	const purchaseOrderHistoryOverview =
		props.parentData.purchaseOrderHistoryOverview;
	const foreignCurrency = props.parentData.purchaseOrder.foreignCurrency;
	const decimalPlaceTl = props.parentData.purchaseOrder.decimalPlaceTl;
	const {
		accountName,
		bankAccountGuid,
		bankingAccountName,
		employeeGuid,
		employeeName,
		grossAmount,
		foreignGrossAmount,
		note,
		postingDate,
	} = props.parentData.payment;

	const [sortBy, setSortBy] = useState('orderDate');
	const { isLoading, error, sendRequest: axiosHistoryOverview } = useAxios();
	const guid = useLocation().pathname.split('/').pop();

	const [data, setData] = useState({});
	let purchaseOrderStatus = [];
	let headTitleList = [];
	let bodyTitleList = [];
	let tableHeadStyles = [];
	let tableBodyStyles = [];

	// TODO: change it to version 2
	useEffect(() => {
		// const url = `api/Logistics/LgsPurchaseOrder/historyOverview?guid=${guid}`;
		// axiosHistoryOverview({ url }, (res) => {
		// console.log(res);

		setData(purchaseOrderHistoryOverview);
		// });
	}, []);

	if (error) {
		console.log('error !', error);
	}

	const {
		foreignGoodsReceiveAmount,
		foreignInvoiceGrossAmount,
		foreignInvoiceTaxAmount,
		foreignPayableAmount,
		goodsReceiveAmount,
		historyOverviewList,
		invoiceGrossAmount,
		invoiceTaxAmount,
		orderDate,
		payableAmount,
		property,
		totalForeignGoodsReceiveAmount,
		totalForeignInvoiceGrossAmount,
		totalForeignInvoiceTaxAmount,
		totalForeignPayableAmount,
		totalGoodsReceiveAmount,
		totalInvoiceGrossAmount,
		totalInvoiceTaxAmount,
		totalPayableAmount,
	} = data;

	// alter table content with foreignCurrency (是否外幣交易)
	if (foreignCurrency) {
		purchaseOrderStatus = [
			'',
			'orderDate',
			'property',
			'foreignGoodsReceiveAmount',
			'foreignInvoiceTaxAmount',
			'foreignInvoiceGrossAmount',
			'foreignPayableAmount',
			'payableAmount',
		];
		headTitleList = [
			'#',
			t('lgsPurchaseOrderPayment:page2.historyOverview.date'),
			t('lgsPurchaseOrderPayment:page2.historyOverview.nature'),
			t(
				'lgsPurchaseOrderPayment:page2.historyOverview.foreignCurrencyNetAmountReceived'
			),
			t(
				'lgsPurchaseOrderPayment:page2.historyOverview.foreignCurrencyInvoiceTaxAmount'
			),
			t(
				'lgsPurchaseOrderPayment:page2.historyOverview.foreignCurrencyInvoiceTotalAmount'
			),
			t(
				'lgsPurchaseOrderPayment:page2.historyOverview.foreignCurrencyAccountsPayable'
			),
			t(
				'lgsPurchaseOrderPayment:page2.historyOverview.localCurrencyAccountsPayable'
			),
		];
		bodyTitleList = [
			'displayOrder',
			'orderDate',
			'property',
			'foreignGoodsReceiveAmount',
			'foreignInvoiceTaxAmount',
			'foreignInvoiceGrossAmount',
			'foreignPayableAmount',
			'payableAmount',
		];
		tableHeadStyles = [
			{
				width: '56px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '336px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
		];
		tableBodyStyles = [
			{
				width: '56px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'left',
			},
			{
				width: '336px',
				textAlign: 'left',
			},
			{
				width: '136px',
				textAlign: 'right',
			},
			{
				width: '136px',
				textAlign: 'right',
			},
			{
				width: '136px',
				textAlign: 'right',
			},
			{
				width: '136px',
				textAlign: 'right',
			},
			{
				width: '136px',
				textAlign: 'right',
			},
		];
	} else {
		purchaseOrderStatus = [
			'',
			'orderDate',
			'property',
			'goodsReceiveAmount',
			'invoiceTaxAmount',
			'invoiceGrossAmount',
			'payableAmount',
		];
		headTitleList = [
			'#',
			t('lgsPurchaseOrderPayment:page2.historyOverview.date'),
			t('lgsPurchaseOrderPayment:page2.historyOverview.nature'),
			t(
				'lgsPurchaseOrderPayment:page2.historyOverview.netAmountReceived'
			),
			t('lgsPurchaseOrderPayment:page2.historyOverview.invoiceTaxAmount'),
			t(
				'lgsPurchaseOrderPayment:page2.historyOverview.invoiceTotalAmount'
			),
			t('lgsPurchaseOrderPayment:page2.historyOverview.accountsPayable'),
		];
		bodyTitleList = [
			'displayOrder',
			'orderDate',
			'property',
			'goodsReceiveAmount',
			'invoiceTaxAmount',
			'invoiceGrossAmount',
			'payableAmount',
		];
		tableHeadStyles = [
			{
				width: '56px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '336px',
				textAlign: 'center',
			},
			{
				width: '168px',
				textAlign: 'center',
			},
			{
				width: '168px',
				textAlign: 'center',
			},
			{
				width: '168px',
				textAlign: 'center',
			},
			{
				width: '176px',
				textAlign: 'center',
			},
		];
		tableBodyStyles = [
			{
				width: '56px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'left',
			},
			{
				width: '336px',
				textAlign: 'left',
			},
			{
				width: '168px',
				textAlign: 'right',
			},
			{
				width: '168px',
				textAlign: 'right',
			},
			{
				width: '168px',
				textAlign: 'right',
			},
			{
				width: '176px',
				textAlign: 'right',
			},
		];
	}

	// console.log(historyOverviewList);s
	return (
		<>
			{historyOverviewList && (
				<HistoryOverviewTable
					guidName="historyOverviewGuid"
					sortBy={sortBy}
					tableHoverEffect={false}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					purchaseOrderStatus={purchaseOrderStatus}
					indexData={historyOverviewList}
					property={property}
					orderDate={orderDate}
					foreignGoodsReceiveAmount={foreignGoodsReceiveAmount}
					foreignInvoiceGrossAmount={foreignInvoiceGrossAmount}
					foreignInvoiceTaxAmount={foreignInvoiceTaxAmount}
					foreignPayableAmount={foreignPayableAmount}
					goodsReceiveAmount={goodsReceiveAmount}
					invoiceGrossAmount={invoiceGrossAmount}
					invoiceTaxAmount={invoiceTaxAmount}
					payableAmount={payableAmount}
					totalForeignGoodsReceiveAmount={
						totalForeignGoodsReceiveAmount
					}
					totalForeignInvoiceGrossAmount={
						totalForeignInvoiceGrossAmount
					}
					totalForeignInvoiceTaxAmount={totalForeignInvoiceTaxAmount}
					totalForeignPayableAmount={totalForeignPayableAmount}
					totalGoodsReceiveAmount={totalGoodsReceiveAmount}
					totalInvoiceGrossAmount={totalInvoiceGrossAmount}
					totalInvoiceTaxAmount={totalInvoiceTaxAmount}
					totalPayableAmount={totalPayableAmount}
					foreignCurrency={foreignCurrency}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					grossAmount={grossAmount}
					foreignGrossAmount={foreignGrossAmount}
					postingDate={postingDate}
					decimalPlaceTl={decimalPlaceTl}
				/>
			)}
		</>
	);
}

export default LgsPurchaseOrder_HistoryOverview;
