import { useState, useEffect } from 'react';
import { Table } from '../../../../../../components/exports';
import { ReactComponent as Icn_check } from '../../../../../../assets/images/icn_Check-r.svg';
import { useTranslation } from 'react-i18next';
function LgsPurchaseOrderPage4Tab_PurchaseOrderDetails({ purchaseOrderData }) {
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);
	const [tableData, setTableData] = useState([]);
	const [tableFooter, setTableFooter] = useState([]);

	const headTitleList = [
		'#',
		t('lgsPurchaseOrder:type'),
		t('lgsPurchaseOrder:purchaseContent'),
		t('lgsPurchaseOrder:purchaseQuantity'),
		t('lgsPurchaseOrder:unit'),
		t('lgsPurchaseOrder:inspection'),
		t('lgsPurchaseOrder:unitPriceExcludingTax'),
		t('lgsPurchaseOrder:priceDifferencePercentage'),
		t('lgsPurchaseOrder:amountExcludingTax'),
		t('lgsPurchaseOrder:deliveryDate'),
		t('lgsPurchaseOrder:annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'purchaseCategoryCode',
		'purchaseOrderContent',
		'quantityPurchase',
		'uomName01',
		'inspection',
		'netPrice',
		'diffPrice',
		'netAmount',
		'deliverDate',
		'note',
	];

	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '60px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '104px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
	];

	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'left',
		},
		{
			width: '240px',
			textAlign: 'left',
		},
		{
			width: '112px',
			textAlign: 'right',
		},
		{
			width: '80px',
			textAlign: 'left',
		},
		{
			width: '60px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'right',
		},
		{
			width: '80px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '104px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'left',
		},
	];

	// console.log('purchaseOrderData', purchaseOrderData);

	let purchaseOrderDetails = purchaseOrderData.page1.map(
		({
			displayOrder,
			purchaseCategoryCode,
			quantityPurchase,
			uomName01,
			inspection,
			netPrice,
			diffPrice,
			netAmount,
			deliverDate,
			note,
			name,
		}) => ({
			displayOrder,
			purchaseCategoryCode:
				purchaseCategoryCode === 'M'
					? t('lgsPurchaseOrder:material')
					: purchaseCategoryCode === 'E'
					? t('lgsPurchaseOrder:expense')
					: purchaseCategoryCode === 'A'
					? t('lgsPurchaseOrder:asset')
					: '?',
			purchaseOrderContent: name,
			quantityPurchase,
			uomName01,
			inspection: inspection !== '0' ? <Icn_check /> : '',
			netPrice,
			diffPrice,
			netAmount,
			deliverDate,
			note,
		})
	);

	useEffect(() => {
		setTableData(purchaseOrderDetails);
		setTableFooter([
			'',
			'',
			t('lgsPurchaseOrder:totalAmount'),
			'',
			'',
			'',
			'',
			'',
			purchaseOrderData.page0.foreignCurrency
				? purchaseOrderData.topInfoData.foreignNetAmount
				: purchaseOrderData.topInfoData.netAmount,
			'',
			'',
		]);
	}, []);
	console.log('purchaseOrderDetails', purchaseOrderDetails);

	return (
		<>
			{tableData && (
				<Table
					guidName="purchaseCategoryCode"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					tableData={tableData}
					tableFooter={tableFooter}
				></Table>
			)}
		</>
	);
}

export default LgsPurchaseOrderPage4Tab_PurchaseOrderDetails;
