import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import ThreeColLayout from '../../../../../components/ThreeColLayout/ThreeColLayout';
import { DialogCol } from '../DialogCol';
import { useTranslation } from 'react-i18next';

//#region styled-components

const SelectElement = styled.select`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
`;

const TopTable = styled.table`
	font-size: 12px;
	color: #616e83;
	background-color: #f7f8fc;
	width: 100% !important;
	height: 128px !important;
	border: 1px solid var(--grey1) !important;
	border-collapse: separate;
	border-spacing: 0;
	border-radius: 10px;
`;

const TBody = styled.tbody``;

const TR = styled.tr`
	height: 32px;
	border: 2px solid var(--grey1) !important;
`;
const TH1 = styled.th`
	padding: 0px;
	height: 32px;
	width: 23% !important;
	text-align: center !important;
`;
const TH = styled.th`
	padding: 0px;
	height: 32px;
	width: 38.5% !important;
	text-align: center !important;
`;
const TD1 = styled.td`
	padding: 0px;
	height: 32px;
	width: 23% !important;
	border: 1px solid var(--grey1) !important;
	text-align: center !important;
`;
const TD = styled.td`
	padding-right: 8px;
	height: 32px;
	width: 38.5% !important;
	border: 1px solid var(--grey1) !important;

	text-align: right !important;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

const InputElement2 = styled.input`
	font-size: 14px;
	border: 1px solid var(--grey2);
	border-right: none;
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

// -----DialogCol------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

const TopInfo = ({ pageData, CurrencyCode, CurrencyName, DecimalPlaceTl }) => {
	const { t, i18n } = useTranslation();
	const { purchaseOrder, employeeList, bankingAccountList } = pageData;
	// console.log(pageData);

	function LeftCol() {
		//#region Dialog Col

		// DialogCol input 黃色按鈕取消
		const handleCancelClick = () => {
			const newData = { ...pageData };
			newData.goodsItemInfo.employeeGuid = '';
			newData.goodsItemInfo.employeeName = '';
			console.log(newData);

			setPageData(newData);
		};

		//#endregion
		return (
			<>
				<div className="wrapper d-flex flex-column mb-3">
					<LabelNormal>
						{t('lgsPurchaseOrderPaymentReturn:page1.top.vendor')}
					</LabelNormal>
					<div className="input-group">
						<InputElement2
							className="form-control border-grey2"
							placeholder=""
							style={{
								backgroundColor: 'var(--grey1)',
							}}
							disabled={true}
							value={purchaseOrder?.vendorName}
							onChange={() => {}}
							type="text"
							id="vendor"
							name="vendor"
						/>

						<DialogBtn
							style={{
								backgroundColor: '#EFF2F5',
								cursor: 'auto',
							}}
						>
							<Icn_InputDialog />
						</DialogBtn>
					</div>
					<span className="text-danger ms-1 mt-1">
						{/* {purchaseOrder.vendorNameWarning} */}
					</span>
				</div>

				<div className="row">
					<div className="col-lg-7">
						<div className="d-flex flex-column">
							<LabelNormal>
								{t(
									'lgsPurchaseOrderPaymentReturn:page1.top.orderNo'
								)}
							</LabelNormal>
							<InputElement
								type="text"
								id="orderNo"
								className="mb-3"
								defaultValue={purchaseOrder?.orderNo}
								onChange={() => handleInput('orderNo')}
								disabled
							/>
						</div>
					</div>

					<div className="col-lg-5 ps-0">
						<div className="d-flex flex-column">
							<LabelNormal>
								{t(
									'lgsPurchaseOrderPaymentReturn:page1.top.orderDate'
								)}
							</LabelNormal>
							<InputElement
								type="date"
								id="orderDate"
								className="mb-3"
								disabled
								value={
									purchaseOrder?.orderDate
										? purchaseOrder?.orderDate.split('T')[0]
										: ''
								}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	function CenterCol() {
		return (
			<>
				<div className="row">
					<div className="wrapper d-flex flex-column mb-3 col">
						<LabelNormal>
							{t(
								'lgsPurchaseOrderPaymentReturn:page1.top.currency'
							)}
						</LabelNormal>
						<div className="input-group">
							<InputElement2
								type="text"
								id="currency"
								name="currency"
								className="form-control border-grey2"
								placeholder=""
								style={{
									backgroundColor: 'var(--grey1)',
								}}
								disabled={true}
								value={
									purchaseOrder?.currencyCode
										? `${purchaseOrder?.currencyCode} ${purchaseOrder?.currencyName}`
										: ''
								}
								onChange={() => {}}
							/>

							<DialogBtn
								style={{
									backgroundColor: '#EFF2F5',
									cursor: 'auto',
								}}
							>
								<Icn_InputDialog />
							</DialogBtn>
						</div>
						<span className="text-danger ms-1 mt-1">
							{/* {purchaseOrder.vendorNameWarning} */}
						</span>
					</div>
					{purchaseOrder.foreignCurrency ? (
						<div className="d-flex flex-column col-5 ps-0">
							<LabelNormal>
								{t(
									'lgsPurchaseOrderPaymentReturn:page1.bottom.exchangeRate'
								)}
							</LabelNormal>
							<InputElement
								type="number"
								id="exchageRate"
								className="mb-3"
								value={
									pageData?.purchaseOrder?.exchageRate || ''
								}
								min="1"
								onChange={(e) =>
									handleGrossAmountChange(e.target.value)
								}
								disabled
							/>
						</div>
					) : null}
				</div>
				<div className="row">
					<div className="col-lg-7">
						<div className="d-flex flex-column">
							<LabelNormal>
								{t(
									'lgsPurchaseOrderPaymentReturn:page1.top.invoiceTaxCategory'
								)}
							</LabelNormal>
							<InputElement
								type="text"
								id="invoiceTaxCategory"
								className="mb-3"
								value={
									purchaseOrder?.taxCode
										? `${purchaseOrder?.taxCode} ${purchaseOrder?.taxName}`
										: ''
								}
								onChange={() =>
									handleInput('invoiceTaxCategory')
								}
								disabled
							/>
						</div>
					</div>

					<div className="col-lg-5 ps-0">
						<div className="d-flex flex-column">
							<LabelNormal>
								{t(
									'lgsPurchaseOrderPaymentReturn:page1.top.taxRate'
								)}
							</LabelNormal>
							<InputElement
								type="text"
								id="taxRate"
								className="mb-3"
								disabled
								value={purchaseOrder?.taxRate}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}

	function RightCol() {
		const foreignCurrency = purchaseOrder?.foreignCurrency;

		return (
			<>
				<TopTable>
					<TBody>
						<TR>
							<TH1></TH1>
							<TH>
								{purchaseOrder?.currencyCode
									? `${purchaseOrder?.currencyCode} ${purchaseOrder?.currencyName}`
									: ''}
							</TH>
							{foreignCurrency && (
								<TH>
									{purchaseOrder?.localCurrency
										? purchaseOrder?.localCurrency
										: ''}
								</TH>
							)}
						</TR>
						<TR>
							<TD1>
								{t(
									'lgsPurchaseOrderPaymentReturn:page1.top.netAmount'
								)}
							</TD1>
							{foreignCurrency && (
								<TD>
									{purchaseOrder.foreignNetAmount
										? Number(
												purchaseOrder.foreignNetAmount
										  ).toFixed(DecimalPlaceTl)
										: ''}
								</TD>
							)}
							<TD>
								{purchaseOrder.netAmount
									? Number(purchaseOrder.netAmount).toFixed(
											DecimalPlaceTl
									  )
									: ''}
							</TD>
						</TR>
						<TR>
							<TD1>
								{t(
									'lgsPurchaseOrderPaymentReturn:page1.top.taxAmount'
								)}
							</TD1>
							{foreignCurrency && (
								<TD>
									{purchaseOrder.foreignTaxAmount
										? purchaseOrder.foreignTaxAmount.toFixed(
												DecimalPlaceTl
										  )
										: ''}
								</TD>
							)}
							<TD>
								{purchaseOrder.taxAmount
									? Number(purchaseOrder.taxAmount).toFixed(
											DecimalPlaceTl
									  )
									: ''}
							</TD>
						</TR>
						<TR>
							<TD1>
								{t(
									'lgsPurchaseOrderPaymentReturn:page1.top.grossAmount'
								)}
							</TD1>
							{foreignCurrency && (
								<TD>
									{purchaseOrder.foreignGrossAmount
										? Number(
												purchaseOrder.foreignGrossAmount
										  ).toFixed(DecimalPlaceTl)
										: ''}
								</TD>
							)}
							<TD>
								{purchaseOrder.grossAmount
									? Number(purchaseOrder.grossAmount).toFixed(
											DecimalPlaceTl
									  )
									: ''}
							</TD>
						</TR>
						<TR>
							<TD1>
								{t(
									'lgsPurchaseOrderPaymentReturn:page1.top.payableBalance'
								)}
							</TD1>
							{foreignCurrency && (
								<TD>
									{purchaseOrder.foreignPaymentBalance
										? Number(
												purchaseOrder.foreignPaymentBalance
										  ).toFixed(DecimalPlaceTl)
										: ''}
								</TD>
							)}
							<TD>
								{purchaseOrder.paymentBalance
									? Number(
											purchaseOrder.paymentBalance
									  ).toFixed(DecimalPlaceTl)
									: ''}
							</TD>
						</TR>
					</TBody>
				</TopTable>
			</>
		);
	}

	return (
		<ThreeColLayout
			LeftCol={<LeftCol />}
			CenterCol={<CenterCol />}
			RightCol={<RightCol />}
		/>
	);
};

export default TopInfo;
