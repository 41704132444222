import { useState, useEffect, useCallback } from 'react';
import useAxios from '../../../../../../hooks/useAxios';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Table } from '../../../../../../components/exports';

function LgsPurchaseOrder_PaymentHistory(props) {
	// console.log(props);
	const { foreignCurrency } = props.detailsInfo;
	const [sortBy, setSortBy] = useState('postingDate');
	const [displayColumns, setDisplayColumns] = useState([]);
	const [data, setData] = useState({});
	const guid = useLocation().pathname.split('/').pop();
	const { isLoading, error, sendRequest: axiosPayment } = useAxios();

	let headTitleList = [];
	let bodyTitleList = [];
	let tableHeadStyles = [];
	let tableBodyStyles = [];

	useEffect(() => {
		const url = `api/Logistics/LgsPurchaseOrder/purchaseOrderPayment?guid=${guid}`;
		axiosPayment({ url }, (res) => {
			console.log(res.data);
			setData(res.data);
		});
	}, []);

	if (error) {
		console.log('error !', error);
	}

	const {
		purchaseOrderPaymentList,
		totalExchangeDifferenceAmount,
		totalForeignGrossAmount,
		totalGrossAmount,
	} = data;

	if (foreignCurrency) {
		headTitleList = [
			'#',
			'付款日期',
			'付款人員',
			'會計憑證編號',
			'退回 ',
			'幣別',
			'本幣付款金額',
			'外幣付款金額',
			'匯差金額',
			'註記',
		];

		bodyTitleList = [
			'displayOrder',
			'postingDate',
			'staffGuid',
			'documentNo',
			'isReturn',
			'currencyCode',
			'grossAmount',
			'foreignGrossAmount',
			'exchangeDifferenceAmount',
			'note',
		];

		tableHeadStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'center',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'center',
			},
			{
				width: '200px',
				textAlign: 'center',
			},
		];

		tableBodyStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'left',
			},
			{
				width: '136px',
				textAlign: 'left',
			},
			{
				width: '160px',
				textAlign: 'left',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '120px',
				textAlign: 'right',
			},
			{
				width: '120px',
				textAlign: 'right',
			},
			{
				width: '120px',
				textAlign: 'right',
			},
			{
				width: '200px',
				textAlign: 'left',
			},
		];
	} else {
		headTitleList = [
			'#',
			'付款日期',
			'付款人員',
			'會計憑證編號',
			'退回 ',
			'幣別',
			'付款金額',
			'註記',
		];

		bodyTitleList = [
			'displayOrder',
			'postingDate',
			'staffGuid',
			'documentNo',
			'isReturn',
			'currencyCode',
			'grossAmount',
			'note',
		];

		tableHeadStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'center',
			},
			{
				width: '136px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'center',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'center',
			},
			{
				//     width: '120px',
				textAlign: 'center',
			},
		];

		tableBodyStyles = [
			{
				width: '48px',
				textAlign: 'center',
			},
			{
				width: '112px',
				textAlign: 'left',
			},
			{
				width: '136px',
				textAlign: 'left',
			},
			{
				width: '160px',
				textAlign: 'left',
			},
			{
				width: '80px',
				textAlign: 'center',
			},
			{
				width: '96px',
				textAlign: 'center',
			},
			{
				width: '160px',
				textAlign: 'right',
			},
			{
				// width: '120px',
				textAlign: 'right',
			},
		];
	}

	return (
		<>
			{purchaseOrderPaymentList && (
				<Table
					guidName="paymentGuid"
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					tableData={purchaseOrderPaymentList}
					tableHoverEffect={false}
					totalGrossAmount={totalGrossAmount}
					totalForeignGrossAmount={totalForeignGrossAmount}
					totalExchangeDifferenceAmount={
						totalExchangeDifferenceAmount
					}
					tableHeadStyles={tableHeadStyles}
					tableBodyStyles={tableBodyStyles}
					foreignCurrency={foreignCurrency}
				/>
			)}
		</>
	);
}

export default LgsPurchaseOrder_PaymentHistory;
