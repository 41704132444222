import styled from 'styled-components';
import TableFooter from './TableFooter';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
    margin-bottom: 0;
    color: var(--grey4);
    background-color: #fff;
`;

const THead = styled.thead`
    height: 40px;
`;

const TR = styled.tr`
    height: 40px;
    &:hover {
        cursor: ${(hoverEffect) =>
            hoverEffect === 'true' ? 'pointer' : 'default'};
        background-color: ${(hoverEffect) =>
            hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
    }
`;

const TH = styled.th`
    vertical-align: middle;
    border: 1px solid var(--grey1) !important;
    &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    }
`;

const TBody = styled.tbody``;

const TD = styled.td`
    cursor: auto;
    border: 1px solid var(--grey1) !important;
    &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    }
`;

const FootTR = styled.tr`
    border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
    border: none !important;
`;

const NoInfoRow = styled.div`
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 16px;
`;
//#endregion

function SimpleTablePartial(props) {
    // console.log(props);
    const {
        guidName,
        headTitleList,
        bodyTitleList,
        tableData,
        tableHeadStyles,
        tableBodyStyles,
        tableFooter,
    } = props;

    // !!! 好像沒有用的 function => 本意為希望 tableHeader 保持和 UI 排序一致, 但測試中好像不需要也會排序一致?
    // TODO: 暫先註解, 找出 function 使用時機後再處理
    // const preferredOrder = (tableData, bodyTitleList) => {
    //     let newObject = {};
    //     for (let i = 0; i < bodyTitleList.length; i++) {
    //         if (tableData.hasOwnProperty(bodyTitleList[i])) {
    //             newObject[bodyTitleList[i]] = tableData[bodyTitleList[i]];
    //         }
    //     }
    //     return newObject;
    // };

    // preferredOrder(tableData, bodyTitleList);

    return (
        <div className="position-relative">
            <IndexTableContainer className="bg-white d-flex flex-column p-4 position-relative">
                <IndexTable className="table">
                    <THead className="table-head">
                        <TR>
                            {headTitleList?.map((el) => (
                                <TH
                                    key={`tableHead${headTitleList.indexOf(
                                        el
                                    )}`}
                                    style={
                                        tableHeadStyles[
                                            headTitleList.indexOf(el)
                                        ]
                                    }
                                >
                                    {el}
                                </TH>
                            ))}
                        </TR>
                    </THead>
                    <TBody className="">
                        {tableData?.map((data) => (
                            <TR id={data[guidName]} key={data[guidName]}>
                                {bodyTitleList.map((el) => (
                                    <TD
                                        style={
                                            tableBodyStyles[
                                                bodyTitleList.indexOf(el)
                                            ]
                                        }
                                        className="p-2 border"
                                        key={`${el}${data[guidName]}`}
                                    >
                                        {data[el]}
                                    </TD>
                                ))}
                            </TR>
                        ))}
                    </TBody>
                    <TableFooter
                        tableData={tableData}
                        tableFooter={tableFooter}
                    />
                </IndexTable>
                {/* {!tableData.length && <NoInfoRow>(無資料)</NoInfoRow>} */}
            </IndexTableContainer>
        </div>
    );
}

export default SimpleTablePartial;
