import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props) => (props.isActivated ? 0 : '32px')};
`;

export default function StatusBar(props) {
    const { t, i18n } = useTranslation();
    const { status } = props;

    // console.log(status);

    const statusInfoObject = {
        0: {
            color: '#FFB435',
            backgroundColor: '#FFF9E7',
            message: t('common:status.inactive'),
        },
        1: {
            // color: '#31A952',
            // backgroundColor: '#E9FAF0',
            // message: '已啟用',
        },
        2: {
            color: '#EA4132',
            backgroundColor: '#FFF1F1',
            message: t('common:status.lock'),
        },
    };

    return (
        status && (
            <Container
                className={`rounded-t-xxl`}
                style={{
                    color: statusInfoObject[status].color,
                    backgroundColor: statusInfoObject[status].backgroundColor,
                }}
                isActivated={status === '1'}
            >
                {statusInfoObject[status].message}
            </Container>
        )
    );
}
