import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CreateIcon } from '../../assets/images/icn_Create.svg';
import { BtnNormal } from './BtnNormal';
import { useNavigate } from 'react-router-dom';

export const CreateBtnNormal = (props) => {
	// console.log(props);
	const { permission, handleClick, word } = props;
	const disabled = !permission?.includes('ADD');

	const navigate = useNavigate();
	return (
		<BtnNormal
			className={'btn btn-primary text-white'}
			key={'CreateBtnNormal'}
			disabled={disabled}
			onClick={handleClick ? handleClick : () => navigate('Create')}
		>
			<CreateIcon />
			<span>{word}</span>
		</BtnNormal>
	);
};
