import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { PrevBtnSmall } from '../../../../../components/Buttons/PrevBtnSmall';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAxios from '../../../../../hooks/useAxios';
import { ReactComponent as Icn_OpenModal } from '../../../../../assets/images/icn_OpenModal.svg';
import CurrencyModal from './CurrencyModal';
import VendorModal from './VendorModal';
import PlantModal from './PlantModal';
import SectorModal from './SectorModal';
import StaffModal from './StaffModal';
import { useForm } from 'react-hook-form';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as CopyPage } from '../../../../../assets/images/icn_copyPage.svg';

// TODO: change to useAxios
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// #region styled-components

const NumberInputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}

	-webkit-inner-spin-button,
	-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:hover::-webkit-inner-spin-button,
	&:hover::-webkit-outer-spin-button {
		-webkit-appearance: none !important;
		opacity: 0;
	}

	&:focus::-webkit-inner-spin-button,
	&:focus::-webkit-outer-spin-button {
		-webkit-appearance: none !important;
		opacity: 0;
	}
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const InputElement = styled.input`
	font-size: 14px;
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
`;

const SelectElement = styled.select`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}

	/* 下拉箭頭圖案 */
	background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
		linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
	background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
	background-size: 6px 6px, 6px 6px;
	background-repeat: no-repeat;
	-webkit-appearance: none;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const Wrapper = styled.div`
	min-height: 720px;
`;

const NoInfoRow = styled.div`
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	margin-top: 16px;
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

const BtnNormal = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 88px;
	height: 32px;
	margin-right: 8px;
	svg {
		margin-right: 8px;
	}
`;

const DialogBtn = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 12px;
	cursor: pointer;
	height: 32px;
`; // dialog按鈕

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕
//#endregion

function Page0({
	page,
	processSteps,
	pageZeroButtonRender,
	handlePrevPage,
	handleNextPage,
	purchaseOrderData,
	setPurchaseOrderData,
	setPage,
	copyPurchaseOrderData,
	setCopyPurchaseOrderData,
}) {
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);
	//console.log(copyPurchaseOrderData);
	const guid = useLocation().pathname.split('/').pop();
	const [cookies, setCookie] = useCookies();
	//console.log(cookies);

	const [orderTypeList, setOrderTypeList] = useState([]);
	const [currencyList, setCurrencyList] = useState([]);
	const [vendorList, setVendorList] = useState([]);
	const [invoiceTaxRateTypeList, setInvoiceTaxRateTypeList] = useState([]);
	const [plantList, setPlantList] = useState([]);
	const [sectorList, setSectorList] = useState([]);
	const [employeeList, setEmployeeList] = useState([]);
	const [exchangeRate, setExchangeRate] = useState();
	const companyCurrencyCode = cookies._company_currency_code;
	const [data, setData] = useState({
		purchaseOrderGuid: '', //	採購訂單GuidV
		orderTypeCode: '', //	採購訂單類型V
		categoryControl: '', //	採購訂單Control
		orderDate: '', //採購日期V
		currencyShow: '', //幣別顯示
		currencyCode: '', //交易幣別
		currencyName: '', //交易幣別名稱
		decimalPlaceTl: '', //交易幣別小數位數
		foreignCurrency: '', //外幣交易
		exchangeRate: '', //匯率V
		vendorGuid: '', //	廠商Guid
		vendorName: '', //廠商
		inputTaxGuid: '', //發票稅別Guid
		inputTaxCode: '', //發票稅別Code
		inputTaxName: '', //發票稅別Name
		inputTaxRate: '', //發票進項稅率
		orderTypeGuid: '',
		orderTypeCode: '',
		vendorSales: '', //廠商聯絡代表(聯絡人)
		vendorSalesPhone: '', //廠商聯絡代表(聯絡人)電話
		shipToPlantGuid: '', //收貨廠房Guid
		plantName: '', //收貨廠房
		shipToSectorGuid: '', //收貨部門Guid
		sectorName: '', //收貨部門
		shipToStaffGuid: '', //收貨人員Guid
		staffName: '', //收貨人員
		note: '', //註記
	});
	console.log(data);

	// setData({
	//   ...data,
	//   purchaseOrderGuid: copyPurchaseOrderData.purchaseOrderGuid,
	//   // orderTypeCode: '', //	採購訂單類型V
	//   // categoryControl: '', //	採購訂單Control
	//   // orderDate: '', //採購日期V
	//   // currencyShow: '', //幣別顯示
	//   // currencyCode: '', //交易幣別
	//   // currencyName: '', //交易幣別名稱
	//   // decimalPlaceTl: '', //交易幣別小數位數
	//   // foreignCurrency: '', //外幣交易
	//   // exchangeRate: '', //匯率V
	//   // vendorGuid: '', //	廠商Guid
	//   // vendorName: '', //廠商
	//   // inputTaxGuid: '', //發票稅別Guid
	//   // inputTaxCode: '', //發票稅別Code
	//   // inputTaxName: '', //發票稅別Name
	//   // inputTaxRate: '', //發票進項稅率
	//   // vendorSales: '', //廠商聯絡代表(聯絡人)
	//   // vendorSalesPhone: '', //廠商聯絡代表(聯絡人)電話
	//   // shipToPlantGuid: '', //收貨廠房Guid
	//   // plantName: '', //收貨廠房
	//   // shipToSectorGuid: '', //收貨部門Guid
	//   // sectorName: '', //收貨部門
	//   // shipToStaffGuid: '', //收貨人員Guid
	//   // staffName: '', //收貨人員
	//   // note: '', //註記
	//   // vendorSales: e.target.value || '',
	// });

	// let copyData = {
	//   purchaseOrderGuid: copyPurchaseOrderData.purchaseOrderGuid,
	//   categoryControl: copyPurchaseOrderData.categoryControl,
	//   // currencyCode: res.data.purchaseOrder.currencyCode,
	//   // currencyName: res.data.purchaseOrder.currencyName,
	//   // currencyShow: `${res.data.purchaseOrder.currencyCode} ${res.data.purchaseOrder.currencyName}`,
	//   // decimalPlaceTl: res.data.purchaseOrder.decimalPlaceTl,
	//   // exchangeRate: res.data.purchaseOrder.exchageRate,
	//   // foreignCurrency: true,
	//   // inputTaxCode: res.data.purchaseOrder.inputTaxCode,
	//   // inputTaxGuid: res.data.purchaseOrder.inputTaxGuid,
	//   // inputTaxName: res.data.purchaseOrder.inputTaxName,
	//   // inputTaxRate: res.data.purchaseOrder.inputTaxRate,
	//   // note: res.data.purchaseOrder.note,
	//   // orderDate: res.data.purchaseOrder.orderDate.substring(0, 10),
	//   // orderTypeCode: res.data.purchaseOrder.orderTypeCode,
	//   // rowStamp: res.data.purchaseOrder.rowStamp,
	//   // plantName: plantName01,
	//   // sectorName: sectorName01,
	//   // staffName: staffName01,
	//   // vendorGuid: res.data.purchaseOrder.vendorGuid,
	//   // vendorName: res.data.purchaseOrder.vendorName,
	//   // vendorSales: res.data.purchaseOrder.vendorSales,
	//   // vendorSalesPhone: res.data.purchaseOrder.vendorSalesPhone,
	//   // foreignCurrency: res.data.purchaseOrder.foreignCurrency,
	//   // foreignGrossAmount: res.data.purchaseOrder.foreignGrossAmount,
	//   // foreignNetAmount: res.data.purchaseOrder.foreignNetAmount,
	//   // foreignCurrency: res.data.purchaseOrder.foreignCurrency,
	//   // grossAmount: res.data.purchaseOrder.grossAmount,
	// };

	// page1此頁面資料管控
	const [newPurchaseOrderData, setNewPurchaseOrderData] = useState([]);
	//console.log(newPurchaseOrderData);

	// // required必填欄位驗證
	// let array = [
	// 	'orderTypeCode',
	// 	'orderDate',
	// 	'currencyCode',
	// 	'exchangeRate',
	// 	'vendorName',
	// ];

	const handleChangePage1 = () => {
		// 送出前驗證
		const newData = { ...data }; //我的 data
		let falseCount = 0;

		const idKeys = Object.keys(newData);
		//console.log(idKeys);

		let array = [
			'orderTypeCode',
			'orderDate',
			'currencyCode',
			'vendorName',
		];

		newData.foreignCurrency === true && array.push('exchangeRate');

		// required必填欄位驗證
		//console.log('Before loop', newData);

		array.forEach((keyName) => {
			//console.log('Inside loop', keyName, newData[keyName]);
			if (newData[keyName] === '') {
				newData[`${keyName}Warning`] = t('lgsPurchaseOrder:fillData');
				falseCount++;
			}
		});

		console.log('After loop', newData);

		setData(newData);
		console.log(newData);

		console.log(falseCount);
		if (falseCount === 0) return true;
	};

	// 整理為後端需要的資料，存到父層
	const handleChangePage = () => {
		if (handleChangePage1()) {
			setPurchaseOrderData({
				...purchaseOrderData,
				page0: data,
			});
			handleNextPage();
		}
	};

	// input 有值時，清空錯誤訊息
	useEffect(() => {
		const newData = { ...data };

		// array.forEach((el) => {
		//   if (newData['el']) {
		//     newData[`${el}Warning`] = '';
		//     setData(newData);
		//   }

		//   // newData['el'];
		//   console.log(el);
		// });

		if (newData['orderTypeCode']) {
			newData[`orderTypeCodeWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
		if (newData['orderDate']) {
			newData[`orderDateWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
		if (newData['exchangeRate']) {
			newData[`exchangeRateWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
		if (newData['currencyCode']) {
			newData[`currencyCodeWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
		if (newData['vendorName']) {
			newData[`vendorNameWarning`] = ''; // 清空錯誤訊息
			setData(newData);
		}
	}, [
		data.orderTypeCode,
		data.orderDate,
		data.exchangeRate,
		data.currencyCode,
		data.vendorName,
	]);
	//console.log(data);
	// console.log(purchaseOrderData);

	const [currencyTableShow, setCurrencyTableShow] = useState(false);
	const handleCurrencyClose = () => setCurrencyTableShow(false);
	const handleCurrencyShow = () => {
		setCurrencyTableShow(true);
	};

	const [vendorTableShow, setVendorTableShow] = useState(false);
	const handleVendorClose = () => setVendorTableShow(false);
	const handleVendorShow = () => {
		setVendorTableShow(true);
	};

	const [vendor1TableShow, setVendor1TableShow] = useState(false);
	const handleVendor1Close = () => setVendor1TableShow(false);
	const handleVendor1Show = () => {
		setVendor1TableShow(true);
	};

	const [plantTableShow, setPlantTableShow] = useState(false);
	const handlePlantClose = () => setPlantTableShow(false);
	const handlePlantShow = () => {
		setPlantTableShow(true);
	};

	const [sectorTableShow, setSectorTableShow] = useState(false);
	const handleSectorClose = () => setSectorTableShow(false);
	const handleSectorShow = () => {
		setSectorTableShow(true);
	};

	const [staffTableShow, setStaffTableShow] = useState(false);
	const handleStaffClose = () => setStaffTableShow(false);
	const handleStaffShow = () => {
		setStaffTableShow(true);
	};
	const getCreateData = () => {
		axios
			.get(`api/Logistics/LgsPurchaseOrder/getCreateDatapage0`)
			.then((res) => {
				console.log(res.data);
				setOrderTypeList(res.data.orderTypeList);
				setSectorList(res.data.sectorList); //更新 setSectorList 為 res.data.sectorList
				setCurrencyList(res.data.currencyList);
				setVendorList(res.data.vendorList);
				setInvoiceTaxRateTypeList(res.data.invoiceTaxRateTypeList);
				setPlantList(res.data.plantList);
				setEmployeeList(res.data.employeeList);
				invoiceTaxRateTypeList;
			});
	};

	const handleGetExchangeRate = (orderDate, currencyCode) => {
		//console.log(orderDate, currencyCode);
		const url = `api/Logistics/LgsPurchaseOrder/getExchangeRate`;

		// call api (axios)
		axios
			.get(`api/Logistics/LgsPurchaseOrder/getExchangeRate`)
			.then((res) => {
				console.log(res.data);
			});
		// setData.rate ------這邊判斷還沒完成
	};

	// 載入畫面時從複製訂單拿到資料
	useEffect(() => {
		getCopyData();
	}, [copyPurchaseOrderData]);

	// 從API拿到的資料
	useEffect(() => {
		getCreateData();
	}, []);

	const getCopyData = () => {
		//console.log(copyPurchaseOrderData.purchaseOrder);

		let newData = {
			purchaseOrderGuid:
				copyPurchaseOrderData.purchaseOrder?.purchaseOrderGuid || '',
			categoryControl:
				copyPurchaseOrderData.purchaseOrder?.categoryControl || '',
			currencyCode:
				copyPurchaseOrderData.purchaseOrder?.currencyCode || '',
			currencyName:
				copyPurchaseOrderData.purchaseOrder?.currencyName || '',
			currencyShow:
				`${copyPurchaseOrderData.purchaseOrder?.currencyCode} ${copyPurchaseOrderData.purchaseOrder?.currencyName}` ||
				'',
			decimalPlaceTl:
				copyPurchaseOrderData.purchaseOrder?.decimalPlaceTl || '',
			exchangeRate:
				copyPurchaseOrderData.purchaseOrder?.exchageRate || '',
			foreignCurrency:
				copyPurchaseOrderData.purchaseOrder?.foreignCurrency || '',
			inputTaxCode:
				copyPurchaseOrderData.purchaseOrder?.inputTaxCode || '',
			inputTaxGuid:
				copyPurchaseOrderData.purchaseOrder?.inputTaxGuid || '',
			inputTaxName:
				copyPurchaseOrderData.purchaseOrder?.inputTaxName || '',
			inputTaxRate:
				copyPurchaseOrderData.purchaseOrder?.inputTaxRate || '',
			note: copyPurchaseOrderData.purchaseOrder?.note || '',
			orderDate:
				copyPurchaseOrderData.purchaseOrder?.orderDate.substring(
					0,
					10
				) || '',
			orderTypeCode:
				copyPurchaseOrderData.purchaseOrder?.orderTypeCode || '',
			orderTypeGuid:
				copyPurchaseOrderData.purchaseOrder?.orderTypeGuid || '',
			rowStamp: copyPurchaseOrderData.purchaseOrder?.rowStamp || '',
			// plantName: plantName01,
			// sectorName: sectorName01,
			// staffName: staffName01,
			vendorGuid: copyPurchaseOrderData.purchaseOrder?.vendorGuid || '',
			vendorName: copyPurchaseOrderData.purchaseOrder?.vendorName || '',
			vendorSales: copyPurchaseOrderData.purchaseOrder?.vendorSales || '',
			vendorSalesPhone:
				copyPurchaseOrderData.purchaseOrder?.vendorSalesPhone || '',
			foreignCurrency:
				copyPurchaseOrderData.purchaseOrder?.foreignCurrency || '',
			foreignGrossAmount:
				copyPurchaseOrderData.purchaseOrder?.foreignGrossAmount || '',
			foreignNetAmount:
				copyPurchaseOrderData.purchaseOrder?.foreignNetAmount || '',
			foreignCurrency:
				copyPurchaseOrderData.purchaseOrder?.foreignCurrency || '',
			grossAmount: copyPurchaseOrderData.purchaseOrder?.grossAmount || '',
		};
		//console.log('newData', newData);

		Object.keys(copyPurchaseOrderData).length !== 0 &&
			setData({ ...newData });

		// const isEverEnterPage = purchaseOrderData.isEverEnterPage.page0;
		// if (!isEverEnterPage) {
		// 	//setData({ ...newData });

		// 	// 標記是否曾拿取過資料   ....
		// 	let parentData = { ...purchaseOrderData };
		// 	//parentData.isEverEnterPage.page0 = true;

		// 	console.log('parentData', parentData);
		// 	//setPurchaseOrderData(parentData);
		// }

		// console.log('data', data);
	};

	// 判斷 如果 copyPurchaseOrderData 有值，則....未完
	// useEffect(() => {
	// 	const newData = { ...data };
	// 	console.log(copyPurchaseOrderData.purchaseOrder);
	// 	Object.keys(copyPurchaseOrderData).length !== 0 &&
	// 		setData({
	// 			...data,
	// 			purchaseOrderGuid: copyPurchaseOrderData.purchaseOrder?.purchaseOrderGuid || '',
	// 		});

	// 	console.log(data);
	// }, []);

	// 編輯過程，回到此頁後，回復資料。一開始父層為空，所以到這邊會直接清空data，必須做判斷：
	useEffect(() => {
		Object.keys(purchaseOrderData.page0).length !== 0 &&
			setData(purchaseOrderData.page0);
	}, []);

	// Delete it later
	// do it in the inputs of currency & orderDate instead of do it in effect
	useEffect(() => {
		if (data.orderDate && data.currencyShow)
			return handleGetExchangeRate(data.orderDate, data.currencyCode);
	}, [data]);

	return (
		<>
			<Container className="">
				<Left className="">
					{/* page0 */}
					{page === 0 && (
						<BtnNormal2
							className="btn-primary d-inline-block me-2 mediumSmallBtn"
							keyName="advancedSearchCancel"
							word={t('lgsPurchaseOrder:orderCopy')}
							handleClick={() => {
								setPage('copy');
							}}
						>
							<CopyPage />
						</BtnNormal2>
					)}
					{page !== 0 && (
						<PrevBtnSmall
							handleClick={() => {
								// handlePrevPage();
								// handleSubmit(onSubmit)();
							}}
						/>
					)}
					{/* page0 */}
				</Left>

				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>

				<Right className="">
					{page < processSteps.length - 1 ? (
						<NextBtnSmall
							handleClick={() => {
								handleChangePage();

								// handleNextPage();
								// handleSubmit(onSubmit)();
								// handle
							}}
						/>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>

			<div className="position-relative">
				<Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
					{/* <form onSubmit={handleSubmit(onSubmit)}> */}
					<h1
						style={{
							color: '#1278E8',
							fontSize: '20px',
							fontWeight: 'bold',
						}}
					>
						{t('lgsPurchaseOrder:basicInformation')}
					</h1>

					<div className="col-12">
						<div className="row">
							{/* #Col1------------------------------------ */}
							<div className="col-lg-4 px-4 pr-xl-6">
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'orderTypeGuid'}>
										{t(
											'lgsPurchaseOrder:purchaseOrderType'
										)}
									</LabelNormal>
									<SelectElement
										id={'orderTypeGuid'}
										value={data.orderTypeGuid}
										onChange={(e) => {
											const options = e.target.options; //(e) event的 target(這邊指的是select)
											const content =
												options[options.selectedIndex]
													.text; //這邊指的是選到第幾個的文字

											const content2 =
												options[options.selectedIndex];

											console.log(content2);
											const categoryControl =
												orderTypeList[
													options.selectedIndex - 1
												].categoryControl;

											setData({
												...data,
												orderTypeGuid: e.target.value,
												orderTypeCode:
													content2.getAttribute(
														'typeCode'
													),
												orderTypeName: content,
												categoryControl:
													categoryControl,
											});
										}}
									>
										<option>
											{t('lgsPurchaseOrder:selectData')}
										</option>
										{orderTypeList.map((el) => (
											<option
												key={el.typeGuid}
												id={el.typeGuid}
												value={el.typeGuid}
												onChange={() => {}}
												typecode={el.typeCode}
											>
												{el.name01}
											</option>
										))}
									</SelectElement>
									<span className="text-danger ms-1 mt-1">
										{data.orderTypeCodeWarning}
									</span>
								</div>

								{/* <div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'purchaseOrderGuid'}>
										{'採購訂單類型'}
									</LabelNormal>
									<SelectElement
										id={'purchaseOrderGuid'}
										//defaultValue="1"
										value={data.purchaseOrderGuid}
										onChange={(e) => {
											const options = e.target.options; //(e) event的 target(這邊指的是select)
											const content =
												options[options.selectedIndex]
													.text;
											//console.log(content);
											const categoryControl =
												orderTypeList[
													options.selectedIndex - 1
												].categoryControl;

										

											setData({
												...data,
												purchaseOrderGuid: e.target.value,
												orderTypeCode: content,
												categoryControl:
													categoryControl,
											});
										}}
									>
										<option>--- Please select---</option>
										{orderTypeList.map((el) => (
											<option
												key={el.typeGuid}
												id={el.typeGuid}
												value={el.typeGuid}
												onChange={() => {}}
											>
												{el.name01}
											</option>
										))}
									</SelectElement>
									<span className="text-danger ms-1 mt-1">
										{data.orderTypeCodeWarning}
									
									</span>
								</div> */}
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'orderDate'}>
										{t('lgsPurchaseOrder:orderDate')}
									</LabelNormal>
									<InputElement
										type="date"
										id="orderDate"
										value={data.orderDate || ''}
										onChange={(e) => {
											setData({
												...data,
												orderDate: e.target.value,
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
									<span className="text-danger ms-1 mt-1">
										{data.orderDateWarning}
									</span>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal>
										{t('lgsPurchaseOrder:currency')}
									</LabelNormal>
									<div
										className="input-group vendorGuidInput"
										id="currencyCode"
									>
										<InputElement
											className="form-control border-right-0 item-view-VendorGuid border-grey2"
											placeholder={t(
												'lgsPurchaseOrder:selectData'
											)}
											value={data.currencyShow || ''}
											onChange={() => {}}
											//value={`${'data.currencyCode'} ${'data.currencyName'}`}
											type="text"
											id="currencyCode"
											name="currencyCode"
											style={{
												cursor: 'pointer',
												pointerEvents: 'none',
												borderRight: 0,
											}}
										/>
										<DialogBtn
											style={{
												backgroundColor: '#fff',
												cursor: 'pointer',
											}}
											onClick={() => {
												handleCurrencyShow();
											}}
										>
											<Icn_OpenModal />
										</DialogBtn>
									</div>
									<span className="text-danger ms-1 mt-1">
										{data.currencyCodeWarning}
									</span>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'vendorSales'}>
										{t('lgsPurchaseOrder:exchangeRate')}
									</LabelNormal>

									{data.currencyCode ===
									companyCurrencyCode ? (
										<InputElement
											type={'text'}
											id={'exchangeRate'}
											placeholder={t(
												'lgsPurchaseOrder:localCurrencyTransaction'
											)}
											value={''}
											//defaultValue={''}
											onChange={(e) => {
												setData({
													...data,
													exchangeRate:
														e.target.value,
												});
											}}
											disabled={true}
											disabledCssType={2}
										/>
									) : (
										<InputElement
											type={'text'}
											id={'exchangeRate'}
											placeholder={t(
												'lgsPurchaseOrder:dataInput'
											)}
											//defaultValue={defaultValue}
											value={data.exchangeRate || ''}
											onChange={(e) => {
												setData({
													...data,
													exchangeRate:
														e.target.value || '',
												});
											}}
											disabled={false}
											disabledCssType={0}
										/>
									)}
									<span className="text-danger ms-1 mt-1">
										{data.exchangeRateWarning}
									</span>
								</div>
							</div>
							{/* #endCol1------------------------------------ */}
							{/* #Col2------------------------------------ */}
							<div className="col-lg-4 px-4 px-xl-5">
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									{/* //<div className="formArea pb-0"> */}
									{/* <div className="form-group"> */}
									<LabelNormal>
										{t('lgsPurchaseOrder:vendor')}
									</LabelNormal>
									<div
										className="input-group vendorGuidInput"
										id="vendorGuidInput"
									>
										<InputElement
											className="form-control border-right-0 item-view-VendorGuid border-grey2"
											placeholder={t(
												'lgsPurchaseOrder:selectData'
											)}
											value={data.vendorName || ''} //value 抓取 data.vendorName
											onChange={() => {}}
											type="text"
											id="vendorCode"
											name="vendorCode"
											style={{
												cursor: 'pointer',
												pointerEvents: 'none',
												borderRight: 0,
											}}
										/>
										<DialogBtn
											style={{
												backgroundColor: '#fff',
												cursor: 'pointer',
											}}
											onClick={() => {
												handleVendorShow();
											}}
										>
											<Icn_OpenModal />
										</DialogBtn>
									</div>
									<span className="text-danger ms-1 mt-1">
										{data.vendorNameWarning}
									</span>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'inputTaxGuid'}>
										{t(
											'lgsPurchaseOrder:invoiceTaxCategory'
										)}
									</LabelNormal>
									<SelectElement
										id={'inputTaxGuid'}
										value={data.inputTaxGuid || ''}
										onChange={(e) => {
											const options = e.target.options;
											const content =
												options[options.selectedIndex]
													.text;
											const content2 =
												options[options.selectedIndex];
											// console.log(options.selected);

											console.log(content2);
											console.log(
												content2.getAttribute('taxRate')
											); //取 options 中的值的方法

											setData({
												...data,
												inputTaxGuid: e.target.value,
												inputTaxCode: content,
												//----------連動修改 發票稅率
												inputTaxRate:
													content2.getAttribute(
														'taxRate'
													),
											});
										}}
									>
										<option value="" onChange={() => {}}>
											{t('lgsPurchaseOrder:selectData')}
										</option>
										{invoiceTaxRateTypeList.map((el) => (
											<option
												key={el.rateTypeGuid} // 從 API 來的資料為 rateTypeGuid，整理到 data 為 inputTaxGuid
												id={el.rateTypeGuid}
												value={el.rateTypeGuid || ''}
												onChange={() => {}}
												taxrate={el.taxRate}
											>
												{el.name01}
											</option>
										))}
									</SelectElement>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'inputTaxRate'}>
										{t('lgsPurchaseOrder:invoiceTaxRate')}
									</LabelNormal>
									<InputElement
										type={'text'}
										id={'inputTaxRate'}
										value={data.inputTaxRate || ''}
										//defaultValue={'資料輸入'}
										placeholder={t(
											'lgsPurchaseOrder:dataInput'
										)}
										onChange={(e) => {
											setData({
												...data,
												inputTaxRate:
													e.target.value || '',
											});
											//const vendorSalesValue = getValues('vendorSales');
											// console.log(vendorSalesValue);
										}}
										disabled={false}
										disabledCssType={0}
									/>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'vendorSales'}>
										{t(
											'lgsPurchaseOrder:vendorSalesRepresentative'
										)}
									</LabelNormal>
									<InputElement
										type={'text'}
										id={'vendorSales'}
										value={data.vendorSales || ''}
										//defaultValue={defaultValue}
										placeholder={t(
											'lgsPurchaseOrder:dataInput'
										)}
										onChange={(e) => {
											setData({
												...data,
												vendorSales:
													e.target.value || '',
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
								</div>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'vendorSalesPhone'}>
										{t(
											'lgsPurchaseOrder:vendorSalesRepresentativePhone'
										)}
									</LabelNormal>
									<NumberInputElement
										type={'number'}
										id={'vendorSalesPhone'}
										value={data.vendorSalesPhone || ''}
										//defaultValue={defaultValue}
										placeholder={t(
											'lgsPurchaseOrder:dataInput'
										)}
										onChange={(e) => {
											setData({
												...data,
												vendorSalesPhone:
													e.target.value || '',
											});
										}}
										disabled={false}
										disabledCssType={0}
									/>
								</div>
							</div>
							{/* #endCol2------------------------------------ */}
							{/* #Col3------------------------------------ */}
							<div className="col-lg-4 px-4 pl-xl-6">
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal>
										{t('lgsPurchaseOrder:receivingPlant')}
									</LabelNormal>
									<div
										className="input-group"
										id="vendorGuidInput"
									>
										<InputElement
											className="form-control border-right-0 item-view-VendorGuid border-grey2"
											placeholder={t(
												'lgsPurchaseOrder:selectData'
											)}
											value={data.plantName || ''}
											onChange={() => {}}
											type="text"
											id="plantCode"
											name="plantCode"
											style={{
												cursor: 'pointer',
												pointerEvents: 'none',
												borderRight: 0,
											}}
										/>

										<DialogBtn
											style={{
												backgroundColor: '#fff',
												cursor: 'pointer',
											}}
											onClick={() => {
												handlePlantShow();
											}}
										>
											<Icn_OpenModal />
										</DialogBtn>
									</div>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal>
										{t(
											'lgsPurchaseOrder:receivingDepartment'
										)}
									</LabelNormal>
									<div
										className="input-group vendorGuidInput"
										id="vendorGuidInput"
									>
										<InputElement
											className="form-control border-right-0 item-view-VendorGuid border-grey2"
											placeholder={t(
												'lgsPurchaseOrder:selectData'
											)}
											value={data.sectorName || ''}
											onChange={() => {}}
											type="text"
											id="VendorText"
											name="VendorText"
											style={{
												cursor: 'pointer',
												pointerEvents: 'none',
												borderRight: 0,
											}}
										/>

										<DialogBtn
											style={{
												backgroundColor: '#fff',
												cursor: 'pointer',
											}}
											onClick={() => {
												handleSectorShow();
											}}
										>
											<Icn_OpenModal />
										</DialogBtn>
									</div>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal>
										{t('lgsPurchaseOrder:receiver')}
									</LabelNormal>
									<div
										className="input-group vendorGuidInput"
										id="vendorGuidInput"
									>
										<InputElement
											className="form-control border-right-0 item-view-VendorGuid border-grey2"
											placeholder={t(
												'lgsPurchaseOrder:selectData'
											)}
											value={data.staffName || ''}
											onChange={() => {}}
											type="text"
											id="VendorText"
											name="VendorText"
											style={{
												cursor: 'pointer',
												pointerEvents: 'none',
												borderRight: 0,
											}}
										/>
										<DialogBtn
											style={{
												backgroundColor: '#fff',
												cursor: 'pointer',
											}}
											onClick={() => {
												handleStaffShow();
											}}
										>
											<Icn_OpenModal />
										</DialogBtn>
									</div>
								</div>

								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal htmlFor={'note'}>
										{t('lgsPurchaseOrder:annotation')}
									</LabelNormal>
									<TextareaElement
										//style={elementStyle}
										id={'note'}
										rows={'4'}
										cols={'30'}
										value={data.note || ''}
										//defaultValue={defaultValue}

										onChange={(e) => {
											setData({
												...data,
												note: e.target.value || '',
											});
											//const noteValue = getValues('note');
											// console.log(noteValue);
										}}
										disabled={false}
										disabledCssType={0}
									/>
								</div>
							</div>
							{/* #endCol3------------------------------------ */}
						</div>
					</div>
					{/* </form> */}
				</Wrapper>
			</div>

			<CurrencyModal
				modalOpen={currencyTableShow}
				handleClose={handleCurrencyClose}
				currencyList={currencyList}
				data={data}
				setData={setData}
				companyCurrencyCode={companyCurrencyCode}
			/>

			<VendorModal
				modalOpen={vendorTableShow}
				handleClose={handleVendorClose}
				vendorList={vendorList}
				invoiceTaxRateTypeList={invoiceTaxRateTypeList}
				data={data}
				setData={setData}
			/>
			<PlantModal
				modalOpen={plantTableShow}
				handleClose={handlePlantClose}
				plantList={plantList}
				data={data}
				setData={setData}
			/>
			<SectorModal
				modalOpen={sectorTableShow}
				handleClose={handleSectorClose}
				sectorList={sectorList}
				data={data}
				setData={setData}
			/>
			<StaffModal
				modalOpen={staffTableShow}
				handleClose={handleStaffClose}
				employeeList={employeeList}
				data={data}
				setData={setData}
			/>
		</>
	);
}
export default Page0;
