import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { ReactComponent as Icn_check } from '../../../../../../assets/images/icn_Check-r.svg';
import { DialogInvoice } from '../../DialogInvoice';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody`
	& tr:hover .deleteBtn {
		display: block !important;
	}

	& tr:hover,
	& tr:hover td {
		cursor: pointer;
		background-color: var(--grey0);
	}
`;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-left: none !important;
		border-right: none !important;
	}

	position: relative;
	padding: 0;
`;

const TFoot = styled.tfoot`
	border: none;
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;

//#endregion

function InvoiceTable(props) {
	//#region state initiation
	const { t } = useTranslation(['common', 'lgsPurchaseOrderInvoice']); // i18n

	const {
		backEndData,
		parentData,
		pageData,
		setPageData,
		headTitleList,
		bodyTitleList,
		tableHeadStyles,
		tableBodyStyles,
		tableBodyData,
		setTableBodyData,
		decimalPlaceTl,
	} = props;
	// console.log(props);

	console.log(decimalPlaceTl);

	const [cookies] = useCookies();
	const CountryCode = cookies._company_country_code;

	//#endregion

	//#region function

	// 計算總額
	const evlAllAmount = (newPageData) => {
		let totalAmount = 0;
		let netAmount = 0;
		let taxAmount = 0;
		newPageData.invoiceItemList.forEach((item) => {
			totalAmount += Number(item.totalAmount);
			netAmount += Number(item.netAmount);
			taxAmount += Number(item.taxAmount);
		});
		setPageData({
			...newPageData,
			allAmount: {
				totalAmount: totalAmount,
				netAmount: netAmount,
				taxAmount: taxAmount,
			},
		});
	};
	//#endregion

	// #region dialogInvoice

	// state
	const [firstDialogName, setFirstDialogName] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState([]);

	const originInvoiceDialogData = {
		createEditDetail: '',
		invoiceTempGuid: '',
		governmentInvoice: '',
		invoiceReturn: '',
		vatControl: '',
		invoiceDate: '',
		invoiceCode: '',
		invoiceNo: '',
		vatInvoiceFormat: '',
		vatInvoiceFormatViewName: '',
		vatInvoiceTaxType: '',
		vatRate: '',
		vatInputTaxable: '',
		netAmount: '',
		taxAmount: '',
		totalAmount: '',
		currencyName: '',
		purchaserCustomerViewName: '',
		purchaserCustomerGuid: '',
		purchaserTaxID: '',
		purchaserTaxName: '',
		purchaserAddTel: '',
		purchaserBankAccount: '',
		salesVendorViewName: '',
		salesVendorGuid: '',
		salesTaxID: '',
		salesTaxName: '',
		salesAddTel: '',
		salesBankAccount: '',
	};

	const [invoiceDialogData, setInvoiceDialogData] = useState(
		originInvoiceDialogData
	);

	const editInvoiceDialog = (
		governmentInvoice,
		invoiceTempGuid,
		countryCode
	) => {
		if (
			governmentInvoice === '1' &&
			(countryCode === 'TW' || countryCode === 'CN')
		) {
			setFirstDialogName('govInvoiceBtn');
		} else if (governmentInvoice === '0') {
			setFirstDialogName('otherInvoiceBtn');
		}

		const newInvoiceDialogData = pageData.invoiceItemList.find(
			(el) => el.invoiceTempGuid === invoiceTempGuid
		);
		console.log(newInvoiceDialogData);
		newInvoiceDialogData.createEditDetail = 'edit';

		setInvoiceDialogData({
			...newInvoiceDialogData,
		});

		setIsOpen(true);
	};

	console.log('firstDialogName', firstDialogName);
	console.log('pageData', pageData);

	//#endregion

	return (
		<div className="position-relative">
			{/* DialogInvoice element */}
			<DialogInvoice
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				modalData={modalData}
				setModalData={setModalData}
				firstDialogName={firstDialogName}
				backEndData={backEndData}
				parentData={parentData}
				pageData={pageData}
				setPageData={setPageData}
				invoiceDialogData={invoiceDialogData}
				setInvoiceDialogData={setInvoiceDialogData}
				tableBodyData={tableBodyData}
				setTableBodyData={setTableBodyData}
				evlAllAmount={evlAllAmount}
				decimalPlaceTl={decimalPlaceTl}
				isDetailView={true}
			/>

			<h6 className="fw-bold fontSize75Rem text-end mt-2 mb-3 me-3">
				{t('lgsPurchaseOrderInvoice:currency')}：
				{parentData.page0.purchaseOrderSelectedList[0].currencyCode +
					' ' +
					parentData.page0.purchaseOrderSelectedList[0].currencyName}
			</h6>
			<div
				style={{ minHeight: '65vh' }}
				className="bg-white d-flex flex-column position-relative"
			>
				<IndexTable className="table">
					<THead className="table-head">
						<TR>
							{headTitleList?.map((el) => (
								<TH
									key={`tableHead${headTitleList?.indexOf(
										el
									)}`}
									style={
										tableHeadStyles[
											headTitleList?.indexOf(el)
										]
									}
								>
									{el}
								</TH>
							))}
						</TR>
					</THead>
					<TBody className="">
						{tableBodyData?.map((data, rowIndex) => (
							<TR
								key={data.invoiceTempGuid}
								id={data.invoiceTempGuid}
								onClick={() =>
									editInvoiceDialog(
										data.governmentInvoice,
										data.invoiceTempGuid,
										CountryCode
									)
								}
							>
								{bodyTitleList?.map((el, inputIndex) => {
									// displayOrder
									if (el === 'displayOrder')
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`p-2 border`}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<div>{rowIndex + 1}</div>
											</TD>
										);
									// governmentInvoice || invoiceReturn
									if (
										el === 'governmentInvoice' ||
										el === 'invoiceReturn'
									)
										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`p-2 border`}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												{data[el] === '1' && (
													<Icn_check />
												)}
											</TD>
										);

									// others
									return (
										<TD
											style={
												tableBodyStyles[
													bodyTitleList?.indexOf(el)
												]
											}
											className={`p-2 border`}
											key={`${bodyTitleList?.indexOf(
												el
											)}`}
										>
											<div>{data[el]}</div>
										</TD>
									);
								})}
							</TR>
						))}
					</TBody>
					{tableBodyData && tableBodyData.length === 0 ? (
						<tfoot>
							<tr>
								<td colSpan="10" className="p-0 border-0">
									<div className="pt-3 text-secondary fw-bold text-center">
										({t('lgsPurchaseOrderInvoice:nodata')})
									</div>
								</td>
							</tr>
						</tfoot>
					) : (
						<TFoot>
							<FootTR
								id="total"
								key="total"
								hoverEffect={false}
								style={{ fontWeight: 'bold' }}
							>
								<FootTD className="p-2 text-end"></FootTD>
								<FootTD className="p-2 text-end"></FootTD>
								<FootTD className="p-2 text-end">
									{t('lgsPurchaseOrderInvoice:total')}
								</FootTD>
								<FootTD className="p-2 text-end"></FootTD>
								<FootTD className="p-2 text-end"></FootTD>
								<FootTD className="p-2 text-end"></FootTD>
								<FootTD className="p-2 text-end"></FootTD>
								<FootTD className="p-2 text-end">
									{pageData.allAmount
										? pageData.allAmount.totalAmount.toFixed(
												decimalPlaceTl
										  )
										: 0}
								</FootTD>
								<FootTD className="p-2 text-end">
									{pageData.allAmount
										? pageData.allAmount.netAmount.toFixed(
												decimalPlaceTl
										  )
										: 0}
								</FootTD>
								<FootTD className="p-2 text-end">
									{pageData.allAmount
										? pageData.allAmount.taxAmount.toFixed(
												decimalPlaceTl
										  )
										: 0}
								</FootTD>
							</FootTR>
						</TFoot>
					)}
				</IndexTable>
			</div>
		</div>
	);
}

export default InvoiceTable;
