import styled from 'styled-components';
import TableFooter from './TableFooter';
import SimpleTablePartial from './SimpleTablePartial';
import IndexTablePartial from './IndexTablePartial';
import InvoiceHistoryTable from '../../pages/Logistics/LgsPurchaseOrder/Details/TabContent/InvoiceHistory/InvoiceHistoryTable';
import PageCopyPartial from '../../pages/Logistics/LgsPurchaseOrder/Create/Pages/PageCopy/PageCopyPartial';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const FootTR = styled.tr`
	border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
	border: none !important;
`;

const NoInfoRow = styled.div`
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	margin-top: 16px;
	margin-bottom: 16px;
`;
//#endregion

function Table(props) {
	const { t } = useTranslation();

	// console.log(props);
	const {
		// all tables need
		guidName,
		headTitleList,
		bodyTitleList,
		tableData,
		tableHeadStyles,
		tableBodyStyles,
		tableFooter,

		// for index table
		sortBy,
		displayColumns,
		setDisplayColumns,
		paginationData,
		setPaginationData,
		fetchData,
		fetchedData,
		tableStyles,
		columnMustShow,
		clickSelection,
		table,
	} = props;

	const tableList = {
		SimpleTablePartial,
		IndexTablePartial,
	};

	const Table = () => {
		if (table === 'pageCopyTable') return <PageCopyPartial {...props} />;
		if (sortBy && displayColumns) {
			return <IndexTablePartial {...props} />;
		} else if (guidName === 'invoiceGuid') {
			return <InvoiceHistoryTable {...props} />;
		} else {
			return <SimpleTablePartial {...props} />;
		}
	};

	return (
		<div className="position-relative">
			<IndexTableContainer className="bg-white d-flex flex-column position-relative">
				<Table />
				{!tableData.length && (
					<NoInfoRow>({t('common:noData')})</NoInfoRow>
				)}
			</IndexTableContainer>
		</div>
	);
}

export default Table;
