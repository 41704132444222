import { useCallback, useEffect, useState } from 'react';
// import { purchaseOrderAdvancedSearchData as searchContentList } from '../../../utils/advancedSearchData';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { SearchBtnSmall } from '../../../components/Buttons/SearchBtnSmall';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../../components/Modals/AdvancedSearchResultTag';
import LogTable from '../../../components/Table/LogTable';
import { PageTitleBar } from '../../../components/exports';
import useAuth from '../../../hooks/useAuth';
import useAxios from '../../../hooks/useAxios';

// #region styled-components
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
    display: flex;
`;

//#endregion

function LgsServiceClass_Log() {
    const { t, i18n } = useTranslation();
    // 使用 useAuth Hook 取得 isLogin 和 checkAuth
    const { isLogin, checkAuth } = useAuth();
    const navigate = useNavigate();
    const { logGuid } = useParams();

    // console.log(logGuid);

    // t('log.transactionTime'),
    // t('log.name'),
    // t('log.updateContent'),
    // t('log.user'),
    const searchContentList = [
        {
            column: 'transactionTime',
            name: t('log.transactionTime'),
            type: 'date',
            tableColumn: 'transactionTime',
            searchFromDataBase: 'transactionTime',
        },
        {
            column: 'name',
            name: t('log.name'),
            type: 'text',
            tableColumn: 'name',
            searchFromDataBase: 'name',
        },
        {
            column: 'updateContent',
            name: t('log.updateContent'),
            type: 'text',
            tableColumn: 'updateContent',
            searchFromDataBase: 'updateContent',
        },
        {
            column: 'user',
            name: t('log.user'),
            type: 'text',
            tableColumn: 'user',
            searchFromDataBase: 'user',
        },
    ];

    // 在組件生命週期方法中呼叫 checkAuth 函式檢查登入狀態
    useEffect(() => {
        checkAuth();
    }, []);

    const [fetchedData, setFetchedData] = useState([]);
    const [displayColumns, setDisplayColumns] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const sortBy = 'transactionTime';
    const guidName = 'classGuid';
    const pageTitle = t('lgsServiceClass:pageTitle');
    const { isLoading, error, sendRequest: axiosIndexData } = useAxios();

    const [advancedSearchShow, setAdvancedSearchShow] = useState(false);

    if (error) {
        console.log('error !', error);
    }

    const headTitleList = [
        '#',
        '編號名稱',
        '編號規則',
        '連續號起始號碼',
        '增量值',
        '備註',
    ];

    const bodyTitleList = [
        'displayOrder',
        'name',
        'noRule',
        'startNo',
        'incremental',
        'status',
    ];

    const tableStyles = [
        {
            minWidth: '72px',
            textAlign: 'center',
        },
        {
            minWidth: '200px',
            textAlign: 'left',
        },
        {
            minWidth: '320px',
            textAlign: 'left',
        },
        {
            minWidth: '200px',
            textAlign: 'left',
        },
        {
            minWidth: '160px',
            textAlign: 'left',
        },
        {
            minWidth: '208px',
            textAlign: 'left',
        },
    ];

    const columnMustShow = ['displayOrder', 'orderDate', 'orderNo'];

    // 進階搜尋 一進去的第一筆的模板
    const advancedSearchDataTemp = {
        searchLogic: '',
        column: searchContentList[0].column,
        searchOperator: '==',
        searchValue: '',
        searchContent: '',
        inputWarningContent: '',
    };

    // 進階搜尋實際資料
    const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
    // 進階搜尋畫面資料
    const [displayList, setDisplayList] = useState([]);

    // Advanced Search

    //todo: default sort col => orderDate

    const fetchData = (
        advancedSearchDataList = null,
        pageSize = 5,
        sort = sortBy,
        pageNumber = 1
    ) => {
        const guidString = logGuid ? `?guid=${logGuid}` : '';
        const url = `api/Logistics/LgsServiceCLass/getLog${guidString}`;

        // 如果只有一個查詢條件, 並不需要 AND/OR, 所以要把 searchLogic 清空
        if (advancedSearchDataList && advancedSearchDataList.length === 1) {
            advancedSearchDataList[0].searchLogic = '';
        }

        axiosIndexData(
            {
                url,
                method: 'post',
                data: {
                    sort,
                    pageSize,
                    page: pageNumber,
                    advancedSearchDataList,
                },
            },
            (res) => {
                console.log(res);
                const { indexData, permission, numberOfData, totalPage } = res;

                setPaginationData({
                    ...paginationData,
                    numberOfData: numberOfData,
                    totalPage: totalPage,
                    pageNumber: pageNumber,
                });

                setFetchedData({
                    indexData,
                    permission,
                    paginationData,
                });

                function isArrayFn(obj) {
                    // 包成函式
                    if (typeof Array.isArray === 'function') {
                        return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
                    } else {
                        // 否則就使用 toString 方法
                        return (
                            Object.prototype.toString.call(obj) ===
                            '[object Array]'
                        );
                    }
                }

                if (isArrayFn(advancedSearchDataList)) {
                    // 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
                    // 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
                    advancedSearchDataList.map((el) => {
                        searchContentList.map((item) => {
                            // console.log(item);
                            if (el.searchFilter === item.tableColumn) {
                                el.searchFilter = item.searchFromDataBase;
                            }
                        });
                    });

                    setDisplayList(advancedSearchDataList);
                    setAdvancedSearchDataList([...advancedSearchDataList]);
                }

                handleAdvancedSearchModalClose();
                localStorage.setItem(
                    'Permission_LgsPurchaseOrderNoRule',
                    permission
                );
            }
        );
    };

    const getLocalStorageData = useCallback(() => {
        const gettingData = async () => {
            const data = await (JSON.parse(
                localStorage.getItem(`${guidName}DisplayColumns`)
            ) || bodyTitleList);

            setDisplayColumns(data);

            return data;
        };

        return gettingData();
    }, []);

    const handleAdvancedSearchModalClose = () => setAdvancedSearchShow(false);
    const handleAdvancedSearchModalShow = () => {
        setAdvancedSearchShow(true);
    };

    const deleteAllFilters = () => {
        setDisplayList([]);
        setAdvancedSearchDataList([]);
        fetchData();
    };

    const deleteFilter = (targetIndex) => {
        console.log('deleteFilter index', targetIndex);
        console.log(advancedSearchDataList[targetIndex]);
        console.log(displayList[targetIndex]);

        const newAdvancedSearchDataList = advancedSearchDataList.filter(
            (_, index) => index !== targetIndex
        );
        const newDisplayList = displayList.filter(
            (_, index) => index !== targetIndex
        );
        setDisplayList(newDisplayList);
        setAdvancedSearchDataList(newAdvancedSearchDataList);
        fetchData(
            newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
        );
    };

    const handleGetLog = () => {
        axios(`api/Logistics/LgsPurchaseOrderType/getLog`).then(({ data }) => {
            console.log(data);
            setLogBodyData(data);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    // useEffect(() => {
    //     // fetchIndexInfo();
    //     getLocalStorageData();
    //     fetchData();

    //     return () => {
    //         // cleanup
    //     };
    // }, [getLocalStorageData]);

    console.log(fetchedData);
    // console.log(sortBy);

    return (
        <>
            <div id="indexView">
                <PageTitleBar
                    pageTitle={pageTitle}
                    previousPageButton={true}
                    backTo={-1}
                />
                <Container>
                    <Left></Left>
                    <Center></Center>
                    <Right>
                        <SearchBtnSmall
                            permission={fetchedData?.permission}
                            handleClick={handleAdvancedSearchModalShow}
                        />
                    </Right>
                </Container>
                <div className="position-relative firstRowMinHeight bg-white d-flex flex-column p-4 position-relative overflow-auto">
                    <AdvancedSearchResultTag
                        advancedSearchDataList={advancedSearchDataList}
                        deleteAllFilters={deleteAllFilters}
                        deleteFilter={deleteFilter}
                    />
                    {fetchedData?.indexData && (
                        <LogTable
                            logBodyData={fetchedData?.indexData}
                            guidName={guidName}
                            headTitleList={headTitleList}
                            bodyTitleList={bodyTitleList}
                            tableData={fetchedData?.indexData}
                            indexData={fetchedData?.indexData}
                            sortBy={sortBy}
                            displayColumns={displayColumns}
                            setDisplayColumns={setDisplayColumns}
                            paginationData={paginationData}
                            setPaginationData={setPaginationData}
                            fetchData={fetchData}
                            fetchedData={fetchedData}
                            tableStyles={tableStyles}
                            columnMustShow={columnMustShow}
                            rowClick={true}
                            advancedSearchDataList={advancedSearchDataList}
                        ></LogTable>
                    )}
                </div>
            </div>

            {advancedSearchShow && (
                <AdvancedSearchModal
                    isOpen={advancedSearchShow}
                    handleClose={handleAdvancedSearchModalClose}
                    headTitleList={headTitleList}
                    bodyTitleList={bodyTitleList}
                    advancedSearchDataList={
                        advancedSearchDataList.length > 0
                            ? advancedSearchDataList
                            : [{ ...advancedSearchDataTemp }]
                    }
                    setAdvancedSearchDataList={setAdvancedSearchDataList}
                    advancedSearchDataTemp={advancedSearchDataTemp}
                    fetchData={fetchData}
                    sortBy={sortBy}
                    setFetchedData={setFetchedData}
                    displayList={
                        displayList.length > 0
                            ? displayList
                            : [{ ...advancedSearchDataTemp }]
                    }
                    setDisplayList={setDisplayList}
                    searchContentList={searchContentList}
                    // getFilterList={getFilterList}
                />
            )}
        </>
    );
}

export default LgsServiceClass_Log;
