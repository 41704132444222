import './styles/app.scss';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { CookiesProvider } from 'react-cookie';
import './i18n';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Suspense fallback={null}>
        <BrowserRouter basename={baseUrl}>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </BrowserRouter>
    </Suspense>
);

registerServiceWorker();
