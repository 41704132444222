import React, { useState } from 'react';
import styled from 'styled-components';

const TabNavLi = styled.li`
    cursor: pointer;
    height: 48px;
    padding-block: 14px;
    border: none;
    border-radius: 0 !important;
    color: #616e83;

    &:nth-of-type(1) {
        border-radius: 0.75rem 0 0 0 !important;
    }

    &:last-of-type {
        border-radius: 0 0.75rem 0 0 !important;
    }

    &:hover {
        color: var(--primary);
        border-bottom: 1px solid var(--primary);
    }

    &.text-primaryNew {
        color: var(--primary) !important;
    }
`;

const Ul = styled.ul`
    border-bottom: 0.1px solid #dee2e6;
    border-radius: 0;
`;

const TabPage = ({
    wrapperStyle,
    liColWidth,
    tabNavList,
    tabContent,
    defaultPage,
}) => {
    // 初始顯示頁面
    const [tabNavIndex, setTabNavIndex] = useState(defaultPage);

    return (
        <div
            className="row bg-white rounded-xxl pb-0 mx-0"
            style={{ ...wrapperStyle }}
        >
            <div className="col-12 px-0">
                <Ul className="tabNav list-group row flex-row px-0 mx-0">
                    {tabNavList?.map((el, index) => {
                        return (
                            <TabNavLi
                                key={index}
                                className={`${
                                    liColWidth ? liColWidth : 'col'
                                } tabNav-item list-group-item text-center fw-bold fontSize1Rem  border-bottom ${
                                    tabNavIndex === index
                                        ? 'text-primaryNew border-primary'
                                        : 'border-0'
                                }`}
                                onClick={(e) => setTabNavIndex(index)}
                            >
                                {el}
                            </TabNavLi>
                        );
                    })}
                </Ul>

                <div className="tabContent">{tabContent[tabNavIndex]}</div>
            </div>
        </div>
    );
};

export default TabPage;
