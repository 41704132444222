import { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { BtnNormal2 } from '../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import FormControlInput from '../../../../components/Inputs/FormControlInput';
import NumberingRuleDialog from '../dialog/NumberingRuleDialog';
import {
	convertFromNumberingRuleToRuleForUser,
	getRulePreview,
	periodFormatDict,
} from '../dialog/NumberingRule';
import { SubmitConfirm } from '../dialog/SubmitConfirm';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icn_OpenModal } from '../../../../assets/images/icn_OpenModal.svg';
import { ReactComponent as ActivateIcon } from '../../../../assets/images/Activate-icon.svg';

// #region styled-components
const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;
const InputElement = styled.input`
	font-size: 14px;
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
`;

const DialogBtn = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 12px;
	cursor: pointer;
	height: 32px;
`;
const Wrapper = styled.div`
	border-radius: 0 0 0.75rem 0.75rem;
`;

const Textarea = styled.div`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
	background-color: #efefef4d;
`;

const NumberingRuleArea = styled.div`
	height: 630px;
	border-radius: 0;
	border: 1px solid var(--grey1);
`;
//#endregion

function LgsDocumentNoRule_Create(props) {
	const { t } = useTranslation(['common', 'lgsDocumentNoRule']);

	const {
		leftActive,
		handleLeftColBtnClick,
		setMiddleIndexList,
		middleOriginIndexList,
	} = props;

	const [modalOpen, setModalOpen] = useState(false);

	// numbering rule 的參數
	const [data, setData] = useState({
		// numbering rule 組成參數
		name: '',
		prefix: '',
		period: '',
		separator: '',
		suffix: '',
		length: '',
		startNo: '001',
		incremental: '1',
		length: 3,
	});

	const [dialogData, setDialogData] = useState({});

	// 真正的 numbering rule
	const [noRule, setNoRule] = useState('3');
	// 給使用者看的 numbering rule
	const [ruleForUser, setRuleForUser] = useState('000');
	// numbering rule 的預期結果示範
	const [preview, setPreview] = useState([]);

	const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
		useState(false);
	const [err, setErr] = useState(false);

	const [tempCreateData, setTempCreateData] = useState({
		status: '0',
		noRule: '3',
	});

	const handleDialogConfirm = (
		dialogNoRule,
		dialogData,
		dialogStartNo,
		dialogIncrement
	) => {
		console.log(dialogNoRule, dialogData, dialogStartNo, dialogIncrement);
		setData({
			...data,
			...dialogData,
			startNo: dialogStartNo,
			incremental: dialogIncrement,
		});

		setNoRule(dialogNoRule);

		// 更新暫存的新增資料
		setTempCreateData({
			...tempCreateData,
			noRule: dialogNoRule,
		});

		setMiddleIndexList([
			...middleOriginIndexList,
			{ ...tempCreateData, noRule: dialogNoRule },
		]);
	};

	const handleSubmit = (status) => {
		const { name, noRuleGuid, startNo, incremental, rowStamp } = data;

		const updateContent = t('log.create', {
			value: name,
		});

		console.log(updateContent);

		axios
			.post(`api/Logistics/${leftActive}/insert`, {
				name,
				noRule,
				noRuleGuid,
				startNo,
				incremental,
				status,
				rowStamp,
				updateContent,
			})
			.then((res) => {
				if (res.status === 200) {
					handleLeftColBtnClick(leftActive);
					setIsSubmitConfirmDialogOpen(false);

					// 清空回復最初的值
					setData({
						name: '',
						prefix: '',
						period: '',
						separator: '',
						suffix: '',
						length: '',
						startNo: '001',
						incremental: '1',
						length: 3,
					});

					setDialogData({
						name: '',
						prefix: '',
						period: '',
						separator: '',
						suffix: '',
						length: '',
						startNo: '001',
						incremental: '1',
						length: 3,
						isPrefix: false,
						isPeriod: false,
						isTimeSeparator: false,
						isSuffix: false,
					});

					setNoRule('3');
				} else {
					alert(res.status);
				}
			});
	};

	const checkInputs = (isActiveSaveDirectly) => {
		// 啟動並存檔，不跳出存檔視窗
		if (data?.name && isActiveSaveDirectly) {
			setErr(false);
			handleSubmit('1');
		} else if (data?.name && !isActiveSaveDirectly) {
			// 跳出存檔視窗
			setErr(false);
			return setIsSubmitConfirmDialogOpen(true);
		} else {
			setErr(true);
		}
	};

	// 計算 numbering rule(UI) & 產生 preview
	useEffect(() => {
		// 把 numbering rule(db) 拆解
		const {
			prefix,
			suffix,
			timeSeparator,
			format,
			content,
			period,
			length,
		} = convertFromNumberingRuleToRuleForUser(noRule, data.startNo);

		// 把拆解後的參數, 更新到 data 裡
		setData({
			...data,
			prefix: prefix,
			suffix: suffix,
			timeSeparator: timeSeparator,
			period: period,
			length: length,
			format: format,
		});

		const noRuleForUser = `${prefix}${format}${timeSeparator}${content}${suffix}`;
		setRuleForUser(noRuleForUser);

		const rule = {
			prefix,
			timeSeparator,
			format: periodFormatDict[period],
			startNo: data.startNo,
			incremental: data.incremental,
			suffix,
		};

		// 產生 preview
		const preview = getRulePreview(rule);
		setPreview(preview);
	}, [noRule, data.startNo, data.incremental]);

	return (
		<>
			<div className="h-100 bg-white">
				<div
					className="authTitleWrapper d-flex justify-content-center bg-warninglt"
					style={{
						borderRadius: '.75rem .75rem 0 0',
					}}
				>
					<h5
						className="fw-bold text-left text-warning m-0 "
						style={{
							fontSize: '14px',
							lineHeight: '40px',
						}}
					>
						未啟用
					</h5>
				</div>
				<NumberingRuleArea>
					<SubmitConfirm
						t={t}
						isOpen={isSubmitConfirmDialogOpen}
						setIsOpen={setIsSubmitConfirmDialogOpen}
						handleClickInactive={() => handleSubmit('0')}
						handleClickActive={() => handleSubmit('1')}
						message={t('dialog.saveConfirm')}
					/>
					<div className="position-relative">
						<Wrapper className="bg-whit d-flex flex-column px-5 py-4 position-relative bg-white">
							<div className="col-12 mb-4">
								<FormControlInput
									id="name"
									labelText={t('lgsDocumentNoRule:name')}
									value={data.name}
									onChange={(e) => {
										setData({
											...data,
											name: e.target.value,
										});

										// 暫存的新增資料
										setTempCreateData({
											...tempCreateData,
											name: e.target.value,
										});

										setMiddleIndexList([
											...middleOriginIndexList,
											{
												...tempCreateData,
												name: e.target.value,
											},
										]);
									}}
									disabled={false}
									disabledCssType={0}
									isErr={err}
									errMsg={t('errMsg.mustFill')}
								/>
								<div
									className={`wrapper d-flex flex-column mb-3`}
								>
									<LabelNormal>
										{t('lgsDocumentNoRule:noRule')}
									</LabelNormal>
									<div
										className="input-group vendorGuidInput"
										id="vendorGuidInput"
									>
										<InputElement
											className="form-control border-right-0 item-view-noRule border-grey2"
											placeholder=""
											value={ruleForUser}
											onChange={() => {}}
											type="text"
											id="noRule"
											name="noRule"
											style={{
												cursor: 'pointer',
												pointerEvents: 'none',
												borderRight: 0,
											}}
										/>

										<DialogBtn
											style={{
												backgroundColor: '#fff',
												cursor: 'pointer',
											}}
											onClick={() => {
												setModalOpen(true);
											}}
										>
											<Icn_OpenModal />
										</DialogBtn>
									</div>
								</div>
								<FormControlInput
									id="startNo"
									labelText={t('lgsDocumentNoRule:startNo')}
									value={data?.startNo}
									onChange={(e) => {
										setData({
											...data,
											startNo: e.target.value,
										});
									}}
									disabled={true}
									disabledCssType={0}
								/>
								<FormControlInput
									id="incremental"
									labelText={t(
										'lgsDocumentNoRule:incremental'
									)}
									value={data.incremental}
									onChange={(e) => {
										setData({
											...data,
											incremental: e.target.value,
										});
									}}
									disabled={true}
									disabledCssType={0}
								/>
								<LabelNormal>
									{t('lgsDocumentNoRule:preview')}
								</LabelNormal>
								<Textarea disabled>
									<div className="mb-1">{preview[0]}</div>
									<div className="mb-1">{preview[1]}</div>
									<div className="mb-1">{preview[2]}</div>
								</Textarea>
							</div>

							<div className="col-12 d-flex">
								<BtnNormal2
									className="btn-primary me-3"
									handleClick={() => checkInputs(true)}
									word="啟用"
									children={<ActivateIcon className="me-2" />}
									elementStyle={{ width: '88px' }}
								></BtnNormal2>
								<BtnNormal2
									className="btn-primary mediumBtn me-3"
									handleClick={() => checkInputs(false)}
								>
									{t('button.saveButton')}
								</BtnNormal2>
								<BtnNormal2
									className="btn-outline-primary mediumBtn me-0"
									handleClick={() =>
										handleLeftColBtnClick(leftActive)
									}
								>
									{t('button.cancelButton')}
								</BtnNormal2>
							</div>
						</Wrapper>
					</div>
				</NumberingRuleArea>
			</div>

			<NumberingRuleDialog
				modalOpen={modalOpen}
				handleClose={() => {
					setModalOpen(false);
				}}
				data={{
					...data,
					prefix: data.prefix?.replace(new RegExp('"', 'g'), ''),
					suffix: data.suffix?.replace(new RegExp('"', 'g'), ''),
				}}
				setData={setData}
				noRule={noRule}
				preview={preview}
				setPreview={setPreview}
				handleSubmit={handleDialogConfirm}
				t={t}
				dialogData={dialogData}
				setDialogData={setDialogData}
			/>
		</>
	);
}

export default LgsDocumentNoRule_Create;
