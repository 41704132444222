import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import { CreateBtnNormal } from '../../../../../components/Buttons/CreateBtnNormal';
import { ReactComponent as CreateIcon } from '../../../../../assets/images/icn_Create.svg';
import { ReactComponent as CreateBlueIcon } from '../../../../../assets/images/icn_Create_Blue.svg';
import { Dialog2 } from './Dialog2';
import { Dialog } from './Dialog';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
	&:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}
`;

const TBody = styled.tbody``;

const Button = styled.div`
	//active-submit dropdownItem d-flex align-items-center pe-2 tex-left px-3 py-2
	display: flex;
	cursor: pointer;
	align-items: center;
	padding: 8px 16px;
	text-align: left;
	min-width: 64px;
	height: 32px;
	justify-content: center;
	svg {
		margin-right: 8px;
	}
`;
const Menu = styled.div`
	position: absolute;
	top: 0px;
	right: 0px;
	will-change: transform;
	padding: 0;
	margin-top: 8px;
	min-width: 64px;
	background-color: #ffffff;
	box-shadow: 2px 4px 12px -1px rgb(61 61 61 / 51%);
	border-radius: 0.25rem;
	color: var(--danger);

	&:hover,
	&:focus {
		color: var(--danger);
	}
`;
const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-right: none !important;
	}

	background-color: ${(prop) =>
		prop.active ? '#fbfcfd !important' : '#fff'};

	position: relative;
	padding: 7px 8px !important;
	max-height: 40px;
`;

const Container = styled.div`
	display: flex;
	justify-content: start;
	margin: 16px 0;
`;

const Input = styled.input`
	padding-inline: 8px;
	border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
	border-radius: 4px;

	/* 去除 type="number" 上下按鈕 */
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	::placeholder {
		color: var(--grey3);
	}

	&:disabled {
		border: none;
		background-color: transparent;
	}
`;

// -----Dialog------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
	height: 25px;
	--bs-gutter-x: none;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 25px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: -1px;
	right: 24px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

function PaymentTermTable(props) {
	//#region state initiation
	const {
		pageData,
		setPageData,
		headTitleList,
		bodyTitleList,
		tableHeadStyles,
		tableBodyStyles,
		tableBodyData,
		setTableBodyData,
		inputProperties,
		backEndData,
		dialogBtnKeyName,
		setDialogBtnKeyName,
		isOpen,
		setIsOpen,
		modalData,
		setModalData,
	} = props;
	// console.log(props);

	//#endregion

	//#region function
	//console.log('pageData', pageData);

	const handleReceiveAll = () => {
		const newData = [...tableBodyData];
		newData.forEach((el) => {
			el.quantityReceive = el.quantityUnreceived;
		});

		setTableBodyData([...newData]);

		const newPageData = { ...pageData };
		newPageData.purchaseOrderItemList = [...newData];
		setPageData({ ...newPageData });
	};
	//console.log('pageData', pageData);

	const handleDoubleClick = (rowIndex) => {
		console.log(rowIndex);
		const newData = [...tableBodyData];
		newData[rowIndex].quantityReceive =
			newData[rowIndex].quantityUnreceived;
		setTableBodyData([...newData]);

		const newPageData = { ...pageData };
		newPageData.purchaseOrderItemList = [...newData];
		setPageData({ ...newPageData });
	};

	const handleEditTable = (e, index) => {
		const newList = [...tableBodyData];
		newList.forEach((el) => delete el?.active);
		newList[index].active = true;

		setTableBodyData(newList);
	};
	//console.log('tableBodyData', tableBodyData);

	// input改變時
	const handleInputChange = (value, rowIndex, inputIndex, toFloat) => {
		console.log(rowIndex);
		const newData = [...tableBodyData];

		if (toFloat) {
			// 如果此input有decimalPlace限制
			// const regex = new RegExp(`^(\\d+(\\.\\d{0,${decimalPlace}})?|)$`); // 只允許數字，並且小數點後最多decimalPlace位
			// if (regex.test(value))
			// 	newData[rowIndex][bodyTitleList[inputIndex]] = value;
			// setTableBodyData(newData);
		} else {
			newData[rowIndex][bodyTitleList[inputIndex]] = value;
			setTableBodyData(newData);

			const newPageData = { ...pageData };
			newPageData.purchaseOrderItemList = [...newData];
			setPageData({ ...newPageData });
		}
	};

	const handleDeletePaymentTerm = (e, data) => {
		e.stopPropagation();
		const oldList = [...tableBodyData];

		// 只有在 EDIT 裡才會有被刪除的付款條件
		if (oldList[data.displayOrder - 1].action === 'update') {
			oldList[data.displayOrder - 1].action = 'delete';
			deletedTermList &&
				setDeleteTermList([
					...deletedTermList,
					oldList[data.displayOrder - 1],
				]);
		}

		// remove the item from the array
		oldList.splice(data.displayOrder - 1, 1);
		// update the displayOrder property of the remaining items
		const newList = oldList.map((item, index) => ({
			...item,
			displayOrder: index + 1,
		}));

		setTableBodyData(newList);
		// console.log(newList);
	};

	const handleInputBlur = (value, rowIndex, inputIndex, inputName) => {
		const newData = [...tableBodyData];

		if (inputName === 'quantityReceive') {
			const quantityUnreceived = newData[rowIndex]['quantityUnreceived'];

			// 收貨數量 不大於 未收數量
			if (Number(value) > Number(quantityUnreceived)) {
				newData[rowIndex]['quantityReceive'] = quantityUnreceived;
				setTableBodyData(newData);
			} else if (Number(value) < 0) {
				// 未收數量不為負數
				newData[rowIndex]['quantityReceive'] = 0;
				setTableBodyData(newData);
			}

			const newPageData = { ...pageData };
			newPageData.purchaseOrderItemList = [...newData];
			setPageData({ ...newPageData });
		}

		// 手動批次編號，判斷是否滿碼
		if ((inputName = 'batchNo' && newData[rowIndex].batchNoAuto === '0')) {
			const { batchNumberingFull, batchNumberingLength } =
				newData[rowIndex];
			const inputValueLength = value.length;

			// console.log(
			// 	batchNumberingFull,
			// 	batchNumberingLength,
			// 	inputValueLength
			// );

			if (
				batchNumberingFull &&
				inputValueLength !== batchNumberingLength
			) {
				newData[rowIndex]['batchNo'] = '';
				setTableBodyData(newData);
			}

			const newPageData = { ...pageData };
			newPageData.purchaseOrderItemList = [...newData];
			setPageData({ ...newPageData });
		}
	};

	//#endregion

	// #region

	const [indexDialog, setIndexDialog] = useState('');

	// DialogCol input 黃色按鈕取消
	const handleCancelClick = (rowIndex) => {
		const newData = { ...pageData };
		newData.purchaseOrderItemList[rowIndex].cubeGuid = '';
		newData.purchaseOrderItemList[rowIndex].cubeName = '';
		console.log(newData);
		setPageData(newData);
	};

	// DialogCol Modal open
	const handleOpen = (rowIndex) => {
		setDialogBtnKeyName('cube');
		setModalData(backEndData.materialCubeList);
		setIndexDialog(rowIndex); // 獲取真實的index，傳給Dialog2
		setIsOpen(true);
	};

	//#endregion

	// 預設新增一筆檢驗資料
	useEffect(() => {
		const newData = {};
		const newArray = [...tableBodyData];
		newArray.push(newData);
		setTableBodyData(newArray);
	}, []);

	const handleAddRow = () => {
		console.log(tableBodyData);
		const newData = {
			displayOrder: tableBodyData.length + 1,
			cubeNam: '',
			materialName: pageData.goodsItemInfo.materialName, //物料 名稱
			batchNo: '',
		};

		let newArray = [...tableBodyData];
		newArray.push(newData);
		setTableBodyData(newArray);

		// console.log(newArray);
	};

	return (
		<div className="position-relative">
			{/* DialogCol element */}
			<Dialog2
				indexDialog={indexDialog}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				modalData={modalData}
				setModalData={setModalData}
				btnKeyName={dialogBtnKeyName}
				pageData={pageData}
				setPageData={setPageData}
			/>

			<IndexTableContainer className="bg-white d-flex flex-column position-relative">
				<Container>
					<BtnNormal2
						className="btn-primary ms-3 smallBtn"
						keyName="advance-search"
						// handleClick={handleReceiveAll}
						handleClick={handleAddRow}
						word="新增"
					>
						<CreateIcon className="me-2" />
					</BtnNormal2>
				</Container>
				<IndexTable className="table">
					<THead className="table-head">
						<TR>
							{headTitleList?.map((el) => (
								<TH
									key={`tableHead${headTitleList?.indexOf(
										el
									)}`}
									style={
										tableHeadStyles[
											headTitleList?.indexOf(el)
										]
									}
								>
									{el}
								</TH>
							))}
						</TR>
					</THead>
					<TBody className="">
						{tableBodyData?.map((data, rowIndex) => {
							//console.log(bodyTitleList);
							return (
								<TR
									key={`paymentTermTableRow${rowIndex}`}
									onClick={(e) =>
										handleEditTable(e, rowIndex)
									}
								>
									{bodyTitleList?.map((el, inputIndex) => {
										//console.log(bodyTitleList);
										// 不可編輯欄位(不為input)
										if (
											[
												'displayOrder',
												'materialName',
												'cubeName',
												'batchNo',
											].includes(el)
										)
											return (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList?.indexOf(
																el
															)
														]
													}
													className={`border`}
													active={data.active}
													key={`${bodyTitleList?.indexOf(
														el
													)}`}
												>
													<div className="px-2">
														{el ===
														'purchaseCategoryCode'
															? handlePurchaseCategoryName(
																	data[el]
															  )
															: data[el]}
														{el ===
															'purchaseContent' &&
															handlePurchaseContent(
																data
															)}
													</div>
												</TD>
											);

										// 檢驗數量
										if (el === 'number') {
											return (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList?.indexOf(
																el
															)
														]
													}
													className={`p-2 border`}
													active={data.active}
													key={`${bodyTitleList?.indexOf(
														el
													)}`}
												>
													<Input
														value={data[el] || ''}
														active={data.active}
														type={
															inputProperties[
																inputIndex
															]?.type
														}
														min={
															inputProperties[
																inputIndex
															]?.min
														}
														onChange={(e) => {
															handleInputChange(
																e.target.value,
																rowIndex,
																inputIndex,
																toFloatList[
																	inputIndex
																]
															);
														}}
														onBlur={
															inputProperties[
																inputIndex
															]?.onBlur
														}
														style={{
															minWidth: '24px',
															width: '100%',
															outline: 'none',
														}}
													/>
												</TD>
											);
										}
										// 明細註記
										if (el === 'note') {
											return (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList?.indexOf(
																el
															)
														]
													}
													className={`p-2 border`}
													active={data.active}
													key={`${bodyTitleList?.indexOf(
														el
													)}`}
												>
													<Input
														value={data[el] || ''}
														active={data.active}
														type={
															inputProperties[
																inputIndex
															]?.type
														}
														min={
															inputProperties[
																inputIndex
															]?.min
														}
														onChange={(e) => {
															handleInputChange(
																e.target.value,
																rowIndex,
																inputIndex,
																toFloatList[
																	inputIndex
																]
															);
														}}
														//失焦動作
														onBlur={
															inputProperties[
																inputIndex
															]?.onBlur
														}
														style={{
															minWidth: '24px',
															width: '100%',
															outline: 'none',
														}}
													/>
												</TD>
											);
										}
										// 判定品質型態
										if (el === 'Quality') {
											return (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList?.indexOf(
																el
															)
														]
													}
													className={`border`}
													active={data.active}
													key={`${bodyTitleList?.indexOf(
														el
													)}`}
												>
													<DialogWrapper className="row flex-nowrap">
														<InputDialogElementData
															type="text"
															value={
																data.cubeGuid ||
																''
															}
															onChange={() => {}}
														/>
														<InputDialogElementView
															style={{
																minWidth:
																	'85px',
																cursor: 'auto',
															}}
															value={
																data.cubeName ||
																''
															}
															onChange={() => {}}
														/>
														<CancelInputBtn
															onClick={() => {
																handleCancelClick(
																	rowIndex
																);
															}}
															isValueExist={
																data.cubeGuid
															}
														>
															<Icn_InputYellowCancel />
														</CancelInputBtn>

														<DialogBtn
															style={{
																cursor: 'pointer',
															}}
															onClick={() =>
																handleOpen(
																	rowIndex
																)
															}
														>
															<Icn_InputDialog />
														</DialogBtn>
													</DialogWrapper>
													{/* <div className="mt-xl-1">
														<span className="text-danger ms-1">
															{
																data[
																	'cubeGuidWarning'
																]
															}
														</span>
													</div> */}
												</TD>
											);
										}

										// 判收物料倉位
										if (el === 'materialCube') {
											return (
												<TD
													style={
														tableBodyStyles[
															bodyTitleList?.indexOf(
																el
															)
														]
													}
													className={`border`}
													active={data.active}
													key={`${bodyTitleList?.indexOf(
														el
													)}`}
												>
													<DialogWrapper className="row flex-nowrap">
														<InputDialogElementData
															type="text"
															value={
																data.cubeGuid ||
																''
															}
															onChange={() => {}}
														/>
														<InputDialogElementView
															style={{
																minWidth:
																	'85px',
																cursor: 'auto',
															}}
															value={
																data.cubeName ||
																''
															}
															onChange={() => {}}
														/>
														<CancelInputBtn
															onClick={() => {
																handleCancelClick(
																	rowIndex
																);
															}}
															isValueExist={
																data.cubeGuid
															}
														>
															<Icn_InputYellowCancel />
														</CancelInputBtn>

														<DialogBtn
															style={{
																cursor: 'pointer',
															}}
															onClick={() =>
																handleOpen(
																	rowIndex
																)
															}
														>
															<Icn_InputDialog />
														</DialogBtn>
													</DialogWrapper>
													{/* <div className="mt-xl-1">
														<span className="text-danger ms-1">
															{
																data[
																	'cubeGuidWarning'
																]
															}
														</span>
													</div> */}
												</TD>
											);
										}

										return (
											<TD
												style={
													tableBodyStyles[
														bodyTitleList?.indexOf(
															el
														)
													]
												}
												className={`border`}
												active={data.active}
												key={`${bodyTitleList?.indexOf(
													el
												)}`}
											>
												<Dialog
													btnWord="序號"
													btnKeyName="material"
													// key="111"
												/>

												<Menu style={{ zIndex: '2' }}>
													<Button
														onClick={(e) => {
															handleDeletePaymentTerm(
																e,
																data
															);
														}}
													>
														<span>刪除</span>
													</Button>
												</Menu>
											</TD>
										);
									})}
								</TR>
							);
						})}
					</TBody>
				</IndexTable>
			</IndexTableContainer>
		</div>
	);
}

export default PaymentTermTable;
