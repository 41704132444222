import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PrevBtnSmall } from '../../../../../../components/Buttons/PrevBtnSmall';
import { NextBtnSmall } from '../../../../../../components/Buttons/NextBtnSmall';
import { StepByStepBar } from '../../../../../../components/StepByStepBar/StepByStepBar';
import { SaveSubmitBtn } from '../../../../../../components/Buttons/SaveSubmitBtn';
import { CancelSubmitBtn } from '../../../../../../components/Buttons/CancelSubmitBtn';
import TopInfo from '../TopInfo';
import { TabPage } from '../../../exports';
import LgsPurchaseOrderPage4Tab_PurchaseOrderDetails from './PurchaseOrderDetails';
import LgsPurchaseOrderPage4Tab_PaymentTerm from './PaymentTerm';
import useAxios from '../../../../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';

// #region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

// 表格
const Wrapper = styled.div``;

//#endregion

function Page4(props) {
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);
	const {
		purchaseOrderData,
		setPurchaseOrderData,
		page,
		processSteps,
		handlePrevPage,
		handleNextPage,
	} = props;

	// 分頁title
	const tabNavList = [
		t('lgsPurchaseOrder:purchaseDetails'),
		t('lgsPurchaseOrder:paymentTerms'),
	];

	// 分頁內容 component
	const tabContent = [
		<LgsPurchaseOrderPage4Tab_PurchaseOrderDetails
			purchaseOrderData={purchaseOrderData}
		/>,
		<LgsPurchaseOrderPage4Tab_PaymentTerm
			purchaseOrderData={purchaseOrderData}
		/>,
	];

	// page1此頁面資料管控
	const [newPurchaseOrderData, setNewPurchaseOrderData] = useState([]);
	console.log(newPurchaseOrderData);

	console.log('parentData', purchaseOrderData);

	// 整理資料
	const organizeData = () => {
		const oldData = { ...purchaseOrderData };

		// 整理資料
		const updatePurchaseOrderItemList = oldData.page1.map(
			({
				action,
				purchaseOrderItemGuid,
				displayOrder,
				purchaseCategoryCode,
				materialGuid,
				assetsGuid,
				accountGuid,
				expenseClassGuid,
				sectorGuid,
				sectorClassGuid,
				deliverDate,
				uomCode,
				quantityPurchase,
				netPrice,
				netAmount,
				note,
				rowStamp,
			}) => ({
				action,
				purchaseOrderItemGuid,
				displayOrder,
				purchaseCategoryCode,
				materialGuid,
				assetGuid: assetsGuid,
				accountGuid,
				expenseClassGuid,
				sectorGuid,
				sectorClassGuid,
				deliverDate,
				uomCode,
				quantityPurchase,
				netPrice,
				netAmount,
				note,
				rowStamp,
			})
		);

		console.log(updatePurchaseOrderItemList);

		let newPaymentTermList = oldData.page2.paymentTermList.concat(
			oldData.page2.deletedTermList
		);
		const updatePurchaseOrderPaymentTermList = newPaymentTermList.map(
			({
				action,
				paymentTermGuid,
				displayOrder,
				baseOn,
				payDate,
				days,
				amount,
				foreignAmount,
				percentage,
				note,
				rowStamp,
			}) => ({
				action,
				paymentTermGuid,
				displayOrder,
				baseOn,
				payDate,
				days,
				amount,
				foreignAmount,
				percentage,
				note,
				rowStamp,
			})
		);

		const updatePurchaseOrderAttachmentList =
			oldData.page3.appendixInfo.map(
				({
					action,
					attachmentGuid,
					displayOrder,
					appendixClass,
					appendixName,
					rowStamp,
				}) => ({
					action,
					attachmentGuid,
					displayOrder,
					appendixClass,
					appendixName,
					rowStamp,
				})
			);

		const updateAppendixDataList = [...oldData.page3.fileList];

		let newData = {
			updatePurchaseOrder: {
				PurchaseOrderGuid: purchaseOrderData.page0.purchaseOrderGuid,
				NetAmount: purchaseOrderData.topInfoData.netAmount,
				TaxAmount: purchaseOrderData.topInfoData.taxAmount,
				GrossAmount: purchaseOrderData.topInfoData.grossAmount,
				ForeignNetAmount:
					purchaseOrderData.topInfoData.foreignNetAmount,
				ForeignTaxAmount:
					purchaseOrderData.topInfoData.foreignTaxAmount,
				ForeignGrossAmount:
					purchaseOrderData.topInfoData.foreignGrossAmount,
				VendorSales: purchaseOrderData.page0.vendorSales,
				VendorSalesPhone: purchaseOrderData.page0.vendorSalesPhone,
				ShipToPlantGuid: purchaseOrderData.page0.shipToPlantGuid,
				ShipToSectorGuid: purchaseOrderData.page0.shipToSectorGuid,
				ShipToStaffGuid: purchaseOrderData.page0.shipToStaffGuid,
				Note: purchaseOrderData.page0.note,
				RowStamp: purchaseOrderData.page0.rowStamp,
			},
			updatePurchaseOrderItemList: updatePurchaseOrderItemList,
			updatePurchaseOrderPaymentTermList:
				updatePurchaseOrderPaymentTermList,
			updatePurchaseOrderAttachmentList:
				updatePurchaseOrderAttachmentList,
			updateAppendixDataList: updateAppendixDataList,
		};

		// 將資料形式改為formData回傳給後端
		const formData = new FormData();

		formData.append(
			'updatePurchaseOrder',
			JSON.stringify(newData.updatePurchaseOrder)
		);
		formData.append(
			'updatePurchaseOrderItemList',
			JSON.stringify(newData.updatePurchaseOrderItemList)
		);
		formData.append(
			'updatePurchaseOrderPaymentTermList',
			JSON.stringify(newData.updatePurchaseOrderPaymentTermList)
		);
		formData.append(
			'updatePurchaseOrderAttachmentList',
			JSON.stringify(newData.updatePurchaseOrderAttachmentList)
		);

		// 新增附件-以同樣的keyName一筆筆append
		newData.updateAppendixDataList.forEach((el) => {
			formData.append('updateAppendixDataList', el);
		});

		console.log(newData.updateAppendixDataList);
		console.log([...formData.entries()]);

		return formData;
	};

	// 儲存
	const { isLoading, error, sendRequest: axiosSaveData } = useAxios();

	const handleSubmit = () => {
		const newData = organizeData();

		const headers = {
			'Content-Type': 'multipart/form-data',
		};
		const url = `api/Logistics/LgsPurchaseOrder/updatePurchaseOrder`;
		const method = 'PUT';
		const data = newData;

		axiosSaveData({ headers, url, method, data }, (res) => {
			console.log(res);

			const newParentData = { ...purchaseOrderData };
			newParentData.page4.orderNo = res;
			setPurchaseOrderData(newParentData);

			res && handleNextPage();
		});
		console.log(purchaseOrderData);

		console.log(error);
	};

	return (
		<>
			<Container>
				<Left>
					{page !== 0 && (
						<PrevBtnSmall
							handleClick={() => {
								handlePrevPage();
							}}
						/>
					)}
				</Left>
				<Center className="ps-14">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					{page < processSteps.length - 1 ? (
						<NextBtnSmall
							handleClick={() => {
								handleNextPage();
							}}
						/>
					) : (
						<>
							<SaveSubmitBtn handleSubmit={handleSubmit} />
							<CancelSubmitBtn
								className={'btn-outline-primary'}
							/>
						</>
					)}
				</Right>
			</Container>

			<Wrapper className="row bg-white rounded-xxl px-6 pt-4 pb-4 mx-0 mb-3">
				<div className="col-12 px-0 ">
					<TopInfo
						topInfoData={purchaseOrderData.topInfoData}
						purchaseOrderData={purchaseOrderData}
					/>
				</div>
			</Wrapper>

			<TabPage
				wrapperStyle={{ minHeight: '530px' }}
				liColWidth={'col-2'}
				tabNavList={tabNavList}
				tabContent={tabContent}
				defaultPage={0}
			/>
		</>
	);
}

export default Page4;
