import { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Pagination from '../../../components/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
    margin-bottom: 0;
    color: var(--grey4);
    background-color: #fff;
`;

const THead = styled.thead`
    height: 40px;
`;

const TR = styled.tr`
    height: 40px;
    &:hover {
        cursor: ${(hoverEffect) =>
            hoverEffect === 'true' ? 'pointer' : 'default'};
        background-color: ${(hoverEffect) =>
            hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
    }
`;

const TH = styled.th`
    vertical-align: middle;
    border: none !important;
    /* border: 1px solid var(--grey1) !important;
    &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    }*/
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard syntax */
`;

const TBody = styled.tbody``;

const TD = styled.td`
    cursor: auto;
    border: 1px solid var(--grey1) !important;
    border-left: none !important;
    border-right: none !important;
    /* &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    } */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard syntax */
`;

const FootTR = styled.tr`
    border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
    border: none !important;
`;

const NoInfoRow = styled.div`
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 16px;
`;
//#endregion

function LogTable(props) {
    // console.log(props);
    const {
        guidName,
        logBodyData,
        sortBy,
        headTitleList,
        bodyTitleList,
        indexData,
        paginationData,
        setPaginationData,
        displayColumns,
        setDisplayColumns,
        fetchData,
        fetchedData,
        tableStyles,
        columnMustShow,
        clickSelection,
        rowClick,
        advancedSearchDataList,
        advancedSearchDataTemp,
        displaySetting,
    } = props;

    const [orderBy, setOrderBy] = useState('desc');
    const [UISort, setUISort] = useState(sortBy);
    const { t, i18n } = useTranslation();

    const logHeaderList = [
        t('log.transactionTime'),
        t('log.name'),
        t('log.updateContent'),
        t('log.user'),
    ];

    const logBodyTitleList = [
        'transactionTime',
        'name',
        'updateContent',
        'user',
    ];

    const handleSortChange = (newTarget) => {
        const oldTarget = UISort;
        setUISort(newTarget);

        if (oldTarget === newTarget)
            return setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');

        return setOrderBy('asc');
    };

    // {
    //     "logGuid": "eb0d7fcf-f4e6-ed11-a182-00155d070709",
    //     "name": "逃生路線圖",
    //     "updateContent": "新增 逃生路線圖",
    //     "user": "王勿刪(勿刪)",
    //     "transactionTime": "1487-04-30T09:17:46.103+08:00"
    // }

    useEffect(() => {
        fetchData(
            advancedSearchDataList.length ? advancedSearchDataList : null,
            5,
            `${UISort} ${orderBy}`,
            1
        );
    }, [UISort, orderBy]);

    return (
        <div className="position-relative">
            <IndexTableContainer className="bg-white d-flex flex-column p-4 position-relative">
                <IndexTable className="table">
                    <THead className="table-head">
                        <TR>
                            {logHeaderList?.map((el, index) => (
                                <TH
                                    key={`tableHead${logHeaderList.indexOf(
                                        el
                                    )}`}
                                    onClick={() => {
                                        handleSortChange(
                                            logBodyTitleList[
                                                logHeaderList.indexOf(el)
                                            ]
                                        );
                                    }}
                                    style={{
                                        width:
                                            logBodyTitleList[index] ===
                                                'updateContent' && '50%',
                                    }}
                                >
                                    {el}
                                    {logBodyTitleList[
                                        logHeaderList.indexOf(el)
                                    ] === UISort &&
                                        (orderBy === 'desc' ? (
                                            <span className="ml-1">▼</span>
                                        ) : (
                                            <span className="ml-1">▲</span>
                                        ))}
                                </TH>
                            ))}
                        </TR>
                    </THead>
                    <TBody className="">
                        {logBodyData?.map((data) => (
                            <TR id={data.logGuid} key={data.logGuid}>
                                <TD className="p-2 border">
                                    {dayjs(data.transactionTime).format(
                                        'YYYY-MM-DD'
                                    )}
                                </TD>
                                <TD className="p-2 border">{data.name}</TD>
                                <TD className="p-2 border">
                                    {data.updateContent}
                                </TD>
                                <TD className="p-2 border">{data.user}</TD>
                            </TR>
                        ))}
                    </TBody>
                </IndexTable>
                <Pagination
                    paginationData={paginationData}
                    setPaginationData={setPaginationData}
                    fetchData={fetchData}
                    sortBy={sortBy}
                    UISort={UISort}
                    orderBy={orderBy}
                    advancedSearchDataList={advancedSearchDataList}
                />
            </IndexTableContainer>
        </div>
    );
}

export default LogTable;
