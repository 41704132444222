import { useState, useEffect } from 'react';
import styled from 'styled-components';
import InvoiceSplitTable from './InvoiceSplitTable';
import { useTranslation } from 'react-i18next';

// #region styled-components

const IndexTableContainer = styled.div``;

//#endregion

function InvoiceSplitTab(props) {
	//#region state initiation
	const { t } = useTranslation(['common', 'lgsPurchaseOrderInvoice']); // i18n

	// props
	const { parentData } = props;

	// state
	const [pageData, setPageData] = useState({
		invoiceSplitList: [],
	});
	const [tableBodyData, setTableBodyData] = useState([]);

	console.log('tableBodyData', tableBodyData);
	console.log('pageData', pageData);
	console.log('parentData', parentData);

	//#endregion

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsPurchaseOrderInvoice:vendorCode'),
		t('lgsPurchaseOrderInvoice:vendor'),
		t('lgsPurchaseOrderInvoice:orderDate'),
		t('lgsPurchaseOrderInvoice:orderNo'),
		t('lgsPurchaseOrderInvoice:certificateNumber'),
		t('lgsPurchaseOrderInvoice:purchaseTaxableTotal'),
		t('lgsPurchaseOrderInvoice:totalUncollectedInvoices'),
		t('lgsPurchaseOrderInvoice:totalCollectedInvoices'),
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'vendorCode',
		'vendor',
		'orderDate',
		'orderNo',
		'invoiceNo',
		'purchaseTaxableTotal',
		'totalUncollectedInvoices',
		'totalCollectedInvoices',
	];

	// 不同 input 的 type
	const inputProperties = [
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{ type: 'number' },
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '136px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '136px',
			textAlign: 'right',
		},
	];

	//#endregion

	//#region useEffect
	useEffect(() => {
		setTableBodyData(parentData.page2.invoiceSplitList);
		setPageData(parentData.page2);
	}, []);
	//#endregion

	return (
		<>
			<div className="position-relative">
				<IndexTableContainer className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
					<InvoiceSplitTable
						pageData={pageData}
						parentData={parentData}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						tableBodyData={tableBodyData}
						inputProperties={inputProperties}
					/>
				</IndexTableContainer>
			</div>
		</>
	);
}

export default InvoiceSplitTab;
