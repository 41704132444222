import styled from 'styled-components';
import { ReactComponent as Icn_Person } from '../../assets/images/icn_Person.svg';
import { useCookies } from 'react-cookie';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

// #region styled-components
const NavbarContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    height: 56px;
    background: #ffffff;
    border-bottom: 1px solid var(--grey2);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
`;

const NavbarWrap = styled.div`
    flex-wrap: nowrap;
`;

const NavbarLeftArea = styled.div`
    padding: 0.375rem 0.75rem;
    margin-left: 24px;
    color: var(--grey5);
    font-weight: 700;
    font-size: 0.875rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
`;

const NavbarLangArea = styled.div`
    width: 112px;
`;

const NavbarLangSelect = styled.select`
    border: none;
    box-shadow: none;
    &:focus {
        box-shadow: none;
    }
    cursor: pointer;
`;

const NavbarRightArea = styled.div`
    min-width: 150px;
    position: relative;
    display: flex;
    background: var(--primary);
    align-items: center;
    height: 100%;
    cursor: pointer;
`;

const NavUserName = styled.div`
    color: white;
    margin-right: 1rem;
`;

const NavbarRightModal = styled.div`
    position: absolute;
    top: 60px;
    min-width: 136px;
    border-radius: 0.25rem;
    background-color: #ffffff;
    box-shadow: 2px 4px 12px -1px rgb(61 61 61 / 51%);
    z-index: 1000;
`;

const DropdownItem = styled.div`
    padding: 8px 16px;
    &:hover,
    &:focus {
        color: var(--primaryhover);
        background-color: var(--primarylt);
    }
`;
// #endregion styled-components

export default function Navbar({ lang }) {
    const [cookies, setCookie] = useCookies();
    // const lang = cookies['_system_culture'];

    // console.log(lang);

    // 1.modal
    //  Create a ref that we add to the element for which we want to detect outside clicks
    const ref = useRef();
    //  State for our modal
    const [isModalOpen, setModalOpen] = useState(false);
    //  Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(ref, () => setModalOpen(false));

    //  Hook
    function useOnClickOutside(ref, handler) {
        useEffect(
            () => {
                const listener = (event) => {
                    // Do nothing if clicking ref's element or descendent elements
                    if (!ref.current || ref.current.contains(event.target)) {
                        return;
                    }
                    handler(event);
                };
                document.addEventListener('mousedown', listener);
                document.addEventListener('touchstart', listener);
                return () => {
                    document.removeEventListener('mousedown', listener);
                    document.removeEventListener('touchstart', listener);
                };
            },
            // Add ref and handler to effect dependencies
            // It's worth noting that because passed in handler is a new ...
            // ... function on every render that will cause this effect ...
            // ... callback/cleanup to run every render. It's not a big deal ...
            // ... but to optimize you can wrap handler in useCallback before ...
            // ... passing it into this hook.
            [ref, handler]
        );
    }

    // 2.logout
    const logout = () => {
        localStorage.clear();
        clearAllCookies();
        window.location.href = `/`;
    };

    function clearAllCookies() {
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie =
                name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        }
    }

    // const mvcLocalhost = 'https://localhost:7014/'; // publishReset 前改為下方
    const mvcLocalhost = '/';

    return (
        <NavbarContainer className="navbar navbar-expand-lg">
            <NavbarWrap className="container-fluid px-0 h-100">
                <NavbarLeftArea>{cookies['_company_name']}</NavbarLeftArea>
                <NavbarLangArea className="ms-auto me-3">
                    <NavbarLangSelect
                        className="form-control"
                        value={lang}
                        onChange={(e) => {
                            setCookie('_system_culture', e.target.value, {
                                path: '/',
                            });
                        }}
                    >
                        <option value="c=en|uic=en">English</option>
                        <option value="c=zh-CN|uic=zh-CN">简体</option>
                        <option value="c=zh-TW|uic=zh-TW">繁體</option>
                    </NavbarLangSelect>
                </NavbarLangArea>
                <NavbarRightArea onClick={() => setModalOpen(!isModalOpen)}>
                    <Icn_Person className="mx-3" />
                    <NavUserName>{cookies['_user_name']}</NavUserName>

                    {isModalOpen && (
                        <NavbarRightModal ref={ref}>
                            {!(
                                cookies._company_guid || cookies._group_guid
                            ) && (
                                <DropdownItem>
                                    <span
                                        onClick={() =>
                                            (window.location.href = `${mvcLocalhost}user/home/index`)
                                        }
                                    >
                                        選擇集團/公司
                                    </span>
                                </DropdownItem>
                            )}
                            <DropdownItem>
                                <span
                                    onClick={() =>
                                        (window.location.href = `${mvcLocalhost}User/ErpUsers/Details/${cookies['_user_guid']}`)
                                    }
                                >
                                    使用者基本資料
                                </span>
                            </DropdownItem>
                            <DropdownItem onClick={() => logout()}>
                                <span>登出</span>
                            </DropdownItem>
                        </NavbarRightModal>
                    )}
                </NavbarRightArea>
            </NavbarWrap>
        </NavbarContainer>
    );
}
