import React from 'react';
import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';

export const BtnLarge = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    margin-right: 8px;
`;
