// #region 程式說明--銷售訂單編號規則

/*  ****
  
    Author：
    2023/11/21: 江彥均: 初版(參考採購訂單編號規則)
  
    **主要功能**：

    1.提供「列表」、「查詢」、「新增」、「檢視」、「修改」、「刪除」、「啟動」、「停用」、「刪除」、「異動記錄」功能
    2.列表：顯示所有銷售訂單編號規則，並提供進階搜尋、異動紀錄功能。
    3.查詢：顯示單筆銷售訂單編號規則的資料。
    4.新增：新增銷售訂單編號規則。
    5.修改：修改銷售訂單編號規則。
    6.啟動：啟動銷售訂單編號規則。
    7.停用：停用銷售訂單編號規則。
    8.刪除：刪除銷售訂單編號規則。
    9.異動記錄：查看銷售訂單編號規則的異動記錄。
  
    **使用者步驟**：

    A. 銷售訂單編號規則列表
        1.從側邊欄點選「銷售訂單編號規則」，進入銷售訂單編號規則「列表」頁面。
        2.點選頁面上方的選單按鈕，將可以選擇進入「異動記錄」以查詢全部銷售訂單編號規則異動記錄。
        3.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行銷售訂單編號規則搜尋。
        4.使用者可點擊列表中每欄的標題，將以此標題為排序依據，並以反向排序或正向排序顯示銷售訂單編號規則列表資料。
        5.如果使用者已進行進階搜尋，則會列表上方出現已選擇的搜尋條件的小卡片，以顯示已選擇的搜尋條件。
        6.使用者可點擊上述的小卡片，以刪除該進階搜尋條件，或點擊「進階查詢」小卡片，以刪除所有進階搜尋條件。

    B. 進階搜尋
        1.點選進階搜尋按鈕，會彈出「搜尋條件」對話框，並進行銷售訂單編號規則搜尋。
        2.使用者可選擇之搜尋條件：編號名稱、編號規則、連續起始號碼、增量值。
        3.使用者可選擇之搜尋邏輯：包括、不包括、等於、不等於。
        4.使用者可選擇之搜尋邏輯會根據其選擇的搜尋條件而有所變化，但不會出現上述方法以外的選項。
        5.使用者可於右方的輸入框輸入搜尋條件，輸入框的種類會有「文字」、「數字」及「下拉選單」。
        6.使右方的輸入框種類會根據其選擇的搜尋條件而有所變化，但不會出現上述方法以外的選項。
        7.使用者可一個或多個搜尋條件，當多於一個搜尋條件時，會多出現一個搜尋邏輯的選項，會有「且」和「或」兩個選頂。
        8.「且」代表「且」搜尋邏輯，「或」代表「或」搜尋邏輯。
        9.當多於一個搜尋條件時，會出現一個刪除小按鈕於每一筆搜尋條件的右側，點選後會刪除該搜尋條件。
        10.在 dialog 中間會有一個橘色的刪除小按鈕，點選後會刪除全部搜尋條件。
        11.點選「確定」，進行服務主檔搜尋，並回到服務主檔「列表」頁面顯示搜尋結果。

    C. 新增銷售訂單編號規則
        1.如需新增銷售訂單編號規則，請點選「新增」以進入「新增」頁面。
        2.新增頁面包含「編號名稱」、「編號規則」、「連續起始號碼」、「增量值」及「示例」欄位。
        3.點下「編號規則」，彈出「自動編號規則」對話框，依編號規則需求填入或選擇：「前綴字」、「週期」、「分隔字元」、「連續號碼長度」、「連續起始號碼」、「增量值」、「後綴字」，並即時在示例欄位更新顯示3個範例。
        4.按下「確定」後，更新「編號規則」、「連續起始號碼」、「增量值」及「示例」欄位。或是按下「取消」後，回到「新增」頁面。
        5.輸入完畢後，點選「儲存」以進行銷售訂單編號規則的新增。
        6.點選「儲存」時，使用者可選擇「存檔」或「存檔並啟用」。
        7.銷售訂單編號規則新增完成後，將會返回銷售訂單編號規則列表。
        8.在新增的過程中，使用者可點選「取消」按鈕或頁面左上方的返回按鈕，以取消操作並返回銷售訂單編號規則列表。

    D. 查詢銷售訂單編號規則
        1.如需查詢服務主檔服務及其資料，請點選「列表」頁面中的任一筆資料,以進入「查詢」頁面。
        2.查詢頁面包含「編號名稱」、「編號規則」、「連續起始號碼」、「增量值」及「示例」欄位。
        3.如需要修改銷售訂單編號規則及其資料，請點選頁面左上方的「修改」按鈕，以進入「修改」頁面。
        4.點選頁面左上方的返回按鈕，將返回銷售訂單編號規則列表。
        5.點選頁面上方的選單按鈕，將可以選擇「啟用」、「刪除」、「停用」或進入「異動記錄」以查詢該銷售訂單編號規則異動記錄。
        6.如銷售訂單編號規則處於未啟用狀態，將可選擇「修改」、「啟用」、「刪除」或「異動記錄」。
        7.如銷售訂單編號規則正處於啟用狀態，將可選擇「修改」、「停用」或「異動記錄」。
        8.如銷售訂單編號規則正處於停用狀態，將可選擇「修改」、「啟用」、「刪除」或「異動記錄」。停用狀態之「刪除」，是在資料庫中，將其狀態值變更為"4"且不顯示在銷售訂單編號規則列表中。

    E. 修改銷售訂單編號規則
        1.修改頁面僅能修改「編號名稱」。
        4.輸入完畢後，點選「儲存」以進行銷售訂單編號規則的修改。
        5.點選「儲存」時，如該服務主檔的狀態是「未啟用」或「停用」，使用者可選擇「存檔」或「存檔並啟用」。
        6.點選「儲存」時，如該服務主檔的狀態是「啟用中」，修改會主立即生效。
        7.服銷售訂單編號規則修改完成後，將會返回銷售訂單編號規則查詢。
        8.在修改的過程中，使用者可點選「取消」按鈕或頁面左上方的返回按鈕，以取消操作並返回銷售訂單編號規則列表。

    F. 銷售訂單編號規則異動記錄
        1.異動記錄以列表方式呈現，預設以時間正序排序。
        2.使用者可點擊列表中每欄的標題，將以此標題為排序依據，並以反向排序或正向排序顯示服務主檔列表資料。
        3.點選進階搜尋按鈕，會彈出搜尋條件 dialog，並進行異動記錄的進階搜尋。
        4.此進階查詢功能使用邏輯和「列表」頁面的進階查詢相同。

    **功能頁面**：

    (一)「新增」頁面
        1.使用者輸入：
            (1)編號名稱(必填)
            (2)編號規則(必選彈出對話框，並依對話框選填內容更新規則欄位)
                (A)前綴字(選填)
                (B)週期(選填)
                (C)連續號碼長度(必填，預設3)
                (D)連續號碼起始號碼(必填，依預設號碼長度顯示第一碼)
                (E)增量值(必填，預設1)
                (F)後綴字(選填)
                (G)示例(依前面A~F自動組合並顯示出3個示例)
            (3)連續號起始號碼(依編號規則(D)帶入)
            (4)增量值(依編號規則(E)帶入)
            (5)示例(依編號規則(G)帶入)

  
        2.儲存前檢查：
            (1)必填項目：如資料輸入有缺，將會顯示錯誤訊息於其輸入框下（請填寫資料）。
                (A)編號名稱
                (B)編號規則
        
            (2)如輸入的資料無錯誤，則會進入儲存資料的流程。

    **資料連動**：
      

    (二)「修改」頁面
         1.未啟用過銷售訂單編號規則與「新增」頁面相同。
         2.啟用過銷售訂單編號規則(包含狀態為：啟用中、停用)，僅能修改「編號名稱」。
       
*/

// #endregion 程式說明--銷售訂單編號規則
import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useContext,
} from "react";
import { purchaseOrderNoRuleAdvancedSearchData as searchContentList } from "../../../utils/advancedSearchData";
import styled from "styled-components";
import useAxios from "../../../hooks/useAxios";
import {
  FunctionActionButtonBar,
  IndexTablePartial,
  PageTitleBar,
} from "../../../components/exports";
import usePermissionButton from "../../../hooks/usePermissionButton";
import { useNavigate } from "react-router-dom";
import { CreateBtnNormal } from "../../../components/Buttons/CreateBtnNormal";
import { DeleteBtnSmall } from "../../../components/Buttons/DeleteBtnSmall";
import { SearchBtnSmall } from "../../../components/Buttons/SearchBtnSmall";
import { CopyCreateBtn } from "../../../components/Buttons/CopyCreateBtn";
import { EditBtnNormal } from "../../../components/Buttons/EditBtnNormal";
import { DropdownMenuArea } from "./DropdownMenuArea";
import { ReactComponent as LogIcon } from "../../../assets/images/icn_Log.svg";
import Table from "../../../components/Table/Table";
import AdvancedSearchModal from "../../../components/Modals/AdvancedSearchModal";
import AdvancedSearchResultTag from "../../../components/Modals/AdvancedSearchResultTag";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import {
  convertFromNumberingRuleToRuleForUser,
  getRulePreview,
  periodFormatDict,
} from "./NumberingRule";
import { useTranslation } from "react-i18next";

// #region styled-components
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const Left = styled.div`
  display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
  display: flex;
`;

//#endregion

// Author: YL
function lgsSalesOrderNoRule_Index() {
  const { t } = useTranslation(["common", "lgsSalesOrderNoRule"]);
  // 使用 useAuth Hook 取得 isLogin 和 checkAuth
  const { isLogin, checkAuth } = useAuth();
  const navigate = useNavigate();

  // 在組件生命週期方法中呼叫 checkAuth 函式檢查登入狀態
  useEffect(() => {
    checkAuth();
  }, []);

  const [fetchedData, setFetchedData] = useState([]);
  const [displayColumns, setDisplayColumns] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const view = "index";
  const [sortBy, setSortBy] = useState("name desc");
  const guidName = "noRuleGuid";
  const pageTitle = t("lgsSalesOrderNoRule:pageTitle");
  const { isLoading, error, sendRequest: axiosIndexData } = useAxios();

  const [advancedSearchShow, setAdvancedSearchShow] = useState(false);

  if (error) {
    console.log("error !", error);
  }

  const headTitleList = [
    "#",
    t("lgsSalesOrderNoRule:name"),
    t("lgsSalesOrderNoRule:noRule"),
    t("lgsSalesOrderNoRule:startNo"),
    t("lgsSalesOrderNoRule:incremental"),
    t("lgsSalesOrderNoRule:status"),
  ];

  const bodyTitleList = [
    "displayOrder",
    "name",
    "noRule",
    "startNo",
    "incremental",
    "status",
  ];

  const tableStyles = [
    {
      minWidth: "72px",
      textAlign: "center",
    },
    {
      minWidth: "200px",
      textAlign: "left",
    },
    {
      minWidth: "320px",
      textAlign: "left",
    },
    {
      minWidth: "200px",
      textAlign: "left",
    },
    {
      minWidth: "160px",
      textAlign: "left",
    },
    {
      minWidth: "208px",
      textAlign: "left",
    },
  ];

  const columnMustShow = ["displayOrder", "orderDate", "orderNo"];

  // 進階搜尋 一進去的第一筆的模板
  const advancedSearchDataTemp = {
    searchLogic: "AND",
    column: searchContentList[0].column,
    searchOperator: "==",
    searchValue: "",
    searchContent: "",
    inputWarningContent: "",
    selectOptionList: searchContentList[0].selectOptionList,
  };

  // 進階搜尋實際資料
  const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
  // 進階搜尋畫面資料
  const [displayList, setDisplayList] = useState([]);

  // Advanced Search

  //todo: default sort col => orderDate

  /// 1. 第一次進入 index
  /// 2. 改變進階查詢
  /// 3. 改變筆數
  /// 4. 改變排序
  /// 都需要重新向後端拿資料, 所以合成一個 function 處理

  // advancedSearchDataList: 進階查詢資料
  // sort: 排序

  const fetchData = (
    advancedSearchDataList = null,
    pageSize = 20,
    sort = sortBy,
    pageNumber = 1
  ) => {
    // const pageString = page ? `&page=${page}` : '';
    const url = `api/Logistics/LgsSalesOrderNoRule/GetList`;
    //console.log(advancedSearchDataList);

    // 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
    if (advancedSearchDataList) {
      // only "one" search data
      advancedSearchDataList[0].searchLogic = "";
    }

    axiosIndexData(
      {
        url,
        method: "post",
        data: {
          sort,
          pageSize,
          page: pageNumber,
          advancedSearchDataList,
          TransactionCode: "lgsSalesOrderNoRule", //permission 由前端傳直給後端，判斷權限
        },
      },
      (res) => {
        //console.log(res);
        const { indexData, permission, numberOfData, totalPage } = res;

        indexData.map((el) => {
          // 把 numbering rule(db) 拆解
          const {
            prefix,
            suffix,
            timeSeparator,
            format,
            content,
            period,
            length,
          } = convertFromNumberingRuleToRuleForUser(el.noRule, el.startNo);

          // 把拆解後的參數, 更新到 data 裡
          return (el.noRule = `${prefix}${format}${timeSeparator}${content}${suffix}`);
        });
        // 分頁資訊
        const totalPage1 = Math.ceil(numberOfData / pageSize);
        setPaginationData({
          ...paginationData,
          numberOfData: numberOfData,
          totalPage: totalPage1,
          pageNumber: pageNumber,
        });

        // 計算每一頁的起始 displayOrder
        const startIndex = (pageNumber - 1) * pageSize + 1;

        indexData?.map((el, index) => {
          el.displayOrder = startIndex + index;
        });

        // table 資料
        setFetchedData({
          indexData,
          permission,
          paginationData,
        });

        function isArrayFn(obj) {
          // 包成函式
          if (typeof Array.isArray === "function") {
            return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
          } else {
            // 否則就使用 toString 方法
            return Object.prototype.toString.call(obj) === "[object Array]";
          }
        }
        /// 這和 api 回傳的 result 無關
        // 如果有 advancedSearchDataList, 且是 array
        // console.log('original', advancedSearchDataList);
        if (isArrayFn(advancedSearchDataList)) {
          // 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
          // 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
          advancedSearchDataList.map((el) => {
            searchContentList.map((item) => {
              // console.log(item);
              if (el.searchFilter === item.tableColumn) {
                el.searchFilter = item.searchFromDataBase;
              }
            });
          });

          // console.log('after edit', advancedSearchDataList);

          setDisplayList(advancedSearchDataList);
          setAdvancedSearchDataList(advancedSearchDataList);
        }

        handleAdvancedSearchModalClose();
        localStorage.setItem("Permission_LgsSalesOrderNoRule", permission);
      }
    );
  };

  const getLocalStorageData = useCallback(() => {
    const gettingData = async () => {
      const data = await (JSON.parse(
        localStorage.getItem(`${guidName}DisplayColumns`)
      ) || bodyTitleList);

      setDisplayColumns(data);

      return data;
    };

    return gettingData();
  }, []);

  const handleOpenAdvancedSearch = () => {
    console.log("handleOpenAdvancedSearch");
  };
  const handleAdvancedSearchModalClose = () => setAdvancedSearchShow(false);
  const handleAdvancedSearchModalShow = () => {
    setAdvancedSearchShow(true);
  };

  const deleteAllFilters = () => {
    setDisplayList([]);
    setAdvancedSearchDataList([]);
    fetchData();
  };

  const deleteFilter = (targetIndex) => {
    //console.log('deleteFilter index', targetIndex);
    //console.log(advancedSearchDataList[targetIndex]);
    //console.log(displayList[targetIndex]);

    const newAdvancedSearchDataList = advancedSearchDataList.filter(
      (_, index) => index !== targetIndex
    );
    const newDisplayList = displayList.filter(
      (_, index) => index !== targetIndex
    );
    setDisplayList(newDisplayList);
    setAdvancedSearchDataList(newAdvancedSearchDataList);
    fetchData(
      newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
    );
  };

  useEffect(() => {
    // fetchIndexInfo();
    getLocalStorageData();
    fetchData();

    return () => {
      // cleanup
    };
  }, [getLocalStorageData]);

  //console.log(fetchedData);

  return (
    <>
      <div id="indexView">
        <PageTitleBar pageTitle={pageTitle} previousPageButton={false} />
        <Container>
          <Left>
            <CreateBtnNormal
              permission={fetchedData?.permission}
              word={t("button.createButton")}
            />
          </Left>
          <Center></Center>
          <Right>
            <SearchBtnSmall
              permission={fetchedData?.permission}
              handleClick={handleAdvancedSearchModalShow}
            />
            <DropdownMenuArea permission={fetchedData?.permission}>
              <div onClick={() => navigate("Log")}>
                <LogIcon />
                <span>{t("button.logButton")}</span>
              </div>
            </DropdownMenuArea>
          </Right>
        </Container>
        <div className="position-relative firstRowMinHeight bg-white d-flex flex-column p-4 position-relative overflow-auto">
          <AdvancedSearchResultTag
            advancedSearchDataList={advancedSearchDataList}
            deleteAllFilters={deleteAllFilters}
            deleteFilter={deleteFilter}
            ns="lgsSalesOrderNoRule"
          />
          {fetchedData?.indexData && (
            <Table
              guidName={guidName}
              headTitleList={headTitleList}
              bodyTitleList={bodyTitleList}
              tableData={fetchedData?.indexData}
              indexData={fetchedData?.indexData}
              sortBy={sortBy}
              displayColumns={displayColumns}
              setDisplayColumns={setDisplayColumns}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
              fetchData={fetchData}
              fetchedData={fetchedData}
              tableStyles={tableStyles}
              columnMustShow={columnMustShow}
              rowClick={true}
              advancedSearchDataList={advancedSearchDataList}
            />
          )}
        </div>
      </div>

      {advancedSearchShow && (
        <AdvancedSearchModal
          isOpen={advancedSearchShow}
          handleClose={handleAdvancedSearchModalClose}
          headTitleList={headTitleList}
          bodyTitleList={bodyTitleList}
          advancedSearchDataList={advancedSearchDataList}
          setAdvancedSearchDataList={setAdvancedSearchDataList}
          advancedSearchDataTemp={advancedSearchDataTemp}
          fetchData={fetchData}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setFetchedData={setFetchedData}
          displayList={
            displayList.length > 0
              ? displayList
              : [{ ...advancedSearchDataTemp }]
          }
          setDisplayList={setDisplayList}
          searchContentList={searchContentList}
          ns="lgsSalesOrderNoRule"
        />
      )}
    </>
  );
}

export default lgsSalesOrderNoRule_Index;
