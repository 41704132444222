import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ReactComponent as CreateIcon } from '../../../../../assets/images/icn_Create.svg';
import { ReactComponent as CreateGreyIcon } from '../../../../../assets/images/icn_Create_Grey.svg';
import { ReactComponent as CreateBlueIcon } from '../../../../../assets/images/icn_Create_Blue.svg';
import { ReactComponent as Icn_Cancel } from '../../../../../assets/images/icn_Cancel.svg';
import { ReactComponent as Icn_Search2 } from '../../../../../assets/images/icn_Search2.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import useSortableData from '../../../../../hooks/useSortableData';
import { DialogWarning } from '../DialogWarning';
import { DialogWarning_No } from '../DialogWarning_No';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import { useTranslation } from 'react-i18next';

// #region styled-components
const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕
const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const Textarea = styled.textarea`
	height: 97%;
	&:focus-visible {
		outline: 0;
	}
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const LabelNormal2 = styled.label`
	font-size: 14px;
	color: #0c2556;
	margin-top: 10px;
	margin-bottom: 8px;
	font-weight: bold;
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: #eff2f5;
	}
`;
const ModalThead = styled.thead`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalTh = styled.th`
	padding: 0 0.75rem;
	cursor: pointer !important;
	border: none !important;
`;
const ModalLabel = styled.label`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalInput = styled.input`
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const SearchModalInput = styled.input`
	font-size: 14px;
	width: 240px;
	height: 32px;
	padding: 0 0.75rem;
	cursor: pointer !important;
	text-transform: uppercase;
`;
const ModalSearchBtn = styled.button`
	top: 1px;
	right: -25px;
	height: 30px;
	border-radius: 0 0.25rem 0.25rem 0;
`;
// #endregion

export const Dialog = ({
	btnWord,
	btnKeyName,
	tableBodyData,
	setTableBodyData,
	rowIndex,
	data,
}) => {
	//#region state initialization

	const { t } = useTranslation();

	//後端序號假資料
	const serialNoList = [
		'101',
		'102',
		'103',
		'104',
		'105',
		'106',
		'107',
		'108',
		'109',
		'110',
	];
	//	console.log(serialNoList);

	// DialogWarning Modal open
	// Y & N model
	const handleDialogWarningNo = () => {
		setIsWarningOpen(true);
	};

	// 確定 model
	const handleDialogWarning = () => {
		setIsWarningOpen1(true);
	};

	// state
	const [modal, setModal] = useState(false);
	const [modalData, setModalData] = useState([]);
	const [warningMessage, setWarningMessage] = useState('');
	const [isWarningOpen, setIsWarningOpen] = useState(false);
	const [warningMessage1, setWarningMessage1] = useState('');
	const [isWarningOpen1, setIsWarningOpen1] = useState(false);
	const [isDeleted, setIsDeleted] = useState(false);
	const [valuesNotInSerialNoList, setValuesNotInSerialNoList] = useState('');
	const [valuesIsInSerialNoList, setValuesIsInSerialNoList] = useState('');
	const [serialString, setSerialString] = useState('');
	const [serialList, setSerialList] = useState([]);
	const [wrongNumberList, setWrongNumberList] = useState('');
	const [newArrayData, setNewArrayData] = useState({});
	const [textAreaData, setTextAreaData] = useState({});
	const [snCount, setSnCount] = useState(0);

	// ref
	const searchModalInput = useRef('');

	// base function
	const toggle = () => setModal(!modal);

	//#region clickCreateModal(createBtn)

	//打開 序號 dialog

	const clickCreateModal = (e, btnKeyName, rowIndex, tableBodyData) => {
		setSerialString(tableBodyData[rowIndex].serialString);

		//console.log(serialString);

		if (
			tableBodyData[rowIndex].thisQuantityInspect === '' ||
			tableBodyData[rowIndex].thisQuantityInspect < 1
		) {
			//			alert('必須至少輸入一個檢驗數量');
			setWarningMessage1(
				t(
					'lgsPurchaseOrderGoodsInspect:dialog.atLeastOneInspectionQuantityRequired'
				) + '！'
			);
			handleDialogWarning();
			// setWarningMessage('必須至少輸入一個檢驗數量' + '！');
			// handleDialogWarning();
		} else {
			e.preventDefault();
			toggle();
		}
	};

	//#endregion

	//#region Modal搜尋

	// 搜尋
	const searchModalBtn = () => {
		//取得搜尋文字
		let searchModalInputText = searchModalInput.current.value.toUpperCase();

		let modalTrList = document.querySelectorAll('.modalTr');

		if (searchModalInputText !== '') {
			//隱藏全部欄位
			modalTrList.forEach((item) => {
				item.classList.add('d-none');
			});

			// 搜尋每個Tr中的每個Td欄位
			modalTrList.forEach((item) => {
				// console.log(item.children);

				[...item.children].forEach((td) => {
					if (
						td.textContent
							.toUpperCase()
							.includes(searchModalInputText)
					) {
						td.parentElement.classList.remove('d-none');
					}
				});
			});

			//顯示取消搜尋btn
			document
				.querySelector('.cancelSearchModalBtn')
				.classList.remove('d-none');
		}
	};

	// 取消搜尋
	const cancelSearchModalBtn = (i) => {
		// 顯示全部欄位
		document.querySelectorAll('.modalTr').forEach((item) => {
			item.classList.remove('d-none');
		});

		// 清空搜尋欄位
		searchModalInput.current.value = '';

		// 隱藏取消搜尋btn
		document.querySelector('.cancelSearchModalBtn').classList.add('d-none');
	};

	//#endregion

	//#region Modal clickTableRow

	function clickTableRow(e) {
		//點選tr 使checkbox被選取
		let majorCheck =
			e.target.parentElement.children[0].querySelector('input');
		//避免input無法直接點擊
		if (e.nodeName != 'INPUT' && e.nodeName != 'LABEL') {
			majorCheck.checked = !majorCheck.checked;
		}
	}

	//#endregion

	//#region Modal儲存

	// function modalSave() {
	// 	toggle();

	// 	// 選取哪些checkbox
	// 	const handleSelectedData = () => {
	// 		const selectedId = [];
	// 		const selectedList = [];

	// 		document
	// 			.querySelectorAll('.modal-content [type=checkbox]')
	// 			.forEach((el) => {
	// 				el.checked && selectedId.push(el.id);
	// 			});
	// 		// console.log(selectedId);

	// 		selectedId.forEach((id) => {
	// 			let item = modalData.find(
	// 				(el) => el[`${btnKeyName}Guid`] == id
	// 			);

	// 			selectedList.push(item);
	// 		});
	// 		// console.log(selectedList);

	// 		return selectedList;
	// 	};
	// }

	//#endregion

	//#region Modal取消(關閉)

	const modalCancel = () => {
		toggle();
	};

	//#endregion

	//#region Modal排序

	let headTitleList = [];
	let bodyTitleList = [];

	if (btnKeyName === 'material') {
		headTitleList = ['代碼', '名稱', '分類'];
		bodyTitleList = ['materialNo', 'name01', 'materialClassName'];
	} else if (btnKeyName === 'assets') {
		headTitleList = ['代碼', '名稱', '分類'];
		bodyTitleList = ['assetsNo', 'name01', 'assetsClassName'];
	}

	const [orderBy, setOrderBy] = useState('asc');
	const [UISort, setUISort] = useState(
		btnKeyName === 'material' ? 'materialNo' : 'assetsNo'
	);
	const { items, requestSort, sortConfig } = useSortableData(
		modalData,
		bodyTitleList[0],
		'ascending'
	);

	const handleSortChange = (newTarget) => {
		const oldTarget = UISort;
		setUISort(newTarget);

		if (oldTarget === newTarget) {
			setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
		} else {
			setOrderBy('asc');
		}

		setModalData(items);
	};

	//比較輸入序號是否與後端資料相符

	//20230612重組序號比較陣列
	function CompareApp() {
		tableBodyData[rowIndex].serialList = serialList;
		const newSerialListArray = [];
		tableBodyData.forEach((item) => {
			// 檢查是否有 key 為 serialList 的屬性
			if (item.hasOwnProperty('serialList')) {
				// 將 serialList 陣列值加入到 A 陣列中
				newSerialListArray.push(item.serialList);
			}
		});

		console.log('全部的序號', newSerialListArray);

		// 分割A数组为C和D数组
		//arrayC：除了被挑以外的序號陣列
		const arrayC = newSerialListArray.filter(
			(element, index) => index !== rowIndex
		);
		//arrayD：挑到的的序號陣列
		const arrayD = [newSerialListArray[rowIndex]];

		//將挑到的 C 陣列展開
		const flattenedArrayC = arrayC.flat();

		//將挑到的 D 陣列展開
		const flattenedArrayD = arrayD.flat();

		// 比较展开后的数组C和数组D的元素
		const duplicates = 123;

		console.log('展開的序號', flattenedArrayC);

		console.log('挑出重複的序號', duplicates);

		if (duplicates.length !== 0) {
			setWarningMessage1(
				t(
					'lgsPurchaseOrderGoodsInspect:dialog.deleteDuplicateSerialNumber',
					{
						value: duplicates,
					}
				)
			);
			handleDialogWarning();
			return false;
		} else {
			return true;
		}
	}

	const modalSave = () => {
		const res = CompareApp();

		//---------新方法

		//--------------------------------------
		if (!res) return;

		if (snCount != tableBodyData[rowIndex].thisQuantityInspect) {
			setWarningMessage1(
				t(
					'lgsPurchaseOrderGoodsInspect:dialog.materialSerialNumberCountMismatch'
				)
			);
			handleDialogWarning();
			return;
		}

		//return alert('物料序號筆數與檢驗數量不符');

		setSerialList(serialList);
		// console.log('267-全部輸入序號', serialList);

		const valuesIsInSerialNoList = serialList.filter((value) =>
			serialNoList.includes(value)
		);

		// console.log('312-相符序號', valuesIsInSerialNoList);

		const valuesNotInSerialNoList = serialList.filter(
			(value) => !serialNoList.includes(value)
		);

		//console.log('318-不符的序號', valuesNotInSerialNoList);
		//重新排序序號
		const newArray = [
			...valuesNotInSerialNoList,
			...valuesIsInSerialNoList,
		];
		setValuesNotInSerialNoList(valuesNotInSerialNoList);
		setValuesIsInSerialNoList(valuesIsInSerialNoList);
		const newData = [...tableBodyData];
		newData[rowIndex].serialList = valuesIsInSerialNoList;
		newData[rowIndex].snCount = snCount;
		setTableBodyData(newData);
		//console.log('348', tableBodyData);
		//console.log('349', valuesNotInSerialNoList);

		if (valuesNotInSerialNoList.length !== 0) {
			setWarningMessage(
				t(
					'lgsPurchaseOrderGoodsInspect:dialog.deleteErrorOrBlankSerialNumber',
					{
						value: valuesNotInSerialNoList,
					}
				)
			);
			handleDialogWarningNo();
		} else if (valuesNotInSerialNoList.length === 0) {
			newData[rowIndex].serialString = serialString;
			newData[rowIndex].snCount = snCount;

			toggle();
		}

		// //將排序好的序號填回 textarea 以供顯示
		setNewArrayData(newArray);
		const textareaValue = newArray.join('\n');
		setSerialString(textareaValue);
	};

	if (isDeleted) {
		const textareaValue = valuesIsInSerialNoList.join('\n');
		//將序號重設為後端有的
		setSerialString(textareaValue);
		const newData = [...tableBodyData];
		newData[rowIndex].serialString = textareaValue;
		setTableBodyData(newData);
		setSerialList(valuesIsInSerialNoList);
		console.log(serialList);
		console.log(tableBodyData);
		setIsDeleted(false);
	}
	{
		// const newArray = [
		// 	...valuesNotInSerialNoList,
		// 	...valuesIsInSerialNoList,
		// ];
	}

	useEffect(() => {
		console.log(serialString);
		const lines = serialString.split('\n');
		const arrayValue = lines.map((line) => line.trim());
		const newCount = arrayValue[0] ? arrayValue.length : 0;
		console.log(arrayValue);

		setSerialList(arrayValue);
		setSnCount(newCount);
	}, [serialString]);

	//#endregion

	//console.log('serialString\n\n\n', serialString);
	//console.log('serialList, ', serialList);

	const handleSerialChange = (val) => {
		setSerialString(val);
		// console.log(val);

		// const count = serialList.length++;
		// setSnCount(count);
		// console.log(snCount);
		// console.log(count, serialString);
	};

	//console.log(serialList);
	//console.log(tableBodyData);

	return (
		<div>
			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen1}
				setIsWarningOpen={setIsWarningOpen1}
				warningMessage={warningMessage1}
			/>

			<DialogWarning_No
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				isDeleted={isDeleted}
				setIsDeleted={setIsDeleted}
				warningMessage={warningMessage}
			/>

			{/* 開啟序號 button */}

			<BtnNormal2
				className="btn-grey0 d-inline-block me-2 smallBtn"
				keyName={btnKeyName}
				word={btnWord}
				handleClick={(e) =>
					clickCreateModal(e, btnKeyName, rowIndex, tableBodyData)
				}
			>
				<CreateBlueIcon className="me-2" />
			</BtnNormal2>

			{/* Modal */}
			<Modal
				isOpen={modal}
				toggle={toggle}
				centered={true}
				style={{
					maxWidth: '544px',
					border: 'none',
				}}
			>
				<ModalHeader className="position-relative pt-4 pb-2 ps-4">
					<p
						style={{ fontSize: '20px', color: 'var(--primary)' }}
						className="fw-bold mb-1"
					>
						{t(
							'lgsPurchaseOrderGoodsInspect:dialog.materialSerialNumber'
						)}
					</p>
					<button
						style={{ top: '12px', right: '12px' }}
						type="button"
						className="close position-absolute btn"
						onClick={toggle}
					>
						<Icn_Cancel />
					</button>
				</ModalHeader>
				<ModalBody className="px-6 pt-6 pb-0">
					<form asp-action="Index" method="get">
						<div className="modal-body p-0">
							<div className="wrap mx-2">
								<div className="row">
									<div className="position-relative col-12 col-md-5 p-0 mb-3">
										<SearchModalInput
											type="text"
											name="searchModalInput"
											ref={searchModalInput}
											className="form-control searchModalInput"
											placeholder={t(
												'lgsPurchaseOrderGoodsInspect:dialog.quickSearch'
											)}
											onChange={() => {}}
										/>
										<ModalSearchBtn
											type="button"
											className="searchModalBtn position-absolute border-0 btn btn-primaryllt p-0 d-flex align-items-center"
											onClick={() => searchModalBtn()}
										>
											<Icn_Search2 />
										</ModalSearchBtn>
										<a
											className="cancelSearchModalBtn position-absolute d-none"
											style={{
												top: '4px',
												right: '48px',
												cursor: 'pointer',
											}}
											onClick={() =>
												cancelSearchModalBtn()
											}
										>
											<Icn_InputYellowCancel />
										</a>
									</div>
								</div>
								<div
									className="row fw-bold text-secondary bg-primaryllt  border border-primarylt"
									style={{
										height: '40px',
										lineHeight: '40px',
										borderRadius: '12px 12px 0 0',
										textAlign: 'right',
										direction: 'rtl',
										paddingRight: '15px',
										color: '#616E83',
									}}
								>
									{/* 共 {tableBodyData[rowIndex].snCount} 筆 */}
									{/* serialList[0] ? serialList.length : 0 */}
									{t(
										'lgsPurchaseOrderGoodsInspect:dialog.totalRecords',
										{
											value: snCount,
										}
									)}
								</div>
								<div
									style={{ height: '240px' }}
									className="row"
								>
									<div
										style={{
											height: '240px',
											overflowY: 'scroll',
										}}
										className="col-12 px-0 border border-primaryllt rounded-xl"
									>
										{/* {console.log(tableBodyData)} */}

										<Textarea
											className="w-100 border-0"
											value={serialString}
											onChange={(e) =>
												handleSerialChange(
													e.target.value
												)
											}
											// onChange={(e) =>
											// 	setSerialString(e.target.value)
											// }
										/>
									</div>
								</div>
							</div>
						</div>
						<hr className="border-grey1 mt-5 mb-0" />

						<div className="col-lg-12 d-flex justify-content-center align-items-center">
							{/* dialog 寬為 544，距離兩篇各為60，所以開一個424 ，然後上面使用 justify-content-center 置中 */}
							<div className="" style={{ width: '424px' }}>
								<LabelNormal2 htmlFor={`orderNo`}>
									{t(
										'lgsPurchaseOrderGoodsInspect:dialog.serialNumberRange'
									)}
								</LabelNormal2>
								<div className="">
									<div className="d-flex">
										<div
											className=""
											style={{ width: '200px' }}
										>
											<div className="d-flex flex-column">
												<InputElement
													type="text"
													id=""
													value=""
													disabled={true}
													onChange={() => {}}
												/>
											</div>
										</div>
										<div className="px-2">
											<label className="col-form-label">
												~
											</label>
										</div>
										<div
											className=""
											style={{ width: '200px' }}
										>
											<div className="d-flex flex-column">
												<InputElement
													type="text"
													className=""
													id=""
													value=""
													disabled={true}
													onChange={() => {}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-12 d-flex justify-content-center align-items-center">
							{/* dialog 寬為 544，距離兩篇各為60，所以開一個424 ，然後上面使用 justify-content-center 置中 */}
							<div className="" style={{ width: '424px' }}>
								<LabelNormal2 htmlFor={`orderNo`}>
									{t(
										'lgsPurchaseOrderGoodsInspect:dialog.exclusionRange'
									)}
								</LabelNormal2>
								<div className="">
									<div className="d-flex">
										<div
											className=""
											style={{ width: '200px' }}
										>
											<div className="d-flex flex-column">
												<InputElement
													type="text"
													id=""
													value=""
													disabled={true}
													onChange={() => {}}
												/>
											</div>
										</div>
										<div className="px-2">
											<label className="col-form-label">
												~
											</label>
										</div>
										<div
											className=""
											style={{ width: '200px' }}
										>
											<div className="d-flex flex-column">
												<InputElement
													type="text"
													className=""
													id=""
													value=""
													disabled={true}
													onChange={() => {}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<hr className="border-grey1 mt-5 mb-0" />
						<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
							<button
								type="button"
								className="btn btn-primary mediumBtn mx-1"
								// onClick={comparisonSerialNo}

								// onClick={handleButtonClick}
								onClick={modalSave}
							>
								{t('button.confirmButton')}
							</button>
							<button
								type="button"
								className="btn btn-outline-primarylllt mediumBtn mx-1"
								onClick={modalCancel}
							>
								{t('button.cancelButton')}
							</button>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</div>
	);
};
