import { useState, useEffect, useCallback } from 'react';
import useAxios from '../../../hooks/useAxios';
import PageTitleBar from '../../../components/PageTitleBar/PageTitleBar';
import { useLocation } from 'react-router-dom';
import Page0 from './Pages/Page0/Page0';
import Page1 from './Pages/Page1/Page1';
import Page2 from './Pages/Page2/Page2';
import { DialogConfirm } from './Pages/DialogConfirm';
import { useTranslation } from 'react-i18next';
import Page3 from '../LgsPurchaseOrderGoodsInspect/Pages/Page3/Page3';

function LgsPurchaseOrderGoodsInspect() {
	// i18n
	const { t } = useTranslation();

	// state
	const [parentData, setParentData] = useState({
		isEverEnterPage: {
			page0: false,
			page1: false,
			page2: false,
		},
		page0: {},
		page1: {},
		page2: {},
	});

	const [page, setPage] = useState(0);
	const pageTitle = t('lgsPurchaseOrderGoodsInspect:pageTitle');

	const processSteps = [
		t('lgsPurchaseOrderGoodsInspect:steps.step0'),
		t('lgsPurchaseOrderGoodsInspect:steps.step1'),
		t('lgsPurchaseOrderGoodsInspect:steps.step2'),
	];

	const handlePrevPage = () => {
		setPage(page - 1);
	};

	const handleNextPage = () => {
		setPage(page + 1);
	};

	/**
	 * DialogConfirm 返回btn確認dialog
	 **/
	const [dialogConfirmData, setDialogConfirmData] = useState({
		btnName: '',
		isOpen: false,
		goTo: '',
		title: '',
	});
	const [isConfirmBtnClicked, setIsConfirmBtnClicked] = useState(false);

	// DialogConfirm Modal open
	const handleDialogConfirm = () => {
		const newData = {
			btnName: 'goToRoot',
			isOpen: true,
			goTo: 'prePage',
			title:
				t(
					'lgsPurchaseOrderGoodsInspect:dialog.confirmLeavePurchaseOrderPage'
				) + '?',
		};
		setDialogConfirmData(newData);
	};
	//console.log(dialogConfirmData);

	return (
		<>
			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					goTo={-1}
					isDialogConfirm={true}
					handleDialogConfirm={handleDialogConfirm}
				/>

				{/* DialogConfirm element */}
				<DialogConfirm
					dialogConfirmData={dialogConfirmData}
					setDialogConfirmData={setDialogConfirmData}
					page={page}
					setPage={setPage}
					setIsConfirmBtnClicked={setIsConfirmBtnClicked}
				/>

				<div>
					{page === 0 && (
						<Page0
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handleNextPage={handleNextPage}
						/>
					)}
					{page === 1 && (
						<Page1
							dialogConfirmData={dialogConfirmData}
							setDialogConfirmData={setDialogConfirmData}
							parentData={parentData}
							setParentData={setParentData}
							// setPurchaseOrderData={setPurchaseOrderData}
							// headTitleList={headTitleList}
							// bodyTitleList={bodyTitleList}
							// tableStyles={tableStyles}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							// copyPurchaseOrderData={copyPurchaseOrderData}
							// setCopyPurchaseOrderData={setCopyPurchaseOrderData}
						/>
					)}
					{page === 2 && (
						<Page2
							parentData={parentData}
							setParentData={setParentData}
							//setPurchaseOrderData={setPurchaseOrderData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
							//copyPurchaseOrderData={copyPurchaseOrderData}
							//setCopyPurchaseOrderData={setCopyPurchaseOrderData}
						/>
					)}
					{page === 3 && (
						<Page3
							parentData={parentData}
							setParentData={setParentData}
							page={page}
							setPage={setPage}
							processSteps={processSteps}
							handlePrevPage={handlePrevPage}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default LgsPurchaseOrderGoodsInspect;
