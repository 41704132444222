import React from 'react';
import styled from 'styled-components';
import { LabelNormal } from './shared/LabelNormal';
import { disabledStyle } from './shared/DisabledStyle';
import { ErrMsg } from './shared/ErrMsg';

const Element = styled.textarea`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
`;

function TextareaElement({
    labelText,
    elementStyle,
    type,
    value,
    placeholder,
    onChange,
    isErr,
    errMsg,
    wrapperClass,
    disabled,
    disabledCssType,
    id,
}) {
    return (
        <div className={`wrapper d-flex flex-column mb-3 ${wrapperClass}`}>
            <LabelNormal htmlFor={id}>{labelText}</LabelNormal>
            <Element
                style={elementStyle}
                id={id}
                value={value || ''}
                placeholder={placeholder || ''}
                onChange={onChange}
                disabled={disabled}
                disabledCssType={disabledCssType}
            />
            <ErrMsg
                className={`text-danger mt-1 ${isErr ? 'd-block' : 'd-none'}`}
            >
                {errMsg}
            </ErrMsg>
        </div>
    );
}

export default TextareaElement;
