import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import InvoiceTable from './InvoiceTable';
import { useTranslation } from 'react-i18next';

// #region styled-components

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page2(props) {
	//#region state initiation

	const { t } = useTranslation(['common', 'lgsPurchaseOrderGoodsInvoice']); // i18n

	const {
		parentData,
		setParentData,
		page,
		setPage,
		processSteps,
		handlePrevPage,
		handleNextPage,
	} = props;
	// console.log(props);

	// state
	const [backEndData, setBackEndData] = useState({});
	const [pageData, setPageData] = useState({});
	const { sendRequest: axiosDataPage2 } = useAxios();
	const { decimalPlaceTl, decimalPlaceUp } =
		parentData.page1.backEndData.purchaseOrder;
	const [tableBodyData, setTableBodyData] = useState([]);

	//#endregion

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsPurchaseOrderGoodsInvoice:invoice'),
		t('lgsPurchaseOrderGoodsInvoice:invoiceFormat'),
		t('lgsPurchaseOrderGoodsInvoice:return'),
		t('lgsPurchaseOrderGoodsInvoice:certificateDate'),
		t('lgsPurchaseOrderGoodsInvoice:certificateNumber'),
		t('lgsPurchaseOrderGoodsInvoice:taxRate'),
		t('lgsPurchaseOrderGoodsInvoice:totalTaxableAmount'),
		t('lgsPurchaseOrderGoodsInvoice:netAmount2'),
		t('lgsPurchaseOrderGoodsInvoice:taxAmount2'),
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'governmentInvoice',
		'vatInvoiceFormatViewName',
		'invoiceReturn',
		'invoiceDate',
		'invoiceNo',
		'vatRate',
		'totalAmount',
		'netAmount',
		'taxAmount',
	];

	// 不同 input 有沒有小數點的要求
	const toFloatList = [
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'decimalPlace',
		'decimalPlace',
		'decimalPlace',
	];

	// 不同 input 的 type
	const inputProperties = [
		{ type: 'number' },
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'date' },
		{ type: 'text' },
		{ type: 'number' },
		{ type: 'number' },
		{ type: 'number' },
		{ type: 'number' },
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '72px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'left',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '72px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
	];

	//#endregion

	//#region function

	// 暫存改被修改的資料
	const handleTemporaryStore = () => {
		const newParentData = { ...parentData };
		newParentData.page2 = pageData;
		// 標記是否曾拿取過資料
		newParentData.isEverEnterPage.page2 = true;

		setParentData(newParentData);
	};

	// 往下一頁之前的檢查
	const handleSubmit = () => {
		handleTemporaryStore();
		handleNextPage();
	};

	//#endregion

	//#region useEffect

	useEffect(() => {
		const isEverEnterPage = parentData.isEverEnterPage.page2;

		if (!isEverEnterPage) {
			// 1.axios get BE data
			const url = `api/Logistics/LgsPurchaseOrderGoodsInvoice/getPage3`;
			const method = 'POST';

			axiosDataPage2({ url, method }, async (res) => {
				console.log(res);

				await setBackEndData(res);

				const newPageData = {
					...pageData,
					backEndData: { ...res },
					invoiceItemList: [],
					allAmount: { totalAmount: 0, taxAmount: 0, netAmount: 0 },
				};

				await setPageData(newPageData);
			});
		} else {
			setBackEndData(parentData.page2.backEndData);
			setTableBodyData(parentData.page2.invoiceItemList);
			setPageData(parentData.page2);
		}
	}, []);

	console.log('parentData', parentData);
	//#endregion

	return (
		<>
			<Container className="">
				<Left className="">
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handlePrevPage();
								handleTemporaryStore();
							}}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right className="">
					{page < processSteps.length - 1 ? (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={(e) => {
								e.preventDefault();
								handleSubmit(page);
							}}
						>
							{t('common:button.nextStep')}
						</BtnNormal>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>

			<div className="position-relative">
				<div className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
					<InvoiceTable
						backEndData={backEndData}
						parentData={parentData}
						setParentData={setParentData}
						pageData={pageData}
						setPageData={setPageData}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						tableBodyData={tableBodyData}
						setTableBodyData={setTableBodyData}
						decimalPlaceTl={decimalPlaceTl}
						page={page}
						setPage={setPage}
					/>
				</div>
			</div>
		</>
	);
}

export default Page2;
