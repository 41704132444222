import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { ReactComponent as Icn_chevronTop } from '../../../../../assets/images/icn_chevron-top.svg';
import { ReactComponent as Icn_Trash } from '../../../../../assets/images/icn_Trash.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_InputDialog } from '../../../../../assets/images/icn_inputDialog.svg';
import { ReactComponent as Icn_AlertExclamation } from '../../../../../assets/images/icn_AlertExclamation.svg';
import { DialogTwoCol } from './DialogTwoCol';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const TR = styled.tr`
	height: 40px;

	&:hover {
		background-color: transparent !important;
	}
	&:hover:nth-of-type(0) {
		background-color: var(--primaryllt) !important;
	}
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: none !important;
	background-color: #f7f8fc !important;
	text-align: left !important;
	font-weight: bold !important;

	&:nth-of-type(1) {
		width: 48px;
	}

	&:nth-of-type(2) {
		width: 96px;
	}

	&:nth-of-type(3) {
		width: 336px;
		padding: 0 !important;
		padding-right: 56px !important;
	}
	&:nth-of-type(6) {
		text-align: right !important;
	}
`;

// 錯誤Popup
const DeleteErrPopup = styled.div`
	top: 50px;
	right: -24px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
`;

const QuantityPurchaseErrPopup = styled.div`
	top: 56px;
	right: 0;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
`;

const ChildTD = styled.td`
	height: 40px;
	text-align: center;

	&:first-of-type {
		width: 144px;
	}
`;

const OrderItemImg = styled.img`
	max-width: 96px;
	max-height: 88px;
	object-fit: cover;
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	&:disabled {
		background-color: var(--grey1);
	}
`;

// -----DialogTwoCol------
const DialogWrapper = styled.div`
	position: relative;
	align-items: stretch;
	width: 100%;
`;

const InputDialogElementData = styled.input`
	display: none;
`; // input實際資料欄位

const InputDialogElementView = styled.input`
	flex: 1 1 auto;
	border: 1px solid var(--grey2);
	border-radius: 4px 0 0 4px;
	border-right: 0;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
	pointer-events: none;
	background-color: transparent;
`; // input顯示欄位

const CancelInputBtn = styled.span`
	display: inline-block;
	position: absolute;
	height: 100%;
	top: 3px;
	right: 32px;
	width: 24px;
	text-align: center;
	display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
	cursor: pointer;
`; // 取消按鈕

const DialogBtn = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 8px;
	cursor: pointer;
`; // dialog按鈕

//#endregion

function Page1_Item(props) {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);

	// prop
	const {
		dialogDataFromBE,
		parentData,
		newPurchaseOrderData,
		setNewPurchaseOrderData,
		deletePurchaseOrderData,
		setDeletePurchaseOrderData,
		localDecimalPlaceTl,
		localDecimalPlaceUp,
		convertToFloat,
		reCalculateTopInfo,
	} = props;

	const { inputTaxRate, vendorGuid, orderDate, currencyCode } =
		parentData.page0;

	console.log('newPurchaseOrderData', newPurchaseOrderData);

	// state
	const [showDeleteErrPopup, setShowDeleteErrPopup] = useState(''); // hover 刪除按鈕。哪一個index顯示錯誤alert
	const [showQuantityPurchasePopup, setShowQuantityPurchasePopup] =
		useState(''); // 採購數量 blur判斷，哪一個index顯示錯誤alert

	// axios
	const { sendRequest: axiosGetVendorPrice } = useAxios(); // 計算"價格差異"

	// 型態 => name轉換
	const purchaseCategoryCodeList = {
		M: t('lgsPurchaseOrder:material'),
		E: t('lgsPurchaseOrder:expense'),
		A: t('lgsPurchaseOrder:asset'),
	};

	//#endregion

	//#region handle input change

	const handleInputChange = (keyName, rowIndex, value, decimalPlace) => {
		// console.log(keyName, rowIndex, value, decimalPlace);

		// 1.更新newPurchaseOrderData
		const newData = [...newPurchaseOrderData];

		if (decimalPlace) {
			// 如果此input有decimalPlace限制
			console.log(value);
			const regex = new RegExp(`^(\\d+(\\.\\d{0,${decimalPlace}})?|)$`); // 只允許數字，並且小數點後最多decimalPlace位
			if (regex.test(value)) newData[rowIndex][keyName] = value;
			newData[rowIndex][`${keyName}Warning`] = ''; //　清空錯誤訊息
			setNewPurchaseOrderData(newData);
		} else {
			// 一般的input
			newData[rowIndex][keyName] = value; // input值
			newData[rowIndex][`${keyName}Warning`] = ''; // 清空錯誤訊息
			setNewPurchaseOrderData(newData);
		}

		// 2.重新計算topInfo數值
		reCalculateTopInfo(newData);
	};

	//#endregion

	//#region Dialog Two Col

	const [isOpen, setIsOpen] = useState(false);
	const [indexDialogTwoCol, setIndexDialogTwoCol] = useState('');

	// DialogTwoCol input 黃色按鈕取消
	const handleCancelClick = (index) => {
		// 更新newPurchaseOrderData
		const newData = [...newPurchaseOrderData];
		newData[index]['uomCode'] = '';
		newData[index]['uomName01'] = '';
		newData[index]['uomGuid'] = '';
		setNewPurchaseOrderData(newData);
	};

	// DialogTwoCol Modal open
	const handleOpen = (index) => {
		setIsOpen(true);

		setIndexDialogTwoCol(index); // 獲取真實的index，傳給DialogTwoCol
	};

	//#endregion

	//#region 錯誤訊息共用程式

	const errAlert = useRef(null); // alert卡片，點擊不消失

	useEffect(() => {
		function handleClickOutside() {
			if (
				errAlert.current &&
				!errAlert.current.contains(errAlert.target)
			) {
				// 清空目前有的錯誤訊息
				setShowDeleteErrPopup('');
				setShowQuantityPurchasePopup('');
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []); // 點擊外部關閉視窗

	//#endregion

	//#region hover刪除按鈕，顯示提示訊息

	const handleMouseEnterDeleteCard = (index) => {
		// 刪除時判斷是否有"已收數量"，有的話顯示"不可刪除"
		const newData = [...newPurchaseOrderData];
		newData[index].quantityDelivered && setShowDeleteErrPopup(index); // 顯示哪個index的錯誤訊息
	};

	const handleMouseLeaveDeleteCard = () => {
		setShowDeleteErrPopup('');
	};

	//#endregion

	//#region 刪除卡片
	// a.刪除時判斷是否有"已收數量"，有的話不可以刪除
	// b1.刪除的資料若原本來自於後端，則不刪除，只標記action=delete
	// b2.刪除的資料不來自於後端
	const handleDeleteChange = (rowIndex) => {
		console.log(rowIndex);
		const newData = [...newPurchaseOrderData];
		// console.log(newData[rowIndex].quantityDelivered);

		// a.刪除時判斷是否有"已收數量"，有的話不可以刪除
		if (newData[rowIndex].quantityDelivered) {
			setShowDeleteErrPopup(rowIndex); // 顯示哪個index的錯誤訊息
		} else {
			// b.刪除的資料若原本來自於後端，1.標記action=delete，2.刪除，3.並且將此刪除的資料存到deletePurchaseOrderData
			if (newData[rowIndex].aDataFromBackEnd) {
				newData[rowIndex].action = 'delete';
				setDeletePurchaseOrderData([
					...deletePurchaseOrderData,
					newData[rowIndex],
				]);
			}

			newData.splice(rowIndex, 1); // 刪除

			newData.forEach((el, index) => (el.displayOrder = index + 1)); // 重新賦予displayOrder

			setNewPurchaseOrderData(newData);

			// 重新計算topInfo數值
			reCalculateTopInfo(newData);
		}
	};
	console.log('deletePurchaseOrderData', deletePurchaseOrderData);

	//#endregion

	//#region 改變數值，重新計算(blur判斷)
	const handleQuantityPurchaseBlur = async (
		e,
		materialGuid,
		index,
		netPrice,
		quantityDelivered
	) => {
		// console.log(index, e, quantityDelivered);

		// 1.更新newPurchaseOrderData
		const newData = [...newPurchaseOrderData];

		// 修改時，value不可低於已收數量，否則直接改為已收數量
		if (e.target.value < quantityDelivered) {
			newData[index].quantityPurchase = e.target.value;
			// setShowQuantityPurchasePopup(index); // 顯示訊息，對應哪個index發生錯誤
			newData[index].quantityPurchaseWarning =
				t('lgsPurchaseOrder:cannotBeLessThanReceivedQuantity') + '!';

			// 以"已收數量"，更新netAmount與taxAmount
			handleInputChange(
				`netAmount`,
				index,
				convertToFloat(
					newData[index].netPrice * e.target.value,
					localDecimalPlaceTl
				),
				localDecimalPlaceTl
			);
			handleInputChange(
				`taxAmount`,
				index,
				convertToFloat(
					(newData[index].netPrice * e.target.value * inputTaxRate) /
						100,
					localDecimalPlaceTl
				),
				localDecimalPlaceTl
			);
		} else {
			newData[index].quantityPurchase = e.target.value; // input值
		}

		setNewPurchaseOrderData(newData);

		// 2.重新計算topInfo數值
		reCalculateTopInfo(newData);

		// 3.若是物料，更新價格差異
		if (materialGuid) {
			try {
				const url = `api/Logistics/LgsPurchaseOrder/getMaterialMasterVendorPrice?MaterialGuid=${materialGuid}&VendorGuid=${vendorGuid}&OrderDate=${orderDate}&Quantity=${e.target.value}&CurrencyCode=${currencyCode}`;

				axiosGetVendorPrice({ url }, (res) => {
					console.log('materialMasterVendorPrice', res);

					handleInputChange(`materialMasterVendorPrice`, index, res);

					// 假使先填"單價"再填"採購數量"，更新價格差異
					if (netPrice && res !== 0) {
						let diffPriceOrigin = ((netPrice - res) / res) * 100;

						handleInputChange(
							`diffPrice`,
							index,
							Math.round(
								diffPriceOrigin *
									Math.pow(10, localDecimalPlaceUp)
							) / Math.pow(10, localDecimalPlaceUp)
						);
					}
				});
			} catch (error) {
				console.error(error);
			}
		}
	};

	//#endregion

	return (
		<div className="position-relative">
			<IndexTableContainer className="firstRowMinHeight d-flex flex-column p-4 position-relative">
				<IndexTable className="table PurchaseOrderCardWrapper">
					<TBody>
						{newPurchaseOrderData?.map((data, index) => {
							return (
								<React.Fragment
									key={`"purchaseOrderDataItem"${index}`}
								>
									{/* 父層 */}
									<TR className="list-item table-item">
										<TD>{index + 1}</TD>
										<TD
											className={`text-left p-2 text-center`}
										>
											{/* 資料顯示轉換 -型態  */}
											{data.purchaseCategoryCode === 'M'
												? purchaseCategoryCodeList.M
												: data.purchaseCategoryCode ===
												  'A'
												? purchaseCategoryCodeList.A
												: data.purchaseCategoryCode ===
												  'E'
												? purchaseCategoryCodeList.E
												: data.purchaseCategoryCode}
										</TD>

										<TD
											className={`text-left p-2 text-center`}
										>
											{data.cardName}
										</TD>

										<TD className={`px-0`}>
											<div className="d-flex">
												<span className="flex-grow-1 collapsedItem">
													{data.quantityPurchase}
												</span>
												<span className="flex-grow-1 collapsedItem">
													{data.uomName01}
												</span>
											</div>
										</TD>
										<TD></TD>

										<TD className="text-end position-relative">
											<span className="me-5 collapsedItem">
												{data.netAmount}
											</span>

											{/* 刪除垃圾桶 */}
											<div
												className="d-inline-block"
												style={{
													cursor: 'pointer',
												}}
												index={1}
												onClick={(e) =>
													handleDeleteChange(index, e)
												}
												onMouseEnter={() =>
													handleMouseEnterDeleteCard(
														index
													)
												}
												onMouseLeave={
													handleMouseLeaveDeleteCard
												}
											>
												<Icn_Trash />
											</div>

											{/* 刪除-錯誤訊息 */}
											{showDeleteErrPopup === index && (
												<DeleteErrPopup
													className="DeleteErrPopup position-absolute d-flex justify-content-center align-items-center bg-white rounded-1 py-2 px-3"
													ref={errAlert}
												>
													<span className="text-danger">
														<Icn_AlertExclamation className="me-2" />
														{t(
															'lgsPurchaseOrder:cannotDeleteWithReceivedItems'
														)}
														!
													</span>
												</DeleteErrPopup>
											)}

											{/* 展開按鈕 */}
											<div
												className={`d-inline-block text-left p-2 text-center`}
												style={{
													cursor: 'pointer',
												}}
												onClick={(e) => {
													// icon箭頭方向
													e.target.closest('svg')
														.style.transform === ''
														? (e.target.closest(
																'svg'
														  ).style.transform =
																'rotate(.5turn)')
														: (e.target.closest(
																'svg'
														  ).style.transform =
																'');

													// 是否顯示子層
													e.target
														.closest('tr')
														.nextElementSibling.classList.contains(
															'd-none'
														)
														? e.target
																.closest('tr')
																.nextElementSibling.classList.remove(
																	'd-none'
																) && alert(1)
														: e.target
																.closest('tr')
																.nextElementSibling.classList.add(
																	'd-none'
																);

													e.target.closest(
														'svg'
													).style.transform =
														'rotate("0.5turn")';
												}}
											>
												<Icn_chevronTop
													className="iconChevron"
													style={{
														transform:
															index === 0
																? ''
																: 'rotate(.5turn)',
													}}
												/>
											</div>
										</TD>
									</TR>

									{/* 子層 */}
									<tr
										// 1.第一個預設開啟
										// className={`${index !== 0 && 'd-none'} childInfoTr rounded-xxl`}
										// 2.全部預設開啟
										className={`childInfoTr rounded-xxl`}
									>
										{/* {console.log(index)}
											{console.log(data)} */}

										{data.length !== 0 && (
											<>
												<ChildTD
													colSpan={2}
													className="p-0 border-0"
												>
													<div className="h-100 p-4">
														<OrderItemImg
															className="rounded-xxl"
															src={
																location.origin +
																data.imageUrl
															}
															alt="OrderItemImg"
														/>
													</div>
												</ChildTD>
												<ChildTD className="ps-0 pe-8 py-0 border-0">
													<div className="py-4 text-align-left text-grey4">
														<span>
															{data.cardChildName}
														</span>
														<br />

														{data?.cardChildName2 ? (
															<span>
																{
																	data?.cardChildName2
																}
															</span>
														) : (
															<span className="invisible">
																123
															</span>
														)}
													</div>
													<div className="pt-2 pb-4 text-align-left">
														<div
															className={`wrapper d-flex flex-column "mb-3`}
														>
															<TextareaElement
																style={{
																	minHeight:
																		'0px',
																}}
																id={`note${index}`}
																rows={2}
																value={
																	data.note ||
																	''
																}
																onChange={(
																	e
																) => {
																	handleInputChange(
																		`note`,
																		index,
																		e.target
																			.value
																	);
																}}
															/>
														</div>
													</div>
												</ChildTD>

												<ChildTD className="ps-0 pe-6 py-0 border-0">
													<div className="pt-4 d-flex text-align-left text-grey4">
														<div
															className={`wrapper position-relative row flex-column mb-3 pe-5 flex-fill`}
														>
															<LabelNormal
																htmlFor={`quantityPurchase${index}`}
															>
																{t(
																	'lgsPurchaseOrder:purchaseQuantity'
																)}
															</LabelNormal>
															<InputElement
																style={{
																	minWidth:
																		'120px',
																}}
																className="flex-fill"
																type="number"
																min="1"
																id={`quantityPurchase${index}`}
																value={
																	data.quantityPurchase
																}
																onChange={(
																	e
																) => {
																	handleInputChange(
																		`quantityPurchase`,
																		index,
																		e.target
																			.value,
																		null
																	);

																	handleInputChange(
																		`netAmount`,
																		index,
																		convertToFloat(
																			data.netPrice *
																				e
																					.target
																					.value,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceTl
																	);
																	handleInputChange(
																		`taxAmount`,
																		index,
																		convertToFloat(
																			(data.netPrice *
																				e
																					.target
																					.value *
																				inputTaxRate) /
																				100,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceTl
																	);
																}}
																onBlur={(e) =>
																	handleQuantityPurchaseBlur(
																		e,
																		data.materialGuid,
																		index,
																		data.netPrice,
																		data.quantityDelivered
																	)
																}
															/>
															<span className="text-danger p-0 mt-1">
																{
																	data.quantityPurchaseWarning
																}
															</span>

															{/* 採購-錯誤訊息 */}
															{showQuantityPurchasePopup ===
																index && (
																<QuantityPurchaseErrPopup
																	className="errAlert position-absolute d-flex justify-content-center align-items-center bg-white rounded-1 py-2"
																	ref={
																		errAlert
																	}
																>
																	<span className="text-danger">
																		<Icn_AlertExclamation className="me-2" />
																		{t(
																			'lgsPurchaseOrder:cannotBeLessThanReceivedQuantity'
																		)}
																		!
																	</span>
																</QuantityPurchaseErrPopup>
															)}
														</div>

														{/* 計量單位dialogTwoCol */}
														{/* input btn */}
														<div
															className={`wrapper d-flex flex-column mb-3 flex-fill`}
														>
															<LabelNormal
																htmlFor={`uomCode${index}`}
															>
																{t(
																	'lgsPurchaseOrder:unitOfMeasure'
																)}
															</LabelNormal>
															<DialogWrapper className="row flex-nowrap">
																<InputDialogElementData
																	type="text"
																	id={`uomCode${index}`}
																	value={
																		newPurchaseOrderData[
																			index
																		][
																			'uomGuid'
																		] || ''
																	}
																	disabled={
																		!data.quantityDelivered &&
																		data.purchaseCategoryCode ===
																			'E' &&
																		false
																	}
																	onChange={() => {}}
																/>
																<InputDialogElementView
																	style={{
																		minWidth:
																			'85px',
																		backgroundColor:
																			!data.quantityDelivered &&
																			data.purchaseCategoryCode ===
																				'E'
																				? '#fff'
																				: 'var(--grey1)',
																		cursor: 'auto',
																	}}
																	disabled={
																		!data.quantityDelivered &&
																		data.purchaseCategoryCode ===
																			'E' &&
																		false
																	}
																	value={
																		data.uomName01 ||
																		''
																	}
																	onChange={() => {}}
																/>

																<CancelInputBtn
																	onClick={() => {
																		!data.quantityDelivered &&
																			data.purchaseCategoryCode ===
																				'E' &&
																			handleCancelClick(
																				index
																			);
																	}}
																	// 費用分類並且uomName01有值，則存在刪除按鈕
																	isValueExist={
																		(!data.quantityDelivered &&
																			data.purchaseCategoryCode ===
																				'E' &&
																			data.uomName01) ||
																		false
																	}
																>
																	<Icn_InputYellowCancel />
																</CancelInputBtn>

																<DialogBtn
																	style={{
																		backgroundColor:
																			!data.quantityDelivered &&
																			data.purchaseCategoryCode ===
																				'E'
																				? '#fff'
																				: '#EFF2F5',
																		cursor:
																			!data.quantityDelivered &&
																			data.purchaseCategoryCode ===
																				'E'
																				? 'pointer'
																				: 'auto',
																	}}
																	onClick={() => {
																		!data.quantityDelivered &&
																			data.purchaseCategoryCode ===
																				'E' &&
																			handleOpen(
																				index
																			);
																	}}
																>
																	<Icn_InputDialog />
																</DialogBtn>
															</DialogWrapper>
														</div>

														{/* DialogTwoCol element */}
														<DialogTwoCol
															index={
																indexDialogTwoCol
															}
															purchaseCategoryCode={
																data.purchaseCategoryCode
															}
															props={props}
															isOpen={isOpen}
															setIsOpen={
																setIsOpen
															}
															dialogDataFromBE={
																dialogDataFromBE
															}
														/>
													</div>
													<div className="pb-0 d-flex text-align-left">
														<div
															className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
														>
															<LabelNormal
																htmlFor={`deliverDate${index}`}
															>
																{t(
																	'lgsPurchaseOrder:deliveryDate'
																)}
															</LabelNormal>
															<InputElement
																style={{
																	minWidth:
																		'135px',
																}}
																className="flex-fill"
																type="date"
																id={`deliverDate${index}`}
																value={
																	data.deliverDate
																}
																onChange={(
																	e
																) => {
																	handleInputChange(
																		`deliverDate`,
																		index,
																		e.target
																			.value
																	);
																}}
															/>
															<span className="text-danger ms-1 mt-1">
																{
																	data.deliverDateWarning
																}
															</span>
														</div>

														{/* 排版用假div */}
														<div
															className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
														>
															<span
																style={{
																	minWidth:
																		'135px',
																}}
																className="flex-fill"
															></span>
														</div>
													</div>
												</ChildTD>

												<ChildTD
													colSpan="2"
													className="ps-0 pe-4 py-0 border-0"
												>
													<div className="pt-4 d-flex text-align-left text-grey4">
														<div
															className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
														>
															<LabelNormal
																htmlFor={`netPrice${index}`}
															>
																{t(
																	'lgsPurchaseOrder:unitPriceExcludingTax'
																)}
															</LabelNormal>
															<InputElement
																style={{
																	minWidth:
																		'120px',
																}}
																className="flex-fill"
																type="number"
																id={`netPrice${index}`}
																value={
																	data.netPrice
																}
																// 有已收數量則disabled
																disabled={
																	data.quantityDelivered
																}
																onChange={(
																	e
																) => {
																	handleInputChange(
																		`netPrice`,
																		index,
																		e.target
																			.value,
																		localDecimalPlaceUp
																	);
																	handleInputChange(
																		`netAmount`,
																		index,
																		convertToFloat(
																			e
																				.target
																				.value *
																				data.quantityPurchase,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceTl
																	);
																	handleInputChange(
																		`taxAmount`,
																		index,
																		convertToFloat(
																			(e
																				.target
																				.value *
																				data.quantityPurchase *
																				inputTaxRate) /
																				100,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceTl
																	);
																}}
																onBlur={
																	// 假如是物料，並且單價改值，計算價格差異
																	(e) => {
																		if (
																			data.materialMasterVendorPrice
																		) {
																			let diffPriceOrigin =
																				((e
																					.target
																					.value -
																					data.materialMasterVendorPrice) /
																					data.materialMasterVendorPrice) *
																				100;

																			handleInputChange(
																				`diffPrice`,
																				index,
																				Math.round(
																					diffPriceOrigin *
																						Math.pow(
																							10,
																							localDecimalPlaceUp
																						)
																				) /
																					Math.pow(
																						10,
																						localDecimalPlaceUp
																					)
																			);
																		}
																	}
																}
															/>
															<span className="text-danger ms-1 mt-1">
																{
																	data.netPriceWarning
																}
															</span>
														</div>

														<div
															className={`wrapper row flex-column mb-3 flex-fill`}
														>
															<LabelNormal
																htmlFor={`netAmount${index}`}
															>
																{t(
																	'lgsPurchaseOrder:amountExcludingTax'
																)}
															</LabelNormal>
															<InputElement
																style={{
																	minWidth:
																		'120px',
																}}
																className="flex-fill"
																type="number"
																id={`netAmount${index}`}
																value={
																	data.netAmount
																}
																// 有已收數量則disabled
																disabled={
																	data.quantityDelivered
																}
																onChange={(
																	e
																) => {
																	handleInputChange(
																		`netAmount`,
																		index,
																		e.target
																			.value,
																		localDecimalPlaceTl
																	);
																	handleInputChange(
																		`netPrice`,
																		index,
																		convertToFloat(
																			e
																				.target
																				.value /
																				data.quantityPurchase,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceUp
																	);
																	handleInputChange(
																		`taxAmount`,
																		index,
																		convertToFloat(
																			(e
																				.target
																				.value *
																				inputTaxRate) /
																				100,
																			localDecimalPlaceTl
																		),
																		localDecimalPlaceTl
																	);
																}}
																onBlur={
																	// 假如是物料，並且單價改值，計算價格差異
																	(e) => {
																		if (
																			data.materialMasterVendorPrice
																		) {
																			let diffPriceOrigin =
																				((data.netPrice -
																					data.materialMasterVendorPrice) /
																					data.materialMasterVendorPrice) *
																				100;

																			handleInputChange(
																				`diffPrice`,
																				index,
																				Math.round(
																					diffPriceOrigin *
																						Math.pow(
																							10,
																							localDecimalPlaceUp
																						)
																				) /
																					Math.pow(
																						10,
																						localDecimalPlaceUp
																					)
																			);
																		}
																	}
																}
															/>
															<span className="text-danger ms-1 mt-1">
																{
																	data.netAmountWarning
																}
															</span>
														</div>
													</div>
													{/* 如果有從後端來的資料(修改模式) */}
													{data.aDataFromBackEnd && (
														<div className="pb-0 d-flex text-align-left">
															<div
																className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
															>
																<LabelNormal
																	htmlFor={`quantityDelivered${index}`}
																>
																	{t(
																		'lgsPurchaseOrder:receivedQuantity2'
																	)}
																</LabelNormal>
																<InputElement
																	style={{
																		minWidth:
																			'120px',
																		backgroundColor:
																			'#EFF2F5',
																	}}
																	className="flex-fill"
																	type="number"
																	id={`quantityDelivered${index}`}
																	value={
																		data.quantityDelivered
																	}
																	disabled
																/>
															</div>

															<div
																className={`wrapper row flex-column mb-3 flex-fill`}
															>
																<LabelNormal
																	htmlFor={`quantityNotDelivered${index}`}
																>
																	{t(
																		'lgsPurchaseOrder:unreceivedQuantity'
																	)}
																</LabelNormal>
																<InputElement
																	style={{
																		minWidth:
																			'120px',
																		backgroundColor:
																			'#EFF2F5',
																	}}
																	className="flex-fill"
																	type="number"
																	id={`quantityNotDelivered${index}`}
																	value={
																		data.quantityPurchase -
																		data.quantityDelivered
																	}
																	disabled
																/>
															</div>
														</div>
													)}
													{/* 如果是物料，才有價格差異 */}
													{data.purchaseCategoryCode ===
														'M' && (
														<div className="pb-0 d-flex text-align-left">
															<div
																className={`wrapper row flex-column mb-3 pe-5 flex-fill`}
															>
																<LabelNormal
																	htmlFor={`diffPrice${index}`}
																>
																	{t(
																		'lgsPurchaseOrder:priceDifferencePercentage'
																	)}
																</LabelNormal>
																<InputElement
																	style={{
																		minWidth:
																			'120px',
																		backgroundColor:
																			'#EFF2F5',
																	}}
																	className="flex-fill"
																	type="number"
																	id={`diffPrice${index}`}
																	value={
																		data.diffPrice
																	}
																	disabled
																/>
															</div>

															{/* 排版用假div */}
															<div
																className={`wrapper row flex-column mb-3 flex-fill`}
															>
																<InputElement
																	style={{
																		minWidth:
																			'120px',
																		padding:
																			'6px 8px',
																	}}
																	className="invisible"
																	disabled
																/>
															</div>
														</div>
													)}
												</ChildTD>
											</>
										)}
									</tr>
								</React.Fragment>
							);
						})}
					</TBody>
				</IndexTable>
			</IndexTableContainer>
		</div>
	);
}

export default Page1_Item;
