import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as CopyPage } from '../../../../../assets/images/copyPage.svg';
import Page0Table from './Page0Table';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { DialogWarning } from '../DialogWarning';

// #region styled-components

const Wrapper = styled.div`
	min-height: 720px;
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page0(props) {
	//#region state initialization

	const { t } = useTranslation(['common', 'lgsPurchaseOrderInvoice']); // i18n
	const [cookies] = useCookies();
	const lang = cookies['_system_culture'];

	const { parentData, setParentData, page, processSteps, handleNextPage } =
		props;
	const [purchaseOrderGuid, setPurchaseOrderGuid] = useState('');
	const [advancedSearchShow, setAdvancedSearchShow] = useState(false);
	const [buttonName, setButtonName] = useState(
		t('common:button.advancedSearch')
	);
	const [warningMessage, setWarningMessage] = useState('');
	const [listData, setListData] = useState([]);
	console.log(listData);

	//#endregion
	console.log(purchaseOrderGuid);
	const handlePurchaseOrderNext = () => {
		// console.log(purchaseOrderGuid);
		// filter 所選出來的data，傳到下一頁
		let selectedList = listData.filter((item) => {
			return purchaseOrderGuid.includes(item.purchaseOrderGuid);
		});
		// 重新給displayOrder
		selectedList.forEach((el, index) => {
			el.displayOrder = index + 1;
		});
		console.log(selectedList);

		// 多選時必須相同廠商
		const firstVendorCode = selectedList[0].vendorCode;
		const checkVendorEquality = (arr) =>
			arr.every((el) => el.vendorCode === firstVendorCode);

		if (selectedList.length > 1 && !checkVendorEquality(selectedList)) {
			setWarningMessage(
				t('lgsPurchaseOrderInvoice:selectSameVendorPurchaseOrder')
			);

			return handleDialogWarning();
		} else {
			const newParentData = { ...parentData };
			newParentData.page0.purchaseOrderGuidList = purchaseOrderGuid;
			newParentData.page0.purchaseOrderSelectedList = selectedList;
			setParentData(newParentData);
			handleNextPage();
		}
	};

	//#region DialogWarning

	// 返回btn確認dialog

	const [isWarningOpen, setIsWarningOpen] = useState(false);

	// Modal open
	const handleDialogWarning = () => {
		setIsWarningOpen(true);
	};

	//#endregion

	// 每次回到page0清空parentData
	useEffect(() => {
		const newParentData = {
			...parentData,
			isEverEnterPage: {
				page0: false,
				page1: false,
				page2: false,
				page3: false,
			},
			page1: {},
			page2: {},
			page3: {},
		};

		setParentData(newParentData);
	}, []);

	useEffect(() => {
		setButtonName(t('common:button.advancedSearch'));
	}, [lang, t('common:button.advancedSearch')]);

	return (
		<>
			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				warningMessage={warningMessage}
			/>

			{/* 上下步驟 */}
			<Container>
				<Left>
					<BtnNormal2
						className="btn-primary d-inline-block me-2 mediumSmallBtn"
						keyName="advancedSearchCancel"
						word={buttonName}
						handleClick={() => {
							setAdvancedSearchShow(true);
							// setButtonName(t('common:button.advancedSearch'));
						}}
					>
						<CopyPage className="me-2 mb-1" />
					</BtnNormal2>
				</Left>
				<Center>
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					{page < processSteps.length - 1 ? (
						<BtnNormal2
							className="btn-primary d-inline-block me-2 mediumBtn"
							keyName="advancedSearchCancel"
							word={t('common:button.nextStep')}
							handleClick={() => {
								handlePurchaseOrderNext(purchaseOrderGuid);
							}}
							disabled={!purchaseOrderGuid}
						/>
					) : (
						<BtnNormal2 className="d-none" />
					)}
				</Right>
			</Container>

			<div className="position-relative">
				<Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
					<Page0Table
						singleChecked={purchaseOrderGuid}
						setSingleChecked={setPurchaseOrderGuid}
						advancedSearchShow={advancedSearchShow}
						setAdvancedSearchShow={setAdvancedSearchShow}
						setListData={setListData}
					/>
				</Wrapper>
			</div>
		</>
	);
}

export default Page0;
