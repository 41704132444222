import React from 'react';
import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';

export const BtnSmall = styled.button`
    height: 35px;
    width: 35px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 8px; */
`;
