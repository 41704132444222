import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { BtnNormal2 } from '../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as CopyPage } from '../../../../../assets/images/copyPage.svg';
import Page0Table from './Page0Table';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { useTranslation } from 'react-i18next';
import useAxios from '../../../../../hooks/useAxios';
import { purchaseOrderAdvancedSearchData as searchContentList } from '../../../../../utils/advancedSearchData';

// #region styled-components

const Wrapper = styled.div`
	min-height: 720px;
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page0(props) {
	//#region state initiation
	// const { t, i18n } = useTranslation(['common', 'lgsPurchaseOrderPaymentClose']);
	const {
		parentData,
		setParentData,
		page,
		setPage,
		processSteps,
		handleNextPage,
		emptyPaymentData,
		t,
	} = props;
	const [purchaseOrderGuid, setPurchaseOrderGuid] = useState('');
	const [advancedSearchShow, setAdvancedSearchShow] = useState(false);
	const [buttonName, setButtonName] = useState('');
	const [fetchedData, setFetchedData] = useState([]);
	const [displayColumns, setDisplayColumns] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	// const sortBy = 'orderDate desc';
	const [sortBy, setSortBy] = useState('orderDate desc');
	const guidName = 'purchaseOrderGuid';
	const { sendRequest: axiosIndexData } = useAxios();

	const headTitleList = [
		'#',
		t('lgsPurchaseOrderPaymentClose:index.vendorCode'),
		t('lgsPurchaseOrderPaymentClose:index.vendorName'),
		t('lgsPurchaseOrderPaymentClose:index.orderDate'),
		t('lgsPurchaseOrderPaymentClose:index.orderNo'),
		t('common:currency'),
		t('lgsPurchaseOrderPaymentClose:index.payable'),
		t('lgsPurchaseOrderPaymentClose:index.taxable'),
		t('lgsPurchaseOrderPaymentClose:index.localPayable'),
		t('lgsPurchaseOrderPaymentClose:index.localTaxable'),
	];

	const bodyTitleList = [
		'displayOrder',
		'vendorCode',
		'vendorName',
		'orderDate',
		'orderNo',
		'currencyCode',
		'accountPayableBalance',
		'purchaseTaxableTotal',
		'displayAccountPayableBalance',
		'displayPurchaseTaxableTotal',
	];

	const tableStyles = [
		{
			minWidth: '54px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '240px',
			textAlign: 'left',
		},
		{
			minWidth: '120px',
			textAlign: 'left',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '72px',
			textAlign: 'left',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
		{
			minWidth: '160px',
			textAlign: 'right',
		},
	];

	const columnMustShow = ['displayOrder', 'orderDate', 'orderNo'];

	// 進階搜尋 一進去的第一筆的模板
	const advancedSearchDataTemp = {
		searchLogic: '',
		column: searchContentList[0].column,
		searchOperator: 'contains',
		searchValue: '',
		searchContent: '',
		inputWarningContent: '',
	};

	// 進階搜尋實際資料
	const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
	// 進階搜尋畫面資料
	const [displayList, setDisplayList] = useState([]);

	const fetchData = (
		advancedSearchDataList = null,
		pageSize = 20,
		sort = sortBy,
		pageNumber = 1
	) => {
		// TODO:  (1) 【採購訂單主檔】[收貨狀態]不等於"1"  (2) 【採購訂單主檔】[訂單狀態]="0"的訂單。

		// const pageString = page ? `&page=${page}` : '';
		const url = `api/Logistics/LgsPurchaseOrderPaymentClose/GetList`;

		// 如果只有一個查詢條件, 並不需要 AND/OR, 所以要把 searchLogic 清空
		if (advancedSearchDataList && advancedSearchDataList.length === 1) {
			advancedSearchDataList[0].searchLogic = '';
		}

		axiosIndexData(
			{
				url,
				method: 'post',
				data: {
					sort,
					pageSize,
					page: pageNumber,
					advancedSearchDataList,
				},
			},
			(res) => {
				const { indexData, permission, numberOfData, totalPage } = res;

				setPaginationData({
					...paginationData,
					numberOfData: numberOfData,
					totalPage: totalPage,
					pageNumber: pageNumber,
				});
				setFetchedData({
					indexData,
					permission,
					paginationData,
				});

				function isArrayFn(obj) {
					// 包成函式
					if (typeof Array.isArray === 'function') {
						return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
					} else {
						// 否則就使用 toString 方法
						return (
							Object.prototype.toString.call(obj) ===
							'[object Array]'
						);
					}
				}

				if (isArrayFn(advancedSearchDataList)) {
					// 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
					// 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
					advancedSearchDataList.map((el) => {
						searchContentList.map((item) => {
							// console.log(item);
							if (el.searchFilter === item.tableColumn) {
								el.searchFilter = item.searchFromDataBase;
							}
						});
					});

					setDisplayList(advancedSearchDataList);
					setAdvancedSearchDataList([...advancedSearchDataList]);
				}

				setAdvancedSearchShow(false);
				localStorage.setItem('Permission_LgsPurchaseOrder', permission);
			}
		);
	};

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			return data;
		};

		return gettingData();
	}, []);

	//#endregion

	const handlePaymentNext = () => {
		const newParentData = { ...parentData };
		newParentData.purchaseOrderGuid = purchaseOrderGuid;
		setParentData(newParentData);
		handleNextPage();
	};

	// 每次回到page0清空parentData
	useEffect(() => {
		setParentData(emptyPaymentData);
	}, []);

	useEffect(() => {
		setButtonName(t('common:button.advancedSearch'));
	}, [t]);

	useEffect(() => {
		// fetchIndexInfo();
		getLocalStorageData();
		fetchData();

		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);

	return (
		<>
			{/* 上下步驟 */}
			<Container>
				<Left>
					<BtnNormal2
						className="btn-primary d-inline-block me-2 mediumSmallBtn"
						keyName="advancedSearchCancel"
						word={buttonName}
						handleClick={() => {
							setAdvancedSearchShow(true);
							setButtonName(t('common:button.re-search'));
						}}
					>
						<CopyPage className="me-2" />
					</BtnNormal2>
				</Left>
				<Center>
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					{page < processSteps.length - 1 ? (
						<BtnNormal2
							className="btn-primary d-inline-block me-2 mediumBtn"
							keyName="advancedSearchCancel"
							word={t('common:button.nextStep')}
							handleClick={() => {
								handlePaymentNext(purchaseOrderGuid);
							}}
							disabled={!purchaseOrderGuid}
						/>
					) : (
						<BtnNormal2 className="d-none" />
					)}
				</Right>
			</Container>

			<div className="position-relative">
				<Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
					<Page0Table
						table="page0Table"
						guidName={guidName}
						singleChecked={purchaseOrderGuid}
						setSingleChecked={setPurchaseOrderGuid}
						advancedSearchShow={advancedSearchShow}
						setAdvancedSearchShow={setAdvancedSearchShow}
						fetchData={fetchData}
						fetchedData={fetchedData}
						setFetchedData={setFetchedData}
						sortBy={sortBy}
						setSortBy={setSortBy}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableStyles={tableStyles}
						columnMustShow={columnMustShow}
						displayColumns={displayColumns}
						setDisplayColumns={setDisplayColumns}
						paginationData={paginationData}
						setPaginationData={setPaginationData}
						displayList={
							displayList.length > 0
								? displayList
								: [{ ...advancedSearchDataTemp }]
						}
					/>
				</Wrapper>
			</div>
		</>
	);
}

export default Page0;
