//<!-- Plant Modal -->
import { useRef, useState } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_Search2 } from '../../../../assets/images/icn_Search2.svg';
import showAlert from '../../../../utils/swal';

// #region styled-components

const ModalThead = styled.thead`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalTh = styled.th`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
	border: none !important;
`;
const ModalLabel = styled.label`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalInput = styled.input`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;

const SearchModalInput = styled.input`
	height: 35px;
	font-size: 14px;
	padding: 0 0.75rem;
	cursor: pointer !important;
	text-transform: uppercase;
	border-color: #e3e8ee;

	::placeholder {
		color: #a1aab9;
		opacity: 1; /* Firefox */
	}
`;

const ModalSearchBtn = styled.button`
	top: 1px;
	right: 1px;
	height: 33px;
	border-radius: 0 0.25rem 0.25rem 0;
`;

const Ul = styled.ul`
	height: 400px;
	overflow-y: scroll;
	border-radius: 0 0 0.75rem 0.75rem;
	padding: 0;
`;

const Li = styled.li`
	height: 40px;
	display: flex;
	align-items: center;
	padding-left: 16px;
	color: #616e83;

	&:hover {
		background-color: #f9fafd;
	}

	${(props) => (props.active === true ? 'background-color: #f9fafd;' : '')};
`;
//#endregion

export default function UomModal(props) {
	const { t } = useTranslation(['common', 'lgsSalesKit']);
	const {
		modalOpen,
		handleClose,
		uomCategoryList,
		uomList,
		pageData,
		setPageData,
	} = props;
	const [oneCheck, setOneCheck] = useState('');
	const searchModalInput = useRef('');
	const [selectedData, setSelectedData] = useState(null);
	const [selectedCategoryCode, setSelectedCategoryCode] = useState('0');

	// console.log(selectedCategoryCode);

	const headTitleList = [
		'',
		t('lgsPurchaseOrder:dialogCol.number'),
		t('lgsPurchaseOrder:dialogCol.name'),
	];

	const tableHeadStyles = [
		{ width: '48px', textAlign: 'center' },
		{ width: '200px', textAlign: 'left' },
		{ width: '280px', textAlign: 'left' },
	];
	// console.log(props);

	/**
	 *搜尋Modal
	 **/
	const searchModalBtn = () => {
		// //取得搜尋文字
		// let searchModalInputText = searchModalInput.current.value.toUpperCase();

		// let modalTrList = document.querySelectorAll('.modalTr');

		// if (searchModalInputText !== '') {
		//     //隱藏全部欄位
		//     modalTrList.forEach((el) => {
		//         el.classList.add('d-none');
		//     });

		//     // 搜尋每個Tr中的每個Td欄位
		//     modalTrList.forEach((el) => {
		//         // console.log(el.children);

		//         [...el.children].forEach((td) => {
		//             if (
		//                 td.textContent
		//                     .toUpperCase()
		//                     .includes(searchModalInputText)
		//             ) {
		//                 td.parentElement.classList.remove('d-none');
		//             }
		//         });
		//     });

		//     //顯示取消搜尋btn
		//     document
		//         .querySelector('.cancelSearchModalBtn')
		//         .classList.remove('d-none');
		// }

		console.log(searchModalInput.current.value);
	};

	/**
	 *取消搜尋
	 **/
	const cancelSearchModalBtn = (i) => {
		// 顯示全部欄位
		document.querySelectorAll('.modalTr').forEach((el) => {
			el.classList.remove('d-none');
		});

		// 清空搜尋欄位
		searchModalInput.current.value = '';

		// 隱藏取消搜尋btn
		document.querySelector('.cancelSearchModalBtn').classList.add('d-none');
	};

	const selectUomCategory = (categoryCode) => {
		console.log(categoryCode);
		setSelectedCategoryCode(categoryCode);

		// 預設選第一個 計量單位
		// let defaultUom = uomList.filter(
		// 	(uom) => uom.uomcategoryCode === categoryCode
		// );
		// setSelectedData(defaultUom[0]);
	};

	const selectUom = (uom) => {
		console.log(uom);
		setSelectedData(uom);
	};

	const handleSubmit = () => {
		if (selectedData === null) {
			showAlert({
				html: `<div class="mt-4 text-danger font-weight-bold">${t(
					'lgsSalesKit:chooseUnitOfMeasure'
				)}</div>`,
				// 請選擇計量單位
				htmlSvg: 'danger',
				size: 'medium',
				confirmButtonText: t('button.confirmButton'),
				showCloseButton: true,
				allowOutsideClick: false,
				// title: '我是 title 哦',
				// icon: 'warning',
				// showCancelButton: false,
				// cancelButtonText: '取消的字哦',
			});
			return;
		}

		const { uomguid, uomcode, name01 } = selectedData;

		// 清除錯誤提示 TODO
		pageData.goodsItemInfo.uomCodeWarning = '';

		setPageData({
			...pageData,
			goodsItemInfo: {
				...pageData.goodsItemInfo,
				uomDisplayName: `${uomcode} ${name01}`,
				uomGuid: uomguid,
				uomCode: uomcode,
				uomcode: uomcode,
				uomName: name01,
			},
		});

		handleClose();
	};

	return (
		<div>
			<Modal
				isOpen={modalOpen}
				//onHide={handleClose}
				centered={true}
				style={{ maxWidth: '624px' }}
			>
				<ModalHeader className="position-relative pt-4 pb-2 ps-4">
					<p
						style={{ fontSize: '20px' }}
						className="text-primary fw-bold mb-1"
					>
						{t('lgsSalesKit:unitOfMeasure')}
						{/* 計量單位 */}
					</p>
					<button
						style={{ top: '12px', right: '12px' }}
						type="button"
						className="close position-absolute btn"
						onClick={handleClose}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							id="icn_Clear"
							viewBox="0 0 16 16"
							width="16"
							height="16"
						>
							<defs>
								<clipPath id="clip-path-clear22">
									<rect
										id="Rectangle_3088"
										fill="#686868"
										width="10"
										height="10"
										data-name="Rectangle 3088"
									/>
								</clipPath>
							</defs>
							<rect
								id="Rectangle_3086"
								fill="none"
								width="16"
								height="16"
								data-name="Rectangle 3086"
							/>
							<g
								id="Group_6190"
								transform="translate(3 3)"
								data-name="Group 6190"
							>
								<g
									id="Group_6188"
									clipPath='url("#clip-path")'
									data-name="Group 6188"
								>
									<path
										id="Path_1410"
										fill="#686868"
										transform="translate(0)"
										d="M 1.134 0.188 a 0.669 0.669 0 0 0 -0.946 0.946 L 4.054 5 L 0.188 8.866 a 0.669 0.669 0 0 0 0.946 0.946 L 5 5.946 L 8.866 9.812 a 0.669 0.669 0 0 0 0.946 -0.946 L 5.946 5 L 9.812 1.134 A 0.669 0.669 0 0 0 8.866 0.188 L 5 4.054 Z"
										data-name="Path 1410"
									/>
								</g>
							</g>
						</svg>
					</button>
				</ModalHeader>
				<ModalBody className="px-6 pt-6 pb-0">
					<form asp-action="Index" method="get">
						<div className="modal-body p-0">
							<div className="wrap mx-3">
								<div className="row">
									<div className="position-relative col-12 px-2 mb-3">
										<div className="position-relative w-50">
											<SearchModalInput
												type="text"
												name="searchModalInput"
												ref={searchModalInput}
												className="form-control searchModalInput"
												placeholder={t(
													'lgsSalesKit:dialogCol.quickSearch'
												)}
											/>
											<ModalSearchBtn
												type="button"
												className="searchModalBtn position-absolute border-0 btn btn-primaryllt px-3 d-flex align-items-center"
												onClick={() => searchModalBtn()}
											>
												<Icn_Search2 />
											</ModalSearchBtn>
											<a
												className="cancelSearchModalBtn position-absolute d-none"
												style={{
													top: '5px',
													right: '-22px',
													cursor: 'pointer',
												}}
												onClick={() =>
													cancelSearchModalBtn()
												}
											>
												<Icn_InputYellowCancel />
											</a>
										</div>
									</div>
									<div
										className="col-6 px-2"
										style={{ height: '440px' }}
									>
										<div
											style={{
												height: '100%',
												border: '1px solid #EFF2F5',
												borderRadius:
													'0.75rem 0.75rem 0 0',
											}}
										>
											<div
												className="px-2 d-flex justify-content-center align-items-center"
												style={{
													borderRadius:
														'0.75rem 0.75rem 0 0',
													height: '40px',
													color: '#0C2556',
													backgroundColor: '#F8F9FB',
													fontWeight: 'bold',
												}}
											>
												{t(
													'lgsSalesKit:unitOfMeasureType'
												)}
												{/* 計量單位型態 */}
											</div>
											<Ul>
												{uomCategoryList?.map(
													(category) => (
														<Li
															key={
																category.categoryCode
															}
															onClick={() =>
																selectUomCategory(
																	category.categoryCode
																)
															}
															active={
																category.categoryCode ===
																selectedCategoryCode
															}
														>
															{category.nameZf}
														</Li>
													)
												)}
											</Ul>
										</div>
									</div>
									<div
										className="col-6 px-2"
										style={{ height: '442px' }}
									>
										<div
											style={{
												height: '100%',
												border: '1px solid #EFF2F5',
												borderRadius:
													'0.75rem 0.75rem 0 0',
											}}
										>
											<div
												className="px-2 d-flex justify-content-center align-items-center"
												style={{
													borderRadius:
														'0.75rem 0.75rem 0 0',
													height: '40px',
													color: '#0C2556',
													backgroundColor: '#F8F9FB',
													fontWeight: 'bold',
												}}
											>
												{t(
													'lgsSalesKit:unitOfMeasureName'
												)}
												{/* 計量單位名稱 */}
											</div>
											<Ul
												style={{
													height: '400px',
													overflowY: 'scroll',
													borderRadius:
														'0 0 0.75rem 0.75rem',
													scrollbarWidth: 'thin',
													listStyleType: 'none',
												}}
											>
												{uomList
													.filter(
														(uom) =>
															uom.uomcategoryCode ===
															selectedCategoryCode
													)
													.map((uom, index) => (
														<Li
															key={index}
															onClick={() =>
																selectUom(uom)
															}
															active={
																uom.uomguid ===
																selectedData?.uomguid
															}
														>
															{`${uom.uomcode} ${uom.name01}`}
														</Li>
													))}
											</Ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr className="border-grey1 mt-5 mb-0" />
						<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
							<button
								type="button"
								className="btn btn-primary mediumBtn mx-1"
								onClick={() => {
									handleSubmit();
								}}
							>
								{t('button.confirmButton')}
							</button>
							<button
								type="button"
								className="btn btn-outline-primarylllt mediumBtn mx-1"
								onClick={() => {
									handleClose();
								}}
								// onClick="cancelTableModal(this, 'personnel')"
							>
								{t('button.cancelButton')}
							</button>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</div>
	);
}
