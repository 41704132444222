import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
`;

function StatusBar(props) {
    const { status } = props;
    console.log(status);

    const statusInfoObject = {
        0: {
            color: '#FFB435',
            backgroundColor: '#FFF9E7',
            message: '訂單未完成',
        },
        1: {
            color: '#31A952',
            backgroundColor: '#E9FAF0',
            message: '訂單已完成',
        },
        2: {
            color: '#EA4132',
            backgroundColor: '#FFF1F1',
            message: '訂單已關閉',
        },
        3: {
            color: '#fffff',
            backgroundColor: '#000000',
            message: '訂單已結清',
        },
    };

    return (
        status && (
            <Container
                className={`rounded-t-xxl`}
                style={{
                    color: statusInfoObject[status].color,
                    backgroundColor: statusInfoObject[status].backgroundColor,
                }}
            >
                {statusInfoObject[status].message}
            </Container>
        )
    );
}

export default StatusBar;
