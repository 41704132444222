import { useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

/**
    fixed :
    - no need to JSON.stringify to then immediately do a JSON.parse
    - don't use export defaults, because default imports are hard to search for
    - axios already support generic request in one parameter, no need to call specialized ones
**/
const useAxios = () => {
	const [cookies, setCookie] = useCookies();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const sendRequest = useCallback(async (configuration, cb, authorize) => {
		setIsLoading(true);
		setError(null);

		let res;

		try {
			const config = {
				headers: configuration.headers
					? configuration.headers
					: {
							SYNERTER_WEB_CSRF_TOKEN: cookies['CSRF-TOKEN'],
							'Content-Type': 'application/json',
					  },
				url: configuration.url,
				method: configuration.method ? configuration.method : 'GET',
				data: configuration.data ? configuration.data : null,
			};

			// 要增加身份驗證時會加入 JWT 到 headers
			// if (authorize) {
			//     let currentDate = new Date();
			//     const decodeToken = jwt_decode(user.accessToken);
			//     if (decodeToken.exp * 1000 < currentDate.getTime()) {
			//         const data = await getRefreshToken();
			//         config.headers[
			//             'authorization'
			//         ] = `Bearer ${data.accessToken}`;
			//     }
			// }

			// console.log(config);

			res = await axios(config);
		} catch (error) {
			setError(error.message || 'Something went wrong!');
			cb(error);
		} finally {
			if (res) {
				cb(res.data);
			}

			setIsLoading(false);
		}
	}, []);

	return {
		isLoading,
		error,
		sendRequest,
	};
};

export default useAxios;
