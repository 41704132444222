import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DetailAndGoodsTable from './DetailAndGoodsTable';
import useAxios from '../../../../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';

function LgsPurchaseOrder_DetailAndGoods() {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);

	// state
	const [fetchedData, setFetchedData] = useState([]);

	// axios
	const { sendRequest: axiosDetailAndGoodsList } = useAxios();

	// variable
	const guidName = 'purchaseOrderItemGuid';
	const sortBy = 'displayOrder';
	const purchaseOrderGuid = useLocation().pathname.split('/').pop();

	// table title list
	const headTitleList = [
		'#',
		t('lgsPurchaseOrder:type'),
		t('lgsPurchaseOrder:purchaseContent'),
		t('lgsPurchaseOrder:purchaseQuantity'),
		t('lgsPurchaseOrder:unit'),
		t('lgsPurchaseOrder:inspection'),
		t('lgsPurchaseOrder:unitPriceExcludingTax'),
		t('lgsPurchaseOrder:priceDifferencePercentage'),
		t('lgsPurchaseOrder:amountExcludingTax'),
		t('lgsPurchaseOrder:deliveryDate'),
		t('lgsPurchaseOrder:annotation'),
	];

	const bodyTitleList = [
		'displayOrder',
		'purchaseCategoryCode',
		'procurementContent',
		'quantityPurchase',
		'uomcode',
		'inspection',
		'netPrice',
		'spreadPercent',
		'netAmount',
		'deliverDate',
		'note',
	];

	const childHeadTitleList = [
		'#',
		t('lgsPurchaseOrder:date'),
		t('lgsPurchaseOrder:receivedQuantity'),
		t('lgsPurchaseOrder:unreceivedQuantity'),
		t('lgsPurchaseOrder:materialLocation'),
		t('lgsPurchaseOrder:receiptNumber'),
		t('lgsPurchaseOrder:annotation'),
		t('lgsPurchaseOrder:batchNumber'),
	];

	//#endregion

	//#region useEffect

	useEffect(() => {
		const url = `api/Logistics/LgsPurchaseOrder/purchaseOrderItem?guid=${purchaseOrderGuid}`;
		axiosDetailAndGoodsList({ url }, (res) => {
			console.log(res);
			setFetchedData(res.data);
		});
	}, []);

	//#endregion

	return (
		<div id="indexView">
			{fetchedData && (
				<DetailAndGoodsTable
					guidName={guidName}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					childHeadTitleList={childHeadTitleList}
					indexData={fetchedData}
					sortBy={sortBy}
				/>
			)}
		</div>
	);
}

export default LgsPurchaseOrder_DetailAndGoods;
