import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CopyIcon } from '../../assets/images/icn_Copy.svg';
import { BtnNormal } from './BtnNormal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const SaveSubmitBtn = ({ handleSubmit }) => {
	const { t } = useTranslation(['common']); // i18n
	const navigate = useNavigate();
	return (
		<BtnNormal
			className={'btn btn-primary'}
			to={'Create'}
			key={'CopyCreateBtn'}
			onClick={() => handleSubmit()}
		>
			<span>{t('common:button.saveButton')}</span>
		</BtnNormal>
	);
};
