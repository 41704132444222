import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../assets/images/icn_Trash.svg';
import { ReactComponent as NextIcon } from '../../assets/images/icn_Next.svg';
import { BtnSmall } from './BtnSmall';

export const NextBtnSmall = ({ className, handleClick }) => {
	// console.log(className, to);
	return (
		<BtnSmall
			className={` ${
				className === 'd-none' ? 'd-none' : 'd-flex'
			} layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center ${className}`}
			key={'PrevBtn'}
			onClick={(e) => {
				e.preventDefault();
				handleClick();
			}}
		>
			<NextIcon />
		</BtnSmall>
	);
};
