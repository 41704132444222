import React from 'react';
import styled from 'styled-components';
import { disabledStyle } from './shared/DisabledStyle';
import { ErrMsg } from './shared/ErrMsg';
import { LabelNormal } from './shared/LabelNormal';
// 使用範例
/* 
<Input
	wrapperClass="mb-3" // 外層class，可用來推下面margin
	labelText="年齡" // label文字
	type="number" // input型態
	id="age" // input id&name ; label htmlFor
	placeholder="請填寫年齡"
	disabled={false}
	disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
	register={register} // react-hook-form
	validation={{
		// 驗證邏輯
		required: 'must be required',
		min: { value: 0, message: '>0' },
		max: { value: 100, message: '<100' },
	}}
	isErr={errors.age} // 是否顯示錯誤訊息
	errMsg={errors.age?.message} // 錯誤訊息
	onChange={(e) => {
		setValue('age', e.target.value);
		const ageValue = getValues('age');
		// console.log(ageValue);
	}}
/>
*/

const Element = styled.select`
    font-size: 14px;
    border: 1px solid var(--grey2) !important;
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    /* 下拉箭頭圖案 */
    background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
        linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
    background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
    background-size: 6px 6px, 6px 6px;
    background-repeat: no-repeat;
    -webkit-appearance: none;

    ${disabledStyle}
`;

// const OptionBtn = styled.div`
// 	display: flex;
// 	align-items: center;
// 	border: 1px solid var(--grey2);
// 	border-radius: 4px;
// 	border-left: 0;
// 	padding-left: 16px;
// 	padding-right: 12px;
// 	cursor: pointer;
// 	height: 32px;
// `; // dialog按鈕

function SelectElement({
    labelText,
    elementStyle,
    type,
    name,
    value,
    placeholder,
    onChange,
    onBlur,
    isErr,
    errMsg,
    wrapperClass,
    disabled,
    disabledCssType,
    handleDialogShow,
    id,
    options,
}) {
    return (
        <div className="wrapper d-flex flex-column mb-3">
            <LabelNormal htmlFor={id}>{labelText}</LabelNormal>
            <Element
                placeholder={placeholder}
                value={value || ''}
                name={name}
                type={type || 'text'}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                disabledCssType={disabledCssType}
                id={id}
                style={{
                    cursor: 'pointer',
                    borderRight: 0,
                }}
            >
                <option value="">--- Please select---</option>
                {options?.map((option) => (
                    <option id={option.id} key={option.id} value={option.value}>
                        {option.name || ''}
                    </option>
                ))}
            </Element>

            <ErrMsg
                className={`text-danger mt-1 ${isErr ? 'd-block' : 'd-none'}`}
            >
                {errMsg}
            </ErrMsg>
        </div>
    );
}

export default SelectElement;
