import React from "react";
import styled from "styled-components";
import { ReactComponent as ActivateIcon } from "../../assets/images/icn_Activate.svg";
import { BtnNormal } from "./BtnNormal";
import { useNavigate } from "react-router-dom";

const StyledActivateBtnNormal = styled(BtnNormal)`
  margin-left: 25px;
`;

export const ActivateBtnNormal = (props) => {
  // console.log(props);
  const { permission, handleClick, word } = props;
  const disabled = !permission?.includes("Activate");

  const navigate = useNavigate();
  return (
    <StyledActivateBtnNormal
      className={"btn btn-primary text-white"}
      key={"ActivateBtnNormal"}
      disabled={!disabled}
      onClick={handleClick ? handleClick : () => navigate("Activate")}
    >
      <ActivateIcon />
      <span>{word}</span>
    </StyledActivateBtnNormal>
  );
};
