import styled from 'styled-components';
import { useState } from 'react';
import { ReactComponent as BackIcon } from '../../assets/images/icn_PreviousPage.svg';
import { useNavigate } from 'react-router-dom';

// #region styled-components
const Container = styled.div`
	display: flex;
	margin-top: 8px;
`;

const PageTitle = styled.div`
	font-size: 20px;
	font-weight: 700;
	color: var(--secondary);
`;

const PreviousPage = styled.div`
	display: flex;
	width: 40px;
	height: 32px;
	border-radius: 4px;
	color: var(--primary);
	border: 1px solid var(--primary);
	cursor: pointer;
	justify-content: center;
	align-items: center;
	margin-right: 16px;
`;

// #endregion

function PageTitleBar(props) {
	// #region functions
	// console.log(props)
	const {
		pageTitle,
		previousPageButton,
		backTo,
		isDialogConfirm,
		handleDialogConfirm,
	} = props;
	const navigate = useNavigate();

	const handleBackBtn = (backTo) => {
		if (isDialogConfirm) {
			handleDialogConfirm();
		} else {
			navigate(backTo);
		}
	};

	// #endregion

	return (
		<Container>
			{previousPageButton && (
				<PreviousPage onClick={() => handleBackBtn(backTo)}>
					<BackIcon />
				</PreviousPage>
			)}
			<PageTitle>{pageTitle}</PageTitle>
		</Container>
	);
}

export default PageTitleBar;
