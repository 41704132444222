import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Icn_deleted } from '../../../../assets/images/icn_DeleteCross.svg';
import { ReactComponent as CreateIcon } from '../../../../assets/images/icn_Create.svg';
import { BtnNormal } from '../../../../components/Buttons/BtnNormal';
import { useCookies } from 'react-cookie';

// 價格明細

// #region styled-components
const IndexTable = styled.table`
	margin-bottom: 0;
	color: var(--grey4);
	background-color: #fff;
`;

const THead = styled.thead`
	height: 40px;
`;

const TR = styled.tr`
	height: 40px;
`;

const TH = styled.th`
	vertical-align: middle;
	border: 1px solid var(--grey1) !important;
	background-color: var(--grey1) !important;
`;

const NextToLast = styled(TH)`
	border-right: none !important;
`;

const LastTH = styled(TH)`
	border-left: none !important;
`;

const TBody = styled.tbody``;

const TD = styled.td`
	cursor: auto;
	border: 1px solid var(--grey1) !important;
`;

const Input = styled.input`
	border: 1px solid #e3e8ee;
	border-radius: 4px;
	min-width: 24px;
	width: 100%;
	outline: none;
`;

const Select = styled.select`
	border: 1px solid #e3e8ee;
	border-radius: 4px;
	min-width: 24px;
	width: 100%;
	outline: none;
`;

//#endregion
export default function PriceDetails({
	data,
	setData,
	error,
	baseInfoList,
	customerClassActiveGuid,
	isDetailRoute,
	isEditRoute,
}) {
	const { t } = useTranslation(['common', 'lgsSalesKit']);
	const [editingRowIndex, setEditingRowIndex] = useState(-1);
	const [cookies, setCookie] = useCookies();
	const defaultCurrencyCode = cookies['_company_currency_code']; // 公司幣別

	const handleAddServiceEstimatedPriceList = () => {
		const newNumber =
			data.priceDetailsList.filter(
				(el) =>
					el.action !== 'delete' &&
					el.customerClassGuid === customerClassActiveGuid
			).length + 1;

		const newItem = {
			id: _uuid(),
			displayOrder: newNumber,
			currencyCode: defaultCurrencyCode,
			currencyName: baseInfoList.currencyList.find(
				(el) => el.currencyCode === defaultCurrencyCode
			)?.name01,
			startDate: '',
			endDate: '',
			note: '',
			quantityFrom: 0,
			quantityTo: 0,
			rowStamp: '',
			unitPrice: 0,
			action: 'insert',

			customerClassGuid: customerClassActiveGuid,
		};

		const oldList = [...data.priceDetailsList];

		// displayOrder排序必須去除delete與reallyDelete
		const notIncludeAnyDelete = oldList
			.filter((item) => item.action !== 'delete')
			.map((item) => ({
				...item,
			}));

		// 不能顯示但必須保留給後端的delete資料
		const actionDelete = oldList.filter((item) => item.action === 'delete');

		// 結合新的資料，將delete放到最後，才能讓修改資料時index對應到
		const newList = [...notIncludeAnyDelete, newItem, ...actionDelete];

		setData({
			...data,
			priceDetailsList: newList,
		});
	};

	const handleDeleteEstimatedPrice = (e, id) => {
		e.stopPropagation();

		const oldList = [...data.priceDetailsList];

		console.log(oldList, id);

		// action === 'update'或'native' 的資料只能標記不能刪除
		let deletedItem = oldList.find((item) => item.id === id);
		if (
			deletedItem.action === 'update' ||
			deletedItem.action === 'native'
		) {
			deletedItem.action = 'delete';
			deletedItem.displayOrder = '0'; // 由於必須給予displayOrder，所以預設為0
		} else if (deletedItem.action === 'insert') {
			deletedItem.action = 'reallyDelete';
			deletedItem.displayOrder = '0'; // 由於必須給予displayOrder，所以預設為0
		}

		// 不能顯示但必須保留給後端的delete資料
		const actionDelete = oldList.filter((item) => item.action === 'delete');

		// displayOrder排序必須去除delete與reallyDelete
		const notIncludeAnyDelete = oldList.filter(
			(item) => item.action !== 'delete' && item.action !== 'reallyDelete'
		);

		// 將notIncludeAnyDelete中customerClassGuid === customerClassActiveGuid的displayOrder重新給予
		let count = 1;
		notIncludeAnyDelete.forEach((item) => {
			if (item.customerClassGuid === customerClassActiveGuid) {
				item.displayOrder = count;
				count++;
			}
		});

		// 排序後，結合除了reallyDelete的資料
		const newList = [...notIncludeAnyDelete, ...actionDelete];

		console.log(newList);

		setData({ ...data, priceDetailsList: newList });

		console.log(oldList);
	};

	const handleEditTable = (e, rowIndex, el) => {
		// console.log(e.target, rowIndex, el);

		const targetIndex = rowIndex;
		setEditingRowIndex(targetIndex);
	};

	const handleInputChange = (e, index) => {
		const value = e.target.value;
		const name = e.target.name;

		const priceDetailsList = [...data.priceDetailsList];
		priceDetailsList[index][name] = value;

		// 在編輯頁面且不是新增時，標記為update
		if (isEditRoute && priceDetailsList[index]['action'] !== 'insert')
			priceDetailsList[index]['action'] = 'update';

		setData({ ...data, priceDetailsList });
	};

	function _uuid() {
		var d = Date.now();
		if (
			typeof performance !== 'undefined' &&
			typeof performance.now === 'function'
		) {
			d += performance.now(); //use high-precision timer if available
		}
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g,
			function (c) {
				var r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d / 16);
				return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
			}
		);
	}

	// console.log(data);

	useEffect(() => {
		setEditingRowIndex(-1);
	}, [isDetailRoute, isEditRoute]); // 換頁後取消可編輯狀態

	//#endregion
	return (
		<>
			<div className="position-relative">
				<div className="bg-white d-flex flex-column p-4 pt-0 pe-0 position-relative">
					<div className="px-2 pt-3 pb-2 d-flex align-item-center">
						<div
							className="d-flex align-items-end"
							style={{
								fontSize: '16px',
								fontWeight: 'bold',
							}}
						>
							{t('lgsSalesKit:priceCondition')}
						</div>
						{/* 價格條件 */}
						{!isDetailRoute && (
							<BtnNormal
								className={'btn btn-primary text-white ms-4'}
								key={'ActivateBtnNormal'}
								onClick={handleAddServiceEstimatedPriceList}
							>
								<CreateIcon />
								{t('lgsSalesKit:add')}
								{/* 新增 */}
							</BtnNormal>
						)}
					</div>
					<IndexTable className="table">
						<THead className="table-head">
							<TR>
								<TH
									style={{
										width: '48px',
										textAlign: 'center',
									}}
								>
									#
								</TH>

								<TH
									style={{
										width: '128px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:startDate')}
									{/* 起始日期 */}
								</TH>
								<TH
									style={{
										width: '128px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:endDate')}
									{/* 截止日期 */}
								</TH>
								<TH
									style={{
										width: '160px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:quantityLowerLimit')}
									{/* 數量下限 */}
								</TH>
								<TH
									style={{
										width: '160px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:quantityUpperLimit')}
									{/* 數量上限 */}
								</TH>
								<TH
									style={{
										width: '128px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:currency')}
									{/* 幣別 */}
								</TH>
								<TH
									style={{
										width: '160px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:unitPriceExcludingTax')}
									{/* 未稅單價 */}
								</TH>
								<NextToLast
									style={{
										width: '232px',
										textAlign: 'center',
									}}
								>
									{t('lgsSalesKit:remarks')}
									{/* 備註 */}
								</NextToLast>
								<LastTH
									style={{
										width: '48px',
										textAlign: 'center',
									}}
								></LastTH>
							</TR>
						</THead>
						<TBody className="">
							{data?.priceDetailsList
								?.filter((item) => item.action !== 'delete')
								?.map((el, rowIndex) =>
									editingRowIndex !== rowIndex ? (
										<TR
											key={el?.id}
											id={el?.id}
											onClick={(e) =>
												!isDetailRoute &&
												handleEditTable(e, rowIndex, el)
											}
											// 只顯示對應的客戶分類
											className={`${
												el.customerClassGuid ===
												customerClassActiveGuid
													? ''
													: 'd-none'
											}`}
										>
											<TD className="text-center">
												{el?.displayOrder}
											</TD>

											<TD className="text-left">
												{el?.startDate
													? dayjs(
															el?.startDate
													  ).format('YYYY-MM-DD')
													: ''}
												<div className="mt-xl-1 text-danger text-start">
													{error?.priceDetailsList &&
														error?.priceDetailsList[
															el.id
														]?.startDate?.message}
												</div>
											</TD>
											<TD className="text-left">
												{el?.endDate
													? dayjs(el?.endDate).format(
															'YYYY-MM-DD'
													  )
													: ''}
											</TD>
											<TD className="text-end">
												{el?.quantityFrom}
											</TD>
											<TD className="text-end">
												{el?.quantityTo}{' '}
												<div className="mt-xl-1 text-danger text-start">
													{error?.priceDetailsList &&
														error?.priceDetailsList[
															el.id
														]?.quantityTo?.message}
												</div>
											</TD>
											<TD className="text-left">
												{el.currencyCode
													? `${el?.currencyCode} ${el?.currencyName}`
													: ``}
												<div className="mt-xl-1 text-danger text-start">
													{error?.priceDetailsList &&
														error?.priceDetailsList[
															el.id
														]?.currencyCode
															?.message}
												</div>
											</TD>
											<TD className="text-end">
												{el?.unitPrice}
												<div className="mt-xl-1 text-danger text-start">
													{error?.priceDetailsList &&
														error?.priceDetailsList[
															el.id
														]?.unitPrice?.message}
												</div>
											</TD>
											<TD className="text-left">
												{el?.note}
											</TD>
											<TD className="text-left"></TD>
										</TR>
									) : (
										<TR
											key={el.displayOrder}
											onClick={(e) =>
												!isDetailRoute &&
												handleEditTable(e, rowIndex, el)
											}
											// 只顯示對應的客戶分類
											className={`${
												el.customerClassGuid ===
												customerClassActiveGuid
													? ''
													: 'd-none'
											}`}
										>
											<TD className="text-center bg-grey1">
												{el?.displayOrder}
											</TD>

											<TD className="text-left bg-grey1">
												<Input
													name="startDate"
													value={
														el?.startDate
															? dayjs(
																	el?.startDate
															  ).format(
																	'YYYY-MM-DD'
															  )
															: ''
													}
													active={el?.active}
													type="date"
													min="0"
													onChange={(e) => {
														handleInputChange(
															e,
															rowIndex
														);
													}}
												/>
												<div className="mt-xl-1 text-danger text-start">
													{error?.priceDetailsList &&
														error?.priceDetailsList[
															el.id
														]?.startDate?.message}
												</div>
											</TD>
											<TD className="text-left bg-grey1">
												<Input
													name="endDate"
													value={dayjs(
														el?.endDate
													).format('YYYY-MM-DD')}
													active={el?.active}
													type="date"
													min="0"
													onChange={(e) => {
														handleInputChange(
															e,
															rowIndex
														);
													}}
												/>
											</TD>
											<TD className="text-end bg-grey1">
												<Input
													name="quantityFrom"
													value={el?.quantityFrom}
													active={el?.active}
													type="number"
													min="0"
													onChange={(e) => {
														handleInputChange(
															e,
															rowIndex
														);
													}}
												/>
											</TD>
											<TD className="text-end bg-grey1">
												<Input
													name="quantityTo"
													value={el?.quantityTo}
													active={el?.active}
													type="number"
													min="0"
													onChange={(e) => {
														handleInputChange(
															e,
															rowIndex
														);
													}}
												/>
												<div className="mt-xl-1 text-danger text-start">
													{error?.priceDetailsList &&
														error?.priceDetailsList[
															el.id
														]?.quantityTo?.message}
												</div>
											</TD>
											<TD className="text-left bg-grey1">
												<Select
													name="currencyCode"
													value={el?.currencyCode}
													onChange={(e) =>
														handleInputChange(
															e,
															rowIndex
														)
													}
												>
													<option value="">
														----------
													</option>
													{baseInfoList.currencyList?.map(
														(currency) => (
															<option
																key={
																	currency.currencyCode
																}
																value={
																	currency?.currencyCode
																}
															>
																{`${currency.currencyCode} ${currency.name01}`}
															</option>
														)
													)}
												</Select>
												<div className="mt-xl-1 text-danger text-start">
													{error?.priceDetailsList &&
														error?.priceDetailsList[
															el.id
														]?.currencyCode
															?.message}
												</div>
											</TD>
											<TD className="text-end bg-grey1">
												<Input
													name="unitPrice"
													value={el?.unitPrice}
													active={el?.active}
													type="number"
													min="0"
													onChange={(e) => {
														handleInputChange(
															e,
															rowIndex
														);
													}}
												/>
												<div className="mt-xl-1 text-danger text-start">
													{error?.priceDetailsList &&
														error?.priceDetailsList[
															el.id
														]?.unitPrice?.message}
												</div>
											</TD>
											<TD className="text-left bg-grey1">
												<Input
													name="note"
													value={el?.note}
													active={el?.active}
													type="text"
													onChange={(e) => {
														handleInputChange(
															e,
															rowIndex
														);
													}}
												/>
											</TD>
											<TD className="text-left position-relative bg-grey1">
												<div
													className="position-absolute top-50 start-50 translate-middle cursor"
													role="button"
													onClick={(e) =>
														handleDeleteEstimatedPrice(
															e,
															el.id
														)
													}
												>
													<Icn_deleted />
												</div>
											</TD>
										</TR>
									)
								)}
						</TBody>
					</IndexTable>
				</div>
			</div>
		</>
	);
}
