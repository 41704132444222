import axios from 'axios';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../../assets/images/icn_InputYellowCancel.svg';
import { ReactComponent as Icn_OpenModal } from '../../../../../assets/images/icn_OpenModal.svg';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import CurrencyModal from './CurrencyModal';
import { DialogConfirm } from './DialogConfirm';
import PlantModal from './PlantModal';
import SectorModal from './SectorModal';
import StaffModal from './StaffModal';
import VendorModal from './VendorModal';

// #region styled-components

const TextareaElement = styled.textarea`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
`;

const NumberInputElement = styled.input`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    -webkit-inner-spin-button,
    -webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:hover::-webkit-inner-spin-button,
    &:hover::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        opacity: 0;
    }

    &:focus::-webkit-inner-spin-button,
    &:focus::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        opacity: 0;
    }
`;
const InputElement = styled.input`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
`;

const InputElement2 = styled.input`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-right: 0px;
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
`;

const SelectElement = styled.select`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    /* 下拉箭頭圖案 */
    background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
        linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
    background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
    background-size: 6px 6px, 6px 6px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
`;

const LabelNormal = styled.label`
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;

const Wrapper = styled.div`
    min-height: 720px;
`;

const NoInfoRow = styled.div`
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    margin-top: 16px;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
`;

const BtnNormal = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 88px;
    height: 32px;
    margin-right: 8px;
    svg {
        margin-right: 8px;
    }
`;

const DialogBtn = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 12px;
    cursor: pointer;
    height: 32px;
`; // dialog按鈕

const CancelInputBtn = styled.span`
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 3px;
    right: 32px;
    width: 24px;
    text-align: center;
    display: ${(props) => (props.isValueExist ? 'inline' : 'none')};
    cursor: pointer;
`; // 取消按鈕

//#endregion

function Page0({
    page,
    processSteps,
    handlePrevPage,
    handleNextPage,
    purchaseOrderData,
    setPurchaseOrderData,
}) {
    //   const guid = useLocation().pathname.split('/').pop();
    const { t } = useTranslation(['common', 'lgsPurchaseOrder']);
    const [cookies, setCookie, removeCookie] = useCookies();
    //console.log(cookies);

    const [orderTypeList, setOrderTypeList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [modalList, setModalList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [invoiceTaxRateTypeList, setInvoiceTaxRateTypeList] = useState([]);
    const [plantList, setPlantList] = useState([]);
    const [sectorList, setSectorList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [exchangeRate, setExchangeRate] = useState();
    const companyCurrencyCode = cookies._company_currency_code;
    const navigate = useNavigate();
    const [data, setData] = useState({
        purchaseOrderGuid: '', //	採購訂單GuidV
        orderTypeCode: '', //	採購訂單類型
        categoryControl: '', //	採購訂單Control
        orderDate: '', //採購日期V
        currencyShow: '', //幣別顯示
        currencyCode: '', //交易幣別
        currencyName: '', //交易幣別名稱
        decimalPlaceTl: '', //交易幣別小數位數
        foreignCurrency: '', //外幣交易
        exchangeRate: '', //匯率V
        vendorGuid: '', //	廠商Guid
        vendorName: '', //廠商
        inputTaxGuid: '', //發票稅別Guid
        inputTaxCode: '', //發票稅別Code
        inputTaxName: '', //發票稅別Name
        inputTaxRate: '', //發票進項稅率
        vendorSales: '', //廠商聯絡代表(聯絡人)
        vendorSalesPhone: '', //廠商聯絡代表(聯絡人)電話
        shipToPlantGuid: '', //收貨廠房Guid
        plantName: '', //收貨廠房
        shipToSectorGuid: '', //收貨部門Guid
        sectorName: '', //收貨部門
        shipToStaffGuid: '', //收貨人員Guid
        staffName: '', //收貨人員
        note: '', //註記
    });
    //console.log(data);

    // page1此頁面資料管控
    const [newPurchaseOrderData, setNewPurchaseOrderData] = useState([]);
    console.log(newPurchaseOrderData);

    // required必填欄位驗證
    let array = [
        'orderTypeCode',
        'orderDate',
        'currencyCode',
        'exchangeRate',
        'vendorName',
    ];

    const handleChangePage1 = () => {
        // 送出前欄位驗證
        const newData = { ...data }; //我的 data
        let falseCount = 0;

        const idKeys = Object.keys(newData);
        //console.log(idKeys);

        let array = [
            'orderTypeCode',
            'orderDate',
            'currencyCode',
            'vendorName',
        ];

        newData.foreignCurrency === true && array.push('exchangeRate');

        // required必填欄位驗證
        //console.log('Before loop', newData);

        array.forEach((keyName) => {
            //console.log('Inside loop', keyName, newData[keyName]);
            if (newData[keyName] === '') {
                newData[`${keyName}Warning`] = t('lgsPurchaseOrder:fillData');
                falseCount++;
            }
        });

        //console.log('After loop', newData);

        setData(newData);
        //console.log(newData);

        //console.log(falseCount);
        if (falseCount === 0) return true;
    };

    // 整理為後端需要的資料，存到父層
    const handleChangePage = () => {
        if (handleChangePage1()) {
            setPurchaseOrderData({
                ...purchaseOrderData,
                page0: data,
            });
            handleNextPage();
        }
    };

    // input 有值時，清空錯誤訊息

    useEffect(() => {
        const newData = { ...data };

        array.forEach((el) => {
            if (newData['el']) {
                newData['el'] = '';
                setData(newData);
            }

            // newData['el'];
            // console.log(el);
        });

        // array.forEach((el) => {
        //   if (newData['el']) {
        //     newData['orderTypeCodeWarning'] = ''; // 清空錯誤訊息
        //     setData(newData);
        //   }
        //   console.log(el);
        // });
        // console.log(newData);

        // if (newData['orderTypeCode']) {
        //   newData[`orderTypeCodeWarning`] = ''; // 清空錯誤訊息
        //   setData(newData);
        // }
        // // if (newData['orderDate']) {
        // //   newData[`orderDateWarning`] = ''; // 清空錯誤訊息
        // //   setData(newData);
        // // }
        // if (newData['exchangeRate']) {
        //   newData[`exchangeRateWarning`] = ''; // 清空錯誤訊息
        //   setData(newData);
        // }
        // if (newData['currencyCode']) {
        //   newData[`currencyCodeWarning`] = ''; // 清空錯誤訊息
        //   setData(newData);
        // }
        // if (newData['vendorName']) {
        //   newData[`vendorNameWarning`] = ''; // 清空錯誤訊息
        //   setData(newData);
        // }
    }, [
        data.orderTypeCode,
        data.orderDate,
        data.exchangeRate,
        data.currencyCode,
        data.vendorName,
    ]);
    //console.log(data);

    //#endregion

    // console.log(purchaseOrderData);

    const [currencyTableShow, setCurrencyTableShow] = useState(false);
    const handleCurrencyClose = () => setCurrencyTableShow(false);
    const handleCurrencyShow = () => {
        setCurrencyTableShow(true);
    };

    const [vendorTableShow, setVendorTableShow] = useState(false);
    const handleVendorClose = () => setVendorTableShow(false);
    const handleVendorShow = () => {
        setVendorTableShow(true);
    };

    const [plantTableShow, setPlantTableShow] = useState(false);
    const handlePlantClose = () => setPlantTableShow(false);
    const handlePlantShow = () => {
        setPlantTableShow(true);
    };

    const [sectorTableShow, setSectorTableShow] = useState(false);
    const handleSectorClose = () => setSectorTableShow(false);
    const handleSectorShow = () => {
        setSectorTableShow(true);
    };

    const [staffTableShow, setStaffTableShow] = useState(false);
    const handleStaffClose = () => setStaffTableShow(false);
    const handleStaffShow = () => {
        setStaffTableShow(true);
    };

    //
    const guid = useLocation().pathname.split('/').pop();
    const getDBData = () => {
        axios
            .get(`api/Logistics/LgsPurchaseOrder/getEditDatapage0?guid=${guid}`)
            .then((res) => {
                console.log(res.data);
                //console.log(res.data.purchaseOrder);
                // console.log(cookies);
                //整理來自後端的資料
                setEmployeeList(res.data.employeeList);
                setPlantList(res.data.plantList);
                setSectorList(res.data.sectorList);
                //console.log(res.data.employeeList);

                // 從 planGuid找出收貨廠房對應name01
                const plantData = res.data.plantList.find(
                    (item) =>
                        item.plantGuid ===
                        res.data.purchaseOrder.shipToPlantGuid
                );
                const plantName01 = plantData ? plantData.name01 : null;
                //console.log(plantName01);

                // 從 sectorGuid找出對應 name01
                const sectorData = res.data.sectorList.find(
                    (item) =>
                        item.sectorGuid ===
                        res.data.purchaseOrder.shipToSectorGuid
                );
                const sectorName01 = sectorData ? sectorData.name01 : null;
                //console.log(sectorName01);

                // 從 staffGuid找出對應 name01
                const staffData = res.data.employeeList.find(
                    (item) =>
                        item.employeeGuid ===
                        res.data.purchaseOrder.shipToStaffGuid
                );
                const staffName01 = staffData ? staffData.fullname : null;

                let newData = {
                    purchaseOrderGuid: res.data.purchaseOrder.purchaseOrderGuid,
                    categoryControl: res.data.purchaseOrder.categoryControl,
                    currencyCode: res.data.purchaseOrder.currencyCode,
                    currencyName: res.data.purchaseOrder.currencyName,
                    currencyShow: `${res.data.purchaseOrder.currencyCode} ${res.data.purchaseOrder.currencyName}`,
                    decimalPlaceTl: res.data.purchaseOrder.decimalPlaceTl,
                    exchangeRate: res.data.purchaseOrder.exchageRate,
                    foreignCurrency: true,
                    inputTaxCode: res.data.purchaseOrder.inputTaxCode,
                    inputTaxGuid: res.data.purchaseOrder.inputTaxGuid,
                    inputTaxName: res.data.purchaseOrder.inputTaxName,
                    inputTaxRate: res.data.purchaseOrder.inputTaxRate,
                    note: res.data.purchaseOrder.note,
                    orderDate: res.data.purchaseOrder.orderDate.substring(
                        0,
                        10
                    ),
                    orderTypeCode: res.data.purchaseOrder.orderTypeCode,
                    orderTypeName: res.data.purchaseOrder.orderTypeName,
                    rowStamp: res.data.purchaseOrder.rowStamp,
                    plantName: plantName01,
                    sectorName: sectorName01,
                    staffName: staffName01,
                    vendorGuid: res.data.purchaseOrder.vendorGuid,
                    vendorName: res.data.purchaseOrder.vendorName,
                    vendorSales: res.data.purchaseOrder.vendorSales,
                    vendorSalesPhone: res.data.purchaseOrder.vendorSalesPhone,
                    foreignCurrency: res.data.purchaseOrder.foreignCurrency,
                    foreignGrossAmount:
                        res.data.purchaseOrder.foreignGrossAmount,
                    foreignNetAmount: res.data.purchaseOrder.foreignNetAmount,
                    foreignCurrency: res.data.purchaseOrder.foreignCurrency,
                    grossAmount: res.data.purchaseOrder.grossAmount,
                };
                console.log(newData);

                //應該要換成 createOrganizeItemListFromBE(res);
                //第一次進來，先從後端拿資料

                const isEverEnterPage = purchaseOrderData.isEverEnterPage.page0;
                if (!isEverEnterPage) {
                    setData({ ...newData });

                    // 標記是否曾拿取過資料   ....
                    let parentData = { ...purchaseOrderData };
                    parentData.isEverEnterPage.page0 = true;

                    setPurchaseOrderData(parentData);
                }

                //const substr = res.data.purchaseOrder.orderData.slice(0, 10);

                //setData(res.data.purchaseOrder);
            });
    };
    console.log(data);

    // 載入畫面時，從API拿到的資料
    //這邊要參考 page1 ，判斷是否去過別頁
    useEffect(() => {
        getDBData();
    }, []);

    const handleGetExchangeRate = (orderDate, currencyCode) => {
        //console.log(orderDate, currencyCode);
        const url = `api/Logistics/LgsPurchaseOrder/getExchangeRate`;

        // call api (axios)
        axios
            .get(`api/Logistics/LgsPurchaseOrder/getExchangeRate`)
            .then((res) => {
                //console.log(res.data);
            });
        // setData.rate ------這邊判斷還沒完成
    };

    // 修改過程，回到此頁後，回復資料。一開始父層為空，所以到這邊會直接清空data，必須做判斷：
    useEffect(() => {
        Object.keys(purchaseOrderData.page0).length !== 0 &&
            setData(purchaseOrderData.page0);
    }, []);

    //console.log(purchaseOrderData);

    // Delete it later
    // do it in the inputs of currency & orderDate instead of do it in effect
    useEffect(() => {
        if (data.orderDate && data.currencyShow)
            return handleGetExchangeRate(data.orderDate, data.currencyCode);
    }, [data]);

    //handleNextPage;

    // const
    // data.currencyCode === cookies._company_currency_code
    // setData (...data,foreignCurrency: 0 || 1)

    /**
     * DialogConfirm 返回btn確認dialog
     **/
    const [isOpen, setIsOpen] = useState(false);
    // DialogConfirm Modal open
    const handleDialogConfirm = () => {
        setIsOpen(true);
    };

    //  input 黃色按鈕取消
    const handleCancelClick = (name) => {
        // 更新newPurchaseOrderData
        const newData = { ...data };
        newData[name] = '';
        setData(newData, '');
        console.log(name);
    };

    return (
        <>
            <Container className="">
                <Left className="">
                    {/* page0 */}

                    <BtnNormal
                        className="layout-title-searchBtn layout-title-item btn btn-outline-primary d-flex justify-content-center align-items-center me-2"
                        to={'Create'}
                        key={'CopyCreateBtn'}
                        onClick={handleDialogConfirm}
                    >
                        <span>{t('button.cancelButton')}</span>
                    </BtnNormal>

                    <DialogConfirm
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        backTo={-1}
                    />

                    {/* page0 */}
                </Left>

                <Center className="">
                    <StepByStepBar steps={processSteps} activePage={page} />
                </Center>

                <Right className="">
                    {page < processSteps.length - 1 ? (
                        <NextBtnSmall
                            handleClick={() => {
                                handleChangePage();
                            }}
                        />
                    ) : (
                        <NextBtnSmall className="d-none" />
                    )}
                </Right>
            </Container>

            <div className="position-relative">
                <Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
                    {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                    <h1
                        style={{
                            color: '#1278E8',
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                    >
                        {t('lgsPurchaseOrder:basicInformation')}
                    </h1>

                    <div className="col-12">
                        <div className="row">
                            {/* #Col1---------------------  */}
                            <div className="col-lg-4 px-4 pr-xl-6">
                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal htmlFor={'purchaseOrderGuid'}>
                                        {t('lgsPurchaseOrder:basicInformation')}
                                    </LabelNormal>
                                    <SelectElement
                                        style={{
                                            backgroundColor: '#EFF2F5',
                                        }}
                                        id={'purchaseOrderGuid'}
                                        value={data.purchaseOrderGuid || ''}
                                        disabled={true}
                                        onChange={() => {}}
                                    >
                                        <option>{data.orderTypeName}</option>
                                    </SelectElement>
                                </div>

                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal htmlFor={'orderDate'}>
                                        {t('lgsPurchaseOrder:orderDate')}
                                    </LabelNormal>
                                    <InputElement
                                        // style={{
                                        //   backgroundColor: '#EFF2F5',
                                        // }}
                                        style={{
                                            backgroundColor: '#EFF2F5',
                                        }}
                                        type="date"
                                        id="orderDate"
                                        value={data.orderDate || ''}
                                        disabled={true}
                                        onChange={() => {}}
                                        disabledCssType={2}
                                    />
                                </div>

                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal htmlFor={'currencyCode'}>
                                        {t('lgsPurchaseOrder:currency')}
                                    </LabelNormal>
                                    <div className="input-group">
                                        <InputElement2
                                            className="form-control border-grey2"
                                            placeholder={t(
                                                'lgsPurchaseOrder:selectData'
                                            )}
                                            style={{
                                                backgroundColor: 'var(--grey1)',
                                            }}
                                            disabled={true}
                                            value={
                                                `${data.currencyCode} ${data.currencyName}` ||
                                                ''
                                            }
                                            onChange={() => {}}
                                            type="text"
                                            id="currencyCode"
                                            name="currencyCode"
                                        />

                                        <DialogBtn
                                            style={{
                                                backgroundColor: '#EFF2F5',
                                                cursor: 'auto',
                                            }}
                                        >
                                            <Icn_OpenModal />
                                        </DialogBtn>
                                    </div>
                                    <span className="text-danger ms-1 mt-1">
                                        {data.currencyCodeWarning}
                                    </span>
                                </div>
                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal htmlFor={'vendorSales'}>
                                        {t('lgsPurchaseOrder:exchangeRate')}
                                    </LabelNormal>
                                    {data.currencyCode ===
                                    cookies._company_currency_code ? (
                                        <InputElement
                                            type={'text'}
                                            id={'exchangeRate'}
                                            placeholder={t(
                                                'lgsPurchaseOrder:currency'
                                            )}
                                            style={{
                                                backgroundColor: 'var(--grey1)',
                                            }}
                                            value={t(
                                                'lgsPurchaseOrder:localCurrencyTransaction'
                                            )}
                                            onChange={() => {}}
                                            disabled={true}
                                            disabledCssType={2}
                                        />
                                    ) : (
                                        <InputElement
                                            type={'text'}
                                            id={'exchangeRate'}
                                            // placeholder="資料輸入"

                                            style={{
                                                backgroundColor: 'var(--grey1)',
                                            }}
                                            value={data.exchangeRate || ''}
                                            onChange={() => {}}
                                            disabled={true}
                                            disabledCssType={0}
                                        />
                                    )}
                                    <span className="text-danger ms-1 mt-1">
                                        {data.exchangeRateWarning}
                                    </span>
                                </div>
                            </div>
                            {/* #endCol1---------------------  */}
                            {/* #Col2---------------------  */}
                            <div className="col-lg-4 px-4 px-xl-5">
                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal>
                                        {t('lgsPurchaseOrder:vendor')}
                                    </LabelNormal>
                                    <div className="input-group">
                                        <InputElement2
                                            className="form-control border-grey2"
                                            placeholder={t(
                                                'lgsPurchaseOrder:selectData'
                                            )}
                                            style={{
                                                backgroundColor: 'var(--grey1)',
                                            }}
                                            disabled={true}
                                            value={data.vendorName || ''}
                                            onChange={() => {}}
                                            type="text"
                                            id="vendorCode"
                                            name="vendorCode"
                                        />

                                        <DialogBtn
                                            style={{
                                                backgroundColor: '#EFF2F5',
                                                cursor: 'auto',
                                            }}
                                        >
                                            <Icn_OpenModal />
                                        </DialogBtn>
                                    </div>
                                    <span className="text-danger ms-1 mt-1">
                                        {data.vendorNameWarning}
                                    </span>
                                </div>

                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal htmlFor={'inputTaxGuid'}>
                                        {t(
                                            'lgsPurchaseOrder:invoiceTaxCategory'
                                        )}
                                    </LabelNormal>

                                    <SelectElement
                                        id={'inputTaxGuid'}
                                        value={data.inputTaxGuid || ''}
                                        onChange={() => {}}
                                        disabled={true}
                                        style={{
                                            backgroundColor: 'var(--grey1)',
                                        }}
                                    >
                                        <option value="" onChange={() => {}}>
                                            {data.inputTaxName}
                                        </option>
                                        {/* {invoiceTaxRateTypeList.map((el) => (
                      <option
                        key={el.rateTypeGuid} // 從 API 來的資料為 rateTypeGuid，整理到 data 為 inputTaxGuid
                        id={el.rateTypeGuid}
                        value={el.rateTypeGuid}
                        taxRate={el.taxRate}
                      >
                        {el.name01}
                      </option>
                    ))} */}
                                    </SelectElement>
                                </div>

                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal htmlFor={'inputTaxRate'}>
                                        {t('lgsPurchaseOrder:invoiceTaxRate')}
                                    </LabelNormal>
                                    <InputElement
                                        type={'text'}
                                        id={'inputTaxRate'}
                                        style={{
                                            backgroundColor: 'var(--grey1)',
                                        }}
                                        value={data.inputTaxRate || ''}
                                        placeholder={t(
                                            'lgsPurchaseOrder:dataInput'
                                        )}
                                        onChange={() => {}}
                                        disabled={true}
                                        disabledCssType={0}
                                    />
                                </div>

                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal htmlFor={'vendorSales'}>
                                        {t(
                                            'lgsPurchaseOrder:vendorSalesRepresentative'
                                        )}
                                    </LabelNormal>

                                    <InputElement
                                        type={'text'}
                                        id={'vendorSales'}
                                        value={data.vendorSales || ''}
                                        placeholder={t(
                                            'lgsPurchaseOrder:dataInput'
                                        )}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                vendorSales:
                                                    e.target.value || '',
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                    />
                                </div>
                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal htmlFor={'vendorSalesPhone'}>
                                        {t(
                                            'lgsPurchaseOrder:vendorSalesRepresentativePhone'
                                        )}
                                    </LabelNormal>
                                    <NumberInputElement
                                        type={'number'}
                                        id={'vendorSalesPhone'}
                                        value={data.vendorSalesPhone || ''}
                                        placeholder={t(
                                            'lgsPurchaseOrder:dataInput'
                                        )}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                vendorSalesPhone:
                                                    e.target.value || '',
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                    />
                                </div>
                            </div>
                            {/* #endCol2---------------------  */}

                            {/* #Col3---------------------  */}
                            <div className="col-lg-4 px-4 pl-xl-6">
                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal>
                                        {t('lgsPurchaseOrder:receivingPlant')}
                                    </LabelNormal>
                                    <div className="input-group">
                                        <InputElement
                                            className="form-control border-grey2"
                                            placeholder={t(
                                                'lgsPurchaseOrder:selectData'
                                            )}
                                            value={data.plantName || ''}
                                            onChange={() => {}}
                                            type="text"
                                            id="plantName"
                                            name="plantName"
                                            style={{
                                                cursor: 'pointer',
                                                pointerEvents: 'none',
                                                borderRight: 0,
                                            }}
                                        />
                                        <CancelInputBtn
                                            onClick={() => {
                                                handleCancelClick('plantName');
                                            }}
                                            //plantName有值，則存在刪除按鈕
                                            isValueExist={
                                                data.plantName || false
                                            }
                                        >
                                            <Icn_InputYellowCancel />
                                        </CancelInputBtn>
                                        <DialogBtn
                                            style={{
                                                backgroundColor: '#fff',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                handlePlantShow();
                                            }}
                                        >
                                            <Icn_OpenModal />
                                        </DialogBtn>
                                    </div>
                                </div>

                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal>
                                        {t(
                                            'lgsPurchaseOrder:receivingDepartment'
                                        )}
                                    </LabelNormal>
                                    <div className="input-group">
                                        <InputElement
                                            className="form-control border-right-0 item-view-VendorGuid border-grey2"
                                            placeholder={t(
                                                'lgsPurchaseOrder:selectData'
                                            )}
                                            value={data.sectorName || ''}
                                            onChange={() => {}}
                                            type="text"
                                            id="sectorName"
                                            name="sectorName"
                                            style={{
                                                cursor: 'pointer',
                                                pointerEvents: 'none',
                                                borderRight: 0,
                                            }}
                                        />
                                        <CancelInputBtn
                                            onClick={() => {
                                                handleCancelClick('sectorName');
                                            }}
                                            // staffName有值，則存在刪除按鈕
                                            isValueExist={
                                                data.sectorName || false
                                            }
                                        >
                                            <Icn_InputYellowCancel />
                                        </CancelInputBtn>
                                        <DialogBtn
                                            style={{
                                                backgroundColor: '#fff',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                handleSectorShow();
                                            }}
                                        >
                                            <Icn_OpenModal />
                                        </DialogBtn>
                                    </div>
                                </div>

                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal>
                                        {' '}
                                        {t('lgsPurchaseOrder:receiver')}
                                    </LabelNormal>
                                    <div className="input-group">
                                        <InputElement
                                            className="form-control border-grey2"
                                            placeholder={t(
                                                'lgsPurchaseOrder:selectData'
                                            )}
                                            value={data.staffName || ''}
                                            onChange={() => {}}
                                            type="text"
                                            id="staffName"
                                            name="staffName"
                                            style={{
                                                cursor: 'pointer',
                                                pointerEvents: 'none',
                                                borderRight: 0,
                                            }}
                                        />

                                        <CancelInputBtn
                                            onClick={() => {
                                                handleCancelClick('staffName');
                                            }}
                                            // staffName有值，則存在刪除按鈕
                                            isValueExist={
                                                data.staffName || false
                                            }
                                        >
                                            <Icn_InputYellowCancel />
                                        </CancelInputBtn>

                                        <DialogBtn
                                            style={{
                                                backgroundColor: '#fff',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                handleStaffShow();
                                            }}
                                        >
                                            <Icn_OpenModal />
                                        </DialogBtn>
                                    </div>
                                </div>

                                <div
                                    className={`wrapper d-flex flex-column mb-3`}
                                >
                                    <LabelNormal htmlFor={'note'}>
                                        {t('lgsPurchaseOrder:annotation')}
                                    </LabelNormal>
                                    <TextareaElement
                                        //style={elementStyle}
                                        id={'note'}
                                        rows={'4'}
                                        cols={'30'}
                                        value={data.note || ''}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                note: e.target.value || '',
                                            });
                                            //const noteValue = getValues('note');
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                    />
                                </div>
                            </div>
                            {/* #endCol3---------------------  */}
                        </div>
                    </div>
                    {/* </form> */}
                </Wrapper>
            </div>

            <CurrencyModal
                modalOpen={currencyTableShow}
                handleClose={handleCurrencyClose}
                currencyList={currencyList}
                data={data}
                setData={setData}
                companyCurrencyCode={companyCurrencyCode}
            />
            <VendorModal
                modalOpen={vendorTableShow}
                handleClose={handleVendorClose}
                vendorList={vendorList}
                invoiceTaxRateTypeList={invoiceTaxRateTypeList}
                data={data}
                setData={setData}
            />
            <PlantModal
                modalOpen={plantTableShow}
                handleClose={handlePlantClose}
                plantList={plantList}
                data={data}
                setData={setData}
            />
            <SectorModal
                modalOpen={sectorTableShow}
                handleClose={handleSectorClose}
                sectorList={sectorList}
                data={data}
                setData={setData}
            />
            <StaffModal
                modalOpen={staffTableShow}
                handleClose={handleStaffClose}
                employeeList={employeeList}
                data={data}
                setData={setData}
            />
        </>
    );
}
export default Page0;
