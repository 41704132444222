import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Icn_Activate } from '../../../assets/images/icn_Activate.svg';
import { ReactComponent as Icn_Edit } from '../../../assets/images/icn_Edit.svg';
import { ReactComponent as Icn_Lock } from '../../../assets/images/icn_Lock.svg';
import { ReactComponent as LogIcon } from '../../../assets/images/icn_Log.svg';
import { ReactComponent as Icn_Trash } from '../../../assets/images/icn_Trash.svg';
import { BtnNormal2 } from '../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { DropdownMenuArea } from '../../../components/DropdownMenuArea/DropdownMenuArea';
import Input from '../../../components/FormElement/Input2';
import PageTitleBar from '../../../components/PageTitleBar/PageTitleBar';
import StatusBar from '../../../components/StatusBar/StatusBarNormal';
import { TabPage } from '../LgsPurchaseOrder/exports';
import ServiceAttachment from './ServiceAttachment';
import ServiceEstimatedPrice from './ServiceEstimatedPrice';

// #region styled-components
const Wrapper = styled.div`
    /* min-height: 720px; */
    border-radius: 0 0 0.75rem 0.75rem;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Right = styled.div`
    display: flex;
`;

const LabelNormal = styled.label`
    text-align: left;
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;

const InputElement = styled.input`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
`;

const SelectElement = styled.select`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 6px 8px;

    height: 32px;
    &:focus-visible {
        outline: 0;
    }

    /* 下拉箭頭圖案 */
    background-image: linear-gradient(45deg, transparent 50%, var(--grey4) 50%),
        linear-gradient(135deg, var(--grey4) 50%, transparent 50%) !important;
    background-position: calc(100% - 18px) 12px, calc(100% - 12px) 12px;
    background-size: 6px 6px, 6px 6px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
`;

//#endregion

export default function LgsServiceMaster_Details() {
    const serviceGuid = useLocation().pathname.split('/').pop();
    // console.log(serviceGuid);

    const { t } = useTranslation(['common', 'lgsServiceMaster']);
    const [data, setData] = useState(null);

    // 分頁title
    const tabNavList = [
        '照片',
        '預定售價',
        // t('lgsPurchaseOrder:basicInformation'),
    ];

    // 分頁內容 component
    const tabContent = [
        <ServiceAttachment
            appendixList={data?.serviceAttachmentList}
            view={'details'}
        />,
        <ServiceEstimatedPrice
            data={data?.serviceEstimatedPriceList}
            view={'details'}
        />,
    ];

    useEffect(() => {
        axios(`api/Logistics/LgsServiceMaster/getDetails/${serviceGuid}`).then(
            (res) => {
                setData(res.data);
            }
        );
    }, []);

    const forSaleCodeModel = {
        0: '不能銷售',
        1: '可銷售',
        2: '暫停銷售',
    };

    console.log('DATA, ', data);

    return (
        <div>
            <div id="detailsView">
                <PageTitleBar
                    pageTitle={'你好'}
                    previousPageButton={true}
                    backTo={-1}
                    // isDialogConfirm={true}
                    // handleDialogConfirm={handleDialogConfirm}
                />
                <Container className="">
                    <Left className="">
                        <BtnNormal2
                            permission={[]}
                            serviceGuid={serviceGuid}
                            className="btn-primary smallBtn"
                            to="Create"
                            key="EditBtnDetails"
                            handleClick={(e) =>
                                navigate(`../Edit/${serviceGuid}`)
                            }
                            word={t('common:button.editButton')}
                        >
                            <Icn_Edit />
                        </BtnNormal2>

                        {/* 當 status = 0 or 2 時，顯示"啟用"按鈕 */}
                        {(data?.status === '0' || data?.status === '2') && (
                            <BtnNormal2
                                className="btn-primary smallBtn"
                                key="EnableBtnDetails"
                                handleClick={() =>
                                    setIsActivateDialogOpen(true)
                                }
                                word={t('common:button.activateButton')}
                            >
                                <Icn_Activate />
                            </BtnNormal2>
                        )}
                    </Left>

                    <Center className=""></Center>

                    <Right className="">
                        <DropdownMenuArea permission={data?.permission}>
                            {/* 先判斷 status 是不是 1 ；不是的話再看是不是0。*/}
                            {data?.status === '1'
                                ? [
                                      <div
                                          className="d-flex justify-content-center align-items-center"
                                          key={'changeRecord'}
                                          onClick={() =>
                                              navigate(`../Log/${serviceGuid}`)
                                          }
                                      >
                                          <LogIcon />
                                          <span>
                                              {t('common:button.logButton')}
                                          </span>
                                      </div>,
                                      <div
                                          className="d-flex justify-content-center align-items-center"
                                          key={'disabled'}
                                          onClick={() =>
                                              setIsInActivateDialogOpen(true)
                                          }
                                      >
                                          <Icn_Lock />
                                          <span>
                                              {t('common:button.lockButton')}
                                          </span>
                                      </div>,
                                  ]
                                : data?.status === '0'
                                ? [
                                      <div
                                          className="d-flex justify-content-center align-items-center"
                                          key={'changeRecord'}
                                          onClick={() =>
                                              navigate(`../Log/${serviceGuid}`)
                                          }
                                      >
                                          <LogIcon />
                                          <span>
                                              {t('common:button.logButton')}
                                          </span>
                                      </div>,
                                      <div
                                          className="d-flex justify-content-center align-items-center"
                                          onClick={() =>
                                              setIsDeleteDialogOpen(true)
                                          }
                                      >
                                          <Icn_Trash />
                                          <span>
                                              {t('common:button.deleteButton')}
                                          </span>
                                      </div>,
                                  ]
                                : [
                                      <div
                                          className="d-flex justify-content-center align-items-center"
                                          key={'changeRecord'}
                                          onClick={() =>
                                              navigate(`../Log/${serviceGuid}`)
                                          }
                                      >
                                          <LogIcon />
                                          <span>{t('button.logButton')}</span>
                                      </div>,
                                  ]}
                        </DropdownMenuArea>
                    </Right>
                </Container>
                <div className="position-relative">
                    <StatusBar status={data?.status} />
                    <Wrapper className="bg-white d-flex flex-column p-4 position-relative mb-3">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            // labelText={t(
                                            //     'lgsPurchaseOrder:vendorSalesRepresentative'
                                            // )}

                                            labelText="代碼"
                                            type="text"
                                            id="vendorSales"
                                            placeholder=""
                                            disabled={true}
                                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={data?.serviceNo || ''}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 py-1 "></div>
                                <div className="col-lg-4 px-4 py-1 "></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            // labelText={t(
                                            //     'lgsPurchaseOrder:vendorSalesRepresentative'
                                            // )}
                                            labelText="名稱"
                                            type="text"
                                            id="vendorSales"
                                            placeholder=""
                                            disabled={true}
                                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={data?.name01 || ''}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            // labelText={t(
                                            //     'lgsPurchaseOrder:vendorSalesRepresentative'
                                            // )}
                                            labelText="名稱（英文）"
                                            type="text"
                                            id="vendorSales"
                                            placeholder=""
                                            disabled={true}
                                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={data?.name02 || ''}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            // labelText={t(
                                            //     'lgsPurchaseOrder:vendorSalesRepresentative'
                                            // )}
                                            labelText="名稱（日語）"
                                            type="text"
                                            id="vendorSales"
                                            placeholder=""
                                            disabled={true}
                                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={data?.name03 || ''}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            // labelText={t(
                                            //     'lgsPurchaseOrder:vendorSalesRepresentative'
                                            // )}
                                            labelText="服務分類"
                                            type="text"
                                            id="vendorSales"
                                            placeholder=""
                                            disabled={true}
                                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={data?.className || ''}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            // labelText={t(
                                            //     'lgsPurchaseOrder:vendorSalesRepresentative'
                                            // )}
                                            labelText="計量單位"
                                            type="text"
                                            id="vendorSales"
                                            placeholder=""
                                            disabled={true}
                                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={data?.uomCode || ''}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <div className="wrapper d-flex flex-column mb-3">
                                        <Input
                                            Input
                                            wrapperClass="mb-3"
                                            // labelText={t(
                                            //     'lgsPurchaseOrder:vendorSalesRepresentative'
                                            // )}
                                            labelText="可銷售"
                                            type="text"
                                            id="vendorSales"
                                            placeholder=""
                                            disabled={true}
                                            disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
                                            value={
                                                data?.forSale === undefined
                                                    ? ''
                                                    : forSaleCodeModel[
                                                          data?.forSale
                                                      ]
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wrapper>
                    <TabPage
                        liColWidth="col-2"
                        tabNavList={tabNavList}
                        tabContent={tabContent}
                        defaultPage={0}
                    />
                </div>
            </div>
        </div>
    );
}

// <div className="col-lg-4 px-4 py-1 ">
// <Input
//     Input
//     wrapperClass="mb-3"
//     // labelText={t(
//         'lgsPurchaseOrder:vendorSalesRepresentative'
//     )}
//     type="text"
//     id="vendorSales"
//     placeholder=""
//     disabled={true}
//     disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
//     value={'aa'}
// />
// <Input
//     Input
//     wrapperClass="mb-3"
//     // labelText={t(
//         'lgsPurchaseOrder:vendorSalesRepresentative'
//     )}
//     type="text"
//     id="vendorSales"
//     placeholder=""
//     disabled={true}
//     disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
//     value={'aa'}
// />
// </div>
