import axios from 'axios';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { generateUpdateLogContent } from '../../../../utils';
import { BtnNormal2 } from '../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as Icn_Activate } from '../../../../assets/images/icn_Activate.svg';
import { ReactComponent as Icn_Edit } from '../../../../assets/images/icn_Edit.svg';
import { ReactComponent as Icn_Lock } from '../../../../assets/images/icn_Lock.svg';
import { ReactComponent as LogIcon } from '../../../../assets/images/icn_Log.svg';
import { ReactComponent as Icn_Trash } from '../../../../assets/images/icn_Trash.svg';
import { BtnNormal } from '../../../../components/Buttons/BtnNormal';
import { CancelSubmitBtn } from '../../../../components/Buttons/CancelSubmitBtn';
import PageTitleBar from '../../../../components/PageTitleBar/PageTitleBar';
import StatusBar from '../../../../components/StatusBar/StatusBarNormal';
import BottomInfo from '../Component/BottomInfo';
import TopInfo from '../Component/TopInfo';
import { DialogStatusConfirm } from '../Dialog/DialogStatusConfirm';
import { DropdownMenuArea } from '../Dialog/DropdownMenuArea';
import { SubmitConfirm } from '../Dialog/SubmitConfirm';

// #region styled-components

const IndexTableContainer = styled.div``;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function LgsSalesKit_DetailsAndEdit() {
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	const pageTitle = t('lgsSalesKit:salesProductMasterFile'); //'銷售商品主檔'
	const param = useParams('salesKitGuid');
	const isDetailRoute = useMatch(
		'/Logistics/LgsSalesKit/Details/:salesKitGuid'
	);
	const isEditRoute = useMatch('/Logistics/LgsSalesKit/Edit/:salesKitGuid');
	// console.log(param);
	// console.log(isDetailRoute);

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
	const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
	const [twoOrThreeBtn, setTwoOrThreeBtn] = useState(''); // 開啟兩按鈕或三按鈕的dialog

	const [isOpen, setIsOpen] = useState(false);
	const [tableBodyData, setTableBodyData] = useState([]);
	const [dialogBtnKeyName, setDialogBtnKeyName] = useState('');
	const [modalData, setModalData] = useState([]);
	const [salesKitGuid, setSalesKitGuid] = useState(param.salesKitGuid);
	const [isBaseInfoCreateSuccess, setIsBaseInfoCreateSuccess] =
		useState(true);
	const [selectedCustomerClassList, setSelectedCustomerClassList] = useState(
		[]
	);
	const [originalDataForLog, setOriginalDataForLog] = useState({});
	const [pageData, setPageData] = useState({
		goodsItemInfo: {
			salesKitGuid: salesKitGuid,
			salesKitCode: '',
			salesKitCodeWarning: '',
			name01: '',
			name02: '',
			name03: '',
			currencyCode: '',
			listPrice: '',
			startDate: '',
			endDate: '',
			salesKitClassGuid: '',
			uomCode: '',
			note: '',
		},
		appendixList: [],
		salesDetailsList: [],
		priceDetailsList: [],
	});
	const [cookies, setCookie] = useCookies();
	const cookieLangList = [
		cookies['_system_language01'],
		cookies['_system_language02'] ? cookies['_system_language02'] : '',
		cookies['_system_language03'] ? cookies['_system_language03'] : '',
	];
	const [langList, setLangList] = useState(cookieLangList);
	const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
	const [specList, setSpecList] = useState([]);
	const [baseInfoList, setBaseInfoList] = useState({}); // 基礎的所有選單list

	const navigate = useNavigate();

	// 上一頁
	const handleDialogConfirm = () => {
		isEditRoute
			? navigate('/Logistics/LgsSalesKit/Details/' + salesKitGuid)
			: navigate('/Logistics/LgsSalesKit');
	};

	// 規格與顏色的displayOrder
	const getDisplayOrder = (list, key, guid) => {
		const matchedItem = list.find((el) => el[key] === guid);
		return matchedItem ? matchedItem.displayOrder : null;
	};

	//啟用
	const handleActivate = () => {
		const newData = {
			...pageData.goodsItemInfo,
			status: '1',

			// log
			updateContent: t('common:log.activate', {
				value: pageData.goodsItemInfo.name01,
			}),
		};

		const formData = new FormData();
		Object.keys(newData).forEach((key) => {
			formData.append(`SalesKit.${key}`, newData[key]);
		});

		axios
			.put(`api/Logistics/LgsSalesKit/activate`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => {
				if (res.data) {
					setIsActivateDialogOpen(false);

					const sizeDisplayOrder = getDisplayOrder(
						specList.lgsSalesKitSize,
						'sizeGuid',
						specList.lgsSalesKitSize[0].sizeGuid
					);
					const colorDisplayOrder = getDisplayOrder(
						specList.lgsSalesKitColor,
						'colorGuid',
						specList.lgsSalesKitColor[0].colorGuid
					);

					getDetails(
						sizeDisplayOrder,
						colorDisplayOrder,
						specList.lgsSalesKitSize[0].sizeGuid,
						specList.lgsSalesKitColor[0].colorGuid
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//停用
	const handleInActivate = () => {
		const newData = {
			...pageData.goodsItemInfo,
			status: '2',

			// log
			updateContent: t('common:log.lock', {
				value: pageData.goodsItemInfo.name01,
			}),
		};

		const formData = new FormData();
		Object.keys(newData).forEach((key) => {
			formData.append(`SalesKit.${key}`, newData[key]);
		});

		axios
			.put(`api/Logistics/LgsSalesKit/lock`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => {
				if (res.data) {
					setIsInActivateDialogOpen(false);

					const sizeDisplayOrder = getDisplayOrder(
						specList.lgsSalesKitSize,
						'sizeGuid',
						specList.lgsSalesKitSize[0].sizeGuid
					);
					const colorDisplayOrder = getDisplayOrder(
						specList.lgsSalesKitColor,
						'colorGuid',
						specList.lgsSalesKitColor[0].colorGuid
					);

					getDetails(
						sizeDisplayOrder,
						colorDisplayOrder,
						specList.lgsSalesKitSize[0].sizeGuid,
						specList.lgsSalesKitColor[0].colorGuid
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//刪除
	const handleDelete = () => {
		const newData = {
			...pageData.goodsItemInfo,
			status: '4',

			// log
			updateContent: t('common:log.delete', {
				value: pageData.goodsItemInfo.name01,
			}),
		};

		const formData = new FormData();
		Object.keys(newData).forEach((key) => {
			formData.append(`SalesKit.${key}`, newData[key]);
		});

		axios
			.put(`api/Logistics/LgsSalesKit/delete`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => {
				if (res.data) {
					navigate('/Logistics/LgsSalesKit');
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleSubmit = () => {
		let falseCount = 0;

		// 驗證欄位必填
		let newPageData = { ...pageData };

		const fillDataVerifyList = [
			'salesKitClassGuid',
			'name01',
			'uomCode',
			'currencyGuid',
			'listPrice',
			'startDate',
		];

		fillDataVerifyList.forEach((el) => {
			if (pageData.goodsItemInfo[el] === '') {
				newPageData.goodsItemInfo[el + 'Warning'] = t(
					'common:errMsg.mustFill'
				);
				falseCount++;
			}
		});

		setPageData({ ...newPageData });

		// 驗證商品代碼不重複
		if (pageData.goodsItemInfo.salesKitCodeWarning !== '') falseCount++;

		// 驗證通過
		console.log(falseCount);
		if (falseCount === 0) {
			const newData = {
				...pageData.goodsItemInfo,

				// log
				updateContent: t('log.create', {
					value: pageData.goodsItemInfo.name01,
				}),
			};
			console.log(newData);

			const formData = new FormData();
			Object.keys(newData).forEach((key) => {
				formData.append(`SalesKit.${key}`, newData[key]);
			});

			axios
				.post(`api/Logistics/LgsSalesKit/insert`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((res) => {
					console.log(res);

					if (res.data) {
						const newPageData = { ...pageData };
						setSalesKitGuid(res.data);
						setPageData({
							...newPageData,
							goodsItemInfo: {
								...newPageData.goodsItemInfo,
								salesKitGuid: res.data,
							},
						});

						setIsBaseInfoCreateSuccess(true);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const getPageInfoBaseList = async () => {
		await axios
			.post(`api/Logistics/LgsSalesKit/getPageInfo`)
			.then((res) => {
				setBaseInfoList({ ...res.data.result });
			});
	};
	console.log(baseInfoList);

	const getSpecList = async () => {
		const specListTemp = { lgsSalesKitSize: [], lgsSalesKitColor: [] };

		await axios
			.post(
				`api/Logistics/LgsSalesKitSize/getList`,
				JSON.stringify(salesKitGuid), // 銷售主檔guid
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			.then((res) => {
				// console.log(res.data.indexData);

				// 依照displayOrder排序&篩選狀態為0的資料
				const sortAndFilterSpecList = res.data.indexData
					.sort((a, b) => {
						return a.displayOrder - b.displayOrder;
					})
					.filter((el) => {
						return el.status === '0';
					});
				specListTemp.lgsSalesKitSize.push(...sortAndFilterSpecList);
			});

		await axios
			.post(
				`api/Logistics/LgsSalesKitColor/getList`,
				JSON.stringify(salesKitGuid), // 銷售主檔guid
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			.then((res) => {
				// console.log(res);

				// 依照displayOrder排序&篩選狀態為0的資料
				const sortAndFilterSpecList = res.data.indexData
					.sort((a, b) => {
						return a.displayOrder - b.displayOrder;
					})
					.filter((el) => {
						return el.status === '0';
					});
				specListTemp.lgsSalesKitColor.push(...sortAndFilterSpecList);
			});

		getPageInfoBaseList();
		setSpecList(specListTemp);
	};

	const getDetails = (
		salesKitSizeDisplayOrder,
		salesKitColorDisplayOrder,
		sizeGuid,
		colorGuid
	) => {
		axios
			.get(
				`api/Logistics/LgsSalesKit/getDetails/${salesKitGuid}?SalesKitColorDisplayOrder=${
					salesKitColorDisplayOrder ?? '1'
				}&SalesKitSizeDisplayOrder=${salesKitSizeDisplayOrder ?? '1'}`
			)
			.then((res) => {
				console.log(res.data);
				console.log(baseInfoList);

				// salesKit
				const currencyInfo = baseInfoList?.currencyList?.find(
					(el) => el.currencyCode === res.data.salesKit.currencyCode
				);

				const salesKitClassInfo = baseInfoList?.salesKitClassList?.find(
					(el) =>
						el.salesKitClassGuid ===
						res.data.salesKit.salesKitClassGuid
				);

				const uomInfo = baseInfoList?.uomList?.find(
					(el) => el.uomcode === res.data.salesKit.uomcode
				);

				// appendixList
				const newAppendixList = res.data.salesKitAppendixList
					? [...res.data.salesKitAppendixList].map((el) => {
							return {
								action: 'native',
								// appendixFile: '',
								// appendixType: 'jpg',
								appendixName: el.appendixName,
								appendixUrl: el.appendixUrl,
								displayOrder: el.displayOrder,
								pictureGuid: el.pictureGuid,
								rowStamp: el.rowStamp,
							};
					  })
					: [];

				// salesDetailsList
				const newSalesDetailsList = res.data.salesKitItemList
					? [...res.data.salesKitItemList].map((el) => {
							const materialInfo =
								baseInfoList?.materialMasterList?.find(
									(item) =>
										item.materialGuid === el.materialGuid
								);

							const serviceInfo =
								baseInfoList?.serviceMasterList?.find(
									(item) =>
										item.serviceGuid === el.serviceGuid
								);

							return {
								action: 'native',
								displayOrder: el.displayOrder,
								itemGuid: el.itemGuid,
								id: el.itemGuid,
								materialGuid: el.materialGuid ?? '',
								materialName: materialInfo?.name01 ?? '',
								note: el.note,
								percentage: el.percentage,
								quantity: el.quantity,
								salesCategoryCode: el.salesCategoryCode,
								salesCategoryName:
									el.salesCategoryCode === 'P'
										? t('lgsSalesKit:material') //'物料'
										: el.salesCategoryCode === 'S'
										? t('lgsSalesKit:service') //'服務'
										: '',
								serviceGuid: el.serviceGuid ?? '',
								serviceName: serviceInfo?.name01 ?? '',
								rowStamp: el.rowStamp,
							};
					  })
					: [];

				// priceDetailsList
				const newPriceDetailsList = res.data.salesKitPriceList
					? [...res.data.salesKitPriceList].map((el) => {
							const currencyInfo =
								baseInfoList?.currencyList?.find(
									(item) =>
										item.currencyCode === el.currencyCode
								);

							return {
								action: 'native',
								currencyCode: el.currencyCode,
								currencyName: currencyInfo?.name01,
								customerClassGuid: el.customerClassGuid ?? '', // null 轉為 ""
								displayOrder: el.displayOrder,
								endDate: el.endDate?.split('T')[0] ?? '',
								id: el.priceGuid,
								priceGuid: el.priceGuid,
								note: el.note,
								quantityFrom: el.quantityFrom,
								quantityTo: el.quantityTo,
								rowStamp: el.rowStamp,
								startDate: el.startDate?.split('T')[0],
								unitPrice: el.unitPrice,
								rowStamp: el.rowStamp,
							};
					  })
					: [];

				res.data.customerClassList &&
					setSelectedCustomerClassList([
						...res.data.customerClassList,
					]);

				setPageData({
					goodsItemInfo: {
						...res.data.salesKit,
						colorGuid:
							colorGuid ??
							pageData.goodsItemInfo.colorGuid ??
							'00000000-0000-0000-0000-000000000000',
						sizeGuid:
							sizeGuid ??
							pageData.goodsItemInfo.sizeGuid ??
							'00000000-0000-0000-0000-000000000000',
						colorGuidWarning: '',
						sizeGuidWarning: '',

						startDate: res.data.salesKit.startDate.split('T')[0],
						endDate: res.data.salesKit.endDate?.split('T')[0] ?? '',

						currencyCode: res.data.salesKit.currencyCode,
						currencyGuid: currencyInfo?.currencyGuid,
						currencyName: currencyInfo?.name01,
						currencyGuidWarning: '',

						salesKitClassGuid: res.data.salesKit.salesKitClassGuid,
						salesKitClassCode: salesKitClassInfo?.salesKitClassCode,
						salesKitClassName: salesKitClassInfo?.name01,
						salesKitClassGuidWarning: '',

						uomCode: res.data.salesKit.uomcode,
						uomDisplayName: `${res.data.salesKit.uomcode} ${uomInfo?.name01}`,
						uomGuid: uomInfo?.uomguid,
						uomName: uomInfo?.name01,
						uomCodeWarning: '',

						salesKitCodeWarning: '',

						rowStamp: res.data.salesKit.rowStamp,
					},
					appendixList: [...newAppendixList],
					salesDetailsList: [...newSalesDetailsList],
					priceDetailsList: [...newPriceDetailsList],
				});

				// 保留原本資料用來LOG比對用
				setOriginalDataForLog({
					goodsItemInfo: {
						...res.data.salesKit,
						colorGuid: pageData.goodsItemInfo.colorGuid,
						sizeGuid: pageData.goodsItemInfo.sizeGuid,

						startDate: res.data.salesKit.startDate.split('T')[0],
						endDate: res.data.salesKit.endDate?.split('T')[0] ?? '',

						currencyCode: res.data.salesKit.currencyCode,
						currencyGuid: currencyInfo?.currencyGuid,
						currencyName: currencyInfo?.name01,
						currencyGuidWarning: '',

						salesKitClassGuid: res.data.salesKit.salesKitClassGuid,
						salesKitClassCode: salesKitClassInfo?.salesKitClassCode,
						salesKitClassName: salesKitClassInfo?.name01,
						salesKitClassGuidWarning: '',

						uomCode: res.data.salesKit.uomcode,
						uomDisplayName: `${res.data.salesKit.uomcode} ${uomInfo?.name01}`,
						uomGuid: uomInfo?.uomguid,
						uomName: uomInfo?.name01,
						uomCodeWarning: '',

						rowStamp: res.data.salesKit.rowStamp,
					},
					appendixList: [...newAppendixList],
					salesDetailsList: [...newSalesDetailsList],
					priceDetailsList: [...newPriceDetailsList],
				});

				// 依狀態判斷儲存要用兩個或三個欄位
				if (res.data.salesKit.status === '1') {
					setTwoOrThreeBtn('2');
				} else if (
					res.data.salesKit.status === '0' ||
					res.data.salesKit.status === '2'
				) {
					setTwoOrThreeBtn('3');
				}
			});
	};

	useEffect(() => {
		getSpecList();
	}, []);

	useEffect(() => {
		getDetails();
	}, [baseInfoList, isDetailRoute, isEditRoute]);

	// salesDetails相關
	const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
		useState(false);
	const [error, setError] = useState({});

	const checkEstimatedPriceList = async () => {
		const newErrorState = {
			salesDetailsList: {}, // 初始化 salesDetailsList 对象
			priceDetailsList: {}, // 初始化 priceDetailsList 对象
		};

		// 銷售明細
		const keysToCheck = [
			'materialGuid', // materialGuid與serviceGuid，擇一必填
			'serviceGuid', // materialGuid與serviceGuid，擇一必填
			'quantity',
			'percentage',
		];

		for (const checkKey of keysToCheck) {
			for (const element of pageData?.salesDetailsList || []) {
				const uuid = element.id; // uuid 存在于 element id 中

				if (!newErrorState['salesDetailsList'][uuid]) {
					newErrorState['salesDetailsList'][uuid] = {};
				}

				if (
					(!element || !element[checkKey]) &&
					element.action !== 'delete'
				) {
					// materialGuid與serviceGuid，擇一必填即可
					if (
						(element['serviceGuid'] &&
							checkKey === 'materialGuid') ||
						(element['materialGuid'] && checkKey === 'serviceGuid')
					) {
						console.log(
							'已填入 materialGuid 或 serviceGuid，擇一必填'
						);
					} else {
						newErrorState['salesDetailsList'][uuid][checkKey] = {
							isError: true,
							message: `${t('common:errMsg.mustFill')}`,
						};
					}
				}

				// 銷售明細-價格拆分比例加總必須為100
				if (element['percentage'] && element.action !== 'delete') {
					let total = 0;
					pageData.salesDetailsList.forEach((element) => {
						total += parseInt(element.percentage);
					});

					// console.log(total);

					if (total !== 100) {
						newErrorState['salesDetailsList'][uuid]['percentage'] =
							{
								isError: true,
								message: t(
									'lgsSalesKit:percentageSumMustBe100'
								),
							};
					}
				}
			}
		}

		// 價格條件
		const keysToCheck2 = ['startDate', 'currencyCode', 'unitPrice'];
		for (const checkKey of keysToCheck2) {
			for (const element of pageData?.priceDetailsList || []) {
				const uuid = element.id; // uuid 存在于 element id 中

				if (!newErrorState['priceDetailsList'][uuid]) {
					newErrorState['priceDetailsList'][uuid] = {};
				}

				if (
					(!element || !element[checkKey]) &&
					element.action !== 'delete'
				) {
					newErrorState['priceDetailsList'][uuid][checkKey] = {
						isError: true,
						message: `${t('common:errMsg.mustFill')}`,
					};
				}
			}
		}

		for (const element of pageData?.priceDetailsList || []) {
			const uuid = element.id; // uuid 存在于 element id 中

			if (!newErrorState['priceDetailsList'][uuid]) {
				newErrorState['priceDetailsList'][uuid] = {};
			}

			if (
				element['quantityTo'] < element['quantityFrom'] &&
				element.action !== 'delete'
			) {
				newErrorState['priceDetailsList'][uuid]['quantityTo'] = {
					isError: true,
					message: t(
						'lgsSalesKit:quantityUpperLimitCannotBeLessThanLowerLimit'
					), //`數量上限不能小於數量下限`,
				};
			}
		}

		console.log(newErrorState);
		return newErrorState;
	};

	const checkInputs = async () => {
		const estimatedPriceListError = await checkEstimatedPriceList();

		const newErrors = {
			...estimatedPriceListError,
		};

		setError(newErrors);
		return newErrors;
	};

	// 递归检查是否有子对象中的"isError"为true
	const checkIsErrorTrue = async (obj) => {
		if (obj && typeof obj === 'object' && Object.keys(obj).length > 0) {
			for (const prop in obj) {
				if (obj.hasOwnProperty(prop)) {
					const property = obj[prop];

					if (typeof property === 'object') {
						if (
							Object.keys(property).length > 0 &&
							checkIsErrorTrue(property)
						) {
							return true;
						}
					} else if (prop === 'isError' && property === true) {
						return true;
					}
				}
			}
			// 所有子属性都为 false，返回 false
			return false;
		} else {
			return false;
		}
	};

	const checkIsAnyErrors = async () => {
		const finalError = await checkInputs();
		console.log(finalError);

		// 遍历List每个子对象
		for (const key in finalError) {
			if (finalError.hasOwnProperty(key)) {
				const childObject = finalError[key];

				// 检查子对象中的"isError"是否为true
				if (await checkIsErrorTrue(childObject)) {
					return true;
				}
			}
		}

		let falseCount = 0;
		let newPageData = { ...pageData };

		// topInfo and size and color
		const fillDataVerifyList = [
			'salesKitClassGuid',
			'name01',
			'uomCode',
			'currencyGuid',
			'listPrice',
			'startDate',

			'sizeGuid',
			'colorGuid',
		];

		fillDataVerifyList.forEach((el) => {
			if (!pageData.goodsItemInfo[el]) {
				newPageData.goodsItemInfo[el + 'Warning'] = t(
					'common:errMsg.mustFill'
				);
				falseCount++;
			}
		});

		// 驗證商品代碼不重複
		if (pageData.goodsItemInfo.salesKitCodeWarning !== '') falseCount++;

		// 銷售明細-價格拆分比例加總必須為100
		if (pageData.salesDetailsList && pageData.salesDetailsList.length > 0) {
			let total = 0;
			pageData.salesDetailsList.forEach((element) => {
				total += element.percentage;
			});

			console.log(total);

			if (total !== 100) {
				newPageData.salesDetailsList.forEach(
					(el) =>
						(el.percentageWarning = t(
							// 'lgsSalesKit:percentageTotalMustBe100'
							'比例加總須為100'
						))
				);
				falseCount++;
			}
		}

		console.log(falseCount);
		if (falseCount !== 0) {
			setPageData({ ...newPageData });
			return true;
		}

		// 如果所有子对象中的"isError"都为false，进行相应处理
		return false;
	};

	const confirmSubmit = async (isCopyItemSave, statusAction) => {
		// 驗證欄位
		const isValid = !(await checkIsAnyErrors());

		console.log(isValid);

		if (isValid) {
			setIsSubmitConfirmDialogOpen(true);

			const newData = {
				...pageData.goodsItemInfo,
				status:
					statusAction === 'active'
						? '1'
						: pageData.goodsItemInfo.status,
				updateContent: generateUpdateLogContent(
					'salesKit',
					t,
					{ ...pageData },
					originalDataForLog
				),

				// log
				// updateContent: t('log.edit', {
				// 	value: pageData.goodsItemInfo.name01,
				// }),
			};
			console.log(newData);

			// SalesKit
			const formData = new FormData();
			Object.keys(newData).forEach((key) => {
				formData.append(`SalesKit.${key}`, newData[key]);
			});

			// SalesKitAppendixList
			const addToFormData1 = (data, index, formData) => {
				Object.keys(data).forEach((key) => {
					if (key !== 'appendixUrl') {
						formData.append(
							`SalesKitAppendixList[${index}].${key}`,
							data[key]
						);
					}
				});
			};

			pageData.appendixList.forEach((item, index) => {
				addToFormData1(item, index, formData);
			});

			// SalesKitItemList
			const addToFormData2 = (data, index, formData) => {
				Object.keys(data).forEach((key) => {
					formData.append(
						`SalesKitItemList[${index}].${key}`,
						data[key]
					);
				});
			};

			pageData.salesDetailsList.forEach((item, index) => {
				addToFormData2(item, index, formData);
			});

			// SalesKitPriceList
			const addToFormData3 = (data, index, formData) => {
				Object.keys(data).forEach((key) => {
					formData.append(
						`SalesKitPriceList[${index}].${key}`,
						data[key]
					);
					salesKitGuid;
				});
			};

			pageData.priceDetailsList.forEach((item, index) => {
				addToFormData3(item, index, formData);
			});

			axios
				.put(`api/Logistics/LgsSalesKit/update`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((res) => {
					console.log(res);

					if (isCopyItemSave) {
						setIsCopyDialogOpen(true);
					} else {
						navigate(`../Details/${salesKitGuid}`);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}

		// 如果是"複製"的時候執行更新，則彈出提示框，提醒使用者有資料未填，所以無法開啟"複製"的dialog
		if (!isValid && isCopyItemSave) {
			alert('請先確認表單內容，再複製');
		}

		setIsSubmitConfirmDialogOpen(false);
	};

	const handleFinalSubmit = (isCopyItemSave, statusAction) => {
		confirmSubmit(isCopyItemSave, statusAction);
	};

	return (
		<>
			{/* DialogStatusConfirm element */}
			<DialogStatusConfirm
				t={t}
				isOpen={isActivateDialogOpen}
				setIsOpen={setIsActivateDialogOpen}
				handleClick={() => handleActivate()}
				message={t('common:dialog.activeConfirm')}
			/>
			<DialogStatusConfirm
				t={t}
				isOpen={isInActivateDialogOpen}
				setIsOpen={setIsInActivateDialogOpen}
				handleClick={() => handleInActivate()}
				message={t('common:dialog.lockConfirm')}
			/>
			<DialogStatusConfirm
				t={t}
				isOpen={isDeleteDialogOpen}
				setIsOpen={setIsDeleteDialogOpen}
				handleClick={() => handleDelete()}
				message={t('common:dialog.deleteConfirm')}
			/>

			<SubmitConfirm
				t={t}
				isOpen={isSubmitConfirmDialogOpen}
				setIsOpen={setIsSubmitConfirmDialogOpen}
				handleClickInactive={() =>
					handleFinalSubmit(false, 'notChange')
				} // isCopyItemSave是否是來自於"複製"的儲存, status維持本來狀態
				handleClickActive={() => handleFinalSubmit(false, 'active')} // isCopyItemSave是否是來自於"複製"的儲存, status啟動
				message={t('dialog.saveConfirm')}
				twoOrThreeBtn={twoOrThreeBtn}
			/>

			<div id="detailsView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={true}
					backTo={-1}
					isDialogConfirm={true}
					handleDialogConfirm={handleDialogConfirm}
				/>

				<>
					<Container className="">
						<Left className="">
							{isDetailRoute && (
								<>
									<BtnNormal2
										permission={[]}
										salesKitGuid={salesKitGuid}
										className="btn-primary smallBtn"
										to="Create"
										key="EditBtnDetails"
										handleClick={(e) =>
											navigate(`../Edit/${salesKitGuid}`)
										}
										word={t('common:button.editButton')}
									>
										<Icn_Edit />
									</BtnNormal2>

									{/* 當 status = 0 or 2 時，顯示"啟用"按鈕 */}
									{(pageData.goodsItemInfo?.status === '0' ||
										pageData.goodsItemInfo?.status ===
											'2') && (
										<BtnNormal2
											className="btn-primary smallBtn"
											key="EnableBtnDetails"
											handleClick={() =>
												setIsActivateDialogOpen(true)
											}
											word={t(
												'common:button.activateButton'
											)}
										>
											<Icn_Activate />
										</BtnNormal2>
									)}
								</>
							)}
						</Left>
						<Center className=""></Center>
						<Right className="">
							{isEditRoute && (
								<>
									<BtnNormal
										className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
										key={'finalSaveBtn'}
										onClick={async (e) => {
											e.preventDefault();

											// 先判斷有沒有錯誤，再開啟儲存框
											if (
												(await checkIsAnyErrors()) ===
												false
											) {
												setIsSubmitConfirmDialogOpen(
													true
												);
											}
										}}
									>
										{/* 儲存 */}
										{t('lgsSalesKit:save')}
									</BtnNormal>

									<CancelSubmitBtn
										className={'btn-outline-primary'}
									/>
								</>
							)}

							{isDetailRoute && (
								<DropdownMenuArea
									permission={
										pageData?.goodsItemInfo?.permission
									}
								>
									{/* 先判斷 status 是不是 1 ；不是的話再看是不是0。*/}
									{pageData?.goodsItemInfo?.status === '1'
										? [
												<div
													className="d-flex justify-content-start align-items-center w-100"
													key={'changeRecord'}
													onClick={() =>
														navigate(
															`../Log/${salesKitGuid}`
														)
													}
												>
													<LogIcon />
													<span>
														{t(
															'common:button.logButton'
														)}
													</span>
												</div>,
												<div
													className="d-flex justify-content-start align-items-center w-100"
													key={'disabled'}
													onClick={() =>
														setIsInActivateDialogOpen(
															true
														)
													}
												>
													<Icn_Lock />
													<span>
														{t(
															'common:button.lockButton'
														)}
													</span>
												</div>,
										  ]
										: pageData?.goodsItemInfo?.status ===
										  '0'
										? [
												<div
													className="d-flex justify-content-start align-items-center w-100"
													key={'changeRecord'}
													onClick={() =>
														navigate(
															`../Log/${salesKitGuid}`
														)
													}
												>
													<LogIcon />
													<span>
														{t(
															'common:button.logButton'
														)}
													</span>
												</div>,
												<div
													className="d-flex justify-content-start align-items-center w-100"
													onClick={() =>
														setIsDeleteDialogOpen(
															true
														)
													}
												>
													<Icn_Trash />
													<span>
														{t(
															'common:button.deleteButton'
														)}
													</span>
												</div>,
										  ]
										: [
												<div
													className="d-flex justify-content-center align-items-center"
													key={'changeRecord'}
													onClick={() =>
														navigate(
															`../Log/${salesKitGuid}`
														)
													}
												>
													<LogIcon />
													<span>
														{t('button.logButton')}
													</span>
												</div>,
										  ]}
								</DropdownMenuArea>
							)}
						</Right>
					</Container>

					<div className="position-relative">
						{pageData.goodsItemInfo?.status !== '1' && (
							<StatusBar
								status={pageData.goodsItemInfo?.status}
							/>
						)}

						<div
							className="bg-white d-flex flex-column p-4 position-relative mb-3"
							style={{
								// .75rem .75rem 0 0
								borderRadius: `${
									pageData.goodsItemInfo?.status !== '1'
										? '0 0 .75rem .75rem'
										: '.75rem'
								}`,
							}}
						>
							<TopInfo
								pageData={pageData}
								setPageData={setPageData}
								dialogBtnKeyName={dialogBtnKeyName}
								setDialogBtnKeyName={setDialogBtnKeyName}
								isOpen={isOpen}
								setIsOpen={setIsOpen}
								modalData={modalData}
								setModalData={setModalData}
								baseInfoList={baseInfoList}
								langList={langList}
								isDetailRoute={isDetailRoute}
							/>
						</div>
						{isBaseInfoCreateSuccess && (
							<div className="position-relative">
								<IndexTableContainer className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
									<BottomInfo
										pageData={pageData}
										setPageData={setPageData}
										tableBodyData={tableBodyData}
										setTableBodyData={setTableBodyData}
										dialogBtnKeyName={dialogBtnKeyName}
										setDialogBtnKeyName={
											setDialogBtnKeyName
										}
										isOpen={isOpen}
										setIsOpen={setIsOpen}
										modalData={modalData}
										setModalData={setModalData}
										langList={langList}
										getSpecList={getSpecList}
										specList={specList}
										salesKitGuid={salesKitGuid}
										handleFinalSubmit={handleFinalSubmit}
										error={error}
										setError={setError}
										baseInfoList={baseInfoList}
										selectedCustomerClassList={
											selectedCustomerClassList
										}
										setSelectedCustomerClassList={
											setSelectedCustomerClassList
										}
										isDetailRoute={isDetailRoute}
										isEditRoute={isEditRoute}
										getDetails={getDetails}
										isCopyDialogOpen={isCopyDialogOpen}
										setIsCopyDialogOpen={
											setIsCopyDialogOpen
										}
									/>
								</IndexTableContainer>
							</div>
						)}
					</div>
				</>
			</div>
		</>
	);
}

export default LgsSalesKit_DetailsAndEdit;
