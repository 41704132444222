import { CopyCreateBtn } from './components/Buttons/CopyCreateBtn';
import { CreateBtnNormal } from './components/Buttons/CreateBtnNormal';
import { DeleteBtnSmall } from './components/Buttons/DeleteBtnSmall';
import { EditBtnNormal } from './components/Buttons/EditBtnNormal';
import { SearchBtnSmall } from './components/Buttons/SearchBtnSmall';
import { DropdownMenuArea } from './components/DropdownMenuArea/DropdownMenuArea';

//TODO: 之後可能會不要
export const functionButtonsTable = (name, view) => {
    const table = {
        create: <CreateBtnNormal name={name} view={view} key={name} />,
        log: <DropdownMenuArea name={name} />,
        delete: <DeleteBtnSmall />,
        search: <SearchBtnSmall />,
        copy: <CopyCreateBtn />,
        edit: <EditBtnNormal />,
    };

    return table[name];
};

export const deepEqual = (obj1, obj2, visited = new Set()) => {
    // 步驟 1: 檢查是否是相同的引用
    if (obj1 === obj2) {
        return true;
    }

    // 步驟 2: 處理原始型別的比較
    if (
        typeof obj1 !== 'object' ||
        obj1 === null ||
        typeof obj2 !== 'object' ||
        obj2 === null
    ) {
        const isValueNaN = Number.isNaN(obj1);
        const isOtherNaN = Number.isNaN(obj2);

        // 在 JavaScript 中，NaN 是唯一不等於自己的值，所以要特別處理
        if (isValueNaN && isOtherNaN) {
            return true;
        }

        // 其他原始型別直接比較
        return obj1 === obj2;
    }

    // 步驟 3: 處理循環引用，使用 Set 來追踪已經比較過的對象
    if (visited.has(obj1) || visited.has(obj2)) {
        return true;
    }

    visited.add(obj1);
    visited.add(obj2);

    // 步驟 4: 處理特殊對象類型 - Date
    if (obj1 instanceof Date && obj2 instanceof Date) {
        return obj1.getTime() === obj2.getTime();
    }

    // 步驟 5: 處理特殊對象類型 - RegExp
    if (obj1 instanceof RegExp && obj2 instanceof RegExp) {
        return obj1.source === obj2.source && obj1.flags === obj2.flags;
    }

    // 步驟 6: 處理陣列類型
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) {
            return false;
        }

        // 迭代陣列中的每個值，遞迴使用 deepEqual 比較兩個值
        for (let i = 0; i < obj1.length; i++) {
            if (!deepEqual(obj1[i], obj2[i], visited)) {
                return false;
            }
        }

        return true;
    }

    // 步驟 7: 處理原型鏈上的屬性
    if (Object.getPrototypeOf(obj1) !== Object.getPrototypeOf(obj2)) {
        return false;
    }

    // 步驟 8: 處理普通對象類型，逐個比較鍵值對
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key], visited)) {
            return false;
        }
    }

    // 如果通過所有檢查，返回 true，說明兩個對象深度相等
    return true;
};

export const generateUpdateLogContent = (
    pageName,
    t,
    newContent,
    originalContent
) => {
    let updateContent = '';

    Object.keys(originalContent).forEach((key) => {
        const originalVal = originalContent[key];
        const updatedVal = newContent[key];

        if (deepEqual(originalVal, updatedVal)) {
            return;
        }

        const keyName = t(`${pageName}:index.${key}`);
        console.log(keyName);
        const content = t('log.edit', {
            key: `[${keyName}]`,
            originalVal: originalVal,
            updatedVal: updatedVal,
        });

        updateContent += `${content} `;
    });

    // console.log(updateContent);
    return updateContent;
};

export function generateUpdateLogContentFromArray(
    pageName,
    t,
    newContent,
    originalContent
) {
    console.log(newContent, originalContent);
    let updateContent = '';
    if (newContent) {
        newContent.forEach((el, index) => {
            let content = '';

            if (el.action === 'insert') {
                content = t('log.create', {
                    value: `價格條件[${index}]`,
                });
                updateContent += `${content} `;
            }

            if (el.action === 'delete') {
                content = t('log.delete', {
                    value: `價格條件[${index}]`,
                });
                updateContent += `${content} `;
            }

            if (el.action === 'update') {
                const originalEl = originalContent.find(
                    (item) => item?.priceGuid === el.priceGuid
                );

                console.log(originalEl);

                Object.keys(el).forEach((key) => {
                    const originalVal = originalEl[key];
                    const updatedVal = el[key];

                    console.log(originalVal, updatedVal);

                    if (key !== 'action') {
                        if (originalVal !== updatedVal) {
                            // Log the change for the specific key in estimatedPriceList
                            const keyName = '價格條件';
                            content = t('log.edit', {
                                key: `[${keyName}[${index}]]${t(
                                    `lgsServiceMaster:estimatedPrice.${key}`
                                )}`,
                                originalVal: originalVal,
                                updatedVal: updatedVal,
                            });

                            updateContent += `${content} `;
                        }
                    }
                });
            }
        });
    }
    console.log(updateContent);
    return updateContent;
}

export function generateUpdateLogContent_appendix(
    pageName,
    t,
    newContent,
    originalContent
) {
    console.log(newContent, originalContent);
    let updateContent = '';
    if (newContent) {
        newContent.forEach((el, index) => {
            let content = '';

            if (el.action === 'insert') {
                content = t('log.create', {
                    value: `附件${el.appendixName}}`,
                });
                updateContent += `${content} `;
            }

            if (el.action === 'delete') {
                content = t('log.delete', {
                    value: `附件${el.appendixName}`,
                });
                updateContent += `${content} `;
            }

            if (el.action === 'update') {
                const originalEl = originalContent.find(
                    (item) => item?.attachmentGuid === el.attachmentGuid
                );

                Object.keys(el).forEach((key) => {
                    const originalVal = originalEl[key];
                    const updatedVal = el[key];

                    console.log(originalVal, updatedVal);

                    if (key !== 'action') {
                        if (originalVal !== updatedVal) {
                            // Log the change for the specific key in estimatedPriceList
                            const keyName = '附件';
                            content = t('log.edit', {
                                key: `[${keyName}[${index}]]`,
                                originalVal: originalVal,
                                updatedVal: updatedVal,
                            });

                            updateContent += `${content} `;
                        }
                    }
                });
            }
        });
    }

    return updateContent;
}

export function deepClone(obj) {
    // 確認是不是原始型態，如果是的話就直接回傳
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    // 確認是不是 Date 或 RegExp 這種特殊型態，是的話就透過建構式複製一個相同的值，然後回傳
    if (obj instanceof Date || obj instanceof RegExp)
        return obj.constructor(obj);

    // 看 obj 是物件還是陣列，然後先建一個新的空物件 (或空陣列)
    let result = Array.isArray(obj) ? [] : {};

    // 透過 Ojbect.entries 來迭代，然後遞迴地對每個值深拷貝
    // 因為 Object.entries 不會列舉整個原型鍊 (prototype chain)
    // 所以不用透過 obj.hasOwnProperty(key) 額外檢查是不是非原型鏈上的屬性
    for (const [key, value] of Object.entries(obj)) {
        result[key] = deepClone(value);
    }

    // 最後回傳結果
    return result;
}
