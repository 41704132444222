import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../assets/images/icn_Trash.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icn_Search.svg';
import { BtnSmall } from './BtnSmall';

export const SearchBtnSmall = ({ className, to, handleClick }) => {
    // console.log(className, to);
    return (
        <BtnSmall
            className="layout-title-searchBtn layout-title-item btn btn-outline-primary d-flex justify-content-center align-items-center"
            to={to}
            key={'SearchBtn'}
            onClick={handleClick}
        >
            <SearchIcon />
        </BtnSmall>
    );
};
