import { useRef, useState, useEffect, useCallback } from 'react';
import { purchaseOrderAdvancedSearchData as searchContentList } from '../../../utils/advancedSearchData';
import styled from 'styled-components';
import useAxios from '../../../hooks/useAxios';
import { DialogConfirm } from './DialogConfirm';
import {
	FunctionActionButtonBar,
	IndexTablePartial,
	PageTitleBar,
} from '../../../components/exports';
import usePermissionButton from '../../../hooks/usePermissionButton';
import { useNavigate } from 'react-router-dom';
import { CreateBtnNormal } from '../../../components/Buttons/CreateBtnNormal';
import { DeleteBtnSmall } from '../../../components/Buttons/DeleteBtnSmall';
import { SearchBtnSmall } from '../../../components/Buttons/SearchBtnSmall';
import { CopyCreateBtn } from '../../../components/Buttons/CopyCreateBtn';
import { EditBtnNormal } from '../../../components/Buttons/EditBtnNormal';
import { DropdownMenuArea } from '../../../components/DropdownMenuArea/DropdownMenuArea';
import { ReactComponent as LogIcon } from '../../../assets/images/icn_Log.svg';
import { ReactComponent as Icn_OpenModal } from '../../../assets/images/icn_OpenModal.svg';
import { ReactComponent as CreateIcon } from '../../../assets/images/icn_Create.svg';
import { BtnNormal2 } from '../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import Table from '../../../components/Table/Table_Accoutant';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../../components/Modals/AdvancedSearchResultTag';
import axios from 'axios';
import useAuth from '../../../hooks/useAuth';
import Dialog from './Dialog';
import { useTranslation } from 'react-i18next';

// #region styled-components
const Text = styled.div`
	font-size: 16px;
	font-weight: bold;
	font-color: #0c2556;
`;

const DialogBtn = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid var(--grey2);
	border-radius: 0 4px 4px 0;
	border-left: 0;
	padding-left: 16px;
	padding-right: 12px;
	cursor: pointer;
	height: 32px;
`; // dialog按鈕

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
	margin-right: 0px;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
	display: flex;
`;

//#endregion

function GlaAccountant_Index() {
	const { t } = useTranslation(['common', 'glaAccoutant']);

	// 使用 useAuth Hook 取得 isLogin 和 checkAuth
	const { isLogin, checkAuth } = useAuth();

	// 在組件生命週期方法中呼叫 checkAuth 函式檢查登入狀態
	useEffect(() => {
		checkAuth();
	}, []);

	const [currencyList, setCurrencyList] = useState([]);
	const [dialogDataFromBE, setDialogDataFromBE] = useState({});
	const [fetchedData, setFetchedData] = useState([]);
	const [displayColumns, setDisplayColumns] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const [clickCount, setClickCount] = useState(0);
	const view = 'index';
	// const sortBy = 'orderDate';
	const [sortBy, setSortBy] = useState('AccountantGuid desc');
	const [orderBy, setOrderBy] = useState('desc');
	const guidName = 'accountantGuid';
	const pageTitle = t('glaAccoutant:accoutant');
	const { isLoading, error, sendRequest: axiosIndexData } = useAxios();

	const [advancedSearchShow, setAdvancedSearchShow] = useState(false);
	const [currencyTableShow, setCurrencyTableShow] = useState(false);
	const handleCurrencyClose = () => setCurrencyTableShow(false);
	const handleCurrencyShow = () => {
		setClickCount(clickCount + 1); // 增加點擊次數
		setCurrencyTableShow(true);
	};

	// console.log('modalData', modalData);

	if (error) {
		console.log('error !', error);
	}

	const headTitleList = [
		'#',
		t('glaAccoutant:employeeCode'),
		t('glaAccoutant:employeeName'),
		t('glaAccoutant:sectorName'),
	];

	const bodyTitleList = [
		'displayOrder',
		'employeeCode',
		'employeeName',
		'sectorName',
	];

	const tableStyles = [
		{
			minWidth: '50px',
			maxWidth: '80px',
			textAlign: 'center',
		},
		{
			minWidth: '200px',
			textAlign: 'left',
		},
		{
			minWidth: '200px',
			textAlign: 'left',
		},
		{
			minWidth: '200px',
			textAlign: 'left',
		},
	];

	const columnMustShow = ['displayOrder', 'orderDate', 'orderNo'];

	// 進階搜尋 一進去的第一筆的模板
	const advancedSearchDataTemp = {
		searchLogic: 'AND',
		column: searchContentList[0].column,
		searchOperator: '==',
		searchValue: '',
		searchContent: '',
		inputWarningContent: '',
		selectOptionList: searchContentList[0].selectOptionList,
	};

	// 進階搜尋實際資料
	const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
	// 進階搜尋畫面資料
	const [displayList, setDisplayList] = useState([]);

	// Advanced Search

	//todo: default sort col => orderDate

	/// 1. 第一次進入 index
	/// 2. 改變進階查詢
	/// 3. 改變筆數
	/// 4. 改變排序
	/// 都需要重新向後端拿資料, 所以合成一個 function 處理

	// advancedSearchDataList: 進階查詢資料
	// sort: 排序

	// 取得新增用員工資料
	const getCreateData = () => {
		axios.post(`api/GeneralAccount/GlaAccountant/search`).then((res) => {
			setCurrencyList(res.data.result);
		});
	};

	//console.log(currencyList);

	// useEffect(() => {
	// 	getCreateData();
	// }, []);

	// 使用 useEffect 監聽 clickCount 的變化
	useEffect(() => {
		if (clickCount > 0) {
			getCreateData();
		}
	}, [clickCount]);

	const fetchData = (
		advancedSearchDataList = null,
		pageSize = 20, // 每頁筆數
		sort = sortBy,
		pageNumber = 1
	) => {
		const url = `api/GeneralAccount/GlaAccountant/GetList`;
		// const url = `api/Logistics/LgsPurchaseOrder/GetList`;
		console.log(advancedSearchDataList);
		// 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
		if (advancedSearchDataList) {
			// only "one" search data
			advancedSearchDataList[0].searchLogic = '';
		}

		axiosIndexData(
			{
				url,
				method: 'post',
				data: {
					sort,
					pageSize,
					page: pageNumber,
					advancedSearchDataList,
					TransactionCode: 'glaAccountant', //permission 由前端傳直給後端，判斷權限
				},
			},
			(res) => {
				console.log(res);

				const { indexData, permission, numberOfData, totalPage } = res;

				// 分頁資訊
				setPaginationData({
					...paginationData,
					numberOfData: numberOfData,
					totalPage: totalPage,
					pageNumber: pageNumber,
				});

				// table 資料
				setFetchedData({
					indexData,
					permission,
					paginationData,
				});

				function isArrayFn(obj) {
					if (typeof Array.isArray === 'function') {
						return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
					} else {
						// 否則就使用 toString 方法
						return (
							Object.prototype.toString.call(obj) ===
							'[object Array]'
						);
					}
				}

				/// 這和 api 回傳的 result 無關
				// 如果有 advancedSearchDataList, 且是 array
				// console.log('original', advancedSearchDataList);
				if (isArrayFn(advancedSearchDataList)) {
					// 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
					// 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
					advancedSearchDataList.map((el) => {
						searchContentList.map((item) => {
							// console.log(item);
							if (el.searchFilter === item.tableColumn) {
								el.searchFilter = item.searchFromDataBase;
							}
						});
					});

					// console.log('after edit', advancedSearchDataList);

					setDisplayList(advancedSearchDataList);
					setAdvancedSearchDataList(advancedSearchDataList);
				}

				handleAdvancedSearchModalClose();
				localStorage.setItem('Permission_LgsPurchaseOrder', permission);
			}
		);
	};

	fetchData;

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			console.log(data);

			return data;
		};

		return gettingData();
	}, []);

	const handleOpenAdvancedSearch = () => {
		console.log('handleOpenAdvancedSearch');
	};

	const handleAdvancedSearchModalClose = () => setAdvancedSearchShow(false);
	const handleAdvancedSearchModalShow = () => {
		setAdvancedSearchShow(true);
	};

	const deleteAllFilters = () => {
		setDisplayList([]);
		setAdvancedSearchDataList([]);
		fetchData();
	};

	const deleteFilter = (targetIndex) => {
		// console.log('deleteFilter index', targetIndex);
		// console.log(advancedSearchDataList[targetIndex]);
		// console.log(displayList[targetIndex]);

		const newAdvancedSearchDataList = advancedSearchDataList.filter(
			(_, index) => index !== targetIndex
		);
		const newDisplayList = displayList.filter(
			(_, index) => index !== targetIndex
		);
		setDisplayList(newDisplayList);
		setAdvancedSearchDataList(newAdvancedSearchDataList);
		fetchData(
			newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
		);
	};

	useEffect(() => {
		// fetchIndexInfo();
		getLocalStorageData();
		fetchData();

		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);

	// console.log(advancedSearchDataList);

	return (
		<>
			<div id="indexView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={false}
				/>
				<Container>
					{/* <Left>
						<CreateBtnNormal
							permission={fetchedData?.permission}
							word={t('button.createButton')}
						/>
					</Left>
					<Center></Center>
					<Right>
						<SearchBtnSmall
							permission={fetchedData?.permission}
							handleClick={handleAdvancedSearchModalShow}
						/>
						<DropdownMenuArea permission={fetchedData?.permission}>
							<LogIcon />
							<span>{t('button.logButton')}</span>
						</DropdownMenuArea>
					</Right> */}
				</Container>
				<div className="position-relative firstRowMinHeight bg-white d-flex flex-column p-4 position-relative overflow-auto">
					<Container>
						<Left>
							<Text>會計人員</Text>
						</Left>

						<Center></Center>

						<Right>
							<BtnNormal2
								className="btn-primary d-inline-block me-2 smallBtn"
								keyName={'create'}
								word={'新增'}
								handleClick={() => {
									handleCurrencyShow();
								}}
							>
								<CreateIcon className="me-2" />
							</BtnNormal2>
						</Right>
					</Container>

					{/* <AdvancedSearchResultTag
						advancedSearchDataList={advancedSearchDataList}
						deleteAllFilters={deleteAllFilters}
						deleteFilter={deleteFilter}
						ns="lgsPurchaseOrder"
					/> */}
					{fetchedData?.indexData && (
						<Table
							guidName={guidName}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableData={fetchedData?.indexData}
							indexData={fetchedData?.indexData}
							sortBy={sortBy}
							setSortBy={setSortBy}
							displayColumns={displayColumns}
							setDisplayColumns={setDisplayColumns}
							paginationData={paginationData}
							setPaginationData={setPaginationData}
							fetchData={fetchData}
							fetchedData={fetchedData}
							tableStyles={tableStyles}
							columnMustShow={columnMustShow}
							rowClick={false}
							advancedSearchDataList={advancedSearchDataList}
							displaySetting={true}
							orderBy={orderBy}
							setOrderBy={setOrderBy}
						/>
					)}
				</div>
			</div>

			{/* {advancedSearchShow && (
				<AdvancedSearchModal
					isOpen={advancedSearchShow}
					handleClose={handleAdvancedSearchModalClose}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					advancedSearchDataList={advancedSearchDataList}
					setAdvancedSearchDataList={setAdvancedSearchDataList}
					advancedSearchDataTemp={advancedSearchDataTemp}
					fetchData={fetchData}
					sortBy={sortBy}
					setSortBy={setSortBy}
					setFetchedData={setFetchedData}
					displayList={
						displayList.length > 0
							? displayList
							: [{ ...advancedSearchDataTemp }]
					}
					setDisplayList={setDisplayList}
					searchContentList={searchContentList}
					ns="lgsPurchaseOrder"
				/>
			)} */}

			<Dialog
				modalOpen={currencyTableShow}
				handleClose={handleCurrencyClose}
				currencyList={currencyList}
				headTitleList={headTitleList}
				bodyTitleList={bodyTitleList}
				fetchData={fetchData}
			/>
		</>
	);
}

export default GlaAccountant_Index;
