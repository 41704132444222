import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAxios from '../../../../../hooks/useAxios';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { NextBtnSmall } from '../../../../../components/Buttons/NextBtnSmall';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import InvoiceTable from './InvoiceTable';
import { useTranslation } from 'react-i18next';
import { DialogWarning } from '../DialogWarning';

// #region styled-components

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page1(props) {
	//#region state initiation

	const { t } = useTranslation(['common', 'lgsPurchaseOrderInvoice']); // i18n

	const {
		setDialogConfirmData,
		parentData,
		setParentData,
		page,
		processSteps,
		handleNextPage,
	} = props;
	// console.log(props);

	// state
	const [isMultiSelected, setIsMultiSelected] = useState(false);
	const [backEndData, setBackEndData] = useState({});
	const [pageData, setPageData] = useState({});
	const { sendRequest: axiosDataPage1 } = useAxios();
	const decimalPlaceTl =
		parentData.page0.purchaseOrderSelectedList[0].decimalPlaceTL;
	const [tableBodyData, setTableBodyData] = useState([]);
	const [warningMessage, setWarningMessage] = useState('');

	//#endregion
	console.log(decimalPlaceTl);

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsPurchaseOrderInvoice:invoice'),
		t('lgsPurchaseOrderInvoice:invoiceFormat'),
		t('lgsPurchaseOrderInvoice:return'),
		t('lgsPurchaseOrderInvoice:certificateDate'),
		t('lgsPurchaseOrderInvoice:certificateNumber'),
		t('lgsPurchaseOrderInvoice:taxRate') + '%',
		t('lgsPurchaseOrderInvoice:totalTaxableAmount'),
		t('lgsPurchaseOrderInvoice:netAmount2'),
		t('lgsPurchaseOrderInvoice:taxAmount2'),
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'governmentInvoice',
		'vatInvoiceFormatViewName',
		'invoiceReturn',
		'invoiceDate',
		'invoiceNo',
		'vatRate',
		'totalAmount',
		'netAmount',
		'taxAmount',
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'center',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '72px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '48px',
			textAlign: 'center',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '240px',
			textAlign: 'left',
		},
		{
			width: '80px',
			textAlign: 'center',
		},
		{
			width: '112px',
			textAlign: 'left',
		},
		{
			width: '200px',
			textAlign: 'center',
		},
		{
			width: '72px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
		{
			width: '120px',
			textAlign: 'right',
		},
	];

	//#endregion

	//#region function

	// 回到page0 清空暫存資料
	const handleBackPage0 = () => {
		const newData = {
			btnName: 'goToPage0',
			isOpen: true,
			goTo: 'preStep',
			title: t('lgsPurchaseOrderInvoice:confirmRechooseOrder') + '?',
		};
		setDialogConfirmData(newData);
	};

	// 暫存改被修改的資料
	const handleTemporaryStore = () => {
		const newParentData = { ...parentData };
		newParentData.page1 = pageData;
		// 標記是否曾拿取過資料
		newParentData.isEverEnterPage.page1 = true;

		setParentData(newParentData);
	};

	//#region DialogWarning

	// 返回btn確認dialog

	const [isWarningOpen, setIsWarningOpen] = useState(false);

	// Modal open
	const handleDialogWarning = () => {
		setIsWarningOpen(true);
	};

	//#endregion

	// 驗證發票應稅總額是否小於採購訂單應稅總額

	// 往下一頁之前的檢查
	const handleSubmit = () => {
		let falseCount = 0;

		// 驗證是否至少一個發票
		if (pageData.invoiceItemList.length === 0) {
			falseCount++;

			setWarningMessage(
				t('lgsPurchaseOrderInvoice:atLeastOneInvoiceOrVoucherRequired')
			);
			handleDialogWarning();
		}

		// 驗證發票應稅總額是否小於採購訂單應稅總額
		const maxInvoiceAmount =
			parentData.page0.purchaseOrderSelectedList.reduce(
				(accumulator, currentValue) => {
					return (
						accumulator + Number(currentValue.displayGrossAmount)
					);
				},
				0
			);

		const invoiceAmount = pageData.invoiceItemList.reduce(
			(accumulator, currentValue) => {
				return accumulator + Number(currentValue.totalAmount);
			},
			0
		);

		console.log(maxInvoiceAmount, invoiceAmount);
		if (invoiceAmount > maxInvoiceAmount) {
			falseCount++;

			setWarningMessage(
				t(
					'lgsPurchaseOrderInvoice:invoiceTaxableTotalMustBeLessThanPurchaseTaxableTotal'
				) +
					':' +
					maxInvoiceAmount
			);
			handleDialogWarning();
		}

		// 驗證通過
		console.log(falseCount);
		if (falseCount === 0) {
			handleTemporaryStore();
			handleNextPage();
		}
	};

	//#endregion

	//#region useEffect

	useEffect(() => {
		const isEverEnterPage = parentData.isEverEnterPage.page1;

		const { purchaseOrderSelectedList } = parentData.page0;
		purchaseOrderSelectedList.length > 1
			? setIsMultiSelected(true)
			: setIsMultiSelected(false);

		if (!isEverEnterPage) {
			// 1.axios get BE data
			const url = `api/Logistics/LgsPurchaseOrderInvoice/getPage2`;
			const method = 'POST';

			axiosDataPage1({ url, method }, async (res) => {
				console.log(res);

				await setBackEndData(res);

				const newPageData = {
					...pageData,
					backEndData: { ...res },
					invoiceItemList: [],
					allAmount: { totalAmount: 0, taxAmount: 0, netAmount: 0 },
				};

				await setPageData(newPageData);
			});
		} else {
			setBackEndData(parentData.page1.backEndData);
			setTableBodyData(parentData.page1.invoiceItemList);
			setPageData(parentData.page1);
		}
	}, []);

	console.log('parentData', parentData);
	//#endregion

	return (
		<>
			{/* DialogWarning element */}
			<DialogWarning
				isWarningOpen={isWarningOpen}
				setIsWarningOpen={setIsWarningOpen}
				warningMessage={warningMessage}
			/>

			<Container className="">
				<Left className="">
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handleBackPage0();
							}}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right className="">
					{page < processSteps.length - 1 ? (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={(e) => {
								e.preventDefault();
								handleSubmit(page);
							}}
						>
							{t('common:button.nextStep')}
						</BtnNormal>
					) : (
						<NextBtnSmall className="d-none" />
					)}
				</Right>
			</Container>

			<div className="position-relative">
				<div className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
					<InvoiceTable
						backEndData={backEndData}
						parentData={parentData}
						pageData={pageData}
						setPageData={setPageData}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						tableBodyData={tableBodyData}
						setTableBodyData={setTableBodyData}
						decimalPlaceTl={decimalPlaceTl}
					/>
				</div>
			</div>
		</>
	);
}

export default Page1;
