import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BtnNormal } from '../../../components/Buttons/BtnNormal';

// #region styled-components
const IndexTableContainer = styled.div``;

const IndexTable = styled.table`
    margin-bottom: 0;
    color: var(--grey4);
    background-color: #fff;
`;

const THead = styled.thead`
    height: 40px;
`;

const TR = styled.tr`
    height: 40px;
    /* &:hover {
        cursor: ${(hoverEffect) =>
        hoverEffect === 'true' ? 'pointer' : 'default'};
        background-color: ${(hoverEffect) =>
        hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
    } */
    /* td:last-child {
        display: none !important;
    }

    &:hover {
        td:last-child {
            display: block !important;
        }
    } */
`;

const TH = styled.th`
    vertical-align: middle;
    border: 1px solid var(--grey1) !important;
    /* &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-right: none !important;
    } */
`;

const TBody = styled.tbody``;

const TD = styled.td`
    cursor: auto;
    border: 1px solid var(--grey1) !important;

    /* text-align: ${(prop) => prop.align}; */

    /* &:first-of-type {
        border-left: none !important;
    }

    &:last-of-type {
        border-left: none !important;
        border-right: none !important;
    }

    background-color: ${(prop) =>
        prop.active ? '#fbfcfd !important' : '#fff'};

    position: relative;
    padding: 0; */
`;

const TFoot = styled.tfoot`
    border: none;
`;

const FootTR = styled.tr`
    border-top: 2px solid var(--grey3) !important;
`;

const FootTD = styled.td`
    border: none !important;
`;

const Container = styled.div`
    display: flex;
    justify-content: start;
    margin: 16px 0;
`;

const Cell = styled.div`
    border: ${(prop) => (prop.active ? '1px solid #e3e8ee' : 'none')};
    border-radius: 4px;
`;

const Menu = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    will-change: transform;
    padding: 0;
    margin-top: 8px;
    min-width: 64px;
    background-color: #ffffff;
    box-shadow: 2px 4px 12px -1px rgb(61 61 61 / 51%);
    border-radius: 0.25rem;
    color: var(--danger);

    &:hover,
    &:focus {
        color: var(--danger);
    }
`;

const Button = styled.div`
    //active-submit dropdownItem d-flex align-items-center pe-2 tex-left px-3 py-2
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 8px 16px;
    text-align: left;
    min-width: 64px;
    height: 32px;
    justify-content: center;
    svg {
        margin-right: 8px;
    }
`;

const Input = styled.input`
    border: 1px solid #e3e8ee;
    border-radius: 4px;
    min-width: 24px;
    width: 100%;
    outline: 'none;
`;

//#endregion
export default function ServiceEstimatedPrice({
    estimatedPriceList,
    setEstimatedPriceList,
    view,
}) {
    const { t } = useTranslation(['common', 'lgsServiceMaster']);
    const [editingRowIndex, setEditingRowIndex] = useState(-1);

    //#region variable initiation

    // table header 的名稱
    const headTitleList = [
        '#',
        // t('lgsPurchaseOrder:paymentDateBasis'),
        // t('lgsPurchaseOrder:paymentDateDays'),
        // t('lgsPurchaseOrder:paymentDate'),
        // t('lgsPurchaseOrder:paymentAmount'),
        // t('lgsPurchaseOrder:paymentPercentage'),
        // t('lgsPurchaseOrder:annotation'),
        '幣別',
        '起始日期',
        '截止日期',
        '數量下限',
        '數量上限',
        '未稅單價',
        '備註',
    ];

    // table body 的資料名稱
    const bodyTitleList = [
        'displayOrder',
        'currencyCode',
        'startDate',
        'endDate',
        'quantityFrom',
        'quantityTo',
        'unitPrice',
        'note',
    ];

    // 不同 input 有沒有小數點的要求
    const toFloatList = ['', '', '', '', 'decimalPlace', 'decimalPlace', ''];

    // 不同 input 的 type
    const inputProperties = [
        { type: 'number' },
        { type: 'text' },
        {
            type: 'number',
            min: 1,
        },
        { type: 'date' },
        {
            type: 'number',
            // onBlur: () =>
            //     handleCalculateAmount('changingAmount', tableBodyData),
        },
        {
            type: 'number',
            // onBlur: () =>
            //     handleCalculateAmount('changingPercentage', tableBodyData),
        },
        { type: 'text' },
        { type: 'text' },
    ];

    // table header 的 style
    const tableHeadStyles = [
        {
            width: '48px',
            textAlign: 'center',
        },
        {
            width: '128px',
            textAlign: 'center',
        },
        {
            width: '128px',
            textAlign: 'center',
        },
        {
            width: '128px',
            textAlign: 'center',
        },
        {
            textAlign: 'center',
        },
        {
            textAlign: 'center',
        },
        {
            textAlign: 'center',
        },
        {
            width: '248px',
            textAlign: 'center',
        },
    ];

    // table body 的 style
    const tableBodyStyles = [
        {
            width: '48px',
            textAlign: 'center',
        },
        {
            width: '128px',
            textAlign: 'left',
        },
        {
            width: '128px',
            textAlign: 'left',
        },
        {
            width: '128px',
            textAlign: 'left',
        },
        {
            textAlign: 'right',
        },
        {
            textAlign: 'right',
        },
        {
            textAlign: 'right',
        },
        {
            width: '248px',
            textAlign: 'left',
        },
    ];

    const handleAddServiceEstimatedPriceList = () => {
        const newNumber = estimatedPriceList.length + 1;

        const newItem = {
            displayOrder: newNumber,
            currencyCode: '',
            currencyName: '',
            startDate: dayjs(),
            endDate: dayjs(),
            note: '',
            priceGuid: '',
            quantityFrom: 0,
            quantityTo: 0,
            rowStamp: '',
            serviceGuid: '',
            unitPrice: 0,
        };

        setEstimatedPriceList([...estimatedPriceList, newItem]);
    };

    const handleEditTable = (e, rowIndex, el) => {
        console.log(e.target, rowIndex, el);

        const targetIndex = rowIndex;
        setEditingRowIndex(targetIndex);
    };

    //#endregion
    return (
        <>
            <div className="position-relative">
                <div className="bg-white d-flex flex-column p-4 position-relative">
                    <div className="px-2 py-3 d-flex align-item-center">
                        <div
                            style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#1278E8',
                                lineHeight: '2',
                            }}
                        >
                            價格條件
                        </div>
                        {view !== 'details' && (
                            <BtnNormal
                                className={'btn btn-primary text-white ms-4'}
                                key={'ActivateBtnNormal'}
                                // disabled={!disabled}
                                // onClick={
                                //     handleClick
                                //         ? handleClick
                                //         : () => navigate('Activate')
                                // }
                                onClick={handleAddServiceEstimatedPriceList}
                            >
                                新增
                            </BtnNormal>
                        )}
                    </div>
                    <IndexTable className="table">
                        <THead className="table-head">
                            <TR>
                                <TH
                                    style={{
                                        width: '48px',
                                        textAlign: 'center',
                                    }}
                                >
                                    #
                                </TH>
                                <TH
                                    style={{
                                        width: '128px',
                                        textAlign: 'center',
                                    }}
                                >
                                    幣別
                                </TH>
                                <TH
                                    style={{
                                        width: '128px',
                                        textAlign: 'center',
                                    }}
                                >
                                    起始日期
                                </TH>
                                <TH
                                    style={{
                                        width: '128px',
                                        textAlign: 'center',
                                    }}
                                >
                                    截止日期
                                </TH>
                                <TH
                                    style={{
                                        width: '160px',
                                        textAlign: 'center',
                                    }}
                                >
                                    數量下限
                                </TH>
                                <TH
                                    style={{
                                        width: '160px',
                                        textAlign: 'center',
                                    }}
                                >
                                    數量上限
                                </TH>
                                <TH
                                    style={{
                                        width: '160px',
                                        textAlign: 'center',
                                    }}
                                >
                                    未稅單價
                                </TH>
                                <TH
                                    style={{
                                        width: '232px',
                                        textAlign: 'center',
                                    }}
                                >
                                    備註
                                </TH>
                            </TR>
                        </THead>
                        <TBody className="">
                            {estimatedPriceList?.map((el, rowIndex) => {
                                // console.log(rowIndex);
                                return editingRowIndex !== rowIndex ? (
                                    <TR
                                        key={el?.priceGuid || el.displayOrder}
                                        onClick={(e) =>
                                            view !== 'details' &&
                                            handleEditTable(e, rowIndex, el)
                                        }
                                    >
                                        <TD
                                            className="text-center"
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                        >
                                            {el?.displayOrder}
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-left"
                                        >
                                            {`${el?.currencyCode} ${el?.currencyName}`}
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-left"
                                        >
                                            {dayjs(el?.startDate).format(
                                                'YYYY-MM-DD'
                                            )}
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-left"
                                        >
                                            {dayjs(el?.endDate).format(
                                                'YYYY-MM-DD'
                                            )}
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-end"
                                        >
                                            {el?.quantityFrom}
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-end"
                                        >
                                            {el?.quantityTo}
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-end"
                                        >
                                            {el?.unitPrice}
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-left"
                                        >
                                            {el?.note}
                                        </TD>
                                    </TR>
                                ) : (
                                    <TR
                                        key={el?.priceGuid || el.displayOrder}
                                        onClick={(e) =>
                                            view !== 'details' &&
                                            handleEditTable(e, rowIndex, el)
                                        }
                                    >
                                        <TD
                                            className="text-center"
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                        >
                                            {el?.displayOrder}
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-left"
                                        >
                                            {`${el?.currencyCode} ${el?.currencyName}`}
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-left"
                                        >
                                            <Input
                                                value={dayjs(
                                                    el?.startDate
                                                ).format('YYYY-MM-DD')}
                                                active={el?.active}
                                                type="date"
                                                min="0"
                                                onChange={(e) => {
                                                    // handleInputChange(
                                                    //     e.target.value,
                                                    //     rowIndex,
                                                    //     inputIndex,
                                                    //     toFloatList[inputIndex]
                                                    // );
                                                }}
                                            />
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-left"
                                        >
                                            <Input
                                                value={dayjs(
                                                    el?.endDate
                                                ).format('YYYY-MM-DD')}
                                                active={el?.active}
                                                type="date"
                                                min="0"
                                                onChange={(e) => {
                                                    // handleInputChange(
                                                    //     e.target.value,
                                                    //     rowIndex,
                                                    //     inputIndex,
                                                    //     toFloatList[inputIndex]
                                                    // );
                                                }}
                                            />
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-end"
                                        >
                                            <Input
                                                value={el?.quantityFrom}
                                                active={el?.active}
                                                type="number"
                                                min="0"
                                                onChange={(e) => {
                                                    // handleInputChange(
                                                    //     e.target.value,
                                                    //     rowIndex,
                                                    //     inputIndex,
                                                    //     toFloatList[inputIndex]
                                                    // );
                                                }}
                                            />
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-end"
                                        >
                                            <Input
                                                value={el?.quantityTo}
                                                active={el?.active}
                                                type="number"
                                                min="0"
                                                onChange={(e) => {
                                                    // handleInputChange(
                                                    //     e.target.value,
                                                    //     rowIndex,
                                                    //     inputIndex,
                                                    //     toFloatList[inputIndex]
                                                    // );
                                                }}
                                            />
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-end"
                                        >
                                            <Input
                                                value={el?.unitPrice}
                                                active={el?.active}
                                                type="number"
                                                min="0"
                                                onChange={(e) => {
                                                    // handleInputChange(
                                                    //     e.target.value,
                                                    //     rowIndex,
                                                    //     inputIndex,
                                                    //     toFloatList[inputIndex]
                                                    // );
                                                }}
                                            />
                                        </TD>
                                        <TD
                                            style={
                                                view === 'details'
                                                    ? {
                                                          backgroundColor:
                                                              rowIndex % 2 === 0
                                                                  ? 'white'
                                                                  : '#F7F8FB',
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              'white',
                                                      }
                                            }
                                            className="text-left"
                                        >
                                            <Input
                                                value={el?.note}
                                                active={el?.active}
                                                type="number"
                                                min="0"
                                                onChange={(e) => {
                                                    // handleInputChange(
                                                    //     e.target.value,
                                                    //     rowIndex,
                                                    //     inputIndex,
                                                    //     toFloatList[inputIndex]
                                                    // );
                                                }}
                                            />
                                        </TD>
                                    </TR>
                                );
                            })}
                        </TBody>
                    </IndexTable>
                </div>
            </div>
        </>
    );
}
