import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icn_Search2 } from '../../../../assets/images/icn_Search2.svg';
import { ReactComponent as Icn_Cancel } from '../../../../assets/images/icn_Cancel.svg';
import { ReactComponent as Icn_InputYellowCancel } from '../../../../assets/images/icn_InputYellowCancel.svg';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// #region styled-components
const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	width: 100%;
	height: 100%;
`;

const ModalDialog = styled.div`
	max-width: 600px !important;
	min-height: calc(100% - 3.5rem);
`;

const SortableTableUl = styled.ul`
	height: 440px;
	overflow-y: scroll;
	overflow-x: hidden;
	border-radius: 0 !important;
`;

const SortableTableLi = styled.li`
	height: 40px;
	cursor: pointer;
	border: none;
	border-radius: 0 !important;
	background-color: ${({ selected }) => (selected ? 'var(--grey1)' : '#fff')};

	&:hover {
		background-color: ${({ selected }) =>
			selected ? `fff` : 'var(--grey1)'};
	}
`;

// #endregion

export const DialogTwoCol = (props) => {
	//#region state initialization
	const { t } = useTranslation(['common', 'lgsSalesKit']);

	// prop
	const {
		isCopyDialogOpen,
		setIsCopyDialogOpen,
		specList,
		specSelectedList,
		copyFunctionName,
		salesKitGuid,
		getDetails,
	} = props;

	const [data, setData] = useState({
		sizeGuid: '',
		colorGuid: '',
	});

	// state
	const [isCancelSearchModalBtn, setIsCancelSearchModalBtn] = useState(false); // cancel search btn

	// ref
	const searchModalInput = useRef('');

	//#endregion

	const handleItemClick = (itemName, guid) => {
		if (itemName === 'size') {
			setData({
				...data,
				sizeGuid: guid,
			});
		} else if (itemName === 'color') {
			setData({
				...data,
				colorGuid: guid,
			});
		}
	};

	console.log(data);

	//#region Modal搜尋

	// 搜尋
	const searchModal = () => {
		//取得搜尋文字
		let searchModalInputText = '';
		if (searchModalInput.current) {
			searchModalInputText = searchModalInput.current.value.toUpperCase();
		}

		let modalItemList = document.querySelectorAll('.modalItem');

		if (modalItemList.length !== 0 && searchModalInputText !== '') {
			//隱藏全部欄位
			modalItemList.forEach((item) => {
				item.classList.add('d-none');
			});

			// 搜尋每個rightLi中的每個text
			modalItemList.forEach((item) => {
				// console.log(item.textContent);

				if (
					item.textContent
						.toUpperCase()
						.includes(searchModalInputText)
				) {
					item.classList.remove('d-none');
				}
			});

			//顯示取消搜尋btn
			setIsCancelSearchModalBtn(true);
		}
	};

	// 取消搜尋
	const cancelSearchModal = (i) => {
		// 顯示全部欄位
		document.querySelectorAll('.modalItem').forEach((item) => {
			item.classList.remove('d-none');
		});

		// 清空搜尋欄位
		searchModalInput.current.value = '';

		// 隱藏取消搜尋btn
		setIsCancelSearchModalBtn(false);
	};

	//#endregion

	//#region Modal儲存
	console.log(salesKitGuid);

	function modalSave() {
		const toBackEndData = {
			salesKitGuid: salesKitGuid,
			TargetSizeGuid: specSelectedList[0],
			TargetColorGuid: specSelectedList[1],
			OriginalSizeGuid: data.sizeGuid,
			OriginalColorGuid: data.colorGuid,
		};

		axios
			.post(
				`api/Logistics/LgsSalesKit/${copyFunctionName}`,
				toBackEndData
			)
			.then((res) => {
				console.log(res.data);

				// getDetails(
				// 	salesKitSizeDisplayOrder,
				// 	salesKitColorDisplayOrder,
				// 	sizeGuid,
				// 	colorGuid
				// ); // 獲取最新的資料

				const getDisplayOrder = (list, key, guid) => {
					const matchedItem = list.find((el) => el[key] === guid);
					return matchedItem ? matchedItem.displayOrder : null;
				};
				const sizeDisplayOrder = getDisplayOrder(
					specList.lgsSalesKitSize,
					'sizeGuid',
					specSelectedList[0]
				);
				const colorDisplayOrder = getDisplayOrder(
					specList.lgsSalesKitColor,
					'colorGuid',
					specSelectedList[1]
				);

				getDetails(
					sizeDisplayOrder,
					colorDisplayOrder,
					specSelectedList[0],
					specSelectedList[1]
				);
			});

		handleClose();
	}

	//#endregion

	//#region Modal取消(關閉)

	const handleClose = () => {
		setData({});
		setIsCopyDialogOpen(false);
	};

	//#endregion

	//#region useEffect

	useEffect(() => {
		searchModal();
	}, [data]);

	//#endregion

	return (
		<>
			{isCopyDialogOpen && (
				<ModalWrapper onClick={handleClose}>
					<ModalDialog
						className="modal-dialog d-flex align-items-center"
						onClick={(e) => e.stopPropagation()}
					>
						<div
							className="modal-content border-0 bg-white"
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
								borderRadius: '12px',
							}}
						>
							<div className="modal-header px-4 pt-4 pb-3">
								<h5 className="text-primary fw-bold fontSize125Rem mb-0">
									{`將「${
										copyFunctionName === 'copyItem'
											? t('lgsSalesKit:銷售明細') //'銷售明細'
											: copyFunctionName ===
											  'copyAppendix'
											? t('lgsSalesKit:photo') //'照片'
											: copyFunctionName === 'copyPrice'
											? t('lgsSalesKit:price') //'價格'
											: ''
									}」複製到目前的資料`}
								</h5>
								<button
									style={{ top: '12px', right: '12px' }}
									type="button"
									className="close position-absolute btn"
									onClick={handleClose}
								>
									<Icn_Cancel />
								</button>
							</div>

							<div className="modal-body text-center px-7 py-0">
								{/* 搜尋 */}
								<div className="row ms-1">
									<div className="position-relative col-12 col-md-6 p-0 pr-3 mt-6 mb-3">
										<input
											type="text"
											id="uomSearchInput"
											name="searchModalInput"
											className="form-control searchModalInput fontSize875Rem"
											ref={searchModalInput}
											placeholder={t(
												'lgsSalesKit:dialogCol.quickSearch'
											)}
											style={{
												height: '32px',
												textTransform: 'uppercase',
											}}
										/>
										<button
											type="button"
											className="searchModalBtn position-absolute border-0 btn btn-primaryllt px-3 py-0"
											onClick={() => searchModal()}
											style={{
												top: '1px',
												right: '1px',
												height: '30px',
												borderRadius:
													'0 0.25rem 0.25rem 0',
											}}
										>
											<Icn_Search2 />
										</button>
										{isCancelSearchModalBtn && (
											<a
												id="cancelSearchUomModal"
												className={`cancelSearchModalBtn position-absolute ${
													searchModalInput.current
														?.value
														? ''
														: 'd-none'
												}`}
												onClick={() =>
													cancelSearchModal()
												}
												style={{
													top: '3px',
													right: '-24px',
													cursor: 'pointer',
												}}
											>
												<Icn_InputYellowCancel />
											</a>
										)}
									</div>
								</div>

								<div
									className="form-group mb-0 row"
									style={{ height: '500px' }}
								>
									{/* left col */}
									<div className="col-6 pl-0 bg-white leftContainer">
										<div
											className="ModalSubTitleBar fw-bold text-secondary bg-primaryllt  border border-primarylt"
											style={{
												height: '40px',
												lineHeight: '40px',
												borderRadius: '12px 12px 0 0',
											}}
										>
											{t(
												'lgsSalesKit:salesSpecification'
											)}
											{/* //銷售規格 */}
										</div>
										<div id="sortable">
											<SortableTableUl
												id="left"
												className="list-group text-left border border-primarylt border-top-0 connectedSortable"
											>
												{specList.lgsSalesKitSize.map(
													(el) => (
														<SortableTableLi
															key={el.sizeGuid}
															selected={
																data.sizeGuid ===
																el.sizeGuid
															}
															onClick={() =>
																handleItemClick(
																	'size',
																	el.sizeGuid
																)
															}
															className="list-group-item modalItem"
														>
															{el.name01}
														</SortableTableLi>
													)
												)}
											</SortableTableUl>
										</div>
									</div>

									{/* right col */}
									<div className="col-6 pr-0 bg-white rightContainer">
										<div
											className="ModalSubTitleBar fw-bold text-secondary bg-primaryllt  border border-primarylt"
											style={{
												height: '40px',
												lineHeight: '40px',
												borderRadius: '12px 12px 0 0',
											}}
										>
											{t('lgsSalesKit:color')}
											{/* 顏色 */}
										</div>
										<div id="sortable">
											<SortableTableUl
												id="right"
												className="list-group text-left border border-primarylt border-top-0 connectedSortable"
											>
												{specList.lgsSalesKitColor.map(
													(el) => (
														<SortableTableLi
															key={el.colorGuid}
															selected={
																data.colorGuid ===
																el.colorGuid
															}
															onClick={() =>
																handleItemClick(
																	'color',
																	el.colorGuid
																)
															}
															className="list-group-item modalItem"
														>
															{el.name01}
														</SortableTableLi>
													)
												)}
											</SortableTableUl>
										</div>
									</div>
								</div>
							</div>

							<hr
								className="mx-4 mt-3 mb-0"
								style={{
									border: `1px solid var(--grey2)`,
								}}
							/>

							{/* 確定btn */}
							<div className="modal-footer justify-content-center border-0 pt-4 pb-5">
								<button
									type="button"
									className="btn btn-primary mediumBtn mx-1"
									style={{
										margin: '0 20px',
										width: '120px',
									}}
									onClick={
										data.sizeGuid && data.colorGuid
											? () => modalSave()
											: () =>
													alert(
														t(
															'lgsSalesKit:chooseSpecificationAndColor'
														)
													) //'請選擇規格和顏色'
									}
								>
									{t('button.confirmButton')}
								</button>
								<button
									type="button"
									className="btn btn-outline-primarylllt mediumBtn mx-1"
									onClick={handleClose}
								>
									{t('button.cancelButton')}
								</button>
							</div>
						</div>
					</ModalDialog>
				</ModalWrapper>
			)}
		</>
	);
};
