import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../assets/images/icn_Trash.svg';
import { ReactComponent as PrevIcon } from '../../assets/images/icn_Prev.svg';
import { BtnSmall } from './BtnSmall';

export const PrevBtnSmall = ({ className, handleClick }) => {
    // console.log(className, to);
    return (
        <BtnSmall
            className="layout-title-searchBtn layout-title-item btn btn-outline-primary d-flex justify-content-center align-items-center me-2"
            key={'PrevBtn'}
            onClick={() => handleClick()}
        >
            <PrevIcon />
        </BtnSmall>
    );
};
