import { useState, useEffect, useCallback, useContext } from 'react';
//進階搜尋 list 定義位置
import { appendixClass as searchContentList } from '../../../utils/advancedSearchData';
import styled from 'styled-components';
import useAxios from '../../../hooks/useAxios';
import { PageTitleBar } from '../../../components/exports';
import { useNavigate } from 'react-router-dom';
import { CreateBtnNormal } from '../../../components/Buttons/CreateBtnNormal';
import { SearchBtnSmall } from '../../../components/Buttons/SearchBtnSmall';
import { DropdownMenuArea } from '../../../components/DropdownMenuArea/DropdownMenuArea';
import { ReactComponent as LogIcon } from '../../../assets/images/icn_Log.svg';
import Table from '../../../components/Table/Table';
import AdvancedSearchModal from '../../../components/Modals/AdvancedSearchModal';
import AdvancedSearchResultTag from '../../../components/Modals/AdvancedSearchResultTag';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

// #region styled-components
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
	display: flex;
`;

//#endregion

function LgsAppendixClass_Index() {
	const { t } = useTranslation(['common', 'lgsAppendixClass']);

	// 使用 useAuth Hook 取得 isLogin 和 checkAuth
	const { isLogin, checkAuth } = useAuth();
	const navigate = useNavigate();

	// 在組件生命週期方法中呼叫 checkAuth 函式檢查登入狀態
	useEffect(() => {
		checkAuth();
	}, []);

	const [fetchedData, setFetchedData] = useState([]);
	const [displayColumns, setDisplayColumns] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const view = 'index';
	const [sortBy, setSortBy] = useState('appendixClassCode asc');
	const guidName = 'appendixClassGuid';
	const pageTitle = t('lgsAppendixClass:pageTitle');
	//const pageTitle = '採購訂單附件分類';
	const { isLoading, error, sendRequest: axiosIndexData } = useAxios();

	const [advancedSearchShow, setAdvancedSearchShow] = useState(false);

	if (error) {
		console.log('error !', error);
	}

	const headTitleList = [
		'#',
		t('lgsAppendixClass:appendixClassCode'),
		t('lgsAppendixClass:name01'),
		t('lgsAppendixClass:status'),
	];

	const bodyTitleList = [
		'displayOrder',
		'appendixClassCode',
		'name01',
		'status',
	];

	const tableStyles = [
		{
			minWidth: '72px',
			maxWidth: '72px',
			textAlign: 'center',
		},
		{
			minWidth: '160px',
			textAlign: 'left',
		},
		{
			minWidth: '280px',
			textAlign: 'left',
		},
		{
			minWidth: '200px',
			textAlign: 'left',
		},
		{
			minWidth: '368px',
			textAlign: 'left',
		},
	];

	const columnMustShow = ['displayOrder', 'name01', 'name02'];

	// 進階搜尋 一進去的第一筆的模板
	const advancedSearchDataTemp = {
		searchLogic: 'AND',
		column: searchContentList[0].column,
		searchOperator: '==',
		searchValue: '',
		searchContent: '',
		inputWarningContent: '',
		selectOptionList: searchContentList[0].selectOptionList,
	};

	// 進階搜尋實際資料
	const [advancedSearchDataList, setAdvancedSearchDataList] = useState([]);
	// 進階搜尋畫面資料
	const [displayList, setDisplayList] = useState([]);

	// Advanced Search

	//todo: default sort col => orderDate

	/// 1. 第一次進入 index
	/// 2. 改變進階查詢
	/// 3. 改變筆數
	/// 4. 改變排序
	/// 都需要重新向後端拿資料, 所以合成一個 function 處理

	// advancedSearchDataList: 進階查詢資料
	// sort: 排序

	const fetchData = (
		advancedSearchDataList = null,
		pageSize = 20,
		sort = sortBy,
		pageNumber = 1
	) => {
		//const url = `api/Logistics/LgsPurchaseOrderType/GetList`;
		const url = `api/Logistics/LgsAppendixClass/GetList`;
		console.log(advancedSearchDataList);

		// 如果只有一個查詢條件, 後端並不需要 AND/OR, 所以要在 call api 前把 searchLogic 清空
		if (advancedSearchDataList) {
			// only "one" search data
			advancedSearchDataList[0].searchLogic = '';
		}

		console.log(advancedSearchDataList);
		axiosIndexData(
			{
				url,
				method: 'post',
				data: {
					sort,
					pageSize,
					page: pageNumber,
					advancedSearchDataList,
					TransactionCode: 'lgsAppendixClass', //permission 由前端傳直給後端，判斷權限
				},
			},
			(res) => {
				console.log(res);
				const { indexData, permission, numberOfData, totalPage } = res;
				// 分頁資訊
				setPaginationData({
					...paginationData,
					numberOfData: numberOfData,
					totalPage: totalPage,
					pageNumber: pageNumber,
				});
				// table 資料
				setFetchedData({
					indexData,
					permission,
					paginationData,
				});

				console.log(indexData);

				function isArrayFn(obj) {
					// 包成函式
					if (typeof Array.isArray === 'function') {
						return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
					} else {
						// 否則就使用 toString 方法
						return (
							Object.prototype.toString.call(obj) ===
							'[object Array]'
						);
					}
				}

				/// 這和 api 回傳的 result 無關
				// 如果有 advancedSearchDataList, 且是 array
				// console.log('original', advancedSearchDataList);
				if (isArrayFn(advancedSearchDataList)) {
					// 因為後端給的 select list 名稱不一定 和 後端在做進階查詢時的欄位名稱一樣
					// 所以要將進階查詢時的欄位(searchFilter) 轉換為 tableColumn
					advancedSearchDataList.map((el) => {
						searchContentList.map((item) => {
							console.log(item);
							if (el.searchFilter === item.tableColumn) {
								el.searchFilter = item.searchFromDataBase;
							}
						});
					});

					console.log('after edit', advancedSearchDataList);

					setDisplayList(advancedSearchDataList);
					setAdvancedSearchDataList(advancedSearchDataList);
				}

				handleAdvancedSearchModalClose();
				localStorage.setItem(
					'Permission_LgsPurchaseOrderType',
					permission
				);
			}
		);
	};

	const getLocalStorageData = useCallback(() => {
		const gettingData = async () => {
			const data = await (JSON.parse(
				localStorage.getItem(`${guidName}DisplayColumns`)
			) || bodyTitleList);

			setDisplayColumns(data);

			return data;
		};

		return gettingData();
	}, []);

	const handleOpenAdvancedSearch = () => {
		//console.log('handleOpenAdvancedSearch');
	};
	const handleAdvancedSearchModalClose = () => setAdvancedSearchShow(false);
	const handleAdvancedSearchModalShow = () => {
		setAdvancedSearchShow(true);
	};

	const deleteAllFilters = () => {
		setDisplayList([]);
		setAdvancedSearchDataList([]);
		fetchData();
	};

	const deleteFilter = (targetIndex) => {
		console.log('deleteFilter index', targetIndex);
		console.log(advancedSearchDataList[targetIndex]);
		console.log(displayList[targetIndex]);

		const newAdvancedSearchDataList = advancedSearchDataList.filter(
			(_, index) => index !== targetIndex
		);
		const newDisplayList = displayList.filter(
			(_, index) => index !== targetIndex
		);
		setDisplayList(newDisplayList);
		setAdvancedSearchDataList(newAdvancedSearchDataList);
		fetchData(
			newAdvancedSearchDataList.length ? newAdvancedSearchDataList : null
		);
	};

	useEffect(() => {
		// fetchIndexInfo();
		getLocalStorageData();
		fetchData();

		return () => {
			// cleanup
		};
	}, [getLocalStorageData]);
	//console.log(fetchedData);
	return (
		<>
			<div id="indexView">
				<PageTitleBar
					pageTitle={pageTitle}
					previousPageButton={false}
				/>
				<Container>
					<Left>
						<CreateBtnNormal
							style={{
								height: '35px',
							}}
							permission={fetchedData?.permission}
							word={t('button.createButton')}
						/>
					</Left>
					<Center></Center>
					<Right>
						<SearchBtnSmall
							permission={fetchedData?.permission}
							handleClick={handleAdvancedSearchModalShow}
						/>
						<DropdownMenuArea permission={fetchedData?.permission}>
							<div onClick={() => navigate('Log')}>
								<LogIcon />
								<span>{t('button.logButton')}</span>
							</div>
						</DropdownMenuArea>
					</Right>
				</Container>
				<div className="position-relative firstRowMinHeight bg-white rounded-xxl d-flex flex-column p-4 position-relative overflow-auto">
					<AdvancedSearchResultTag
						advancedSearchDataList={advancedSearchDataList}
						deleteAllFilters={deleteAllFilters}
						deleteFilter={deleteFilter}
						ns="lgsAppendixClass"
					/>
					{fetchedData?.indexData && (
						<Table
							guidName={guidName}
							headTitleList={headTitleList}
							bodyTitleList={bodyTitleList}
							tableData={fetchedData?.indexData}
							indexData={fetchedData?.indexData}
							sortBy={sortBy}
							displayColumns={displayColumns}
							setDisplayColumns={setDisplayColumns}
							paginationData={paginationData}
							setPaginationData={setPaginationData}
							fetchData={fetchData}
							fetchedData={fetchedData}
							tableStyles={tableStyles}
							columnMustShow={columnMustShow}
							rowClick={true}
							advancedSearchDataList={advancedSearchDataList}
						/>
					)}
				</div>
			</div>

			{advancedSearchShow && (
				<AdvancedSearchModal
					isOpen={advancedSearchShow}
					handleClose={handleAdvancedSearchModalClose}
					headTitleList={headTitleList}
					bodyTitleList={bodyTitleList}
					advancedSearchDataList={advancedSearchDataList}
					setAdvancedSearchDataList={setAdvancedSearchDataList}
					advancedSearchDataTemp={advancedSearchDataTemp}
					fetchData={fetchData}
					sortBy={sortBy}
					setSortBy={setSortBy}
					setFetchedData={setFetchedData}
					displayList={
						displayList.length > 0
							? displayList
							: [{ ...advancedSearchDataTemp }]
					}
					setDisplayList={setDisplayList}
					searchContentList={searchContentList}
					ns="LgsAppendixClass"
				/>
			)}
		</>
	);
}

export default LgsAppendixClass_Index;
