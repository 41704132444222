import styled from 'styled-components';
import { useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/images/icn_Trash.svg';
import { ReactComponent as GroupIcon } from '../../assets/images/icn_Group.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icn_Search.svg';
import DropdownMenuArea from '../DropdownMenuArea/DropdownMenuArea';
import { CreateBtnNormal } from '../Buttons/CreateBtnNormal';
import { DeleteBtnSmall } from '../Buttons/DeleteBtnSmall';
import { SearchBtnSmall } from '../Buttons/SearchBtnSmall';

// #region styled-components
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
    display: flex;
`;

//#endregion

function FunctionActionButtonBar(props) {
    console.log(props);
    const { left, center, right } = props;
    // console.log(props);
    // const { view, permission, buttonListObject } = props;
    // const [buttonListShow, setButtonListShow] = useState(false);
    // const [modalShow, setModalShow] = useState(true);

    // const handleButtonList = (data, page) => {
    //     // console.log(data, page);
    //     const result = [];
    //     Object.entries(buttonListObject[page]).forEach((buttonArr) => {
    //         if (
    //             data.includes(buttonArr[0]) ||
    //             buttonListObject[page][buttonArr[0]] === true
    //         ) {
    //             result.push(buttonArr[0]);
    //         }
    //     });

    //     return result;
    // };

    // // function button list
    // const buttonList = handleButtonList();
    return (
        <Container>
            <Left>{left.map((el) => el)}</Left>
            <Center>{center.map((el) => el)}</Center>
            <Right>{right.map((el) => el)}</Right>
        </Container>
    );
}

export default FunctionActionButtonBar;
