import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StepByStepBar } from '../../../../../components/StepByStepBar/StepByStepBar';
import { SaveSubmitBtn } from '../../../../../components/Buttons/SaveSubmitBtn';
import { CancelSubmitBtn } from '../../../../../components/Buttons/CancelSubmitBtn';
import TabPage from '../../../../../components/TabPage/TabPage';
import useAxios from '../../../../../hooks/useAxios';
import InvoiceTab from './InvoiceTab/InvoiceTab';
import AccountDocumentTab from './AccountDocumentTab/AccountDocumentTab';
import { BtnNormal } from '../../../../../components/Buttons/BtnNormal';
import { useTranslation } from 'react-i18next';
import InvoiceSplitTab from './InvoiceSplitTab/InvoiceSplitTab';

// #region styled-components

// 換上下頁元件
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

//#endregion

function Page3(props) {
	//#region state initialization

	const { t } = useTranslation(['common', 'lgsPurchaseOrderInvoice']); // i18n

	const { parentData, page, processSteps, handlePrevPage, handleNextPage } =
		props;

	const decimalPlaceTl =
		parentData.page0.purchaseOrderSelectedList[0].decimalPlaceTL;

	// state
	const [tab3Data, setTab3Data] = useState([]);
	const [orgData, setOrgData] = useState(null);

	// axios
	const { sendRequest: axiosDataPage4 } = useAxios();

	//#endregion

	//#region 3個分頁tab

	// 分頁title
	const tabNavList = [
		t('lgsPurchaseOrderInvoice:invoiceDetails'),
		t('lgsPurchaseOrderInvoice:invoiceSplit'),
		t('lgsPurchaseOrderInvoice:accountingVoucher'),
	];

	// 分頁內容 4頁 component
	const tabContent = [
		<InvoiceTab parentData={parentData} />,
		<InvoiceSplitTab parentData={parentData} />,
		<AccountDocumentTab parentData={parentData} tab3Data={tab3Data} />,
	];
	//#endregion

	//#region useEffect

	useEffect(() => {
		const url = `api/Logistics/lgsPurchaseOrderInvoice/getPage4`;
		const method = 'POST';

		const purchaseOrderInvoicePage4InvoiceList =
			parentData.page2.invoiceSplitList.map((el) => {
				return {
					PurchaseOrderGuid: el.purchaseOrderGuid,
					GovernmentInvoice:
						el.governmentInvoice === '0'
							? false
							: el.governmentInvoice === '1'
							? true
							: false,
					InvoiceDate: el.invoiceDate,
					InvoiceDocumentNo: el.invoiceNo ? el.invoiceNo : null,
					InvoiceFormat:
						el.governmentInvoice === '0'
							? el.vatInvoiceFormat
							: null,
					VatinvoiceTaxType: el.vatInvoiceTaxType
						? el.vatInvoiceTaxType
						: null,
					Vatrate: el.vatRate ? el.vatRate : null,
					NetAmount: parentData.page0.purchaseOrderSelectedList[0]
						.foreignCurrency
						? null
						: el.totalCollectedInvoices,
					TaxAmount: parentData.page0.purchaseOrderSelectedList[0]
						.foreignCurrency
						? null
						: (
								Number(el.totalCollectedInvoices) *
								(Number(el.vatRate) / 100)
						  ).toFixed(decimalPlaceTl),
					ForeignNetAmount: parentData.page0
						.purchaseOrderSelectedList[0].foreignCurrency
						? el.totalCollectedInvoices
						: null,
					ForeignTaxAmount: parentData.page0
						.purchaseOrderSelectedList[0].foreignCurrency
						? (
								Number(el.totalCollectedInvoices) *
								(Number(el.vatRate) / 100)
						  ).toFixed(decimalPlaceTl)
						: null,
					PurchaserCustomerGuid: el.purchaserCustomerGuid
						? el.purchaserCustomerGuid
						: null,
					PurchaserTaxId: el.purchaserTaxID
						? el.purchaserTaxID
						: null,
					PurchaserTaxName: el.purchaserTaxName
						? el.purchaserTaxName
						: null,
					PurchaserAddTel: el.purchaserAddTel
						? el.purchaserAddTel
						: null,
					PurchaserBankAccount: el.purchaserBankAccount
						? el.purchaserBankAccount
						: null,
					SalesVendorGuid: el.salesVendorGuid
						? el.salesVendorGuid
						: null,
					SalesTaxId: el.salesTaxID ? el.salesTaxID : null,
					SalesTaxName: el.salesTaxName ? el.salesTaxName : null,
					SalesAddTel: el.salesAddTel ? el.salesAddTel : null,
					SalesBankAccount: el.salesBankAccount
						? el.salesBankAccount
						: null,
					DisplayOrder: el.displayOrder ? el.displayOrder : null,
					Vatcontrol: el.vatControl ? el.v : null,
					InvoiceCode: el.invoiceCode ? el.invoiceCode : null,
					VatinvoiceFormat:
						el.governmentInvoice === '0'
							? null
							: el.vatInvoiceFormat,
					VatinputTaxable: el.vatInputTaxable
						? el.vatInputTaxable
						: null,
				};
			});
		console.log(purchaseOrderInvoicePage4InvoiceList);

		const data = {
			PurchaseOrderInvoicePage4PurchaseOrderGuidList:
				parentData.page0.purchaseOrderGuidList,
			PurchaseOrderInvoicePage4InvoiceList:
				purchaseOrderInvoicePage4InvoiceList,
		};

		axiosDataPage4({ url, method, data }, async (res) => {
			console.log(res);

			// 會計憑證
			const { entry1, entry2, entry3 } = res;

			// 給予排序，後端需要(將三個list內元件，依序給予序號)
			let displayOrder = 1;

			// 處理小數點位數
			const processEntry = (entry) => {
				entry.forEach((el) => {
					if (el.amountCr)
						el.amountCr = el.amountCr.toFixed(decimalPlaceTl);
					if (el.amountDr)
						el.amountDr = el.amountDr.toFixed(decimalPlaceTl);

					// 給displayOrder
					el.displayOrder = displayOrder;
					displayOrder++;
				});
			};

			processEntry(entry1);
			processEntry(entry2);
			processEntry(entry3);

			// 合體(前端畫面使用)
			let accountList = [...entry1, ...entry2, ...entry3];
			console.log(accountList);

			setTab3Data({
				// 前端畫面使用
				accountDocumentName: res.accountDocumentName,
				accountList: accountList,

				// 後端需要的，分開3個list
				dataToBackEnd: {
					entry1: entry1,
					entry2: entry2,
					entry3: entry3,
				},
			});

			// 整理給後端的資料
			setOrgData({
				...data,
				entry1: entry1,
				entry2: entry2,
				entry3: entry3,
			});
		});
	}, []);

	console.log('parentData', parentData);
	console.log('tab3Data', tab3Data);
	console.log('orgData', orgData);
	//#endregion

	//#region submit送出給後端

	// 儲存
	const { error, sendRequest: axiosSaveData } = useAxios();

	const handleSubmit = () => {
		console.log(orgData);

		const url = `api/Logistics/lgsPurchaseOrderInvoice/insert`;
		const method = 'POST';
		const data = orgData;

		axiosSaveData({ url, method, data }, (res) => {
			console.log(res);
			res && handleNextPage();
		});
		console.log(error);
	};

	// const handleSubmit = () => {
	// 	handleNextPage();
	// };

	//#endregion

	return (
		<>
			<Container>
				<Left>
					{page !== 0 && (
						<BtnNormal
							className={`layout-title-searchBtn layout-title-item btn btn-outline-primary justify-content-center align-items-center`}
							key={'PrevBtn'}
							onClick={() => {
								handlePrevPage();
							}}
						>
							{t('common:button.prevStep')}
						</BtnNormal>
					)}
				</Left>
				<Center className="ps-14">
					<StepByStepBar steps={processSteps} activePage={page} />
				</Center>
				<Right>
					<SaveSubmitBtn handleSubmit={handleSubmit} />
					<CancelSubmitBtn className={'btn-outline-primary'} />
				</Right>
			</Container>

			<TabPage
				wrapperStyle={{ minHeight: '78vh' }}
				liColWidth={'col-2'}
				tabNavList={tabNavList}
				tabContent={tabContent}
				defaultPage={0}
			/>
		</>
	);
}

export default Page3;
