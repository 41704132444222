import React, { useState } from 'react';
import styled from 'styled-components';
import { BtnNormal2 } from '../../../../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import { ReactComponent as CopyPage } from '../../../../../../assets/images/copyPage.svg';
import LgsPurchaseOrder_Index from '../../../LgsPurchaseOrder_Index';
import PageCopyTable from './PageCopyTable';
import useAxios from '../../../../../../hooks/useAxios';
import { useTranslation } from 'react-i18next';

// #region styled-components

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}
`;

const InputElement = styled.input`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
`;

const SelectElement = styled.select`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 6px 8px;
	height: 32px;
	&:focus-visible {
		outline: 0;
	}
`;

const LabelNormal = styled.label`
	font-size: 12px;
	color: var(--grey4);
	margin-bottom: 6px;
`;

const Wrapper = styled.div`
	min-height: 720px;
`;

const NoInfoRow = styled.div`
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
	margin-top: 16px;
`;

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0;
`;

const Left = styled.div`
	display: flex;
`;

const Center = styled.div`
	display: flex;
`;

const Right = styled.div`
	display: flex;
`;

const ModalThead = styled.thead`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalTh = styled.th`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
	border: none !important;
`;
const ModalLabel = styled.label`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;
const ModalInput = styled.input`
	//table row固定高
	padding: 0 0.75rem;
	cursor: pointer !important;
`;

const SearchModalInput = styled.input`
	padding: 0 0.75rem;
	cursor: pointer !important;
	text-transform: uppercase;
`;

const ModalSearchBtn = styled.button`
	top: 1px;
	right: 1px;
	height: 30px;
	border-radius: 0 0.25rem 0.25rem 0;
`;

//#endregion

// 複製訂單
// Author: YL
function PageCopy(props) {
	//#region state initiation

	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);
	const {
		page,
		setPage,
		data,
		setData,
		purchaseOrderData,
		setPurchaseOrderData,
	} = props;
	const [purchaseOrderGuid, setPurchaseOrderGuid] = useState('');
	const [advancedSearchShow, setAdvancedSearchShow] = useState(false);
	const [buttonName, setButtonName] = useState(t('button.advancedSearch'));

	const {
		isLoading,
		error,
		sendRequest: axiosCopyPurchaseOrder,
	} = useAxios();

	//#endregion

	const handleCopyPurchaseOrder = () => {
		// console.log(purchaseOrderGuid);
		// console.log(data);

		const url = `api/Logistics/LgsPurchaseOrder/copyPurchaseOrder?guid=${purchaseOrderGuid}`;
		axiosCopyPurchaseOrder({ url }, (res) => {
			setPurchaseOrderData({
				...purchaseOrderData,
				isEverEnterPage: {
					page0: false,
					page1: false,
					page2: false,
					page3: false,
				},
			});
			setData(res);
			setPage(0);
		});
	};

	return (
		<>
			<Container className="">
				<Left className="">
					<BtnNormal2
						className="btn-primary d-inline-block me-2 mediumSmallBtn"
						keyName="advancedSearchCancel"
						word={buttonName}
						handleClick={() => {
							setAdvancedSearchShow(true);
							setButtonName(t('button.re-search'));
						}}
					>
						<CopyPage />
					</BtnNormal2>
				</Left>

				<Center className=""></Center>

				<Right className="">
					<BtnNormal2
						className="btn-primary d-inline-block me-2 mediumBtn"
						keyName="advancedSearchCancel"
						word={t('button.confirmButton')}
						handleClick={() => {
							handleCopyPurchaseOrder(purchaseOrderGuid);
						}}
						disabled={!purchaseOrderGuid}
					/>
				</Right>
			</Container>

			<div className="position-relative">
				<Wrapper className="bg-white rounded-xxl d-flex flex-column p-4 position-relative">
					<PageCopyTable
						// setPurchaseOrderGuid={setPurchaseOrderGuid}
						table="pageCopyTable"
						singleChecked={purchaseOrderGuid}
						setSingleChecked={setPurchaseOrderGuid}
						advancedSearchShow={advancedSearchShow}
						setAdvancedSearchShow={setAdvancedSearchShow}
					/>
				</Wrapper>
			</div>
		</>
	);
}

export default PageCopy;
