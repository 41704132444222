import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BtnNormal2 } from '../../../components/Buttons/FunctionRenderButtons/UniversalBTN';
import FormControlInput from '../../../components/Inputs/FormControlInput';
import useAxios from '../../../hooks/useAxios';
import { generateUpdateLogContent } from '../../../utils';
import { PageTitleBar } from '../LgsPurchaseOrder/exports';
import { DialogConfirm } from './DialogConfirm';
import { SubmitConfirm } from './SubmitConfirm';

// #region styled-components
const LabelNormal = styled.label`
    font-size: 12px;
    color: var(--grey4);
    margin-bottom: 6px;
`;
const InputElement = styled.input`
    font-size: 14px;
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px 8px;
    height: 32px;
    &:focus-visible {
        outline: 0;
    }
`;

const DialogBtn = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid var(--grey2);
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 16px;
    padding-right: 12px;
    cursor: pointer;
    height: 32px;
`; // dialog按鈕
const Wrapper = styled.div`
    min-height: 720px;

    border-radius: 0 0 0.75rem 0.75rem;
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

const Left = styled.div`
    display: flex;
`;

const Center = styled.div``;

const Right = styled.div`
    display: flex;
`;
const Textarea = styled.div`
    border: 1px solid var(--grey2);
    border-radius: 4px;
    padding: 8px;
    min-height: 88px;
    resize: none;
    &:focus-visible {
        outline: 0;
    }
    background-color: #efefef4d;
    /* width: 600px;
    height: 160px; */
`;
//#endregion

function LgsServiceClass_Edit() {
    const { t } = useTranslation(['common', 'lgsSalesKitClass']);
    const [page, setPage] = useState(0);
    const [view, setView] = useState('create');
    const pageTitle = t('lgsSalesKitClass:pageTitle');
    const salesKitClassGuid = useLocation().pathname.split('/').pop();
    const permission = localStorage.getItem('Permission_lgsSalesKitClass');
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    //const noRuleGuid = useParams().guid;
    const { isLoading, err, sendRequest: axiosGetData } = useAxios();

    // numbering rule 的參數
    const [data, setData] = useState({});
    const [originalDataForLog, setOriginalDataForLog] = useState({});

    // 真正的 numbering rule
    const [noRule, setNoRule] = useState('3');
    // 給使用者看的 numbering rule
    const [ruleForUser, setRuleForUser] = useState('000');
    // numbering rule 的預期結果示範
    const [preview, setPreview] = useState([]);

    const [uomList, setUomList] = useState([]);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
    const [isInActivateDialogOpen, setIsInActivateDialogOpen] = useState(false);
    const [isSubmitConfirmDialogOpen, setIsSubmitConfirmDialogOpen] =
        useState(false);

    const [error, setError] = useState({});

    //啟用:1
    const handleActivate = () => {
        const updateContent = t('log.activate', {
            value: data.name01,
        });

        console.log(data);
        axios
            .put(`api/Logistics/lgsSalesKitClass/activate`, {
                salesKitClassGuid: data.salesKitClassGuid,
                salesKitClassCode: data.salesKitClassCode,
                name01: data.name01,
                name02: data.name02,
                name03: data.name03,
                status: '1',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    //停用:2
    const handleInActivate = () => {
        const updateContent = t('log.lock', {
            value: data.name01,
        });
        axios
            .put(`api/Logistics/lgsSalesKitClass/activate`, {
                salesKitClassGuid: data.salesKitClassGuid,
                salesKitClassCode: data.salesKitClassCode,
                name01: data.name01,
                name02: data.name02,
                name03: data.name03,
                status: '2',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };
    //刪除:4
    const handleDelete = () => {
        const updateContent = t('log.delete', {
            value: data.name,
        });
        axios
            .put(`api/Logistics/lgsSalesKitClass/activate`, {
                salesKitClassGuid: data.salesKitClassGuid,
                salesKitClassCode: data.salesKitClassCode,
                name01: data.name01,
                name02: data.name02,
                name03: data.name03,
                status: '4',
                rowStamp: data.rowStamp,
                updateContent,
            })
            .then((res) => navigate(-1));
    };

    const handleSubmit = (status) => {
        const {
            salesKitClassGuid,
            salesKitClassCode,
            name01,
            name02,
            name03,
            rowStamp,
        } = data;

        console.log(data);

        const updateContent = generateUpdateLogContent(
            'salesKitClass',
            t,
            { ...data, status: status },
            originalDataForLog
        );

        console.log({ ...data, status: status });
        console.log('updateContent', updateContent);

        if (updateContent) {
            axios
                .put(`api/Logistics/lgsSalesKitClass/update`, {
                    salesKitClassGuid,
                    salesKitClassCode,
                    name01,
                    name02,
                    name03,
                    status,
                    rowStamp,
                    updateContent,
                })
                .then((res) => navigate(-1));
        } else {
            navigate(-1);
        }
    };

    const checkInputs = async () => {
        // instance event -> dialog shows or not
        const errorList = { ...error };

        // warnings show or not
        const newErrorState = { ...error };

        console.log('newErrorState', newErrorState);

        if (data?.classCode) {
            const isValidClassCode = await handleVerifyClassCode(
                data?.classCode
            );

            console.log(isValidClassCode);

            if (isValidClassCode) {
                errorList.classCode = false;
                newErrorState.classCode = false;
            }
        }

        if (data?.name01) {
            errorList.name01 = false;
            newErrorState.name01 = false;
        } else {
            errorList.name01 = true;
            newErrorState.name01 = true;
        }

        if (data?.serviceOriginCategoryCode) {
            errorList.serviceOriginCategoryCode = false;
            newErrorState.serviceOriginCategoryCode = false;
        } else {
            errorList.serviceOriginCategoryCode = true;
            newErrorState.serviceOriginCategoryCode = true;
        }

        // change UI (if errors occur)
        setError(newErrorState);

        // determine if dialog shows or not
        const isValid = Object.values(errorList).every((el) => el === false);

        return isValid && data?.status === '1'
            ? handleSubmit('1')
            : setIsSubmitConfirmDialogOpen(true);
    };

    const handleVerifyClassCode = async (val) => {
        if (!val) return true;

        const res = await axios
            .get(
                `api/Logistics/lgsServiceClass/verifyCode?classCode=${val}&salesKitClassGuid=${salesKitClassGuid}`
            )
            .then((res) => {
                // instance event -> dialog shows or not
                const errorList = { ...error };

                // warnings show or not
                const newErrorState = { ...error };

                errorList.classCode = false;
                newErrorState.classCode = false;

                setError(errorList);
                return true;
            })
            .catch((err) => {
                const res = err.response.data;
                // instance event -> dialog shows or not
                const errorList = { ...error };

                // warnings show or not
                const newErrorState = { ...error };
                const tValue = `errMsg.${res.message}`;
                errorList.classCode = {
                    state: true,
                    message: `${t(tValue)}`,
                };
                newErrorState.classCode = true;

                setError(errorList);
                return false;
            });

        return res;
    };

    useEffect(() => {
        axiosGetData(
            {
                url: `api/Logistics/LgsServiceClass/getCreateEdit`,
            },
            (res) => {
                const uomList = res?.uomList;

                const result = uomList.map((item) => {
                    item.id = item.uomguid;
                    item.value = item.uomcode;
                    item.name = item.uomcode + item.name01;

                    return item;
                });

                setUomList(result);
            }
        );
        axios(
            `api/Logistics/lgsSalesKitClass/getDetails/${salesKitClassGuid}`
        ).then((res) => {
            console.log(res.data);
            setData(res.data);
            setOriginalDataForLog(res.data);
        });
    }, []);

    console.log('datatatatata', data);

    return (
        <>
            <div id="detailsView">
                <PageTitleBar
                    pageTitle={pageTitle}
                    previousPageButton={true}
                    backTo={-1}
                />
                {/* DialogConfirm element */}
                <DialogConfirm
                    isOpen={isActivateDialogOpen}
                    setIsOpen={setIsActivateDialogOpen}
                    handleClick={() => handleActivate()}
                    message={t('dialog.activeConfirm')}
                />
                <DialogConfirm
                    isOpen={isInActivateDialogOpen}
                    setIsOpen={setIsInActivateDialogOpen}
                    handleClick={() => handleInActivate()}
                    message={t('dialog.lockConfirm')}
                />
                <DialogConfirm
                    isOpen={isDeleteDialogOpen}
                    setIsOpen={setIsDeleteDialogOpen}
                    handleClick={() => handleDelete()}
                    message={t('dialog.deleteConfirm')}
                />
                <SubmitConfirm
                    t={t}
                    isOpen={isSubmitConfirmDialogOpen}
                    setIsOpen={setIsSubmitConfirmDialogOpen}
                    handleClickInactive={() => handleSubmit('0')}
                    handleClickActive={() => handleSubmit('1')}
                    message={t('dialog.saveConfirm')}
                />
                <Container>
                    <Left></Left>
                    <Center></Center>
                    <Right>
                        <BtnNormal2
                            className="btn-primary mediumBtn"
                            handleClick={() => checkInputs()}
                        >
                            {t('button.saveButton')}
                        </BtnNormal2>
                        <BtnNormal2
                            className="btn-outline-primary mediumBtn"
                            handleClick={() => navigate(-1)}
                        >
                            {t('button.cancelButton')}
                        </BtnNormal2>
                    </Right>
                </Container>
                <div className="position-relative">
                    <Wrapper className="bg-whit d-flex flex-column p-4 position-relative bg-white">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <FormControlInput
                                        id="classCode"
                                        labelText={t(
                                            'lgsSalesKitClass:details.classCode'
                                        )}
                                        name="salesKitClassCode"
                                        value={data.salesKitClassCode}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                salesKitClassCode:
                                                    e.target.value,
                                            });
                                        }}
                                        onBlur={(e) =>
                                            handleVerifyClassCode(
                                                e.target.value
                                            )
                                        }
                                        disabled={false}
                                        disabledCssType={0}
                                        isErr={error?.classCode?.state}
                                        errMsg={error?.classCode?.message}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <FormControlInput
                                        id="name01"
                                        labelText={t(
                                            'lgsSalesKitClass:details.name01'
                                        )}
                                        name="name01"
                                        value={data.name01}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                name01: e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                        isErr={error?.name01}
                                        errMsg={t('errMsg.mustFill')}
                                    />
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <FormControlInput
                                        id="name02"
                                        labelText={t(
                                            'lgsSalesKitClass:details.name02'
                                        )}
                                        name="name02"
                                        value={data.name02}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                name02: e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                        isErr={error?.name02}
                                        errMsg={t('errMsg.mustFill')}
                                    />
                                </div>
                                <div className="col-lg-4 px-4 pr-xl-6">
                                    <FormControlInput
                                        id="name03"
                                        labelText={t(
                                            'lgsSalesKitClass:details.name03'
                                        )}
                                        name="name02"
                                        value={data.name03}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                name03: e.target.value,
                                            });
                                        }}
                                        disabled={false}
                                        disabledCssType={0}
                                        isErr={error?.name02}
                                        errMsg={t('errMsg.mustFill')}
                                    />
                                </div>
                            </div>
                        </div>
                    </Wrapper>
                </div>
            </div>
        </>
    );
}

export default LgsServiceClass_Edit;
