import swal from 'sweetalert2';
import styles from '../styleModule/SwalStyles.module.css';
// import { ReactComponent as dangerIcon } from '../assets/images/alertIcon/alertDangerIcon.js';
import dangerIcon from './alertIcon/alertDangerIcon';

const alertSize = {
    small: 'swal2-small',
    medium: 'swal2-medium',
    large: 'swal2-large',
};

const commonOptions = {
    confirmButtonText: '確定',
    cancelButtonText: '取消',
    showCloseButton: true,
};

const svgMappingTable = {
    danger: dangerIcon,
};

const addIconInFront = (props) => {
    const { htmlSvg, html } = props;
    console.log(htmlSvg, html);

    if (htmlSvg) {
        const newHtml = `
            <div class='d-flex justify-content-center align-items-center'>
                <div class='mt-4 me-2'>${svgMappingTable[htmlSvg]}</div>${html}
            </div>
        `;

        props.html = newHtml;
    }

    return props;
};

function showAlert(props) {
    console.log(props);
    const alertSizeClass = styles[`swal2-${props.size}`];

    const properties = addIconInFront(props);

    const swalOptions = {
        ...properties,
        customClass: {
            popup: `${alertSizeClass} ${styles['swal2-popup']}`,
            title: `${styles['swal2-title']}`,
            htmlContainer: `${styles['swal2-content']}`,
            confirmButton: `${styles['swal2-confirm']}`,
            cancelButton: `${styles['swal2-cancel']}`,
        },
    };

    swal.fire(swalOptions);
}

export default showAlert;
