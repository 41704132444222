import React from 'react';
import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';

export const BtnNormal = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 88px;
	height: 32px;
	margin-right: 8px;
	svg {
		margin-right: 8px;
	}
`;
