import { useState, useEffect } from 'react';
import TopInfo from './TopInfo';
import GoodsItemTable from './GoodsItemTable';
import { useTranslation } from 'react-i18next';

function GoodsItemTab({ parentData }) {
	const { t } = useTranslation();
	//#region state initiation
	const [tableBodyData, setTableBodyData] = useState([]);
	//#endregion

	//#region variable initiation

	// table header 的名稱
	const headTitleList = [
		'#',
		t('lgsPurchaseOrderGoodsInspect:page1.material'),
		t('lgsPurchaseOrderGoodsInspect:page1.originalMaterialLocation'),
		t('lgsPurchaseOrderGoodsInspect:page1.inspectionQuantity'),
		t('lgsPurchaseOrderGoodsInspect:page1.qualityTypeDecision'),
		t('lgsPurchaseOrderGoodsInspect:page1.acceptedMaterialLocation'),
		t('lgsPurchaseOrderGoodsInspect:page1.batchNumber'),
		t('lgsPurchaseOrderGoodsInspect:page1.detailAnnotation'),
		'',
	];

	// table body 的資料名稱
	const bodyTitleList = [
		'displayOrder',
		'materialName',
		'cubeName',
		'thisQuantityInspect',
		'qualityCode',
		'materialCube',
		'batchNo',
		'tableBodyNote',
	];

	// 不同 input 的 type
	const inputProperties = [
		{ type: 'number' },
		{ type: 'text' },
		{ type: 'text' },
		{
			type: 'number',
			min: 1,
			//待處理，離開欄位時，計算總和並比對和填入
			onBlur: () =>
				handleCalculateAmount('changingAmount', tableBodyData),
		},
		{},
		{},
		{ type: 'text' },
		{ type: 'text' },
		{ type: 'text' },
	];

	// table header 的 style
	const tableHeadStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '136px',
			textAlign: 'center',
		},
		{
			width: '136px',
			textAlign: 'center',
		},
		{
			width: '120px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'center',
		},
		{
			minWidth: '264px',
			textAlign: 'center',
		},
	];

	// table body 的 style
	const tableBodyStyles = [
		{
			width: '56px',
			textAlign: 'center',
		},
		{
			width: '160px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '136px',
			textAlign: 'left',
		},
		{
			width: '136px',
			textAlign: 'left',
		},
		{
			width: '120px',
			textAlign: 'left',
		},
		{
			width: '160px',
			textAlign: 'left',
		},
		{
			width: '264px',
			textAlign: 'left',
		},
	];

	//#endregion

	useEffect(() => {
		setTableBodyData(parentData.page1.goodsInspectList);
	}, []);
	console.log('tableBodyData', tableBodyData);

	return (
		<>
			<h1
				style={{ fontSize: '28px' }}
				className="fw-bold text-grey5 text-center mt-4"
			>
				{t('lgsPurchaseOrderGoodsInspect:page2.inspectionForm')}
			</h1>
			<div className="row bg-white rounded-xxl px-6 pt-6 mx-0 mb-0">
				<TopInfo parentData={parentData} />
			</div>
			<div className="position-relative">
				<div className="bg-white rounded-xxl d-flex flex-column px-2 py-3 position-relative">
					<GoodsItemTable
						parentData={parentData}
						headTitleList={headTitleList}
						bodyTitleList={bodyTitleList}
						tableHeadStyles={tableHeadStyles}
						tableBodyStyles={tableBodyStyles}
						tableBodyData={tableBodyData}
						inputProperties={inputProperties}
					/>
				</div>
			</div>
		</>
	);
}

export default GoodsItemTab;
