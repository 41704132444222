import React from 'react';
import styled from 'styled-components';
import { LabelNormal } from './shared/LabelNormal';
import { disabledStyle } from './shared/DisabledStyle';
import { ErrMsg } from './shared/ErrMsg';

// 使用範例

{
	/* 
	<Textarea
	wrapperClass="mb-3" // 外層class，可用來推下面margin
	labelText="註記" // label文字
	labelStyle={{
		marginBottom: '0px',
	}} // label style
	elementStyle={{
		minHeight: '0px',
	}} // element style
	id="note" // input id&name ; label htmlFor
	rows="12"
	cols="30"
	placeholder="請填寫註記"
	disabled={false}
	disabledCssType={0} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
	register={register} // react-hook-form
	validation={{
		// 驗證邏輯
		required: 'must be required',
	}}
	isErr={errors.note} // 是否顯示錯誤訊息
	errMsg={errors.note?.message} // 錯誤訊息
	onChange={(e) => {
		setValue('note', e.target.value);
		const noteValue = getValues('note');
		// console.log(noteValue);
	}}
	/>; 
*/
}

const TextareaElement = styled.textarea`
	border: 1px solid var(--grey2);
	border-radius: 4px;
	padding: 8px;
	min-height: 88px;
	resize: none;
	&:focus-visible {
		outline: 0;
	}

	${disabledStyle}
`;

function Textarea({
	labelText,
	labelStyle,
	elementStyle,
	id,
	rows,
	cols,
	value,
	defaultValue,
	placeholder,
	onChange,
	isErr,
	errMsg,
	wrapperClass,
	disabled,
	disabledCssType,
	register,
	validation,
}) {
	return (
		<div className={`wrapper d-flex flex-column ${wrapperClass}`}>
			<LabelNormal htmlFor={id} style={labelStyle}>
				{labelText}
			</LabelNormal>
			<TextareaElement
				style={elementStyle}
				{...register(id, validation)}
				id={id}
				rows={rows}
				cols={cols}
				value={value}
				defaultValue={defaultValue}
				placeholder={placeholder}
				onChange={onChange}
				disabled={disabled}
				disabledCssType={disabledCssType}
			/>
			<ErrMsg
				className={`text-danger mt-1 ${isErr ? 'd-block' : 'd-none'}`}
			>
				{errMsg}
			</ErrMsg>
		</div>
	);
}

export default Textarea;
