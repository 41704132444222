import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../assets/images/icn_Trash.svg';
import { ReactComponent as NextStepIcon } from '../../assets/images/icn_NextStep.svg';

//#region styled-components

const Wrapper = styled.div`
    width: 126px;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const StepNumber = styled.div`
    /* border: 1px solid green; */
    height: 26px;
    width: 26px;
    border-radius: 8px;
    background-color: ${(prop) => (prop.isActive ? '#ffa51d' : '#E3E8EE')};
    color: ${(prop) => (prop.isActive ? '#ffffff' : '#616E83')};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IconWrapper = styled.div`
    width: 7.5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

//#endregion

export const StepByStepBar = ({ steps, activePage }) => {
    // console.log(className, to);
    return steps?.map((el) => {
        return (
            <Wrapper
                // className="justify-content-center align-items-center
                // me-2"
                // to={to}
                key={'PrevBtn' + el}
            >
                {steps.indexOf(el) === steps.length - 1 ? (
                    <React.Fragment>
                        <StepNumber isActive={activePage === steps.indexOf(el)}>
                            {steps.indexOf(el) + 1}
                        </StepNumber>
                        {el}
                        <IconWrapper />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <StepNumber isActive={activePage === steps.indexOf(el)}>
                            {steps.indexOf(el) + 1}
                        </StepNumber>
                        {el}
                        <IconWrapper>
                            <NextStepIcon />
                        </IconWrapper>
                    </React.Fragment>
                )}
            </Wrapper>
        );
    });
};
