import useSortableData from '../../hooks/useSortableData';
import styled from 'styled-components';
import { ReactComponent as DeletingIcon } from '../../assets/images/icn_deleted.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback, useContext } from 'react';

import useHover from '../../hooks/useHover';

import Modal from '../Modals/IndexTableDisplayModal_Accoutant';
import Pagination from '../Pagination/Pagination';
import Select from '../FormElement/Select';
import AdvancedSearchResultTag from '../Modals/AdvancedSearchResultTag';
import { DialogConfirm } from '../../pages/Logistics/GlaAccountant/DialogConfirm';
import { useTranslation } from 'react-i18next';

// #region styled-components

const IndexTableContainer = styled.div``;

const Menu = styled.div`
	position: absolute;
	top: 0px;
	right: 0px;
	will-change: transform;
	padding: 0;
	margin-top: 8px;
	min-width: 64px;


	color: var(--danger);

	&:hover,
	&:focus {
		color: var(--danger);
	}
`;

const Button = styled.div`
	//active-submit dropdownItem d-flex align-items-center pe-2 tex-left px-3 py-2
	display: flex;
	cursor: pointer;
	align-items: center;
	padding: 8px 16px;
	text-align: left;
	min-width: 64px;
	height: 32px;
	justify-content: center;
	svg {
		margin-right: 8px;
	}
    `;

const IndexTable = styled.table`
    margin-bottom: 0;
    color: var(--grey4);
    background-color: #fff;
`;

const THead = styled.thead`
    height: 40px;
`;

const TR = styled.tr`
    height: 40px;
    &:hover {
		cursor: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'pointer' : 'default'};
		background-color: ${(hoverEffect) =>
			hoverEffect === 'true' ? 'var(--grey0)' : 'var(--bs-table-bg)'};
	}
	td:last-child {
		display: none !important;
	}

	&:hover {
		td:last-child {
			display: block !important;
		}
	}
`;

const TH = styled.th`
    vertical-align: middle;
    border: none;
`;

const TBody = styled.tbody``;


const TD = styled.td`
	cursor: auto;
    border: none;
	&:first-of-type {
		border-left: none !important;
	}

	&:last-of-type {
		border-left: none !important;
		border-right: none !important;
	}

	background-color: ${(prop) =>
		prop.active ? '#fbfcfd !important' : '#fff'};

	position: relative;
	padding: 0;
`;

const TD1 = styled.td`
    border: none;
`;

const ModalInputWrapper = styled.div`
    width: 200px;
    text-align: left !important;
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.3125rem;
    color: #616e83;
`;

const ModalCheckbox = styled.input``;

const ModalCheckboxLabel = styled.label`
    margin-left: 0.5rem !important;
    display: inline-block;
    margin-bottom: 0.5rem;
`;
//#endregion

function IndexTablePartial(props) {
    // console.log(props);
    const {
        guidName,
        sortBy,
        setSortBy,
        headTitleList,
        bodyTitleList,
        indexData,
        paginationData,
        setPaginationData,
        displayColumns,
        setDisplayColumns,
        fetchData,
        fetchedData,
        tableStyles,
        columnMustShow,
        clickSelection,
        rowClick,
        advancedSearchDataList,
        advancedSearchDataTemp,
        displaySetting,
        orderBy,
        setOrderBy,
    } = props;

    const { t, i18n } = useTranslation();
    const [hoverRef, isHovered] = useHover(); // https://usehooks.com/usehover
    const [hoverRef1, isHovered1] = useHover(); // 因為 button & header 的 hover event 不能一樣, 所以使用兩次 useHover
    const [displayOptions, setDisplayOptions] = useState(displayColumns);

    // for checkbox column
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);
    const [data, setData] = useState([]);

    const { items, requestSort, sortConfig } = useSortableData(indexData);
    const navigate = useNavigate();
    const location = useLocation();

    let { numberOfData, pageNumber, totalPage, pageSize } = paginationData;

    const statusSpanObj = {
        0: (
            <span>
                <span
                    className="bg-warning d-inline-block me-2"
                    style={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                    }}
                ></span>
                <span className="text-grey4">
                    {t('common:status.inactive')}
                </span>
            </span>
        ),
        1: <span></span>,
        2: (
            <span>
                <span
                    className="bg-danger d-inline-block me-2"
                    style={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                    }}
                ></span>
                <span className="text-grey4">{t('status.lock')}</span>
            </span>
        ),
    };

    // #region functions

    // !!! 好像沒有用的 function => 本意為希望 tableHeader 保持和 UI 排序一致, 但測試中好像不需要也會排序一致?
    // TODO: 暫先註解, 找出 function 使用時機後再處理
    // const preferredOrder = (indexData, bodyTitleList) => {
    //     let newObject = {};
    //     for (let i = 0; i < bodyTitleList.length; i++) {
    //         if (indexData.hasOwnProperty(bodyTitleList[i])) {
    //             newObject[bodyTitleList[i]] = indexData[bodyTitleList[i]];
    //         }
    //     }
    //     return newObject;
    // };

	const [isOpen, setIsOpen] = useState(false);
	const handleDialogConfirm = (e,data) => {
        setData(data);
		setIsOpen(true);
        //console.log(e);
        console.log(data);
	};

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const handleRowClick = (guid) =>
        navigate(`${location.pathname}/Details/${guid}`, {
            state: {
                permission: fetchedData.permission,
            },
        });

    const handleSortChange = (newTarget) => {
        let newSortBy;
        let newOrderBy;

        const oldTarget = sortBy.split(' ')[0];

        console.log(oldTarget);
        console.log(newTarget);
        console.log(orderBy);

        if (oldTarget === newTarget) {
            newOrderBy = orderBy === 'desc' ? 'asc' : 'desc';
        } else {
            newOrderBy = 'desc';
        }

        console.log(newOrderBy);

        newSortBy = `${newTarget} ${newOrderBy}`;

        fetchData(
            advancedSearchDataList.length ? advancedSearchDataList : null,
            pageSize,
            newSortBy,
            pageNumber
        );

        setSortBy(newSortBy);
        setOrderBy(newOrderBy);
    };

    const handleSelectAll = (e) => {
        e.stopPropagation();
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map((li) => li));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const checkingIsCheckAll = useCallback(() => {
        const checkIsCheckAll = () => {
            if (list.length === isCheck.length) {
                setIsCheckAll(true);
            } else {
                setIsCheckAll(false);
            }
        };

        isCheck.length && checkIsCheckAll();
    });

    const handleSelect = (e) => {
        e.stopPropagation();
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id));
        }
    };

    useEffect(() => {
        let guidList = items.map((el) => el[guidName]);

        setList(guidList);

        return checkingIsCheckAll();
    }, [isCheck]);

    //#endregion

    return (
        <>
            <IndexTableContainer>

                    <DialogConfirm
                        fetchData={fetchData}
                        data={data}
                 		isOpen={isOpen}
						setIsOpen={setIsOpen}
						backTo={-1}
					/>

                <IndexTable
                    className="table"
                    style={{
                        // display: 'block' 可讓 scroll bar 接在 table 下面
                        // display: 'block',
                        border: '1px solid var(--grey1)',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        borderCollapse: 'collapse',
                        borderSpacing: '0',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                    }}
                >
                    
                    <THead
                        className="table-head"
                        items={items}
                        requestSort={requestSort}
                        sortConfig={sortConfig}
                    >
                           <TR ref={hoverRef} className="d-flex">
                        {/* <TR ref={hoverRef} className="d-flex"> */}
                            {clickSelection ? (
                                clickSelection === 2 ? (
                                    <TH>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => {
                                                handleSelectAll(e);
                                            }}
                                            checked={isCheckAll}
                                        />
                                    </TH>
                                ) : (
                                    <TH>
                                        <input
                                            type="checkbox"
                                            style={{ visibility: 'hidden' }}
                                        />
                                    </TH>
                                )
                            ) : null}
                            {headTitleList?.map((el) => {
                                // if
                                return (
                                    <TH
                                        style={
                                            tableStyles[
                                                headTitleList.indexOf(el)
                                            ]
                                        }
                                        key={`tableHead${headTitleList.indexOf(
                                            el
                                        )}`}
                                        className={`
                                            px-2 w-100 cursor-pointer
                                            ${
                                                !displayColumns.includes(
                                                    bodyTitleList[
                                                        headTitleList.indexOf(
                                                            el
                                                        )
                                                    ]
                                                )
                                                    ? 'd-none'
                                                    : 'd-block'
                                            }
                                            ${
                                                bodyTitleList[
                                                    headTitleList.indexOf(el)
                                                ]
                                            }
                                            ${getClassNamesFor(
                                                bodyTitleList[
                                                    headTitleList.indexOf(el)
                                                ]
                                            )}
                                                `}
                                        onClick={() => {
                                            // requestSort(
                                            //     bodyTitleList[
                                            //         headTitleList.indexOf(el)
                                            //     ]
                                            // );
                                            bodyTitleList[
                                                headTitleList.indexOf(el)
                                            ] !== 'displayOrder' &&
                                                handleSortChange(
                                                    bodyTitleList[
                                                        headTitleList.indexOf(
                                                            el
                                                        )
                                                    ]
                                                );
                                        }}
                                    >
                                        {el}
                                        {bodyTitleList[
                                            headTitleList.indexOf(el)
                                        ] === sortBy.split(' ')[0] &&
                                            (orderBy === 'desc' ? (
                                                <span className="ml-1">▼</span>
                                            ) : (
                                                <span className="ml-1">▲</span>
                                            ))}
                                    </TH>
                                );
                            })}
                        </TR>
                    </THead>
                    <TBody>
                        {items?.map((data) => (
                            <TR
                                className="list-item table-item d-flex"
                                id={data[guidName]}
                                key={data[guidName]}
                                onClick={(e) =>
                                    rowClick && handleRowClick(data[guidName])
                                }
                            >
                                {clickSelection ? (
                                    <TD>
                                        <input
                                            id={data[guidName]}
                                            type="checkbox"
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => handleSelect(e)}
                                            checked={isCheck.includes(
                                                data[guidName]
                                            )}
                                        />
                                    </TD>
                                ) : null}
                                {bodyTitleList.map((el) => {
                                    if (el === 'status')
                                        return (
                                            <TD
                                                style={
                                                    tableStyles[
                                                        bodyTitleList.indexOf(
                                                            el
                                                        )
                                                    ]
                                                }
                                                className={`p-2 w-100
                                                    ${
                                                        !displayColumns.includes(
                                                            el
                                                        )
                                                            ? 'd-none'
                                                            : 'd-block'
                                                    }`}
                                                key={`${el}${data[guidName]}`}
                                            >
                                                {statusSpanObj[data[el]]}
                                            </TD>
                                        );
                                    return (
                                        <TD
                                            style={{
                                                ...tableStyles[
                                                    bodyTitleList.indexOf(el)
                                                ],
                                                overflow: 'hidden',
                                            }}
                                            // style={
                                            //     tableStyles[
                                            //         bodyTitleList.indexOf(el)
                                            //     ]
                                            // }
                                            className={`p-2 w-100
                                                ${
                                                    !displayColumns.includes(el)
                                                        ? 'd-none'
                                                        : 'd-block'
                                                }`}
                                            key={`${el}${data[guidName]}`}
                                        >
                                            {data[el]}
                                            {/* {'111'} */}
            
                      

                                        </TD>



                                    );
                                })}



                                <TD
									style={{
										padding: '0',
										border: 'none !important',
									}}
								>
                                                   <Menu style={{ zIndex: '2' }}>
                                            <Button
                                           
                                                 onClick={(e) => {
                                                    handleDialogConfirm(
                                                        e,
                                                        data
                                                    );
                                                }}





                                            >
                                                      <DeletingIcon />
                                                <span>
                                           
                                                </span>
                                            </Button>

           



                                        </Menu>
								</TD>

                            </TR>
                        ))}
                    </TBody>
                </IndexTable>
                <Pagination
                    paginationData={paginationData}
                    setPaginationData={setPaginationData}
                    fetchData={fetchData}
                    sortBy={sortBy}
                    advancedSearchDataList={advancedSearchDataList}
                />
            </IndexTableContainer>

            {/* 首列的 顯示設定 hover */}
            {/* {displaySetting && (
                <div ref={hoverRef1}>
                    {
                        <Modal
                            headTitleList={headTitleList}
                            bodyTitleList={bodyTitleList}
                            guidName={guidName}
                            isHovered={isHovered || isHovered1}
                            displayColumns={displayColumns}
                            setDisplayColumns={setDisplayColumns}
                            displayOptions={displayOptions}
                            setDisplayOptions={setDisplayOptions}
                            columnMustShow={columnMustShow}
                        />
                    }
                </div>
            )} */}
        </>
    );
}

export default IndexTablePartial;
