import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Input from '../../../../../../components/FormElement/Input';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

function InvoiceModal(props) {
	const { t } = useTranslation(['common', 'lgsPurchaseOrder']);
	// console.log(props);
	const {
		show,
		handleClose,
		register,
		errors,
		getValues,
		setValue,
		detailsInfo,
	} = props;

	return (
		// modalCode === 1 && (
		<Modal show={show} onHide={handleClose} size="xl">
			<Modal.Header closeButton>
				<Modal.Title>
					<h2
						style={{
							color: 'blue',
							textAlign: 'center',
							fontSize: '20px',
							fontWeight: 'bold',
						}}
					>
						{t('lgsPurchaseOrder:otherCredentials')}
					</h2>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-lg-3">
						<Input
							wrapperClass="mb-3" // 外層class，可用來推下面margin
							labelText={t(
								'lgsPurchaseOrder:purchaseSalesControl'
							)} // label文字
							type="text" // input型態
							id="vendor" // input id&name ; label htmlFor
							placeholder={t('lgsPurchaseOrder:purchaseInput')}
							disabled={true}
							disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
							register={register}
							validation={{
								required: 'must be required',
							}}
						/>
					</div>

					<div className="col-lg-3">
						<Input
							wrapperClass="mb-3" // 外層class，可用來推下面margin
							labelText={t('lgsPurchaseOrder:invoiceDate')} // label文字
							type="text" // input型態
							id="vendor" // input id&name ; label htmlFor
							placeholder="2021/09/30"
							disabled={true}
							disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
							register={register}
							validation={{
								required: 'must be required',
							}}
						/>
					</div>

					<div className="col-lg-3">
						<Input
							wrapperClass="mb-3" // 外層class，可用來推下面margin
							labelText={t('lgsPurchaseOrder:certificateNumber')} // label文字
							type="text" // input型態
							id="vendor" // input id&name ; label htmlFor
							placeholder="00000000"
							disabled={true}
							disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
							register={register}
							validation={{
								required: 'must be required',
							}}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-3">
						<Input
							wrapperClass="mb-3" // 外層class，可用來推下面margin
							labelText={t('lgsPurchaseOrder:certificateFormat')} // label文字
							type="text" // input型態
							id="vendor" // input id&name ; label htmlFor
							placeholder={t('lgsPurchaseOrder:twoPartInvoice')}
							disabled={true}
							disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
							register={register}
							validation={{
								required: 'must be required',
							}}
						/>
					</div>

					<div className="col-lg-3">
						<div className="row">
							<div className="col-lg-8">
								<Input
									wrapperClass="mb-3" // 外層class，可用來推下面margin
									labelText={t('lgsPurchaseOrder:taxRate')} // label文字
									type="text" // input型態
									id="vendor" // input id&name ; label htmlFor
									placeholder={t(
										'lgsPurchaseOrder:generalTaxRate'
									)}
									disabled={true}
									disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
									register={register}
									validation={{
										required: 'must be required',
									}}
								/>
							</div>
							<div className="col-lg-4">
								<Input
									wrapperClass="mb-3" // 外層class，可用來推下面margin
									labelText="　" // label文字
									type="text" // input型態
									id="vendor" // input id&name ; label htmlFor
									placeholder="5%"
									disabled={true}
									disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
									register={register}
									validation={{
										required: 'must be required',
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-3">
						<Input
							wrapperClass="mb-3" // 外層class，可用來推下面margin
							labelText={t('lgsPurchaseOrder:deductibleInput')} // label文字
							type="text" // input型態
							id="vendor" // input id&name ; label htmlFor
							placeholder={t('lgsPurchaseOrder:deductibleInput')}
							disabled={true}
							disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
							register={register}
							validation={{
								required: 'must be required',
							}}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-3">
						<Input
							wrapperClass="mb-3" // 外層class，可用來推下面margin
							labelText={t('lgsPurchaseOrder:amountNetSales')} // label文字
							type="text" // input型態
							id="vendor" // input id&name ; label htmlFor
							placeholder="999,999,999.9999"
							disabled={true}
							disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
							register={register}
							validation={{
								required: 'must be required',
							}}
						/>
					</div>

					<div className="col-lg-3">
						<Input
							wrapperClass="mb-3" // 外層class，可用來推下面margin
							labelText={t('lgsPurchaseOrder:taxAmount3')} // label文字
							type="text" // input型態
							id="vendor" // input id&name ; label htmlFor
							placeholder="999,999,999.9999"
							disabled={true}
							disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
							register={register}
							validation={{
								required: 'must be required',
							}}
						/>
					</div>

					<div className="col-lg-3">
						<Input
							wrapperClass="mb-3" // 外層class，可用來推下面margin
							labelText={t(
								'lgsPurchaseOrder:totalTaxableAmountIncludingTaxSales'
							)} // label文字
							type="text" // input型態
							id="vendor" // input id&name ; label htmlFor
							placeholder="999,999,999.9999"
							disabled={true}
							disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
							register={register}
							validation={{
								required: 'must be required',
							}}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							wrapperClass="mb-3" // 外層class，可用來推下面margin
							labelText={t('lgsPurchaseOrder:currency')} // label文字
							type="text" // input型態
							id="vendor" // input id&name ; label htmlFor
							// placeholder="USD 美元"
							disabled={true}
							disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
							register={register}
							validation={{
								required: 'must be required',
							}}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-6">
						<div className="row">
							<div className="col-lg-6">
								<h3
									style={{
										color: 'b#0C2556',
										fontSize: '14px',
										fontFamily: 'Noto Sans',
										fontWeight: 'bold',
										marginBottom: '13px',
									}}
								>
									{t('lgsPurchaseOrder:purchaser')}
								</h3>
							</div>
							<div className="col-lg-6">
								<span
									style={{
										background: '#E3E8EE',
										color: 'b#0C2556',
										fontFamily: 'Noto Sans',
										width: '100%',
										display: 'inline-block',
										marginBottom: '13px',
										paddingLeft: '5px',
									}}
								>
									ASDF 翔睿德
								</span>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6">
								<Input
									wrapperClass="mb-3" // 外層class，可用來推下面margin
									labelText={t(
										'lgsPurchaseOrder:unifiedNumber'
									)} // label文字
									type="text" // input型態
									id="vendor" // input id&name ; label htmlFor
									placeholder="00000000"
									disabled={true}
									disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
									register={register}
									validation={{
										required: 'must be required',
									}}
								/>
							</div>
							<div className="col-lg-6">
								<Input
									wrapperClass="mb-3" // 外層class，可用來推下面margin
									labelText={t('lgsPurchaseOrder:fullName')} // label文字
									type="text" // input型態
									id="vendor" // input id&name ; label htmlFor
									placeholder="王小明"
									disabled={true}
									disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
									register={register}
									validation={{
										required: 'must be required',
									}}
								/>
							</div>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="row">
							<div className="col-lg-6">
								<h3
									style={{
										color: 'b#0C2556',
										fontSize: '14px',
										fontFamily: 'Noto Sans',
										fontWeight: 'bold',
										marginBottom: '13px',
									}}
								>
									{t('lgsPurchaseOrder:seller')}
								</h3>
							</div>

							<div className="col-lg-6">
								<span
									style={{
										background: '#E3E8EE',
										color: 'b#0C2556',
										fontFamily: 'Noto Sans',
										width: '100%',
										display: 'inline-block',
										marginBottom: '13px',
										paddingLeft: '5px',
									}}
								>
									翔睿德
								</span>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6">
								<Input
									wrapperClass="mb-3" // 外層class，可用來推下面margin
									labelText={t(
										'lgsPurchaseOrder:unifiedNumber'
									)} // label文字
									type="text" // input型態
									id="vendor" // input id&name ; label htmlFor
									placeholder="00000000"
									disabled={true}
									disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
									register={register}
									validation={{
										required: 'must be required',
									}}
								/>
							</div>
							<div className="col-lg-6">
								<Input
									wrapperClass="mb-3" // 外層class，可用來推下面margin
									labelText={t('lgsPurchaseOrder:fullName')} // label文字
									type="text" // input型態
									id="vendor" // input id&name ; label htmlFor
									placeholder="趙大明"
									disabled={true}
									disabledCssType={1} // disable樣式 0:無樣式 1:只有下底線 2:有border底色深色
									register={register}
									validation={{
										required: 'must be required',
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<button
					type="button"
					onClick={handleClose}
					className="btn btn-primary mediumBtn mx-1"
				>
					{t('button.confirmButton')}
				</button>
				<button
					type="button"
					onClick={handleClose}
					class="btn btn-outline-primarylllt mediumBtn mx-1"
				>
					{t('button.cancelButton')}
				</button>
			</Modal.Footer>
		</Modal>
		// )
	);
}

export default InvoiceModal;
