import styled from 'styled-components';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { useState } from 'react';

// #region styled-components
const Container = styled.div``;

const PageContent = styled.div`
	width: ${(prop) =>
		prop.isSidebarWrap ? 'calc(100% - 56px)' : 'calc(100% - 200px)'};
	min-height: 100vh;
	transition: all 0.3s;
	position: absolute;
	top: 0;
	right: 0;
`;

const PageContentMain = styled.div`
	min-height: calc(100vh - 3.5rem - 1px);
	padding: 1rem !important;
	background-color: #f2f5f9 !important;
`;

const PageContentMainPrimary = styled.div``;

//#endregion

function Layout({ children, lang }) {
	const [isSidebarWrap, setSidebarWrap] = useState(false);

	return (
		<Container>
			<Sidebar
				isSidebarWrap={isSidebarWrap}
				setSidebarWrap={setSidebarWrap}
			/>
			<PageContent
				isSidebarWrap={isSidebarWrap}
				setSidebarWrap={setSidebarWrap}
			>
				<Navbar lang={lang} />
				<PageContentMain>
					<PageContentMainPrimary
						id="pageContentMainPrimary"
						className="rounded-xl"
					>
						{children}
					</PageContentMainPrimary>
				</PageContentMain>
			</PageContent>
		</Container>
	);
}

export default Layout;
